import { Directive, Attribute, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl, AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appPercentageValidation][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: PercentageValidationDirective,
      multi: true
    }
  ]
})
export class PercentageValidationDirective implements Validator {

  constructor(@Attribute('appPercentageValidation') public PercentageControl: string) { }

  public validate(c: FormControl) {
    const surchargeType = c.root.get(this.PercentageControl);
    const inputPrice = c;
    surchargeType.valueChanges.subscribe((type) => {
      if (type === 'Percentage') {
        inputPrice.setValidators([Validators.min(0), Validators.max(100),
        Validators.compose([
          Validators.required,
          Validators.pattern("^([0-9][0-9]*[.]?[0-9]{0,2})$"),
        ]),
        ]);
      }
      if (type === 'Fixed') {
        inputPrice.setValidators([Validators.compose([
          Validators.required,
          Validators.pattern("^([0-9][0-9]*[.]?[0-9]{0,2})$"),
        ])]);
      }
      inputPrice.updateValueAndValidity();
    });
    return null;
  }

}
