import { CurrencyService } from './../services/currency.service';
import { FirebaseDataService } from '@app/shared/services/firebase-data.service';
import { CommonService } from '@app/shared/services/common.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: "ConvtVendorCurrency",
})
export class VendorCurrencyPipe implements PipeTransform {
    constructor(
        private fbDatabase: FirebaseDataService,
        private commonService: CommonService,
        private currencyService: CurrencyService
    ) {
        // getting the all currency name from the firebase as on 23.11.2018
    }
    public transform(value: any, currency: string) {
        // handling the all negetive scenario to return the blank string
        if (
            value == "" ||
            Number(value) == 0 ||
            value == null ||
            currency == "USD" ||
            !currency ||
            !value ||
            value == "."
        ) {
            return "";
        } else {
            let platformPrice: any;
            // if currency id not found in the firebase array it will return the blank string
            if (this.fbDatabase.currencyPrices[currency]) {
                // converting the price to the no and dividing by the today price to get the USD as on 23.11.2018
                // to send the two decimal point spliting the data
                const price = this.currencyService
                    .vendorConvertCurrency(value, currency, "platform")
                    .split(".");
                if (price[1]) {
                    if (price[1][1]) {
                        platformPrice =
                            price[0].length <= 1 && price[1].indexOf("+") > -1
                                ? price[0] + price[1]
                                : price[0] + "." + price[1][0] + price[1][1];
                    } else {
                        platformPrice = price[0] + "." + price[1][0];
                    }
                } else {
                    platformPrice = price[0];
                }
                // only two digit value is displaying
                return platformPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " USD";
            }
        }
    }
}
