import { MessageConfig } from '@app/shared/constants/constant';
import { AppupdateConfig, APP_CONFIG } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import firebase from 'firebase';
import * as compareVersions from 'compare-versions';
import { AlertController, Platform, ToastController } from '@ionic/angular';
import { UtilService } from './util.service';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { BehaviorSubject, Observable } from 'rxjs';
import { SplashScreen } from '@capacitor/splash-screen';
// import { SplashScreen } from '@ionic-native/splash-screen';
import { CommonService } from '@app/shared/services/common.service';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
@Injectable({
  providedIn: 'root',
})
export class AppUpdateService {
  public appUpdate = new AppupdateConfig();
  public InstalledVersion: string;
  public visible = false;
  public token;
  public firebaseMaxVersion;
  public latestVersionNumber: string;
  public urlSetCompleted = new BehaviorSubject<boolean>(false);
  public urlSetCompletedLoginError = new BehaviorSubject<boolean>(false);
  public appUrl: string;
  constructor(
    private platform: Platform,
    private utilService: UtilService,
    private tostCtrl: ToastController,
    private alertCtrl: AlertController,
    private appVersion: AppVersion,
  ) {
    this.platform.ready().then((data) => {
      if (Capacitor.isNativePlatform()) {
        this.appVersion.getVersionNumber().then((version) => {
        this.InstalledVersion = version;
        window.addEventListener('statusTap',  (()=>{})
        );
        // Display content under transparent status bar (Android only)
        const setStatusBarStyleLight = async () => {
          await StatusBar.setStyle({ style: Style.Light });
        };
        const showStatusBar = async () => {
          await StatusBar.show();
        };

        });
      } else {
        this.InstalledVersion = APP_CONFIG.WebVersion;
      }
    });
  }
  public hideSplashScreen() {
    setTimeout(() => {
      SplashScreen.hide();
    }, 3000);
  }

  public async getUpdateConfig() {
    firebase
      .database()
      .ref('/AppUpdate/')
      .on('value', (snapshot) => {
        setTimeout(() => {
          this.appUpdate = snapshot.val();
          if (Capacitor.isNativePlatform()) {
            if (Capacitor.getPlatform() === 'android') {
              this.latestVersionNumber = this.appUpdate.MinAndroidVersion;
              setTimeout(() => {
                this.hardUpdate();
              }, 500);
              if (compareVersions(
                this.appUpdate.MinAndroidVersion,
                this.InstalledVersion
              ) == 1) {
                this.showPopup(true);
              } else {
                this.setUrl();
                this.softUpdate();
              }
            } else if (Capacitor.getPlatform() === 'ios') {
              this.latestVersionNumber = this.appUpdate.MinIosVersion;
              setTimeout(() => {
                this.hardUpdate();
              }, 500);
              if (compareVersions(
                this.appUpdate.MinIosVersion,
                this.InstalledVersion
              ) == 1) {
                this.showPopup(true);
              } else {
                this.setUrl();
                this.softUpdate();
              }
            }

          } else {
            if (compareVersions(
              this.appUpdate.MinWebversion,
              this.InstalledVersion
            ) == 1) {
              this.showPopup(true);
            } else {
              this.setUrl();
            }
          }
        }, 100)
      })
  }
  public deviceMessage() {
    this.appUrl = Capacitor.getPlatform() === "android"
      ? MessageConfig.PlayStoreUrl
      : Capacitor.getPlatform() === "ios"
        ? MessageConfig.AppStoreUrl
        : '';
  }
  public hardUpdate() {
    // this.deviceMessage();
    if (Capacitor.isNativePlatform())
      if (this.latestVersionNumber && this.InstalledVersion) {
        if (this.latestVersionNumber > this.InstalledVersion) {
          this.presentAlert();
        }
      }
  }
  public softUpdate() {
    this.deviceMessage();
    if (this.firebaseMaxVersion && this.InstalledVersion) {
      if (this.firebaseMaxVersion > this.InstalledVersion) {
        this.showToastMessage(MessageConfig.AppUpdateMessage, 'bottom', false);
      }
    }
  }
  public async presentAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Update Available',
      message: 'Please update app for better experience',
      buttons: [
        {
          text: 'Update',
          handler: () => {
            window.open(this.appUrl);
          }
        }
      ],
      backdropDismiss: false,
    });

    await alert.present();
  }
  public async showToastMessage(
    str: string,
    position?: 'bottom',
    eventEnabled = false,
  ) {
    this.tostCtrl
      .create({
        header: str,
        position: 'bottom',
        cssClass: 'toast-custom-wrapper',
        buttons: [
          {
            side: 'end',
            icon: 'cloud-download',
            role: 'ok',
            handler: () => {
              window.open(this.appUrl);
            },
          },
          {
            side: 'end',
            icon: 'close-outline',
            role: 'cancel',
            handler: () => {
            },
          },
        ],
      })
      .then((toast) => {
        toast.present();
      });
  }
  public setUrl() {
    this.showPopup(false);
    if (APP_CONFIG.MyURL == '') {
      if (
        compareVersions(this.appUpdate.url1.min, this.InstalledVersion) < 1 &&
        compareVersions(this.appUpdate.url1.max, this.InstalledVersion) > -1
      ) {
        this.utilService.actualURL = this.appUpdate.url1.url;
        this.utilService.flightPlanningURL = this.appUpdate.url1.FlpUrl;
        this.firebaseMaxVersion = this.appUpdate.url1.max;
      } else {
        this.utilService.actualURL = this.appUpdate.url2.url;
        this.utilService.flightPlanningURL = this.appUpdate.url2.FlpUrl;
        this.firebaseMaxVersion = this.appUpdate.url2.max;
      }
    } else {
      this.utilService.actualURL = APP_CONFIG.MyURL;
      this.utilService.flightPlanningURL = APP_CONFIG.FlpURL;
    }
    this.urlSetCompleted.next(true);
  }

  public showPopup(value: boolean) {
    this.visible = value;
  }
}
