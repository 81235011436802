import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { AuthSessionService } from '../services/auth-session.service';


@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {
  constructor(
    private authSessionService: AuthSessionService,
    private router: Router,
    private storage: Storage,
  ) {

  }
  public canActivate() {
    this.storage.get('userdetails').then((value) => {
      if (value?.RoleID == 7) {
        this.router.navigate(['charter'],{replaceUrl: true});
      } else {
        this.router.navigate(['trips'],{replaceUrl: true});
      }
    });
    return false;
  }

}
