import { AuthSessionService } from '@app/core/services/auth-session.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { filter, map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authSessionService: AuthSessionService,
    private router: Router
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (state.url === '/fuel-delivery-note') {
      return true; // Allow access to the public page without authentication
    }

    return this.authSessionService.userLoggedin.pipe(
      filter((val: any) => val !== null),
      take(1), // Ensure the Observable completes after receiving the first value
      map((isAuthenticated: boolean) => {
        if (isAuthenticated) {
          return true;
        } else {
          this.router.navigate(['signin']);
          return false;
        }
      })
    );
  }
}
