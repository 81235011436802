import { Component, OnInit, Input } from '@angular/core';
import { ChatInfo, UnreadNotification } from '@app/right-menu/models/right-menu.model';
import { ChatDetails } from '@app/shared/models/shared.model';
import { CommonService } from '@app/shared/services/common.service';
import { Events } from '@app/shared/services/events.service';
import firebase from 'firebase/app';
@Component({
  selector: 'app-unread-chat',
  templateUrl: './unread-chat.component.html',
  styleUrls: ['./unread-chat.component.scss'],
})
export class UnreadChatComponent implements OnInit {
  @Input() public chatInfo: ChatInfo;
  public chatDetails = new Array<ChatDetails>();
  public unreadNotification = new Array<any>();
  constructor(private commonService: CommonService, private events: Events) { }

  public ngOnInit() {
    this.fetchChatNotification();
  }

  public fetchChatNotification() {
    let chatRef: any;
    firebase.database().ref(this.chatRefNotification()).on("value", (snapshot: any) => {
      chatRef = snapshot.val();
      this.unreadNotification = new Array<UnreadNotification>()
      /* tslint:disable */
      for (const triId in chatRef) {
        for (const legId in chatRef[triId]) {
          for (const sector in chatRef[triId][legId]) {
            for (const service in chatRef[triId][legId][sector]) {
              const toSendID = chatRef[triId][legId][sector][service];
              const mgsIds = Object.keys(chatRef[triId][legId][sector][service]);
              mgsIds.forEach((msgid: string, index: number) => {
                if (index == 0) {
                  const chat = new UnreadNotification();
                  chat.count = Object.keys(chatRef[triId][legId][sector][service]).length || 0;
                  chat.chatDetails = new ChatInfo(chatRef[triId][legId][sector][service][msgid].toChatId, Number(triId), Number(legId), sector, service, 'LiveChat')
                  chat.photoUrl = chatRef[triId][legId][sector][service][msgid].logo;
                  chat.name = chatRef[triId][legId][sector][service][msgid].name;
                 this.unreadNotification.push(chat);
                }
              });
            }
          }
        }
      }
    });
  }

  // for chatRefNotification
  public chatRefNotification() {
    return `/ChatNotification/${this.commonService.uid}`;
  }

  // openlivechat
  public openLiveChat(notification: UnreadNotification) {
    this.events.publish('openChat', new ChatInfo(notification.chatDetails.toChatId, notification.chatDetails.tripId, notification.chatDetails.legId, notification.chatDetails.location, notification.chatDetails.service, 'LiveChat'));
    firebase.database().ref(`${this.chatRefNotification()}/${notification.chatDetails.tripId}/${notification.chatDetails.legId}/${notification.chatDetails.location}/${notification.chatDetails.service}`).remove();
  }
}
