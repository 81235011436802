import { Pipe, PipeTransform } from '@angular/core';
import { Handling_Order_Services_Names, Handling_Order_Services_Order} from '../enums/common-enums';
@Pipe({
  name: 'handlingServicesOrder'
})
export class HandlingOtherServicesOrderPipe implements PipeTransform {
  public transform(servicesData) {
    const servicesArr = [];
    for (const index in servicesData) {
      if (servicesData) {
        for (const serviceName in Handling_Order_Services_Names) {
          if (Handling_Order_Services_Names[serviceName] == index) {
            servicesData[index].title = index;
            servicesData[index].order =
              Handling_Order_Services_Order[serviceName];
            servicesArr.push(servicesData[index]);
          }
        }
      }
    }
    return servicesArr.sort((a, b) => {
      return a.order < b.order ? -1 : 1;
    });
  }
}
