import { Injectable } from '@angular/core';
import { AuthSessionService } from '@app/core/services/auth-session.service';
import { ServerConnectService } from '@app/core/services/server-connect.service';
import { activityUriConfig } from '@app/trips/constants/trip-config-url';
import { MessageConfig } from '../constants/constant';
import { CommonService } from './common.service';
import { FirebaseDataService } from './firebase-data.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  public show: boolean = false;
  constructor(private commonservice: CommonService, private serverConnectService: ServerConnectService, private firebaseDataService: FirebaseDataService,
    private authSessionService: AuthSessionService) { }
  public download(
    name: string,
    tripid: number,
    download,
    tripstatus: string,
    legid: number,
    sector: string,
    path: string,
    filename: any,
    station?: any,
  ) {
    let databody: string;
    switch (name) {
      case "TCE":
        this.commonservice.showSpinnerLoader(MessageConfig.downLoading);
        databody = "SRID=" + tripid + "&status=" + tripstatus + "&download=" + download;
        this.serverConnectService.connectToServer(0,
          activityUriConfig.GetTripCostEstimate,
          databody, false, false, false
        ).then((res: any) => {
          this.firebaseDataService.getFileFormPath(res.GetTripCostEstimateDetails);
        }).catch((err)=>{
          this.commonservice.dismissLoader();
        })
        break;

      case "TCS":
        this.commonservice.showSpinnerLoader(MessageConfig.downLoading);
        const data = "SRID=" + tripid + "&download=" + download;
        this.serverConnectService.connectToServer(0, activityUriConfig.GetTripCostSheet, data, false, false, false).then(
          (res: any) => {
            this.firebaseDataService.getFileFormPath(res.GetTripCostSheetDetails);
          },
        );
        break;

      case "GD":
        this.commonservice.showSpinnerLoader(MessageConfig.downLoading);
        const gdData = "srid=" + tripid + "&sector=" + sector + "&legid=" + legid;
        this.serverConnectService.connectToServer(0,
          activityUriConfig.GenralDeclaration,
          gdData,
          false, false, false).then((res: any) => {
            this.firebaseDataService.getFileFormPath(res.genrateGenralDeclaration);
          });
        break;

      case "Pax Manifest":
        this.commonservice.showSpinnerLoader(MessageConfig.downLoading);
        const paxbody = "srid=" + tripid + "&sector=" + sector + "&legid=" + legid;
        this.serverConnectService.connectToServer(0,
          activityUriConfig.GenratePaxManifest,
          paxbody,
          false, false, false).then((res: any) => {
            this.firebaseDataService.getFileFormPath(res.genratePaxManifest);
          });
        break;
      case "Fuel Release":
        this.commonservice.showSpinnerLoader(MessageConfig.downLoading);
        databody = "SRID=" +
          tripid +
          "&SRSecID=" +
          legid +
          "&Sector=" +
          sector +
          "&Station=" +
          station;
        this.serverConnectService.connectToServer(0, activityUriConfig.getFuelReleaseNote, databody, false, false, false).then(
          (res: any) => {
            this.firebaseDataService.getFileFormPath(res.GetFuelReleaseNote);
          },
        );
        break;
      case "CBP":
      case "Trip Kit":
      case "FDN":
      case "Flight Plan":
      case "Nav Log":
        this.firebaseDataService.downloadfileFrombucket(path, filename);
        break;
      // case "COA":
      // case "COR":
      // case "NOISE":
      // case "AOC":
      // case "Insurance":
      // case "INSURANCE":
      // case "Operational Notes":
      // case "Aircraft Documents":
      case name:
        this.firebaseDataService.openDoc(path, filename)
        break;
      default:
        break;
    }
  }

  public permisionToShow(tripData: any, access: string) {
    return new Promise((resolve, reject) => {
      this.authSessionService.$userSession.subscribe((res: any) => {
        const permissions = Object.values(res.permissions);
        permissions.filter((re: any) => {
          if (re.Module == 'SidemenuAcDocuments') {
            resolve(true);
          } else
            tripData.info.filter((download: { name: any; }) => {
              if (re.Access) {
                if (re.Module.includes(download.name) && access == 'R') {
                  resolve(true);
                }
              } else {
                resolve(false);
              }
            })
        })
      })
    })

  }
}
