import { Injectable } from '@angular/core';
import { ServerConnectService } from '@app/core/services/server-connect.service';
import { ApiTypes } from '../constants/constant';
import { ApiConfig } from '../constants/url-constant';
import { CommonService } from './common.service';
import { queryParams } from './../../shared/utils/query-params';
@Injectable({
  providedIn: 'root'
})
export class TaxCsvServiceService {
  public apiType = ApiTypes;
  constructor(private serverConnectService: ServerConnectService, private commonService: CommonService) { }

  public fetchCsvList() {
    return new Promise((resolve, reject) => {
      const databody = "";
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          ApiConfig.getListOfTaxExcelData,
          databody,
          false,
          false,
          false
        )
        .then((value: any) => {
          resolve(value.GetListOfTaxesExcelData);
        })
        .catch((error) => {
          this.commonService.showErrorAlert().then((res)=> {
            if (res) {
                      this.fetchCsvList();
                    } else {
                      reject();
                    }
          })
        });
    });
  }
 public deleteCsvFile(FUDName , TEID) {
  const databody = queryParams({
    UserEmailID: this.commonService.userName,
    UserPass: this.commonService.password,
    FUDName,
    TEID,
  });
  return new Promise((resolve, reject) => {
    this.serverConnectService
    .connectToServer(
      this.apiType.POST,
      ApiConfig.deleteTaxExcelData,
      databody,
      false,
      false,
      false
    )
    .then((value: any) => {
      resolve(value.ResponseStatus);
    })
    .catch((error) => {
      this.commonService.showErrorAlert().then((res)=> {
        if (res) {
                  this.deleteCsvFile(FUDName , TEID);
                } else {
                  reject();
                }
      })
    });
  });
 }
 public deleteLandingCsv(FUDName , LEDID, ServiceName) {
  const databody = queryParams({
    FUDName,
    LEDID,
    ServiceName,
  });
  return new Promise((resolve, reject) => {
    this.serverConnectService
    .connectToServer(
      this.apiType.POST,
      ApiConfig.deleteCSV,
      databody,
      false,
      false,
      false
    )
    .then((value: any) => {
      resolve(value.ResponseStatus);
    })
    .catch((error) => {
      this.commonService.showErrorAlert().then((res)=> {
        if (res) {
                  this.deleteLandingCsv(FUDName , LEDID, ServiceName);
                } else {
                  reject();
                }
      })
    });
  });
 }
 public deleteSlotCsv(FUDName , SEDID, ServiceName) {
  const databody = queryParams({
    FUDName,
    SEDID,
    ServiceName,
  });
  return new Promise((resolve, reject) => {
    this.serverConnectService
    .connectToServer(
      this.apiType.POST,
      ApiConfig.deleteCSV,
      databody,
      false,
      false,
      false
    )
    .then((value: any) => {
      resolve(value.ResponseStatus);
    })
    .catch((error) => {
      this.commonService.showErrorAlert().then((res)=> {
        if (res) {
                  this.deleteLandingCsv(FUDName , SEDID, ServiceName);
                } else {
                  reject();
                }
      })
    });
  });
 }
 public deleteOvfCsv(FUDName , OFEDID, ServiceName) {
  const databody = queryParams({
    FUDName,
    OFEDID,
    ServiceName,
  });
  return new Promise((resolve, reject) => {
    this.serverConnectService
    .connectToServer(
      this.apiType.POST,
      ApiConfig.deleteCSV,
      databody,
      false,
      false,
      false
    )
    .then((value: any) => {
      resolve(value.ResponseStatus);
    })
    .catch((error) => {
      this.commonService.showErrorAlert().then((res)=> {
        if (res) {
                  this.deleteLandingCsv(FUDName , OFEDID, ServiceName);
                } else {
                  reject();
                }
      })
    });
  });
 }
}
