
export const CharterMessageConfig = {
    Invoicedetails: 'Your trip Invoice details would be shared on registered emails address.',
    InfoDetails: 'Your trip details will be shared on your registered Email.'
}
export const EstimateStatusList = [
    {
        icon: 'infinite-outline',
        status: 'All',
        loadStatus: 'Drafts'
    },
    {
        icon: 'contrast-outline',
        status: 'Trip Estimate',
        loadStatus: 'Estimated'
    },
    {
        icon: 'thumbs-down-outline',
        status: 'Activated',
        loadStatus: 'ActiveEstimate',
    },
    {
        icon: 'close-circle-outline',
        status: 'Expired',
        loadStatus: 'ExpiredEstimated',
    },
];