import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  public transform(value: any, useLocalTime: boolean = false): any {
    if (value) {
      if (useLocalTime) {
        let updatedAtDate: Date;
        if (typeof value === 'string' || typeof value === 'number') {
          updatedAtDate = new Date(value);
        } else {
          updatedAtDate = value as Date;
        }
        if (isNaN(updatedAtDate.getTime())) {
          return 'Invalid Date';
        }
        const currentDateTimeLocal = moment().format();
        const updatedDateTimeLocal = moment.utc(value).local().format(); // converting zulu (utc) to local timezone
        const currentDateTimeInLocal = new Date(currentDateTimeLocal);
        const lastUpdatedDateTimeInLocal = new Date(updatedDateTimeLocal);
        const secondsDiff = Math.floor((currentDateTimeInLocal.getTime() - lastUpdatedDateTimeInLocal.getTime()) /1000);
        if (secondsDiff < 30) {
          return 'Just now';
        } else if (secondsDiff < 60) {
          return '1 minute ago';
        } else if (secondsDiff < 3600) {
          const minutes = Math.floor(secondsDiff / 60);
          return `${minutes} minutes ago`;
        } else if (secondsDiff < 86400) {
          const hours = Math.floor(secondsDiff / 3600);
          return `${hours} hours ago`;
        } else if (secondsDiff < 172800) {
          return '1 day ago';
        } else if (secondsDiff < 259200) {
          // this is for 2 days.
          const days = Math.floor(secondsDiff / 86400);
          return `${days} days ago`;
        } else {
          const formattedDate = moment(updatedAtDate).format('MMM D, YYYY HH:mm[Z]');
          return `${formattedDate}`;
        }
      } else {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
        return 'Just now';
      const intervals = {
        'year': 31536000,
        'month': 2592000,
        'week': 604800,
        'day': 86400,
        'hour': 3600,
        'minute': 60,
        'second': 1
      }
      let counter;
      for (const i of Object.keys(intervals)) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0)
          if (counter === 1) {
            return counter + ' ' + i + ' ago'; // singular (1 day ago)
          } else {
            return counter + ' ' + i + 's ago'; // plural (2 days ago)
          }
      }
    }
  }
    return value;
  }
}
