import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getKeys',
})
export class GetKeysPipe implements PipeTransform {
  public transform(value): any {
    const keys = [];
    for (const key in value) {
      if (key) {
        keys.push(key);
      }
    }
    return keys;
  }
}
