import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { AuthSessionService } from '@app/core/services/auth-session.service';
import { UtilService } from '@app/core/services/util.service';
import { MessageConfig, PageHeader } from '@app/shared/constants/constant';
import { CommonService } from '@app/shared/services/common.service';
import { FileUploadServiceService } from '@app/shared/services/file-upload-service.service';
import { FirebaseDataService } from '@app/shared/services/firebase-data.service';
import { RouteObj } from '@app/trips/model/route.model';
import { EnterTripDetailsService } from '@app/trips/services/enter-trip-details.service';
import { TripRouteService } from '@app/trips/services/trip-route.service';
import { Capacitor } from '@capacitor/core';
import { PermitsService } from '@app/trips/trips-tab/enter-trip-details/permit/permits.service';
import { AlertController } from '@ionic/angular';
import { FlightPlanService } from '@app/flight-planning/route-plan/services/flight-plan.service';
import { environment } from '@environment/environment';
import { EnterDetailsApiService } from '@app/trips/services/enter-details-api.service';
@Component({
  selector: 'app-flight-plan',
  templateUrl: './flight-plan.component.html',
  styleUrls: ['./flight-plan.component.scss'],
})
export class FlightPlanComponent implements OnInit {
  @Input("route") public route: any;
  @Input("flightPlanIndex") public flightPlanIndex: any;
  @Input("tripID") public tripID: number;
  @Input("serviceName") public serviceName: string;
  @Output() public save: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild("cbpelement") public fileInput: ElementRef;
  @ViewChild("flightelement") public fileInput1: ElementRef;
  public AttachedfilenameCBP = 'Attach-Documents';
  public CPBFileCheck = '';
  public AttachdFile = 'File';
  public filename: any;
  public CBPAttachFile :any;
  public chekfileType: any;
  public uploadedFiles = [];
  public imageSelect: any;
  public remarks: string;
  public messageConfig = MessageConfig;
  public sentToAtcButton: boolean = true;
  public storagePath: any;
  public storageCBPath: any;
  private file: any;
  public fileName: any;
  public fileFormate: string = "";
  public CBPFileName = "Tripkit";
  public cbpEnable: any;
  public navLogEnable: any;
  public getFLPattachment:any;
  constructor(public tripRouteService: TripRouteService,
    public authSessionService: AuthSessionService,
    public commonService: CommonService,
    private firebaseService: FirebaseDataService,
    private utilService: UtilService,
    private router: Router,
    private fileUploadService: FileUploadServiceService,
    public enterTripDetailsService: EnterTripDetailsService,
    private flightPlanService: FlightPlanService,
    public permitService: PermitsService, private alertCtrl: AlertController,private enterDetailApiService : EnterDetailsApiService) {
  }

  public ngOnInit() {
    if (this.route) {
      this.cbpBtnEnable();
      this.NavEnable();
    }

    const legid = this.route?.legId ? this.route?.legId : this.route?.legID;
    if(this.tripRouteService?.RouteDetails?.flpattachments){
      this.getFLPattachment = this.tripRouteService?.RouteDetails?.flpattachments[this.flightPlanIndex]?.[legid];
    }
  }
  public cbpBtnEnable() {
    this.cbpEnable =
      this.route?.CBPAttachmentDetails?.Cbpname ??
        this.route?.CBPAttachmentDetails?.Cbpname ?
        this.route?.CBPAttachmentDetails?.Cbpname
        : this.route?.Cbpname ?? this.route?.Cbpname
          ? this.route?.Cbpname
          : this.route?.UploadedArray?.Cbpname ??
            this.route?.UploadedArray?.Cbpname
            ? this.route?.UploadedArray?.Cbpname
            : ''
    const enable = this.cbpEnable ?? '';
  }
  public NavEnable() {
    this.navLogEnable = this.route?.CBPAttachmentDetails?.AttachmentFileName ??
      this.route?.CBPAttachmentDetails?.AttachmentFileName
      ? this.route?.CBPAttachmentDetails?.AttachmentFileName
      : this.route?.AttachmentFileName ?? this.route?.AttachmentFileName
        ? this.route?.AttachmentFileName
        : this.route?.UploadedArray?.AttachmentFileName ??
          this.route?.UploadedArray?.AttachmentFileName
          ? this.route?.UploadedArray?.AttachmentFileName
          : ''
    const enable = this.navLogEnable ?? '';
  }
  public validateToAccessCompute(VendorID: number) {
    if (Array.isArray(this.firebaseService.fLPVendorList)) {
      const result = this.firebaseService.fLPVendorList.filter(
        (s) => VendorID == s,
      );
      if (result.length != 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  public toggleSelectFlightPlanCheckmark(selectedFlightPlan) {
    if (selectedFlightPlan.Selected == "1") {
      return 'checkmark-circle-outline';
    } else {
      return 'radio-button-off-outline';
    }
  }
  public toggleSelectFlightPlanClassName(route: RouteObj) {
    let checkmarkType = this.toggleSelectFlightPlanCheckmark(route);
    if (checkmarkType == 'checkmark-circle-outlinee') {
      checkmarkType = 'green-status-icon round_icon';
    } else {
      if (route.Valid != '1') {
        checkmarkType = 'disabled-status-icon round_icon';
      } else {
        checkmarkType = 'amber-status-icon round_icon';
      }
    }
    return checkmarkType;
  }
  public async acceptFlightPlan(route: RouteObj, ind) {
    this.commonService.showConfirmAlert(MessageConfig.FinalLize).then(async (res) => {
      if (res) {
        const prompt = await this.alertCtrl.create({
          cssClass: 'alertCustomCss',
          header:
            'Enter remarks (Optional) ',
          inputs: [
            {
              type: 'text',
              name: 'remarks',
              value: this.remarks,
            },
          ],
          buttons: [
            {
              text: 'Ok',
              handler: (data) => {
                if (data) {
                  if (route.Valid == '1' && this.commonService.roleId != 3 && route?.Selected != '1' && route?.CBPAttachmentDetails?.Selected != '1') {
                    this.remarks = data.remarks;
                    if ((this.tripRouteService?.RouteDetails?.assgnedcrewdetails != undefined && ind != undefined && this.tripRouteService.RouteDetails?.assgnedcrewdetails[ind][route.legId]?.list.length > 0) ||
                      (this.permitService.tripServices?.crewDetails != undefined && this.permitService.tripServices.crewDetails[route.legID]?.list.length > 0)) {
                      if (this.validateToAccessCompute(route?.VendorID)) {
                        const SRSecID = route.legId ? route.legId : route.legID;
                        const respid = route.routeId ? route.routeId : route.RouteID;
                        const sector = route.sector ? route.sector : route.Sector;
                        this.flightPlanService
                          .getATCRouteByLegID(Number(respid))
                          .then((data1: any) => {
                            // setTimeout is requred for all dependencies objects to create before compute
                            setTimeout(() => {
                              this.flightPlanService
                                .compute(SRSecID?.toString(), this.tripID?.toString(), sector,environment.firebase_config.projectId == "avplat-local" ? 'testFlight':'production', this.remarks)
                                .then((computeRouteId: any) => {
                                  route.routeId = computeRouteId;
                                  this.flightPlanService
                                    .submitFlightPlan(
                                      SRSecID?.toString(),
                                      this?.tripID?.toString(),
                                      sector,
                                      route?.VendorID?.toString(),
                                    )
                                    .then((data2: any) => {
                                      const foundItem = data2?.SaveFlightPlan?.find(item => item.RouteID == route.routeId);
                                      if (foundItem) {
                                        route.CrewBriefingAttachmentID = foundItem.CrewBriefingAttachmentID;
                                        this.FinalizeFlighPlan(route);
                                      }
                                    });
                                });
                            }, 1000);
                          });
                      } else {
                        this.FinalizeFlighPlan(route, this.remarks);
                      }

                    } else {
                      this.commonService.showMeassgeAlert(MessageConfig.FinalizeFlightPlan).then((response: any) => {
                        if (response) {
                          this.router.navigate([`trips/trips-tab/${this.tripID}/trip-activity`]);
                        }
                      });
                    }
                  } else {
                    this.commonService.showToastMessage(MessageConfig.NotValidRoute);
                  }
                }

              },
            },
          ],
        });
        await prompt.present();
      }
    });
  }

  public FinalizeFlighPlan(route, remarks?) {
    this.tripRouteService.remarks = remarks;
    this.tripRouteService
      .selectFlightPlan(route, this.tripID, 'manual')
      .then(() => {
        route.Selected = '1';
        if (this.enterTripDetailsService.serviceName == 'Finalize Flight Plan') {
          this.enterTripDetailsService.close();
        } else {
          this.router.navigate(["/trips/trips-tab/" + this.tripID + "/flightplan/" + this.tripID]);
        }
      });
  }

  public FileFlightPlan(route: RouteObj, action?: string) {
    const currentLeg = this.tripRouteService.LegInformation.filter(
      (legObj) => legObj.legid == route.legId
    );
    const navigationExtras: NavigationExtras = {
      state: {
        route,
        pageType: 'TripRoute',
        serviceName: PageHeader.FileFlightPlan,
        action,
        routeID: route.routeId,
        legid: route.legId,
        tripId: this.tripID,
        sector: currentLeg[0].sector,
        flightplanID: route.CBPAttachmentDetails.CrewBriefingAttachmentID,
        vendorid: route.VendorID,
      }
    };
    this.router.navigate(['trips/enter-trip-details'], navigationExtras);
  }
  public enableAtcButton() {
    this.sentToAtcButton = false;
  }
  public sendFlightPlan(route: any, action: string) {
    action = action.toLowerCase();
    switch (action) {
      case "file":
        this.FileFlightPlan(route, action);
        break;
      case "delay":
      case "change":
        this.FileFlightPlan(route, action);
        break;
      case "cancel":
        this.cancel(route.CrewBriefingAttachmentID);
        break;
      default:
        return false;
    }
  }
  public cancel(crewBriefingAttachmentID: number) {
    this.commonService
      .showConfirmAlert(
        MessageConfig.DeclinedFlightPlan,
        MessageConfig.AcceptButtonText
      )
      .then((data) => {
        if (data) {
          this.tripRouteService
            .flightPlanCancellation(crewBriefingAttachmentID)
            .then(() => {
              this.sentToAtcButton = true;
              this.tripRouteService.getTripRouteDetails(this.tripID);
            });
        }
      });
  }
  public downloadAttachment(
    type: string,
    downloadfilename: string,
    sector: string,
    vendorUid: number,
    AccessType: string,
    ServiceType: string,
    route?: any
  ) {
    switch (ServiceType) {
      case 'Vendor':
        this.storagePath = 'flightPlan/' + vendorUid + '/' + sector;
        this.storageCBPath = 'crewdatacbp/' + vendorUid + '/' + sector;
        break;
      default:
        this.storagePath = 'flightPlan/' + this.commonService.uid + '/' + sector;
        this.storageCBPath = 'crewdatacbp/' + this.commonService.uid + '/' + sector;
        break;
    }
    const storagePathByType =
      type == 'flightPlan' ? this.storagePath : this.storageCBPath;
    this.commonService.showSpinnerLoader(MessageConfig.downLoading);
    this.firebaseService.downloadfileFrombucket(
      storagePathByType,
      downloadfilename
    );
  }

  public downloadFiledFlightPlan(crewBriefingAttachmentID: number) {
    this.tripRouteService
      .downloadFiledFlightPlan(crewBriefingAttachmentID)
      .then((res: string) => {
        if (this.utilService.isBrowser()) {
          this.firebaseService.dowloadforWeb(res);
        } else {
          this.firebaseService.downloadfordevice(String(res));
        }
      });
  }
  public GetMarker(item: any, type: string) {
    switch (type) {
      case "start": {
        return item[0].Latitude + "," + item[0].Longitude;
      }
      case "end": {
        return (
          item[item.length - 1].Latitude + "," + item[item.length - 1].Longitude
        );
      }
      default: {
        let temparr = "";
        let tempstring = "";
        item.forEach((LatLongData) => {
          tempstring = LatLongData.Latitude + "," + LatLongData.Longitude + "|";
          temparr = temparr + tempstring;
        });
        temparr = temparr.slice(0, -1);
        return temparr;
      }
    }
  }
  public showRoute(route: RouteObj) {
    const routeid = route.routeId ? route.routeId : route.RouteID;
    const navigationExtras: NavigationExtras = {
      state: {
        showCloseButton: true,
        tripID: this.tripID,
        legId: route?.legId ? route?.legId : route?.SRSecID ? route?.SRSecID : route.legID,
        sector: route?.sector ? route?.sector : route?.Sector,
        vendorid: route?.VendorID ? route?.VendorID : undefined,
        selectedRoute:route?.Selected
      }
    };
    this.router.navigate(['/flight-plan/' + routeid], navigationExtras);
  }
  public validateRoute(route: RouteObj) {
    let reload = true;
    if ('Finalize Flight Plan' == this.serviceName) {
      reload = false;
    }
    if (this.validateToAccessCompute(route.VendorID)) {
      this.tripRouteService.validateRoute(route, this.tripID, reload).then((res) => {
        this.save.next(res);
      });
    }
  }
  public selectFile() {

  }
  public filechoose(event: any) {
    this.fileUploadService
      .checkFileType(event.target.files[0], "file")
      .then((filevalid) => {
        if (filevalid) {
          if (event.target.files && event.target.files[0]) {
            const size = event.target.files[0].size;
            if (size < 7000000) {
              this.fileFormate = event.target.files[0].name;
              const filename = event.target.files[0].name;
              const reader = new FileReader();
              reader.onload = (env: any) => {
                this.file = env.target.result;
                this.fileFormate = filename;
                const extensionSplit = filename.split(".").pop();
                const filePath = `${this.getConcatedFileName()}.${extensionSplit}`;
                this.enterTripDetailsService.uploadTripKitToFirebaseStorage(filePath, this.file, this.route.Sector).then(() => {
                  const FilePath = filePath;
                  const sector = this.route ? this.route.Sector : undefined;
                  const SRID = this.route ? this.route.SRID : undefined;
                  const legid = this.route ? this.route.legID : undefined;
                  const crewBriefingAttachmentID = this.route ? this.route.CrewBriefingAttachmentID : undefined;
                  this.enterTripDetailsService.finalizeCBP(SRID, crewBriefingAttachmentID, filePath, sector, legid).then(() => {
                    this.enterTripDetailsService.close()
                    this.commonService.dissmissSpinnerLoader();
                  });
                });
              };
              reader.readAsDataURL(event.target.files[0]);
            } else {
              this.commonService.dissmissSpinnerLoader();
            }
          }
        } else {
          this.commonService.dissmissSpinnerLoader();
        }
        event.target.value = null;
      });
  }
  public getConcatedFileName(): string {
    const dateFormate = this.commonService.getCurrentDate(new Date(), "MMYYss");
    const aircraftType = this.enterTripDetailsService.activity?.AircraftType ? this.enterTripDetailsService.activity.AircraftType.replace('-', '') : this.permitService.tripServices?.AircraftType ? this.permitService.tripServices.AircraftType.replace('-', '') : '';
    const location = this.route ? this.route.Sector : undefined;
    const tripID = this.route ? this.route.SRID : undefined;
    return [this.CBPFileName, tripID, aircraftType, location, dateFormate].join("_");;
  }
  public tripKitCondition(item: any) {
    if (item.Selected == "1" && item.Finalize != "1" && item.Filed == "true") {
      return true;
    } else if (item?.CBPAttachmentDetails && item?.CBPAttachmentDetails?.Selected == "1" && item?.CBPAttachmentDetails?.Finalize == "1" && item?.CBPAttachmentDetails?.Filed == "true") {
      return true;
    } else {
      return false;
    }
  }
  public selectCBP() {
    this.commonService.dissmissSpinnerLoader();
    if (Capacitor.isNativePlatform()) {
      this.fileUploadService.uploadFile(this.fileInput).then((data: any) => {
        if (data != "Error") {
          if (data != null || undefined) {
            this.fileUploadService.chekfileType = data;
            this.fileUploadService.imageSelect = this.fileUploadService.base64MimeType(
              this.fileUploadService.chekfileType,
            );
            if (this.fileUploadService.imageSelect == "image/jpeg") {
              const time = this.commonService.getUTCTime();
              this.fileName = `${this.getConcatedFileName()}.jpg`;
              this.file = data;
            } else {
              const filename = data.filename;
              this.file = data.base64;
              const filedot = filename.substr(filename.lastIndexOf(".") + 1);
              this.fileName = `${this.getConcatedFileName()}.${filedot}`;
            }
            this.enterTripDetailsService.uploadTripKitToFirebaseStorage(this.fileName, this.file, this.route.Sector).then(() => {
              const FilePath = this.fileName;
              const sector = this.route ? this.route.Sector : undefined;
              const SRID = this.route ? this.route.SRID : undefined;
              const legid = this.route ? this.route.legID : undefined;
              const crewBriefingAttachmentID = this.route ? this.route.CrewBriefingAttachmentID : undefined;
              this.enterTripDetailsService.finalizeCBP(SRID, crewBriefingAttachmentID, FilePath, sector, legid).then(() => {
                this.enterTripDetailsService.close()
                this.commonService.dissmissSpinnerLoader();
              });
            });
          }
        } else {
          this.commonService.dissmissSpinnerLoader();
        }
      })
    } else {
      this.fileInput.nativeElement.click();
    }
  }
  public refreshTripKit(item: any) {
    this.tripRouteService.reload(item.routeId, "tripKit", item.VendorID);
  }

  public flightfilechoose(fileInput: any) {
    this.commonService.showSpinnerLoader();
    this.fileUploadService
      .checkFileType(fileInput.target.files[0], 'file')
      .then((filevalid) => {
        if (filevalid) {
          if (fileInput.target.files && fileInput.target.files[0]) {
            const size = fileInput.target.files[0].size;
            if (size < 7000000) {
              this.CPBFileCheck = fileInput.target.files[0].name;
              this.AttachedfilenameCBP = fileInput.target.files[0].name;
              this.file = fileInput.target.files[0];
              const reader = new FileReader();
              reader.onload = (event: any) => {
                this.AttachdFile = event.target.result;
                this.commonService.dismissLoader();
              };
              reader.readAsDataURL(fileInput.target.files[0]);
            } else {
              this.fileUploadService.fileAlert(
                MessageConfig.FileSizeforAttachment
              );
              this.commonService.dismissLoader();
            }
          } else {
            this.fileUploadService.fileAlert('FDN');
          }
        }
        this.commonService.dismissLoader();
        fileInput.target.value = null;
      });
  }
  public selectFlightFile() {
    if (
      Capacitor.isNativePlatform()
    ) {
      this.selectFileForCordova();
    } else {
      // this.edit = true;
      this.fileInput1.nativeElement.click();
    }
  }
  public selectFileForCordova() {
    this.fileUploadService.uploadFile(this.fileInput).then((data: any) => {
      if (data != "Error") {
        if (data != null || undefined) {
          this.chekfileType = data;
          this.imageSelect = this.fileUploadService.base64MimeType(
            this.chekfileType,
          );
          if (this.imageSelect == "image/jpeg") {
            this.AttachedfilenameCBP = "FDN.jpg";
            this.CBPAttachFile = data.filename;
            this.AttachdFile = data;
            // this.attchedFileEvent();
          } else {
            if (data.size < 7000000) {
              this.AttachedfilenameCBP = data.filename;
              this.CBPAttachFile = data.filename;
              this.AttachdFile = data.base64;
              // this.attchedFileEvent();
            } else {
              this.fileUploadService.fileAlert(
                this.messageConfig.FileSizeforAttachment
              );
            }
          }
        }
      }
    });
  }
  public uploadInFirebase(){
    const legid = this.route.legId ? this.route.legId : this.route.legID
    const sector = this.route.sector ? this.route.sector : this.route.Sector
    const fileNameof = this.AttachedfilenameCBP.split('.').pop();
    let filename = `FlightPlan_${ this.tripID}_${legid}.${fileNameof}`;
    let counter = 0;
    this.commonService.showSpinnerLoader();
    if(this.getFLPattachment?.length > 0 || this.uploadedFiles?.length > 0){
      counter = counter + this.getFLPattachment.length;
      counter = counter + this.uploadedFiles.length;
    // Counter to keep track of file uploads
    filename = filename.split('.').shift()
    filename = `${filename}_${counter}`
    filename = filename + '.' + fileNameof
    }
    else{
    filename = filename
    }
    const storePath = `FlightPlanAttach/${this.commonService.uid}/${this.tripID}/${legid}/${filename}`
    this.fileUploadService
      .FirebaseBucketUpload(
        storePath,
        this.AttachdFile
      )
      .then((data: any) => {
        this.enterDetailApiService.submitFLPDocs(this.tripID,legid,sector,filename).then((res:any)=>{
          if(res == 'Success'){
            this.uploadedFiles.push({ name: filename, url: data });
            this.AttachedfilenameCBP = 'Attach-Documents';
            this.CPBFileCheck = '';
          }
        }).catch((erro)=>{
          this.commonService.dismissLoader();
        })
        this.commonService.dismissLoader();
      });

  }

  public downloadFLPUrl(fileUrl,filename) {
    this.firebaseService.openDoc(fileUrl,filename)
  }

  public downloadFLPfilename(route, flpAttachment) {
    this.firebaseService.downloadfileFrombucket(
      this.enterDetailApiService.getPath(
        'FlightPlanAttach',
        route.ServiceType,
        route.VendorID,
        route,
        this.tripID
      ),
      flpAttachment
    );
  }
}
