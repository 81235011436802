import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ApiTypes } from '@app/shared/constants/constant';
import { first } from 'rxjs/operators';
import { UtilService } from './util.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { AppUpdateService } from './app-update.service';
import { InterceptorService } from './interceptor.service';

@Injectable({
  providedIn: 'root',
})
export class ServerConnectService {
  public apiType = ApiTypes;
  private interceptorService: any;

  constructor(
    private https: HttpClient,
    private utilService: UtilService,
    private angularFireDatabase: AngularFireDatabase,
    private appUpdateService: AppUpdateService,
    private injector: Injector
  ) {
    this.interceptorService = this.injector.get(InterceptorService);
    this.appUpdateService.getUpdateConfig();
  }
  // connect to server direct
  public async connectToServer(
    type: number,
    url: string,
    param: string,
    showLoader: boolean,
    dismissLoader: boolean,
    tryAgain: boolean,
    serverType: string = 'api',
  ) {
    try {
      return await new Promise((resolve, reject) => {
        this.utilService.generateToken(url).then(() => {
          switch (type) {
            case this.apiType.POST:
              this.https
                .post(this.getActualUrl(url, serverType), param)
                .pipe(first())
                .subscribe(
                  (res: any) => {
                    resolve(res);
                  },
                  (error: any) => {
                    reject('Error');
                  }
                );
              break;
            case this.apiType.GET:
              this.https
                .get(this.getActualUrl(url, serverType))
                .pipe(first())
                .subscribe(
                  (res: any) => {
                    resolve(res);
                  },
                  (error: any) => { }
                );
              break;
            case this.apiType.DELETE:
              break;
            case this.apiType.PATCH:
              break;
            case this.apiType.PUT:
              break;
            default:
              break;
          }
        });
      });
    } catch (error) {
      return false;
    }
  }

  public getActualUrl(url: string, type: string) {
    if (!this.utilService.actualURL || this.utilService.actualURL == '') {
      this.appUpdateService.getUpdateConfig();
      setTimeout(() => {
        this.getActualUrl(url, type);
      }, 300)
    }
     else {
      switch (type) {
        case 'api/v1':
          return this.utilService.flightPlanningURL + url;
          break;
        case 'api':
          return this.utilService.actualURL + url;
          break;
        case 'assets':
        case 'velocityweather':
        default:
          return url;
      }
    }
  }
}
