import { Injectable } from '@angular/core';
import { ServerConnectService } from '@app/core/services/server-connect.service';
import { ApiTypes } from '@app/shared/constants/constant';
import { CommonService } from '@app/shared/services/common.service';
import { AircraftUriConfig } from '../setup-url-constant';
import { AircraftTotalStructure } from './aircraft-constant-config';
import firebase from 'firebase';
@Injectable({
  providedIn: 'root',
})
export class AircraftServiceService {
  private httpRequestType = ApiTypes;
  public loaderPresent: boolean = false;
  public loading: any;
  public draftAircraftID = null;
  public savedAircraftID = null;
  private userRef: any;

  constructor(
    private commonService: CommonService,
    private serverConnectService: ServerConnectService
  ) {}

  public getclientAircraft() {
    const databody =
      'UserEmailID=' +
      this.commonService.userName +
      '&UserPass=' +
      this.commonService.password +
      '&enct=1';
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          AircraftUriConfig.getAircraftSettings,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res);
        });
    });
  }

  public DeleteAircraftDetails(id, regdNo, flag) {
    const databody = 'id=' + id + '&regdNo=' + regdNo + '&action=' + flag;
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          AircraftUriConfig.deleteAircraft,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res);
        });
    });
  }

  public LoadingDismiss() {
    if (this.loaderPresent && this.loading) {
      this.loading.dismiss();
      this.loaderPresent = false;
    }
  }

  public getListOfAircraftManufacturerFromAssets() {
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.GET,
          'assets/manufacture.json',
          '',
          false,
          false,
          false,
          'assets'
        )
        .then(
          (staData: any) => {
            const field = 'makename';
            staData.data.sort((a, b) =>
              (a[field] || '')
                .toString()
                .localeCompare((b[field] || '').toString())
            );
            resolve(staData.data);
          },
          (err) => {
            resolve('Error');
          }
        );
    });
  }

  public CheckAircraftTailNumber(CountryPrefix, Registration) {
    const databody =
      'Registration=' +
      Registration +
      '&registerCountryPrefix=' +
      JSON.stringify(CountryPrefix);
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          AircraftUriConfig.checkAircraftTailNumber,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res);
        });
    });
  }

  public getAircraftDetailsbyUUID(allSendData: AircraftTotalStructure) {
    const databody =
      'UserEmailID=' +
      this.commonService.userName +
      '&UserPass=' +
      this.commonService.password +
      '&enct=1&airtype=' +
      allSendData.AircraftData.basicAircraft.EngineName +
      '&engineName=' +
      allSendData.AircraftData.basicAircraft.AircraftType +
      '&UUID=' +
      allSendData.AircraftData.basicAircraft.UUID;
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          AircraftUriConfig.getAircraftDetailsUID,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res);
        });
    });
  }

  // for upload aircraft details
  public uploadAircraftDetails(
    allSendData: AircraftTotalStructure,
    saveAircraftID
  ) {
    const basicinfo = JSON.stringify(allSendData.AircraftData.basicAircraft);
    const aircraftWeight = JSON.stringify(
      allSendData.AircraftData.weightAircrft
    );
    const climbProfiles = JSON.stringify(
      allSendData.AircraftData.ClimbProfiles
    );
    const desecntProfiles = JSON.stringify(
      allSendData.AircraftData.DescentProfiles
    );
    const cruiseProfiles = JSON.stringify(
      allSendData.AircraftData.cruiseProfileData
    );
    const fuelDetails = JSON.stringify(allSendData.AircraftData.fuelAircraft);
    const Equipment = JSON.stringify(
      allSendData.AircraftData.equipmentAircraft
    );
    const performancestring = JSON.stringify(
      allSendData.AircraftData.avionicsAircraft
    );
    const charterRule = JSON.stringify(
      allSendData.AircraftData.charterRule
    );
    const databody =
      'basicinfo=' +
      basicinfo +
      '&aircraftWeight=' +
      aircraftWeight +
      '&Equipment=' +
      Equipment +
      '&fuelDetails=' +
      fuelDetails +
      '&performancestring=' +
      performancestring +
      '&AircraftID=' +
      saveAircraftID +
      '&cruiseProfiles=' +
      cruiseProfiles +
      '&desecntProfiles=' +
      desecntProfiles +
      '&climbProfiles=' +
      climbProfiles +
      '&charterRules=' +
      charterRule;
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          AircraftUriConfig.uploadAircraftDetails,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res);
        });
    });
  }

  // for get new aircraft details ID
  public getNewAircraftDetailsByID(id) {
    const databody =
      'UserEmailID=' +
      this.commonService.userName +
      '&UserPass=' +
      this.commonService.password +
      '&enct=1&SaveID=' +
      id;

    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.httpRequestType.POST,
          AircraftUriConfig.getAircraftNewData,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res);
        });
    });
  }

  public setDraftAircraftId(id) {
    this.draftAircraftID = id;
  }
  public setSaveAircraftID(saveAircraftID) {
    this.savedAircraftID = saveAircraftID;
  }
  public getSaveAircraftID() {
    return new Promise((resolve) => {
      resolve(this.savedAircraftID);
    });
  }

  //  for Aircraft attachment get
  public loadpath(saveAircraftID) {
    return new Promise((resolve, reject) => {
      this.userRef = firebase
        .database()
        .ref(
          '/AircraftAttachment/' + this.commonService.uid + '/' + saveAircraftID
        );
      this.userRef.on('value', (snapshot) => {
        if (snapshot.val() != null) {
          const data = snapshot.val();
          if (data.Status == 'Failed') {
            this.commonService.showErrorAlert().then((res: boolean) => {
              if (res) {
                this.loadpath(saveAircraftID);
              } else {
                reject();
              }
            });
          } else {
            resolve(snapshot.val());
          }
        } else {
          resolve(snapshot.val());
        }
      });
    });
  }
  // for firebase bucket upload
  public FirebaseBucketUpload(PathToUpload: string, fileToUpload: any) {
    return new Promise((resolve) => {
      const storageref = firebase.storage().ref(PathToUpload);
      const task = storageref
        .putString(fileToUpload, 'data_url')
        .then((snapshot) => {
          snapshot.ref.getDownloadURL().then((data) => {
            resolve(data);
          });
        });
    });
  }

  public uploadtoFirebase(SendAircraftData, saveAircraftID) {
    return firebase
      .database()
      .ref(
        '/AircraftAttachment/' +
          this.commonService.uid +
          '/' +
          saveAircraftID +
          '/'
      )
      .push(SendAircraftData);
  }
  public updateFirebase(SendAircraftData, saveAircraftID, key) {
    return firebase
      .database()
      .ref()
      .child(
        '/AircraftAttachment/' +
          this.commonService.uid +
          '/' +
          saveAircraftID +
          '/' +
          key +
          '/'
      )
      .update(SendAircraftData);
  }
}
