export class Users {
  public userName: string;
  public userEmail: any;
  public userPassword: any;
  public userConfirmPassword: any;
  public userRole: any;
}

export enum userRoles {
  Admin  = 'Admin',
  AccountsManager = 'Accounts Manager',
  OperationsManager = 'Operations Manager',
  Pilot = 'Pilot',
  CabinCrew = 'Cabin Crew',
  OpsAgent = 'Operations Agent'
}
