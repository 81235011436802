import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@app/shared/services/common.service';
@Injectable({
  providedIn: 'root',
})
export class TripTabService {
  public tripId: any;
  public operatorUid;
  constructor(public router: Router, private commonService: CommonService) { }
  public validate(data, customMessage?: any) {
    if (
      /^.+$/.test(data)
    ) {
      return {
        isValid: true,
        message: '',
      };
    } else {
      return {
        isValid: false,
        message: 'Enter valid ' + customMessage,
      };
    }
  }
  public async contactNumberLoad(service) {
    if (service.handlerdetails) {
      const ContactNumber = this.commonService.getContactNumberFormate(
        service.handlerdetails[0].HandlerCallingCode,
        service.handlerdetails[0].Handlernumber
      );
      service.handlerdetails[0].Handlernumber = ContactNumber;
    }
  }
  public backPageActivity(valueBack: any, type: any, SRID: any) {
    const queryParams = {
      valueBackSuccess: valueBack,
      pageType: type
    };
    this.router.navigate([
      `trips/trips-tab/${SRID}/trip-activity`,
    ], { queryParams });
  }
  public checkValidation(event: any) {
    const regex = new RegExp('^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$');
    if (regex.test(event.target.value)) {
    } else {
      event.target.value = '';
    }
  }
}
