import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { TripActivityService } from '@app/trips/services/trip-activity.service';
import { FirebaseDataService } from '@app/shared/services/firebase-data.service';

@Component({
  selector: 'app-charter-notifications',
  templateUrl: './charter-notifications.page.html',
  styleUrls: ['./charter-notifications.page.scss'],
})
export class CharterNotificationsPage implements OnInit {
  public slectedTripId:any;
  public pageType:string='charterInfo'
  constructor(private tripActivityService:TripActivityService,private activatedRoute:ActivatedRoute,
    private router:Router,
    private firebaseDataService:FirebaseDataService) {
    this.activatedRoute.queryParamMap.subscribe(() => {
      if (this.router.getCurrentNavigation().extras.state) {
        const params = this.router.getCurrentNavigation().extras.state;
        this.slectedTripId = params.tripID;
      }
    });
   }

  public ngOnInit() {
    this.tripActivityService.sendselectedTripTotalCountValue(this.slectedTripId);
  }
  public ionViewDidEnter() {
    this.firebaseDataService.markNotificationAsRead(this.slectedTripId)
  }

  public ngOnDestroy() {
    this.firebaseDataService.CharterNotificationsUnread.next(null);
    this.firebaseDataService.$NotificationsRef.next(null);
  }

}
