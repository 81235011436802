import { CommonService } from '@app/shared/services/common.service';
import { Injectable } from '@angular/core';
import { FirebaseDataService } from './firebase-data.service';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  constructor(private fbDatabase: FirebaseDataService, private commonService: CommonService) { }
  public userconvertUnit(sgravity, value) {
    let conversion = "";
    switch (this.commonService.fuelUnit) {
      case "LTR":
        conversion = String((Number(value) * 3.785).toFixed(3));
        break;
      case "USG":
        conversion = value;
        break;
      case "Pound":
        conversion = String((((Number(value) * 3.785) * sgravity) * 2.2).toFixed(3));
        break;
      case "KG":
        conversion = String((((Number(value) * 3.785) * sgravity)).toFixed(3));
        break;
      case "MT":
        conversion = String((((Number(value) * 3.785) * sgravity) / 1000).toFixed(3));
        break;
      default:
        conversion = value;
        break;
    }
    return conversion;
  }

  public vendorconvertUnit(sgravity, value) {
    let conversion = "";
    switch (this.commonService.fuelUnit) {
      case "LTR":
        conversion = String((Number(value) * 3.785).toFixed(3));
        break;
      case "USG":
        conversion = value;
        break;
      case "Pound":
        conversion = String((((Number(value) * 3.785) * sgravity) * 2.2).toFixed(3));
        break;
      case "KG":
        conversion = String((((Number(value) * 3.785) * sgravity)).toFixed(3));
        break;
      case "MT":
        conversion = String((((Number(value) * 3.785) * sgravity) / 1000).toFixed(3));
        break;
      default:
        conversion = value;
        break;
    }
    return conversion;
  }
  // get the currency in the backend ie ts file
  public convertCurrency(price, type) {
    const currencyID = "currencyId";
    if (price == "" || Number(price) == 0 || price == null) {
      return "";
    } else if (this.commonService.userCurrency[currencyID] == "USD") {
      return price;
    } else {
      let returnprice = "";
      if (type == "user") {
        if (this.fbDatabase.currencyPrices[this.commonService.userCurrency[currencyID]]) {
          returnprice = String((Number(price)
            * this.fbDatabase.currencyPrices[this.commonService.userCurrency[currencyID]]).toFixed(2));
        } else {
          returnprice = "";
        }
      } else {
        if (this.fbDatabase.currencyPrices[this.commonService.userCurrency[currencyID]]) {
          returnprice = String((Number(price)
            / this.fbDatabase.currencyPrices[this.commonService.userCurrency[currencyID]]).toFixed(2));
        } else {
          returnprice = "";
        }
      }
      return returnprice;
    }
  }
  // convert the platform currency to user currency
  // received price USD and currency is selected currency by the user in the setup
  public vendorConvertCurrency(price, currency, type) {
    // if any wrong data it will return the given price
    if (price != "" && price != 0 && price) {
      if (currency == "USD") {
        return price;
      } else {
        // if user is requestibg for his price the converting the usd to his currency
        if (type == "user") {
          // if price not USD then it will send the price as per the user currency as on 23.11.2018
          const userPrice = Number(price);
          return userPrice.toFixed(2);
        } else { // if platform is requestibg for his price the converting the usd ex.to send the data
          // if vendopr converting the price to platform price i.e USD
          // making the calculation and sending only 14digit after the currency conversion after decimal
          //           this.fbDatabase.currencyPrices.subscribe(data=>{
          //           });
          const platformPrice = (Number(price) / this.fbDatabase.currencyPrices[currency]).toFixed(14);
          return platformPrice;
        }
      }
    } else {
      // (to get currency string for zero price)
      return price == 0 ? price + "" : "";
    }
  }
}
