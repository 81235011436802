import { Injectable } from '@angular/core';
import { FirebaseDataService } from './firebase-data.service';
import { Rating } from '@app/trips/trips-tab/model/model.index';
import firebase from 'firebase';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class RatingService {
  private ratings: { [key: string]: { [key: string]: Rating[] } } = {};
  constructor() {
    this.loadRatings();
   }

   public async loadRatings() {
    setTimeout(async () => {
    const ratingsRef = firebase.database().ref('/ReviewAndRating');
    const snapshot = await ratingsRef.once('value');
    this.ratings = snapshot.val() || {};

    }, 200);
  }


  public getAverageRatingForName(name: string): number {
    const matchingRatings: Rating[] = [];
    for (const tripId of Object.keys(this.ratings)) {
      const ratings = this.ratings[tripId];
      for (const userId of Object.keys(ratings)) {
        const userRatings = ratings[userId];
        for (const rating of userRatings) {
          if (rating.ratedtoUid === name) {
            matchingRatings.push(rating);
          }
        }
      }
    }

    if (matchingRatings.length > 0) {
      const totalRating = matchingRatings.reduce((sum, r) => sum + r.rating, 0);
      const rating = totalRating / matchingRatings.length;
      return parseFloat(rating.toFixed(2))
    } else {
      return 0; // No matching ratings found for the specified name
    }
  }
}
