import { FirebaseDataService } from './../../../services/firebase-data.service';
import { Component, OnInit } from '@angular/core';
import { CsvExcelData } from '@app/shared/models/tax.model';
import { CommonService } from '@app/shared/services/common.service';
import { ModalController, NavParams } from '@ionic/angular';
import { MessageConfig }  from '@app/shared/constants/constant';
@Component({
  selector: 'app-csv-file-details',
  templateUrl: './csv-file-details.component.html',
  styleUrls: ['./csv-file-details.component.scss'],
})
export class CsvFileDetailsComponent implements OnInit {
  public csvDetails = new CsvExcelData();
  public type: any;
  public configMessage = MessageConfig;
  constructor(private commonService: CommonService, public navParams: NavParams, private modalcntrl: ModalController, private firebaseDataService: FirebaseDataService) { }

  public ngOnInit() {
    this.type = this.navParams.get('serviceType');
  }
  public dismiss() {
    this.modalcntrl.dismiss();
  }
  public CsvDownload(fileName) {
    let storePath;
    switch(this.type) {
      case 'fuel':
        storePath = `FuelExel/${this.commonService.uid}/`;
      break;
      case 'handling':
        storePath = `HandlingExel/${this.commonService.uid}/`;
      break;
      case 'Taxes':
        storePath = `TaxesExcel/${this.commonService.uid}/`;
      break;
      case 'landing':
        storePath = `LandingExcel/${this.commonService.uid}/`;
      break;
      case 'slot':
        storePath = `SlotExcel/${this.commonService.uid}/`;
      break;
      case 'ovf-permits':
        storePath = `OverflightExcel/${this.commonService.uid}/`;
      break;
      default:
        this.commonService.showFileAlert(this.configMessage.InvalidFilePath);
        return;
    }
    this.firebaseDataService.downloadfileFrombucket(
      storePath,
      fileName,
    );
  }
  public selectFile() {
    this.dismiss();
  }
}
