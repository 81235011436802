import { ChatComponent } from './chat/chat.component';
import { DownloadComponent } from './download/download.component';
import { SkeltonComponent } from './skelton/skelton.component';
import { FilterListComponent } from './filter-list/filter-list.component';
import { AccordionComponent } from './accordion/accordion.component';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BadgeCountComponent } from './badge-count/badge-count.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { SuggestStationComponent } from './suggest-station/suggest-station/suggest-station.component';
import { ExcelCsvPageComponent } from './excel-csv-page/excel-csv-page.component';
import { ExcelCsvUploadComponent } from './excel-csv-upload/excel-csv-upload.component';
import { CsvFileListComponent } from './csv-file-list/csv-file-list/csv-file-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PriceRateBreakupComponent } from './price-rate-breakup/price-rate-breakup.component';
import { PipesModule } from '../pipes/pipes.module';
import { AnimatedHeaderComponent } from './animated-header/animated-header.component';
import { FlightPlanComponent } from './flight-plan/flight-plan.component';
import { CsvFileDetailsComponent } from './csv-file-list/csv-file-details/csv-file-details.component';
import { MbscModule } from '@mobiscroll/angular';
import { SharedDirectiveModule } from '../directives/shared-directive.module';
import { LinkshareComponent } from '../linkshare/linkshare.component';
import { CustomSpinnerComponent } from './custom-spinner/custom-spinner.component';
@NgModule({
  imports: [IonicModule, CommonModule, FormsModule, PipesModule,ReactiveFormsModule,SharedDirectiveModule],
  declarations: [
    AccordionComponent,
    FilterListComponent,
    BadgeCountComponent,
    SkeltonComponent,
    DownloadComponent,
    NotificationsComponent,
    SuggestStationComponent,
    PriceRateBreakupComponent,
    ExcelCsvPageComponent,
    ExcelCsvUploadComponent,
    CsvFileListComponent,
    AnimatedHeaderComponent,
    ChatComponent,
    FlightPlanComponent,
    CsvFileDetailsComponent,
    LinkshareComponent,
    CustomSpinnerComponent

  ],
  exports: [
    AccordionComponent,
    FilterListComponent,
    BadgeCountComponent,
    SkeltonComponent,
    DownloadComponent,
    NotificationsComponent,
    SuggestStationComponent,
    PriceRateBreakupComponent,
    ExcelCsvPageComponent,
    ExcelCsvUploadComponent,
    CsvFileListComponent,
    AnimatedHeaderComponent,
    ChatComponent,
    FlightPlanComponent,
    MbscModule,
    CsvFileDetailsComponent,
    LinkshareComponent,
    CustomSpinnerComponent
  ],
})
export class SharedComponentModule {}
