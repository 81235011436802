import { Subscription } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { SideMenuService } from '@app/right-menu/services/side-menu.service';
import { CommonService } from '@app/shared/services/common.service';
import { DownloadService } from '@app/shared/services/download.service';
import { TimerService } from '@app/shared/services/timer.service';
import { SimpleTimer } from 'ng2-simple-timer';
import { ActivityService } from '@app/trips/services/activity.service';

@Component({
  selector: 'app-trip-info-downloads',
  templateUrl: './trip-downloads.component.html',
  styleUrls: ['./trip-downloads.component.scss'],
})
export class TripDownloadsComponent implements OnInit {
  public tripData: any;
  public moreSettings: boolean = false;
  public show: boolean = false;
  public maxProgressvalue = 100;
  public progress1: number = null;
  public takeOfToLanding:any;
  public progress: number = null;
  public Subscription:Subscription;
  @Input() public tripInformation: any;
  constructor(private sidemenuService: SideMenuService, private timer: SimpleTimer,
     private commonService: CommonService,
    private timerService: TimerService,
    private downloadService: DownloadService,
    private activityService:ActivityService) {
    this.getData();
  }
public getData(){
  this.Subscription =this.sidemenuService.tripData.subscribe((res: any) => {
    this.tripData = res;
    this.tripData.legInfo.forEach((legDetails) => {
      legDetails.downloadOpen = false;
      if (legDetails.legStage != "Closed") {
        this.timer.newTimer(String(legDetails.legid), 1);
        legDetails.showStatus = this.getStatus(legDetails.legStage);
        this.timer.subscribe(String(legDetails.legid), () => {
          legDetails.progress = this.progressbarTimer(
            legDetails.progressDate,
            this.tripData.dateCreated,
          );
          this.progress1 = this.progressbarTimer(
            legDetails.ActualLanding,
            legDetails.ActualTakeoff
          );
         this.takeOfToLanding = this.timerService.timeDiffInSecond(
          legDetails.ActualLanding,
            this.timerService.getTimeNow(),
          );

          legDetails.countdownTime--;
        });
      } else {
        legDetails.progress = 100;
        legDetails.countdownTime = 0;
        legDetails.showStatus = this.getStatus(legDetails.legStage);

      }
    });
    this.downloadService.permisionToShow(this.tripData, 'R').then((access: any) => {
      this.show = access;
    });
  });
}
  public ngOnInit() {
    this.reloadActivity();
   }
  public airportname(value) {
    value = value.substr(5);
    value = value.split(",").join(",  ");
    return value;
  }
  public getLegStatus(tripdatalegStage) {
    this.reorderDownloads();
    const leStage =
      tripdatalegStage == "Processing"
        ? "Pending"
        : tripdatalegStage == "Confirmed"
          ? "Upcoming"
          : tripdatalegStage == "Inprogressstarting"
            ? "In Progress"
            : tripdatalegStage == "Inprogressfinishing"
              ? "Inflight"
              : tripdatalegStage == "Inprogressfinished"
                ? "Landed"
                : tripdatalegStage;
    return leStage;
  }
  public downloadPdf(download, tripdata) {
    const station = tripdata.sector.split("-")[0];
    this.downloadService.download(download.name,
      this.tripData.tripId,
      download.visible,
      this.tripData.status,
      tripdata.legid,
      tripdata.sector,
      download.path,
      download.fileName,
      station);
  }
  public getStatus(legStatus: string): string {
    let status: string;
    switch (legStatus) {
      case "Closed":
        status = "Trip Completed";
        break;
      case "Processing":
        status =
          this.commonService.roleId == 3
            ? "Awaiting Confirmation"
            : "Awaiting Confirmation";
        break;
      case "Confirmed":
        status = "to takeoff";
        break;
      case "Inprogressstarting":
        status = "to takeoff";
        break;
      case "Inprogressfinishing":
        status = "to land";
        break;
      case "Inprogressfinished":
        status = "to complete";
        break;
    }
    return status;
  }
  public stopTimer() {
    const timersID: string[] = this.timer.getTimer();
    timersID.forEach((element) => {
      this.timer.delTimer(element);
    });
  }
  public progressbarTimer(departdatesecond, createdatesecond) {
    return (
      (this.timerService.timediffinSecond(
        this.timerService.getTimeNow(),
        createdatesecond,
      ) /
        this.timerService.timediffinSecond(
          departdatesecond,
          createdatesecond,
        )) *
      100
    );
  }

  public reloadActivity(){
    this.Subscription = this.activityService.reloadActivity.subscribe(() => {
      this.getData();
    });
  }
    public ngOnDestroy() {
      this.Subscription.unsubscribe();
    }

    public reorderDownloads() {
      this.tripData?.legInfo.forEach(tripdata => {
        const tripkitIndex = tripdata.downloads.findIndex(download => download.name === 'Trip Kit');
        if (tripkitIndex !== -1) {
        const tripkitDownload = tripdata.downloads.splice(tripkitIndex, 1)[0];
        tripdata.downloads.unshift(tripkitDownload);
        }
      });
    }
}
