import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentage'
})
export class PercentagePipe implements PipeTransform {

 public transform(basePrice: number, percentage: number): number {
    const basePriceNum = typeof basePrice === 'string' ? parseFloat(basePrice) : basePrice;
    const percentageNum = typeof percentage === 'string' ? parseFloat(percentage) : percentage;
    // Check if the conversion resulted in valid numbers
    if (isNaN(basePriceNum) || isNaN(percentageNum)) {
      return 0;
    }
    const price = Math.round(basePriceNum * (percentageNum / 100));
    return price;
  }

}
