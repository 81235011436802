export interface Country {
    CountryID: string;
    CountryCode: string;
    CountryName: string;
    OverflightPermitRequiredorNot: string;
}
export interface CurrencyCode {
    currencyId: string;
    name: string;
}
export interface ClientDetails {
    ClientID: string;
    UserID: string;
    ClientName: string;
    ClientInvoiceAddressline1: string;
    ClientInvoiceAddressline2: string;
    ClientInvoiceAddressline3: string;
    ClientContactNo: string;
    ClientOperationContactName: string;
    CreatedDate: string;
    ClientOperationContactNo: string;
    ClientOperationContactEmailID: string;
    ClientAccDeptContactName: string;
    ClientAccDeptContactNo: string;
    ClientAccDeptContactEmailID: string;
    Verified: string;
    flag: string;
    logo: string;
    Timezone: string;
    name: string;
    CompanyName: string;
    CompanyDetails: string;
    PAN_No: string;
    Service_TaxNo: string;
    PaymentType: string;
    NumberOfAircraft: string;
    Description: string;
    PrivacyForEmail: string;
    PrivacyForNotification: string;
    CompanyEmail: string;
    CompanyAddress: string;
    fuelUnit: string;
    Pincode: string;
    State: string;
    Country: Country;
    Documentpath: string;
    filenametitle: string;
    clientcallingCodes: string;
    callingCodeOpscontact: string;
    AccountcallingCodes: string;
    STDID?: any;
    currencyCode: CurrencyCode;
}

export interface Usersettings {
    Email_Flag: string;
    Push_Flag: string;
}

export interface User {
    UserID: string;
    EmailID: string;
    Password: string;
    RoleID: string;
    Status: string;
    CreatedDate: string;
    LastVisit: string;
    Forget_link_time: string;
    isTripSupportCreated: string;
    VGID: string;
    CGID: string;
    VerificationCode: string;
    first_failed_login: string;
    failed_login_count: string;
    PinModifiedDate: string;
    firebase_uid: string;
    refresh_token: string;
    device_token: string;
    flag: string;
    registrationType: string;
    clientDetails?: ClientDetails;
    usersettings?: Usersettings;
    permissions?:string;
}

export enum ModulePermissions {
    Estimates = 'Estimates',
    Trips = 'Trips',
    Invoices = "Invoices",
    Profile = "Profile",
    Aircraft = "Aircraft",
    Passengers = "Passengers",
    Crew = "Crew",
    Accounting = "Accounting",
    Support = "Support",
    Sidemenu = "Sidemenu",
    Passbook = "Passbook",
    Tripsinfo = "Tripsinfo",
    SetupRateCard ="SetupRateCard",
    QuickQuotes='QuickQuotes',
    Requests='Requests',
    ServiceProvider="ServiceProviders",
    SidemenuAcDocuments = "SidemenuAcDocuments",
    users = "Users",
}