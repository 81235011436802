import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription, fromEvent } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  private onlineStatus$ = new BehaviorSubject<boolean>(navigator.onLine);
  private onlineEventSubscription: Subscription;
  private offlineEventSubscription: Subscription;

  constructor() {
    this.initializeNetworkEvents();
  }

  public initializeNetworkEvents() {
    this.onlineEventSubscription = fromEvent(window, 'online').subscribe(() => this.updateOnlineStatus(true));
    this.offlineEventSubscription = fromEvent(window, 'offline').subscribe(() => this.updateOnlineStatus(false));
  }

  public updateOnlineStatus(status: boolean) {
    this.onlineStatus$.next(status);
  }

  public getNetworkStatus() {
    return this.onlineStatus$.asObservable();
  }

  public ngOnDestroy() {
    if (this.onlineEventSubscription) {
      this.onlineEventSubscription.unsubscribe();
    }
    if (this.offlineEventSubscription) {
      this.offlineEventSubscription.unsubscribe();
    }
  }

}
