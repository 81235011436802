import { Directive, ElementRef, Renderer2 } from '@angular/core';
@Directive({
  selector: '[focuser]'
})
export class FocuserDirective {

  constructor(public elementRef: ElementRef, public renderer: Renderer2) { }
  public ngAfterViewInit() {
    const searchInput = this.elementRef.nativeElement.querySelector('input');
    if(searchInput){
      setTimeout(() => {
        this.renderer.selectRootElement(searchInput).setFocus();
      }, 600);
    } else {}
  }
}
