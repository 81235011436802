import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-taxes-excel-page',
  templateUrl: './excel-csv-page.component.html',
  styleUrls: ['./excel-csv-page.component.scss'],
})
export class ExcelCsvPageComponent implements OnInit {
  public type: any;
  public pageTitle: any;
  constructor(public modalCtrl: ModalController, public navParams: NavParams,) { }

  public ngOnInit() {
   this.type = this.navParams.get('pageType');
  }
  public dismiss() {
    this.modalCtrl.dismiss();
  }
}
