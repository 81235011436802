import { Pipe, PipeTransform } from '@angular/core';
import { DateFormate } from '@app/shared/constants/constant';
import { CommonService } from '@app/shared/services/common.service';
import { tz } from 'moment';
@Pipe({
  name: 'changeTime',
})
export class DateFilterPipe implements PipeTransform {
  // required parameters date in utc format,type is utc or local and timezone
  constructor(private commonService: CommonService) { }
  public transform(date?: any, type?: any, timezone?: any) {
    // if local converting to the depart airport timezone format and diplaying
    // with user selected format if found
    if (type != 'UTC') {
      if (this.commonService.userFormat.TimeFormat != '') {
        const dateToTimezone =
          tz(date, timezone)
            .format(this.commonService.userFormat.TimeFormat);
        return date && date.startsWith('on') ? 'on ' + dateToTimezone : dateToTimezone;
      } else {
        const dateToTimezone =
          tz(date, timezone)
            .format(DateFormate.dispalyMinDate);
        return date && date.startsWith('on') ? 'on ' + dateToTimezone : dateToTimezone;
      }
    } else {
      const dateToTimezone =
        tz(date, timezone)
          .utc()
          .format(DateFormate.displayDateMinFormate) + 'Z';
      return date && date.startsWith('on') ? 'on ' + dateToTimezone : dateToTimezone;
    }
  }
}
