import { AuthSessionService } from '@app/core/services/auth-session.service';
import { Component, OnInit, Input } from '@angular/core';
import { SideMenuService } from '@app/right-menu/services/side-menu.service';
import { DownloadService } from '@app/shared/services/download.service';
import { TripService } from '@app/trips/services/trip.service';

@Component({
  selector: 'app-trip-info',
  templateUrl: './trip-info.component.html',
  styleUrls: ['./trip-info.component.scss'],
})
export class TripInfoComponent implements OnInit {
  public tripDataDetails: any;
  public status: any;
  public permissionAccess: boolean = false;
  @Input() public tripInformation: any;
  constructor(
    private sidemenuService: SideMenuService,
    public downloadService: DownloadService,
    public authSessionService: AuthSessionService, private tripService: TripService) {
    this.sidemenuService.tripData.subscribe((res: any) => {
      this.tripDataDetails = res;
      this.downloadService.permisionToShow(this.tripDataDetails, 'R').then((access: any) => {
        this.permissionAccess = access;
      });
    });
  }

  public ngOnInit() { }

  public disableTripStatusDownloadBtn(tripData: any) {
    let enableDownloadOpt: boolean = false;
    if (tripData.name == "TCS") {
      return enableDownloadOpt = this.tripInformation.firebaseTripStatus == "Pending";
    } else if (tripData.name == "TCE") {
      return enableDownloadOpt = false;
    } else {
      if (tripData.fileName == "") {
        return enableDownloadOpt = true;
      }
    }
  }

  public downloadPdf(name: string, path: string, download: string, filename: string) {
    this.downloadService.download(name,
      this.tripDataDetails.tripId,
      download,
      this.tripDataDetails.status,
      null,
      "",
      path,
      filename);
  }


}
