import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MessageConfig }  from '@app/shared/constants/constant';
import { CsvExcelFile ,HandlingExcelFile} from '@app/shared/models/tax.model';
import { CommonService } from '@app/shared/services/common.service';
import { FileUploadServiceService } from '@app/shared/services/file-upload-service.service';
import { TaxCsvServiceService } from '@app/shared/services/tax-csv-service.service';
import { AttachmentName } from '@app/trips/trips-tab/constants/constant-message';
import { Platform } from '@ionic/angular';
import { CsvFileListComponent } from '../csv-file-list/csv-file-list/csv-file-list.component';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { FirebaseDataService } from '@app/shared/services/firebase-data.service';
import { CsvFileService } from './../../services/csv-file.service';
import { Capacitor } from '@capacitor/core';

declare var cordova: any;
@Component({
  selector: 'app-csv-excel-upload',
  templateUrl: './excel-csv-upload.component.html',
  styleUrls: ['./excel-csv-upload.component.scss'],
})
export class ExcelCsvUploadComponent implements OnInit {
  @ViewChild("csvExcelElement") public fileInput: ElementRef;
  @ViewChild(CsvFileListComponent) public csvFileList: CsvFileListComponent;
  @Input() public serviceType: any;
  public type: any;
  public csvExcelFile = new CsvExcelFile();
  public handlingExcelFile = new HandlingExcelFile();
  public AttachmentName = new AttachmentName();
  public checkFileType: any;
  public imageSelect: any;
  public uploadFilename: string;
  public CSVFileUpload: string = "CSV file Upload";
  public listComponent: boolean = true;
  public configMessage = MessageConfig;
  public filePath: string;
  public filename: string;
  public CsvSampleFilepath: string;
  constructor(
    public commonService: CommonService,
    public taxCsvService: TaxCsvServiceService,
    public fileUploadServiceProvider: FileUploadServiceService,
    public platform: Platform,
    public https: HttpClient,
    private fbService: FirebaseDataService,
    private csvFileService: CsvFileService,
    private iab: InAppBrowser
  ) {
    this.servicesLoad();
  }

  public ngOnInit() {
    this.type = this.serviceType;
    this.listComponent = true;
  }
  public servicesLoad() {
    this.AttachmentName.DateFormate = this.commonService.getCurrentDate(
      new Date(),
      "HHmmss",
    );
    this.AttachmentName.FileNameConcatenate = [
      this.AttachmentName.DateFormate,
    ].join("_");
  }

  public selectFile() {
    if (Capacitor.isNativePlatform()) {
      this.selectFileForCordova();
    } else {
      this.fileInput.nativeElement.click();
    }
  }

  public fileChoose(event: any, serviceType?: any) {
    this.commonService.showSpinnerLoader();
    switch (serviceType) {
      case 'fuel':
      case 'handling':
      case 'Taxes':
      case 'ovf-permits':
      case 'landing':
      case 'slot':
        this.chooseFileByService(event);
      break;
      default:
      break;
    }
  }
  public chooseFileByService(event) {
    this.fileUploadServiceProvider
      .checkFilesType(event.target.files[0], "file")
      .then((fileValid) => {
        if (fileValid) {
          if (event.target.files && event.target.files[0]) {
            const size = event.target.files[0].size;
            if (size < 7000000) {
              this.csvExcelFile.selectedFileName = event.target.files[0].name;
              this.csvExcelFile.selectedFileName = this.csvExcelFile.selectedFileName
                .split(" ")
                .join("");
              const tReader = new FileReader();
              tReader.onload = (env: any) => {
                this.csvExcelFile.file = env.target.result;
                this.commonService.dismissLoader();
              };
              tReader.readAsDataURL(event.target.files[0]);
            } else {
              this.commonService.showFileAlert(this.configMessage.FileSizeforAttachment);
              this.commonService.dismissLoader();
            }
          }
        } else {
          this.commonService.showFileAlert("csv file.");
          this.commonService.dismissLoader();
        }
        event.target.value = null;
      });
  }
  public selectFileForCordova() {
    this.commonService.showSpinnerLoader();
    const allowedFileTypes = ["csv", "xls", "xlsx"];
    switch(this.type) {
      case 'fuel':
      case 'handling':
      case 'Taxes':
      case 'ovf-permits':
      case 'landing':
      case 'slot':
        (async () => {
          const file = await (window as any).chooser.getFile();
          const response = await fetch(file.dataURI);
          const blob = await response.blob();
          const size = blob.size;
          const startsWithSpecialChar = /^[^\w\s]/.test(file.name);
          const fileExtension = file.name.split('.').pop()?.toLowerCase();
          const isValidFileType = fileExtension && allowedFileTypes.includes(fileExtension);
          if (isValidFileType) {
            if (startsWithSpecialChar) {
              this.fileUploadServiceProvider.fileAlert("Invalid file name.");
              this.commonService.dismissLoader();
            } else if (size < 7000000) {
              this.csvExcelFile.selectedFileName = file.name;
              this.csvExcelFile.file = file.dataURI;
              this.commonService.dismissLoader();
            } else {
              this.fileUploadServiceProvider.fileAlert(this.configMessage.FileSizeforAttachment);
              this.commonService.dismissLoader();
            }
          } else {
            this.fileUploadServiceProvider.fileAlert("Invalid file type. Please select a CSV, XLS, or XLSX file.");
            this.commonService.dismissLoader();
          }
        })();
        break;
      default:
        break;
    }
    this.commonService.dismissLoader();
  }
  public upload() {
    this.commonService.showSpinnerLoader();
    this.AttachmentName.ExtentionSplit = this.csvExcelFile.selectedFileName
      .split(".")
      .pop();
    const fileName = this.csvExcelFile.selectedFileName
      .split(".")
      .shift();
    this.AttachmentName.FilePath = `${fileName}${this.AttachmentName.FileNameConcatenate}.${this.AttachmentName.ExtentionSplit}`;
    switch (this.serviceType) {

      case 'Taxes':
        this.filePath = 'TaxesExcel/'
        break;
      case 'fuel':
        this.filePath = 'FuelExel/'
        break
      case 'handling':
        this.filePath = 'HandlingExel/'
        break
        case 'ovf-permits':
          this.filePath = 'overflightExcel/';
          break
        case 'landing':
          this.filePath = 'LandingExcel/';
          break
        case 'slot':
          this.filePath = 'SlotExcel/';
          break
      default:
        break;
    }
    const path = `${this.filePath}${this.commonService.uid}/`;
    this.fileUploadServiceProvider
      .FirebaseBucketUpload(
        path +
        this.AttachmentName.FilePath,
        this.csvExcelFile.file,
      )
      .then((data: string) => {
        this.csvFileService.UploadCsvExcel(this.serviceType, this.AttachmentName.FilePath, data)
          .then((res) => {
            this.csvFileList.getCsvData();
          }).then(() => {
            this.commonService.dismissLoader();
            this.csvExcelFile.selectedFileName = "";
          })
          .catch((err) => {
            this.commonService.dismissLoader();
          });
      });
  }
  public sampleCsvDownload() {
    const storePath = "csv_sample_file/";

    switch (this.serviceType) {
      case 'Taxes':
        this.filename = 'Taxes.csv';
        this.CsvSampleFilepath= 'TaxesSampleCsvFile';
        break;
      case 'fuel':
        this.filename = 'Fuel.csv';
        this.CsvSampleFilepath= 'FuelSampleCsvFile';
        break
      case 'handling':
        this.filename = 'GroundHanling.csv';
        this.CsvSampleFilepath= 'GroundHandlingCsvFile';
        break
      case 'ovf-permits':
        this.filename = 'OverFlightpermit.csv';
        break
      case 'landing':
        this.filename = 'Landing.csv';
        break
      case 'slot':
        this.filename = 'SlotPrice.csv';
        break
      default:
        break;
    }
    if (Capacitor.isNativePlatform()) {
      this.fbService.downloadfileFrombucket(storePath,this.filename);
    } else {
      switch (this.serviceType) {
        case 'Taxes':
          this.filename = 'Tax.csv';
          const Tbroser = this.iab.create(
            this.configMessage.TaxesSampleCsvFile,
            "_blank",
          );
          Tbroser.close();
          break;
        case 'fuel':
          this.filename = 'Fuel.csv';
          const broser = this.iab.create(
            this.configMessage.FuelSampleCsvFile,
            "_blank",
          );
          broser.close();
          break
        case 'handling':
          const browser = this.iab.create(
            this.configMessage.SampleHandlingCsvFile,
            "_blank",
          );
          browser.close();
          break
        case 'ovf-permits':
          const Obrowser = this.iab.create(
            this.configMessage.OvfSampleCsvFile,
            "_blank",
          );
          break
        case 'landing':
          const Lbrowser = this.iab.create(
            this.configMessage.LandingSampleCsvFile,
            "_blank",
          );
          break
        case 'slot':
          const Sbrowser = this.iab.create(
            this.configMessage.SlotSampleCsvFile,
            "_blank",
          );
          break
        default:
          break;
      }
    }
  }
}
