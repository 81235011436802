import { RatingService } from '@app/shared/services/rating.service';
import { CommonService } from '@app/shared/services/common.service';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { FirebaseDataService } from '@app/shared/services/firebase-data.service';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import firebase from 'firebase';
import { VendordetailsService } from '@app/shared/services/vendordetails.service';
import { MessageConfig } from '@app/shared/constants/constant';

@Component({
  selector: 'app-about-user',
  templateUrl: './about-user.component.html',
  styleUrls: ['./about-user.component.scss'],
})
export class AboutUserComponent implements OnInit {
  public type: any;
  private vendorID = '';
  public logo: string;
  public info = '';
  public allProfile: Observable<any[]>;
  public userrating = 0;
  public reviewdata: any;
  public imageUrl = '';
  private UID = '';
  public userdata = new VendorInfo();
  private Afterposting = 'true';
  public spinner = false;
  public Operatordetails = 'contacts';
  public messageConfig = MessageConfig;
  public firebaseuid: any;
  public services :any;
  public rating:any;
  public descriptionByVendorRateCardOrProfile: string = '';
  constructor(
    private modalContrl: ModalController,
    private navParams: NavParams,
    private firebaseList: FirebaseDataService,
    private alertCtrl: AlertController,
    private vendordetailsService: VendordetailsService,
    private commonService: CommonService,
    private ratingService:RatingService
  ) {
    this.type = this.navParams.data.Type;
    this.vendorID = this.navParams.data.VendorId;
    this.logo = this.navParams.data.Logo;
    this.allProfile = this.firebaseList.userProfile;
    this.firebaseuid = this.navParams.data.firebaseuid;
    this.services = this.navParams.data.allservice;

    if (this.type == 'Vendor') {
      const usershowratingRef = firebase
        .database()
        .ref('/UserShowRating/' + this.UID + '/Rating');
      usershowratingRef.on('value', (snapshot) => {
        if (snapshot.val() != null) {
          this.userrating = snapshot.val();
        }
      });
      const userRef = firebase.database().ref('/UserProfile/' + this.UID);
      userRef.on('value', (snapshot) => {
        if (snapshot.val() != null) {
          const tempImage = snapshot.val().Image;
          const tempInfo = snapshot.val().Info;
          this.imageUrl = tempImage;
          this.info = tempInfo;
        }
      });
      const reviewRef = firebase.database().ref('/UsersRating/' + this.UID);
      reviewRef.on('value', (snapshot) => {
        if (snapshot.val() != null) {
          this.reviewdata = snapshot.val();
        }
      });
    }
  }

  public ngOnInit() {
   }
  public ionViewWillEnter() {
    this.dataLoad();
  }

  public async dataLoad() {
    this.rating = this.ratingService.getAverageRatingForName(this.firebaseuid ? this.firebaseuid:this.services.chatFirebaseId);
    if (this.type) {
      this.commonService.showSpinnerLoader();
      if(this.commonService.roleId == 2){
        await this.vendordetailsService
        .getResellerInfo(this.services.legId,this.services.SRID,this.services.sector,this.services.location,this.services.vendorID,this.services.servicename)
        .then((data: any) => {
          this.commonService.dissmissSpinnerLoader();
          if (data == 'Error') {
            this.commonService.showErrorAlert().then((res) => {
              if (res) {
                this.dataLoad();
              }
            });
          } else {
            this.userdata = data;
            this.descriptionByVendorRateCardOrProfile =  this.fetchDescriptionByVendorRateCardOrProfile();
            this.commonService.dissmissSpinnerLoader();
          }
        });
      }
      else{
        await this.vendordetailsService
        .getVendorInfo(this.vendorID, this.Afterposting, this.type, this.firebaseuid,)
        .then((data: any) => {
          this.commonService.dissmissSpinnerLoader();
          if (data == 'Error') {
            this.commonService.showErrorAlert().then((res) => {
              if (res) {
                this.dataLoad();
              }
            });
          } else {
            this.userdata = data;
            this.commonService.dissmissSpinnerLoader();
          }
        });
      }
    } else {
      this.spinner = true;
      const usershowratingRef = firebase
        .database()
        .ref('/UserShowRating/' + this.UID + '/Rating');
      usershowratingRef.on('value', (snapshot) => {
        if (snapshot.val() != null) {
          this.userrating = snapshot.val();
        }
      });
      const reviewRef = firebase.database().ref('/UsersRating/' + this.UID);
      reviewRef.on('value', (snapshot) => {
        if (snapshot.val() != null) {
          this.reviewdata = snapshot.val();
        }
      });
      const userRef = firebase.database().ref('/UserProfile/' + this.UID);
      userRef.on('value', (snapshot) => {
        if (snapshot.val() != null) {
          const tempImage = snapshot.val().Image;
          const tempInfo = snapshot.val().Info;
          this.imageUrl = tempImage;
          this.info = tempInfo;
        }
      });
    }
  }
  public dismiss() {
    this.modalContrl.dismiss();
  }
  public fetchDescriptionByVendorRateCardOrProfile() {
    if(this.userdata) {
      this.userdata.Description = this.userdata.Description ? this.userdata.Description : 'No description';
      const vendorRateCardOrProfileDesc = this.userdata.vendordescription  && this.userdata.Description ? this.userdata.vendordescription
      : this.userdata.vendordescription  && !this.userdata.Description ? this.userdata.vendordescription :
      !this.userdata.vendordescription && this.userdata.Description ? this.userdata.Description: this.userdata.Description;
    return vendorRateCardOrProfileDesc;
    } else {
    }
  }
}
export class VendorInfo {
  public AccountcallingCodes: number;
  public ClientAccDeptContactEmailID: string;
  public ClientAccDeptContactName: string;
  public ClientAccDeptContactNo: string;
  public ClientContactNo: number;
  public ClientID: string;
  public ClientInvoiceAddressline1: string;
  public ClientInvoiceAddressline2: string;
  public ClientInvoiceAddressline3: string;
  public ClientName: string;
  public ClientOperationContactEmailID: string;
  public ClientOperationContactName: string;
  public ClientOperationContactNo: string;
  public CompanyAddress: string;
  public CompanyDetails: string;
  public CompanyEmail: string;
  public CompanyName: string;
  public Country: string;
  public CreatedDate: Date;
  public Description: string;
  public NumberOfAircraft: string;
  public PaymentType: string;
  public Pincode: null;
  public PrivacyForEmail: boolean;
  public PrivacyForNotification: string;
  public STDID: null;
  public State: string;
  public Timezone: string;
  public UserID: string;
  public Verified: string;
  public callingCodeOpscontact: string;
  public clientcallingCodes: string;
  public currencyCode: string;
  public filenametitle: null;
  public flag: string;
  public fuelUnit: string;
  public logo: string;
  public CountryName: string;
  public VendorAccDeptContactEmailID: string;
  public VendorAccDeptContactName: string;
  public VendorAccDeptContactNo: number;
  public VendorContactNo: number;
  public VendorInvoiceAddressline1: string;
  public VendorInvoiceAddressline2: string;
  public VendorInvoiceAddressline3: string;
  public VendorName: string = '';
  public VendorOperationContactEmailID: string;
  public VendorOperationContactName: string;
  public VendorOperationContactNo: number;
  public VendorServicesCat: string;
  public firebaseID: string;
  public vendorOpscallingCodes: string;
  public vendoraccountcallingCodes: string;
  public vendorcallingCodes: string;
  public Resellercallingcode: string;
  public Reselleremail: string;
  public Resellername: string;
  public Resellernumber: number;
  public Resellerset: boolean;
  public vendordescription: any;
}
