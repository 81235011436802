import { AuthSessionService } from './../../../core/services/auth-session.service';
import { CommonService } from './../../services/common.service';
import { ActivityCountService } from './../../services/activity-count.service';
import { Component, OnInit, Input } from '@angular/core';
import { Events } from '@app/shared/services/events.service';

@Component({
  selector: 'app-badge-count',
  templateUrl: './badge-count.component.html',
  styleUrls: ['./badge-count.component.scss'],
})
export class BadgeCountComponent implements OnInit {
  public $totalCount;
  @Input() public type: string;
  constructor(private activityCountService: ActivityCountService,
    private commonService: CommonService,
    private authSessionService: AuthSessionService,
    private event: Events) { }

  public ngOnInit() {
      this.activityCountService.sendAllTripsTotalCount();
        switch (this.commonService.roleId) {
          case '2':
            this.activityCountService.$tripsActivityCount.subscribe((totalCount) => {
              this.$totalCount = totalCount;
            })
            break;
          case '3':
            if (this.type == "quotes") {
              this.activityCountService.$tripsQuotesCount.subscribe((QuotesCount) => {
                this.$totalCount = QuotesCount;
              })
            } else if (this.type == "trips") {
              this.activityCountService.$vendorUnreadActivityCount.subscribe((vendorTripCount) => {
                this.$totalCount = vendorTripCount;
              })
            }
            break;
          default:
            break;
        }
  }
}
