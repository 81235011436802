import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnInit {
  @ViewChild('expandWrapper', { read: ElementRef })
  public expandWrapper: ElementRef;
  @Input('expanded') public expanded: boolean = false;
  @Input('expandHeight') public expandHeight: any = '100%';
  constructor(public renderer: Renderer2) {}
  public ngAfterViewInit() {
    this.renderer.setStyle(
      this.expandWrapper.nativeElement,
      'max-height',
      this.expandHeight
    );
  }
  public ngOnInit() {}
}
