import { Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {

  constructor(private menuCtrl: MenuController) { }

  public ngOnInit() {
  }
  public ionViewWillEnter() {
    this.menuCtrl.enable(false);
  }
  public ionViewWillUnload() {
    this.menuCtrl.enable(true);
  }

}
