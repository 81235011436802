import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AnimatedSplashComponent } from './animated-splash/animated-splash.component';
import { AuthLogoComponent } from './auth-logo/auth-logo.component';
import { IonTagsInput } from "./ion-tags-input/ion-tags-input";
import { IonTag } from './ion-tags-input/ion-tag';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [IonicModule, CommonModule,FormsModule,ReactiveFormsModule],
  declarations: [AnimatedSplashComponent, AuthLogoComponent, IonTagsInput,IonTag],
  exports: [AnimatedSplashComponent, AuthLogoComponent, IonTagsInput, IonTag],
  entryComponents: [
    IonTagsInput, IonTag,
  ],
})
export class CoreComponentModule {}
