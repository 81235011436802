import { Pipe, PipeTransform } from '@angular/core';
import { MessageConfig } from '../constants/constant';

@Pipe({
  name: 'surchargesTruncat'
})
export class SurchargesTruncatPipe implements PipeTransform {

  public transform(value: string): string {
    if (value != 'Surcharges') {
      value = value.replace("Surcharge/Discount", "").replace("Surcharge", "").replace(/(?:\b([^-]))Hour(?:\b([^-]))/gi, '$1Hours$2');
    }
    if (value == MessageConfig.HandlingOtherService.Passenger) {
      return MessageConfig.HandlingOtherService.passengerciq;
    }
    else {
      return value;
    }
  }

}
