import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-filter-list',
  templateUrl: './filter-list.component.html',
  styleUrls: ['./filter-list.component.scss'],
})
export class FilterListComponent implements OnInit {
  public filterByTripId;
  public filterByDate;
  public listpage: any;
  private pagefilter: any;
  constructor(
    private popoverCtrl: PopoverController,
    private navParams: NavParams
  ) { }

  public ngOnInit() {
    this.filterByTripId = this.navParams.data.filterByTripId;
    this.filterByDate = this.navParams.data.filterByDate;
    this.pagefilter = this.navParams.data.pageName;
  }
  public ngAfterViewInit() { }
  public close(sortBy) {
    if (sortBy == 'tripId') {
      this.filterByTripId =
        this.filterByTripId == 'tripidDescending'
          ? 'tripidAscending'
          : 'tripidDescending';
    } else if (sortBy == 'date') {
      if (this.pagefilter == 'trip') {
        this.filterByDate =
          this.filterByDate == 'tripDateDescending'
            ? 'tripDateAscending'
            : 'tripDateDescending';
      } else {
        this.filterByDate =
          this.filterByDate == 'invDateDescending'
            ? 'invDateAscending'
            : 'invDateDescending';
      }

    }
    this.popoverCtrl.dismiss({
      filteredBy: sortBy,
      filterByTripId: this.filterByTripId,
      filterByDate: this.filterByDate,
    });
  }
}
