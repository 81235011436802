import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-custom-spinner',
  templateUrl: './custom-spinner.component.html',
  styleUrls: ['./custom-spinner.component.scss'],
})
export class CustomSpinnerComponent implements OnInit {
  @Input() public message: string = 'Please wait...';
  @Input() public isVisible: boolean = false;
  constructor() { }

  public ngOnInit() {}
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.message) {
      this.message = changes.message.currentValue;
    }
    if (changes.isVisible) {
      this.isVisible = changes.isVisible.currentValue;
      this.isVisible ? document.body.classList.add('spinner-active'): document.body.classList.remove('spinner-active');
    }else if(!changes.isVisible){
      document.body.classList.remove('spinner-active');
    }else{
      document.body.classList.remove('spinner-active');
    }
  }

}
