import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SigninPage } from './signin.page';
import { SharedDirectiveModule } from '@app/shared/directives/shared-directive.module';
@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, ReactiveFormsModule,SharedDirectiveModule],
  declarations: [SigninPage],
})
export class SigninPageModule { }
