import { Pipe, PipeTransform } from '@angular/core';
import { TimerService } from '@app/shared/services/timer.service';

@Pipe({
  name: 'timerTime',
})
export class TimerTimePipe implements PipeTransform {
  constructor(private timerService: TimerService) {}
  public transform(inputSeconds: number) {
    if(isNaN(inputSeconds))
    {
      return '';
    }
    else
    {
      const result = this.timerService.getSecondsAsDigitalClock(
        Math.abs(inputSeconds)
      );
      return result;
    }
  }
}
