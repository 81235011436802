export const PageNames = {
  TabPageName: 'Trip Info',
  ActionItem: 'Action Items',
  Notification: 'Notifications',
  Incomplete: 'Incomplete',
  Complete: 'Complete',
  Crew: 'Crew',
  Pax: 'Pax',
  EditCrewPaxbutton: 'Edit',
  NoActivity: 'No activity completed...',
  NoNotification: 'No notification...',
  NoIncompleteActivity: 'No activity pending...',
  RouteFinalized: 'Finalized',
  NoAccessForCBP: 'Only flight plan vendor can see the route...',
  PendingCBPStage: 'After trip confirmed you can view or edit the route.',
  sectorwise: 'Sector-wise',
  stationwise: 'Station-wise',
  FLTnotUploaded: 'Test Flight Plan not uploaded yet.',
  CompanyRoutesNotAvailable: 'Company Routes Not Available.',
  Nostationrequest: 'station not available...',
  Nosectorrequest: 'sector not available...',
  selectFlightPlanNotAccepted: 'Flight Plan is not selected yet...',
  FlightPlanNotUpload: 'Flight plan is not uploaded yet...',
  okButton: 'Ok',
  Yes: 'Yes',
  Done: 'Done',
  Ignore: 'Ignore',
  HandlingDelivered: 'Are you sure the Handling service delivered?',
  PaxClearedCIQ: 'Pax arrived?',
  GHServicesFormSignoff: 'GH Services Form Signoff?',
  ChocksOff: 'Chocks Off?',
  TakeOff: 'Take Off?',
  Addcrew: 'Crew Required',
  Addpax: 'Pax Required',
  ReviewpaxManifest: 'Confirm the pax manifest?',
  ReviewGeneralDeclaration: 'Confirm the general declaration?',
  EnterBayNumber: 'Please enter bay number?',
  Aircrafthaslanded: 'Landed?',
  Chockson: 'Are you sure the Chocks on Completed?',
  PassengershavecompletedCIQ: 'Pax Departed?',
  AirportChargesDelivered: 'Are you sure the Airport Charges delivered?',
  FuelDelivered: 'Are you sure the Fuel delivered?',
  FDNSignoff: 'Confirm FDN signoff?',
  GeneralDeclaration: 'Review General Declaration',
  Declaration: 'General Declaration',
  Landed: 'Landed',
  AircraftLanded: 'Aircraft Landed',
  ChocksOn: 'Chocks on',
  ArrCIQ: 'Pax Departed',
  NoSectorRequest: ' No sector request',
  NoStationRequest: 'No station request',
  NoStationSectorRequest: 'No services required...',
  FinalizeFlightPlan:'Please add Crew before Finalize Flight Plan.',
  NoCrew:'No crew added...',
  NoPax:'No pax added...',
};
export const googleMapProperty ={
  mapUrl:'https://maps.googleapis.com/maps/api/staticmap?',
  size:'size=900x200',
  fromStationMarkers:'&markers=color:red%7Clabel:D%7C',
  toStationMarkers:'&markers=color:red%7Clabel:A%7C',
  mapType:'&maptype=terrain',
  mapKey:'&key=AIzaSyAFblizV8dBXm1OlUq6JNVzQKVKKCmlov0&path=color:0x4F5673%7Cweight:3%7C',
  verticalPipe:'|'

};
export const cardTitle = {
  'Review General Declaration': 'General Declaration',
  Landed: 'Aircraft Landed',
  'Trip Completed': 'Feedback',
  'Review Pax Manifest': 'Pax Manifest',
  'Overflight Permits': 'Nav Fees & Permits',
};

export const AirportChargesServices = {
  ChargesForExtensionOfWatchHours: 'ChargesForExtensionOfWatchHours',
  Lighting: 'Lighting',
  Followme: 'Followme',
  AerobridgeCharges: 'AerobridgeCharges',
  FireCoverageCharge: 'FireCoverageCharge',
};
export const HandlingExcludingRampServices = [
  'BasicHandling',
  'Toilet',
  'Water',
  'CrewTransport',
  'GENDEC',
  'PrivatePassengerCustomsclearanceat',
  'PaxTransport',
  'PrivatePassengerVIPloungue',
];

export enum AirportChargesSubServices {
  AirportCharges = 'Apt Fees',
  Airpermission = 'Apt Permits',
}
export const ApiResponseMessages = {
  GH_SIGNOFF: 'Please fill ground handling services',
  FDN_SIGNOFF: 'Please Upload Fuel Quantity',
  NOTICE: 'Notice',
  ERROR: 'Error',
};

export class AttachmentName {
  public FileNameConcatenate: any;
  public DateFormate: any;
  public FilePath: any;
  public ExtentionSplit: any;
}

export enum RatingMessages {
  RateYourTrip = 'Rate your trip!',
  OK = ' A OK Trip!',
  Bad = ' A bad trip!',
  Good = ' A Good Trip!',
  Greate = 'A Great Trip!',
  Experience = 'How was your experience?'
}
export enum serviceNames {
  Fuel = 'Fuel',
  Handling = 'Handling',
  AirportCharges = 'Apt Fees & Permits'
}
