export const NFPURI = {
  GenerateRoute: '/api/v1/ComputeFlight',
  UploadATCRoute: '/api/v1/ComputeFlightByATCRoute',
  AttachIcaoFlightPlanManual: '/api/v1/FileFlightPlan/manualFile',
  AttachIcaoFlightPlan: '/index.php?page=API&action=AttachIcaoFlightPlan',
  CancellFlightPlan: '/api/v1/FileFlightPlan/cancel',
  DelayFlightPlan: '/api/v1/FileFlightPlan/delay',
  ChangeFlightPlan: '/api/v1/FileFlightPlan/change',
  ValidateRoute: '/api/v1/ValidateRoute',
  ProductionAndRemarks: '/api/v1/ProductionAndRemarks',
  WeatherNotams: '/api/v1/SingleStationWeatherNotam',
  ImportFlight:'/api/v1/ImportFlight'
};

export const ScheduleURI = {
  DraftTripUrl: '/index.php?page=API&action=GetDraftTripByID',
  GetAlternate: '/index.php?page=API&action=GetAlternateAirport',
  GenerateRoute: NFPURI.GenerateRoute,
  DownloadPDF: '/index.php?page=API&action=DownloadIternaryPDF',
  GetPreferedVendorAddedInTrip: '/index.php?page=API&action=GetNewQuote',
  ActivatedTripDetails:
    '/index.php?page=API&action=GetExistingTripScheduleDetails',
  getCharterDraftTripByID:
    '/index.php?page=API&action=GetCharterDraftTripByID',
  GetShortNoticeAlert: '/index.php?page=API&action=GetAlertcontent',
  cancelCharterTrip:'/index.php?page=API&action=CancellCharterTrip',
  cancelCharterEstimate:'/index.php?page=API&action=CancellCharterTrip',
  GetRevisionFlp:'/index.php?page=API&action=GetRevisionFlp'
};
export const SetuplegUriConfig = {
  getVendorQoute: '/index.php?page=API&action=GetVendorQuote',
  getQuote: '/index.php?page=API&action=GetQuote',
  SaveTripData: '/index.php?page=API&action=saveRawTripData',
  RegisterTripData: '/index.php?page=API&action=RegisterTrip',
  getVendorInfo: '/index.php?page=API&action=GetVendorInfo',
  LegChangeVendorQuote: '/index.php?page=API&action=GetLegchangeQuote',
  GetLegchangeAutoselectQuote: '/index.php?page=API&action=GetLegchangeAutoselectQuote',

};

export const EstimateURI = {
  RegisterTrpUrl: '/index.php?page=API&action=RegisterTrip',
  SaveDraftTrpUrl: '/index.php?page=API&action=saveRawTripData',
  UpdateDraftTrip: '/index.php?page=API&action=UpdateDraftTrip',
};

export const AviationWeather = {
  weatherMetar: '/index.php?page=API&action=GetWeatherMetar',
  weatherTafs: '/index.php?page=API&action=GetWeatherTafs',
}
