export const queryParams = (params) => {
  if ('URLSearchParams' in window) {
    return new URLSearchParams(params).toString();
  }
  return Object.keys(params)
    .map((key) => {
      return [key, params[key]].map(encodeURIComponent).join('=');
    })
    .join('&');
};
