import { Injectable, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetController, AlertController, IonRouterOutlet, MenuController, ModalController, Platform, PopoverController, ToastController, NavController, LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class HardwareBackButtonService {
  // set up hardware back button event.
  public lastTimeBackPress = 0;
  public timePeriodToExit = 2000;
  @ViewChildren(IonRouterOutlet) public routerOutlets: QueryList<IonRouterOutlet>;
  constructor(private platform: Platform,
    private alertCtrl: AlertController,
    private navCtrl: NavController
  ) { }

  // active hardware back button
  public backButtonEvent() {
    this.platform.backButton.subscribeWithPriority(10, () => {
      try {
        this.alertCtrl.getTop().then(alerts => {
          if (alerts == undefined) {
            this.navCtrl.back();
          }
        });
      }
      catch (error) {
      }
    });

  }
}