import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tceVendorStru'
})
export class TceVendorStruPipe implements PipeTransform {

  public transform(
    tceArr: any[],
    legId: number,
    sector: string,
    lastLeg: boolean,
  ) {
    let servicesArr = new Array<any>();
    let legIDServiceArr = new Array<any>();
    legIDServiceArr = tceArr.filter((item: any) => {
      return item.legId == legId;
    });
    let firstStationArr = new Array<any>();
    firstStationArr = legIDServiceArr.filter((item: any) => {
      return item.station == sector.substr(0, 4);
    });
    let sectorArr = new Array<any>();
    sectorArr = legIDServiceArr.filter((item: any) => {
      return item.station == sector;
    });
    const secondStationArr = legIDServiceArr.filter((item: any) => {
      return item.station == sector.split("-")[1];
    });
    servicesArr = servicesArr.concat(firstStationArr);
    servicesArr = servicesArr.concat(sectorArr);
    if (lastLeg) {
      servicesArr = servicesArr.concat(secondStationArr);
    }
    const finalArr = {
      price: [],
    };
    servicesArr.forEach((element: any) => {
      if (!finalArr[element.station]) {
        finalArr[element.station] = [];
      }
      finalArr[element.station].push(element);
      if (finalArr.price[element.station]) {
        if (element.UID != element.vendorId) {
          if (finalArr.price[element.station][0] == "self") {
            finalArr.price[element.station][0] = 0;
            finalArr.price[element.station][0] += element.price || 0;
          } else {
            finalArr.price[element.station][0] += element.price || 0;
          }
        }
      } else {
        finalArr.price[element.station] = [];
        if (element.UID != element.vendorId) {
          finalArr.price[element.station][0] = element.price || 0;
        } else {
          finalArr.price[element.station][0] = "self";
        }
      }
    });
    const vendorArr = [];
    vendorArr.push(finalArr);
    for (const stations in vendorArr[0]) {
      if (stations != sector) {
        vendorArr[0][stations].forEach((element: any) => {
          switch (element.serviceName) {
            case "handling":
              element.order = 1;
              break;
            case "fuel":
              element.order = 2;
              break;
            case "airportcharges":
              element.order = 3;
              break;
          }
        });
        vendorArr[0][stations].sort((a, b) => {
          return a.order - b.order;
        });
        this.deleteOrder(vendorArr[0][stations]);
      }
    }
    return vendorArr;
  }
  public deleteOrder(stationArr: any) {
    stationArr.forEach((element: any) => {
      delete element.order;
    });
  }

}
