import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorObservable } from 'rxjs-compat/observable/ErrorObservable';

@Injectable({
  providedIn: 'root',
})
export class ExceptionService {
  constructor(public http: HttpClient) {}
  public handleError(error: HttpErrorResponse) {
    const err: any = new Error();
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      err.message = error.error.message;
      err.status = error.status;
      err.statusText = error.statusText;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      err.message = error.error.message;
      err.status = error.status;
      err.statusText = error.statusText;
    }
    // return an ErrorObservable with a user-facing error message
    // return new ErrorObservable(
    //   'Something bad happened; please try again later.');
    return new ErrorObservable(err);
  }
}
