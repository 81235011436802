import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderWiseOvfPermitsCountry'
})
export class OrderWiseOvfPermitsCountryPipe implements PipeTransform {

public  transform(array: any[], field: string) : any[]{
    if (!Array.isArray(array) || array.length <= 1) {
      return array;
    }
    return array.slice().sort((a, b) => a[field].localeCompare(b[field]));
  }
}
