import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundUp',
})
export class RoundupPipePipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  public transform(value: number) {
    if (value) {
      return Math.ceil(value);
    } else {
      return value;
    }
  }
}
