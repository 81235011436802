import { VendorCurrencyPipe } from './vendor-currency';
import { RoundupPipePipe } from './roundup-pipe.pipe';
import { ReversePipe } from './reverse.pipe';
import { TruncatePipe } from './truncate.pipe';
import { NgModule } from '@angular/core';
import { LastTruncatePipe } from './last-truncate.pipe';
import { DateFilterPipe } from './date-filter';
import { GetKeysPipe } from './get-keys.pipe';
import { EnterDecimalPipe } from './enter-decimal.pipe';
import { RemoveCharPipe } from './remove-char.pipe';
import { TimerTimePipe } from './timer-time.pipe';
import { FlyingDatetimePipe } from './flying-datetime.pipe';
import { CurrencyConversionPipe } from './currency-conversion.pipe';
import { NameServicePipe } from './name-service.pipe';
import { DateFormatFilterPipe } from './date-format-filter.pipe';
import { SurchargesDateFormatPipe } from './surcharges-date-format.pipe';
import { ToNumberPipe } from './to-number.pipe';
import { TceVendorStruPipe } from './tce-vendor-stru.pipe';
import { UnitConversionPipe } from './unit-conversion.pipe';
import { TitleSpacePipe } from './title-space.pipe';
import { SurchargesTruncatPipe } from './surcharges-truncat.pipe';
import { GetSurchargesByOrderPipe } from './get-surcharges-by-order.pipe';

import { SurchargeNamePipe } from './surcharge-name.pipe';
import { SortlistFlightPlanPipe } from './sortlist-flight-plan.pipe';
import { HandlingOtherServicesOrderPipe } from './handling-other-services-order-pipe.pipe';
import { TimeAgoPipe } from './time-ago.pipe';
import { OrderWiseOvfPermitsCountryPipe } from './order-wise-ovf-permits-country.pipe';
import { PercentagePipe } from './percentage.pipe';
@NgModule({
  declarations: [
    TruncatePipe,
    LastTruncatePipe,
    DateFilterPipe,
    GetKeysPipe,
    EnterDecimalPipe,
    ReversePipe,
    RemoveCharPipe,
    TimerTimePipe,
    FlyingDatetimePipe,
    CurrencyConversionPipe,
    NameServicePipe,
    DateFormatFilterPipe,
    RoundupPipePipe,
    SurchargesDateFormatPipe,
    VendorCurrencyPipe,
    ToNumberPipe,
    TceVendorStruPipe,
    UnitConversionPipe,
    TitleSpacePipe,
    SurchargesTruncatPipe,
    GetSurchargesByOrderPipe,
    SurchargeNamePipe,
    SortlistFlightPlanPipe,
    HandlingOtherServicesOrderPipe,
    TimeAgoPipe,
    OrderWiseOvfPermitsCountryPipe,
    PercentagePipe
  ],
  imports: [],
  exports: [
    TruncatePipe,
    LastTruncatePipe,
    DateFilterPipe,
    GetKeysPipe,
    EnterDecimalPipe,
    CurrencyConversionPipe,
    ReversePipe,
    RemoveCharPipe,
    TimerTimePipe,
    FlyingDatetimePipe,
    CurrencyConversionPipe,
    NameServicePipe,
    DateFormatFilterPipe,
    RoundupPipePipe,
    SurchargesDateFormatPipe,
    VendorCurrencyPipe,
    TceVendorStruPipe,
    ToNumberPipe,
    TceVendorStruPipe,
    UnitConversionPipe,
    TitleSpacePipe,
    SurchargesTruncatPipe,
    GetSurchargesByOrderPipe,
    SurchargeNamePipe,
    SortlistFlightPlanPipe,
    HandlingOtherServicesOrderPipe,
    TimeAgoPipe,
    OrderWiseOvfPermitsCountryPipe,
    PercentagePipe
  ],
})
export class PipesModule { }
