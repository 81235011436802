import { Injectable } from '@angular/core';
import { ServerConnectService } from '@app/core/services/server-connect.service';
import { ApiTypes } from '../constants/constant';
import { ApiConfig } from '../constants/url-constant';
import { CommonService } from './common.service';
import { queryParams } from './../../shared/utils/query-params';
@Injectable({
  providedIn: 'root'
})
export class HandlingCsvServiceService {
  public apiType = ApiTypes;
  constructor(private serverConnectService: ServerConnectService, private commonService: CommonService) { }

  public fetchCsvList() {
    return new Promise((resolve, reject) => {
      const databody = "";
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          ApiConfig.getListOfHandlingExcelData,
          databody,
          false,
          false,
          false
        )
        .then((value: any) => {
          resolve(value.GetListOfGHExcelData);
        })
        .catch((error) => {
          this.commonService.showErrorAlert().then((res)=> {
            if (res) {
                      this.fetchCsvList();
                    } else {
                      reject();
                    }
          })
          // resolve('Error');
        });
    });
  }
 public UploadHandlingExcel(filename, url) {
  return new Promise((resolve, reject) => {
    const databody = "HandlingExel=" + filename + "&DownloadUrl=" + url;
    this.serverConnectService
    .connectToServer(
      this.apiType.POST,
      ApiConfig.uploadHandlingExcelData,
      databody,
      false,
      false,
      false
    )
    .then((value: any) => {
      resolve(value);
    })
    .catch((error) => {
      this.commonService.showErrorAlert().then((res)=> {
        if (res) {
                  this.UploadHandlingExcel(filename, url);
                } else {
                  reject();
                }
      })
      // resolve('Error');
    });
  });
 }
 public deleteCsvFile(FUDName,GHEDID) {
  const databody = queryParams({
    UserEmailID: this.commonService.userName,
    UserPass: this.commonService.password,
    FUDName,
    GHEDID,
  });
  return new Promise((resolve, reject) => {
    this.serverConnectService
    .connectToServer(
      this.apiType.POST,
      ApiConfig.deleteHandlingExcelData,
      databody,
      false,
      false,
      false
    )
    .then((value: any) => {
      resolve(value.ResponseStatus);
    })
    .catch((error) => {
      this.commonService.showErrorAlert().then((res)=> {
        if (res) {
                  this.deleteCsvFile(FUDName , GHEDID);
                } else {
                  reject();
                }
      })
      // resolve('Error');
    });
  });
 }
}
