import { ActivityService } from './activity.service';
import { Injectable } from '@angular/core';
import { CommonService } from '@app/shared/services/common.service';
import { ServerConnectService } from '@app/core/services/server-connect.service';
import { ApiTypes } from '@app/shared/constants/constant';
import { activityUriConfig } from '../constants/trip-config-url';
import { TripServices } from '../trips-tab/model/trip-service.model';
import { IntoPlane } from '../trips-tab/model/enter-trip-details.model';
@Injectable({
  providedIn: 'root',
})
export class EnterDetailsApiService {
  public apiType = ApiTypes;
  constructor(
    private activityService: ActivityService,
    private commonService: CommonService,
    private serverConnectService: ServerConnectService
  ) { }
  public submitPermitNumber(permitId: any, permitNo: any) {
    return new Promise((resolve, reject) => {
      this.commonService.showSpinnerLoader();
      const databody = 'permitid=' + permitId + '&permitnumber=' + permitNo;
      if (this.commonService.roleId == 2 || this.commonService.roleId == 3) {
        this.serverConnectService
          .connectToServer(
            this.apiType.POST,
            activityUriConfig.savePermitNumberOfClient,
            databody,
            false,
            false,
            false
          )
          .then((response: any) => {
            this.commonService.dissmissSpinnerLoader();
            resolve(response.ResponseStatus);
          })
          .catch((error) => {
            this.commonService.dissmissSpinnerLoader();
            this.commonService.showErrorAlert().then((res) => {
              if (res) {
                this.submitPermitNumber(permitId, permitNo);
              } else {
                reject();
              }
            });
          });
      }
    });
  }
  public submitAirpermissionNo(permitdetails, permitNo) {
    return new Promise((resolve, reject) => {
      this.commonService.showSpinnerLoader();
      const databody =
        'SRID=' +
        permitdetails.SRID +
        '&station=' +
        permitdetails.Value +
        '&sector=' +
        permitdetails.Sector +
        '&SRSecID=' +
        permitdetails.SRSecID +
        '&permitid=' +
        permitdetails.SRPermitID +
        '&permitnumber=' +
        permitNo;
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          activityUriConfig.EnterAirpermissionPermit,
          databody,
          false,
          false,
          false
        )
        .then((response: any) => {
          this.commonService.dismissLoader();
          resolve(response.ResponseStatus);
        })
        .catch((error) => {
          this.commonService.dismissLoader();
          this.commonService.showErrorAlert().then((res) => {
            if (res) {
              this.submitAirpermissionNo(permitdetails, permitNo);
            } else {
              reject();
            }
          });
        });
    });
  }
  public submitAirportCharges(
    srid,
    serviceDetails: TripServices,
    timeofService: string
  ) {
    if (timeofService == 'arrival') {
      timeofService = 'Arrival';
    } else {
      timeofService = 'Departure';
    }
    let sector: string;
    let legId: any;
    if (serviceDetails.previousSector && timeofService == 'Arrival') {
      sector = serviceDetails.previousSector;
    } else {
      sector = serviceDetails.sector;
    }
    if (serviceDetails.previousLegId && timeofService == 'Arrival') {
      legId = serviceDetails.previousLegId;
    } else {
      legId = serviceDetails.legId;
    }
    return new Promise((resolve, reject) => {
      this.commonService.showSpinnerLoader();
      const airportChargesdata =
        'SRID=' +
        srid +
        '&station=' +
        serviceDetails.location +
        '&sector=' +
        sector +
        '&SRSecID=' +
        legId +
        '&ArrOrDept=' +
        timeofService +
        '&data=' +
        JSON.stringify(serviceDetails.subservicedetails[timeofService]) +
        '&AttachmentFileName=' +
        encodeURIComponent(serviceDetails.AttachmentName);
      let API: any;
      if (
        serviceDetails.sector.substring(0, 4) == serviceDetails.location &&
        serviceDetails.ArrDeptStatus == true &&
        timeofService == 'Arrival'
      ) {
        API = this.serverConnectService.connectToServer(
          this.apiType.POST,
          activityUriConfig.EnterInprogressAirportChargesDetails,
          airportChargesdata,
          false,
          false,
          false
        );
      } else if (
        serviceDetails.legStatus == 'Confirmed' ||
        serviceDetails.legStatus == 'Processing'
      ) {
        API = this.serverConnectService.connectToServer(
          this.apiType.POST,
          activityUriConfig.EnterUpcomingAirportChargesDetails,
          airportChargesdata,
          false,
          false,
          false
        );
      } else {
        API = this.serverConnectService.connectToServer(
          this.apiType.POST,
          activityUriConfig.EnterInprogressAirportChargesDetails,
          airportChargesdata,
          false,
          false,
          false
        );
      }
      if (API) {
        API.then(
          (response: any) => {
            this.commonService.dismissLoader();
            resolve(response.ResponseStatus);
          },
          (error) => {
            this.commonService.dismissLoader();
            this.commonService.showErrorAlert().then((res) => {
              if (res) {
                this.submitAirportCharges(srid, serviceDetails, timeofService);
              } else {
                reject();
              }
            });
          }
        );
      } else {
        reject();
      }
    });
  }
  public getPath(
    serviceName: string,
    requestType: string,
    vendorUid?: string,
    details?:any,
    SRID?:any
  ): string {
    let UID = '';
    let path = '';
    let arrDepServices;
    if(details.previousTimeOfService){
      switch (details.previousTimeOfService) {
        case 'arrival':
          arrDepServices ='arrival';
        break;
        case 'departure':
          arrDepServices ='departure';
        break;
      default:
        break;
   }
}
  else if(details.onTimeOfService){
    switch (details.onTimeOfService) {
      case 'Dep ':
        arrDepServices ='departure';
      break;
    default:
      break;
  }
}
  else if(details.servicename){
    switch (details.servicename) {
      case 'Arr Handling':
      case 'Arr Apt Fees & Permits':
        arrDepServices ='arrival';
      break;
      case 'Dep Handling':
      case 'Dep Apt Fees & Permits':
        arrDepServices ='departure';
      break;
    default:
      break;
  }
}
    if (requestType == 'Vendor') {
      // client
      UID = String(vendorUid);
    } else if (requestType == 'Operator') {
      // vendor
      UID = String(this.commonService.FIREBASE_UID);
    } else if (requestType == 'Self' || 'self') {
      UID = String(this.commonService.FIREBASE_UID);
    }
    const legId = details.previousSector && details.previousTimeOfService == 'arrival' ? details.previousLegId : details.legId ? details.legId : details.legID;
    switch (serviceName) {
      case 'fuel':
        path = `${UID}/fueldata/${details.SRID}/${details.SRSecID}/${details.Station}`
        // path = UID + '/' + 'fueldata/';
        break;
        case 'Handling':
          path = `${serviceName}Attachments/${arrDepServices}/${UID}/${details.SRID}/${legId}/`
          break;
        case 'airportcharges':
          path = `${serviceName}Attachments/${arrDepServices}/${UID}/${details.SRID}/${legId}/`
          break;
        case 'FlightPlanAttach':
          path = `FlightPlanAttach/${this.commonService.FIREBASE_UID}/${SRID}/${legId}/`
          break;
    }
    return path;
  }
  public submitFuelDetails(
    srid,
    Inplane: IntoPlane,
    serviceDetails: TripServices
  ) {
    return new Promise((resolve, reject) => {
      this.commonService.showSpinnerLoader();
      const databody =
        'SRID=' +
        srid +
        '&station=' +
        serviceDetails.location +
        '&sector=' +
        serviceDetails.sector +
        '&SRSecID=' +
        serviceDetails.legId +
        '&fuelarrangedornot=yes' +
        '&contactname=' +
        Inplane.name +
        '&contactnumber=' +
        Inplane.contactNo +
        '&FuelDialCode=' +
        encodeURIComponent(Inplane.FuelDialCode) +
        '&contactemail=' +
        Inplane.email +
        '&FuelOn=' +
        Inplane.departure +
        '&Quantity=' +
        Inplane.Quantity +
        '&Unit=' +
        Inplane.unit;
      let API: any;
      if (
        serviceDetails.legStatus == 'Confirmed' ||
        serviceDetails.legStatus == 'Processing'
      ) {
        API = this.serverConnectService.connectToServer(
          this.apiType.POST,
          activityUriConfig.EnterFuelIntoplaneDetails,
          databody,
          false,
          false,
          false
        );
      }
      if (API) {
        API.then(
          (response: any) => {
            this.commonService.dismissLoader();
            resolve(response.ResponseStatus);
          },
          (error: any) => {
            this.commonService.dismissLoader();
            this.commonService.showErrorAlert().then((res) => {
              if (res) {
                this.submitFuelDetails(srid, Inplane, serviceDetails);
              } else {
                this.commonService.dismissLoader();
                reject();
              }
            });
          }
        );
      } else {
        this.commonService.dismissLoader();
        reject();
      }
    });
  }
  public SubmitFuelOnDetails(Inplane: IntoPlane, serviceDetails: TripServices) {
    return new Promise((resolve, reject) => {
      this.commonService.showSpinnerLoader();
      const databody =
        'SRID=' +
        serviceDetails.SRID +
        '&Station=' +
        serviceDetails.location +
        '&Sector=' +
        serviceDetails.sector +
        '&SRSecID=' +
        serviceDetails.legId +
        '&FuelOnFlag=' +
        Inplane.departure +
        '&Fuelid=' +
        encodeURIComponent(Inplane.FuelDialCode);
      let API: any;
      if (
        serviceDetails.legStatus == 'Confirmed' ||
        serviceDetails.legStatus == 'Processing'
      ) {
        API = this.serverConnectService.connectToServer(
          this.apiType.POST,
          activityUriConfig.SaveFuelOnFlagOfClient,
          databody,
          false,
          false,
          false
        );
      } else {
        API = this.serverConnectService.connectToServer(
          this.apiType.POST,
          activityUriConfig.SaveFuelOnFlagOfClient,
          databody,
          false,
          false,
          false
        );
      }
      if (API) {
        API.then(
          (response: any) => {
            this.commonService.dismissLoader();
            resolve(response.ResponseStatus);
          },
          (error) => {
            this.commonService.dismissLoader();
            this.commonService.showErrorAlert().then((res) => {
              if (res) {
                this.SubmitFuelOnDetails(Inplane, serviceDetails);
              } else {
                reject();
              }
            });
          }
        );
      } else {
        reject();
      }
    });
  }
  public submitInprogressFuelDetails(
    srid,
    Inplane: IntoPlane,
    serviceDetails: TripServices,
    filename?
  ) {
    return new Promise((resolve, reject) => {
      // this.commonService.showSpinnerLoader();
      const databody =
        'SRID=' +
        srid +
        '&station=' +
        serviceDetails.location +
        '&sector=' +
        serviceDetails.sector +
        '&SRSecID=' +
        serviceDetails.legId +
        '&fuelquantity=' +
        Inplane.Quantity +
        '&fuelquantityunit=' +
        Inplane.unit +
        '&filename=' +
        serviceDetails.AttachmentName +
        '&AttachmentFileName=' +
        encodeURIComponent(serviceDetails.AttachmentFileName) +
        '&selectedfile=' +
        Inplane.selectedfile +
        '&FuelOn=' +
        Inplane.departure +
        '&ContactEmail=' +
        Inplane.email +
        '&OriginalFDN=' + encodeURIComponent(filename) ;
      let API: any;
      if (serviceDetails.legStatus != 'Confirmed') {
        API = this.serverConnectService.connectToServer(
          this.apiType.POST,
          activityUriConfig.EnterFuelInprogressDetails,
          databody,
          false,
          false,
          false
        );
      }
      if (API) {
        API.then(
          (response: any) => {
            this.commonService.dismissLoader();
            resolve(response.ResponseStatus);
          },
          (error) => {
            this.commonService.dismissLoader();
            this.commonService.showErrorAlert().then((res) => {
              if (res) {
                this.submitInprogressFuelDetails(srid, Inplane, serviceDetails);
              } else {
                reject();
              }
            });
          }
        );
      } else {
        reject();
      }
    });
  }
  public submitHandling(
    srid,
    serviceDetails: TripServices,
    timeofService: string,
    rampServicesByClient?: any
  ) {
    if (timeofService == 'arrival') {
      timeofService = 'Arrival';
    } else {
      timeofService = 'Departure';
    }
    let sector: string;
    let legId: any;
    sector = serviceDetails.previousSector && timeofService == 'Arrival' ? serviceDetails.previousSector : serviceDetails.sector;
    if (
      serviceDetails.previousLegId &&
      timeofService == 'Arrival'
    ) {
      legId = rampServicesByClient
        ? serviceDetails.legId
        : serviceDetails.previousLegId;
    } else {
      legId = serviceDetails.legId;
    }
    return new Promise((resolve, reject) => {
      this.commonService.showSpinnerLoader();
      const databody =
        'SRID=' +
        srid +
        '&station=' +
        serviceDetails.location +
        '&sector=' +
        sector +
        '&SRSecID=' +
        legId +
        '&ArrOrDept=' +
        timeofService +
        '&data=' +
        JSON.stringify(serviceDetails.subservicedetails[timeofService]) +
        '&AttachmentFileName=' +
         encodeURIComponent(serviceDetails.AttachmentName);
      let API: any;
      if (
        !rampServicesByClient &&
        serviceDetails.sector.substring(0, 4) == serviceDetails.location &&
        serviceDetails.ArrDeptStatus == true &&
        timeofService == 'Arrival'
      ) {
        API = this.serverConnectService.connectToServer(
          this.apiType.POST,
          activityUriConfig.EnterInprogressGroundHandlingDetails,
          databody,
          false,
          false,
          false
        );
      } else if (
        !rampServicesByClient &&
        (serviceDetails.legStatus == 'Confirmed' ||
          serviceDetails.legStatus == 'Processing')
      ) {
        const handlingApi = serviceDetails.previousLegStatus
          ? activityUriConfig.EnterInprogressGroundHandlingDetails
          : activityUriConfig.EnterUpcomingGroundHandlingDetails;
        API = this.serverConnectService.connectToServer(
          this.apiType.POST,
          handlingApi,
          databody,
          false,
          false,
          false
        );
      } else if (rampServicesByClient) {
        API = this.serverConnectService.connectToServer(
          this.apiType.POST,
          activityUriConfig.addClientGroundCharges,
          databody,
          false,
          false,
          false
        );
      } else {
        API = this.serverConnectService.connectToServer(
          this.apiType.POST,
          activityUriConfig.EnterInprogressGroundHandlingDetails,
          databody,
          false,
          false,
          false
        );
      }
      if (API) {
        API.then(
          (response: any) => {
            this.commonService.dismissLoader();
            resolve(response.ResponseStatus);
          },
          (error) => {
            this.commonService.dismissLoader();
            this.commonService.showErrorAlert().then((res) => {
              if (res) {
                this.submitHandling(
                  srid,
                  serviceDetails,
                  timeofService,
                  rampServicesByClient
                );
              } else {
                reject();
              }
            });
          }
        );
      } else {
        reject();
      }
    });
  }
  public deletefileofFDN(fileId) {
    return new Promise((resolve) => {
      const databody =
        "UserEmailID=" +
        this.commonService.userName +
        "&UserPass=" +
        this.commonService.password +
        "&enct=1&FuelQuantityID=" +
        fileId;
      this.serverConnectService.connectToServer(
        this.apiType.POST,
        activityUriConfig.DeleteFDNAttachment,
        databody,
        false,
        false,
        false
      )
        .then(
          (data: any) => {
            resolve(data.ResponseStatus);
          },
          (err) => {
            resolve("Error");
          },
        );
    });
  }
  public deleteFileHandling(fileId) {
    return new Promise((resolve) => {
      const databody =
        "UserEmailID=" +
        this.commonService.userName +
        "&UserPass=" +
        this.commonService.password +
        "&enct=1&handlingAttachID=" +
        fileId;
      this.serverConnectService.connectToServer(
        this.apiType.POST,
        activityUriConfig.DeleteHandlingAttachment,
        databody,
        false,
        false,
        false
      )
        .then(
          (data: any) => {
            resolve(data.ResponseStatus);
          },
          (err) => {
            resolve("Error");
          },
        );
    });
  }
  public deleteFileAPT(fileId) {
    return new Promise((resolve) => {
      const databody =
        "UserEmailID=" +
        this.commonService.userName +
        "&UserPass=" +
        this.commonService.password +
        "&enct=1&aptAttachID=" +
        fileId;
      this.serverConnectService.connectToServer(
        this.apiType.POST,
        activityUriConfig.DeleteAirportchargesAttachment,
        databody,
        false,
        false,
        false
      )
        .then(
          (data: any) => {
            resolve(data.ResponseStatus);
          },
          (err) => {
            resolve("Error");
          },
        );
    });
  }

  public submitFLPDocs(
    srId,
    SRSecID,
    Sector,
    filename?
  ) {
    return new Promise((resolve,reject)=>{
      const databody =
      'SRID=' +
      srId +
      '&SRSecID=' +
      SRSecID +
      '&Sector=' +
      Sector+
      '&filename=' +
      encodeURIComponent(filename);
      this.serverConnectService.connectToServer(
        this.apiType.POST,
        activityUriConfig.SaveFlpDocs,
        databody,
        false,
        false,
        false
      ).then(
        (data: any) => {
          resolve(data.ResponseStatus);
        },
        (err) => {
          resolve("Error");
        },
      );
    })
  }
}