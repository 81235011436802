import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortlistFlightPlan'
})
export class SortlistFlightPlanPipe implements PipeTransform {

  public transform(arr: any) {
    for (const index in arr) {
      if (arr[index]?.CBPAttachmentDetails?.Selected == "1") {
        arr[index].order = -1;
      } else {
        arr[index].order = 1;
      }
    }
    return arr.sort((a, b) => a.order < b.order ? -1 : a.order > b.order ? 1 : 0);
  }
}
