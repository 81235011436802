export class Schedule {
  public legs = new Array<TripLegs>();
  public clientUID: string;
  public tripStatus: string;
  public vendorArr: any;
  public quotesstaus: string;
  public createDate: string;
  public Regd: string;
}

export class TripLegs {
  public BLKTIME: string;
  public ETDGMT: string;
  public ETAGMT: string;
  public ETALT: string;
  public ETDLT: string;
  public departDate: string;
  public Arrivaltime: any;
  public depart: string;
  public aircraft: number;
  public timezone: boolean;
  public stoptype: any;
  public GMTPLUSORIGIN: string;
  public GMTPLUSDEST: string;
  public TIMEZONE: boolean;
  public routeID: number;
  public timeZone: string;
  public CreatedDate: string;
  public sectorfromtimezone: string;
  public sectortotimezone: string;
  public sectorseltimezone: string;
  public savedlegid: string;
  public from: string;
  public to: string;
  public legid: any;
  public sector: string;
  public fromICAO: string;
  public toICAO: string;
  public flyingSeconds: string;
  public Legstage: string;
  public countdownTime: any;
  public progressDate: string;
  public showStatus: string;
  public progress?: number;
  public DIST?: number;
  public ActualLanding?:any;
  public ActualTakeoff?:any;
}
