import { Component, OnInit, ViewChild } from '@angular/core';
import { Manufacturer } from '@app/setup/aircraft/aircraft-model';
import { AircraftServiceService } from '@app/setup/aircraft/aircraft-service.service';
import { MessageConfig } from '@app/shared/constants/constant';
import { CommonService } from '@app/shared/services/common.service';
import { DataService } from '@app/shared/services/data.service';
import { FirebaseDataService } from '@app/shared/services/firebase-data.service';
import { IonSearchbar, ModalController, NavParams } from '@ionic/angular';
import firebase from 'firebase';
@Component({
  selector: 'app-suggest-station',
  templateUrl: './suggest-station.component.html',
  styleUrls: ['./suggest-station.component.scss'],
})
export class SuggestStationComponent implements OnInit {
  @ViewChild('autofocus', { static: false }) public searchbar: IonSearchbar;
  public autocompleteItems;
  public autocomplete;
  public showSPinner;
  public stationList: any;
  public AircraftList: any;
  public exceptargument: string;
  public chunk0: any;
  public chunk1: any;
  public chunk2: any;
  public chunk3: any;
  public chunk4: any;
  public backpage: any;
  public selectedAirportName = '';
  public tempSearchList = [];
  public type: any;
  public Type: any;
  public valueType: string = '';
  public plattsIndexes: any;
  public nationalitydata;
  public aircraftTailNodata;
  public spinner: any;
  public alternatestationlist: any;
  private listOfManufacturer = new Array<Manufacturer>();
  private registertype = [];
  public ACregiteredCountry: any;
  public dataLoaded: boolean = false;
  private searchItemEv: any;
  public messageDisplay = MessageConfig;
  public selectedStaion = [];
  public selectedStaionID = [];
  public enableConfirm: boolean = false;
  public nodata:any;
  constructor(
    private navParams: NavParams,
    private dataService: DataService,
    private aircraftsetupSevice: AircraftServiceService,
    private fbDatebase: FirebaseDataService,
    private commonServiceProvider: CommonService,
    private modalController: ModalController
  ) {
    this.registertype = this.navParams.get('typeOfRegister');
    this.autocomplete = {
      query: '',
    };
  }

  public ngOnInit() {
    // this.dataService.fetchStations();
   }
  public ionViewWillEnter() {
    // setTimeout(() => this.searchbar.setFocus(), 600);
    this.spinner = true;
    this.backpage = this.navParams.get('backpage');
    this.type = this.navParams.get('type');
    const alternateStationData = this.navParams.get('sendData');
    this.valueType = this.navParams.get('valueType');
    switch (this.type) {
      case 'AircraftManufacturer':
        this.aircraftsetupSevice
          .getListOfAircraftManufacturerFromAssets()
          .then((manufacturer: Manufacturer[]) => {
            if (manufacturer != null) {
              this.spinner = false;
              this.listOfManufacturer = manufacturer;
              this.dataLoaded = true;
              this.autocompleteItems = this.listOfManufacturer;
              if (this.autocomplete != '' && this.autocomplete != undefined) {
                this.updateAircraftManufaturerSearch();
              }
            } else {
              this.commonServiceProvider.showErrorAlert();
            }
          });
        break;
      case 'aircraft':
        this.dataService
          .getListOfAircraftPerformanceBased()
          .then((aircraftList: string[]) => {
            if (!aircraftList) {
              this.commonServiceProvider.showErrorAlert();
            } else {
              this.spinner = false;
              this.AircraftList = aircraftList.filter((data: any) => {
                return (
                  data.MakeName ==
                  this.backpage.aircraftValue.AircraftData.basicAircraft
                    .ManufacturerName && data.Status == 1
                );
              });
              this.nodataMessage(this.AircraftList)
              this.dataLoaded = true;
              this.autocompleteItems = this.AircraftList;
              if (this.autocomplete.query) {
                this.updateSearch();
              }
            }
          });
        break;
      case 'baselocation':
        this.dataService.getListOfStation().then((stationDataList) => {
          if (stationDataList == 'Error') {
            this.spinner = false;
            this.commonServiceProvider.showErrorAlert();
          } else {
            this.spinner = false;
            this.stationList = stationDataList;
            this.chunk0 = this.stationList.slice(1, 20);
            this.chunk1 = this.stationList.slice(1, 1672);
            this.chunk2 = this.stationList.slice(0, 3345);
            this.chunk3 = this.stationList.slice(3346, 6689);
            this.chunk4 = this.stationList.slice(5019, 6690);
            if (
              this.type != 'AlternateAerodrome1' &&
              this.type != 'AlternateAerodrome2'
            ) {
              this.dataLoaded = true;
              this.autocompleteItems = this.chunk0;
              if (this.autocomplete != '' && this.autocomplete != undefined) {
                this.updateSearch();
              }
            }
          }
        });
        break;
      case 'aircraftTailNo':
        let countryName;
        const aircraftTailNodata = firebase
          .database()
          .ref('/aircraft_registercountry/')
          .on('value', (snapshot) => {
            countryName = snapshot.val();
          });
        const result = countryName.filter((item: any) => {
          return item.TextField;
        });
        this.spinner = false;
        this.dataLoaded = true;
        this.aircraftTailNodata = result;
        const aircraftTail =
          this.aircraftTailNodata != '' &&
            this.aircraftTailNodata != undefined &&
            this.searchItemEv != undefined
            ? this.SearchItem(this.searchItemEv)
            : '';
        break;
      case 'Nationality':
        let country;
        const nationalitydata = firebase
          .database()
          .ref('/Nationality/')
          .on('value', (snapshot) => {
            country = snapshot.val();
          });
        const resultdata = country.filter((item: any) => {
          return item.en_short_name;
        });
        this.spinner = false;
        this.dataLoaded = true;
        this.nationalitydata = resultdata;
        const nationality =
          this.nationalitydata != '' &&
            this.nationalitydata != undefined &&
            this.searchItemEv != undefined
            ? this.SearchItem(this.searchItemEv)
            : '';
        break;
      case 'RegCountry':
        let regcountrydata;
        const regcountrie = firebase
          .database()
          .ref('/aircraft_registercountry/')
          .on('value', (snapshot) => {
            regcountrydata = snapshot.val();
          });
        this.spinner = false;
        this.dataLoaded = true;
        this.ACregiteredCountry = regcountrydata;
        const regcountriey =
          this.ACregiteredCountry != '' &&
            this.ACregiteredCountry != undefined &&
            this.searchItemEv != undefined
            ? this.selectItem(this.searchItemEv)
            : '';
        break;
      case 'addSation':
        this.dataService.getListOfStation().then((stationDataList) => {
          if (stationDataList == 'Error') {
            this.spinner = false;
            this.commonServiceProvider.showErrorAlert().then((res) => {
              if (res) {
                this.ionViewWillEnter();
              }
            });
          } else {
            this.spinner = false;
            this.selectedStaion = this.backpage.stationArr;
            this.stationList = stationDataList;
            this.chunk0 = this.stationList.slice(1, 20);
            this.chunk1 = this.stationList.slice(1, 1672);
            this.chunk2 = this.stationList.slice(0, 3345);
            this.chunk3 = this.stationList.slice(3346, 6689);
            this.chunk4 = this.stationList.slice(5019, 6690);
            this.dataLoaded = true;
            this.autocompleteItems = this.chunk0;
            if (this.autocomplete != '' && this.autocomplete != undefined) {
              this.updateSearch();
            }
          }
        });
        break;
      case 'addCountry':
        this.dataService.getListOfCountry().then((stationDataList) => {
          if (stationDataList == 'Error') {
            this.spinner = false;
            this.commonServiceProvider.showErrorAlert().then((res) => {
              if (res) {
                this.ionViewWillEnter();
              }
            });
          } else {
            this.spinner = false;
            this.selectedStaion = this.backpage.stationArr;
            this.selectedStaionID = this.backpage.stiotionIdArr;
            this.stationList = stationDataList;
            this.chunk0 = this.stationList.slice(1, 20);
            this.chunk1 = this.stationList.slice(1, 1672);
            this.chunk2 = this.stationList.slice(0, 3345);
            this.chunk3 = this.stationList.slice(3346, 6689);
            this.chunk4 = this.stationList.slice(5019, 6690);
            this.dataLoaded = true;
            this.autocompleteItems = this.chunk0;
            if (this.autocomplete != '' && this.autocomplete != undefined) {
              this.updateSearch();
            }
          }
        });
        break;
      default:
        break;
    }
  }
  public nodataMessage(itemList) {
    if (!itemList.length) {
      this.nodata = true;
    } else {
      this.nodata = false;
    }
  }
  public ionViewDidEnter() {
    setTimeout(() => {
      const searchbarInputPromise = this.searchbar.getInputElement();
      searchbarInputPromise.then((searchbarInput) => {
        if (searchbarInput && document.activeElement !== searchbarInput) {
          searchbarInput.focus();
        }
      });
    }, 600);
    this.intializeitem();
  }
  public updateAircraftManufaturerSearch(ev?: any) {
    this.autocomplete.query = ev ? ev?.target?.value : this.autocomplete.query;
    const me = this;
    this.showSPinner = 1;
    this.autocompleteItems = [];
    const element1 = this.listOfManufacturer.filter(
      (data: any) =>
        data.makename
          .toUpperCase()
          .indexOf(this.autocomplete.query.toUpperCase()) >= 0
    );
    this.autocompleteItems = element1.splice(0, 20);
    this.showSPinner = 0;
  }
  public chooseItem(item: any) {
    switch (this.type) {
      case 'AircraftManufacturer':
        this.backpage.aircraftValue.AircraftData.basicAircraft.ManufacturerName =
          item.makename;
        this.backpage.aircraftValue.AircraftData.basicAircraft.AircraftType =
          '';
        this.modalController.dismiss(item);
        break;
      case 'aircraft':
        this.backpage.aircraftValue.AircraftData.basicAircraft.AircraftType =
          item.ModelName;
        this.backpage.aircraftValue.AircraftData.basicAircraft.EngineName =
          item.VariantName;
        this.backpage.aircraftValue.AircraftData.basicAircraft.UUID = item.UUID;
        this.backpage.aircraftValue.AircraftData.basicAircraft.acicaocode =
          item.ICAOCode;
        this.backpage.getAircraftDetailsByUUID();
        this.spinner = false;
        this.modalController.dismiss(item);
        break;
      case 'baselocation':
        this.backpage.aircraftValue.AircraftData.basicAircraft.from =
          item.ICAOCode +
          ', ' +
          item.AirportName +
          ', ' +
          item.CityName +
          ', ' +
          item.Country;
        this.backpage.aircraftValue.AircraftData.basicAircraft.showFrom =
          item.ICAOCode +
          ', ' +
          item.AirportName +
          ', ' +
          item.CityName +
          ', ' +
          item.Country;
        this.modalController.dismiss(item);
        this.spinner = false;
        break;
      case 'aircraftTailNo':
        this.modalController.dismiss(item);
        break;
      case 'Nationality':
        this.modalController.dismiss(item);
        break;
      case 'RegCountry':
        this.spinner = false;
        this.selectedStaion = item.TextField;
        this.backpage.quoteArr.registration = item;
        this.backpage.quoteArr.prefix = item.RegCode;
        this.backpage.portChange(item.ICAOflag);
        this.modalController.dismiss(item);
      default:
        break;
    }
  }
  public doInfinite(completeStatus) {
    switch (this.type) {
      case 'AircraftManufacturer':
        const manufaturerList = this.listOfManufacturer.slice(
          this.autocompleteItems.length + 1,
          this.autocompleteItems.length + 20
        );
        manufaturerList.forEach((element) => {
          this.autocompleteItems.push(element);
        });
        completeStatus.target.complete();
        break;
      case 'aircraft':
        const tempAircraftList = this.AircraftList.slice(
          this.autocompleteItems.length,
          this.autocompleteItems.length + 20
        );
        tempAircraftList.forEach((element) => {
          this.autocompleteItems.push(element);
        });
        completeStatus.target.complete();
        break;
      case 'Nationality':
        const nationalityIndex = this.nationalitydata?.slice(
          this.autocompleteItems?.length + 1,
          this.autocompleteItems?.length + 20
        );
        nationalityIndex.forEach((element) => {
          this.autocompleteItems.push(element);
        });
        completeStatus.target.complete(this.nationalitydata);

      case 'RegCountry':
        if (typeof this.ACregiteredCountry != undefined) {
          const tempACregList = this.ACregiteredCountry?.slice(
            this.autocompleteItems?.length + 1,
            this.autocompleteItems?.length + 20
          );
          tempACregList?.forEach((element) => {
            this.autocompleteItems.push(element);
          });
        }
        completeStatus.target.complete(this.ACregiteredCountry);
        break;
      case 'addSation':
        const fuelstation = this.stationList.slice(
          this.autocompleteItems.length + 1,
          this.autocompleteItems.length + 20
        );
        fuelstation.forEach((element) => {
          this.autocompleteItems.push(element);
        });
        completeStatus.target.complete();
        break;
      case 'addCountry':
        const permitStation = this.stationList.slice(
          this.autocompleteItems.length + 1,
          this.autocompleteItems.length + 20
        );
        permitStation.forEach((element) => {
          this.autocompleteItems.push(element);
        });
        completeStatus.target.complete();
        break;
        case 'baselocation':
        const baseLocation = this.stationList.slice(
          this.autocompleteItems.length + 1,
          this.autocompleteItems.length + 20
        );
        baseLocation.forEach((element) => {
          this.autocompleteItems.push(element);
        });
        completeStatus.target.complete();
        break;
      default:
        break;
    }
  }
  public dismiss(passData = false) {
    this.modalController.dismiss(passData ? this.selectedStaion : undefined);
  }

  public updateSearchAircraft(ev: any) {
    this.autocomplete.query = ev ? ev?.target?.value : this.autocomplete.query;
    const me = this;
    this.autocompleteItems = [];
    this.spinner = 1;
    if (this.autocomplete.query == '') {
      this.autocompleteItems = this.AircraftList.slice(1, 50);
      return;
    }
    this.autocompleteItems = this.AircraftList
      ? this.AircraftList.filter(
        (data) =>
          [data.ICAOCode, data.ModelName, data.MakeName].find((e) =>
            e.toUpperCase().startsWith(this.autocomplete.query.toUpperCase())
          ) !== undefined
      )
      : [];
    this.spinner = 0;
  }

  public selectItem(item) {
    let selectedStation = '';
    if (item.ICAOCode != '') {
      selectedStation = selectedStation + item.ICAOCode;
    } else if (item.IATACode != '') {
      selectedStation = selectedStation + item.IATACode;
    } else if (item.IATACode != '') {
      selectedStation = selectedStation + item.IATACode;
    } else if (item.FAAIdent != ' ') {
      selectedStation = selectedStation + item.FAAIdent;
    } else if (item.IATACode != '') {
      selectedStation = selectedStation + item.IATACode;
    }
    const index: number = this.selectedStaion.indexOf(selectedStation);
    if (index == -1) {
      this.selectedStaion.push(selectedStation);
    } else {
      this.selectedStaion.splice(index, 1);
    }
  }

  public saveSelecteditem() {
    this.dismiss(true);
  }

  public selectItemCountry(item) {
    const index: number = this.selectedStaion.indexOf(item.CountryName);
    if (index == -1) {
      this.selectedStaion.push(item.CountryName);
      this.selectedStaionID.push(item.CountryID);
    } else {
      this.selectedStaion.splice(index, 1);
      this.selectedStaionID.splice(index, 1);
    }
  }

  public selectRegisteredCountry(item) {
    this.selectedStaion = item;
  }

  public updateSearch(ev?: any) {
    this.enableConfirm = true;
    this.autocomplete.query = ev ? ev?.target?.value : this.autocomplete.query;
    const me = this;
    this.showSPinner = 1;
    this.autocompleteItems = [];
    const tempArr = [];
    if (this.type != 'airCraft' && this.stationList != undefined) {
      if (this.autocomplete.query == '') {
        if (
          this.type == 'AlternateAerodrome1' ||
          this.type == 'AlternateAerodrome2'
        ) {
          me.autocompleteItems = this.stationList;
        } else {
          me.autocompleteItems = this.chunk0;
        }
        this.showSPinner = 0;
        return;
      }
      this.exceptargument = this.navParams.get('param1');
      if (this.type != 'addCountry') {
        if (
          this.type == 'AlternateAerodrome1' ||
          this.type == 'AlternateAerodrome2'
        ) {
          const element1 = this.stationList.filter(
            (data) =>
              data.searchIndexitem
                .toUpperCase()
                .indexOf(this.autocomplete.query.toUpperCase()) >= 0
          );
          this.tempSearchList = element1;
          element1.sort((a: any, b: any): number => {
            const x = a.ICAOCode.toLowerCase();
            const y = this.autocomplete.query.toLowerCase();
            return x === y ? -1 : x < y ? 0 : 1;
          });
          const dummyelement2 = [];
          dummyelement2.push(...element1);
          if (
            dummyelement2.filter((item) => {
              return (
                item.IATACode.toLowerCase() ==
                this.autocomplete.query.toLowerCase()
              );
            }).length
          ) {
            element1.sort((a: any, b: any): number => {
              const x = a.IATACode.toLowerCase();
              const y = this.autocomplete.query.toLowerCase();
              return x === y ? -1 : x < y ? 0 : 1;
            });
          }
          me.autocompleteItems = element1.splice(0, 20);
        } else {
          const element1 = this.stationList.filter(
            (data) =>
              data.searchIndexString
                .toUpperCase()
                .indexOf(this.autocomplete.query.toUpperCase()) >= 0
          );
          this.tempSearchList = element1;
          element1.sort((a: any, b: any): number => {
            const x = a.ICAOCode.toLowerCase();
            const y = this.autocomplete.query.toLowerCase();
            return x === y ? -1 : x < y ? 0 : 1;
          });
          const dummyelement2 = [];
          dummyelement2.push(...element1);
          if (
            dummyelement2.filter((item) => {
              return (
                item.IATACode.toLowerCase() ==
                this.autocomplete.query.toLowerCase()
              );
            }).length
          ) {
            element1.sort((a: any, b: any): number => {
              const x = a.IATACode.toLowerCase();
              const y = this.autocomplete.query.toLowerCase();
              return x === y ? -1 : x < y ? 0 : 1;
            });
          }
          me.autocompleteItems = element1.splice(0, 20);
        }

        this.showSPinner = 0;
      } else {
        const element1 = this.stationList.filter(
          (data) =>
            data.CountryName.toUpperCase().indexOf(
              this.autocomplete.query.toUpperCase()
            ) >= 0
        );
        element1.sort((a: any, b: any): number => {
          const x = a.CountryName.toLowerCase();
          const y = this.autocomplete.query.toLowerCase();
          return x === y ? -1 : x < y ? 0 : 1;
        });
        this.tempSearchList = element1;
        me.autocompleteItems = element1.splice(0, 20);
        this.showSPinner = 0;
      }
    } else {
      if (this.AircraftList != undefined) {
        if (this.autocomplete.query == '') {
          this.showSPinner = 0;
          me.autocompleteItems = this.AircraftList.slice(1, 50);
          return;
        }
        const element5 = this.AircraftList.filter(
          (data) =>
            data.AircraftICAOId.toUpperCase().startsWith(
              this.autocomplete.query.toUpperCase()
            ) == true ||
            data.EngineName.toUpperCase().startsWith(
              this.autocomplete.query.toUpperCase()
            ) == true ||
            data.AircraftType.toUpperCase().startsWith(
              this.autocomplete.query.toUpperCase()
            ) == true
        );
        me.autocompleteItems = element5;
        this.showSPinner = 0;
      }
    }
  }
  public intializeitem() {
    // this.zone.run(() => {
    if (this.type != 'Nationality') {
      if (this.type == 'aircraftTailNo') {
        firebase
          .database()
          .ref('/aircraft_registercountry/')
          .on('value', (snapshot) => {
            this.aircraftTailNodata = snapshot.val();
          });
      } else {
        firebase
          .database()
          .ref('/aircraft_registercountry/')
          .on('value', (snapshot) => {
            this.ACregiteredCountry = snapshot.val();
          });
      }
    } else {
      firebase
        .database()
        .ref('/Nationality/')
        .on('value', (snapshot) => {
          this.nationalitydata = snapshot.val();
        });
    }
    // });
  }

  public saveSelecteditemCountry() {
    this.backpage.stationArr = this.selectedStaion;
    this.backpage.selectedStaion = this.selectedStaion;
    this.backpage.stiotionIdArr = this.selectedStaionID;
    this.dismiss();
  }

  public SearchItem(ev: any) {
    this.searchItemEv = ev;
    this.intializeitem();
    const val = ev.target.value;
    if (val && val.trim() != '') {
      if (this.type != 'Nationality') {
        if (this.type == 'aircraftTailNo') {
          this.aircraftTailNodata = this.aircraftTailNodata.filter(
            (item: any) => {
              return (
                item.RegCode.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
                item.Country.toLowerCase().indexOf(val.toLowerCase()) > -1
              );
            }
          );
        } else if (this.type == 'RegCountry') {
          if (typeof this.ACregiteredCountry != undefined) {
            this.ACregiteredCountry = this.ACregiteredCountry.filter((item) => {
              return (
                item.RegCode.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
                item.Country.toLowerCase().indexOf(val.toLowerCase()) > -1
              );
            });
          }
        }
      } else {
        this.nationalitydata = this.nationalitydata.filter((item: any) => {
          return (
            item.en_short_name.toLowerCase().indexOf(val.toLowerCase()) > -1
          );
        });
      }
    }
  }
}
