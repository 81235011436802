import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormatFilter',
})
export class DateFormatFilterPipe implements PipeTransform {
  public transform(date: string, type?: string) {
    if (type == 'hour') {
      return moment(date, ['YYYY-MM-DD HH:mm', 'd/MMM/yy HH:mm']).format(
        'MMM DD, YYYY HH:mm'
      );
    } else {
      return moment(date, ['DD/MM/YYYY', 'YYYY-MM-DD']).format('MMM DD, YYYY');
    }
  }
}
