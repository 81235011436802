export interface Revision {
    SRID: string;
    Comment: string;
    FileName: string;
    UserId: string;
    Date: string;
}
export class UnreadNotification {
    public chatDetails: ChatInfo;
    public photoUrl: string;
    public name: string;
    public count: number;
    public toId: string;
  }
export interface Leg {
    BLKTIME: string;
    DIST: string;
    ETDGMT: string;
    ETAGMT: string;
    ETALT: string;
    ETDLT: string;
    departDate: string;
    depart: Date;
    aircraft: string;
    timezone: string;
    sector: string;
    stoptype: string;
    GMTPLUSORIGIN: string;
    GMTPLUSDEST: string;
    TIMEZONE: string;
    routeID: string;
    noOfCrew: string;
    noOfPax: string;
    flightPlanresponseID: string;
    fuelPolicy: string;
    adequate: string;
    alt1: string;
    alt2: string;
    depAlt: string;
    etops: string;
    flightLvelalt1?: any;
    flightLvelalt2?: any;
    cruiseSpeed: string;
    maxflightlevelUnit: string;
    flightLevelType: string;
    extrafuel: string;
    fuelUnit: string;
    travelDistrance: string;
    timeZone: string;
    CreatedDate: string;
    sectorfromtimezone: string;
    sectortotimezone: string;
    Sector: string;
    sectorseltimezone: string;
    savedlegid: string;
    from: string;
    to: string;
    legid: string;
    id: string;
    fromICAO: string;
    toICAO: string;
    LegType: string;
    removed: boolean;
    Arrivaltime: Date;
    ActualLanding?:any;
    ActualTakeoff?:any;
    flyingSeconds: number;
    Legstage: string;
    FlightRule: string;
    FlightType: string;
    Operation: string;
    generatedcbparray: any;
}

export interface Srdata {
    Legs: Leg[];
    ClientID: string;
    ClientOperationContactName: string;
    Timezone: string;
    Idclient: string;
    WayType: string;
    Regd: string;
    AirID: string;
    DateCreated: Date;
}

export interface List {
    ServiceRequestPassengerID: string;
    ClientPassengerID: string;
    SRID: string;
    SRSecID: string;
    Sector: string;
    Signoff_Flag: string;
    SignatureURL: string;
    ClientID: string;
    ClientPassengerName: string;
    ClientPassengerImageURL: string;
    ClientPassengerContactNo: string;
    ClientPassengerEmailID: string;
    ClientPassengerDob: string;
    ClientPassengerNationality: string;
    ClientPassengerPassport: string;
    PassportRegistered: string;
    PassportExpiry: string;
    flag: string;
    callingCodes: string;
}

export interface List2 {
    ServiceRequestCrewID: string;
    ClientCrewID: string;
    SRID: string;
    SRSecID: string;
    Sector: string;
    Signoff_Flag: string;
    SignatureURL: string;
    ClientID: string;
    ClientCrewName: string;
    ClientCrewContactNo: string;
    ClientCrewImageURL: string;
    ClientCrewEmailID: string;
    ClientCrewDob: string;
    ClientCrewNationality: string;
    ClientCrewPassport: string;
    PassportRegistered: string;
    PassportExpiry: string;
    Duties: string;
    flag: string;
    callingCodes: string;
}
export interface LegType {
    Sector: string;
    SRSecID: string;
    legid: string;
    Type: string;
}

export interface OpenClientSideMenu {
    srid: string;
    dateOfCreate: string;
    status: string;
    Revision: Revision[];
    srdata: Srdata;
    attachements: any[];
    assignedpaxdetails: any[];
    assgnedcrewdetails: any[];
    LegType: LegType[];
    dof: string;
    vendorArr: any[];
    revisionstcedisplay: any[];
    viewQuote: string;
     ActualLanding?:any;
     ActualTakeoff?:any;
}

export interface OpenClientSideMenuResponse {
    ResponseCode: string;
    APIName: string;
    OpenClientSideMenu: OpenClientSideMenu;
    ResponseStatus: string;
}
export interface GetVendorTripsUpcomingActivity {
    accompleted: any;
    srairportchargesarray: any;
    gdstatus: any[];
    LegType: LegType[];
    ghcompleted: any[];
    srgharray: any[];
    permissionarray: any;
    assgnedcrewdetails: any[];
    assignedpaxdetails: any[];
    srdata: Srdata;
    srfuelarray: any[];
    srfuelInarray: any[];
    srcbparray: any[];
    srpermitsarray: any;
    permitsdetailarray: any;
    servicesarray: any;
    sectorservicesarray: any;
    upcomingsectorservicesarray: any;
    upcomingservicesarray: any[];
    SRID: string;
    sectors: Sector[];
    QuotesStatus: string;
    Station: any;
    clientDetails: ClientDetails;
    Sector?: Sector[];
    ActualLanding?:any;
    ActualTakeoff?:any;
    status?:any;
}
export interface ClientDetails {
    firebase_uid: string;
    ClientID: string;
    UserID: string;
    ClientName: string;
    ClientInvoiceAddressline1: string;
    ClientInvoiceAddressline2: string;
    ClientInvoiceAddressline3: string;
    ClientContactNo: string;
    ClientOperationContactName: string;
    CreatedDate: string;
    ClientOperationContactNo: string;
    ClientOperationContactEmailID: string;
    ClientAccDeptContactName: string;
    ClientAccDeptContactNo: string;
    ClientAccDeptContactEmailID: string;
    Verified: string;
    flag: string;
    logo: string;
    Timezone: string;
    currencyCode: string;
    name: string;
    CompanyName: string;
    CompanyDetails: string;
    PAN_No: string;
    Service_TaxNo: string;
    PaymentType: string;
    NumberOfAircraft: string;
    Description: string;
    PrivacyForEmail: string;
    PrivacyForNotification: string;
    CompanyEmail: string;
    CompanyAddress: string;
    fuelUnit: string;
    Pincode: string;
    State: string;
    Country: string;
    Documentpath: string;
    filenametitle: string;
    clientcallingCodes: string;
    callingCodeOpscontact: string;
    AccountcallingCodes: string;
    STDID?: any;
}
export interface Sector {
    sector: string;
    legid: string;
}
export interface GetVendorTripsUpcomingActivityResponse {
    ResponseCode: string;
    APIName: string;
    GetVendorTripsUpcomingActivity: GetVendorTripsUpcomingActivity;
    ResponseStatus: string;
}
export class TripDetails {
    public tripId: string;
    public status: string;
    public showStatus: string;
    public aircraftId: string;
    public dateCreated: Date;
    public info = new Array<TripInfo>();
    public legInfo = new Array<LegDetails>();
    public chatInfo = new Array<LegListing>();
}
export class TripInfo {
    public name: string;
    public fileName: string;
    public path: string;
    public visible: boolean;
    public userType: string;
    public uploadedFile?:string;
    constructor(name: string, fileName: string, path: string, visible: boolean,uploadedFile?) {
        this.name = name;
        this.fileName = fileName;
        this.path = path;
        this.visible = visible;
        this.uploadedFile = uploadedFile;
    }
}
export class StationDetails {
    public name: string;
    public timeZone: string;
    constructor(name: string, timeZone: string) {
        this.name = name;
        this.timeZone = timeZone;
    }
}
export class LegDetails {
    public fromStation: StationDetails;
    public toStation: StationDetails;
    public aircraftName: string;
    public departDate: string;
    public blkTime: string;
    public distance: string;
    public legid: number;
    public createdDate: string;
    public legStage: string;
    public timerCount: number;
    public downloads: TripInfo[];
    public downloadOpen: boolean = false;
    public progressDate: string;
    public sector: string;
    public routeID: number;
    public progress?: number;
    public countdownTime: any;
    public showStatus: string;
    public arrivalTime: any;
    public ActualLanding?:any;
    public ActualTakeoff?:any;
    public status? :any;
    constructor(fromStation: StationDetails,
        toStation: StationDetails,
        aircraftName: string,
        departDate: string,
        blkTime: string, distance: string,
        legid: number, createdDate: string,
        legStage: string, downloads: TripInfo[],
        downloadOpen: boolean, progressDate: string,
        countdownTime: any, sector: string,
        routeID: number, arrivalTime: any,ActualLanding:any, ActualTakeoff:any,status:any) {
        this.fromStation = fromStation;
        this.toStation = toStation;
        this.aircraftName = aircraftName;
        this.departDate = departDate;
        this.blkTime = blkTime;
        this.distance = distance;
        this.legid = legid;
        this.createdDate = createdDate;
        this.legStage = legStage;
        this.downloads = downloads;
        this.downloadOpen = downloadOpen;
        this.progressDate = progressDate;
        this.countdownTime = countdownTime;
        this.sector = sector;
        this.routeID = routeID;
        this.arrivalTime = arrivalTime;
        this.ActualLanding=ActualLanding;
        this.ActualTakeoff=ActualTakeoff;
        this.status=status;
    }
}
export class LegListing {
    public service = new Array<ServiceListing>();
    public sector: string;
    public legStatus: string;
    public dropDwonStatus: boolean;
    public legId: number;
    constructor(
        service: any,
        sector: string,
        legStatus: string,
        legId: number,
        dropDwonStatus: boolean,
    ) {
        this.service = service;
        this.sector = sector;
        this.legStatus = legStatus;
        this.legId = legId;
        this.dropDwonStatus = dropDwonStatus;
    }
}
export class ServiceListing {
    public uid: string;
    public serviceName: string;
    public vendorLogo: string;
    public station: string;
    public vendorName: string;
    public legId: number;

    constructor(service: any) {
        if (service) {
            this.uid = service.firebase_uid ? service.firebase_uid : service.firebaseuid;
            this.serviceName = service.ServiceRequested;
            this.vendorLogo = service.logo || "assets/imgs/no_image_available.svg";
            this.station = service.LocationName;
            if (service.legId) {
                this.legId = service.legId;
            }
            switch (this.serviceName) {
                case "permits":
                    this.serviceName = "Overflight Permits";
                    break;
                case "fuel":
                    this.serviceName = "Fuel";
                    break;
                case "groundhandling":
                    this.serviceName = "Handling";
                    break;
                case "airportcharges":
                    this.serviceName = "Airport Charges";
                    break;
                case "crew briefing":
                    this.serviceName = "Flight Planning";
                    break;
            }
        }
    }
}
export class VendorServiceListing {
    public ServiceRequested: string;
    public LocationName: string;
    public logo: string;
    public firebaseuid: string;
    public legId: number;

    constructor(
        ServiceRequested: string,
        LocationName: string,
        legId: number,
        clientDetails: any,
    ) {
        this.ServiceRequested = ServiceRequested;
        this.LocationName = LocationName;
        this.logo = clientDetails.logo;
        this.firebaseuid = clientDetails.firebase_uid;
        this.legId = legId;
    }
}
export class NotificationChat {
    public showActiveTripChat: number;
    public type: string;
    public readTabActive: string;
  }
export class ChatInfo {
    public toChatId: string;
    public tripId: number;
    public legId: number;
    public location: string;
    public service: string;
    public type: string;
    public chatTabActive?: string;

    constructor(
        toChatId: string,
        tripId: number,
        legId: number,
        location: string,
        service: string,
        type: string,
        chatTabActive?: string,
    ) {
        this.toChatId = toChatId;
        this.tripId = tripId;
        this.legId = legId;
        this.location = location;
        this.service = service;
        this.type = type;
        this.chatTabActive = chatTabActive;
    }
}
export class ProfileData {
    public photoUrl: string;
    public status: string;
    public name: string;
    constructor(profileDetails: any) {
        if (profileDetails) {
            this.photoUrl =
                profileDetails.Image || "assets/imgs/no_image_available.svg";
            this.status = profileDetails.status || "Offline";
            this.name = profileDetails.Name || "No Name";
        } else {
            this.photoUrl = "assets/imgs/no_image_available.svg";
            this.status = "";
            this.name = "No Name";
        }
    }
}