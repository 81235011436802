import { UtilService } from './../../services/util.service';
import {
    Component,
    ChangeDetectorRef,
    Input,
    Output,
    EventEmitter,
    HostListener,
    OnInit,
    ViewChild,
    forwardRef,
    ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Capacitor } from '@capacitor/core';
import { Platform } from '@ionic/angular';

export const CITY_PICKER_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => IonTagsInput),
    multi: true
};

@Component({
    selector: 'ion-tags-input',
    providers: [CITY_PICKER_VALUE_ACCESSOR],
    template: `
  <div class="iti-tags-wrap">
    <ion-tag  *ngFor="let tag of _tags; let $index = index;"
              [tag]="tag"
              [color]="cssColor"
              [allowClear]="!hideRemove && !readonly"
              (onClear)="btnRemoveTag($index)"
              [ngClass]="{'tranfrom_txt': _tagClass}"
              [tagClass]="_tagClass">
    </ion-tag>
  </div>
  <input #tagsInput
         [hidden]="readonly"
         [disabled]="readonly"
         [ngClass]="{'iti-input-cmp': _tagClass}"
         class="iti-input" [type]="type"
         [placeholder]="placeholder"
         [(ngModel)]="_editTag"
         (blur)="blur()"
         (keyup.backspace)="keyRemoveTag(); false"
         (keyup)="separatorStrAddTag()"
         (keydown.enter)="keyAddTag()"
         (keydown.space)="keyAddTag()"
         autocomplete="off"
         >
  `,
    host: {
        'class': 'tit-border-color ion-tags-input',
        '[style.border-bottom-color]': '_isFocus ? cssColor : null',
        '[class.active]': '_isFocus',
        '[class.readonly]': 'readonly'
    },
    encapsulation: ViewEncapsulation.None,
    styleUrls: [
        /** COMPONENT_STYLE */
        './ion-tags-input.scss'
    ]
})
export class IonTagsInput implements ControlValueAccessor, OnInit {
    public _tagClass: boolean = false;
    public _once: boolean = false;
    @Input() public mode: string = "'md'";
    @Input() public readonly: boolean = false;
    @Input() public hideRemove: boolean = false;
    @Input() public maxTags: number = -1;
    @Input() public placeholder: string = '+';
    @Input() public type: string = 'text';
    @Input() public separatorStr: string = ',';
    @Input() public canEnterAdd: boolean = true;
    @Input() public canBackspaceRemove: boolean = true;
    @Input() public verifyMethod: (tagSrt: string) => boolean;
    @Input()
    set color(value: string) {
        this.cssColor = value;
    }
    @Input()
    set once(value: boolean | string) {
        if (typeof value === 'string') {
            this._once = true;
        } else {
            this._once = value;
        }
    }
    get once(): boolean | string {
        return this._once;
    }
    @Input() set tagClass(tagValue: boolean) {
        this._tagClass = tagValue;
    }

    @Output() public onChange: EventEmitter<any> = new EventEmitter();
    @Output() public ionFocus: EventEmitter<any> = new EventEmitter();
    @Output() public ionBlur: EventEmitter<any> = new EventEmitter();
    @ViewChild('tagsInput') public input: any;

    public _editTag: string = '';
    public _tags: string[] = [];
    public _isFocus: boolean = false;
    public _onChanged: any;
    public _onTouched: any;
    public cssColor: string;
    constructor(public plt: Platform, public ref: ChangeDetectorRef,
        public utilService: UtilService) {
    }

    public ngOnInit(): void {
        if (this.mode === '') {
            this.plt.ready().then(() => {
                this.initMode();
            })
        }
    }

    public keyAddTag(): any {
        const tagStr = this._editTag.trim();
        if (!this.canEnterAdd) return;
        if (!this.verifyTag(tagStr)) return;
        if (this.once && !this.isOnce(tagStr)) {
            this._editTag = '';
            return;
        }
        this.pushTag(tagStr);
    }

    public separatorStrAddTag(): any {
        const lastIndex: number = this._editTag.length - 1;
        let tagStr: string = '';
        if (!this.separatorStr) return;

        if (this._editTag[lastIndex] === this.separatorStr) {
            tagStr = this._editTag.split(this.separatorStr)[0].trim();

            if (this.verifyTag(tagStr) && this.isOnce(tagStr)) {
                this.pushTag(tagStr);
            } else {
                this._editTag = '';
            }
        }
    }

    public keyRemoveTag(): any {
        if (!this.canBackspaceRemove) return;
        if (this._editTag === '') {
            this.removeTag(-1);
            this._editTag = '';
        }
    }

    public btnRemoveTag($index: number): any {
        this.removeTag($index);
    }

    public verifyTag(tagStr: string): boolean {

        if (typeof this.verifyMethod === 'function') {
            if (!this.verifyMethod(tagStr)) {
                this._editTag = '';
                return false;
            } else {
                return true;
            }
        }

        if (!tagStr.trim()) {
            this._editTag = '';
            return false;
        } else {
            return true;
        }
    }

    public pushTag(tagStr: string): any {
        if (this.maxTags !== -1 && this._tags.length >= this.maxTags) {
            this._editTag = '';
            return;
        }
        this._tags.push(tagStr.trim());
        this.ref.detectChanges();
        this.onChange.emit(this._tags);
        this._editTag = '';
    }

    public removeTag($index: number): any {
        if (this._tags.length > 0) {
            if ($index === -1) {
                this._tags.pop();
                this.onChange.emit(this._tags);
            } else if ($index > -1) {
                this._tags.splice($index, 1);
                this.onChange.emit(this._tags);
            }
        }
    }

    public isOnce(tagStr: any): boolean {
        const tags: string[] = this._tags;
        return tags.every((e: string): boolean => {
            return e !== tagStr
        });
    }
    @HostListener('click', ['$event'])
    public _click(ev: UIEvent): any {
        if (!this._isFocus) {

        }
        this.focus();
        ev.preventDefault();
        ev.stopPropagation();
    }

    public blur(): any {
        const tagStr = this._editTag.trim();
        if (this._isFocus) {
            this._isFocus = true;
            this.ionBlur.emit(this._tags);
            if (this.verifyTag(tagStr) && this.isOnce(tagStr)) {
                this.pushTag(tagStr);
            } else {
                this._editTag = '';
            }
        }
    }

    public focus(): any {
        if (!this._isFocus) {
            this._isFocus = true;
            this.input.nativeElement.focus();
            this.ionFocus.emit(this._tags);
        }
    }

    public writeValue(val: any): void {
        this._tags = val;
    }

    public registerOnChange(fn: any): void {
        this._onChanged = fn;
        this.setValue(this._tags);
    }

    public registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    public setValue(val: any): any {
        this._tags = val;
        if (this._tags) {
            this._onChanged(this._tags);
        }
    }

    public initMode(): any {
        this.mode = Capacitor.getPlatform() === "ios" ? 'ios' : Capacitor.getPlatform() === "ios" ? 'md' : 'md';
    }

}
