
import { SetupService } from './../services/setup.service';
import { CommonService } from '@app/shared/services/common.service';
import { ModalController, PopoverController } from '@ionic/angular';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MessageConfig } from '@app/shared/constants/constant';
import { Storage } from '@ionic/storage-angular';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
@Component({
  selector: 'app-pin',
  templateUrl: './pin.page.html',
  styleUrls: ['./pin.page.scss'],
})
export class PinPage implements OnInit {
  public ngOnInit() {}
  @ViewChild('pinFocus') public pinFocus: ElementRef;
  public enteredPin = new Pin();
  public error: string = '';
  public loader: boolean = false;
  public disableSubmitButton: boolean = false;
  public MessageConfig = MessageConfig;
  constructor(
    private viewCtrl: ModalController,
    private popoverCtrl: PopoverController,
    private commonService: CommonService,
    private setupService: SetupService,
    private router: Router,
    public storage: Storage,
    private keyboard:Keyboard
  ) {}
  public ionViewWillEnter() {}
  public ngAfterViewInit() {
    this.setFocus();
  }

  public setFocus() {
    setTimeout(() => {
      this.pinFocus.nativeElement.focus();
    }, 500);
  }
  public close() {
    this.popoverCtrl.dismiss();
  }
  public next(nextElement, value) {
    this.error = '';
    if (this.enteredPin.pin1) {
      this.keyboard.hide()
    } else {
      if (value) {
        nextElement.focus();
      }
    }
  }
  public async verify() {
    if (this.enteredPin.pin1) {
      this.loader = true;
      const pin = String(this.enteredPin.pin1);
      this.setupService.verifyPin(Number(pin)).then(
        (res: any) => {
          this.loader = false;
          if (res.VerifyUserPin.Verification == 'Success') {
            this.commonService
              .VerifyAccessPin(res.VerifyUserPin.ConfirmationHashKey)
              .then(
                async (data: any) => {
                  if (data) {
                    await this.storage.set(
                      'IS_SETUP_PIN_VERIFIED',
                      data.PinVerified
                    );
                    if (data.PinVerified === true) {
                      this.commonService.DeleteVerifiedPinAccessNode(
                        res.VerifyUserPin.ConfirmationHashKey
                      );
                    }
                    this.close();
                  }
                },
                (error) => {
                  this.error = MessageConfig.verifyPinError;
                  this.enteredPin = new Pin();
                }
              );
          } else {
            this.error = MessageConfig.verifyPinError;
            this.enteredPin = new Pin();
          }
        },
        (error) => {
          if (
            error.statusText == 'Unauthorized' ||
            (error.statusText == 'OK' &&
              (error.error.VerifyUserPin == 'Unauthorized' ||
                error.error.VerifyUserPin == 'Pin Blocked'))
          ) {
            this.disableSubmitButton = true;
            this.storage.set('IS_SETUP_PIN_VERIFIED', false);
          }
          this.loader = false;
        }
      );
    }
  }
  public generatePin() {
    this.commonService
      .showConfirmAlert(MessageConfig.PinConfirmation)
      .then((data) => {
        if (data) {
          this.loader = true;
          this.setupService.setPin().then(
            (res: any) => {
              this.loader = false;
              if (res.SetUserPin && res.SetUserPin == 'restricted') {
                this.commonService.showToastMessage(
                  MessageConfig.RestrictedPin
                );
              } else if (res.SetUserPin) {
                this.commonService.showToastMessage(MessageConfig.GetPin);
              }
            },
            (error) => {
              this.loader = false;
              this.error = MessageConfig.ContactPluginIssue;
            }
          );
        }
      });
  }
}
export class Pin {
  public pin1: string;
}
