import { Events } from './../../../shared/services/events.service';
import { Component, OnInit } from '@angular/core';
import { ChatInfo, ServiceListing, TripDetails } from '@app/right-menu/models/right-menu.model';
import { SideMenuService } from '@app/right-menu/services/side-menu.service';

@Component({
  selector: 'app-trip-chat',
  templateUrl: './trip-chat.component.html',
  styleUrls: ['./trip-chat.component.scss'],
})
export class TripChatComponent implements OnInit {
  public tripDataDetails: TripDetails;
  constructor(private sidemenuService: SideMenuService,
    private events: Events
  ) {
    this.sidemenuService.tripData.subscribe((res: any) => {
      this.tripDataDetails = res;
    });
  }

  public ngOnInit() { }

  public makeChat(service: ServiceListing, legid: number) {
    this.events.publish(
      "openChat",
      new ChatInfo(
        service.uid,
        +this.tripDataDetails.tripId,
        legid,
        service.station,
        service.serviceName,
        "LiveChat",
        "tripChats",
      ),
    );
  }

}
