import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServerConnectService } from '@app/core/services/server-connect.service';
import { UtilService } from '@app/core/services/util.service';
import { CommonService } from '@app/shared/services/common.service';
import { FirebaseDataService } from '@app/shared/services/firebase-data.service';
import {
  activityUriConfig,
  NFPURI,
} from '@app/trips/constants/trip-config-url';
import { Leg } from '@app/trips/model/route.model';
import { StationDetails } from '@app/trips/schedule-creation/model/schedule-model';
import { EnterTripDetailsService } from '@app/trips/services/enter-trip-details.service';
import { TripRouteService } from '@app/trips/services/trip-route.service';
import CryptoJS from 'crypto-js';
import { Subject } from 'rxjs';
import {
  AiracCycle,
  Alternate,
  ATCRouteDetails,
  BaronConfig,
  CBP,
  DownloadFlightPlan,
  EtopsConfiguration,
  Flight,
  FlightEtops,
  FlightPlanTitles,
  FlightPlanUrls,
  Fuel,
  GetAirportInformationResponse,
  GetATCStripByRouteID,
  GetATCStripByRouteIDRoot,
  GetWxResponse,
  Payload,
  ProcessUploadCBP,
  ProcessUploadCBPResponse,
  Route,
  TripRoute,
  WeatherNotam,
} from '../../flight-plan-constant';

@Injectable({
  providedIn: 'root',
})
export class FlightPlanService {
  public flightPlanningAccordionOpenStatus: string;
  public payload = new Payload();
  public atcDetails = new Flight();
  public route = new Route();
  public fuel = new Fuel();
  public alternate = new Alternate();
  public atcRouteDetails = new ATCRouteDetails();
  public flightEtops = new FlightEtops();
  public etpConfiguration = new EtopsConfiguration();
  public etopsOnCompute: any;
  public currentLegInformation: any;
  public tripRouteData: TripRoute;
  public emiterToSubject = new Subject<any>();
  public disableFuelPolicySelection: boolean = false;
  public routeDetails = new Array();
  public tripId: string;
  public legId: string;
  public sector: string;
  private remarks:any;
  private routeDataObservable: Subject<string> = new Subject<any>();
  constructor(
    private commonService: CommonService,
    private connectToServer: ServerConnectService,
    private utilService: UtilService,
    private firebasedataservice: FirebaseDataService,
    private tripRouteService: TripRouteService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private enterDetailsApiService: EnterTripDetailsService
  ) {
    this.activatedRoute.queryParamMap.subscribe(arr => {
      this.tripId = arr.get("tripID");
      this.legId = arr.get('legId');
      this.sector = arr.get("sector");
      const leginfo = arr.get("legsInfo");
      const currentLegInformation = this.tripRouteService.LegInformation.filter((legData: Leg) => {
        return legData.legid == this.legId;
      })
      this.currentLegInformation = currentLegInformation[0];
    })
  }
  public accordionSwitch(type) {
    if (
      type &&
      (!this.flightPlanningAccordionOpenStatus ||
        this.flightPlanningAccordionOpenStatus != type)
    ) {
      this.flightPlanningAccordionOpenStatus = type;
    } else if (type && this.flightPlanningAccordionOpenStatus == type) {
      this.flightPlanningAccordionOpenStatus = undefined;
    }
  }
  public getWx() {
    return new Promise((resolve, reject) => {
      const databody =
        'routeid=' +
        this.tripRouteData.routeId +
        '&station=' +
        this.tripRouteData.from +
        '-' +
        this.tripRouteData.to;
      this.connectToServer
        .connectToServer(
          0,
          activityUriConfig.getWx,
          databody,
          false,
          false,
          false
        )
        .then(
          (response: GetWxResponse) => {
            resolve(response.GetWx);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  public getNotams() {
    return new Promise((resolve, reject) => {
      const databody =
        'routeid=' +
        this.tripRouteData.routeId +
        '&station=' +
        this.tripRouteData.from +
        '-' +
        this.tripRouteData.to;
      this.connectToServer
        .connectToServer(
          0,
          NFPURI.weatherNotam,
          databody,
          false,
          false,
          false,
          'api/v1'
        )
        .then(
          (response: WeatherNotam) => {
            resolve(response.WeatherNotam);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  public getAirport() {
    return new Promise((resolve, reject) => {
      const databody =
        'routeid=' +
        this.tripRouteData.routeId +
        '&station=' +
        this.tripRouteData.from +
        '-' +
        this.tripRouteData.to;
      this.connectToServer
        .connectToServer(
          0,
          activityUriConfig.getAirportInformation,
          databody,
          false,
          false,
          false
        )
        .then(
          (response: GetAirportInformationResponse) => {
            resolve(response.GetAirportInformation);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  public getAirportInfoByIcaoCode(iCAOCode:string) {
    return new Promise((resolve, reject) => {
      const databody =
        'data=' + iCAOCode;
      this.connectToServer
        .connectToServer(
          0,
          activityUriConfig.getAirportInfo,
          databody,
          false,
          false,
          false
        )
        .then(
          (response: any) => {
            resolve(response.GetAirportInfo);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  public async getATCRouteByLegID(id: number) {
    return await new Promise(async (resolve, reject) => {
      this.commonService
        .showSpinnerLoader(FlightPlanTitles.pleaseWaitWhileWeGetRouteDetails)
        .then(() => {
          this.connectToServer
            .connectToServer(
              0,
              FlightPlanUrls.GetATCStripByRouteID,
              'RouteID=' + id,
              false,
              false,
              false,
            )
            .then(async (routeDetails: GetATCStripByRouteIDRoot) => {
              this.routeDetails = [];
              this.routeDetails.push(routeDetails.GetATCStripByRouteID);
              this.createChildObjects(routeDetails.GetATCStripByRouteID);
              resolve(routeDetails.GetATCStripByRouteID);
              this.commonService.dissmissSpinnerLoader();
            });
        });
    });
  }
  public async createChildObjects(routeDetails) {
    this.tripRouteData = routeDetails;
    this.emiterToSubject.next(routeDetails);
    await this.createPayloadObject(routeDetails);
    await this.createAtcFlightDetails(routeDetails);
    await this.createDestRouteDetails(routeDetails);
    await this.createAlternate(routeDetails);
    await this.createFuel(routeDetails);
    await this.createAvoid(routeDetails);
    await this.createETOPS(routeDetails);
  }


  public async createPayloadObject(routeDetails: GetATCStripByRouteID) {
    if (routeDetails.requestInputParam.ReceivedData.modifiedData && routeDetails.requestInputParam.ReceivedData.modifiedData != "undefined") {
      const modifiedRequestObject = JSON.parse(
        routeDetails.requestInputParam.ReceivedData.modifiedData
      );
      this.payload = modifiedRequestObject.Payload;
    } else {
      this.payload = new Payload(
        routeDetails.AircraftDetail.NoOfCrewCapacity,
        routeDetails.AircraftDetail.NoOfPaxCapacity,
        routeDetails.requestInputParam
          ? routeDetails.requestInputParam.ReceivedData.crew != undefined
            ? routeDetails.requestInputParam.ReceivedData.crew
            : 1
          : 2,
        routeDetails.requestInputParam
          ? routeDetails.requestInputParam.ReceivedData.pax != undefined
            ? routeDetails.requestInputParam.ReceivedData.pax
            : 1
          : 5,
        routeDetails.requestInputParam &&
          routeDetails.requestInputParam.ReceivedData.cargo &&
          routeDetails.requestInputParam.ReceivedData.cargo != '0' &&
          routeDetails.requestInputParam.ReceivedData.cargo != 'null'
          ? routeDetails.requestInputParam.ReceivedData.cargo
          : '',
        routeDetails.AircraftDetail.UnitType == 'KG' ? 'KGS' : 'LBS'
      );
    }
    return this.payload;
  }

  public async createAtcFlightDetails(routeDetails: any) {
    if (routeDetails.requestInputParam) {
      if (routeDetails.requestInputParam.ReceivedData.modifiedData && routeDetails.requestInputParam.ReceivedData.modifiedData != "undefined") {
        const modifiedRequestObject = JSON.parse(
          routeDetails.requestInputParam.ReceivedData.modifiedData
        );
        this.atcDetails = modifiedRequestObject.FlightDetails;
      } else {
        this.atcDetails = new Flight(
          routeDetails.requestInputParam.ReceivedData.flightType
            ? routeDetails.requestInputParam.ReceivedData.flightType == 'NS'
              ? 'N'
              : routeDetails.requestInputParam.ReceivedData.flightType
            : routeDetails.requestInputParam.ReceivedData.ScheduleType,
          '',
          routeDetails.requestInputParam.ReceivedData.operationType
        );
      }
      return this.atcDetails;
    }
  }

  public async createDestRouteDetails(routeDetails: any) {
    if (routeDetails) {
      if (routeDetails.requestInputParam.ReceivedData.modifiedData && routeDetails.requestInputParam.ReceivedData.modifiedData != "undefined") {
        const modifiedRequestObject = JSON.parse(
          routeDetails.requestInputParam.ReceivedData.modifiedData
        );
        this.route = modifiedRequestObject.DestRoute;
        this.setRouteData(this.route);
      } else {
        if (routeDetails.requestInputParam.ReceivedData.cruieseSpeed) {
          const parseObjectOfCruiseProfile = JSON.parse(
            routeDetails.requestInputParam.ReceivedData.cruieseSpeed
          );
          this.route = new Route(
            routeDetails.requestInputParam.ReceivedData.flightLevelType,
            routeDetails.requestInputParam.ReceivedData.Flightlevel,
            parseObjectOfCruiseProfile.Name,
            routeDetails.ATCroute[0]
          );
        }
        this.setRouteData(this.route);
      }
    }
  }
  public setRouteData(pageType: any) {
    this.routeDataObservable.next(pageType);
  }
  public getRouteDataObservable() {
    return this.routeDataObservable.asObservable();
  }

  public async createAlternate(routeDetails:GetATCStripByRouteID) {
    if (routeDetails.requestInputParam.ReceivedData.modifiedData && routeDetails.requestInputParam.ReceivedData.modifiedData != "undefined") {
      const modifiedRequestObject = JSON.parse(
        routeDetails.requestInputParam.ReceivedData.modifiedData
      );
      this.alternate = modifiedRequestObject.FlightplanAlternate;
      // this.setRouteData(this.route);
    }
    else{
      this.alternate.alt1RouteOption = "alt1RouteOptionauto";
      this.alternate.alt2RouteOption = "alt2RouteOptionauto"
      // this.alternate.alt1 = null;
      // this.alternate.alt2 = null;
      this.alternate.flightLevelType1 = "OptimumFlightLevelDefinition";
      this.alternate.flightLevelType2 = "OptimumFlightLevelDefinition";
      this.alternate.alt1FlightLevel = null;
      this.alternate.alt2FlightLevel = null;
    }
    if (routeDetails.alternate.RouteInformation) {
      if (routeDetails.alternate.RouteInformation != null) {
        this.alternate.alt1 = routeDetails.alternate.Airport.AirportICAOCode;
        if (routeDetails.alternate) {
          this.alternate.alt1Route =
            routeDetails.alternate.RouteInformation.RouteDescription.toString();
        }
      }
      if (routeDetails.alternate2) {
        if (routeDetails.alternate2) {
          const Alternate2 = routeDetails.alternate2;
          this.alternate.alt2 = Alternate2.Airport.AirportICAOCode;
          if (Alternate2) {
            this.alternate.alt2Route =
              Alternate2.RouteInformation.RouteDescription.toString();
          }
          this.alternate.alt2FlightLevel =
            Alternate2.RouteInformation.InitialAltitude.Value;
        }
      }
      else{
        this.alternate.alt2 = null;
        this.alternate.alt2RouteOption = "alt2RouteOptionauto"
        this.alternate.alt2Route = null;
      }
    }
  }

  public async createFuel(routeDetails: GetATCStripByRouteID) {
    this.fuel.fuelUnit = routeDetails.AircraftDetail.UnitType;
    this.fuel.fuelPolicy = routeDetails.requestInputParam
      ? routeDetails.requestInputParam.ReceivedData.FuelPolicy
      : 'MinimumRequiredFuelPolicy';
    this.fuel.extrafuel = routeDetails.requestInputParam
      ? routeDetails.requestInputParam.ReceivedData.extrafuel
      : null;
    if (routeDetails.requestInputParam.ReceivedData.modifiedData
      && routeDetails.requestInputParam.ReceivedData.modifiedData != "undefined") {
      const modifiedRequestObject = JSON.parse(
        routeDetails.requestInputParam.ReceivedData.modifiedData
      );
      this.fuel = modifiedRequestObject.FlightplanFuel;
    }
    this.toggleFuelPolicy();
  }

  public async createAvoid(routeDetails: GetATCStripByRouteID) {
    this.atcRouteDetails.FirList = routeDetails.firList;
    this.atcRouteDetails.CountryList = routeDetails.countryList;
    this.atcRouteDetails.FirNamesList = routeDetails.firNames;
  }

  public async createETOPS(routeDetails: GetATCStripByRouteID) {
    const etopsEnabled = (routeDetails.requestInputParam.ReceivedData as any).etopsEnabled;
    const etopsMinutes = (routeDetails.requestInputParam.ReceivedData as any).etopsMinutes;
    const AdequateAirports = routeDetails.AdequateAirport;
    // const listOfAdequate = [];
    // AdequateAirports.forEach((element) => {
    //   listOfAdequate.push(element.ICAOCode);
    // });
    // this.etpConfiguration.adequate = listOfAdequate;
    // this.etpConfiguration.min = etopsMinutes;
    // this.etpConfiguration.enable =
    //   this.etpConfiguration.enable =
    //   etopsEnabled == 'Yes' ? 'etops' : 'nonetops';
    if (routeDetails.requestInputParam.ReceivedData.modifiedData && routeDetails.requestInputParam.ReceivedData.modifiedData != "undefined") {
      const modifiedRequestObject = JSON.parse(
        routeDetails.requestInputParam.ReceivedData.modifiedData
      );
      this.etpConfiguration = modifiedRequestObject.FlightplanEtops;
    } else {
      this.etpConfiguration = new EtopsConfiguration(
        etopsEnabled,
        etopsMinutes,
        AdequateAirports
      )
    }
  }
  public toggleFuelPolicy() {
    this.fuel.fuelPolicy == 'ActualFuelPolicy'
      ? (this.disableFuelPolicySelection = true)
      : (this.disableFuelPolicySelection = false);
  }
  public get_baron_time() {
    const metaurl =
      BaronConfig.base_url +
      '/' +
      BaronConfig.key +
      '/meta/tiles/product-instances/' +
      BaronConfig.product +
      '/' +
      BaronConfig.configuration +
      '.jsonp?' +
      this.get_signature() +
      '&page_size=1';
    return '2019-09-26T12:35:30Z';
  }
  public get_signature() {
    const ts = Math.round(new Date().getTime() / 1000); // Unix time stamp
    const StringToSign = BaronConfig.key + ':' + ts;
    const hmac = CryptoJS.HmacSHA1(StringToSign, BaronConfig.secret); // Hmac using sha-1 function
    let sig = CryptoJS.enc.Base64.stringify(hmac); // Converting to Base64
    sig = sig.replace(/\+/g, '-').replace(/\//g, '_'); // Replacing '+' to '-' and '/' to '_'
    return 'ts=' + ts + '&sig=' + sig;
  }
  public getAiracCycle() {
    const today = new Date();
    const seconds = Math.round(today.getTime() / 1000);
    const cycleIndex = Math.floor((seconds - 1483606860) / 2419200);
    return AiracCycle[cycleIndex];
  }

  public async downloadFlightPlan() {
    return await new Promise((resolve, reject) => {
      this.commonService
        .showSpinnerLoader(FlightPlanTitles.downloadingFlightPlan)
        .then(() => {
          this.connectToServer
            .connectToServer(
              0,
              FlightPlanUrls.DownLoadFlightPlan,
              'flightplanID=' + this.tripRouteData.routeId + '&flag=test',
              false,
              false,
              false
            )
            .then((data: DownloadFlightPlan) => {
              this.firebasedataservice.downloadfordevice(
                data.DownloadFlightPlan
              );
            });
        });
    });
  }
  public async showAlternate(type: string) {
    this.commonService.selectStation(type, this.tripRouteData.to, this.tripRouteData.AircraftDetail.minimumRunwayLength, type).then((data: StationDetails) => {
      if (data)
        switch (type) {
          case 'Tripalternate':
            this.alternate.alt1 = data.name;
            break;
          case 'Tripalternate2':
            this.alternate.alt2 = data.name;
            break;
          default:
            return false;
        }
    })
  }
  public async latRad(lat: number) {
    const sin = Math.sin((lat * Math.PI) / 180);
    const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }
  public async getZoom(latA: number, lngA: number, latB: number, lngB: number) {
    const latDif = Math.abs(
      (await this.latRad(latA)) - (await this.latRad(latB))
    );
    const lngDif = Math.abs(lngA - lngB);
    const latFrac = latDif / Math.PI;
    const lngFrac = lngDif / 360;
    const lngZoom = Math.log(1 / latFrac) / Math.log(2);
    const latZoom = Math.log(1 / lngFrac) / Math.log(2);
    return Math.min(lngZoom, latZoom);
  }

  public async getLineColorForMap(type: string) {
    switch (type) {
      case 'alt1':
      case 'alt2':
        return '#3d7004';
      default:
        return '#E83610';
    }
  }
  public async getRandomColor() {
    return await new Promise<string>((resolve, reject) => {
      const color = ['#001c07', '#021e70', '#9e039c', '#b00202'];
      const randomNumber = Math.floor(Math.random() * color.length);
      resolve(color[randomNumber]);
    });
  }
  public async cancel() {
    this.commonService.showSpinnerLoader(FlightPlanTitles.revertingthechanges).then(() => {
      if (this.routeDetails.length > 1) {
        this.routeDetails.pop();
        this.createChildObjects(this.routeDetails[this.routeDetails.length - 1]);
      }
      this.commonService.dissmissSpinnerLoader();
    })
  }
  public compute(legid: string, srid: string, sector: string,platform?:string,remarks?:any) {
    this.remarks = remarks;
    return new Promise((resolve, reject) => {
      const modifiedData = {
        Payload: this.payload,
        FlightDetails: this.atcDetails,
        DestRoute: this.route,
        FlightplanAlternate: this.alternate,
        FlightplanFuel: this.fuel,
        FlightplanAvoidfir: this.atcRouteDetails,
        FlightplanEtops: this.etpConfiguration,
      };
      const cbp = new CBP();
      cbp.SRID = srid;
      cbp.legid = legid; // this.currentLegInformation?.legid ? this.currentLegInformation?.legid : this.currentLegInformation?.legId;
      cbp.sector = sector;
      cbp.modifiedData = JSON.stringify(modifiedData);
      cbp.ATCRoute = this.route?.ATCDestRoute;
      this.sentToCompute(cbp , platform).then((response: ProcessUploadCBP) => {
        this.createChildObjects(response);
        resolve(response?.routeId);
      });
    })
  }
  public createDataStringFromRecompute(cbp: CBP,select?:string) {
    return "SRID=" + cbp.SRID + "&sector=" + cbp.sector + "&attName=" + cbp.ATCRoute + "&filename=" + encodeURIComponent(cbp.FilePath) + "&SRSecID=" + cbp.legid + "&alt1=" + cbp.alt1 + "&alt2=" + cbp.alt2 + "&platform=" + select + "&remarks=" + this.remarks +  "&modifiedData=" + cbp.modifiedData;
  }
  public sentToCompute(cbp: CBP,select?:string) {
    const databody = this.createDataStringFromRecompute(cbp,select);
    return new Promise((resolve, reject) => {
      this.commonService
        .showSpinnerLoader(FlightPlanTitles.recomputingtheFlightPlan)
        .then(() => {
          this.connectToServer.connectToServer(
            0,
            NFPURI.uploadATCRoute,
            databody,
            false,
            false,
            false,
            "api/v1"
          ).then(
            (response: ProcessUploadCBPResponse) => {
              this.commonService.dissmissSpinnerLoader();
              if (response?.ProcessUploadCBP?.error) {
                this.commonService.showMeassgeAlert(response.ProcessUploadCBP.error[0]);
                return false;
              }
              this.routeDetails.push(response.ProcessUploadCBP);
              resolve(response.ProcessUploadCBP);
            },
            (err) => {
              this.commonService.dissmissSpinnerLoader();
              reject(err);
            }
          );
        });
    });
  }
  public async confirm() {
    if (this.tripRouteData.Selected && this.tripRouteData.Selected != "0") {
      this.router.navigate(["/trips/trips-tab/" + this.tripId + "/flightplan/" + this.tripId]);
    } else {
      // this.submitFlightPlan();
    }
  }
  public async submitFlightPlan(legid: string, tripid: string, sector: string, vendorid:string, filePath?: string) {
    this.commonService.showSpinnerLoader();
    const legInfo = {
      SRID: tripid,
      sector,
      legId: legid,
      vendorid,
      autoFile: "manual",
    };
    return new Promise((resolve, reject) => {
      const databody = "CurrentLegInformation=" + encodeURIComponent(JSON.stringify(legInfo)) +
        "&attchname=" + this.tripRouteData.ATCroute + "&filename=" + filePath + "&responseID=" + this.tripRouteData.routeId;
      this.connectToServer.connectToServer(0, activityUriConfig.saveFlightPlan, databody, false, false, false).then((response: any) => {
        this.enterDetailsApiService.receiveData.next(response?.SaveFlightPlan);
        this.commonService.dissmissSpinnerLoader();
        resolve(response);
      }, (err) => {
        reject(err);
      });
    });
  }

}
