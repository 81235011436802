import { Component, OnInit, Output } from '@angular/core';
import { CommonService } from '@app/shared/services/common.service';
import { ModalController, NavParams } from '@ionic/angular';
import { EventEmitter } from '@angular/core';
import { SigninService } from '@app/authentication/services/signin.service';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.page.html',
  styleUrls: ['./verify-otp.page.scss'],
})
export class VerifyOTPPage implements OnInit {
  @Output() public otpVerified = new EventEmitter<boolean>();
  public passwordType = 'password';
  public otpICON = "shield-outline"
  public otp: number;
  public userInputTooLong: boolean = false;
  public isOTPVerified: boolean = false;
  public checkVerified = false;
  public userContactNumber: any;
  public receiveContactNumber: any;
  constructor(private modalController: ModalController,
    private commonService: CommonService,
    private signInService: SigninService,
    private navParams: NavParams) {
    this.receiveContactNumber = this.navParams.get('formData');
    this.userContactNumber = this.receiveContactNumber.internationalNumber.replace(/ /g, '');
  }
  public ngOnInit() {
  }
  public async dismissModal() {
    await this.modalController.dismiss();
  }
  public showPassword() {
    if (this.passwordType == 'password') {
      this.passwordType = 'text';
    } else {
      this.passwordType = 'password';
    }
  }
  public async verifyOTP(form:NgForm) {
    this.signInService.verifyCode(this.otp).then((res:boolean) => {
      if (res) {
        this.isOTPVerified = true;
        this.otpVerified.emit(this.isOTPVerified);
        this.otpICON = 'shield-checkmark-outline';
        this.commonService.showToastCtrl('OTP Verified Successfully!');
        setTimeout(() => {
          this.modalController.dismiss({ otpVerified: this.isOTPVerified });
        }, 1000); // Delay Added to display animation and Icon change
      } else {
        this.commonService.showToastCtrl('Incorrect OTP Please Try again');
      }
    })
  }
  public resendOTP() {
    this.signInService.sendContactNumberForOTP(this.userContactNumber)
    this.otp=null;
    this.commonService.showToastCtrl('New OTP sent! Please check for the code');
  }
  public onInput(event: any) {
    const inputValue = event.target.value;
    if (inputValue && inputValue.toString().length > 4) {
      this.userInputTooLong = true;
    } else {
      this.userInputTooLong = false;
    }
  }
}
