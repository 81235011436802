export const TAG_COLORS = {
    'primary': null,
    'secondary': null,
    'tertiary': "#e6e6e6",
    'success': null,
    'warning': null,
    'danger': "red",
    'dark': null,
    'medium': null,
    'light': null,
    "warn": "green",
    "gray": "#767676",
    "purple": "#7e60ff",
};