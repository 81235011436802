import { ActivityCount } from './../../trips/trips-tab/model/trip-activity.model';
import { CommonService } from './common.service';
import { AuthSessionService } from '../../core/services/auth-session.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import firebase from "firebase/app";

@Injectable({
  providedIn: 'root'
})
export class ActivityCountService {
  public $vendorUnreadActivityCount: BehaviorSubject<any> = new BehaviorSubject(
    [],
  );
  public $tripsActivityCount = new BehaviorSubject<any>(null);
  public $tripsQuotesCount = new BehaviorSubject<any>(null);
  public QuaotesCountValue: any;
  public $tripActivityCountValue;
  public $storetotalTripsCount: BehaviorSubject<ActivityCount> = new BehaviorSubject(new ActivityCount());
  public $tripNotificationCountValue;

  constructor(private authSessionService: AuthSessionService,
    private commonService: CommonService) { }
  public sendAllTripsTotalCount() {
    this.getTripActivityCount();
    this.getTripNotificationCount();
    this.subscribeActivityCount();
  }
  public getTripActivityCount() {
    const observable = new Observable(observer => {
      firebase.database().ref(`/Activity/'${this.commonService.uid}`).orderByChild("Count").startAt(1).on("value", (snapshot) => {
        if (snapshot.val() != null && snapshot.val()) {
          let activityCount = 0;
          for (const data of Object.keys(snapshot.val())) {
            activityCount += snapshot.val()[data].Count || 0;
          }
          this.$tripActivityCountValue = activityCount;
          this.sendallTripsCount();
          observer.next(activityCount);
        }
        else {
          this.$tripActivityCountValue = null;
          this.sendallTripsCount();
          observer.next(null);
        }
      });
    });
    return observable;
  }
  public getTripNotificationCount() {
    if (this.commonService.uid != undefined) {
      const observable = new Observable(observer => {
        firebase.database().ref(`/unread_notification/'${this.commonService.uid}`).orderByChild("count").startAt(1).on("value", (snapshot) => {
          let notificationCount = 0;
          if (snapshot.val() != null && snapshot.val()) {
            for (const data of snapshot.val()) {
              notificationCount += snapshot.val()[data].count || 0;
            }
            this.$tripNotificationCountValue = notificationCount;
            this.sendallTripsCount();
            observer.next(notificationCount);
          }
          else {
            this.$tripNotificationCountValue = null;
            this.sendallTripsCount();
            observer.next(null);
          }
        });
      });
      return observable;
    }
  }
  public sendallTripsCount() {
    this.$storetotalTripsCount.next(this.$tripActivityCountValue + this.$tripNotificationCountValue);
  }
  public subscribeActivityCount() {
    this.fetchActivityCountFromFirebase();
    // this.totalBadgeCount();
    this.quotesActivityCount();
  }
  public async totalBadgeCount() {
    return new Promise((resolve) => {
      const refUserTotalBadgeCount =
        "/UsertotalBadgeCount/" +
        this.commonService.uid +
        "/Count/";
      firebase
        .database()
        .ref(refUserTotalBadgeCount)
        .on("value", async (snapshot) => {
          setTimeout(() => {
            if (snapshot.val() != null) {
              const snapValue =
                snapshot.val() != null ? snapshot.val() : 0;
              this.$tripsActivityCount.next(snapValue);
              resolve(snapshot.val());
            } else {
              resolve(0);
            }
          }, 100);
        });
    })

  }
  public quotesActivityCount() {
    return new Promise((resolve) => {
      const QuoteRefference =
        "/UnreadUserActivityAndNotificationQuote/" +
        this.commonService.uid +
        "/Count/";
      firebase
        .database()
        .ref(QuoteRefference)
        .on("value", (snapshot) => {
          if (snapshot.val() != null && snapshot.val()) {
            this.QuaotesCountValue = snapshot.val();
            this.$tripsQuotesCount.next(this.QuaotesCountValue);
            resolve(snapshot.val())
          } else {
            this.QuaotesCountValue = 0;
            this.$tripsQuotesCount.next(snapshot.val());
            resolve(snapshot.val())

          }
        });
    })

  }
  public fetchActivityCountFromFirebase() {
    return new Promise((resolve) => {
      const countVal = firebase
        .database()
        .ref(
          "/UnreadUserActivityAndNotificationTrips/" +
          this.commonService.uid +
          "/Count/",
        );
      countVal.on("value", (snapshot) => {
        setTimeout(() => {
          if (snapshot.val() != null  && snapshot.val()) {
            this.$vendorUnreadActivityCount.next(snapshot.val());
            resolve(snapshot.val())
          } else {
            this.$vendorUnreadActivityCount.next(null);
          }
        }, 100)

      });
    });
  }
}
