import { FirebaseDataService } from '@app/shared/services/firebase-data.service';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { TripActivityService } from '@app/trips/services/trip-activity.service';
import { CommonService } from '@app/shared/services/common.service';

@Component({
  selector: 'app-charter-notifications',
  templateUrl: './charter-notifications.component.html',
  styleUrls: ['./charter-notifications.component.scss'],
})
export class CharterNotificationsComponent implements OnInit {
  public pageType:string = 'allNotification'
  constructor(private menuController:MenuController, private commonService:CommonService,private tripActivityService:TripActivityService) {
     }

  public ngOnInit() {
    this.commonService.charterAllNotificationSubject.subscribe((res)=>{
        this.tripActivityService.LoadTotalNotificationValue();
        this.commonService.dismissLoader();
    })
  }

  public back(){
    this.menuController.enable(false, 'end');
  }

}
