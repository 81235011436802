import { CommonService } from './common.service';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as timezones from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class TimerService {
  constructor(private commonService: CommonService) { }
  // pass the date in second format it will convert and send in a perfect format as per our logic i.e if more than day it will only day,
  // if more than hour it will hour:minute,if less than 1 hour it will be minutes:second
  public getSecondsAsDigitalClock(inputSeconds: number) {
    const SEC_NUM = parseInt(inputSeconds.toString(), 10);
    const days = Math.floor(SEC_NUM / 24 / 60 / 60);
    const hoursleft = Math.floor((SEC_NUM - (days * 86400)));
    const hours = Math.floor(hoursleft / 3600);
    const minutesleft = Math.floor((hoursleft) - (hours * 3600));
    const minutes = Math.floor(minutesleft / 60);
    const seconds = SEC_NUM % 60;
    let hoursString = '';
    let minutesString = '';
    let secondsString = '';
    let dayString = '';
    dayString = (days < 10) ? '0' + days : days.toString();
    hoursString = (hours < 10) ? '0' + hours : hours.toString();
    minutesString = (minutes < 10) ? '0' + minutes : minutes.toString();
    secondsString = (seconds < 10) ? '0' + seconds : seconds.toString();
    if (days != 0 && days == 1) {
      return days + ' Day';
    } else if (days != 0 && days >= 1) {
      return days + ' Days';
    } else if (Number(hoursString) != 0) {
      return hoursString + 'H:' + minutesString + 'M';
    } else if (Number(minutesString) != 0) {
      return minutesString + 'M:' + secondsString + 'S';
    } else if (Number(secondsString) != 0) {
      return minutesString + 'M:' + secondsString + 'S';
    }
  }
  // get the difference between two string date in second format
  public timeDiffInSecond(startDate, endDate) {
    const start = moment(startDate);
    const end = moment(endDate);
    return start.diff(end, 'seconds');
  }
  // get the difference between two string date in hour.
  public timeDiffInHour(startdate, enddate) {
    const start = moment(startdate);
    const end = moment(enddate);
    return end.diff(start, 'hours');
  }
  // to get the current time in date utc format
  public getTimeNow() {
    this.commonService.getUTCTime();
    return this.commonService.NowTime;
  }
  // accept BLK time in string hour:minute format
  public getTimeInMin(time) {
    const startTime = time.split(':');
    const startMinutes = Number(startTime[0]) * 60 + Number(startTime[1]);
    return Number(startMinutes);
  }
  public addMinutes(minutes, date) {
    return moment(date).utc().add(minutes, 'minutes').format();
  }
  // logic to  handle the vendor confirmation time
  public confirmationRequest(createDate, departDate) {
    createDate = createDate.replace(/\//g, '-');
    departDate = departDate.replace(/\//g, '-');
    if (
      this.timeDiffInHour(createDate, departDate) > 4 ||
      this.timeDiffInHour(createDate, departDate) == 4
    ) {
      return moment(createDate)
        .utc()
        .add(
          Math.round(
            (this.timeDiffInSecond(departDate, createDate) * 0.25) / 60
          ),
          'minutes'
        )
        .format();
    } else {
      return moment(createDate).utc().add(60, 'minutes').format();
    }
  }
  public paidtoinvoiceTime(invoiceDate, todaydate) {
    return this.timediffinHour(invoiceDate, todaydate) < 168;
  }
  public timediffinHour(startdate, enddate) {
    const start = moment(startdate);
    const end = moment(enddate);
    return end.diff(start, 'hours');
  }
  public timediffinSecond(startdate, enddate) {
    const start = moment(startdate);
    const end = moment(enddate);
    return start.diff(end, 'seconds');
  }
  public duedaysdate(invoiceDate) {
    return moment(invoiceDate).utc().add(168, 'hours').format();
  }
  public covertUTCtime(timeZone, time) {
    return timezones.tz(time, timeZone).utc().format();
  }
}
