import { Injectable } from '@angular/core';
import { ServerConnectService } from '@app/core/services/server-connect.service';
import { ApiTypes } from '../constants/constant';
import { ApiConfig } from '../constants/url-constant';
import { CommonService } from './common.service';
import { queryParams } from './../../shared/utils/query-params';


@Injectable({
  providedIn: 'root'
})
export class CsvFileService {
  public apiType = ApiTypes;
  constructor(private serverConnectService: ServerConnectService, private commonService: CommonService) { }
  public UploadCsvExcel = async (serviceType, filename, url): Promise<any> => {
    try {
      switch (serviceType) {
        case 'Taxes':
          return await this.UploadTaxExcel(filename, url);
        case 'fuel':
          return await this.UploadFuelExcel(filename, url);
        case 'handling':
            return await this.UploadHandlingExcel(filename, url);
        case 'landing':
            return await this.UploadLandingExcel(serviceType, filename, url);
        case 'slot':
              return await this.UploadSlotExcel(serviceType,filename, url);
        case 'ovf-permits':
                return await this.UploadOvfExcel(serviceType,filename, url);
        default:
          return 'Page does not exist';
      }
    } catch (err) {
      throw err;
    }
  }
  public UploadLandingExcel(serviceType, filename, url) {
    const serviceName = serviceType == 'landing' ? 'Landing': '';
    return new Promise((resolve, reject) => {
      const databody = "ExelName=" + filename + "&DownloadUrl=" + url + "&ServiceName=" + serviceName;
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          ApiConfig.uploadLandingExcelData,
          databody,
          false,
          false,
          false
        )
        .then((value: any) => {
          resolve(value);
        })
        .catch((error) => {
          this.commonService.showErrorAlert().then((res) => {
            if (res) {
              this.UploadLandingExcel(serviceType,filename, url);
            } else {
              reject();
            }
          })
        });
    });
  }
  public UploadSlotExcel(serviceType, filename, url) {
    const serviceName = serviceType == 'slot' ? 'Slot': '';
    return new Promise((resolve, reject) => {
      const databody = "ExelName=" + filename + "&DownloadUrl=" + url + "&ServiceName=" + serviceName;
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          ApiConfig.uploadSlotExcelData,
          databody,
          false,
          false,
          false
        )
        .then((value: any) => {
          resolve(value);
        })
        .catch((error) => {
          this.commonService.showErrorAlert().then((res) => {
            if (res) {
              this.UploadSlotExcel(serviceType,filename, url);
            } else {
              reject();
            }
          })
        });
    });
  }
  public UploadOvfExcel(serviceType,filename, url) {
    const serviceName = serviceType == 'ovf-permits' ? 'Permits': '';
    return new Promise((resolve, reject) => {
      const databody = "ExelName=" + filename + "&DownloadUrl=" + url + "&ServiceName=" + serviceName;
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          ApiConfig.uploadSlotExcelData,
          databody,
          false,
          false,
          false
        )
        .then((value: any) => {
          resolve(value);
        })
        .catch((error) => {
          this.commonService.showErrorAlert().then((res) => {
            if (res) {
              this.UploadSlotExcel(serviceType,filename, url);
            } else {
              reject();
            }
          })
        });
    });
  }
  public UploadFuelExcel(filename, url) {
    return new Promise((resolve, reject) => {
      const databody = "FuelExel=" + filename + "&DownloadUrl=" + url;
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          ApiConfig.uploadFuelExcelData,
          databody,
          false,
          false,
          false
        )
        .then((value: any) => {
          resolve(value);
        })
        .catch((error) => {
          this.commonService.showErrorAlert().then((res) => {
            if (res) {
              this.UploadFuelExcel(filename, url);
            } else {
              reject();
            }
          })
        });
    });
  }
  public UploadHandlingExcel(filename, url) {
    return new Promise((resolve, reject) => {
      const databody = "HandlingExel=" + filename + "&DownloadUrl=" + url;
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          ApiConfig.uploadHandlingExcelData,
          databody,
          false,
          false,
          false
        )
        .then((value: any) => {
          resolve(value);
        })
        .catch((error) => {
          this.commonService.showErrorAlert().then((res) => {
            if (res) {
              this.UploadHandlingExcel(filename, url);
            } else {
              reject();
            }
          })
        });
    });
  }

  public fetchCsvListByServiceName(serviceName) {
    switch(serviceName) {
      case 'ovf-permits':
       return this.fetchOvfPermitCsvList(serviceName);
      case 'slot':
       return this.fetchSlotCsvList(serviceName);
      case 'landing':
       return this.fetchLandingCsvList(serviceName);
      default:
      break;
    }
  }
  public fetchSlotCsvList(service?) {
    return new Promise((resolve, reject) => {
      const databody = "servicename="+service;
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          ApiConfig.getListOfSlotExcelData,
          databody,
          false,
          false,
          false
        )
        .then((data: any) => {
          resolve(data.GetListOfPermitsExcelData);
        }, (error) => {
          this.commonService.showErrorAlert().then((res) => {
            if (res) {
              this.fetchSlotCsvList();
            } else {
              reject();
            }
          });
        });
    });
  }
  public fetchLandingCsvList(service?) {
    return new Promise((resolve, reject) => {
      const databody = "servicename="+service;
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          ApiConfig.getListOfLandingExcelData,
          databody,
          false,
          false,
          false
        )
        .then((data: any) => {
          resolve(data.GetListOfPermitsExcelData);
        }, (error) => {
          this.commonService.showErrorAlert().then((res) => {
            if (res) {
              this.fetchLandingCsvList();
            } else {
              reject();
            }
          });
        });
    });
  }
  public fetchOvfPermitCsvList(service?) {
    return new Promise((resolve, reject) => {
      const databody = "servicename="+service;
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          ApiConfig.getListOfOverFlightExcelData,
          databody,
          false,
          false,
          false
        )
        .then((data: any) => {
          resolve(data.GetListOfPermitsExcelData);
        }, (error) => {
          this.commonService.showErrorAlert().then((res) => {
            if (res) {
              this.fetchOvfPermitCsvList();
            } else {
              reject();
            }
          });
        });
    });
  }
  public fetchHandlingCsvList() {
    return new Promise((resolve, reject) => {
      const databody = "";
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          ApiConfig.getListOfHandlingExcelData,
          databody,
          false,
          false,
          false
        )
        .then((data: any) => {
          resolve(data.GetListOfGHExcelData);
        }, (error) => {
          this.commonService.showErrorAlert().then((res) => {
            if (res) {
              this.fetchHandlingCsvList();
            } else {
              reject();
            }
          });
        });
    });
  }
  public fetchFuelCsvList() {
    return new Promise((resolve, reject) => {
      const databody = "";
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          ApiConfig.getListOfFuelExcelData,
          databody,
          false,
          false,
          false
        )
        .then((data: any) => {
          resolve(data.GetListOfFuelExcelData);
        }, (error) => {
          this.commonService.showErrorAlert().then((res) => {
            if (res) {
              this.fetchFuelCsvList();
            } else {
              reject();
            }
          });
        });
    });
  }
  public deleteFuelCsvFile(FUDName, FPEDID) {
    return new Promise((resolve) => {
      const databody = "UserEmailID=" + this.commonService.userName + "&UserPass=" + this.commonService.password + "&FUDName=" + FUDName + "&FPEDID=" + FPEDID;
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          ApiConfig.deleteFuelExcelData,
          databody,
          false,
          false,
          false
        )
        .then((data: any) => {
          resolve(data.ResponseStatus);
        }, (err) => {
          resolve("Error");
        });
    });
  }
  public UploadTaxExcel(filename, url) {
    return new Promise((resolve, reject) => {
      const databody = "TaxExel=" + filename + "&DownloadUrl=" + url;
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          ApiConfig.uploadTaxExcelData,
          databody,
          false,
          false,
          false
        )
        .then((value: any) => {
          resolve(value);
        })
        .catch((error) => {
          this.commonService.showErrorAlert().then((res) => {
            if (res) {
              this.UploadTaxExcel(filename, url);
            } else {
              reject();
            }
          })
        });
    });
  }
  public deleteCsvFile(FUDName, TEID) {
    const databody = queryParams({
      UserEmailID: this.commonService.userName,
      UserPass: this.commonService.password,
      FUDName,
      TEID,
    });
    return new Promise((resolve, reject) => {
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          ApiConfig.deleteTaxExcelData,
          databody,
          false,
          false,
          false
        )
        .then((value: any) => {
          resolve(value.ResponseStatus);
        })
        .catch((error) => {
          this.commonService.showErrorAlert().then((res) => {
            if (res) {
              this.deleteCsvFile(FUDName, TEID);
            } else {
              reject();
            }
          })
        });
    });
  }
}
