import { OperationType } from '@app/setup/setup.types';
import { EstimateBackendFormatSavedraft } from '@app/charter/model/estimate.model';
import { Injectable } from '@angular/core';
import { ServerConnectService } from '@app/core/services/server-connect.service';
import { CommonService } from '@app/shared/services/common.service';
import { TRIP_SERVICE_CONSTANT } from '@app/trips/constants/trips-constant-config';
import { queryParams } from '@app/shared/utils/query-params';
import { Vendor } from '@app/trips/schedule-creation/model/schedule-model';
import { CharterBackendActivateTripLeglistFormat, CharterBackendActivateTripTripFormat, CharterLegInformationDeatils, Charter } from '../model/estimate.model';
import { activityUriConfig } from '@app/trips/constants/trip-config-url';
import { GetQuoteAll, GetQuoteAllResponse, GetTripEstimateDetailsResponse } from '@app/estimates/model/estimates.model';
import { ApiTypes } from '@app/shared/constants/constant';
import { FirebaseDataService } from '@app/shared/services/firebase-data.service';
import { CharterUriConfig } from '../config/charter-url-config';
import * as timezones from 'moment-timezone';
import { CreateTrip } from '../model/schedule-creation';
import { FlyingDatetimePipe } from '@app/shared/pipes/flying-datetime.pipe';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class CharterTripService {
  public tripInformation = new Array<CharterLegInformationDeatils>();
  public totalAmount: any;
  public totalCost: number = 0;
  public tripID: number;
  public quoteList: GetQuoteAll[];
  public searchList: GetQuoteAll[];
  private apiType = ApiTypes;
  public departStartDate: any;
  public departureDate: any;
  public minLTtime: any;
  public minUTCtime: any;
  public createTripModel = new CreateTrip();
  constructor(private commonService: CommonService,
    private connectToServer: ServerConnectService,
    private flyingDatetime: FlyingDatetimePipe,
    private firebaseDataService: FirebaseDataService) { }
  public async activateTrip(tripInformation, total,type) {
    this.tripInformation = tripInformation;
    this.totalCost = total;
    return await new Promise((resolve, reject) => {
      this.commonService.showSpinnerLoader(TRIP_SERVICE_CONSTANT.registerTripLoader);
      const data = JSON.stringify(this.activateTCE(type));
      const paylaoad = queryParams({
        data
      });
      this.connectToServer.connectToServer(0, CharterUriConfig.charterRegisterTrip, paylaoad, false, false, false).then((result: any) => {
        resolve(result.CharterRegisterTrip);
      }).catch((error) => {
        reject(false);
      })
    })
  }
  public activateTCE(type) {
    const backendActivateTripLeglistFormat = new Array<
      CharterBackendActivateTripLeglistFormat
    >();
    const tripData = new CharterBackendActivateTripTripFormat();
    tripData.legList = backendActivateTripLeglistFormat;
    const stationServiceArray = [];
    this.tripInformation.forEach((element: CharterLegInformationDeatils, index: number) => {
      backendActivateTripLeglistFormat.push(
        new CharterBackendActivateTripLeglistFormat(element,type)
      );
      tripData.SectorServiceArray.push({
        service: "cbp,navigation,permits,",
        sector: element.sector,
      });

      let service: string = "";
      const servicArr = element.serviceProvider.filter((item) => {
        return item.station == element.sector.substr(0, 4);
      });
      servicArr.forEach((serviceElement: Vendor) => {
        if (serviceElement.serviceName == "fuel") {
          if (
            serviceElement.UID != this.commonService.uid
          ) {
            service = service + "fuel,";
          }
        }
        if (serviceElement.serviceName == "handling") {
          if (
            serviceElement.UID != this.commonService.uid
          ) {
            service = service + "groundhandling,";
          }
        }
        if (serviceElement.serviceName == "airportcharges") {
          if (
            serviceElement.UID != this.commonService.uid
          ) {
            service = service + "airportcharges,";
          }
        }
      });
      tripData.StationServiceArray.push({
        service,
        sector: element.sector,
        station: element.sector.substr(0, 4),
      });
      if (index == this.tripInformation.length - 1) {
        service = "";
        const secondServicArr = element.serviceProvider.filter((item) => {
          return item.station == element.sector.substr(5, 9);
        });
        secondServicArr.forEach((serviceElement: Vendor) => {
          if (serviceElement.serviceName == "handling") {
            if (
              serviceElement.UID != this.commonService.uid
            ) {
              service = service + "groundhandling,";
            }
          }
          if (serviceElement.serviceName == "airportcharges") {
            if (
              serviceElement.UID != this.commonService.uid
            ) {
              service = service + "airportcharges,";
            }
          }
        });
        tripData.StationServiceArray.push({
          service,
          sector: element.sector,
          station: element.sector.substr(5, 9),
        });
      }
    });
    tripData.TotalCost = this.totalCost;
    return tripData;
  }

  public saveEstimate(tripInformation: CharterLegInformationDeatils[]) {
    return new Promise((resolve, reject) => {
      const tripData = new Array<EstimateBackendFormatSavedraft>();
      tripInformation.forEach((element: CharterLegInformationDeatils) => {
        tripData.push(new EstimateBackendFormatSavedraft(element));
      });
      const payload =
        "data=" +
        JSON.stringify(tripData) +
        "&trip_id=" +
        null;
      this.connectToServer.connectToServer(0, activityUriConfig.saveRawTripData, payload, false, false, false).then(
        (tripDataValue: any) => {
          this.tripID = tripDataValue.saveRawTripData;
          resolve(tripDataValue.saveRawTripData);
        },
        (error) => {
          this.commonService.dissmissSpinnerLoader();
        }
      );
    });
  }
  public getlistCharterEstimatedata(status: string = 'Drafts', lastTripID?: string, loadMore?: boolean) {
    return new Promise((resolve) => {
      let databody = "status=" + status;
      if (lastTripID)
        databody = databody + "&lastminsrid=" + lastTripID;
      this.connectToServer.connectToServer(0, activityUriConfig.getQuoteAll, databody, false, false, false).then((response: GetQuoteAllResponse) => {
        if (loadMore) {
          response.GetQuoteAll.forEach((element) => {
            // this.quoteList.push(element);
            this.searchList.push(element);
          })
        } else {
          this.quoteList = response.GetQuoteAll;
          this.searchList = response.GetQuoteAll;
        }
        resolve(response.GetQuoteAll);
      })
    });
  }
  public cancelTripByClient(srid, status) {
    return new Promise((resolve) => {
      const cancelTripData = 'enct=1&srid=' + srid + '&type=' + status;
      this.connectToServer
        .connectToServer(
          this.apiType.POST,
          activityUriConfig.cancelTrip,
          cancelTripData,
          false,
          false,
          false
        )
        .then((data: any) => {
          resolve(data.ResponseStatus);
        })
        .catch((err) => {
          resolve('Error');
        });
    });
  }
  // charter Trip Info
  public fetchTripInfo(tripId) {
    return new Promise((resolve, reject) => {
      const databody = 'id=' + tripId;
      this.connectToServer.connectToServer(this.apiType.POST, CharterUriConfig.GetQouteInfo, databody, false, false, false).then((res: any) => {
        resolve(res.GetTripInfo);
      }, (error) => {
        reject();
      }
      )
    });
  }
  // charter Estimate Info
  public fetchEstimateInfo(tripId, activatedEstimateTripId?: any) {
    return new Promise((resolve, reject) => {
      const databody = 'id=' + tripId +
      "&activatedEstimateTrip_id=" + activatedEstimateTripId;
      this.connectToServer.connectToServer(this.apiType.POST, CharterUriConfig.getEstimateInfo, databody, false, false, false).then((res: any) => {
        resolve(res.GetEstimateInfo);
      }, (error) => {
        reject();
      }
      )
    });
  }
  // charter Quote Download
  public downloadCharterQuote(tripId) {
    return new Promise((resolve, reject) => {
      const databody = 'id=' + tripId;
      this.connectToServer.connectToServer(this.apiType.POST, CharterUriConfig.downloadCharterQuote, databody, false, false, false).then((res: any) => {
        resolve(res.DownloadCharterQuoteIternaryPDF);
      }, (error) => {
        reject();
      }
      )
    });
  }
  // For trip status color
  public statusColorsByTripStatus(status) {
    let statusColor;
    if (status == "In Progress" || status == "Accepted" || status == "Inprogress") {
      statusColor = "green-status";
    } else if (status == "Upcoming" || status == "Confirmed") {
      statusColor = "amber-status";
    } else if (
      status == "Declined" ||
      status == "Expired" ||
      status == "Pending" || status == "Processing"
    ) {
      statusColor = "red-status";
    } else {
      statusColor = "custom-light-text14";
    }
    return statusColor + " custom-label-color ";
  }
  public downlaodCharterTripEstimate(data: CharterLegInformationDeatils[], estID: string, fromPage?: string, index?: string) {
    const temp = this.createArrayForIternaryVendor(data, fromPage, index);
    this.commonService.showSpinnerLoader();
    return new Promise((resolve, reject) => {
      const databody = 'data=' + JSON.stringify(temp) +
      "&estimate_id=" + estID;
      this.connectToServer
        .connectToServer(
          this.apiType.POST,
          CharterUriConfig.getTceDownload,
          databody,
          false,
          false,
          false
        )
        .then(
          (value: any) => {
            resolve(value.DownloadCharterIternaryPDF);
          },
          (err) => {
            this.commonService.dismissLoader();
            resolve('Error');
          }
        );
    });
  }
public createArrayForIternaryVendor(data: CharterLegInformationDeatils[], fromPage: string, index: string) {
  return data.map((items: CharterLegInformationDeatils) => {
    return {
      from: items.fromStation.name,
      to: items.toStation.name,
      departDate: items.departDate,
      timeType: items.timeType,
      crew: items.noOfCrew,
      pax: items.noOfPax,
      flightType: items.flightType,
      operationType: items.operationType,
      flightRule: items.flightRule,
      aircraft: items.aircraftId,
      fromStationTimeZone: items.fromStation.timeZone,
      toStationTimeZone: items.toStation.timeZone,
      BLKTIME: items.BLKTIME,
      DIST: items.DIST,
      ETDGMT: items.ETDGMT,
      ETAGMT: items.ETAGMT,
      ETALT: items.ETALT,
      ETDLT: items.ETDLT,
      id: items.legId,
      sector: items.sector,
      UTC1: fromPage === 'aircraftList' ? items.timezonedetails.UTC1 : items.route.timezonedetails.UTC1,
      UTC2: fromPage === 'aircraftList' ?  items.timezonedetails.UTC2 : items.route.timezonedetails.UTC2,
      DEPTTIMELOCAL: moment(
        this.flyingDatetime.transform(
          items.departDate,
          items.BLKTIME,
          "LT",
          items.fromStation.timeZone,
          "from",
        ),
      ).format("D-MMM HH:mm"),
      DEPTTIMEUTC: moment(
        this.flyingDatetime.transform(
          items.departDate,
          items.BLKTIME,
          "UTC",
          items.fromStation.timeZone,
          "from",
          "zulu",
        ),
      ).format("D-MMM HH:mm"),
      ARRTIMELOCAL: moment(
        this.flyingDatetime.transform(
          items.departDate,
          items.BLKTIME,
          "LT",
          items.toStation.timeZone,
          "to",
        ),
      ).format("D-MMM HH:mm"),
      ARRTIMEUTC: moment(
        this.flyingDatetime.transform(
          items.departDate,
          items.BLKTIME,
          "UTC",
          items.toStation.timeZone,
          "to",
          "zulu",
        ),
      ).format("D-MMM HH:mm"),
      SRID: index == "0" ? items.srid : "",
    };
  });
}
  // charter InvoiceList call
  public fetchCharterInvoiceList() {
    return new Promise((resolve, reject) => {
      const databody = '';
      this.connectToServer.connectToServer(this.apiType.POST, CharterUriConfig.GetCharterInvoicesAll, databody, false, false, false).then((res: any) => {
        resolve(res.GetCharterInvoicesAll);
      }, (error) => {
        reject();
      }
      )
    });
  }

  // Charter InvoiceView call
  public charterInvoiceView(invoiceId) {
    return new Promise((resolve, reject) => {
      const databody = "charterinvoiceid=" + invoiceId;
      this.connectToServer.connectToServer(this.apiType.POST, CharterUriConfig.GetCharterInvoiceDetails, databody, false, false, false).then((res: any) => {
        resolve(res.GetCharterInvoiceDetails);
      }, (error) => {
        reject();
      }
      )
    });
  }

  // Convert utc to local time
  public convertAirporttime(timezone, time) {
    if (timezone != '' && timezone) {
      return timezones.tz(time, timezone).format();
    } else {
      return timezones(time).format();
    }
  }
  public covertUTCtime(timeZone, time) {
    return timezones.tz(time, timeZone).utc().format();
  }
  public makeTripTime(addtime, time) {
    return timezones.utc(time).add(addtime, 'minutes').format();
  }
  public addFlyingTime(addtime, time) {
    return timezones.utc(time).add(addtime, 'minutes').format();
  }
  public convertominute(time?) {
    const startTime = time?.split(':');
    const startMinutes = Number(startTime?.[0]) * 60 + Number(startTime?.[1]);
    return Number(startMinutes);
  }
  public addTimeByType(addTime, time, timeType, timezone) {
    if(timeType == 'true') {
    return  this.addTime(addTime, time, timezone);
    }else {
    return  this.addTimeArrivalUTC(addTime, time);
    }
  }
  public addTime(addTime, time, timezone) {
    return timezones.tz(time, timezone).add(addTime, 'minutes').format();
  }
  public addTimeArrivalUTC(addTime, time){
    return timezones.utc(time).add(addTime, 'minutes').format();
  }
  public toDisplayDefaultDate(departDate, timeType, tripTime, timezone,flagForFirstLeg?:boolean) {
    if (departDate == 'default') {
      if (timeType == 'true') {
        this.departureDate = tripTime;
      } else {
        this.departureDate = this.covertUTCtime(timezone, tripTime);
      }
    } else if (departDate == 'switch') {
      if (timeType == 'true') {
        // LT
        this.departureDate = this.convertAirporttime(
          timezone,
          this.makeTripTime(20, tripTime)
        );
      } else {
        // utc
        this.departureDate = this.makeTripTime(20, tripTime);
      }
    } else if (departDate == 'next') {
      if (timeType == 'true') {
         this.departureDate = this.convertAirporttime(
          timezone,
          this.makeTripTime(20, tripTime)
        );
      } else {
         // this is for utc
        this.departureDate = this.makeTripTime(20, tripTime);
      }
    } else if (departDate == 'edit') {
      if (timeType == 'true') {
        this.departureDate = this.convertAirporttime(
          timezone,
          this.makeTripTime(20, tripTime)
        );
        // the flagForFirstLeg will say about only edit of first leg
        if(flagForFirstLeg) {
          this.departureDate = this.convertAirporttime(
            timezone,
            this.makeTripTime(0, tripTime)
          );
        }
      } else {
        this.departureDate = tripTime;
      }
    } else if (departDate == 'add') {
      if (timeType == 'true') {
        this.departureDate = this.convertAirporttime(
         timezone,
         this.makeTripTime(20, tripTime)
        );
      } else {
        // this is for utc
        // this.departDate = this.makeTripTime(20, tripTime);
      }
    } else if( departDate == 'Delete') {
      if(timeType == 'true') {
        this.departureDate = this.convertAirporttime(
          timezone,
          this.makeTripTime(20, tripTime)
        );
      }
    }
  }
  public departDate(departDate, departureDate, timeZone?){
    if (departDate != 'switch') {
      this.minLTtime = this.convertAirporttime(
        timeZone,
        departureDate
      );
      this.minUTCtime = this.covertUTCtime(
        timeZone,
        departureDate
      );
    } else {
      // this.minLTtime = minLTtime;
      // this.minUTCtime = minUTCtime;
    }
  }
  public GetBlktime(setTrip){
    return new Promise((resolve, reject) => {
      const data = {
      depart: setTrip.departDate,
      departLTStartDate: setTrip.departStartDate,
      startTimeZone:setTrip.starttimeZone,
      endTimeZone: setTrip.endtimeZone,
      flightType: setTrip.flightType,
      ruleType: setTrip.flightRule,
      legIndex: setTrip.legId,
      operationType: setTrip.OperationType,
      pax: setTrip.pax,
      timeType: setTrip.timeType,
      }
      const databody = 'data=' +
      JSON.stringify(data) + '&from=' + setTrip.fromStation + "&to=" + setTrip.toStation;
      this.connectToServer.connectToServer(this.apiType.POST, CharterUriConfig.getBlktime, databody, false, false, false).then((res: any) => {
        resolve(res.GetBlktime);
      }, (error) => {
        reject(false);
      }
      )
    });
  }
}
