export const activityUriConfig = {
  getClientTripActivity: '/index.php?page=API&action=GetTripActivity',
  saveRawTripData: '/index.php?page=API&action=saveRawTripData',
  GetTripScheduleDetails: '/index.php?page=API&action=GetTripScheduleDetails',
  getClientTripRoute: '/index.php?page=API&action=GetTripRouteDetails',
  getClientTripManifest: '/index.php?page=API&action=GetTripManifest',
  getClientTripService: '/index.php?page=API&action=GetTripServiceDetails',
  getVendorTripActivity: '/index.php?page=API&action=GetVendorTripsActivity',
  getVendorTripSchedule:
    '/index.php?page=API&action=GetVendorTripsScheduleDetails',
  getVendorTripRoute: '/index.php?page=API&action=GetVendorTripsRouteDetails',
  getVendorTripManifest:
    '/index.php?page=API&action=GetVendorTripsDetailsManifest',
  viewTripsManifest: '/index.php?page=API&action=ViewTripsManifest',
  getVendorTripServiceDetails:
    '/index.php?page=API&action=GetVendorTripsService',
  savePermitNumberOfClient: '/index.php?page=API&action=AddVendorPermitNumber',
  UploadATCRoute: '/index.php?page=API&action=ProcessUploadCBP',
  EnterFuelIntoplaneDetails: '/index.php?page=API&action=AddVendorFuelDetails',
  EnterUpcomingGroundHandlingDetails:
    '/index.php?page=API&action=AddVendorGroundChargesUpcomingDetails',
  EnterUpcomingAirportChargesDetails:
    '/index.php?page=API&action=AddVendorAirportChargesUpcomingDetails',
  EnterAirpermissionPermit: '/index.php?page=API&action=AddVendorPermitNumber',
  SelectFlightPlanByOperator:
    '/index.php?page=API&action=SelectFlightPlanByOperator',
  ConfirmServiceDelivered: '/index.php?page=API&action=SaveVendorFDNSignoff',
  EnterBayNo: '/index.php?page=API&action=AddVendorBayNumber',
  EnterFuelInprogressDetails:
    '/index.php?page=API&action=AddVendorFuleFileUpload',
  EnterInprogressGroundHandlingDetails:
    '/index.php?page=API&action=SaveInprogressGroundHandSeubService',
  EnterInprogressAirportChargesDetails:
    '/index.php?page=API&action=SaveInprogressAirportChargesSubService',
  RequestToActivity: '/index.php?page=API&action=SingleAccept',
  GetTripSchedule: '/index.php?page=API&action=GetTripSchedule',
  // getResSelection of vendor for each service
  GetReSelectVendor: '/index.php?page=API&action=ChooseVendor',
  ReSelectVendor: '/index.php?page=API&action=SaveClientConfirmation',

  ClientSideMenu: '/index.php?page=API&action=OpenClientSideMenu',
  VendorTripsUpcoming:
    '/index.php?page=API&action=GetVendorTripsUpcomingActivity',
  VendorTripsInprogress: '/index.php?page=API&action=GetVendorTripsInprogress',
  GetTripCostEstimate: '/index.php?page=API&action=GetTripCostEstimateDetails',
  GetTripCostSheet: '/index.php?page=API&action=GetTripCostSheetDetails',
  GenralDeclaration: '/index.php?page=API&action=genrateGenralDeclaration',
  GetCBP: '/index.php?page=API&action=GetCBP',
  GenratePaxManifest: '/index.php?page=API&action=genratePaxManifest',

  SaveFuelOnFlagOfClient: '/index.php?page=API&action=AddFuelOn',
  fileFlightplan:
    '/index.php?page=API&action=GetFlightPlanResponseByResponseID',
  attachIcaoFlightPlan: '/index.php?page=API&action=AttachIcaoFlightPlan',
  fileFlightPlanAvplat: '/index.php?page=API&action=FileFlightPlanAvplat',
  SubmitRatingArray: '/index.php?page=API&action=SaveFeedbackinformation',
  CancellFlightPlan: '/index.php?page=API&action=CancelFlightPlanAvplat',
  DownloadFiledFlightPlan: '/index.php?page=API&action=DownloadFiledFlightPlan',
  GetSortIcao: '/index.php?page=API&action=GetSortIcao',
  refreshDocument: "/index.php?page=API&action=RefreshDocument",
  getQuarantineMappingInfo:
    '/index.php?page=API&action=GetQuarantineMappingInfo',
  downloadDeclaration: '/index.php?page=API&action=DownloadDeclaration',
  downloadOnBordingPass: '/index.php?page=API&action=DownloadOnBordingPass',
  getQuoteAll: '/index.php?page=API&action=GetQuoteAll',
  getVendorTripsAll: '/index.php?page=API&action=GetVendorTripsAll',
  getVendorTrips: '/index.php?page=API&action=GetVendorTrips',
  cancelTrip: '/index.php?page=API&action=CancellTrip',
  selectFlightPlanByOperator:
    '/index.php?page=API&action=SelectFlightPlanByOperator',
  getWx: '/index.php?page=API&action=GetWx',
  getAirportInformation: '/index.php?page=API&action=GetAirportInformation',
  getAirportInfo: '/index.php?page=API&action=GetAirportInfo',
  addClientGroundCharges: '/index.php?page=API&action=AddClientGroundCharges',
  saveFlightPlan: '/index.php?page=API&action=SaveFlightPlan',
  getFuelReleaseNote: '/index.php?page=API&action=GetFuelReleaseNote',
  SaveVendorConfirmation: '/index.php?page=API&action=SaveVendorConfirmation',
  ChangeVendorQuoteStatusDeclined:
    '/index.php?page=API&action=ChangeVendorQuoteStatusDeclined',
  SaveServicesHaveBeenDelivered:
    '/index.php?page=API&action=SaveServicesHaveBeenDelivered',
  GetHotelDetails: '/index.php?page=API&action=GetHotelDetails',
  DeleteHotelRoom: '/index.php?page=API&action=DeleteHotelRoom',
  GetSpecialRequest: '/index.php?page=API&action=GetSpecialRequest',
  SaveSpecialRequestItem: '/index.php?page=API&action=SaveSpecialRequestItem',
  DeleteSpecialRequestItem:
    '/index.php?page=API&action=DeleteSpecialRequestItem',
  GetCateringDetails: '/index.php?page=API&action=GetCateringDetails',
  SaveCateringDetails: '/index.php?page=API&action=SaveCateringDetails',
  DeleteCateringItem: '/index.php?page=API&action=DeleteCateringItem',
  SaveHotelDetails: '/index.php?page=API&action=SaveHotelDetails',
  GetTCEDataByDraftID: '/index.php?page=API&action=GetTCEDataByDraftID',
  GetTripEstimateDetails: '/index.php?page=API&action=GetTripEstimateDetails',
  GetATCStripByRouteID: '/index.php?page=API&action=GetATCStripByRouteID',
  FinalizeCBP: '/index.php?page=API&action=FinalizeCBP',
  ProcessSaveFuel: '/index.php?page=API&action=ProcessSaveFuel',
  FuelBasicPrice: '/index.php?page=API&action=FuelBasicPrice',
  ActivateTCE: '/index.php?page=API&action=NewRegisterTrip',
  ProcessSaveAirportCharges: '/index.php?page=API&action=ProcessSaveAirportCharges',
  airportChargesByAirportChargeId: "/index.php?page=API&action=AirportChargesByAirportChargeId",
  updateDraftTrip: "/index.php?page=API&action=UpdateDraftTrip",
  getVendorInfo: "/index.php?page=API&action=GetVendorInfo",
  saveGroundHandling: "/index.php?page=API&action=SaveGroundHandling",
  getHandlingData: "/index.php?page=API&action=GetGroundHandlingDetailsBySetupID",
  legChangesActivateTrip: "/index.php?page=API&action=LegChangesRegistration",
  DeleteFDNAttachment: "/index.php?page=API&action=DeleteFDNAttachment",
  GetLegchangeTCE: "/index.php?page=API&action=GetTCEDataAfterLegchange",
  DeleteHandlingAttachment : "/index.php?page=API&action=DeleteHandlingAttachment",
  DeleteAirportchargesAttachment : "/index.php?page=API&action=DeleteAirportchargesAttachment",
  UpdateLogoGroundHandling: "/index.php?page=API&action=UpdateLogoGroundHandling",
  UpdateLogoFuel: "/index.php?page=API&action=UpdateLogoFuel",
  UpdateLogoAirportCharges: "/index.php?page=API&action=UpdateLogoAirportCharges",
  SaveFlpDocs : "/index.php?page=API&action=SaveFlpDocs"

};

export const FLPConfig = {
  getListOfFlightPlans: "/index.php?page=API&action=FlightPlan",
}

export const NFPURI = {
  generateRoute: '/api/v1/ComputeFlight',
  uploadATCRoute: '/api/v1/ComputeFlightByATCRoute',
  attachIcaoFlightPlanManual: '/api/v1/FileFlightPlan/manualFile',
  attachIcaoFlightPlan: '/index.php?page=API&action=AttachIcaoFlightPlan',
  cancellFlightPlan: '/api/v1/FileFlightPlan/cancel',
  delayFlightPlan: '/api/v1/FileFlightPlan/delay',
  changeFlightPlan: '/api/v1/FileFlightPlan/change',
  validateRoute: '/api/v1/ValidateRoute',
  productionAndRemarks: '/api/v1/ProductionAndRemarks',
  weatherNotam: '/api/v1/WeatherNotam',
};
export const APS_URL_CONFIG = {
  GetListOfTrips: '/index.php?page=API&action=GetListOfTrips',
  ViewTripsManifest: '/index.php?page=API&action=ViewTripsManifest',
  GetTripSchedule: '/index.php?page=API&action=GetTripSchedule',
  DownloadDeclaration: '/index.php?page=API&action=DownloadDeclaration',
};
