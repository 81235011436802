import { FirebaseDataService } from '@app/shared/services/firebase-data.service';
import { CommonService } from '@app/shared/services/common.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'covrtcurrency',
})
export class CurrencyConversionPipe implements PipeTransform {
  constructor(
    private fbDatabase: FirebaseDataService,
    private commonService: CommonService
  ) {}
  public transform(value: any, type: string, pageType?: any, flag?:boolean) {
    if (
      value == null ||
      Number(value) == 0 ||
      value == null ||
      this.commonService.userCurrency.currencyId == 'USD' ||
      !this.fbDatabase.currencyPrices[
        this.commonService.userCurrency.currencyId
      ] ||
      value == '.'
    ) {
      return '';
    } else {
      let price = '';
      if (type == 'user') {
        if (
          this.fbDatabase.currencyPrices[
            this.commonService.userCurrency.currencyId
          ]
        ) {
          const obj = {
            currencyPrice:
              this.fbDatabase.currencyPrices[
                this.commonService.userCurrency.currencyId
              ],
            currencyCode: this.commonService.userCurrency.currencyId,
          };
          for (const p in obj) {
            if (obj.hasOwnProperty(p)) {
              let z = String(
                (
                  value *
                  this.fbDatabase.currencyPrices[
                    this.commonService.userCurrency.currencyId
                  ]
                ).toLocaleString('en', {
                  useGrouping: false,
                })
              );
              let storecurrValue;
              if (pageType != 'updateCurrency') {
                z = z.indexOf('.') != -1 ? z.slice(0, z.indexOf('.') + 3) : z;
                storecurrValue = Math.round(Number(z)).toLocaleString('en', {
                  useGrouping: false,
                });
              } else {
                z =
                  z.indexOf('.') != -1 && z.indexOf('.') != 1
                    ? z.slice(0, z.indexOf('.') + 3)
                    : z;
                storecurrValue = Math.round(Number(z));
              }

              const cPrice = {
                currPrice: storecurrValue
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                currCode: ' ' + this.commonService.userCurrency.currencyId,
              };
              price = cPrice.currPrice + cPrice.currCode;
            }
          }
        } else {
          price = '';
        }
      } else if (type == 'rounded') {
        if (
          this.fbDatabase.currencyPrices[
            this.commonService.userCurrency.currencyId
          ]
        ) {
          price =
            String(
              (
                Number(value) *
                (flag == true ? (Math.floor(this.fbDatabase.currencyPrices[
                  this.commonService.userCurrency.currencyId
                ]*100)/100) : this.fbDatabase.currencyPrices[
                  this.commonService.userCurrency.currencyId
                ])
              ).toFixed(0)
            ).replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
            ' ' +
            this.commonService.userCurrency.currencyId;
        } else {
          price = '';
        }
      } else if (type == 'round') {
        if (
          this.fbDatabase.currencyPrices[
            this.commonService.userCurrency.currencyId
          ]
        ) {
          price =
            String(
              (
                Number(value) *
                this.fbDatabase.currencyPrices[
                  this.commonService.userCurrency.currencyId
                ]
              ).toFixed(0)
            ).replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
            ' ' +
            this.commonService.userCurrency.currencyId;
        } else {
          price = '';
        }
      } else {
        if (
          this.fbDatabase.currencyPrices[
            this.commonService.userCurrency.currencyId
          ]
        ) {
          price =
            String(
              Number(value) /
                this.fbDatabase.currencyPrices[
                  this.commonService.userCurrency.currencyId
                ]
            ).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' USD';
        } else {
          price = '';
        }
      }
      return price;
    }
  }
}
