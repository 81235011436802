import { ProfileData } from './../../models/right-menu.model';
import { Component, OnInit } from '@angular/core';
import { ChatInfo, UnreadNotification } from '@app/right-menu/models/right-menu.model';
import { MessageConfig } from '@app/shared/constants/constant';
import { CommonService } from '@app/shared/services/common.service';
import { Events } from '@app/shared/services/events.service';
import firebase from "firebase/app";

@Component({
  selector: 'app-recent-chat',
  templateUrl: './recent-chat.component.html',
  styleUrls: ['./recent-chat.component.scss'],
})
export class RecentChatComponent implements OnInit {
  public recenChatList: any;
  public recentChatProfile: ProfileData;
  public recenChatListProfile: any;
  constructor(private events: Events,
    private commonService: CommonService) { }

  public ngOnInit() {
    this.fetchData();
  }
  // fetch data from firebase
  public fetchData() {
    this.commonService.showSpinnerLoader();
    firebase
      .database()
      .ref(this.recentChat())
      .on("value", (snapshot: any) => {
        if (snapshot.val() != null) {
          this.recenChatList = snapshot.val();
          this.chatDetails(this.recenChatList);
        }
      });
    this.commonService.dismissLoader();
  }

  public chatDetails(recentChatList) {
    const storeList = [];
    for (const recentchatid of Object.keys(recentChatList)) {
      for (const recentchat of Object.keys(recentChatList[recentchatid])) {
        const chatsInfo = recentChatList[recentchatid][recentchat]
        const data = firebase
          .database()
          .ref("/UserProfile/" + chatsInfo.chatDetails.toChatId);
         data.on("value", (snapshot5) => {
          this.recentChatProfile = new ProfileData(snapshot5.val());
          this.recenChatList[recentchatid][recentchat].name = this.recentChatProfile.name;
          this.recenChatList[recentchatid][recentchat].photoUrl = this.recentChatProfile.photoUrl;
          storeList.push(this.recentChatProfile);
        });
      }
    }
  }
  // for profile refrence
  public userProfileRef(chatInfo) {
    const data = firebase
      .database()
      .ref("/UserProfile/" + chatInfo.toChatId);
    data.on("value", (snapshot5) => {
      this.recentChatProfile = new ProfileData(snapshot5.val());
    });
  }

  // recentchat reference
  public recentChat() {
    return `/RecentChat/${this.commonService.uid}`;
  }
  // openlive chat
  public openLiveChat(notification: UnreadNotification) {
    this.events.publish(
      "openChat",
      new ChatInfo(
        notification.chatDetails.toChatId,
        notification.chatDetails.tripId,
        notification.chatDetails.legId,
        notification.chatDetails.location,
        notification.chatDetails.service,
        "LiveChat",
        "recentChat",
      ),
    );
  }
  public deleteChat(recentchatid, index) {
    this.commonService
      .showConfirmAlert(MessageConfig.RecentChat)
      .then((response) => {
        if (response) {
          this.recenChatList[recentchatid].splice(index, 1);
          firebase
            .database()
            .ref(
              "RecentChat/" +
              this.commonService.uid +
              "/" +
              recentchatid,
            )
            .set(this.recenChatList[recentchatid]);
        }
      });
  }
}
