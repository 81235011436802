import { PriceBreakupConst } from './../../../trips/schedule-creation/model/vendor-listing';
import { ModalController } from '@ionic/angular';
import { FirebasePlaformKey, MessageConfig } from '@app/shared/constants/constant';
import { Component, OnInit, Input } from '@angular/core';
import { NotificationObj } from '@app/shared/models/shared.model';
import { FirebaseDataService } from '@app/shared/services/firebase-data.service';
import { NotificationService } from '@app/shared/services/notification.service';
import { AboutUserComponent } from '@app/trips/trips-tab/trip-services/about-user/about-user.component';
import { Notification, TripActivityModel } from '@app/trips/trips-tab/model/model.index';
import { CommonService } from '@app/shared/services/common.service';
import { TripActivityService } from '@app/trips/services/trip-activity.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  public notificationArr: NotificationObj[];
  public searchArr: NotificationObj[];
  public messageConfig = MessageConfig;
  public subscription: Subscription;
  @Input() public pageType :string;
  @Input() public tripModel = new TripActivityModel();
  constructor(public firebaseService: FirebaseDataService,
    public tripActivityService: TripActivityService,
    private modalController: ModalController, private commonService: CommonService) {
  }
  public ngOnInit(): void {
    this.subscription = this.firebaseService.$NotificationsRef.subscribe(notifications => {
      if (notifications) {
        this.getNotificationInfo(notifications);
      }
    })
     this.NotificationInit();
  }
  public NotificationInit(){
    this.subscription = this.firebaseService.CharterNotificationsUnread.subscribe((res)=>{
       if (res && this.pageType == 'allNotification') {
        this.getNotificationInfo(res);
      }
    });
  }
  public ionViewWillEnter(){

  }

  public getNotificationInfo(ObjectData) {
    this.notificationArr = [];
    this.searchArr = [];
    const refData = [];
    if (ObjectData != null) {
      for (const key in ObjectData) {
        if (key)
          refData.push(ObjectData[key]);
      }
      refData.forEach(element => {
        this.searchArr.push(element);
        this.notificationArr.push(element);
      });
    }
  }

  public searchItem(ev: any) {
    const val = ev.target.value;
    if (val && val != '') {
      this.notificationArr = this.searchArr.filter((element: NotificationObj) => {
        return (element.content.toLowerCase().indexOf(val.toLowerCase()) > -1);
      })
    } else {
      this.notificationArr = this.searchArr;
    }
  }
  public async vendorInfo(service: Notification) {
    /* Future required this code for reseller logo */
  if(service.Sendertype == "client"){
    if (service.sender == this.commonService.uid || service.sender == FirebasePlaformKey.key) { return false; }
    const popover = await this.modalController.create({
      component: AboutUserComponent,
      componentProps: {
        VendorId: this.tripModel.activityData.clientId,
        // Type:  : service.Sendertype == "vendor"
        // ? "Vendor"
        Type:
          service.Sendertype == "client"
            ? "Operator": service.Sendertype,
        Logo: service.logo,
        firebaseuid: service.sender,
      },
    });
    await popover.present();
  }
  }
  public ngOnDestroy() {
    this.firebaseService.CharterNotificationsUnread.next(null);
    this.firebaseService.$NotificationsRef.next(null);
    this.subscription.unsubscribe();
  }
}
