import { NavParams } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';
import { MessageConfig } from '@app/shared/constants/constant';
import { UnitCurrecyService } from '@app/shared/services/unit-currecy.service';
import {
  PriceBreakupConst,
  ServicesConst,
} from '@app/trips/schedule-creation/model/vendor-listing';
import { CommonService } from '@app/shared/services/common.service';

@Component({
  selector: 'app-price-rate-breakup',
  templateUrl: './price-rate-breakup.component.html',
  styleUrls: ['./price-rate-breakup.component.scss'],
})
export class PriceRateBreakupComponent implements OnInit {
  @Input() public priceListBreakup: any;
  @Input() public sector: string;
  @Input() public station: string;
  @Input() public serviceName: string;
  @Input() public grandTotal: any;
  @Input() public requestType: string;
  @Input() public backEndFormate:any;
  @Input() public pageType:any;
  public PriceBreakUpConst = PriceBreakupConst;
  public ServicesName: typeof ServicesConst = ServicesConst;
  public operUnitPrice: any;
  public operUnit: any;
  public operCur: any;
  public vendorInformation: any;
  public carbonSelected: boolean;
  public carbonOfsetPrice: any = 0;
  public fuelTotal: any = 0;
  public fuelfeesTotal: any = 0;
  public ssfuelTotal: any = 0;
  public fuelSurTotal: any = 0;
  public fuelTaxesTotal: any = 0;
  public fuelProd: any = 0;
  public servicePostion: string = '';
  public MessageConst = MessageConfig;

  constructor(private unitConvet: UnitCurrecyService, private navParams: NavParams, private commonService: CommonService) { }

  public ngOnInit() {
    this.vendorInformationInit();
  }
  public vendorInformationInit() {
    this.vendorInformation = this.navParams.get("vendorInformation"); // temp
    if (this.serviceName == 'fuel') {
      if (this.vendorInformation) {
        if (
          this.vendorInformation.carbonoffsetstatus == undefined ||
          this.vendorInformation.carbonoffsetstatus == 1
        ) {
          this.carbonSelected = true;
        } else {
          this.carbonSelected = false;
        }
      }
      let Sgravity: any;
      let UnitPrice: any;
      this.priceListBreakup.price.forEach((prices: any) => {
        if (prices.serviceName == this.ServicesName.FuelUplift) {
          prices.price.forEach((subPrice: any) => {
            if (subPrice.name != this.ServicesName.SG) {
              this.fuelTotal += Number(subPrice.total);
            } else {
              Sgravity = Number(subPrice.price);
            }
          });
        }
        if (prices.serviceName == this.ServicesName.FuelPrice) {
          prices.price.forEach((subbPrice: any) => {
            this.ssfuelTotal += Number(subbPrice.price);
            if (subbPrice.name == 'UnitPrice') {
              UnitPrice = Number(subbPrice.price);
            }
          });
          prices.fees.forEach((subfeePrice: any) => {
            this.fuelfeesTotal += Number(subfeePrice.price);
          });
          prices.surcharges.forEach((subsurPrice: any) => {
            this.fuelSurTotal += Number(subsurPrice.price);
          });
          prices.taxes.forEach((subtaxPrice: any) => {
            this.fuelTaxesTotal += Number(subtaxPrice.price);
          });
          const fuelPrice = Number(this.ssfuelTotal.toFixed(2));
          this.fuelProd += this.fuelTotal * fuelPrice;
          prices.productTotal.push(this.fuelProd);
          prices.clientDetails.forEach((cd: any) => {
            this.operUnit = cd.fuelUnit;
            this.operCur = cd.currencyValue;
            const unitConvt = this.unitConvet.userconvertUnit(
              Sgravity,
              1,
              cd.fuelUnit
            );
            const currencyConvert = this.unitConvet.platformToClientCurrency(
              UnitPrice,
              cd.currencyValue
            );
            this.operUnitPrice = Number(
              Number(currencyConvert) / Number(unitConvt)
            );
          });
        }
        if (prices.serviceName == 'Carbon Offset') {
          prices.price.forEach((subPrice) => {
            this.carbonOfsetPrice += Number(subPrice.price);
          });
        }
      });
    } else {
      this.carbonSelected = true;
    }
    if (this.serviceName == 'permit') {
      this.priceListBreakup.price.forEach((prices: any) => {
        if (prices.serviceName == 'Ovf Permits') {
          let revisionSUbTotal: number = 0;
          prices.price.forEach((revSubTotalPriceStru) => {
            if (revSubTotalPriceStru.revisionsArr) {
              revisionSUbTotal += Number(
                revSubTotalPriceStru.revisionsArr.price
              );
              if (revSubTotalPriceStru.revisionsArr.Taxes) {
                revSubTotalPriceStru.revisionsArr.Taxes.forEach((revTaxes) => {
                  revisionSUbTotal += Number(revTaxes.DisplayPrice);
                });
              }
            }
            revSubTotalPriceStru.subTotal += Number(revisionSUbTotal);
          });
        }
      });
    }
    if (this.serviceName == 'airportcharges') {
      this.priceListBreakup.price.forEach((prices: any) => {
        if (prices.serviceName == 'APT Permits') {
          let revisionSUbTotal: number = 0;
          prices.price.forEach((DeprevSubTotal) => {
            if (DeprevSubTotal.revisionsArr) {
              revisionSUbTotal += Number(DeprevSubTotal.revisionsArr.price);
              if (DeprevSubTotal.revisionsArr.Taxes) {
                DeprevSubTotal.revisionsArr.Taxes.forEach((revTaxes) => {
                  revisionSUbTotal += Number(revTaxes.DisplayPrice);
                });
              }
            }
            DeprevSubTotal.subTotal += Number(revisionSUbTotal);
          });
        }
      });
    }
  }
  public selectCarbonOffset() {
    // this.commonService.showConfirmAlert(this.carbonSelected ? 'Remove carbon offset' : 'Add carbon offset').then((res) => {
    //   if (res) {
    if (this.carbonSelected) {
      this.carbonSelected = false;
      this.grandTotal = this.grandTotal - this.carbonOfsetPrice;
      this.vendorInformation.carbonoffsetstatus = 0;
      this.vendorInformation.carbonValue = this.carbonOfsetPrice;
      this.vendorInformation.price =
        this.vendorInformation.price - this.carbonOfsetPrice;
    } else {
      this.carbonSelected = true;
      this.grandTotal = this.grandTotal + this.carbonOfsetPrice;
      this.vendorInformation.carbonoffsetstatus = 1;
      this.vendorInformation.carbonValue = this.carbonOfsetPrice;
      this.vendorInformation.price =
        this.vendorInformation.price + this.carbonOfsetPrice;
    }
    //   }
    // });
  }

  public fuelEnum() {
    return this.serviceName == this.ServicesName.Fuel ? true : false;
  }
  public operatorCur(name) {
    if (
      name == this.ServicesName.Unitprice &&
      (this.requestType == this.ServicesName.Vendor ||
        this.requestType == this.ServicesName.Client)
    ) {
      return name;
    } else if (
      name == this.ServicesName.Unitprice &&
      typeof this.requestType == 'undefined'
    ) {
      return name;
    }
  }

  public setTimeHour(subNavPrice) {
    const navPriceTime = subNavPrice.split(' ');
    const navTime = navPriceTime[0].substring(0, navPriceTime[0].length - 3);
    const navHours = navPriceTime[0].substr(-3);
    const timehours = `${navTime} ${navHours}`;
    return timehours;
  }
}
