import { Pipe, PipeTransform } from '@angular/core';
import { CommonService } from '../services/common.service';
import { UnitCurrecyService } from '../services/unit-currecy.service';

@Pipe({
  name: 'unitConversion'
})
export class UnitConversionPipe implements PipeTransform {
  constructor(private commonService: CommonService, private unitcurrencyService: UnitCurrecyService){}
  public transform(value: any, type: string, sgravity: number, perunit: boolean, slecetedUnit: string, selectedCurrency: string, nofUnit?: number, amount?: any) {
    if (value == '' || value == null || sgravity == null || sgravity == undefined || (selectedCurrency == 'USD' && slecetedUnit == 'USG')) {
      return '';
    }
    else {
      // if (sgravity != 0) {
      //   sgravity = sgravity / 1000;
      // }
      let conversion = '';
      let returnvalue: any;
      if (perunit) {
        // user will get the value as per his setup
        if (type == 'user') {
          returnvalue = this.unitcurrencyService.vendorconvertperUnit(sgravity, nofUnit, amount, slecetedUnit, selectedCurrency, 'user');
          returnvalue = returnvalue + '' + selectedCurrency + '/' + slecetedUnit
          return returnvalue;
        }
        // vendor will see the platfrom value
        else if (type == 'vendor') {
          returnvalue = this.unitcurrencyService.vendorconvertperUnit(sgravity, nofUnit, amount, slecetedUnit, selectedCurrency, 'vendor');
          return Number(returnvalue).toFixed(4) + ' USD/USG';
        } else if (type == 'platts') {
          returnvalue = this.unitcurrencyService.vendorconvertperUnit(sgravity, nofUnit, amount, slecetedUnit, selectedCurrency, 'vendor');
          return Number(returnvalue).toFixed(4);
        } else if (type == 'plattsuser') {
          returnvalue = this.unitcurrencyService.vendorconvertperUnit(sgravity, nofUnit, amount, slecetedUnit, selectedCurrency, 'user');
          return Number(returnvalue).toFixed(4);
        }
      }
      else {
        // user will get the value as per his setup
        if (type == 'user') {
          returnvalue = this.unitcurrencyService.userconvertUnit(sgravity, value, slecetedUnit);
          switch (slecetedUnit) {
            case 'LTR': // lit
              return returnvalue = returnvalue + ' LTR';
              break;
            case 'USG': // usg
              return returnvalue = returnvalue = '';
              break;
            case 'Pound': // lbs
              return returnvalue = returnvalue + ' LBS';
              break;
            case 'KG': // kg
              return returnvalue = returnvalue + ' KG';
              break;
            case 'MT': // mt
              return returnvalue = returnvalue + ' MT';
              break;
            default:
              return returnvalue = '';
              break;
          }
          return returnvalue;
        }
        // vendor will see the platfrom value
        else if (type == 'vendor') {
          returnvalue = this.unitcurrencyService.vendorconvertUnit(sgravity, value, slecetedUnit);
          switch (slecetedUnit) {
            case 'LTR': // lit
              return returnvalue + ' LTR';
              break;
            case 'USG': // usg
              return returnvalue + ' USG';
              break;
            case 'Pound': // lbs
              return returnvalue + ' LBS';
              break;
            case 'KG': // kg
              return returnvalue + ' KG';
              break;
            case 'MT': // mt
              return returnvalue + ' MT';
              break;
            default:
              conversion = '';
              break;
          }
          return returnvalue;
        }
      }
    }
  }

}
