import { AuthSessionService } from './../../../core/services/auth-session.service';
import { CommonService } from '@app/shared/services/common.service';
import { MenuController } from '@ionic/angular';
import { Component, NgZone, OnInit } from '@angular/core';
import { Events } from '@app/shared/services/events.service';
import firebase from "firebase/app";

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {
  public show = false;
  public data: any;
  public countofmessage = 0;
  private UID = '';

  constructor(private menuController: MenuController,
    private events: Events,
    private zone: NgZone,
    private commonService: CommonService,
    private authSessionService: AuthSessionService) {
    // this.totalChatCount();
  }

  public ngOnInit() {
    this.totalChatCount();
  }
  /* tslint:disable */
  public totalChatCount() {
    let unreadchatdataRef = firebase.database().ref(`/ChatNotification/${this.commonService.uid}`);
    unreadchatdataRef.on('value', (snapshot) => {
      this.countofmessage = 0;
      let tokenData = []
      if (snapshot.val()) {
        this.data = snapshot.val();
        for (let tochatuid in this.data) {
          for (let chattripid in this.data[tochatuid]) {
            for (let chatsector in this.data[tochatuid][chattripid]) {
              for (let chatservice in this.data[tochatuid][chattripid][chatsector]) {
                let mgsIds = Object.keys(this.data[tochatuid][chattripid][chatsector][chatservice]);
                mgsIds.forEach((msgid: string, index: number) => {
                  if (index == 0) {
                    tokenData.push(this.data[tochatuid][chattripid][chatsector][chatservice])
                  }
                });
              }
            }
          }
        }
      }
      const contOf = []
      for (const inde in tokenData) {
        for (const key in tokenData[inde]) {
          if (tokenData[inde][key].toChatId != this.commonService.uid) {
            contOf.push(tokenData[inde][key])
          }
        }
      }
      this.countofmessage = 0;
      this.countofmessage = contOf.length || 0;
    });
  }
  public openchat() {
    const sendData = {
      ShowActiveTripChat: 2,
      type: 'NotificationChat'
    }
    this.menuController.enable(true, 'end');
    this.menuController.toggle('end');
    this.events.publish('openChat', sendData);
  }

}
