import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  public transform(
    value?: any,
    args?: any,
    startLength?: any,
    radix?
  ): string {
    const limit = args.length > 0 ? parseInt(args[0], 4) : 4;
    if (value) {
      if (startLength) {
        return value.length > limit
          ? value.substring(parseInt(startLength, radix))
          : value;
      } else {
        return value.length > limit ? value.substring(0, limit) : value;
      }
    }
  }
}
