import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { TripInfoComponent } from './trip-info/trip-info.component';
import { TripDownloadsComponent } from './trip-downloads/trip-downloads.component';
import { TripActivitiesComponent } from './trip-activities/trip-activities.component';
import { TripChatComponent } from './trip-chat/trip-chat.component';
import { SharedComponentModule } from '@app/shared/components/shared-component.module';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { SimpleTimer } from 'ng2-simple-timer';
import { SharedDirectiveModule } from '@app/shared/directives/shared-directive.module';




@NgModule({
  imports: [IonicModule, CommonModule, SharedComponentModule, PipesModule,SharedDirectiveModule],
  declarations: [
    TripInfoComponent, TripDownloadsComponent,
    TripActivitiesComponent, TripChatComponent],
  exports: [TripInfoComponent,
    TripDownloadsComponent,
    TripActivitiesComponent,
    TripChatComponent],
  providers: [SimpleTimer]
})
export class TripInformationModule { }
