import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';



const routes: Routes = [
    {
        path: '404',
        component: PageNotFoundComponent
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes),
        CommonModule,
        FormsModule,
        IonicModule],
    exports: [RouterModule],
    declarations: [PageNotFoundComponent]
})
export class StatusCodeModule { }
