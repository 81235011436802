export const ApiConfig = {
  apiLogin: '/index.php?page=API&action=APILogin',
  signupUrl: '/index.php?page=API&action=SignUp',
  getClientTripActivity: '/index.php?page=API&action=GetTripActivity',
  getVendorTripActivity: '/index.php?page=API&action=GetVendorTripsActivity',
  ConfirmServiceDelivered: '/index.php?page=API&action=SaveVendorFDNSignoff',
  GetClientAircraft: '/index.php?page=API&action=GetClientAircrafts',
  ForgetPasswordMail: '/index.php?page=API&action=SendForgotPasswordMail',
  EmailVerification: '/index.php?page=API&action=SendEmailVerificationMail',
  AddPassengerClient: '/index.php?page=API&action=AddPassengerClient',
  choosePassengerClient: '/index.php?page=API&action=ChoosePassengerClient',
  getUserWalletBalance: '/index.php?page=API&action=GetUserWalletBalance',
  getPayOutRequests: '/index.php?page=API&action=GetPayOutRequests',
  CardListURL: '/index.php?page=API&action=GetUserAddedCard',
  VerifyUrl: '/index.php?page=API&action=VerifyCardByToken',
  DeleteUserCard: '/index.php?page=API&action=DeleteCard',
  Inactivecard: '/index.php?page=API&action=SaveWalletCardActivityStatus',
  ChargeCardAddToWallet: '/index.php?page=API&action=ChargeCardAddToWallet',
  PayOutRequest: '/index.php?page=API&action=PayOut',
  RegisterTripData: '/index.php?page=API&action=RegisterTrip',
  SaveTripData: '/index.php?page=API&action=saveRawTripData',
  getVendorQoute: '/index.php?page=API&action=GetVendorQuote',
  getVendorInfo: '/index.php?page=API&action=GetVendorInfo',
  getResellerInfo: '/index.php?page=API&action=GetResellerInfo',
  EnterBayNo: '/index.php?page=API&action=AddVendorBayNumber',
  updateQuarantineMappingInfo: '/index.php?page=API&action=UpdateSignOff',
  getQuarantineMappingInfo:
    '/index.php?page=API&action=GetQuarantineMappingInfo',
  downloadDeclaration: '/index.php?page=API&action=DownloadDeclaration',
  downloadOnBordingPass: '/index.php?page=API&action=DownloadOnBordingPass',
  getOverflyingCountryList:
    '/index.php?page=API&action=GetOverflyingCountryList',
  getFlightPlanningQQ:
    '/index.php?page=API&action=GetFlightPlanningQuickQuotes',
  getOverflyingQQ: '/index.php?page=API&action=GetOverflyingQuickquote',
  getFuelStationList: '/index.php?page=API&action=GetFuelStationList',
  getFuelQuickquote: '/index.php?page=API&action=GetFuelQuickquote',
  getHandlingQuickquote: '/index.php?page=API&action=GetHandlingQuickquote',
  getHandlingStationList: '/index.php?page=API&action=GetHandlingStationList',
  getAirportChargesQuickquote:
    '/index.php?page=API&action=GetAirportChargesQuickquote',
  quickquotesPdf: '/index.php?page=API&action=QuickquotesPdf',
  getAirportchargesStationList:
    '/index.php?page=API&action=GetAirportchargesStationList',
  ChooseCrewClient: '/index.php?page=API&action=ChooseCrewClient',
  AddCrewClient: '/index.php?page=API&action=AddCrewClient',
  GetVendorRateBreakup: '/index.php?page=API&action=GetVendorRateBreakup',
  getListOfTaxExcelData:'/index.php?page=API&action=GetListOfTaxesExcelData',
  getListOfHandlingExcelData:'/index.php?page=API&action=GetListOfGHExcelData',
  uploadHandlingExcelData: '/index.php?page=API&action=UploadGHExcelData',
  deleteHandlingExcelData: '/index.php?page=API&action=DeleteGHExcelData',
  uploadTaxExcelData: '/index.php?page=API&action=UploadTaxesExcelData',
  deleteTaxExcelData: '/index.php?page=API&action=DeleteTaxesExcelData',
  deleteCSV: '/index.php?page=API&action=DeleteCsv',
  uploadFuelExcelData:'/index.php?page=API&action=UploadFuelExcelData',
  getListOfFuelExcelData:'/index.php?page=API&action=GetListOfFuelExcelData',
  deleteFuelExcelData:'/index.php?page=API&action=DeleteFuelExcelData',
  uploadLandingExcelData: '/index.php?page=API&action=UploadCsv',
  uploadSlotExcelData: '/index.php?page=API&action=UploadCsv',
  uploadOverFlightExcelData: '/index.php?page=API&action=UploadCsv',
  getListOfLandingExcelData:'/index.php?page=API&action=GetListOfPermitsExcelData',
  getListOfSlotExcelData:'/index.php?page=API&action=GetListOfPermitsExcelData',
  getListOfOverFlightExcelData:'/index.php?page=API&action=GetListOfPermitsExcelData',
  getActionItemInfo: '/index.php?page=API&action=GetActionItemInfo',
  saveActionItemInfo: '/index.php?page=API&action=SaveActionItemInfo',
  optVerification: '/index.php?page=API&action=Twilio',
  deleteDeviceToken :'/index.php?page=API&action=DeleteDeviceToken'
};
