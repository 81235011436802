export class Cbp {
  public ATCRoute: string;
  public FileName: string = 'Attach Flight Plan';
  public sector: string;
  public SRID: string;
  public legid: number;
  public FilePath: string;
  public FlightLevel: string;
  public FlightSpeed: string;
  public alt1: string;
  public alt2: string;
  public modifiedData?: any;
}
export class IntoPlane {
  public name: string;
  public contactNo: any;
  public email: string;
  public Quantity?: number;
  public departure?: string = 'Arrival';
  public unit?: string = 'LITERS';
  public filename?: string;
  public selectedfile?: string;
  public FuelDialCode?: any;
}
