
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgotPasswordPage } from './forgot-password/forgot-password.page';
import { SignupPage } from './signup/signup.page';
import { SigninPage } from './signin/signin.page';

const routes: Routes = [
    {
        path: 'signin',
        component: SigninPage
    },
    {
        path: 'register',
        component: SignupPage,
    },
    {
        path: 'forgot',
        component: ForgotPasswordPage
    },
  {
    path: 'verify-otp',
    loadChildren: () => import('./verify-OTP/verify-otp/verify-otp.module').then( m => m.VerifyOTPPageModule)
  }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthRoutingModule { }
