import { CharterNotificationsComponent } from './../../charter-ui/charter-notifications/charter-notifications.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CharterNotificationsPageRoutingModule } from './charter-notifications-routing.module';

import { CharterNotificationsPage } from './charter-notifications.page';
import { SharedComponentModule } from '@app/shared/components/shared-component.module';
import { SharedDirectiveModule } from '@app/shared/directives/shared-directive.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CharterNotificationsPageRoutingModule,
    SharedComponentModule,
    SharedDirectiveModule
  ],
  declarations: [CharterNotificationsPage , CharterNotificationsComponent],
  exports: [CharterNotificationsComponent],
})
export class CharterNotificationsPageModule {}
