import { PinPage } from './../../setup/pin/pin.page';
import { PopoverController } from '@ionic/angular';
import { CommonService } from './../../shared/services/common.service';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  ActivatedRoute,
  NavigationExtras,
} from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { AuthSessionService } from '../services/auth-session.service';
@Injectable({
  providedIn: 'root',
})
export class SetupGuardService implements CanActivate {
  constructor(
    private router: Router,
    private commonService: CommonService,
    private storage: Storage,
    private popoverCtrl: PopoverController,
    private activatedRoute: ActivatedRoute,
    private authSessionService: AuthSessionService
  ) { }

  public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    let verified = await this.storage.get('IS_SETUP_PIN_VERIFIED');
    if (!verified) {
      const popover = await this.popoverCtrl.create({
        component: PinPage,
        cssClass: 'custom-setup-pin-modal',
        animated: false,
        componentProps: {
          roletype: 'client',
        },
        translucent: true,
      });
      await popover.present();
      popover.onDidDismiss().then(async () => {
        verified = await this.storage.get('IS_SETUP_PIN_VERIFIED');
        if (verified) {
          if (route.parent.routeConfig.path == 'setup') {
            this.router.navigateByUrl('/setup');
          } else {
                const userType = this.commonService.roleId;
                const navigationExtras: NavigationExtras = {
                  state: {
                    userType,
                  },
                };
                this.router.navigateByUrl('wallet', navigationExtras);
          }
        }
        return verified;
      });
    }
    return verified;
  }
}
