import { MenuController } from '@ionic/angular';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { TripService } from '@app/trips/services/trip.service';
import { AuthSessionService } from '@app/core/services/auth-session.service';
import { User } from '@app/core/models/core.model';
import { GetVendorTripsUpcomingActivityResponse, LegListing, OpenClientSideMenuResponse, TripDetails } from '../models/right-menu.model';
import { SideMenuService } from '../services/side-menu.service';
import { Subscription } from 'rxjs';
import { ActivityService } from '@app/trips/services/activity.service';
import { UtilService } from '@app/core/services/util.service';

@Component({
  selector: 'app-trip-information',
  templateUrl: './trip-information.component.html',
  styleUrls: ['./trip-information.component.scss'],
})
export class TripInformationComponent implements OnInit {
  public quickInfoSide: string = "1";
  @Input() public tripDetails: any;
  private subscribeData: Subscription
  public tripInfo = new TripDetails();
  public isMobile: boolean;
  constructor(private menuController: MenuController,
    private auth: AuthSessionService,
    private tripService: TripService,
    public sideMenu: SideMenuService, private activityService:ActivityService,public utilService:UtilService) {
    this.auth.$userSession.subscribe((res: User) => {
      if (!res) {
        this.onClick();
      }
    });
    this.isMobile = this.utilService.detectMobileOrNative()
  }
  public ngOnInit() {
    this.reloadActivity();
  }
  public getTripData(SRID) {
    switch (this.auth.roleID) {
      case '2':
        this.sideMenu.getClientSideMenuTrips(SRID).then((response: OpenClientSideMenuResponse) => { });
        break;
      case '3':
        this.sideMenu.getVendorSideMenuTrips(SRID).then((result: GetVendorTripsUpcomingActivityResponse) => { });
        break;
      default:
        return false;
    }
  }
  public reloadActivity(){
    this.subscribeData = this.activityService.reloadActivity.subscribe(() => {
      this.getTripData(this.tripDetails.SRID)
    });
  }
  public onClick() {
    this.sideMenu.closedChat.next(false)
    this.menuController.enable(false, 'end');
  }
  public segmentChanged(ev: any) {
    this.quickInfoSide = ev.target.value;
    this.getTripData(this.tripDetails.SRID)
  }
  public ngOnDestroy() {
    this.subscribeData.unsubscribe();
  }

}
