import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'surchargeName'
})
export class SurchargeNamePipe implements PipeTransform {

  public transform(value:string ): string {
    return value?.replace(" Surcharge", "");
  }

}
