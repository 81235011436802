import { Injectable, NgZone } from '@angular/core';
import {
  AlertController,
  Platform,
  LoadingController,
  ActionSheetController,
  ToastController,
  ModalController,
} from '@ionic/angular';
import firebase from 'firebase';
import * as moment from 'moment';
import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { delay } from 'rxjs/operators';
import { AuthSessionService } from '@app/core/services/auth-session.service';
import { MessageConfig } from '@app/shared/constants/constant';
import {
  FirebaseCountryObject,
  UserProfileData,
} from '@app/profile-setup/profile.model';
import { ScheduleAirportComponent } from '@app/trips/schedule-creation/schedule-airport/schedule-airport.component';
import { RecivedParams } from '@app/trips/schedule-creation/model/create-schedule';
import { userRoles } from '@app/setup/users/users-model';
import { Subject } from "rxjs/Subject";
import { Capacitor } from '@capacitor/core';
import { shortNoticeLiterals } from '../enums/common-enums';
declare var cordova: any;
@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public offlineEvent: Observable<Event>;
  public onlineEvent: Observable<Event>;
  public subscriptions: Subscription[] = [];
  public isOnline: boolean = false;
  public visible: boolean = false;
  public QuoteIdtoTripDetails = new BehaviorSubject<boolean>(false);
  public itmList: any;
  public revitemList: any;
  private flightdate: any;
  private firebaseStorage: any;
  public NowTime: string = '';
  public defaultCountryIsoSelected = 'in';
  public modalCssClass: any;
  public userFormat = {
    TimeFormat: '',
  };
  public messageConfig = MessageConfig;
  public totalData: any;
  public emailFlag = new BehaviorSubject<any>({});
  public valueBackParent = new BehaviorSubject<boolean>(false);
  public roleId: any;
  public UserID: any;
  public FIREBASE_UID: any;
  public pinVerified: boolean = false;
  public uid: string = '';
  public deviceToken: string = '';
  public registrationType: string = '';
  private spinner: any;
  public userSessionDetails;
  public pinAccessCheck: boolean = false;
  public loading: any;
  public loaderPresent: boolean = false;
  public userCurrency: any;
  public userName: string = '';
  public password: string = '';
  public fuelUnit = 'LTR';
  public portsSubscription: Subscription;
  public contactNumber: any;
  public displayPresent: boolean = false;
  public display: any;
  public countryCode: any;
  public platformType: string = '';
  public platformTypeCheck: string = '';
  public userProfileData = new UserProfileData();
  public todayDate: any;
  public prevDate: any;
  public prevUrl: string = '';
  public dateUpdate = false;
  public urlCheckForLegEstimate: any;
  public currentURL: string = '';
  public selectedPage: any;
  public appPages: any;
  public fuelUnitFromProfile: string ='';
  public checkEstimateBtnRevise: boolean = false;
  public charterAllNotificationSubject: any = new BehaviorSubject<any>([]);
  public pageTitleRevise;
  private pageTypeNavigation: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public storeSelectedTripServices = new Subject<any>();
  public isSpinnerLoading: boolean = false;
  public dynamicMessage:any = 'Loading'
  constructor(
    public platform: Platform,
    private zone: NgZone,
    private alertCtrl: AlertController,
    public actionSheetCtrl: ActionSheetController,
    public authSessionService: AuthSessionService,
    public loadingController: LoadingController,
    public toast: ToastController,
    private modalController: ModalController
  ) {
    this.getUTCTime();
    this.platform.ready().then(() => { });
    this.platformTypeCheck = Capacitor.getPlatform();
  }
  public getUserSessionData() {
    this.authSessionService.userSessionSubject.subscribe((data) => {
      if (data) {
        if (data.clientDetails) {
          this.userProfileData.Image = data.clientDetails.logo;
          this.userProfileData.Name = data.clientDetails.ClientName;
          this.fuelUnitFromProfile = data.clientDetails.fuelUnit;
        } else if (data.vendorDetails) {
          this.userProfileData.Image = data.vendorDetails.logo;
          this.userProfileData.Name = data.vendorDetails.VendorName;
          this.fuelUnitFromProfile = data.vendorDetails.unitType;
        }
        this.uid = data.firebase_uid;
        this.FIREBASE_UID = data.firebase_uid;
        this.roleId = data.RoleID;
        this.UserID = data.UserID;
      }
    });
  }
  public async showSpinnerLoader(message?: string) {
    delete this.spinner;
    this.spinner = await this.loadingController.create({
      spinner: 'lines',
      cssClass: message ? 'backgroud-box' : 'custom-loading',
      message: message ? message : '',
      backdropDismiss: false,
    });
    await this.spinner.present();
  }

  public async showSpinnerTripAndRevise(messages?: string[], from?) {
    delete this.spinner;
    this.spinner = await this.loadingController.create({
      spinner: 'lines',
      cssClass: 'backgroud-box',
      message: messages[0],
      backdropDismiss: false,
    });
    let i = 1;
    const interval = setInterval(() => {
      if (i < messages.length) {
        this.spinner.message = messages[i];
        i++;
      } else {
        clearInterval(interval);
      }
    }, 3000);
    if(from == 'fromCharter') {
      await this.spinner.present();
    } else {
      await this.spinner.present().then((res)=>{
        setTimeout(() => {
         this.dismissLoader()
        }, 4200);
       })
    }
  }
  public async dismissLoader() {
    this.checkAndCloseLoader();
    // sometimes there's delay in finding the loader.
    // so check if the loader is closed after one second. if not closed proceed to close again
    setTimeout(() => this.checkAndCloseLoader(), 1000);
  }
  public async checkAndCloseLoader() {
    // Use getTop function to find the loader and dismiss only if loader is present.
    const loader = await this.loadingController.getTop();
    // if loader present then dismiss
    if (loader !== undefined) {
      await this.loadingController.dismiss();
    }
  }
  public async dissmissSpinnerLoader() {
    if (this.spinner) await this.spinner.dismiss();
  }

  // for sppiner duration loader
  public async showSpinnerDuration(customessage) {
    delete this.spinner;
    this.spinner = await this.loadingController.create({
      spinner: 'lines',
      cssClass: customessage ? 'backgroud-box' : 'custom-loading',
      message: customessage ? customessage : '',
      backdropDismiss: false,
      duration: 2000,
    });
    await this.spinner.present();
  }

  // alert for API (oops);
  public async showErrorAlert() {
    return new Promise(async (resolve) => {
      const alert = await this.alertCtrl.create({
        header: MessageConfig.ApiErrorHeader,
        message: MessageConfig.ApiErrorMessage,
        buttons: [
          {
            text: MessageConfig.CancelButton,
            role: MessageConfig.cancel,
            handler: () => {
              resolve(false);
            },
          },
          {
            text: MessageConfig.TryAgain,
            role: MessageConfig.ok,
            handler: () => {
              resolve(true);
            },
          },
        ],
        backdropDismiss: false,
      });
      await alert.present();
    });
  }
  public async showConfirmAlert(
    message,
    buttonName?: string,
    title?: string,
    cancelButtonName?: string
  ) {
    return new Promise(async (resolve) => {
      const alert = await this.alertCtrl.create({
        header: title ? title : MessageConfig.Confirmation,
        message,
        buttons: [
          {
            text: cancelButtonName
              ? cancelButtonName
              : MessageConfig.CancelButton,
            role: 'cancel',
            handler: () => {
              resolve(false);
            },
          },
          {
            text: buttonName ? buttonName : MessageConfig.ok,
            role: buttonName ? buttonName : 'ok',
            handler: () => {
              resolve(true);
            },
          },
        ],
        backdropDismiss: false,
      });
      await alert.present();
    });
  }
  // custom message Alert
  public async showMeassgeAlert(message: any) {
    return new Promise(async (resolve) => {
      const alert = await this.alertCtrl.create({
        header: MessageConfig.Alert,
        message,
        buttons: [
          {
            text: MessageConfig.ok,
            role: 'Ok',
            handler: () => {
              resolve(true)
             },
          },
        ],
        backdropDismiss: false,
      });
      await alert.present();
     });

  }
  // custom message File Alert
  public async showFileAlert(fileMessage) {
    const alert = await this.alertCtrl.create({
      header: MessageConfig.Alert,
      subHeader: 'Please select a valid ' + fileMessage,
      buttons: [
        {
          text: MessageConfig.ok,
          role: 'Ok',
          handler: () => { },
        },
      ],
      backdropDismiss: false,
    });
    await alert.present();
  }
  //  custom message Alert with resolve(true);
  public callingAlert(message, titleText?: any) {
    return new Promise(async (resolve) => {
      const alert = await this.alertCtrl.create({
        header: titleText ? titleText : MessageConfig.Alert,
        message,
        buttons: [
          {
            text: 'Ok',
            role: 'ok',
            handler: () => {
              resolve(true);
            },
          },
        ],
      });
      await alert.present();
    });
  }

  // without time duration toast message
  public async showToastMessage(
    str: string,
    position?: 'bottom',
    eventEnabled = false
  ) {
    this.toast
      .create({
        message: str,
        position: 'bottom',
        cssClass: 'toast-custom-wrapper',
        // duration: 5000, // Duration in milliseconds
        buttons: [
          {
            side: 'end',
            text: 'OK',
            role: 'cancel',
            handler: () => { },
          },
        ],
      })
      .then((toast) => {
        toast.present();
      });
  }

  // toast controller for time duration.
  public async showToastCtrl(messageType:string, position?: 'bottom',
  eventEnabled = false) {
    const toast = await this.toast.create({
      message: messageType,
      position: 'bottom',
      cssClass: 'toast-custom-wrapper',
      buttons: [
        {
          side: 'end',
          text: 'OK',
          role: 'cancel',
          handler: () => { },
        },
      ],
      duration: 5000,
    });
    toast.present();
  }

  public getUTCTime() {
    firebase
      .database()
      .ref('/.info/serverTimeOffset')
      .on('value', (dateValue) => {
        this.NowTime = moment.utc(dateValue.val() + Date.now()).format();
      });
  }
  public getPassedDateStringInUTC(dateString: string, format: string) {
    return moment(dateString).utc().format(format);
  }
  public VerifyAccessPin(key) {
    this.getUserSessionData();
    return new Promise((resolve) => {
      const Access = firebase.database().ref('/Access/' + this.uid + '/' + key);
      Access.once('value', (snapshot) => {
        resolve(snapshot.val());
      });
    });
  }

  public DeleteVerifiedPinAccessNode(key) {
    this.getUserSessionData();
    return new Promise((resolve) => {
      const deleteNode = firebase
        .database()
        .ref()
        .child('Access')
        .child(this.uid)
        .child(key);
      deleteNode
        .remove()
        .then(() => {
          resolve({
            delete: true,
          });
        })
        .catch((error) => { });
    });
  }

  public async showToastForArrayMessage(list: any, position?: 'top') {
    let str = '';
    list.forEach((element) => {
      str = str + element + '\n';
    });
    const toast = this.toast.create({
      message: str.substr(0, 100),
      position,
      buttons: [
        {
          side: 'end',
          icon: 'close',
          handler: () => { },
        },
        {
          side: 'end',
          text: 'Ok',
          role: 'ok',
          handler: () => { },
        },
      ],
    });
    (await toast).present();
  }

  public calcCrow(lat1, lon1, lat2, lon2) {
    const R = 6371; // km
    const dLat = this.toRad(lat2 - lat1);
    const dLon = this.toRad(lon2 - lon1);
    lat1 = this.toRad(lat1);
    lat2 = this.toRad(lat2);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d / 1.852;
  }
  public toRad(Value) {
    return (Value * Math.PI) / 180;
  }

  public async showNonSelectableFPAlert(errorsandwarnings) {
    return await new Promise(async (resolve, reject) => {
      const alert = await this.alertCtrl.create({
        header: 'Error',
        message: errorsandwarnings,
        buttons: [
          {
            text: 'ok',
            role: 'cancel',
            handler: () => {
              resolve(false);
            },
          },
        ],
      });
      await alert.present();
    });
  }
  public async selectStation(
    type: string,
    selectedStationName: string,
    runwayLength: string,
    pageType: string
  ) {
    const modal = await this.modalController.create({
      component: ScheduleAirportComponent,
      componentProps: {
        SendData: new RecivedParams(type, selectedStationName),
        pageType,
        minimumRunwayLength: runwayLength,
        stationToFilter: selectedStationName,
      },
    });
    modal.present();
    const { data } = await modal.onWillDismiss();
    return data;
  }
  public async moveBackAlert(message: string) {
    return await new Promise(async (resolve, reject) => {
      const alert = await this.alertCtrl.create({
        header: MessageConfig.Confirmation,
        message,
        backdropDismiss: false,
        buttons: [
          {
            text: 'Back',
            role: 'cancel',
            handler: () => {
              resolve(true);
            },
          },
        ],
      });
      alert.present();
    })

  }
  public async showShortMsgAlert(routeData) {
    if (!routeData) {
      return [];
    }  else {
      const allConditionsMet =   Object.values(routeData).every((vendorData: any) =>
      {
      // Check if 'overfly' array is empty
      const isOverflyEmpty = Array.isArray(vendorData.overfly) && vendorData.overfly.length === 0;
        const getStation = vendorData?.arr?.Landing?.station;
       const getDepStation = vendorData?.dept?.DepartureshortSlot?.station;
      // Check for 'dept' and 'arr' objects
      const deptRevisionApplicable = vendorData.dept?.DepartureshortSlot?.RevisionApplicable === 'no';
      const arrRevisionApplicable = vendorData.arr?.Landing?.RevisionApplicable === 'no';
      const arrSlotRevisionApplicable = vendorData.arr?.ArrivalSlot?.RevisionApplicable === 'no';
      const arrivalSlotCheck = vendorData.arr?.ArrivalSlot;
      const arrivalLandingCheck = vendorData.arr?.Landing;
      const arrivalWatchHoursCheck = vendorData.arr?.arrwatchrangesarray;
      const arrivalStationCheck = vendorData?.arr?.[getStation];
      const deptSlotCheck =  vendorData.dept?.DepartureshortSlot;
      const deptStationCheck = vendorData?.dept?.[getDepStation];
      const deptWatchHoursCheck = vendorData?.dept?.depwatchrangesarray;
      // Return true only if all conditions are met
        return (isOverflyEmpty && arrRevisionApplicable && deptRevisionApplicable && arrSlotRevisionApplicable)
    });
    if(allConditionsMet) {
      return []
    }
    }
    let deptList = "<ul class='list-styles'>";
    let depWatch = "<ul class='list-styles'>";
    let ovfRevList =  "<ul class='list-styles'>";
    let ovfList =  "<ul class='list-styles-ovf'>";
    let arrList = "<ul class='list-styles'>";
    let landing = "<ul class='list-styles'>";
    let landingRevList = "<ul class='list-styles'>";
    let arrWatch = "<ul class='list-styles'>";
    let arrFbo = "<ul class='list-styles'>";
    let arrCiq = "<ul class='list-styles'>";
    let depFbo = "<ul class='list-styles'>";
    let depCiq = "<ul class='list-styles'>";
    // const revision = 'RevisionApplicable';
    for (const key of Object.keys(routeData)) {
      for (const objkey of Object.keys(routeData[key])) {
        if(objkey == 'dept') {
          for (const depkey of Object.keys(routeData[key][objkey])) {
            if(depkey == 'DepartureshortSlot'){
              if(routeData[key][objkey][depkey].RevisionApplicable == 'yes' || routeData[key][objkey][depkey].RevisionApplicable == 'Yes' ||
                routeData[key][objkey][depkey][shortNoticeLiterals.RevisionApplicable] == 'yes' || routeData[key][objkey][depkey][shortNoticeLiterals.RevisionApplicable] == 'Yes' ||
                routeData[key][objkey][depkey][shortNoticeLiterals.Revision_Applicable] == 'yes' || routeData[key][objkey][depkey][shortNoticeLiterals.Revision_Applicable] == 'Yes'
              ){
                deptList +=
              "<li class='element-styles'>Dep Slot - " +
              routeData[key][objkey][depkey].station +
              ' - Revision Applicable' +
              "<ul class='ul-style-pading'>" +
              "<li class='ul-li-style'>" +
              'Permit Validity Prior - ' +
              routeData[key][objkey][depkey].PermitValidityPrior +
              "</li>" +
              "<li class='ul-li-style'>" +
              'Permit Validity Post - ' +
              routeData[key][objkey][depkey].PermitValidityPost +
              "</li>" +
              "</ul>" +
              '</li>';
              } else if(routeData[key][objkey][depkey][shortNoticeLiterals.Short_Notice] == 'yes' || routeData[key][objkey][depkey][shortNoticeLiterals.Short_Notice] == 'Yes' ||
                routeData[key][objkey][depkey][shortNoticeLiterals.ShortNotice] == 'yes' || routeData[key][objkey][depkey][shortNoticeLiterals.ShortNotice] == 'Yes' ||
                routeData[key][objkey][depkey].ShortNotice == 'yes' || routeData[key][objkey][depkey].ShortNotice == 'Yes'
              ) {
                deptList +=
                "<li class='element-styles'>Dep Slot - " +
                routeData[key][objkey][depkey].Station +
                ' - ' +
                ' Short Notice - ' +
                'Lead Time - ' +
                routeData[key][objkey][depkey]['Lead Time'] +
                '</li>';
              } else {
              }
            } else  if(depkey == 'depwatchrangesarray') {
                const excludedDepStationArray = [];
          for (const depwatchrangesarray in routeData[key][objkey][depkey]) {
            if (depwatchrangesarray !== 'Station') {
              excludedDepStationArray.push(routeData[key][objkey][depkey][depwatchrangesarray]);
            }
          }
            const tagDepElements = excludedDepStationArray.map(range => {
              const formattedRange = range.replace(/-/g, ' - ');
              return "<li class='ul-li-style'>" + formattedRange + "</li>";
            });
            const tagsDep = tagDepElements.join('');
              depWatch +=
              "<li class='element-styles'> Watch Hours - " +
              routeData[key][objkey][depkey].Station
              + "<ul class='ul-style'>" +
              tagsDep +
              "</ul>" +
              '</li>';
            } else {
              if(routeData[key][objkey][depkey]?.['Start Time']) {
                depCiq += "<li class='element-styles'> CIQ Hours - " +
                depkey +
              "<ul class='ul-style-pading'>" +
                "<li class='ul-li-style'>" +
                routeData[key][objkey][depkey]['Start Time'] + ' - ' +
                routeData[key][objkey][depkey]['End Time'] +
                "</li>" +
                "</ul>" +
                '</li>';
              }
              if(routeData[key][objkey][depkey]?.FBOStart) {
                depFbo += "<li class='element-styles'> FBO Hours - " +
              depkey +
              "<ul class='ul-style-pading'>" +
                "<li class='ul-li-style'>" +
                routeData[key][objkey][depkey].FBOStart + ' - ' +
                routeData[key][objkey][depkey].FBOEnd +
                "</li>" +
                "</ul>" +
              '</li>';
              }
            }
          }
          depFbo += '</ul>';
          depCiq +='</ul>';
          deptList += '</ul>';
          depWatch += '</ul>';
        } else if(objkey == 'overfly') {
          for (const overflykey of Object.keys(routeData[key][objkey])) {
            if(routeData[key][objkey][overflykey].RevisionApplicable == 'yes' || routeData[key][objkey][overflykey].RevisionApplicable == 'Yes' ||
              routeData[key][objkey][overflykey][shortNoticeLiterals.RevisionApplicable] == 'yes' || routeData[key][objkey][overflykey][shortNoticeLiterals.RevisionApplicable] == 'Yes' ||
              routeData[key][objkey][overflykey][shortNoticeLiterals.Revision_Applicable] == 'yes' || routeData[key][objkey][overflykey][shortNoticeLiterals.RevisionApplicable] == 'Yes'
            ) {
              ovfRevList +=
            "<li class='element-styles'> Ovf Permits - " +
                routeData[key][objkey][overflykey]['Country Name'] +
                // ' <br>' +
              ' - Revision Applicable ' +
                "<ul class='ul-style-pading'>" +
              "<li class='ul-li-style'>" +
              'Permit Validity Prior - ' +
              routeData[key][objkey][overflykey].PermitValidityPrior+
              "</li>"+
              "<li class='ul-li-style'>" +
              'Permit Validity Post - ' +
              routeData[key][objkey][overflykey].PermitValidityPost +
              "</li>" +
              "</ul>" +
                '</li>';
            } else if(routeData[key][objkey][overflykey][shortNoticeLiterals.Short_Notice] == 'yes' || routeData[key][objkey][overflykey][shortNoticeLiterals.Short_Notice] == 'Yes' ||
              routeData[key][objkey][overflykey][shortNoticeLiterals.ShortNotice] == 'yes' || routeData[key][objkey][overflykey][shortNoticeLiterals.ShortNotice] == 'Yes' ||
              routeData[key][objkey][overflykey].ShortNotice == 'yes' || routeData[key][objkey][overflykey].ShortNotice == 'Yes'
            ) {
              ovfList +=
              "<li class='element-styles'> Ovf Permits - " +
                  routeData[key][objkey][overflykey]['Country Name'] +
                  ' - Short Notice ' +
                  ' - Lead Time - ' +
                  routeData[key][objkey][overflykey]['Lead Time'] +
                  '</li>';
            } else {

            }
          }
          ovfList += '</ul>';
          ovfRevList += '</ul>';
        } else {
          for (const arrkey of Object.keys(routeData[key][objkey])) {
            if(arrkey == 'Arrivalslot') {
              if(routeData[key][objkey][arrkey].RevisionApplicable === 'yes' || routeData[key][objkey][arrkey].RevisionApplicable === 'Yes' ||
                routeData[key][objkey][arrkey][shortNoticeLiterals.RevisionApplicable] === 'yes' || routeData[key][objkey][arrkey][shortNoticeLiterals.Revision_Applicable] === 'Yes' ||
                routeData[key][objkey][arrkey][shortNoticeLiterals.RevisionApplicable] === 'Yes' || routeData[key][objkey][arrkey][shortNoticeLiterals.Revision_Applicable] === 'yes'
              ) {
                arrList +=
                "<li class='element-styles'>Arrival Slot - " +
                      routeData[key][objkey][arrkey].station +
                      // ' <br>' +
                      ' - Revision Applicable ' +
                     "<ul class='ul-style-pading'>" +
              "<li class='ul-li-style'>" +
              'Permit Validity Prior - ' +
              routeData[key][objkey][arrkey].PermitValidityPrior+
              "</li>"+
              "<li class='ul-li-style'>" +
              'Permit Validity Post - ' +
              routeData[key][objkey][arrkey].PermitValidityPost +
              "</li>" +
              "</ul>" +
                      '</li>';
              } else if(routeData[key][objkey][arrkey][shortNoticeLiterals.ShortNotice] == 'yes'|| routeData[key][objkey][arrkey][shortNoticeLiterals.Short_Notice] == 'yes' ||
                 routeData[key][objkey][arrkey][shortNoticeLiterals.ShortNotice] == 'Yes' || routeData[key][objkey][arrkey][shortNoticeLiterals.Short_Notice] == 'Yes' ||
                 routeData[key][objkey][arrkey].ShortNotice == 'Yes' || routeData[key][objkey][arrkey].ShortNotice == 'yes') {
                arrList +=
                "<li class='element-styles'>Arrival Slot - " +
                      routeData[key][objkey][arrkey].Station +
                      ' - ' +
                      ' Short Notice - ' +
                      ' Lead Time - ' +
                      routeData[key][objkey][arrkey].LeadTime +
                      '</li>';
              } else {

              }
            }  else if(arrkey == 'arrwatchrangesarray') {
              const excludedArrStationArray = [];
                  for (const arrwatchArray in routeData[key][objkey][arrkey]) {
                    if (arrwatchArray !== 'Station') {
                      excludedArrStationArray.push(routeData[key][objkey][arrkey][arrwatchArray]);
                    }
                  }
                    const tagArrElements = excludedArrStationArray.map(arrRange => {
                      const formattedArrRange = arrRange.replace(/-/g, ' - ');
                      return "<li class='ul-li-style'>" + formattedArrRange + "</li>";
                    });
                    const tagsArr = tagArrElements.join(' ');
                    arrWatch +=
                      "<li class='element-styles'> Watch Hours - " +
                      routeData[key][objkey][arrkey].Station +
                      "<ul class='ul-style'>" +
                      tagsArr +
                      "</ul>"+
                      '</li>';
            } else if(arrkey == 'Landing') {
              if(routeData[key][objkey][arrkey].RevisionApplicable == 'yes' || routeData[key][objkey][arrkey].RevisionApplicable == 'Yes' ||
                routeData[key][objkey][arrkey][shortNoticeLiterals.RevisionApplicable] == 'yes' || routeData[key][objkey][arrkey][shortNoticeLiterals.RevisionApplicable] == 'Yes' ||
                routeData[key][objkey][arrkey][shortNoticeLiterals.Revision_Applicable] == 'yes' || routeData[key][objkey][arrkey][shortNoticeLiterals.Revision_Applicable] == 'Yes'
              ) {
                landingRevList +=
                "<li class='element-styles'>Landing Permit - " +
                    routeData[key][objkey][arrkey].station +
                  //  ' <br>' +
                   ' - Revision Applicable ' +
                    "<ul class='ul-style-pading'>" +
                "<li class='ul-li-style'>" +
                'Permit Validity Prior - ' +
                routeData[key][objkey][arrkey].PermitValidityPrior+
                "</li>"+
                "<li class='ul-li-style'>" +
                'Permit Validity Post - ' +
                routeData[key][objkey][arrkey].PermitValidityPost +
                "</li>" +
                "</ul>" +
                    '</li>';
              } else {

              }
            } else if(arrkey == 'LandingPermit') {
              if(routeData[key][objkey][arrkey][shortNoticeLiterals.Short_Notice] == 'yes' || routeData[key][objkey][arrkey][shortNoticeLiterals.Short_Notice] == 'Yes' ||
                routeData[key][objkey][arrkey][shortNoticeLiterals.ShortNotice] == 'yes' || routeData[key][objkey][arrkey][shortNoticeLiterals.ShortNotice] == 'Yes' ||
                routeData[key][objkey][arrkey].ShortNotice == 'yes' || routeData[key][objkey][arrkey].ShortNotice == 'Yes'
              ) {
                landing +=
                "<li class='element-styles'>Landing Permit - " +
                    routeData[key][objkey][arrkey].Station +
                    ' - ' +
                    ' Short Notice - ' +
                    ' Lead Time - ' +
                    routeData[key][objkey][arrkey]['Lead Time'] +
                    '</li>';
              } else {

              }
            } else {
              if(routeData[key][objkey][arrkey]?.['Start Time']) {
                arrCiq +=
                "<li class='element-styles'> CIQ Hours -" + arrkey +
                "<ul class='ul-style-pading'>" +
                "<li class='ul-li-style'>" +
                routeData[key][objkey][arrkey]['Start Time'] + ' - ' +
                routeData[key][objkey][arrkey]['End Time'] +
                "</li>" +
                "</ul>" +
                '</li>'
              }
              if(routeData[key][objkey][arrkey]?.FBOStart) {
                arrFbo += "<li class='element-styles'> FBO Hours - " +
              arrkey +
              "<ul class='ul-style-pading'>" +
                "<li class='ul-li-style'>" +
                routeData[key][objkey][arrkey].FBOStart + ' - ' +
                routeData[key][objkey][arrkey].FBOEnd +
                "</li>" +
                "</ul>" +
              '</li>';
              }
            }
          }
          arrList += "</ul>";
          arrWatch +="</ul>";
          arrFbo +='</ul>';
          landing += "</ul>";
          landingRevList += "</ul>";
          arrCiq += "</ul>";
        }
        //
        }
      }
      const alert = await this.alertCtrl.create({
        header: MessageConfig.Alert,
        message:
          '<div>' +
          deptList +
          '</div>' +
          '<div>' +
          depWatch +
          '</div>'+
          '<div>'+
          depFbo +
          '</div>'+
          '<div>' +
          depCiq +
          '</div>'+
          '<div>' +
          ovfRevList +
          '</div>' +
          '<div>' +
          ovfList +
          '</div>'+
          '<div>'+
          arrList +
          '</div>'+
          '<div>'+
          arrWatch +
          '</div>'+
          '<div>'+
          arrFbo +
          '</div>'+
          '<div>'+
          arrCiq +
          '</div>'+
          '<div>'+
          landingRevList +
          '</div>' +
          '<div>'+
          landing +
          '</div>',
        cssClass: 'shortCss',
        buttons: [
          {
            text: MessageConfig.OkBtn,
            role: 'Ok',
          },
        ],
      });
      alert.present().then(()=>{
        this.dismissLoader();
      });
    // }
    }
  public getRoundCost(totalPrice: number) {
    if (totalPrice) {
      return Math.round(Number(totalPrice));
    } else {
      return Math.round(0);
    }
  }
  public getCurrentDate(dateString, format) {
    return moment(dateString).format(format);
  }
  public contactNumberFormate(object: any) {
    if (object.nationalNumber.startsWith(0)) {
      this.contactNumber = object.nationalNumber.slice(1).replace(/ +/g, '');
    } else {
      this.contactNumber = object.nationalNumber;
    }
    const countryCode = object.dialCode;
    return {
      contact: `${this.contactNumber}`,
      dialCode: countryCode,
    };
  }
  public getContactNumberFormate(dialCode: any, nationalNumber: any) {
    if (dialCode) {
      if (dialCode.startsWith('+')) {
      } else {
        dialCode = '+' + dialCode;
      }
      const filterCountryObj = this.countryCode?.filter(
        (data: FirebaseCountryObject) => {
          return data.callingCodes == dialCode;
        }
      );
      if (filterCountryObj && filterCountryObj[0]) {
        return {
          isoCode: `${filterCountryObj[0].countryCode.toLowerCase()}`,
          nationalNumber: `${nationalNumber}`,
        };
      }
    }
  }
  public tagEmailVerify(str: string): boolean {
    const regExp =
      /^(?!\d+)\w+([-+.']\w+)*@[a-zA-Z0-9][a-zA-Z0-9.-]*\.[a-z0-9_-]+$/;
    if (regExp.test(str)) {
      return true;
    } else {
      return false;
    }
  }
  public async showCurrencyCovertionAlert(response) {
    if (response == 'Please enter correct prices') {
      const alert = await this.alertCtrl.create({
        header: this.messageConfig.Alert,
        message: this.messageConfig.CurrencyConversionAlert,
        buttons: ['Ok'],
      });
      alert.present();
      return false;
    } else {
      return true;
    }
  }

  public async showCommonModal(
    componentName: any,
    paramObject: {},
    classname?: string
  ) {
    const modal = await this.modalController.create({
      component: componentName,
      cssClass: classname,
      componentProps: paramObject,
      swipeToClose: true,
    });
    modal.present();
  }

  public validNumber(input) {
    const inputregExp = /^([0-9]+([.][0-9]+)?)$/;
    return inputregExp.test(input);
  }
  public checkPlatformType() {
    this.platformType = Capacitor.getPlatform() === 'ios'
      ? 'ios'
      : Capacitor.getPlatform() ==='android'
      ? 'android'
      : 'web';
    return Promise.resolve(this.platformType);
  }
  // For datePickerFormat to change date.
  public datePickerFormat(fromDate, toDate, time?) {
    return fromDate + ' - ' + toDate;
  }
  public timePickerFormat(fromTime, toTime) {
    return fromTime + ' - ' + toTime;
  }
  // validate only number
  public validateNumber(data) {
    if (/^[0-9]+$/.test(data.quantity)) {
      return {
        isValid: true,
        message: '',
      };
    } else {
      return {
        isValid: false,
        message: 'Enter valid fuel quantity',
      };
    }
  }
  public capitalize(data) {
    return data && data[0].toUpperCase() + data.slice(1);
  }

  // the purpose of currentDate() function is to give FROM Date as per date selected in exisiting Rate Card
  // and for new Rate card FROM date will be current Date
  public currentDate(validFrom, currentDate) {
    if (validFrom < currentDate.slice(0, 10)) {
      this.todayDate = validFrom;
      this.dateUpdate = true;
    } else {
      this.prevDate = currentDate;
      this.dateUpdate = false;
    }
  }
  public roleTrim(opt) {
    switch (opt) {
      case 'ClientAdmin':
        return userRoles.Admin;
        break;
      case 'ClientaccountManager':
        return userRoles.AccountsManager;
        break;
      case 'ClientOpsManager':
        return userRoles.OperationsManager;
        break;
      case 'ClientPilot':
        return userRoles.Pilot;
        break;
      case 'ClientCabinCrew':
        return userRoles.CabinCrew;
        break;
      case 'ClientOpsAgent':
        return userRoles.OpsAgent;
        break;
      default:
        break;
    }
  }

  public highlightUrl(data: string) {
    this.appPages.filter((element, key) => {
      if (element.title.toLowerCase() === data.toLowerCase()) {
        this.selectedPage = key;
      }
    });
  }
  public async createAlert(
    header,
    backdropDismiss,
    message,
    buttonOptions1,
    buttonOptions2?
  ): Promise<HTMLIonAlertElement> {
    const alert = await this.alertCtrl.create({
      header,
      backdropDismiss,
      message,
      buttons: !buttonOptions2
        ? [buttonOptions1]
        : [buttonOptions1, buttonOptions2],
    });
    return alert;
  }
  public sortByDateAndTripId(listData, filterDetails, pageType){
    switch(pageType) {
      case 'Estimates':
      case 'trip':
      case 'chartertrips':
      case 'charterEstimates':
        listData.sort((a, b) => {
          if (filterDetails.filteredBy == 'tripId') {
            if (filterDetails.filterByTripId == 'tripidDescending') {
              return a.SRID < b.SRID ? -1 : 1;
            } else {
              return a.SRID > b.SRID ? -1 : 1;
            }
          } else {
            if (filterDetails.filterByDate == 'tripDateDescending') {
              return new Date(a.depart) < new Date(b.depart) ? -1 : 1;
            } else {
              return new Date(a.depart) > new Date(b.depart) ? -1 : 1;
            }
          }
        });
        return listData;
        break;
        case 'Invoices':
          listData.sort((a, b) => {
            if (filterDetails.filteredBy == 'tripId') {
              if (filterDetails.filterByTripId == 'tripidDescending') {
                return a.SRID < b.SRID ? -1 : 1;
              } else {
                return a.SRID > b.SRID ? -1 : 1;
              }
            } else {
              if (filterDetails.filterByDate == 'invDateDescending') {
                return new Date(a.depart) < new Date(b.depart) ? -1 : 1;
              } else {
                return new Date(a.depart) > new Date(b.depart) ? -1 : 1;
              }
            }
          });
          return listData;
        break;
        case 'charterSelection':
          listData.sort((a, b) => {
            if (filterDetails.filteredByID == 'Cost') {
                  if (filterDetails.filterByAircraftPrice == 'Descending') {
                    return (a.FixedCharterPrice < b.FixedCharterPrice) ? -1 : 1;
                  } else {
                    return (a.FixedCharterPrice > b.FixedCharterPrice) ? -1 : 1;
                  }
                } else {
                  if (filterDetails.filteredByID == 'Seats') {
                    if (filterDetails.filterByAircraftSeats == 'Descending') {
                      return (a.NoOfPaxCapacity - b.NoOfPaxCapacity)
                    }
                    else {
                      return (b.NoOfPaxCapacity - a.NoOfPaxCapacity);
                    }
                  }
                }
          });
          return listData;
          break;
        default:
        break;
      }
    }
  public setPageType(pageType: string) {
      this.pageTypeNavigation.next(pageType);
    }
  public removeDuplicates(listData) {
      const uniqueList = listData.filter((item, index, self) =>
          index === self.findIndex((t) => (
              t.SRID === item.SRID && t.depart === item.depart
          ))
      );
      return uniqueList;
  }
  public getPageTypeObservable() {
      return this.pageTypeNavigation.asObservable();
    }
    public passSelectedTripServices(
      serviceNameByCategory,
      filterActionItemBy,
      randomValueToTriggerPipe,
    ) {
      const sendServiceStation = [];
      sendServiceStation.push(
        serviceNameByCategory,
        filterActionItemBy,
        randomValueToTriggerPipe,
      );
      this.storeSelectedTripServices.next(sendServiceStation);
    }
    public tranformMailsToLowerCase(multiEmails) {
      if (Array.isArray(multiEmails)) {
        return multiEmails.map(email => email.toLowerCase());
    } else if (typeof multiEmails === 'string') {
        return multiEmails.toLowerCase();
    } else {
        // Handle other cases if needed
        return multiEmails;
    }
      // return  multiEmails.map(emails => emails.toLowerCase());
    }
}
