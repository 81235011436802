import { Pipe, PipeTransform } from '@angular/core';
import { TripServices } from '@app/trips/trips-tab/model/model.index';
import { LEG_STATUSES } from '@app/shared/enums/common-enums';
import { PageNames } from '@app/trips/trips-tab/constants/constant-message';

@Pipe({
  name: 'activityOrder'
})
export class ActivityOrderPipe implements PipeTransform {
  public constStatuses = LEG_STATUSES;
  constructor() { }
  public transform(service: TripServices[]) {
    const HandlingServices = [];
    const GHServices = [];
    const HandlingDeliveredServices = [];
    const AirportChargesServices = [];
    const AirportChargesDeliveredServices = [];
    service.forEach((obj) => {
      if (obj.servicename == "Handling") {
        HandlingServices.push(obj);
      }
      if (obj.servicename == "GH Services Form Signoff") {
        GHServices.push(obj);
      }
      if (obj.servicename == "Handling Delivered") {
        HandlingDeliveredServices.push(obj);
      }
      if (obj.servicename == "Airport Charges") {
        AirportChargesServices.push(obj);
      }
      if (obj.servicename == "Airport Charges Delivered") {
        AirportChargesDeliveredServices.push(obj);
      }
    });
    const handlingbyLegid = this.groupBy(HandlingServices, "legId");
    for (const data in handlingbyLegid) {
      if (handlingbyLegid[data].length > 1) {
        for (let i = 1; i < handlingbyLegid[data].length; i++) {
          handlingbyLegid[data][i].order = 30;
        }
      }
    }
    const GHServicesbyLegid = this.groupBy(GHServices, "legId");
    for (const data in GHServicesbyLegid) {
      if (GHServicesbyLegid[data].length > 1) {
        for (let i = 1; i < GHServicesbyLegid[data].length; i++) {
          GHServicesbyLegid[data][i].order = 31;
        }
      }
    }
    const HandlingDeliveredbyLegid = this.groupBy(
      HandlingDeliveredServices,
      "legId",
    );
    for (const data in HandlingDeliveredbyLegid) {
      if (HandlingDeliveredbyLegid[data].length > 1) {
        for (let i = 1; i < HandlingDeliveredbyLegid[data].length; i++) {
          HandlingDeliveredbyLegid[data][i].order = 32;
        }
      }
    }
    const AirportChargesbyLegid = this.groupBy(AirportChargesServices, "legId");
    for (const data in AirportChargesbyLegid) {
      if (AirportChargesbyLegid[data].length > 1) {
        for (let i = 1; i < AirportChargesbyLegid[data].length; i++) {
          AirportChargesbyLegid[data][i].order = 33;
        }
      }
    }
    const AirportChargesDeliveredbyLegid = this.groupBy(
      AirportChargesDeliveredServices,
      "legId",
    );
    for (const data in AirportChargesDeliveredbyLegid) {
      if (AirportChargesDeliveredbyLegid[data].length > 1) {
        for (let i = 1; i < AirportChargesDeliveredbyLegid[data].length; i++) {
          AirportChargesDeliveredbyLegid[data][i].order = 34;
        }
      }
    }
    const groups = service.reduce((obj, item) => {
      obj[item.legId] = obj[item.legId] || [];
      obj[item.legId].push(item);
      obj[item.legId].sort((a, b) => 0 - (a.order > b.order ? -1 : 1));
      return obj;
    }, {});
    const finalService = [];

    for (const legid in groups) {
      if (legid) {
        if (service[0].tripStatus == this.constStatuses.COMPLETED) {
          finalService.push(...groups[legid]);
        } else {
          finalService.push(
            ...groups[legid].filter((legdata: any) => {
              return legdata.location == groups[legid][0].sector;
            }),
          );
          finalService.push(
            ...groups[legid].filter((legdata: any) => {
              return (
                legdata.location == groups[legid][0].sector.substr(0, 4) ||
                [
                  PageNames.Landed,
                  PageNames.ChocksOn,
                  PageNames.ArrCIQ,
                ].indexOf(legdata.servicename) > -1
              );
            }),
          );
          finalService.push(
            ...groups[legid].filter((legdata: any) => {
              return (
                legdata.location == groups[legid][0].sector.substr(5, 9) &&
                [
                  PageNames.Landed,
                  PageNames.ChocksOn,
                  PageNames.ArrCIQ,
                ].indexOf(legdata.servicename) == -1
              );
            }),
          );
        }
      }
    }
    return finalService;
  }
  public groupBy(xs, key) {
    return xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }
}