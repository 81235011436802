import { AuthSessionService } from '@app/core/services/auth-session.service';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { CommonService } from '@app/shared/services/common.service';
import { Injectable } from '@angular/core';
import firebase from 'firebase';
import { ActivityCount, ActivityCountModel, TripStatusModel } from '../trips-tab/model/model.index';
import { FirebaseDataService } from '@app/shared/services/firebase-data.service';
import { Events } from '@app/shared/services/events.service';
@Injectable({
    providedIn: 'root'
})
export class TripActivityService {
    public tripDisplayStatus: BehaviorSubject<TripStatusModel> = new BehaviorSubject(new TripStatusModel());
    public $NotificationsCount: BehaviorSubject<ActivityCount> = new BehaviorSubject(new ActivityCount());
    public storeNotificationCount: BehaviorSubject<ActivityCountModel> = new BehaviorSubject(new ActivityCountModel());
    public storeActivityCount: BehaviorSubject<ActivityCountModel> = new BehaviorSubject(new ActivityCountModel());
    public $ActivitiesCount: BehaviorSubject<ActivityCount> = new BehaviorSubject(new ActivityCount());
    public $totalTripCount: BehaviorSubject<ActivityCount> = new BehaviorSubject(new ActivityCount());
    public activityCount = new Subject();

    public totalCount: any;
    constructor(private commonService: CommonService, private authSessionService: AuthSessionService,
        private firebaseService: FirebaseDataService, private event: Events) { }
    public getTripStatus(id, ...args) {
        this.fetchTripStatus(id, args[0], args[1], args[2]).subscribe(item => {

        })
    }
    public sendTotalCount(id: any) {
        let nCount: any;
        let aCount: any;
        this.storeNotificationCount.subscribe((ref: any) => {
            if (ref.Id == id) { nCount = ref.Count; }
        })
        this.storeActivityCount.subscribe((ref: any) => {
            if (ref.Id == id) { aCount = ref.Count; }
        })
        const Count = nCount == undefined ? nCount = 0 : nCount;
        const Count1 = aCount == undefined ? aCount = 0 : aCount;
        this.$totalTripCount.next(aCount + nCount);
    }
    public sendSelectedTripActivitiesCount(id) {
        const observable = new Observable(observer => {
            firebase.database().ref('/Activity/' + this.commonService.uid + '/' + id).on("value", (snapshot) => {
                if (snapshot.val() != null && snapshot.val()) {
                    this.storeActivityCount.next({ Id: id, Count: snapshot.val().Count });
                    this.$ActivitiesCount.next(snapshot.val().Count);
                    this.sendTotalCount(id);
                    observer.next(snapshot.val().Count);
                }
                else {
                    this.storeActivityCount.next({ Id: id, Count: 0 });
                    this.$ActivitiesCount.next(null);
                    this.sendTotalCount(id);
                    observer.next(null);
                }
            });
        });
        return observable;
    }
    public fetchTripStatus(id, requestType, clientFirebaseUid, clientFirebaseSRID) {
        const observable = new Observable(observer => {
            if (requestType == 'client') {
                firebase.database().ref('/ClientTrips/' + this.commonService.FIREBASE_UID + '/' + id).on("value", (snapshot) => {
                    if (snapshot.val() != null && snapshot.val()) {
                        this.tripDisplayStatus.next({ Id: id, status: snapshot.val().Status });
                        observer.next(snapshot.val().Status);
                    }
                    else {
                        this.tripDisplayStatus.next({ Id: id, status: '' });
                        observer.next(null);
                    }
                });
            }
            if (requestType == 'vendor') {
                firebase.database().ref('/ClientTrips/' + clientFirebaseUid + '/' + clientFirebaseSRID).on("value", (snapshot) => {
                    if (snapshot.val() != null && snapshot.val()) {
                        this.tripDisplayStatus.next({ Id: id, status: snapshot.val().Status });
                        observer.next(snapshot.val().Status);
                    }
                    else {
                        this.tripDisplayStatus.next({ Id: id, status: '' });
                        observer.next(null);
                    }
                });
            }
        });
        return observable;
    }
    public sendselectedTripTotalCountValue(id) {
        this.firebaseService.sendSelectedTripNotificationRef(id)
    }
    public LoadTotalNotificationValue() {
        this.firebaseService.getTripNotification();
    }
    public getTotalCount(tripID) {
        let path: string = "Activity";
        if (
            this.tripDisplayStatus.value.status != "" &&
            this.tripDisplayStatus.value.status != "Processing"
        ) {
            path = "Activity";
        }

        const refferenceToActivityAndNotificationCount =
            "/" +
            path +
            "/" +
            this.authSessionService.userId +
            "/" +
            tripID +
            "/Count/";
        this.totalCount = 0;
        firebase
            .database()
            .ref(refferenceToActivityAndNotificationCount)
            .on("value", (snapshot) => {
                if (snapshot.val() != null && snapshot.val()) {
                    this.totalCount = snapshot.val() != null ? snapshot.val() : 0;
                } else {
                    this.totalCount = null;
                }
            });
        this.event.subscribe("closeTrip", () => {
            // this.navCtrl.back();
            // const view = this.navCtrl.getActive();
            // if (view.component.name != "TripsPage") { this.closeTripPage(); }
        });
    }
}
