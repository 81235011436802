import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { SideMenuService } from '@app/right-menu/services/side-menu.service';
import { ActivityService } from '@app/trips/services/activity.service';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-activities',
  templateUrl: './trip-activities.component.html',
  styleUrls: ['./trip-activities.component.scss'],
})
export class TripActivitiesComponent implements OnInit {
  private tripData: any;
  public pageType = "tripsNotification"
  constructor(public activityService: ActivityService,
    public sidemenu: SideMenuService, private router: Router, private menuController: MenuController) {
    this.sidemenu.tripData.subscribe(data => {
      this.tripData = data;
    })
  }
  public ngOnInit() { }
  public viewTripDetails() {
    const navigationExtras: NavigationExtras = {
      state: {
        status: this.tripData.status,
        id: this.tripData.tripId,
        pageName: 'Trip Info',
      },
    };
    this.router.navigate(['trips/trips-tab/' + this.tripData.tripId],
      navigationExtras
    );
    this.menuController.toggle('end');
  }
}