export class CsvExcelFile {
    public selectedFileName: string = "";
    public FileName: string = "";
    public file: any;
}

export class CsvExcelData {
    public FUDName: string;
    public CreatedDate: string;
    public DataUpdatedDate: string;
    public FailedFUDName?: string;
    public NoOfFailedRecord: string;
    public NoOfRecord: string;
    public Path: string;
    public Status: string;
    public UploadStatus: string;
    public UserId: string;
    public FPEDID?: any;
}

export class HandlingExcelFile {
    public selectedFileName: string = "";
    public FileName: string = "CSVFile";
    public file: any;
}

export class HandlingExcelData {
    public GHEDID: string;
    public CreatedDate: string;
    public DataUpdatedDate: string;
    public FailedFUDName?: string;
    public NoOfFailedRecord: string;
    public NoOfRecord: string;
    public Path: string;
    public Status: string;
    public UploadStatus: string;
    public UserId: string;
    public FPEDID?: any;
}