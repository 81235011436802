import firebase from 'firebase';
import { CommonService } from './common.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActionSheetController, Platform, AlertController } from '@ionic/angular';
import { MessageConfig } from './../constants/constant';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import { Chooser } from '@awesome-cordova-plugins/chooser/ngx';
@Injectable({
  providedIn: 'root',
})
export class FileUploadServiceService {
  public totalData: any;
  public nativepath: any;
  public fileName: any;
  public checkFileName: any;
  public chekfileType: any;
  public imageSelect: any;
  public messageConfig = MessageConfig;
  public imgSelectWrong: boolean = false;
  constructor(
    public http: HttpClient,
    public actionSheetCtrl: ActionSheetController,
    public platform: Platform,
    private commonService: CommonService,
    private alertCtrl: AlertController,
    private chooser: Chooser
  ) { }

  public uploadFile(fileInput?) {
    return new Promise(async (resolve) => {
      const actionsheet = await this.actionSheetCtrl.create({
        header: 'Choose Options',
        cssClass: 'action-sheets-groups-page',
        buttons: [
          {
            text: 'Take Photo',
            icon: Capacitor.getPlatform() != 'ios' ? 'camera' : null,
            handler: async () => {
              const image = await Camera.getPhoto({
                quality: 100,
                width: 400,
                allowEditing: false,
                resultType: CameraResultType.DataUrl,
                source: CameraSource.Camera
              }).then((data) => {
                const theActualPicture = data.dataUrl;
                resolve(theActualPicture);
              }).catch((error => {
                resolve('Error');
              }));
            }
          },
          {
            text: 'Upload photo from library',
            icon: Capacitor.getPlatform() != 'ios' ? 'images' : null,
            handler: () => {
              const image = Camera.getPhoto({
                quality: 100,
                width: 400,
                allowEditing: false,
                resultType: CameraResultType.DataUrl,
                source: CameraSource.Photos
              }).then((data) => {
                const theActualPicture = data.dataUrl;
                resolve(theActualPicture);
              }).catch((error => {
                resolve('Error');
              }));
            },
          },
          {
            text: 'Document',
            icon: Capacitor.getPlatform() != 'ios' ? 'folder-open' : null,
            handler: () => {
              this.selectFile(resolve)
            },
          },
          {
            text: 'Cancel',
            role: 'cancel',
            icon: Capacitor.getPlatform() != 'ios' ? 'close' : null,
          },
        ],
      });
      await actionsheet.present();
    });
  }

  public async profilePicUpload() {
    return new Promise(async (resolve) => {
      const actionsheet = await this.actionSheetCtrl.create({
        header: 'Choose Options',
        cssClass: 'action-sheets-groups-page',
        buttons: [
          {
            text: 'Take Photo',
            icon: Capacitor.getPlatform() != 'ios' ? 'camera' : null,
            handler: async () => {
              const image = await Camera.getPhoto({
                quality: 100,
                width: 400,
                allowEditing: false,
                resultType: CameraResultType.DataUrl,
                source: CameraSource.Camera
              }).then((data) => {
                const theActualPicture = data.dataUrl;
                resolve(theActualPicture);
              }).catch((error => {
                resolve('Error');
              }));
            }
          },
          {
            text: 'Upload photo from library',
            icon: Capacitor.getPlatform() != 'ios' ? 'images' : null,
            handler: () => {
              const image = Camera.getPhoto({
                quality: 100,
                width: 400,
                allowEditing: false,
                resultType: CameraResultType.DataUrl,
                source: CameraSource.Photos
              }).then((data) => {
                const theActualPicture = data.dataUrl;
                resolve(theActualPicture);
              }).catch((error => {
                resolve('Error');
              }));
            },
          },
          {
            text: 'Cancel',
            role: 'cancel',
            icon: Capacitor.getPlatform() != 'ios' ? 'close' : null,
          },
        ],
      });
      await actionsheet.present();
    });
  }

  public async selectFile(resolve) {
    await this.chooser.getFile()
      .then(async (file: any) => {
        this.totalData = {
          filename: file.name,
          base64: file.dataURI,
          mediaType: file.mediaType,
          size: file.data.length
        };
        resolve(this.totalData);
      })
      .catch((error: any) =>
        resolve('Error'));
  }

  public base64MimeType(encoded) {
    let result = null;
    if (typeof encoded !== 'string') {
      return result;
    }
    const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    if (mime && mime.length) {
      result = mime[1];
    }
    return result;
  }

  public fileReaderResult(event) {
    return event.target.result;
  }

  public fileCheck(base64) {
    const type = this.base64MimeType(base64);
  }

  public FirebaseBucketUpload(PathToUpload: string, fileToUpload: any) {
    return new Promise((resolve) => {
      const storageref = firebase.storage().ref(PathToUpload);
      const task = storageref
        .putString(fileToUpload, 'data_url')
        .then((snapshot) => {
          snapshot.ref.getDownloadURL().then((data) => {
            resolve(data);
          });
        });
    });
  }

  public getFileExt(filestring) {
    const file = filestring.substr(filestring.lastIndexOf('.') + 1);
    return file;
  }
  public checkFileType(fileInput, fileType) {
    return new Promise((resolve) => {
      let filevalid: boolean = false;
      const filereader = new FileReader();
      filereader.readAsArrayBuffer(fileInput);
      filereader.onload = (evt: any) => {
        const uint = new Uint8Array(evt.target.result);
        const bytes = [];
        uint.forEach((byte) => {
          bytes.push(byte.toString(16));
        });
        const hex = bytes.join('').toUpperCase().substr(0, 8);
        if (fileType == 'file') {
          switch (hex) {
            case '89504E47': // png
              if (fileInput.type == 'image/png') {
                filevalid = true;
              }
              break;
            case '25504446': // pdf
              if (fileInput.type == 'application/pdf') {
                filevalid = true;
              }
              break;
            case 'FFD8FFE0': // jpeg
              if (
                fileInput.type == 'image/jpg' ||
                fileInput.type == 'image/jpeg'
              ) {
                filevalid = true;
              }
              break;
            case 'FFD8FFE1': // jpeg
              if (
                fileInput.type == 'image/jpg' ||
                fileInput.type == 'image/jpeg'
              ) {
                filevalid = true;
              }
              break;

            case '504B3414': // docx && XLSx
              if (
                fileInput.type ==
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                fileInput.type ==
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              ) {
                //// xls
                filevalid = true;
              }
              break;
            case 'D0CF11E0':
              // var ext = fileInput.name.split('.').pop();
              // if(ext=="docx" || ext=="doc"){
              //   filevalid = true;
              // }
              if (
                fileInput.type == 'application/vnd.ms-excel' ||
                fileInput.type == 'application/msword' ||
                fileInput.type == 'application/vnd.ms-outlook'
              ) {
                // xls&&doc
                filevalid = true;
              }
              break;
            case '49492A0C': // tiff code aded by shailesh on 19/11/19
              if (fileInput.type == 'image/tiff') {
                filevalid = true;
              }
              break;
            default: {
              if (fileInput.type == 'text/plain') {
                filevalid = true;
              }
              break;
            }
          }
        } else if (fileType == 'image') {
          switch (hex) {
            case '89504E47': // png
              if (fileInput.type == 'image/png') {
                filevalid = true;
              }
              break;
            case 'FFD8FFE0': // jepg
              if (
                fileInput.type == 'image/jpg' ||
                fileInput.type == 'image/jpeg'
              ) {
                filevalid = true;
              }
              break;
            case 'FFD8FFE1': // jpeg
              if (
                fileInput.type == 'image/jpg' ||
                fileInput.type == 'image/jpeg'
              ) {
                filevalid = true;
              }
              break;
          }
        }
        resolve(filevalid);
      };
    });
  }
  public checkFilesType(fileInput, fileType) {
    return new Promise((resolve) => {
      let fileValid: boolean = false;
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(fileInput);
      fileReader.onload = (evt: any) => {
        const uint = new Uint8Array(evt.target.result);
        const bytes = [];
        uint.forEach((byte) => {
          bytes.push(byte.toString(16));
        });
        const hex = bytes.join("").toUpperCase().substr(0, 8);
        // Validate file name
        const fileName = fileInput.name;
        const startsWithSpecialChar = /^[^\w\s]/.test(fileName);
        if (startsWithSpecialChar) {
          fileValid = false;
          fileReader.onloadend = () => {
            // Dismiss the loader when the file reading ends, if fileValid value is false
            if (fileValid == false) {
              this.commonService.showFileAlert("file format.");
              this.commonService.dismissLoader();
            }
          };
        } else {
          if (fileInput.type == "application/vnd.ms-excel" ||
            fileInput.type == "text/csv") {
            fileValid = true;
          }
          resolve(fileValid);
        };
      }
    });
  }
  public async fileAlert(fileMessage) {
    return new Promise(async (resolve) => {
      const alert = await this.alertCtrl.create({
        header: MessageConfig.ApiErrorHeader,
        subHeader: 'Please select a valid ' + fileMessage,
        buttons: [
          {
            text: MessageConfig.CancelButton,
            role: MessageConfig.cancel,
            handler: () => {
              resolve(false);
            },
          },
          {
            text: MessageConfig.TryAgain,
            role: MessageConfig.ok,
            handler: () => {
              resolve(true);
            },
          },
        ],
      });
      await alert.present();
    });
  }
  public generateFromImage(
    img,
    MAX_WIDTH: number = 700,
    MAX_HEIGHT: number = 700,
    quality: number = 1,
    callback
  ) {
    const canvas: any = document.createElement('canvas');
    const image = new Image();

    image.onload = () => {
      let width = image.width;
      let height = image.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        } else {
          this.imgSelectWrong = true;
          this.fileAlert('Image');
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        } else {
          this.imgSelectWrong = true;
          this.fileAlert('Image');
        }
      }
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(image, 0, 0, width, height);
      const dataUrl = canvas.toDataURL('image/jpeg', quality);
      if (this.imgSelectWrong != true) {
        callback(dataUrl);
      }
      else {
        callback(false)
        this.imgSelectWrong = false;
      }
    };
    if (this.imgSelectWrong != true) {
      image.src = img;
    }
    else {
      this.imgSelectWrong = false;
    }
  }

  public getImageSize(dataUrl) {
    const head = 'data:image/jpeg;base64,';
    return (((dataUrl.length - head.length) * 3) / 4 / (1024 * 1024)).toFixed(
      4
    );
  }

  public createThumbnail(bigImg) {
    return new Promise((resolve, reject) => {
      this.generateFromImage(
        bigImg,
        200,
        200,
        0.5,
        (data) => {
          if (data) {
            resolve(data)
          }
        }
      );
    })
  }
}
