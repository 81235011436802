import { UtilService } from '@app/core/services/util.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthSessionService } from '../services/auth-session.service';
import { MessageConfig } from '@app/shared/constants/constant';

@Injectable({
  providedIn: 'root',
})
export class RolesGuard implements CanActivate {
  constructor(
    private authSessionService: AuthSessionService,
    private utilService: UtilService
  ) { }
  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    return this.authSessionService.permission(route.data.moduleName, route.data.access).then((data) => {
      if (data)
        return true;
      else
        this.utilService.showToastCtrl(MessageConfig.Unauthorized)
      return false;
    })
  }
}
