import { Component, OnInit } from '@angular/core';
import { MenuController, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-animated-splash',
  templateUrl: './animated-splash.component.html',
  styleUrls: ['./animated-splash.component.scss'],
})
export class AnimatedSplashComponent implements OnInit {
  public windowWidth: string;
  public showSplash = true;
  constructor(private menuCtrl: MenuController) { }

  public ngOnInit(): void {
    setTimeout(() => {
      this.windowWidth = "-" + window.innerWidth + "px";
      // setTimeout(() => {
        this.showSplash = !this.showSplash;
      // }, 500);
    }, 3000);
  }
  public ionViewWillEnter() {
    this.menuCtrl.enable(false);
  }

  public ionViewWillUnload() {
    this.menuCtrl.enable(true);
  }

}
