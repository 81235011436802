export class ClientProfile {
  public companyname: string = '';
  public companyemail: string = '';
  public clinetname: string = '';
  public clientemail: string = '';
  public clientcontactno: any;
  public clientcallingCodes: string = '';
  public operationname: string = '';
  public operationemail: string = '';
  public operationcontact: any;
  public callingCodeOpscontact: string = '';
  public accountname: string = '';
  public accountemail: string = '';
  public accountcontact: any;
  public AccountcallingCodes: string = '';
  public addressline1: string = '';
  public addressline2: string = '';
  public addressline3: string = '';
  public companyabout: string = '';
  public clientID: string = '';
  public clientlogo: string = '';
  public companydetail: string = '';
  public Timezone: string = '';
  public currency: string = '';
  public fuelUnit: string = '';
  public info: string;
  public UID: string;
  public photoUrl: string;
  public timezoneselect: string;
  public datatype: any;
  public countryname: any;
  public statename: string = '';
  public pincode: string = '';
  public uploadphotofileName: string = '';
  public uploadfileName: string = '';
  public docurl: string = '';
  public type: string = '';
  public doc: string;
  public docid?: string = '';
  public filename: any;
  public readonly: boolean;
  public countrySelect: string = '';
  public filenametitle: string = '';
  public panno: string = '';
  public servicetaxno: string = '';
  public clientMultiEmails: string[] = [];
  public accountMultipleEmail: string[] = [];
  public operationMultipleEmail: string[] = [];
}
export class CharterProfile {
  public companyname: string = '';
  public companyemail: string = '';
  public username: string = '';
  public clientemail: string = '';
  public charterContactno: any;
  public usercallingCodes: string = '';
  public operationname: string = '';
  public operationemail: string = '';
  public operationcontact: any;
  public callingCodeOpscontact: string = '';
  public accountname: string = '';
  public accountemail: string = '';
  public accountcontact: any;
  public accountcallingCodes: string = '';
  public addressline1: string = '';
  public addressline2: string = '';
  public addressline3: string = '';
  public companyabout: string = '';
  public charterUserID: string = '';
  public createdDate: string = '';
  public privacyForEmail: string = '';
  public verified: string = '';
  public UserID: string = '';
  public privacyForNotification: string = '';
  public clientlogo: string = '';
  public companydetail: string = '';
  public Timezone: string = '';
  public currency: string = '';
  public fuelUnit: string = '';
  public info: string;
  public UID: string;
  public photoUrl: string;
  public flag: string;
  public timezoneselect: string;
  public datatype: any;
  public countryname: any;
  public statename: string = '';
  public pincode: string = '';
  public uploadphotofileName: string = '';
  public uploadfileName: string = '';
  public docurl: string = '';
  public type: string = '';
  public doc: string;
  public docid?: string = '';
  public filename: any;
  public readonly: boolean;
  public countrySelect: string = '';
  public filenametitle: string = '';
  public panno: string = '';
  public servicetaxno: string = '';
  public clientMultiEmails: string[] = [];
  public accountMultipleEmail: string[] = [];
  public operationMultipleEmail: string[] = [];
  public logo: string;
  constructor(allcharterDetails?: any, commonService?: any) {
    if (allcharterDetails) {
      this.companyname = allcharterDetails?.CompanyName;
      this.username = allcharterDetails?.CharterUserName;
      this.companyemail = allcharterDetails?.CompanyEmail;
      // this.clientMultiEmails = allcharterDetails.CompanyMultipleEmail;
      const userContactNumber =
        commonService.getContactNumberFormate(
          allcharterDetails.CharterUsercallingCodes,
          allcharterDetails.CharterUserContactNo
        );
      this.charterContactno = userContactNumber;
      this.operationname =
        allcharterDetails.CharterUserOperationContactName;

      this.operationemail =
        allcharterDetails.CharterUserOperationContactEmailID != '' ? allcharterDetails.CharterUserOperationContactEmailID : allcharterDetails.CompanyEmail;
      // this.operationMultipleEmail = allcharterDetails.Ops;

      const operationContactNumber =
        commonService.getContactNumberFormate(
          allcharterDetails.callingCodeOpscontact,
          allcharterDetails.CharterUserOperationContactNo
        );
      this.operationcontact = operationContactNumber;
      this.accountname = allcharterDetails.CharterUserAccDeptContactName;
      this.accountemail =
        allcharterDetails.CharterUserAccDeptContactEmailID;
      // this.accountMultipleEmail = allcharterDetails.Accounts;
      const AccountContactNumber =
        commonService.getContactNumberFormate(
          allcharterDetails.AccountcallingCodes,
          allcharterDetails.CharterUserAccDeptContactNo
        );
      this.accountcontact = AccountContactNumber;
      this.addressline1 =
        allcharterDetails.CharterUserInvoiceAddressline1;
      this.addressline2 =
        allcharterDetails.CharterUserInvoiceAddressline2;
      this.addressline3 =
        allcharterDetails.CharterUserInvoiceAddressline3;
      this.panno = allcharterDetails.PAN_No;
      this.servicetaxno = allcharterDetails.Service_TaxNo;
      this.companyabout = allcharterDetails.Description;
      this.charterUserID = allcharterDetails.CharterUserID;
      this.photoUrl = allcharterDetails.logo;
      this.Timezone = allcharterDetails.Timezone;
      this.pincode = allcharterDetails.Pincode;
      this.statename = allcharterDetails.State;
      this.countryname = allcharterDetails.Country;
      this.uploadfileName = allcharterDetails.Documentpath;
      this.filenametitle = allcharterDetails.filenametitle;
      this.currency = allcharterDetails.currency;
      // this.viewprofileComp.getMultiEmails();
      if (allcharterDetails.currencyCode == '') {
        this.currency = '';
      } else {
        this.currency = allcharterDetails.currencyCode;
      }
    }

  }
}
export class VendorProfile {
  public companyname: string = '';
  public vendorname: string = '';
  public vendoremail: string = '';
  public vendorcontactno: any;
  public vendorcallingCodes: string = '';
  public operationname: string = '';
  public operationemail: string = '';
  public operationcontact: any;
  public vendorOpscallingCodes: string = '';
  public accountname: string = '';
  public accountemail: string = '';
  public accountcontact: any;
  public vendoraccountcallingCodes = '';
  public addressline1: string = '';
  public addressline2: string = '';
  public addressline3: string = '';
  public companyabout: string = '';
  public vendorID: string = '';
  public vendorlogo: string = '';
  public panno: string = '';
  public servicetaxno: string = '';
  public bankname: string = '';
  public branch: string = '';
  public currentaccno: string = '';
  public ifsccode: string = '';
  public companydetails: string = '';
  public Timezone: string = '';
  public pincode: string = '';
  public countryname: string;
  public statename: string = '';
  public datatype: any;
  public currency: string;
  public UID: string;
  public photoUrl: string;
  public info: string;
  public unitType: string = '';
  public FrequencyString: any;
  public Fax: string = '';
  public OperationalStartFrom: string = '';
  public OperationalEndTo: string = '';
  public TollFreeNumber: string = '';
  public SupplierType: any;
  public FrequencyUnit: string = 'Mhz';
  public timezoneselect: string = '';
  public VendorServicesCat: any;
  public uploadfileName: string = '';
  public docurl: string = '';
  public filename: string = '';
  public filenametitle: string = '';
  public type: string = '';
  public docid?: string = '';
  public doc: string = '';
  public callingCodes: string = '';
  public vendorCompanyMultiEmails: string[] = [];
  public vendorAccountMultipleEmail: string[] = [];
  public operationMultipleEmail: string[] = [];
}
export class Userprivacy {
  public emailflag: boolean = false;
  public pushflag: boolean = false;
  public Value: string = 'Privacy';
}
export class CharterApiDetails {
  public AccountcallingCodes: string;
  public CharterUserAccDeptContactEmailID: string;
  public CharterUserAccDeptContactName: string;
  public CharterUserAccDeptContactNo: any;
  public CharterUserContactNo: any;
  public CharterUserID: any;
  public CharterUserInvoiceAddressline1: string;
  public CharterUserInvoiceAddressline2: string;
  public CharterUserInvoiceAddressline3: string;
  public CharterUserName: string;
  public CharterUserOperationContactEmailID: string;
  public CharterUserOperationContactName: string;
  public CharterUserOperationContactNo: any;
  public CharterUsercallingCodes: string;
  public CompanyAddress: string;
  public CompanyDetails: string;
  public CompanyEmail: string;
  public CompanyName: string;
  public Country: string;
  public CreatedDate: string;
  public Description: string;
  public Documentpath: string;
  public NumberOfAircraft: string;
  public PAN_No: string;
  public PaymentType: string;
  public Pincode: string;
  public PrivacyForEmail: string;
  public PrivacyForNotification: string;
  public Service_TaxNo: string;
  public State: string;
  public Timezone: string;
  public UserID: string;
  public Verified: string;
  public callingCodeOpscontact: any;
  public currencyCode: string;
  public filenametitle: string;
  public flag: string;
  public logo: string;
  public name: string = '';
  public datatype: any;

  constructor(UserData?: CharterProfile, commonService?: any) {
    if (UserData) {
      this.CharterUserID = UserData.charterUserID;
      this.CharterUserAccDeptContactEmailID = UserData?.accountemail ? commonService.tranformMailsToLowerCase(UserData.accountemail): UserData?.accountemail;
      this.CharterUserAccDeptContactName = UserData.accountname;
      if (UserData.accountcontact) {
        const contactNumberFormate = commonService.contactNumberFormate(UserData.accountcontact);
        this.AccountcallingCodes = contactNumberFormate.dialCode
        this.CharterUserAccDeptContactNo = contactNumberFormate.contact;
      }
      this.CharterUserInvoiceAddressline1 = UserData.addressline1;
      this.CharterUserInvoiceAddressline2 = UserData.addressline2;
      this.CharterUserInvoiceAddressline3 = UserData.addressline3;
      if (UserData.charterContactno) {
        const contactNumberFormate = commonService.contactNumberFormate(UserData.charterContactno);
        this.CharterUsercallingCodes = contactNumberFormate.dialCode
        this.CharterUserContactNo = contactNumberFormate.contact;
      }
      this.CharterUserName = UserData.username;
      this.CharterUserOperationContactEmailID = UserData?.operationemail ? commonService.tranformMailsToLowerCase(UserData.operationemail) : UserData?.operationemail;
      this.CharterUserOperationContactName = UserData.operationname;
      if (UserData.operationcontact) {
        const contactNumberFormate = commonService.contactNumberFormate(UserData.operationcontact);
        this.callingCodeOpscontact = contactNumberFormate.dialCode
        this.CharterUserOperationContactNo = contactNumberFormate.contact;
      }
      this.CompanyEmail = UserData?.companyemail ? commonService.tranformMailsToLowerCase(UserData.companyemail): UserData?.companyemail;
      this.CompanyName = UserData.companyname;
      this.Country = UserData.countryname;
      this.CreatedDate = UserData.createdDate;
      this.PAN_No = UserData.panno;
      this.Pincode = UserData.pincode;
      this.PrivacyForEmail = UserData.privacyForEmail;
      this.PrivacyForNotification = UserData.privacyForNotification;
      this.Timezone = UserData.Timezone;
      this.UserID = UserData.UserID;
      this.Verified = UserData.verified;
      this.currencyCode = UserData.currency;
      this.flag = UserData.flag;
      this.logo = UserData.logo;
    }
  }
}

export class UserLocalData {
  public ClientID: string;
  public UserID: string;
  public ClientName: string;
  public ClientInvoiceAddressline1: string;
  public ClientInvoiceAddressline2: string;
  public ClientInvoiceAddressline3: string;
  public ClientContactNo: string;
  public clientcallingCodes: string = '+1';
  public ClientOperationContactName: string;
  public CreatedDate: string;
  public ClientOperationContactNo: string;
  public callingCodeOpscontact: string;
  public ClientOperationContactEmailID: string;
  public ClientAccDeptContactName: string;
  public ClientAccDeptContactNo: string;
  public AccountcallingCodes: string;
  public Accounts: any;
  public Ops: any;
  public ClientAccDeptContactEmailID: string;
  public Verified: string;
  public flag: string;
  public logo: string;
  public Timezone: string;
  public currencyCode: string;
  public CompanyName: string;
  public CompanyDetails: string;
  public PaymentType: string;
  public NumberOfAircraft: string;
  public Description: string;
  public PrivacyForNotification: string;
  public CompanyEmail: string;
  public CompanyMultipleEmail: any;
  public fuelUnit: string;
  public datatype: any;
  public info: string;
  public currency: string;
  public timezoneselect: string;
  public Pincode: string;
  public State: string;
  public Country: string;
  public FileName: string;
  public docURL: string;
  public Documentpath: string;
  public filenametitle: string;
  public uploadfileName: string;
  public PAN_No: string;
  public ServiceTaxNo: string;
  public Service_TaxNo: string;
}

export class VendorLocalData {
  public VendorID: string;
  public UserID: string;
  public VendorServicesCat: any;
  public VendorName: string;
  public VendorInvoiceAddressline1: string;
  public VendorInvoiceAddressline2: string;
  public VendorInvoiceAddressline3: string;
  public VendorContactNo: string;
  public vendorcallingCodes: string;
  public VendorOperationContactName: string;
  public CreatedDate: string;
  public VendorOperationContactNo: string;
  public vendorOpscallingCodes: string;
  public VendorOperationContactEmailID: string;
  public Accounts: any;
  public Ops: any;
  public VendorAccDeptContactName: string;
  public VendorAccDeptContactNo: string;
  public vendoraccountcallingCodes: string;
  public VendorAccDeptContactEmailID: string;
  public panNum: string;
  public ServiceTaxNo: string;
  public CompanyName: string;
  public Description: string;
  public BankName: string;
  public Branch: string;
  public CurrentACNo: string;
  public IFSCCode: string;
  public Verified: string;
  public flag: string;
  public logo: string;
  public Timezone: string;
  public currencyCode: string;
  public CompanyDetails: string;
  public PaymentType: string;
  public CompanyEmail: string;
  public unitType: string;
  public FrequencyString: string;
  public Fax: string = '';
  public SupplierType: string;
  public TollFreeNumber: string = '';
  public OperationalStartFrom: string;
  public OperationalEndTo: string;
  public FrequencyUnit: string;
  public Pincode: string;
  public State: string;
  public Country: string;
  public currency: string;
  public datatype: any;
  public unitTyp: any;
  public FileName: string;
  public docURL: string;
  public Documentpath: string;
  public filenametitle: string;
  public uploadfileName: string;
  public callingCodes: string;
  public CompanyMultipleEmail: any;
  public PAN_No: string;
  public Service_TaxNo: string;

}
export class UserProfileData {
  public Info: string = '';
  public Name: string = '';
  public Coverimage: string = '';
  public Image: string = '';
}
export interface FirebaseCountryObject {
  TextField: string;
  callingCodes: string;
  countryCode: string;
  name: string;
  numberExample: string;
}
