import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeChar',
})
export class RemoveCharPipe implements PipeTransform {
  public transform(value: string) {
    if (value.indexOf('Mach') > -1) {
      return value.replace('Mach 0', 'M');
    } else if (value == 'Long Range Cruise') {
      return 'LRC';
    } else if (value == 'Max Range Power') {
      return 'MRP';
    } else if (value == 'Max Cruise Power') {
      return 'MCP';
    } else if (value == 'Max Speed Cruise') {
      return 'Max';
    } else {
      return value;
    }
  }
}
