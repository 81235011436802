import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-logo',
  templateUrl: './auth-logo.component.html',
  styleUrls: ['./auth-logo.component.scss'],
})
export class AuthLogoComponent implements OnInit {
  constructor() {}

  public ngOnInit() {}
}
