import { Injectable } from '@angular/core';
import { FirebaseDataService } from './firebase-data.service';

@Injectable({
  providedIn: 'root',
})
export class UnitCurrecyService {
  constructor(private fbDatabase: FirebaseDataService) {}
  // all user calculation starting from usg
  // all vendor calculation starting from their setting to usg
  // logic is value * 3.785 wii give lit
  // lit * sg will give kg
  // kg * 2.2 will give  lbs
  // kg / 1000 will give mt
  public userconvertUnit(sgravity, value, unit) {
    let conversion = '';
    switch (unit) {
      case 'LTR': // lit
        conversion = String(Number(value) * 3.785);
        break;
      case 'USG': // usg
        conversion = value;
        break;
      case 'Pound': // lbs
        conversion = String(Number(value) * 3.785 * sgravity * 2.20462);
        break;
      case 'KG': // kg
        conversion = String(Number(value) * 3.785 * sgravity);
        break;
      case 'MT': // mt
        conversion = String((Number(value) * 3.785 * sgravity) / 1000);
        break;
      default:
        conversion = '';
        break;
    }
    return conversion;
  }
  public vendorconvertUnit(sgravity, value, unit) {
    // conversion is always USG value return
    let conversion = '';
    switch (unit) {
      case 'LTR': // lit
        conversion = String(Number(value) / 3.785);
        break;
      case 'USG': // usg
        conversion = value;
        break;
      case 'Pound': // lbs
        conversion = String(Number(value) / 2.20462 / sgravity / 3.785);
        break;
      case 'KG': // kg
        conversion = String(Number(value) / sgravity / 3.785);
        break;
      case 'MT': // mt
        conversion = String((Number(value) * 1000) / sgravity / 3.785);
        break;
      default:
        conversion = '';
        break;
    }
    return conversion;
  }
  public vendorconvertperUnit(sgravity, value, amount, unit, Currency, type) {
    let finalAmount: any;
    let unitConvert: any;
    if (type == 'vendor') {
      unitConvert = this.vendorconvertUnit(sgravity, value, unit);
      finalAmount = String(
        amount / Number(unitConvert) / this.fbDatabase.currencyPrices[Currency]
      );
    } else if (type == 'user') {
      finalAmount = Number(amount).toFixed(2);
    }
    return finalAmount;
  }
  public platformToClientCurrency(usd, operCur) {
    return Number(usd * this.fbDatabase.currencyPrices[operCur]);
  }
}
