import { Deserializable } from './deserializable.model';
import * as timezones from 'moment-timezone';
import { FlyingDatetimePipe } from '@app/shared/pipes/flying-datetime.pipe';
import { CommonService } from '@app/shared/services/common.service';

export class EditStatus {
  public type: string;
  public index: number;
}

export class CreateLeg {
  public fromStation: StationDetails;
  public toStation: StationDetails;
  public departDate: string;
  public depart?: string;
  public timeType: string = 'true';
  public noOfCrew: number;
  public noOfPax: number;
  public flightType: string;
  public operationType: string;
  public flightRule: string;
  public fuelPolicy: string = 'MaximumFuelPolicy';
  public adequate: string = 'Auto';
  public alt1: string;
  public alt2: string;
  public depAlt: string;
  public fuelonBoard: number;
  public cruiseSpeed: string;
  public aircraftId: string;
  public maxflightlevelUnit: string;
  public baseStationEditable: boolean;
  public tripTime: string;
  public minTime: string;
  public crusieProfile: any;
  public flightLvel: number;
  public fuelUnit: string;
  public flightLevelType: string = 'OptimumFlightLevelDefinition';
  public regd?: string;
  public minUTCtime: string;
  public minLTtime: string;
  public cargoValue?: number;
  public applyRules: string = "false";
  public fuelOnLast: FuelLastStationDetails;
  public etops: string;
  public etopsMin: number;
constructor(
    fromStationData: StationDetails,
    toStation: StationDetails,
    departDate: string,
    timeType: string,
    noOfCrew: number,
    noOfPax: number,
    flightType: string,
    operationType: string,
    flightRule: string,
    fuelPolicy: string,
    adequate: string,
    alt1: string,
    alt2: string,
    depAlt: string,
    fuelonBoard: number,
    cruiseSpeed: string,
    aircraftId: string,
    maxflightlevelUnit: string,
    baseStationEditable: boolean,
    tripTime: string,
    crusieProfile: any,
    flightLvel: number,
    fuelUnit: string,
    flightLevelType: string,
    regd?: string,
    minLTtime?: string,
    minUTCtime?: string,
    fuelOnLastStn?: FuelLastStationDetails,
    depart?: string,
    etops?: string,
    etopsMin?: string,
  ) {
    this.alt1 = alt1;
    this.alt2 = alt2;
    this.fromStation = fromStationData;
    this.toStation = toStation;
    this.fuelOnLast = fuelOnLastStn;
    this.timeType = timeType;
    this.regd = regd;
    this.etops = etops ? this.getFormatedValue(etops): this.etops ? this.etops : 'No';
    this.etopsMin = etopsMin ? parseInt(etopsMin, 10) : this.etopsMin ? this.etopsMin: null;
    if (tripTime.split(' ')[1]) {
      tripTime = tripTime.split(' ')[0] + tripTime.split(' ')[1];
    }
    if (departDate == 'default') {
      if (this.timeType == 'true') {
        this.departDate = this.convertAirporttime(
          fromStationData.timeZone,
          this.makeTripTime(5, tripTime)
        );
      } else {
        this.departDate = this.makeTripTime(5, tripTime);
      }
    } else if (departDate == 'switch') {
      if (this.timeType == 'true') {
        // tripTime += "Z";
        this.departDate = this.convertAirporttime(
          fromStationData.timeZone,
          tripTime
        );
      } else {
        this.departDate = this.covertUTCtime(
          fromStationData.timeZone,
          tripTime
        );
      }
    } else if (departDate == 'next') {
      if (this.timeType == 'true') {
        this.departDate = this.convertAirporttime(
          fromStationData.timeZone,
          this.makeTripTime(20, tripTime)
        );
      } else {
        this.departDate = this.makeTripTime(20, tripTime);
      }
    } else if (departDate == 'edit') {
      if (this.timeType == 'true') {
        this.departDate = this.convertAirporttime(
          fromStationData.timeZone,
          tripTime
        );
      } else {
        this.departDate = tripTime;
      }
      this.depart = this.convertAirporttime(
        fromStationData.timeZone,
        this.makeTripTime(5, depart)
      );
    } else if (departDate == 'add') {
      if (this.timeType == 'true') {
        this.departDate = this.convertAirporttime(
          fromStationData.timeZone,
          tripTime
        );
      } else {
        this.departDate = this.makeTripTime(20, tripTime);
      }
    }
    this.noOfCrew = Number(noOfCrew);
    this.noOfPax = Number(noOfPax);
    this.flightType = flightType;
    this.operationType = operationType;
    this.flightRule = flightRule;
    if (fuelPolicy && fuelPolicy != '') {
      this.fuelPolicy = fuelPolicy;
    }
    this.adequate = adequate;
    this.depAlt = depAlt;
    this.fuelonBoard = fuelonBoard;
    this.cruiseSpeed = cruiseSpeed;
    this.aircraftId = aircraftId;
    this.maxflightlevelUnit = maxflightlevelUnit;
    this.baseStationEditable = baseStationEditable;
    this.crusieProfile = crusieProfile;
    this.flightLvel = flightLvel;
    this.fuelUnit = fuelUnit;
    if (flightLevelType && flightLevelType != '') {
      this.flightLevelType = flightLevelType;
    }
    if (departDate != 'switch') {
      this.minLTtime = this.convertAirporttime(
        fromStationData.timeZone,
        this.departDate
      );
      this.minUTCtime = this.covertUTCtime(
        fromStationData.timeZone,
        this.departDate
      );
    } else {
      this.minLTtime = minLTtime;
      this.minUTCtime = minUTCtime;
    }
  }
  public getFormatedValue(literal: string) {
    const firstChar = literal?.charAt(0)?.toUpperCase();
    const remainingString = literal?.slice(1);
    return literal ? firstChar + remainingString : 'No';
  }
  public convertAirporttime(timezone, time) {
    if (timezone != '' && timezone) {
      return timezones.tz(time, timezone).format();
    } else {
      return timezones(time).format();
    }
  }
  public makeTripTime(addtime, time) {
    return timezones.utc(time).add(addtime, 'minutes').format();
  }
  public makeLocalTripTime(addtime, time) {
    return timezones.utc(time).add(addtime, 'minutes').format();
  }
  public covertUTCtime(timeZone, time) {
    return timezones.tz(time, timeZone).utc().format();
  }
}

export class LegInformationDeatils implements Deserializable {
  public fromStation: StationDetails;
  public toStation: StationDetails;
  public departDate: string;
  public timeType: string;
  public noOfCrew: number;
  public noOfPax: number;
  public flightType: string;
  public operationType: string;
  public stopType: string; // check
  public flightRule: string;
  public fuelPolicy: string = 'MaximumFuelPolicy';
  public adequate: string = 'Auto';
  public alt1: string;
  public alt2: string;
  public depAlt: string;
  public fuelonBoard: number;
  public etops: string;
  public flightLvelalt1: number;
  public flightLvelalt2: number;
  public cruiseSpeed: string;
  public aircraftId: string;
  public maxflightlevelUnit: string;
  public route: any;
  public arrivalTime: any;
  public serviceProvider: any[];
  public BLKTIME: string;
  public legRouteid: string;
  public fuelQuantity?: any
  public flightPlanresponseID: string;
  public overflyingCountry = [];
  public fuelOnLast: FuelLastStationDetails;
  public travelDistrance: number;
  public ETDGMT: string;
  public ETAGMT: string;
  public ETALT: string;
  public ETDLT: string;
  public vendorSelectionCompleted: boolean;
  public legId: number;
  // it will done by model
  public sector: string;
  public flightLevelType: string = 'OptimumFlightLevelDefinition';
  public regd?: string;
  public flightLvel: number;
  public extrafuel: number;
  public fuelUnit: string = 'Pound';
  public baseStationEditable: boolean;
  // this below two will come from route
  public GMTPLUSORIGIN: string;
  public GMTPLUSDEST: string;
  // it will assign later
  public DraftEstimateId: number;
  // leg changes
  // if leg affecting after any changes
  public legAffected: boolean;
  // is the leg is able to edit or not
  public legEditable: boolean;
  // status of the leg
  public legStatus: string;
  // is the route able to edit
  public routeEditable: boolean;
  // if the leg modified by time or station then it will be true its for backend
  public modifiedLeg: boolean;
  // if the edit button clicked of the leg
  public legOnHighlight: boolean;
  // to add a leg befor the selecetd leg
  public addLeg: boolean;
  // srid for the legchange time
  public srid?: number;
  // if the leg delete at the time of edit then it will be true
  public removed?: boolean = false;
  // the id coming from backend after trip posting
  public backendLegId?: number;
  // cann't do any oparation with thisleg
  public noOperation: boolean;
  // vendor editable i.e if any modification happen then it will be true
  public vendorEditable: boolean;
  // either leg got some change opr not
  public gotModified: boolean;
  public depart: string;
  public advancedEtops: string;
  public advancedEtopsMin: number;
  constructor(
    fromStationData?: StationDetails,
    toStation?: StationDetails,
    departDate?: string,
    timeType?: string,
    noOfCrew?: number,
    noOfPax?: number,
    flightType?: string,
    operationType?: string,
    stopType?: string,
    flightRule?: string,
    fuelPolicy?: string,
    adequate?: string,
    alt1?: string,
    alt2?: string,
    depAlt?: string,
    fuelonBoard?: number,
    etops?: string,
    flightLvelalt1?: number,
    flightLvelalt2?: number,
    cruiseSpeed?: string,
    aircraftId?: string,
    maxflightlevelUnit?: string,
    route?: any,
    arrivalTime?: any,
    serviceProvider?: any,
    BLKTIME?: string,
    legRouteid?: string,
    flightPlanresponseID?: string,
    overflyingCountry?: any,
    travelDistrance?: number,
    ETDGMT?: string,
    ETAGMT?: string,
    ETALT?: string,
    ETDLT?: string,
    vendorSelectionCompleted?: boolean,
    legId?: number,
    flightLevelType?: string,
    regd?: string,
    flightLvel?: number,
    extrafuel?: number,
    fuelUnit?: string,
    baseStationEditable?: boolean,
    legAffected?: boolean,
    legStatus?: string,
    modifiedLeg?: boolean,
    legOnHighlight?: boolean,
    addLeg?: boolean,
    backendLegId?: number,
    gotModified?: boolean,
    srid?: number,
    advancedEtops?: string,
    advancedEtopsMin?: string,
    removed?: boolean,
    fuelOnLastStn?: FuelLastStationDetails,
    depart?: string
  ) {
    this.fromStation = fromStationData;
    this.toStation = toStation;
    this.fuelOnLast = fuelOnLastStn;
    this.departDate = departDate;
    this.timeType = timeType;
    this.noOfCrew = noOfCrew;
    this.noOfPax = noOfPax;
    this.flightType = flightType;
    this.operationType = operationType;
    this.stopType = stopType;
    this.flightRule = flightRule;
    this.fuelPolicy = fuelPolicy;
    this.adequate = adequate;
    this.alt1 = alt1;
    this.alt2 = alt2;
    this.depAlt = depAlt;
    this.fuelonBoard = fuelonBoard;
    this.etops = etops;
    this.flightLvelalt1 = flightLvelalt1;
    this.flightLvelalt2 = flightLvelalt2;
    this.cruiseSpeed = cruiseSpeed;
    this.aircraftId = aircraftId;
    this.maxflightlevelUnit = maxflightlevelUnit;
    this.route = route;
    this.arrivalTime = arrivalTime;
    this.GMTPLUSORIGIN = route.timezonedetails.UTC1;
    this.GMTPLUSDEST = route.timezonedetails.UTC2;
    this.serviceProvider = serviceProvider;
    this.BLKTIME = BLKTIME;
    this.legRouteid = legRouteid;
    this.flightPlanresponseID = flightPlanresponseID;
    this.overflyingCountry = overflyingCountry;
    this.travelDistrance = travelDistrance;
    this.ETDGMT = ETDGMT;
    this.ETAGMT = ETAGMT;
    this.ETALT = ETALT;
    this.ETDLT = ETDLT;
    this.vendorSelectionCompleted = vendorSelectionCompleted;
    this.legId = legId + 1;
    this.sector =
      this.fromStation.name.substr(0, 4) +
      '-' +
      this.toStation.name.substr(0, 4);
    if (flightLevelType && flightLevelType != '') {
      this.flightLevelType = flightLevelType;
    }
    this.regd = regd;
    this.flightLvel = flightLvel;
    this.extrafuel = extrafuel;
    this.fuelUnit = fuelUnit;
    this.srid = srid ? srid : this.srid;
    this.advancedEtops = advancedEtops ? this.getFormatedValue(advancedEtops): this.advancedEtops;
    this.advancedEtopsMin = advancedEtopsMin ? parseInt(advancedEtopsMin, 10): this.advancedEtopsMin;
    this.removed = removed ? true : this.removed;
    // if it coming from activated leg
    if (backendLegId) {
      this.backendLegId = backendLegId;
    } else {
      this.backendLegId = 0;
    }
    this.gotModified = gotModified;
    // logic only for leg change code by saroj
    this.legAffected = legAffected;
    this.legEditable = this.legEditableStatus(legStatus);
    this.depart = depart;
    if (legStatus == 'newleg') {
      this.routeEditable = true;
    } else {
      this.routeEditable = false;
    }
    if (legStatus == 'newleg' || gotModified) {
      this.vendorEditable = true;
    } else {
      this.vendorEditable = false;
    }
    this.noOperation = this.legnooperation(legStatus, legAffected);
    this.legStatus = legStatus;
    this.modifiedLeg = modifiedLeg;
    this.legOnHighlight = legOnHighlight;
    this.baseStationEditable = baseStationEditable;
    this.addLeg = addLeg;
  }
  public getFormatedValue(literal: string) {
    const firstChar = literal?.charAt(0)?.toUpperCase();
    const remainingString = literal?.slice(1);
    return literal ? firstChar + remainingString : 'No';
  }
  public deserialize(input: any): this {
    Object.assign(this, input);
    this.fromStation = new StationDetails(
      input.fromStation.name,
      input.fromStation.timeZone
    ).deserialize(input.fromStation);
    this.toStation = new StationDetails(
      input.toStation.name,
      input.toStation.timeZone
    ).deserialize(input.toStation);
    return this;
  }
  public legEditableStatus(status: string): boolean {
    let returnStatus: boolean = false;
    switch (status) {
      case 'newleg':
        returnStatus = true;
        break;
      case 'Processing':
        returnStatus = true;
        break;
      case 'Confirmed':
        returnStatus = true;
        break;
      case 'Inprogressstarting':
        returnStatus = false;
        break;
      case 'Inprogressfinishing':
        returnStatus = false;
        break;
      case 'Inprogressfinished':
        returnStatus = false;
        break;
      case 'Completed':
        returnStatus = false;
        break;
      case 'Closed':
        returnStatus = false;
        break;
      case 'Cancelled':
        returnStatus = false;
        break;
    }
    return returnStatus;
  }

  public legAffectedStatus(status: string): boolean {
    let returnStatus: boolean = false;
    switch (status) {
      case 'newleg':
        returnStatus = false;
        break;
      case 'editleg':
        returnStatus = true;
        break;
      default:
        returnStatus = false;
        break;
    }
    return returnStatus;
  }
  public legrouteEditable(status: string): boolean {
    let returnStatus: boolean = false;
    switch (status) {
      case 'newleg':
        returnStatus = true;
        break;
      case 'editleg':
        returnStatus = true;
        break;
      case 'Inprogress':
        returnStatus = false;
        break;
      case 'Upcoming':
        returnStatus = true;
        break;
      case 'Completed':
        returnStatus = false;
        break;

      default:
        returnStatus = false;
        break;
    }
    return returnStatus;
  }

  public legvendorEditable(status: string): boolean {
    let returnStatus: boolean = false;
    switch (status) {
      case 'newleg':
        returnStatus = true;
        break;
      case 'editleg':
        returnStatus = true;
        break;
      case 'Inprogress':
        returnStatus = false;
        break;
      case 'Upcoming':
        returnStatus = false;
        break;
      case 'Completed':
        returnStatus = false;
        break;

      default:
        returnStatus = false;
        break;
    }
    return returnStatus;
  }

  public legnooperation(status: string, affected: boolean): boolean {
    let returnStatus: boolean = false;
    if (affected) {
      returnStatus = true;
    } else {
      switch (status) {
        case 'newleg':
          returnStatus = false;
          break;
        case 'Processing':
          returnStatus = false;
          break;
        case 'Confirmed':
          returnStatus = false;
          break;
        case 'Inprogressstarting':
          returnStatus = false;
          break;
        case 'Inprogressfinishing':
          returnStatus = true;
          break;
        case 'Inprogressfinished':
          returnStatus = true;
          break;
        case 'Completed':
          returnStatus = true;
          break;
        case 'Closed':
          returnStatus = true;
          break;
        case 'Cancelled':
          returnStatus = true;
          break;
      }
    }

    return returnStatus;
  }
}
export class StationDetails implements Deserializable {
  public name: string;
  public timeZone: string;
  public Latitude?:any;
  public Longitude?:any;
  constructor(name: string, timeZone: string ,item?) {
    this.name = name;
    this.timeZone = timeZone;
    this.Latitude = item?.Latitude ? item.Latitude :'';
    this.Longitude = item?.Longitude?  item.Longitude:'';
  }
  public deserialize(input: any): this {
    return Object.assign(this, input);
  }
}
export class FuelLastStationDetails implements Deserializable {
  public fuelLastStnReq: string;
  public fuelQuantity: any;
  public fuelUnit: string;
  public lastStation: string;
  constructor(
    fuelLastStnReq: string,
    fuelQuantity: any,
    fuelUnit: string,
    lastStation: string
  ) {
    this.fuelLastStnReq = fuelLastStnReq;
    this.fuelQuantity = fuelQuantity;
    this.fuelUnit = fuelUnit;
    this.lastStation = lastStation;
  }
  public deserialize(input: any): this {
    return Object.assign(this, input);
  }
}
export class ServiceVendorModel {
  public name: string;
  public logo: string;
  public price: number;
  public vendorId: any;
  public UID: string;
  public sector: string;
  public station: string;
  public chargeId: number;
  public serviceName: string;
  public backendFormat: any;
  public legId: number;
  public autoselect: any;
  public clickable: any;
  public actionItems: string;
  public usdPrice?:number
  public responseMessage?:string;
  public rating?:number
  constructor({
    name,
    logo,
    price,
    vendorId,
    UID,
    sector,
    station,
    chargeId,
    serviceName,
    backendFormat,
    legId,
    autoselect,
    clickable,
    actionItems,
    usdPrice,
    rating
  }) {
    this.name = name;
    this.logo = logo;
    this.price = Number(price);
    this.vendorId = vendorId;
    this.UID = UID;
    this.sector = sector;
    this.station = station;
    this.chargeId = chargeId;
    this.serviceName = serviceName;
    this.backendFormat = backendFormat;
    this.legId = legId;
    this.autoselect = autoselect;
    this.clickable =
      !clickable || (autoselect == 'true' && autoselect != undefined)
        ? true
        : false;
    this.actionItems = actionItems;
    this.usdPrice = usdPrice
    this.rating = rating
  }
}
export class PermitServiceVendors extends ServiceVendorModel {
  public TypeofVendor: string;
  constructor({ TypeofVendor, ...rest }) {
    super(rest as any);
    this.TypeofVendor = TypeofVendor;
  }
}

export class LoginDetails {
  public UserID: number;
  public EmailID: string;
  public Password: string;
  public RoleID: number;
  public Status: string = '';
  public CreatedDate: string;
  public LastVisit: string;
  public verificationcode: string = '';
  public firebaseuid: string;
  public refreshtoken: string = '';
  public devicetoken: string = '';
}
export class HandlerServiceVendors extends ServiceVendorModel {
  public TypeofVendor: string;
  public ResellerName: string;
  public Handlername: string;
  constructor({ TypeofVendor, ResellerName, Handlername, ...rest }) {
    super(rest as any);
    this.TypeofVendor = TypeofVendor;
    this.ResellerName = ResellerName;
    this.Handlername = Handlername;
  }
}
export class AirportServiceVendors extends ServiceVendorModel {
  public TypeofVendor: string;
  public ResellerName: string;
  constructor({ TypeofVendor, ResellerName, ...rest }) {
    super(rest as any);
    this.TypeofVendor = TypeofVendor;
    this.ResellerName = ResellerName;
  }
}
export class SelfServiceVendor extends HandlerServiceVendors {
  public responseMessage: any;
  public sharray: any;
  constructor({ sharray, responseMessage, ...rest }) {
    super(rest as any);
    this.sharray = sharray;
    this.responseMessage = responseMessage;
  }
}
export class Vendor implements Deserializable {
  public name: string;
  public logo: string;
  public price: number;
  public vendorId: any;
  public UID: string;
  public sector: string;
  public station: string;
  public chargeId: number;
  public serviceName: string;
  public legId: number;
  public backendFormat: any;
  public sharray: any;
  public clickable?: boolean;
  public responseMessage?: any;
  public carbonoffsetstatus?: number;
  public actionItems?: string;
  public Totalprice?:number;
  public rating?:any
  constructor(
    name: string,
    logo: string,
    price: number,
    vendorId: any,
    UID: string,
    sector: string,
    station: string,
    chargeId: number,
    serviceName: string,
    backendFormat: any,
    legId: number,
    sharray?: any,
    ResellerName?: string,
    Handlername?: string,
    TypeofVendor?: string,
    clickable?: boolean,
    responseMessage?: boolean,
    carbonoffsetstatus?: number,
    actionItems?: string,
    rating?:number
  ) {
    this.name = name;
    this.logo = logo;
    this.price = Number(price);
    this.vendorId = vendorId;
    this.UID = UID;
    this.sector = sector;
    this.station = station;
    this.chargeId = chargeId;
    this.serviceName = serviceName;
    this.backendFormat = backendFormat;
    this.legId = legId;
    this.carbonoffsetstatus = carbonoffsetstatus;
    if (sharray) {
      this.sharray = sharray;
    }
    if (!clickable) {
      this.clickable = true;
    } else {
      this.clickable = false;
    }
    this.responseMessage = responseMessage;
    this.actionItems = actionItems,
    this.rating = rating // temp rating
  }
  public deserialize(input: any): this {
    return Object.assign(this, input);
  }
}

export class StationParams {
  public backpage: any;
  public stationType: string;
  public destination?: string;
  // list?:any;
  // preselected?:any;
  // category?:number;//vendorFlighPlanning=1

  constructor(backpage: any, stationType: string, destination?: string) {
    this.backpage = backpage;
    this.stationType = stationType;
    this.destination = destination;
    // this.list=list;
    // this.preselected=preselected;
    // this.category=category;
  }
}

export class DownloadFormat {
  public fromStation: string;
  public toStation: string;
  public timeType: string = 'true';
  public departDate: string;
  public crew: number;
  public pax: number;
  public flightType: string;
  public operationType: string;
  public aircraft: string;
  public flightRule: string;
  public fromStationTimeZone: string;
  public toStationTimeZone: string;
  public BLKTIME: string;
  public DIST: number;
  public ETDGMT: string;
  public ETAGMT: string;
  public ETALT: string;
  public ETDLT: string;
  public id: number;
  public sector: string;
  public DEPTTIMELOCAL: string;
  public DEPTTIMEUTC: string;
  public ARRTIMELOCAL: string;
  public ARRTIMEUTC: string;

  constructor(
    scheduleInformation: LegInformationDeatils,
    public flyingDatetimeProvider: FlyingDatetimePipe,
    public commonServiceProvider: CommonService
  ) {
    this.fromStation = scheduleInformation.fromStation.name;
    this.toStation = scheduleInformation.toStation.name;
    this.timeType = scheduleInformation.timeType;
    this.crew = scheduleInformation.noOfCrew;
    this.departDate = scheduleInformation.departDate;
    this.pax = scheduleInformation.noOfPax;
    this.flightType = scheduleInformation.flightType;
    this.aircraft = scheduleInformation.aircraftId;
    this.operationType = scheduleInformation.operationType;
    this.flightRule = scheduleInformation.flightRule;
    this.fromStationTimeZone = scheduleInformation.fromStation.timeZone;
    this.toStationTimeZone = scheduleInformation.toStation.timeZone;
    this.BLKTIME = scheduleInformation.BLKTIME;
    this.DIST = scheduleInformation.travelDistrance;
    this.ETDGMT = scheduleInformation.ETDGMT;
    this.ETAGMT = scheduleInformation.ETAGMT;
    this.ETALT = scheduleInformation.ETALT;
    this.ETDLT = scheduleInformation.ETDLT;
    this.id = scheduleInformation.legId;
    this.sector = scheduleInformation.sector;
  }
}

export class UserAircraft implements Deserializable {
  public baseLocation: string;
  public crew = [];
  public pax = [];
  public flightType: string;
  public operationType: string;
  public flightRule: string;
  public fuel: string;
  public cruiseSpeed: string;
  public etops: string;
  public flightLvel: number;
  public maxflightlevelUnit: string;
  public flightlevel: number;
  public aircraft: string;
  public id?: string;
  public crusieProfile: any;
  public aircarftdata: any;
  public timezone: any;
  public extrafuel: number;
  public fuelPolicy: string;
  public fuelUnit: string;
  public registerCode: string;
  public regd: string;
  public flightLevelType: string;
  public minimumRunwayLength: number;
  public etopsMinutes: string;
  public etopsEnable: string;

  constructor(aircarftlist: any) {
    this.baseLocation = aircarftlist.Base;
    this.id = aircarftlist.DT_RowId;
    this.timezone = aircarftlist.AirportTimezone;
    this.flightLvel = aircarftlist.MFLevel;
    this.maxflightlevelUnit = aircarftlist.MaxflightlevelUnit;
    this.flightType = aircarftlist.flightType;
    this.operationType = aircarftlist.OperationName;
    this.flightRule = aircarftlist.FlightRule;
    this.extrafuel = aircarftlist.FuelBurn;
    this.fuelUnit = aircarftlist.FuelUnit;
    this.etops = aircarftlist.EtopsEnable;
    this.cruiseSpeed = aircarftlist.NCM;
    this.crusieProfile = aircarftlist.CruiseProfiles;
    this.flightLevelType = aircarftlist.flightLvelType;
    this.registerCode = aircarftlist.CountryPrefix.RegCode.split('-').join('');
    this.regd = aircarftlist.Regd;
    this.minimumRunwayLength = aircarftlist.minimumRunwayLength;
    this.etopsEnable = aircarftlist.EtopsEnable;
    this.etopsMinutes = aircarftlist.ETOPSMinutes;
    while (aircarftlist.Crewcap > 0) {
      this.crew.push(Number(aircarftlist.Crewcap));
      aircarftlist.Crewcap--;
    }
    while (aircarftlist.Paxcap > -1) {
      this.pax.push(Number(aircarftlist.Paxcap));
      aircarftlist.Paxcap--;
    }
  }
  public deserialize(input: any): this {
    return Object.assign(this, input);
  }

}
export interface GetQuoteResponse {
  ResponseCode: string;
  APIName: string;
  GetQuote: any[];
  permissions: any[];
  role: any[];
  ResponseStatus: string;
}
