import { Injectable } from '@angular/core';
import { ServerConnectService } from '@app/core/services/server-connect.service';
import { ApiTypes } from '../constants/constant';
import { ApiConfig } from '../constants/url-constant';

@Injectable({
  providedIn: 'root',
})
export class VendordetailsService {
  public apiType = ApiTypes;
  constructor(private serverConnectService: ServerConnectService) { }

  public getVendorInfo(vendorid, Afterposting, type, firebaseuid = "") {
    return new Promise((resolve) => {
      const databody =
        'vendorid=' +
        vendorid +
        '&Afterposting=' +
        Afterposting +
        '&type=' +
        type +
        "&firebase_uid=" +
        firebaseuid;
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          ApiConfig.getVendorInfo,
          databody,
          false,
          false,
          false
        )
        .then((value: any) => {
          resolve(value.GetVendorInfo);
        })
        .catch((error) => {
          resolve('Error');
        });
    });
  }

  public getResellerInfo(srSecId, srId, sector,location,vendorId,serviceName) {
    let backendServicename: string;
    switch (serviceName) {
      case 'Flight Planning':
        backendServicename = 'crew briefing';
        break;
      case 'Overflight Permits':
        backendServicename = 'permits';
        break;
      case 'Fuel':
        backendServicename = 'fuel';
        break;
      case 'Dep Handling':
      case 'Arr Handling':
      case 'Handling':
        backendServicename = 'groundhandling';
        break;
      case 'Dep Apt Fees & Permits':
      case 'Arr Apt Fees & Permits':
      case 'Apt Fees & Permits':
        backendServicename = 'airportcharges';
        break;
    }
    return new Promise((resolve) => {
      const databody =
        'SRSecID=' +
        srSecId +
        '&SRID=' +
        srId +
        '&Sector=' +
        sector +
        '&location='+
        location +
        '&vendorID='+
        vendorId +
        '&serviceName='+
        backendServicename;
      this.serverConnectService
        .connectToServer(
          this.apiType.POST,
          ApiConfig.getResellerInfo,
          databody,
          false,
          false,
          false
        )
        .then((value: any) => {
          resolve(value.GetResellerInfo);
        })
        .catch((error) => {
          resolve('Error');
        });
    });
  }
}
