import { Station } from './../model/create-schedule';
import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '@app/shared/services/common.service';
import { CreateScheduleService } from '@app/shared/services/create-schedule.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-schedule-weather-notams',
  templateUrl: './schedule-weather-notams.component.html',
  styleUrls: ['./schedule-weather-notams.component.scss'],
})
export class ScheduleWeatherNotamsComponent implements OnInit {
  @Input('station') public station: Station;
  public wxWeather: any;
  public title: string = "Weather/Notams/Airport Details";
  // public back = () => { this.modalController.dismiss() };
  constructor(public commonService: CommonService,
    public scheduleService: CreateScheduleService, public modalController: ModalController) { }

  public ngOnInit() {}
    public back() {
    this.modalController.dismiss();
  }
}
