export const Messageconfig = {
  Alert: 'Alert',
  TripErrorMessage: 'Your trip has been expired',
  CancelButton: 'cancel',
  OkButton: 'ok',
  Tryagain: 'Tryagain',
  SameStation: 'Departure and destination cannot be same.',
  DepAlternate: 'Please select the departure to get the alternate.',
  CruiseSpeed: 'Please select the cruise speed to get the route.',
  ToStation: 'Please select the destination to get the route.',
  DeleteLeg: 'Are you sure you want to delete Sector?',
  Confirmation: 'Confirm',
  OkBtn: 'Ok',
  ClearIternary: 'Are you sure you want to clear Schedule?',
  NoAircraft: 'No Aircraft available, Please register an aircraft.',
  InactiveAircraft:
    'Your aircraft is inactived. Please activate it to continue.',
  PreferredVendor:
    'Please wait while we fetch Preferred Service Providers from our Marketplace...',
  selectAtleastOne: 'Please select at least one service.',
  ActivateTrip: 'Are you sure you want to Activate Trip?',
};

export const NewTripError = {
  RouteError:
    'The requested route could not be generated. Please change the origin and/or destination and try again.',
  GeneRoutemessage: 'Please wait while we generate a Flight Plan...',
  GeneReviseRouteMessage:'Please wait while we Revising a Flight Plan...',
  GeneEstimatemessage: 'Please wait while we generate a Trip Quote...',
  RegisterTripLoader: 'Activating Trip...',
  EstimateText: 'Saving trip cost estimate.',
  InsufficientBalance:
    'Insufficient balance. Required amount to activate trip is <b>funds </b> USD',
  pageload: 'Loading...',
};
export const sectorTripMessages = [
  'Please wait we are generating Navlog',
  'Please wait while we are fetching Permit Lead Times and Airport, FBO Operational Hours.',
];
export const messageForAvailableAircraft = [
  'Please wait we are generating Navlog',
  'Please wait while we are fetching Permit Lead Times and Airport, FBO Operational Hours.',
  'Please wait while we are calculating Trip Cost Estimate'
];
export const reviseTripMessages = [
  'Please wait we are Revising Navlog',
  'Please wait while we are Recomputing the cost',
];
export const fecthingCost = [
  'Please wait while we are fetching the Service Providers and Cost',
];
export const OperationType = [
  {
    name: 'Commercial',
    value: 'Commercial',
  },
  {
    name: 'Charter',
    value: 'Charter',
  },
  {
    name: 'Cargo',
    value: 'Cargo',
  },
  {
    name: 'Private',
    value: 'Private',
  },
  {
    name: 'Ambulance',
    value: 'Ambulance',
  },
];

export const FlightRule = [
  {
    name: 'IFR',
    value: 'IFR',
  },
  {
    name: 'VFR',
    value: 'VFR',
  },
];

export const FuelPolicy = [
  {
    name: 'OPT',
    value: 'MaximumFuelPolicy',
  },
  {
    name: 'Actual',
    value: 'ActualFuelPolicy',
  },
  {
    name: 'Max',
    value: 'MaximumTankFuelPolicy',
  },
];

export const FlightType = [
  {
    name: 'N - Non Scheduled',
    value: 'NS',
  },
  {
    name: 'S - Scheduled',
    value: 'S',
  },
  {
    name: 'G - General',
    value: 'G',
  },
  {
    name: 'M - Military',
    value: 'M',
  },
  {
    name: 'X - Other',
    value: 'X',
  },
];
export const Adequate = [
  {
    name: 'Auto',
    value: 'Auto',
  },
  {
    name: 'Manual',
    value: 'Manual',
  },
  {
    name: 'False',
    value: 'False',
  },
];

export const ETOPSType = [
  {
    name: 'ETOPS',
    value: 'yes',
  },
  {
    name: 'NON-ETOPS',
    value: 'no',
  },
];
export const FlightWeightUnit = [
  {
    name: 'kg',
    value: 'KG',
  },
  {
    name: 'lbs',
    value: 'Pound',
  },
];
export const MaxflightlevelUnit = [
  {
    name: 'FL',
    value: 'Feet',
  },
  // {
  //   name: 'M',
  //   value: 'Meter'
  // }
];
export const FlightLevelType = [
  {
    name: 'OPT',
    value: 'OptimumFlightLevelDefinition',
  },
  // {
  //   name: "MAX",
  //   value: "MaximumFlightLevelSpecification",
  // },
  {
    name: 'Actual',
    value: 'ExactFlightLevelSpecification',
  },
];
export const ConstantNames = {
  Frombtn: 'From',
  ToBtn: 'To',
  displayFormat: 'YYYY MMM DD, HH:mm',
  tripDateFormat: 'EEEE MMM d y h:mm',
  AircraftName: 'Aircraft',
  CrewName: 'Crew',
  PaxName: 'Pax',
  Alt1: 'ALTN 1',
  Alt2: 'ALTN 2',
  CruiseAlt: 'FL',
  Cruisespd: 'SPD',
  Fuel: 'Fuel',
  FlightRule: 'Flight Rule',
  FlightType: 'Flight Type',
  OperationType: 'Operation Type',
  ToALTN: 'T/O ALTN',
  changeDestination: 'Please change the departure of next leg and try again.',
  ETOPS:'ETOPS',
  ETOPSVALUES: ['Yes', 'No'],
  ETOPSTIMES: [60, 90, 120, 180]
};
export enum ShortNotice {
  stringLiteralPermit = "PermitsLeadData",
  actionItmSelfLiteral = "actionitemsforself",
  charterStatusLiteral = "CharterStatus",
  GetRouteDetailsLiteral = "GetRouteDetails"
}
export interface FromStation {
  name: string;
  timeZone: string;
}

export interface ToStation {
  name: string;
  timeZone: string;
}

export interface ServiceProvider {
  ServiceName: string;
  serviceName: string;
  name: string;
  logo: string;
  price: number;
  chargeId: string;
  UID: string;
  sector: string;
  station: string;
  vendorId: string;
  backendFormat: any;
  sharray: string[];
  legId: number;
  rating?: any;
  Totalprice?: number;
  Fuelqunitityinpound: string;
  TypeofVendor: string;
  carbonoffset?: number;
  Fixedarray: any[];
  Percentage: any[];
  Perunit: any[];
  Surchargestype: any[];
  Station: string;
  ChargeID: string;
  Fuelprice?: number;
  rateprice?: number;
  basichandlingstatus: string;
  legstatus: string;
  WeekendSurcharge?: number;
  PeakperiodSurcharge?: number;
  PeakHourSurcharge?: number;
  OutsideAirportOperationalHourSurcharge?: number;
  OutsideOfficeOperationalHourSurcharge?: number;
  NightSurcharge?: number;
  SupervisionCharge?: number;
  Communication?: number;
  HolidaySurcharge?: number;
  ThirdPartyDisbursement?: number;
  RoyaltyCharges?: number;
  firebase_uid: string;
  AircraftID: string;
  BasicPrice: any;
  vendorid: string;
  Landingsurcharges: any;
  Parkingsurcharges: any[];
  numberofpax?: number;
  acprice?: number;
  VendorName: string;
  registertype: string;
  parkingfee?: number;
  landingfee: any;
  DepartureSlot?: number;
  DepartureshortSlot: string;
  DepartureSlotID: string;
  ArrivalSlot?: number;
  ArrivalshortSlot: string;
  ArrivalSlotID: string;
  LandingPermit?: number;
  LandingPermitID: string;
  Landingshort: string;
}

export interface LegList {
  from: string;
  to: string;
  depart: Date;
  timeZone: string;
  clientseltimeZone: string;
  flightRule: string;
  flightType: string;
  operationType: string;
  BLKTIME: string;
  ETDGMT: string;
  ETDLT: string;
  ETAGMT: string;
  ETALT: string;
  routeID: number;
  TOTALBLKTIME: number;
  crew: number;
  pax: number;
  ETOPS: string;
  fromStation: FromStation;
  toStation: ToStation;
  departDate: Date;
  timeType: string;
  etops: string;
  aircraftId: string;
  legRouteid: number;
  flightPlanresponseID: number;
  sector: string;
  serviceProvider: ServiceProvider[];
}

export interface StationServiceArray {
  sector: string;
  station: string;
  service: string;
}

export interface SectorServiceArray {
  sector: string;
  service: string;
}

export interface GetNewQuote {
  legList: LegList[];
  TotalPrice: number;
  CharterPrice: number;
  StationServiceArray: StationServiceArray[];
  SectorServiceArray: SectorServiceArray[];
}

export interface GetNewQuoteResponse {
  ResponseCode: string;
  APIName: string;
  GetNewQuote: GetNewQuote;
  ResponseStatus: string;
  GetQuote?:GetNewQuote;
}

export const GetQuoteServiceName ={
  flightPlanning:'Crew Briefing',
  overflyingPermit:'Overflying Permits',
  groundHandling:'Ground Handling',
  fuel:'Fuel',
  AirPermissions:'AirPermissions'
}