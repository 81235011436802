import { Injectable } from '@angular/core';
import { MessageConfig } from '@app/shared/constants/constant';
import {
  LoadingController,
  ToastController,
  AlertController,
  Platform,
} from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';
import { find } from 'lodash';
import { ApiConfig } from '@app/shared/constants/url-constant';
// import {
//   Push,
//   PushObject,
//   PushOptions,
// } from '@awesome-cordova-plugins/push/ngx';
type CurrentPlatform = 'browser' | 'cordova';
import { PushNotifications, Token } from '@capacitor/push-notifications';
import {
  Capacitor
} from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  public token: any;
  public actualURL: string;
  public flightPlanningURL: string;
  public user: any;
  public isDevice: boolean = false;
  private spinner: any;
  public currentplatform: CurrentPlatform;
  public deviceToken: string = '';
  public registrationType: string = '';
  constructor(
    public angularFireAuth: AngularFireAuth,
    private loader: LoadingController,
    private alertCtrl: AlertController,
    private toastCtrl: ToastController,
    public platform: Platform,
  ) {  }

  public serverConnect(method: string, ur: string, data: string) {
    this.actualURL = '';
  }


  public detectMobileOrNative(): boolean {
    if (Capacitor.isNativePlatform()) {
      // If it's a native app (Android or iOS)
      return true;
    } else {
      // If it's a mobile browser
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
  }
  public initPush() {
    if (Capacitor.isNativePlatform()) {
      this.registerPush();
    }
  }
  private registerPush() {
    PushNotifications.requestPermissions().then(permission => {
        if (permission.receive === 'granted') {
            PushNotifications.register();
        }
        else {
            // If permission is not granted
        }
    });
    PushNotifications.addListener('registration', (token: Token) => {
        this.deviceToken = token.value;
        this.registrationType  =  Capacitor.getPlatform() === 'ios'
        ? 'APNS':'FCM'
        // Determine the registration type based on the platform
    });
    PushNotifications.addListener('registrationError', (err)=> {
    });

    PushNotifications.addListener('pushNotificationReceived',(notifications) => {
      // alert('notifications')
    })
}

  // use showLoginAlert to ErrorAlert
  public async errorAlert(title, message) {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custoclassm-',
      header: title ? title : MessageConfig.Alert,
      subHeader: message,
      buttons: ['OK'],
    });
    await alert.present();
  }
  public async showConfirmAlert(
    message,
    buttonName?: string,
    title?: string,
    cancelButtonName?: string
  ) {
    return new Promise((resolve) => {
      const alert = this.alertCtrl.create({
        header: title,
        message,
        backdropDismiss:false,
        buttons: [
          {
            text: cancelButtonName
              ? cancelButtonName
              : MessageConfig.CancelButton,
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => { },
          },
          {
            text: buttonName ? buttonName : MessageConfig.OkBtn,
            role: buttonName ? buttonName : 'ok',
            handler: () => {
              resolve(true);
            },
          },
        ],
      });
    });
  }

  public async showAlertConfirm() {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: 'Confirm!',
      message: 'Invalid Role Id',
      backdropDismiss:false,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => { },
        },
        {
          text: 'Okay',
          handler: () => { },
        },
      ],
    });
    await alert.present();
  }

  // without time duration toast message
  public showToastCtrl(messageType) {
    this.toastCtrl
      .create({
        message: messageType,
        position: 'bottom',
        cssClass: 'toast-custom-wrapper',
        buttons: [
          {
            side: 'end',
            text: 'OK',
            role: 'cancel',
            handler: () => { },
          },
        ],
      })
      .then((toast) => {
        toast.present();
      });
  }

  // toast controller for time duration.
  public async showToastCtrlMessage(
    str: string,
    position?: 'bottom',
    eventEnabled = false
  ) {
    this.toastCtrl
      .create({
        message: str,
        position: 'bottom',
        cssClass: 'toast-custom-wrapper',
        duration: 5000, // Duration in milliseconds
        buttons: [
          {
            side: 'end',
            text: 'OK',
            role: 'cancel',
            handler: () => { },
          },
        ],
      })
      .then((toast) => {
        toast.present();
      });
  }
  // for core module
  public async showSpinnerLoader(message?: string) {
    delete this.spinner;
    this.spinner = await this.loader.create({
      spinner: 'lines',
      cssClass: message ? 'backgroud-box' : 'custom-loading',
      message: message ? message : '',
      backdropDismiss: true,
    });
    await this.spinner.present();
  }
  public async dismissLoader() {
    this.checkAndCloseLoader();
    setTimeout(() => this.checkAndCloseLoader(), 1000);
  }
  public async checkAndCloseLoader() {
    const loader = await this.loader.getTop();
    if (loader !== undefined) {
      await this.loader.dismiss();
    }
  }

  /** platform code start
   *  example >> this.commonService.setCurrentPlatform();
   * if (this.utilService.currentplatform == "cordova"){}
   */
  public isNative() {
    return Capacitor.isNativePlatform();
  }
  public isBrowser() {
    return Capacitor.getPlatform() === 'web';
  }
  public isIOS() {
    return Capacitor.getPlatform() === 'ios';
  }
  public isAndroid() {
    return Capacitor.getPlatform() === 'android';
  }
  get currentPlatform() {
    return this.currentplatform;
  }
  // platform code end
  // get the user token
  public generateToken(apiName?: string) {
    return new Promise((resolve, reject) => {
      if (apiName == ApiConfig.ForgetPasswordMail || apiName.includes(ApiConfig.optVerification) ) {
        resolve(true);
      }
      this.angularFireAuth.authState.subscribe((userDetails) => {
        if (userDetails) {
          userDetails.getIdToken().then((data) => {
            this.token = data;
            resolve(this.token);
          });
        } else {
          this.token = null;
        }
      });
    });
  }
}
