import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameSurcharges'
})
export class GetSurchargesByOrderPipe implements PipeTransform {

  public surchargesTypes = [];
  public surchargeObj = {
    title: '',
    order: null
  }
  public transform(surchargeName?: string, args?: string[]): any {
    return this.getKeys(surchargeName);
  }
  public getKeys(key) {
    switch (key) {
      case 'WeekendSurcharge':
        key = 'Weekend Surcharge';
        break;
      case 'OutsideAirportOperationalHourSurcharge':
        key = 'Outside Airport Operational Hour Surcharge';
        break;
      case 'OutsideOfficeOperationalHoursSurcharge':
        key = 'Outside Office Operational Hours Surcharge';
        break;
      case 'NightSurcharge':
        key = 'Night Surcharge';
        break;
      case 'SupervisionCharges':
        key = 'Supervision Charges';
        break;
      case 'ThirdPartyDisbursement':
        key = 'Third Party Disbursement';
        break;
      case 'Communication':
        key = 'Communication';
        break;
      case 'holidayslistdetails':
        key = 'Holiday Surcharge';
        break;
      case 'peakperioddateslist':
        key = 'Peak Period Surcharge';
        break;
      case 'peakperiodtimeslist':
        key = 'Peak Hour Surcharge';
        break;
    }
    return key;
  }

}
