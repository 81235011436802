import * as timezones from 'moment-timezone';
export class StationDetails {
  public name: string;
  public timeZone: string;
  public time: any;
  public utcTime: any;
  public Latitude?:any;
  public Longitude?:any;
  constructor(data: any) {
    this.name = data.name;
    this.timeZone = data.timeZone;
    this.time = this.convertAirporttime(
      this.timeZone,
      this.makeTripTime(60, data.time)
    );
    this.utcTime =
      this.makeTripTime(60, data.time)
     this.Latitude = data?.Latitude
     this.Longitude = data?.Longitude;
  }
  public convertAirporttime(timezone, time) {
    if (timezone != '' && timezone) {
      return timezones.tz(time, timezone).format();
    } else {
      return timezones(time).format();
    }
  }
  public makeTripTime(addtime, time) {
    return timezones.utc(time).add(addtime, 'minutes').format();
  }
}
export class CreateTrip {
  public from: any;
  public to: any;
  public fromStation: string = '';
  public toStation: string = '';
  public starttimeZone: string = '';
  public endtimeZone: string = '';
  public depart?: string;
  public timeType: string = 'true';
  public departStartDate: any;
  public departEndDate: any;
  public storeLegs = [];
  public pax: string = '0';
  public legAddComplete: boolean;
  public legId: number = 0;
  public flightType: string = 'NS';
  public flightRule: string = 'IFR';
  public operationType: string = 'charter';
  public departDate: any;
  public Distance: string;
  public BLKTIME: any;
  public Blktimeinhours: any;
  public Latitude?:any;
  public Longitude?:any;
  public baseStationEditable: boolean;
  public minUTCtime: string;
  public minLTtime: string;
  public tripTime: string;
  public arrivalTimeInUTC: string;
  public depTimeInUTC: string;
}
export class LegInfoDeatils {
  public from: any;
  public to: any;
  public legAddComplete: boolean;
  public timeZone: string;
  public endtimeZone: string;
  public departDate: any;
  public departEndDate: any;
  public pax: string;
  public legId: number;
  public flightType: string;
  public flightRule: string;
  public operationType: string;
  public baseStationEditable: boolean;
  public blkTime: any;
  public blkTimeInHrs: any;
  public timeType: string;
  public distance: string;
  public legOnHighlight: boolean;
  public addLeg: boolean;
  public arrivalTime: string;
  public arrivalDateTimeInUTC: string;
  public depDateTimeInUTC: string;
  public arrivalDateTimeInLT: string;
  public depDateTimeInLT: string;
  constructor(data?: CreateTrip, legOnHighlight?: boolean, addLeg?: boolean,) {
    if (data) {
      this.from = {
        name: data.fromStation,
        timeZone: data.starttimeZone
      },
        this.to = {
          name: data.toStation,
          timeZone: data.endtimeZone
        },
        this.departDate = data.departDate;
      this.pax = data.pax;
      this.legId = data.legId += 1;
      this.flightType = data.flightType;
      this.flightRule = data.flightRule;
      this.operationType = data.operationType;
      this.baseStationEditable = data.baseStationEditable;
      this.blkTime = data.BLKTIME;
      this.blkTimeInHrs = data.Blktimeinhours;
      this.distance = data.Distance;
      this.legOnHighlight = legOnHighlight ? legOnHighlight: this.legOnHighlight;
      this.addLeg = addLeg ? addLeg: this.addLeg;
      this.arrivalTime = data?.arrivalTimeInUTC;
      this.arrivalDateTimeInUTC = this.formatDate(data?.arrivalTimeInUTC);
      this.depDateTimeInUTC = this.formatDate(data.depTimeInUTC);
      this.arrivalDateTimeInLT = this.formatArrivalDate(data);
      this.depDateTimeInLT = this.formatDepDate(data);
    }

  }
  public makeTripTime(addtime, time) {
    return timezones.utc(time).add(addtime, 'minutes').format();
  }
  public convertAirporttime(timezone, time) {
    if (timezone != '' && timezone) {
      return timezones.tz(time, timezone).format();
    } else {
      return timezones(time).format();
    }
  }
  private formatDate(dateString) {
    const dateOfJourney = new Date(dateString);
    const formattedDate = dateOfJourney.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) + ` T${dateOfJourney.toISOString().slice(11, 19)}Z`;
    return formattedDate;
  }
  private formatArrivalDate(data) {
    const arrivalLT = this.convertAirporttime(data.endtimeZone, this.makeTripTime(0, data.arrivalTimeInUTC));
    return arrivalLT;
  }
  private formatDepDate(data) {
    const depLT = this.convertAirporttime(data.starttimeZone,this.makeTripTime(0, data.departDate));
    return depLT;
  }
}
export class AvailableAircraft {
  public availableaircrafts: ListAvailableAircrafts[];
  public leglist: [];
}
export class ListAvailableAircrafts {
  public AircraftID: string = '';
  public ApiAircraftType: string = '';
  public BaseLocation: string = '';
  public ClientID: string = '';
  public ClientName: string = '';
  public NetCharter: string = '';
  public TaxiTime: string = '';
  public UserID: string = '';
  public minimumRunwayLength: string = '';
  public FixedCharterPrice: number;
  public Flighttime: string;
  public TotalBlktime: string;
  public actailnum: string;
  public logo: string;
  public NoOfCrewCapacity: string;
  public NoOfPaxCapacity: string;
} export class EditStatus {
  public type: string;
  public index: number;
}
export class CharterInformationDeatils {
  public BLKTIME: string;
  public DIST: string;
  public ETDGMT: string;
  public ETDLT: string;
  public ETAGMT: string;
  public ETALT: string;
  public legRouteid: string;
  public etops = 'yes';
  public aircraft: string;
  public Blktimeinhours: any;
  constructor(getNewQoute?: any) {
    if (getNewQoute) {
      this.BLKTIME = getNewQoute.EstFlightTime;
    }

  }
}