import { CommonService } from '@app/shared/services/common.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CreateScheduleService } from '@app/shared/services/create-schedule.service';
import { DataService } from '@app/shared/services/data.service';
import {
  ModalController,
  NavController,
  NavParams,
  IonSearchbar
} from '@ionic/angular';
import { RecivedParams, Station } from '../model/create-schedule';
import { StationDetails } from '../model/schedule-model';
import { ScheduleWeatherNotamsComponent } from '../schedule-weather-notams/schedule-weather-notams.component';
import { googleMapProperty } from '@app/trips/trips-tab/constants/constant-message';
import { defaultSESpeed } from '@app/shared/constants/constant';
import { CreateTrip } from '@app/charter/model/schedule-creation';
import { CharterTripService } from '@app/charter/data-access/charter-trip-service';
@Component({
  selector: 'app-schedule-airport',
  templateUrl: './schedule-airport.component.html',
  styleUrls: ['./schedule-airport.component.scss'],
})
export class ScheduleAirportComponent implements OnInit {
  @ViewChild('autofocus', { static: false }) public searchbar: IonSearchbar;
  public enableConfirm: boolean = false;
  public autocomplete: string;
  public stationList = new Array<Station>();
  public autocompleteItems = new Array<Station>();
  public dataLoaded: boolean = false;
  public receivedData: RecivedParams;
  public SelectStation = [];
  public fullStation: StationDetails;
  public setTrip = new CreateTrip();
  public pageType: string;
  public selectedAltTrip;
  public sortedStationList = [];
  public Regd: any;
  public wxWeather: any;
  public toStationLatLong:string;
  public isStationSelected:boolean=false;
  public flightPlanUrl:any;
  public fromStationLatLong:any;
  public textToSearch: string = '';
  public isFirstStationSelected:boolean=false;
  public firstStationData:any;
  public filterListOfSTations:any;
  public distance:string;
  @ViewChild('paragraph', { static: true }) public paragraph!: ElementRef<HTMLParagraphElement>;
  constructor(
    public navCtrl: NavController,
    private navParams: NavParams,
    private dataservice: DataService,
    private modalController: ModalController,
    public scheduleService: CreateScheduleService,
    public commonService: CommonService,
    private charterTripService:CharterTripService,
  ) { }
  public ngOnInit() {
    setTimeout(() => this.searchbar.setFocus(), 600);
    this.Regd = this.navParams.get('Regd');
    this.receivedData = this.navParams.get('SendData');
    this.pageType = this.navParams.get('pageType');
    this.selectedAltTrip = this.navParams.get('SelectedAltTrip');
    const lat  =  this.navParams.get('Latitude');
    const setTrip  =  this.navParams.get('SetTrip');
    const long  =  this.navParams.get('Longitude');
    if(setTrip && setTrip.fromStation && this.commonService.roleId=='7')
    {
      this.setTrip.fromStation=setTrip.fromStation;
      this.setTrip.starttimeZone=setTrip.startTimeZone;
    }
    this.fromStationLatLong=lat+ ',' + long; // For Charter FromStationLatLong
    if (this.pageType == 'ScheduleCreation') {
      if (
        this.receivedData.stationType == 'from' ||
        this.receivedData.stationType == 'to' ||
        this.receivedData.stationType == 'Deaprtalternate'
      ) {
        if (this.receivedData?.departure && this.receivedData?.departure != '') {
          this.fullStation = new StationDetails(
            this.receivedData.departure,
            this.receivedData.timeZoneVal
          );
          this.SelectStation.push(this.receivedData.departure.split(',')[0]);
        }
        this.getStations();
      } else if (
        this.receivedData.stationType == 'Tripalternate' ||
        this.receivedData.stationType == 'Tripalternate2'
      ) {
        if (this.selectedAltTrip && this.selectedAltTrip != '') {
          this.fullStation = new StationDetails(
            this.selectedAltTrip,
            this.receivedData.timeZoneVal
          );
          this.getStations();
          this.SelectStation.push(this.selectedAltTrip.split(',')[0]);
        }
        this.dataservice.getStationList$().then((staionData: Station[]) => {
          let ForAlternateStation: Station[];
          ForAlternateStation = staionData.filter((data) =>
              data.searchIndexString
                .toUpperCase()
                .indexOf(
                  this.receivedData.departure.split(', ')[0].toUpperCase()
                ) >= 0
                );
          this.alternateStation(ForAlternateStation[0]);
        });
      }
    } else {
      this.getStations();
    }
  }
  public alternateStation(destination: Station) {
    this.scheduleService
      .newAlternateStationList(destination)
      .then((staionData: Station[]) => {
        if (this.navParams.get('minimumRunwayLength')) {
          this.stationListFilteredWithRunwayLength(
            this.navParams.get('minimumRunwayLength'),
            staionData
          );
        }
        this.dataLoaded = true;
        this.stationList.shift();
        // first time loading 200 items by default
        this.autocompleteItems = this.stationList;
        if (this.autocomplete != '' && this.autocomplete != undefined) {
          this.updateSearch();
        }
      });
  }
  public ionViewCanEnter() {
    if (
      this.navParams.get('SendData').stationType != '' &&
      this.navParams.get('SendData').stationType
    ) {
      return true;
    } else {
      return false;
    }
  }
  public getStations() {
    this.dataservice.getStationList$().then((stationData: Station[]) => {
      this.Regd  = this.Regd?.split("-").pop();
      this.Regd == 'TEST'
        ? this.stationFiltered(stationData)
        : (this.stationList = stationData);
        this.filterListOfSTations=this.stationList;
      if (
        this.receivedData.stationType == 'to' ||
        this.receivedData.stationType == 'from'
      ) {
        if ('stationToFilter' && this.pageType == 'ScheduleCreation') {
          const departureStation = this.stationList.filter(
            (data) =>
              data.ICAOCode
                .toUpperCase()
                .indexOf(
                  this.navParams
                    .get('stationToFilter')
                    .split(', ')[0]
                    .toUpperCase()
                ) >= 0
          ) as Station[];
          this.stationList = this.dataservice?.filterArr(
            this.stationList,
            departureStation[0]
          );
            this.firstStationData=departureStation[0]
          this.fromStationLatLong = departureStation[0].Latitude+"," +departureStation[0].Longitude;
          if (this.navParams.get('minimumRunwayLength')) {
            this.stationListFilteredWithRunwayLength(
              this.navParams.get('minimumRunwayLength'),
              this.stationList
            );
          }
        } else {

        }
      }
      this.dataLoaded = true;
      this.autocompleteItems = this.stationList.slice(0, 20);
      if (this.autocomplete != '' && this.autocomplete != undefined) {
        this.updateSearch();
      }
      setTimeout(() => { }, 600);
    });
  }
  public stationListFilteredWithRunwayLength(minimumRunwayLength, stationData) {
    this.stationList = stationData.filter((currentElement: Station) => {
      return (
        (currentElement.maxRunwayLength as number) >=
        parseFloat(minimumRunwayLength)
      );
    });
  }
  /*
  For Test aircraft stations has to be restricted for VOHS, VIDP, OMDB should show.
  */
  public stationFiltered(staionData) {
    this.stationList = staionData.filter((currentElement) => {
      return (
        currentElement.ICAOCode === 'VOHS' ||
        currentElement.ICAOCode === 'OMDB' ||
        currentElement.ICAOCode === 'VIDP'
      );
    });
  }
  public dismiss() {
    this.modalController.dismiss();
  }
  public updateSearch(ev?: any) {
    this.autocomplete = ev ? ev.target.value : this.autocomplete;
    if (this.autocomplete != '') {
      if (
        this.receivedData.stationType == 'Tripalternate' ||
        this.receivedData.stationType == 'Tripalternate2' ||
        this.receivedData.stationType == 'Deaprtalternate'
      ) {
        const element1 = this.filterListOfSTations.filter((data: any) =>
          data.searchIndexString
            ? data.searchIndexString
              .toUpperCase()
              .indexOf(this.autocomplete.toUpperCase()) >= 0
            : data.searchIndexitem
              .toUpperCase()
              .indexOf(this.autocomplete.toUpperCase()) >= 0
        );
        this.autocompleteItems = element1.splice(0, 20);
      }
      if (
        this.receivedData.stationType == 'from' ||
        this.receivedData.stationType == 'to'
      ) {
        if (this.autocomplete == '' || this.autocomplete == ' ') {
          this.autocompleteItems = this.stationList.slice(0, 50);
        } else {
          if (!this.autocomplete) {
            return false;
          }
          this.sortedStationList = this.stationList
            ? this.stationList.filter(
              (data) =>
                [
                  data.ICAOCode,
                  !this.isFirstStationSelected?data.IATACode:'',
                  !this.isFirstStationSelected?data.CityName:'',
                  !this.isFirstStationSelected?data.Country:'',
                  !this.isFirstStationSelected?data.AirportName:'',
                ].find((e) =>
                  e.toUpperCase().includes(this.autocomplete.toUpperCase())
                ) !== undefined
            )
            : [];
          this.autocompleteItems = [];
          this.autocompleteItems = this.sortedStationList
            .sort((stationA, stationB) => {
              return stationA.ICAOCode < stationB.ICAOCode ? -1 : 1;
            })
            .slice(
              this.autocompleteItems.length,
              this.autocompleteItems.length + 20
            );
        }
      }
    } else {
      this.autocompleteItems = this.stationList.slice(0, 20);
    }
  }
 public onInputChange() {
    this.search();
  }
 public search() {
  if(this.autocompleteItems.length)
  {
    for (let i = 0; i < this.autocompleteItems.length; i++) {
      const paragraphId = `paragraph${i}`;
      const paragraph = document.getElementById(paragraphId) as HTMLParagraphElement;
      if (paragraph) {
        const escapedTextToSearch = this.autocomplete.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        const pattern = new RegExp(`${escapedTextToSearch}`, "gi");
        paragraph.innerHTML = paragraph.textContent.replace(pattern, match => `<strong>${match}</strong>`);
      }
    }
  }
  }
  public doinfinite(infiniteScroll: any) {
    const stationListlength = this.autocompleteItems.length;
    const stationsList =
      !this.autocomplete || (this.autocomplete && this.autocomplete == ' ')
        ? this.stationList
        : this.sortedStationList;
    const tempAircraftList = stationsList.slice(
      stationListlength,
      stationListlength + 20
    );
    tempAircraftList.forEach((element) => {
      this.autocompleteItems.push(element);
    });
    infiniteScroll.target.complete();
  }
  public confirm() {
    if (this.fullStation) {
      this.fullStation =
        this.SelectStation.length > 0 ? this.fullStation : undefined;
      this.modalController.dismiss(this.fullStation);
    }
  }
  public chooseItem(item: Station) {
    this.toStationLatLong=item.Latitude + "," +item.Longitude;
    let selectedStation = '';
    if (item.ICAOCode != '') {
      selectedStation = selectedStation + item.ICAOCode;
    } else if (item.IATACode != '') {
      selectedStation = selectedStation + item.IATACode;
    } else if (item.FAAIdent != ' ') {
      selectedStation = selectedStation + item.FAAIdent;
    }
    this.distance = item.Distance;
    const index: number = this.SelectStation.indexOf(selectedStation);
    this.autocomplete = item.ICAOCode;
    this.unselectStation(item, selectedStation);
    selectedStation =
      selectedStation +
      ', ' +
      item.AirportName +
      ', ' +
      item.CityName +
      ', ' +
      item.Country;
    if (this.receivedData.stationType == 'to') {
      if(
        (this.SelectStation.indexOf(item.ICAOCode) != -1 ||
        this.SelectStation.indexOf(item.IATACode) != -1 ||
        this.SelectStation.indexOf(item.FAAIdent) != -1)
        )
      {
        this.isFirstStationSelected=true;
      }
      else
      {
        this.isFirstStationSelected=false;
      }
      this.fullStation = new StationDetails(selectedStation, item.timezone,item);
      this.isStationSelected=true;
      this.flightPlanUrl =
        googleMapProperty.mapUrl +
        googleMapProperty.size +
        googleMapProperty.fromStationMarkers +
        this.fromStationLatLong +
        googleMapProperty.toStationMarkers +
        this.toStationLatLong +
        googleMapProperty.mapType +
        googleMapProperty.mapKey +
        this.fromStationLatLong +
        googleMapProperty.verticalPipe +
        this.toStationLatLong;
    this.setTrip.toStation=this.fullStation.name
    this.setTrip.endtimeZone=this.fullStation.timeZone
    if(this.commonService.roleId=='7')
    {
      this.commonService.showSpinnerLoader();
      this.fetchDistanceBetweenStations();
      this.commonService.dismissLoader();
    }
    } else {
      if (this.receivedData.stationType == 'from') {
        this.fullStation = new StationDetails(selectedStation, item.timezone , item);
        this.firstStationData=this.fullStation;
      } else {
        selectedStation = item.ICAOCode;
        this.fullStation = new StationDetails(selectedStation, item.timezone ,item);
      }
    }
    this.updateSearch();
  }

  public fetchDistanceBetweenStations()
  {
    this.charterTripService.GetBlktime(this.setTrip).then((res: any) => {
      this.distance=res.Distance
    })
  }
  public unselectStation(station, prevStation) {
    this.enableConfirm = true;
    const currentStation = [];
    if (this.SelectStation.length > 0) {
      if (
        prevStation != station.ICAOCode ||
        this.SelectStation[0] != station.ICAOCode
      ) {
        currentStation.push(station.ICAOCode);
      }
    } else {
      currentStation.push(prevStation);
    }


    this.SelectStation = currentStation;
  }
  public ionViewDidLeave() {
    delete this.autocompleteItems;
    delete this.fullStation;
  }
  public round(val) {
    return val?.toFixed();
  }
  public showWeatherNotams(station: Station) {
    this.commonService.showCommonModal(ScheduleWeatherNotamsComponent, { station }, 'weatherNotams');
  }


  public ngOnDestroy() {
    this.toStationLatLong='';
    this.isStationSelected=false;
  }
  public calculateTime(distance) {
    // Calculate time in hours
    const timeInHours = distance / defaultSESpeed;
    // Convert time to hours and minutes
    const hours = timeInHours != 0 ? Math.floor(timeInHours) : 0o0;
    const minutes = timeInHours != 0 ? Math.round((timeInHours - hours) * 60) : 0o0;
    return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
  }
}
