import { MenuController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';
import { NotificationChat } from '../models/right-menu.model';

@Component({
  selector: 'app-notification-chat',
  templateUrl: './notification-chat.component.html',
  styleUrls: ['./notification-chat.component.scss'],
})
export class NotificationChatComponent implements OnInit {
  @Input() public notificationChatInfo = new NotificationChat();
  constructor(private menuController:MenuController) {

   }

  public ngOnInit() {
  }
  public back(){
    this.menuController.enable(false, 'end');
  }
}
