import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lastTruncate'
})
export class LastTruncatePipe implements PipeTransform {

  public transform(value?: any,startAfterLength?:any,radix?): any {
    return value.substring(parseInt(startAfterLength , radix));
  }
}
