import { APP_CONFIG } from 'src/environments/environment';

declare var google: any;
export enum FlightPlanTitles {
  pageTitle = 'Flight Plan',
  segmentButtonEdit = 'EDIT',
  segmentButtonView = 'VIEW',
  downloadingFlightPlan = 'Downloading...',
  pleaseWaitWhileWeGetRouteDetails = 'Fetching Route Details',
  revertingthechanges = 'Please wait while we reverting the changes...',
  recomputingtheFlightPlan = 'Please wait while we generate a Flight Plan...',
}

export enum FlightPlanUrls {
  DownLoadFlightPlan = '/index.php?page=API&action=DownloadFlightPlan',
  GetATCStripByRouteID = '/index.php?page=API&action=GetATCStripByRouteID',
}
export const MapStyle = [
  {
    "featureType": "administrative.neighborhood",
    "elementType": "labels",
    "stylers": [{
      "visibility": "off"
    }]
  }, {
    "featureType": "administrative.land_parcel",
    "elementType": "labels",
    "stylers": [{
      "visibility": "off"
    }]
  }, {
    "featureType": "administrative.locality",
    "elementType": "labels",
    "stylers": [{
      "visibility": "off"
    }]
  },
  {
    "featureType": "landscape.natural",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "color": "#e0efef"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "hue": "#1900ff"
      },
      {
        "color": "#c0e8e8"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "lightness": 100
      },
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "lightness": 700
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "all",
    "stylers": [
      {
        "color": "#C5EFFD"
      }
    ]
  }
];
export class Payload {
  public maxCrew = [];
  public maxPax = [];
  public selcetdCrew: number = 0;
  public selectedPax: number = 0;
  public openStatus: boolean = false;
  public cargo: string = 'SimpleCargoLoadSpecification';
  public cargoValue: string = null;
  public weightUnit: string = 'KGS';
  constructor(
    crewCapacity?: any,
    paxCapacity?: any,
    selcetdCrew?: number,
    selectedPax?: number,
    cargoValue?: string,
    weightUnit?: string
  ) {
    this.selcetdCrew = selcetdCrew ? selcetdCrew : this.selcetdCrew;
    this.selectedPax = selectedPax ? selectedPax : this.selectedPax;
    this.cargoValue = cargoValue ? cargoValue : this.cargoValue;
    this.weightUnit = weightUnit ? weightUnit : this.weightUnit;
    this.cargo =
      cargoValue == null
        ? 'MaxCargoLoadSpecification'
        : 'SimpleCargoLoadSpecification';
    if (crewCapacity) {
      while (crewCapacity != 0) {
        this.maxCrew.push(String(crewCapacity));
        crewCapacity--;
      }
    }
    if (paxCapacity) {
      while (paxCapacity > -1) {
        this.maxPax.push(String(paxCapacity));
        paxCapacity--;
      }
    }

    this.maxCrew.reverse();
    this.maxPax.reverse();
  }
}
export class Flight {
  public flightType: string;
  public flightRule: string;
  public operationTypeOption = 'Private';
  public openStatus: boolean = false;
  constructor(
    flightType?: string,
    flightRule?: string,
    operationType?: string
  ) {
    this.flightType = flightType ? flightType : this.flightType;
    this.flightRule = flightRule ? flightRule : this.flightRule;
    this.operationTypeOption = operationType
      ? operationType
      : this.operationTypeOption;
  }
}

export class CBP {
  public ATCRoute: string;
  public FileName: string = 'Attach Flight Plan';
  public sector: string;
  public SRID: string;
  public legid: string;
  public FilePath: string;
  public FlightLevel: string;
  public FlightSpeed: string;
  public alt1: string;
  public alt2: string;
  public modifiedData?: any;
}
export const FlightType = [
  {
    name: 'N-Non Scheduled',
    value: 'N',
  },
  {
    name: 'S-Scheduled',
    value: 'S',
  },
  {
    name: 'G-General',
    value: 'G',
  },
  {
    name: 'M-Military',
    value: 'M',
  },
  {
    name: 'X-Other',
    value: 'X',
  },
];
export const FlightLevelType = [
  {
    name: 'OPT',
    value: 'OptimumFlightLevelDefinition',
  },
  {
    name: 'Actual',
    value: 'ExactFlightLevelSpecification',
  },
];
export const MaxflightlevelUnit = [
  {
    name: 'ft',
    value: 'Feet',
  },
];

export const FlightRule = [
  {
    name: 'IFR',
    value: 'IFR',
  },
  {
    name: 'VFR',
    value: 'VFR',
  },
];
export const OperationType = [
  {
    name: 'Commercial',
    value: 'Commercial',
  },
  {
    name: 'Charter',
    value: 'Charter',
  },
  {
    name: 'Cargo',
    value: 'Cargo',
  },
  {
    name: 'Private',
    value: 'Private',
  },
  {
    name: 'Ambulance',
    value: 'Ambulance',
  },
];
export class Route {
  public routeOption: string = 'auto';
  public openStatus: boolean = false;
  public flightLevelType = 'OptimumFlightLevelDefinition';
  public MaxflightlevelUnit = 'Feet';
  public crusieProfile = '';
  public ATCDestRoute = '';
  public FlightLevel = '';
  public applyRules: string;
  constructor(
    flightLevelType?: string,
    FlightLevel?: string,
    crusieProfile?: string,
    ATCDestRoute?: string,
    applyRules?: string
  ) {
    this.flightLevelType = flightLevelType
      ? flightLevelType
      : this.flightLevelType;
    this.FlightLevel = FlightLevel ? FlightLevel : this.FlightLevel;
    this.crusieProfile = crusieProfile ? crusieProfile : this.crusieProfile;
    this.ATCDestRoute = ATCDestRoute ? ATCDestRoute : this.ATCDestRoute;
    this.applyRules = applyRules ? applyRules : 'false';
  }
}
export class Alternate {
  public maxCrew = [];
  public maxPax = [];
  public routeOption: string;
  public selectedPax: number;
  public openStatus: boolean = false;
  public cargo = 'Relative';
  public cargoValue?: number;
  public alt1: string = "";
  public alt2: string = "";
  public alt1Route: string;
  public alt2Route: string;
  public alt1RouteOption = "alt1RouteOptionauto";
  public alt2RouteOption = "alt2RouteOptionauto";
  public alt1FlightLevel: number;
  public flightLevelType1: string = "OptimumFlightLevelDefinition";
  public MaxflightlevelUnit1: string = "Feet";
  public flightLevelType2: string = "OptimumFlightLevelDefinition";
  public alt2FlightLevel: string;
  public MaxflightlevelUnit2: string = "Feet";
  public alt1Speed: number;
  public alt2Speed: number;
}
export class ATCRouteDetails {
  public ATCRout: string;
  public FirList: any;
  public CountryList: any;
  public WayPoinsts: any;
  public ICAOFlightPlanString: string;
  public Alt1Route: string;
  public Alt2Route: string;
  public AvoidedFir = [];
  public AvoidedCountry = [];
  public AvoidedWaypoints = [];
  public ViaPoints: any;
  public FirNamesList: any;
}
export class Fuel {
  public fuelPolicy: string = 'MinimumRequiredFuelPolicy';
  public extrafuel: string;
  public fuelUnit: string = 'Pound';
  public openStatus: boolean = false;
}
export const FuelPolicy = [
  {
    name: 'OPT',
    value: 'MaximumFuelPolicy',
  },
  {
    name: 'Actual',
    value: 'ActualFuelPolicy',
  },
  {
    name: 'Max',
    value: 'MaximumTankFuelPolicy',
  },
];
export const FlightWeightUnit = [
  {
    name: 'kg',
    value: 'KG',
  },
  {
    name: 'lbs',
    value: 'Pound',
  },
];
export class FlightEtops {
  public etops: string;
  public operationType: number;
  public openStatus: boolean = false;
  public AutoEtops = 'Autoets';
  public Manualetops: string;
}
export class EtopsConfiguration {
  public enable: string = 'etops';
  public adequate: any;
  public min: string = '90';
  public etopsMethod: string = 'Autoets';
  public etpMethod: string = "auto";
  public etpAirportList: any;
  public etpStart: string;
  public etpEnd: string;
  constructor(
    etopsEnable?: string,
    etopsMinutes?: string,
    adequate?: any,
    etopsMethod?: string,
    etpMethod?: string,
    etpAirportList?: string,
    etpStart?: string,
    etpEnd?: string
  ) {
    const listOfAdequate = [];
    if(adequate != undefined) {
      adequate.forEach((element) => {
        listOfAdequate.push(element.ICAOCode);
      });
    }
    this.enable = etopsEnable ? etopsEnable == 'Yes' ? 'etops' : 'nonetops' : this.enable;
    this.min = etopsMinutes ? etopsMinutes : this.min;
    this.adequate = adequate ? listOfAdequate : this.adequate;
    this.etopsMethod = etopsMethod ? etopsMethod : this.etopsMethod;
    this.etpMethod = etpMethod ? etpMethod : this.etpMethod;
    this.etpAirportList = etpAirportList ? etpAirportList : this.etpAirportList;
    this.etpStart = etpStart ? etpStart : this.etpStart;
    this.etpEnd = etpEnd ? etpEnd : this.etpEnd;
  }
}

export class Weather {
  public openStatus: boolean = false;
}
export class Notams {
  public openStatus: boolean = false;
}

export class AirportInfo {
  private openStatus: boolean = false;
}
export class DockerOption {
  public drawerState: number = 1;
  public minimumHeight: number = 0;
  public dockedHeight: number = 150;
  public shouldBounce: boolean = true;
  public distanceTop: number = 70;
}
export class TripRoute {
  public ETOPSMinutes: number;
  public AdequateAirport = new Array();
  public from: string;
  public to: string;
  public EstFlightTimetmp: number;
  public EstFlightTime: string;
  public OperatorScheTimeInHour: string;
  public ATCroute = new Array();
  public FromStation: string;
  public ToStation: string;
  public dateOfJourney: string;
  public ETD: string;
  public ETA: string;
  public latlngArr = new Array();
  public FlightLevel: string;
  public FlightRule: string;
  public Speed: number;
  public flightPlanResponse: any;
  public firList = new Array();
  public countryList = new Array();
  public AircraftDetail: AircraftDetail;
  public FlightPlanType: string;
  public sector: string;
  public Finalize: number;
  public tripStatus: string;
  public legId: number;
  public serviceRequested: boolean;
  public startMarker: string;
  public endMarker: string;
  public polyline: string;
  public flightPlanNo: number;
  public googleApiKey: string;
  public accessType: string;
  public VendorID: number;
  public Valid: string;
  public routeId: number;
  public Selected?: string;
  public alternate: Alternate;
  public alternate2: Alternate;
  public SRID?: number;
  public warnings?: string[];
  public location?: string;
  public requestInputParam?:any;
  constructor(sector) {
    this.location = sector;
  }
}
export const GetAirportInformation = {
  ResponseCode: 'SC0002',
  APIName: 'GetAirportInformation',
  GetAirportInformation: {
    AIRPORTINFORMATION: {
      DEPARTURE: {
        VOHS: [
          {
            AirportFrequencies: null,
            CustomCode: null,
            Elevation: {
              Unit: 'feet',
              Value: null,
            },
            FIR: {
              LowerFLLimit: null,
              Name: null,
              Shape: null,
              UpperFLLimit: null,
              Address: null,
              CPDLCCode: null,
              FIRType: null,
              FullName: null,
              ICAOCode: null,
            },
            IATACode: 'HYD',
            ICAOCode: 'VOHS',
            IsAirportOfEntry: null,
            IsArtificialAirport: null,
            IsArtificialPoint: null,
            LengthOfLongestRunway: null,
            MagneticVariation: null,
            Name: 'Rajiv Gandhi International Airport',
            ReferencePosition: {
              Elevation: '2021',
              Latitude: '17.24',
              Longitude: '78.43',
            },
            RegionalCode: null,
            Runways: [],
            TransitionAltitude: {
              Unit: 'Feet',
              Value: null,
            },
            Type: 'Civil',
            LongestRunwayLength: 0,
          },
        ],
      },
      DESTINATION: {
        OMDB: [
          {
            AirportFrequencies: null,
            CustomCode: null,
            Elevation: {
              Unit: 'feet',
              Value: null,
            },
            FIR: {
              LowerFLLimit: null,
              Name: null,
              Shape: null,
              UpperFLLimit: null,
              Address: null,
              CPDLCCode: null,
              FIRType: null,
              FullName: null,
              ICAOCode: null,
            },
            IATACode: 'DXB',
            ICAOCode: 'OMDB',
            IsAirportOfEntry: null,
            IsArtificialAirport: null,
            IsArtificialPoint: null,
            LengthOfLongestRunway: null,
            MagneticVariation: null,
            Name: 'Dubai Intl',
            ReferencePosition: {
              Elevation: '62',
              Latitude: '25.25',
              Longitude: '55.36',
            },
            RegionalCode: null,
            Runways: [],
            TransitionAltitude: {
              Unit: 'Feet',
              Value: null,
            },
            Type: 'Civil',
            LongestRunwayLength: 0,
          },
        ],
      },
    },
  },
  ResponseStatus: 'Success',
};
export const BaronConfig = {
  base_url: 'http://api.velocityweather.com/v1',
  product: 'C39-0x0302-0',
  configuration: 'Standard-Mercator',
  key: 'qkmsIz9UYcFS', // Shared Access Key or Private Access Key
  secret: 'X1DCnbBvlkuuEMmc8KJhiU4h55oVAGOdGgvsu0TXnb', // Secret Key
};

export const NfpWmsConfig = {
  base_url: APP_CONFIG.FlpURL,
  WMSendpoint: '/api/v1/GetWMSImage',
};
export const LineSymbolForGCD = {
  path: 'M 0,-0.6 0,0.6',
  strokeOpacity: 0.7,
  strokeWeight: 3,
  scale: 3,
};
export const LineSymbol = {
  path: 'M 0,-1 0,1',
  strokeOpacity: 0.5,
  scale: 3,
};
export const MapIcon = {
  icon: LineSymbol,
  offset: '0',
  repeat: '30x',
};
export const AiracCycle = [
  '1701',
  '1702',
  '1703',
  '1704',
  '1705',
  '1706',
  '1707',
  '1708',
  '1709',
  '1710',
  '1711',
  '1712',
  '1713',
  '1801',
  '1802',
  '1803',
  '1804',
  '1805',
  '1806',
  '1807',
  '1808',
  '1809',
  '1810',
  '1811',
  '1812',
  '1813',
  '1901',
  '1902',
  '1903',
  '1904',
  '1905',
  '1906',
  '1907',
  '1908',
  '1909',
  '1910',
  '1911',
  '1912',
  '1913',
  '2001',
  '2002',
  '2003',
  '2004',
  '2005',
  '2006',
  '2007',
  '2008',
  '2009',
  '2010',
  '2011',
  '2012',
  '2013',
  '2014',
  '2101',
  '2102',
  '2103',
  '2104',
  '2105',
  '2106',
  '2107',
  '2108',
  '2109',
  '2110',
  '2111',
  '2112',
  '2113',
  '2201',
  '2202',
  '2203',
  '2204',
  '2205',
  '2206',
  '2207',
  '2208',
  '2209',
  '2210',
  '2211',
  '2212',
  '2213',
  '2301',
  '2302',
  '2303',
  '2304',
  '2305',
  '2306',
  '2307',
  '2308',
  '2309',
  '2310',
  '2311',
  '2312',
  '2313',
  '2401',
  '2402',
  '2403',
  '2404',
  '2405',
  '2406',
  '2407',
  '2408',
  '2409',
  '2410',
  '2411',
  '2412',
  '2413',
  '2501',
  '2502',
  '2503',
  '2504',
  '2505',
  '2506',
  '2507',
  '2508',
  '2509',
  '2510',
  '2511',
  '2512',
  '2513',
  '2601',
  '2602',
  '2603',
  '2604',
  '2605',
  '2606',
  '2607',
  '2608',
  '2609',
  '2610',
  '2611',
  '2612',
  '2613',
];

export interface WeatherNotam {
  WeatherNotam: string[];
}
export class DownloadFlightPlan {
  public APIName: string;
  public DownloadFlightPlan: string;
  public ResponseCode: string;
  public ResponseStatus: string;
}
export class GetATCStripByRouteIDRoot {
  public APIName: string;
  public GetATCStripByRouteID: any;
  public ResponseCode: string;
  public ResponseStatus: string;
}

export interface Elevation {
  Unit: string;
  Value: number;
}

export interface FIR {
  LowerFLLimit: number;
  Name?: any;
  Shape?: any;
  UpperFLLimit: number;
  Address?: any;
  CPDLCCode?: any;
  FIRType: string;
  FullName?: any;
  ICAOCode: string;
}

export interface ReferencePosition {
  Elevation: number;
  Latitude: number;
  Longitude: number;
}

export interface DayASDA {
  Unit: string;
  Value: number;
}

export interface DayLDA {
  Unit: string;
  Value: number;
}

export interface DayTODA {
  Unit: string;
  Value: number;
}

export interface DayTORA {
  Unit: string;
  Value: number;
}

export interface Length {
  Unit: string;
  Value: number;
}

export interface NightASDA {
  Unit: string;
  Value: number;
}

export interface NightLDA {
  Unit: string;
  Value: number;
}

export interface NightTODA {
  Unit: string;
  Value: number;
}

export interface NightTORA {
  Unit: string;
  Value: number;
}

export interface ReferencePosition2 {
  Elevation?: any;
  Latitude: number;
  Longitude: number;
}

export interface Width {
  Unit: string;
  Value: number;
}

export interface Runway {
  Bearing: number;
  DayASDA: DayASDA;
  DayLDA: DayLDA;
  DayTODA: DayTODA;
  DayTORA: DayTORA;
  Description?: any;
  Gradient: number;
  Identifier: string;
  Length: Length;
  NightASDA: NightASDA;
  NightLDA: NightLDA;
  NightTODA: NightTODA;
  NightTORA: NightTORA;
  ReferencePosition: ReferencePosition2;
  Surface: string;
  SurfaceCondition: string;
  Width: Width;
}

export interface Runways {
  Runway: Runway[];
}

export interface TransitionAltitude {
  Unit: string;
  Value: number;
}

export interface AdequateAirport {
  AirportFrequencies: any;
  CustomCode?: any;
  Elevation: Elevation;
  FIR: FIR;
  IATACode: string;
  ICAOCode: string;
  IsAirportOfEntry: boolean;
  IsArtificialAirport: boolean;
  IsArtificialPoint: boolean;
  LengthOfLongestRunway?: any;
  MagneticVariation: number;
  Name: string;
  ReferencePosition: ReferencePosition;
  RegionalCode?: any;
  Runways: Runways;
  ServedCity: string;
  SupportsIFR: boolean;
  TransitionAltitude: TransitionAltitude;
  Type: string;
}

export interface LatlngArr {
  Designator: string;
  Latitude: number;
  Longitude: number;
}

export interface AirportFrequencyARINC424 {
  Callsign: string;
  Frequency: number;
  IsSecondary: boolean;
  RemoteFacility: string;
  ServiceIndicator: string;
  Type: string;
  Unit: string;
}

export interface AirportFrequencies {
  AirportFrequencyARINC424: AirportFrequencyARINC424[];
}

export interface Elevation2 {
  Unit: string;
  Value: number;
}

export interface FIR2 {
  LowerFLLimit: number;
  Name?: any;
  Shape?: any;
  UpperFLLimit: number;
  Address?: any;
  CPDLCCode?: any;
  FIRType: string;
  FullName?: any;
  ICAOCode: string;
}

export interface ReferencePosition3 {
  Elevation?: any;
  Latitude: number;
  Longitude: number;
}

export interface DayASDA2 {
  Unit: string;
  Value: number;
}

export interface DayLDA2 {
  Unit: string;
  Value: number;
}

export interface DayTODA2 {
  Unit: string;
  Value: number;
}

export interface DayTORA2 {
  Unit: string;
  Value: number;
}

export interface Length2 {
  Unit: string;
  Value: number;
}

export interface NightASDA2 {
  Unit: string;
  Value: number;
}

export interface NightLDA2 {
  Unit: string;
  Value: number;
}

export interface NightTODA2 {
  Unit: string;
  Value: number;
}

export interface NightTORA2 {
  Unit: string;
  Value: number;
}

export interface ReferencePosition4 {
  Elevation?: any;
  Latitude: number;
  Longitude: number;
}

export interface Width2 {
  Unit: string;
  Value: number;
}

export interface Runway2 {
  Bearing: number;
  DayASDA: DayASDA2;
  DayLDA: DayLDA2;
  DayTODA: DayTODA2;
  DayTORA: DayTORA2;
  Description?: any;
  Gradient: number;
  Identifier: string;
  Length: Length2;
  NightASDA: NightASDA2;
  NightLDA: NightLDA2;
  NightTODA: NightTODA2;
  NightTORA: NightTORA2;
  ReferencePosition: ReferencePosition4;
  Surface: string;
  SurfaceCondition: string;
  Width: Width2;
}

export interface Runways2 {
  Runway: Runway2[];
}

export interface TransitionAltitude2 {
  Unit: string;
  Value: number;
}

export interface DepartureAirport {
  AirportFrequencies: AirportFrequencies;
  CustomCode?: any;
  Elevation: Elevation2;
  FIR: FIR2;
  IATACode: string;
  ICAOCode: string;
  IsAirportOfEntry: boolean;
  IsArtificialAirport: boolean;
  IsArtificialPoint: boolean;
  LengthOfLongestRunway?: any;
  MagneticVariation: number;
  Name: string;
  ReferencePosition: ReferencePosition3;
  RegionalCode?: any;
  Runways: Runways2;
  ServedCity: string;
  SupportsIFR: boolean;
  TransitionAltitude: TransitionAltitude2;
  Type: string;
}

export interface AirportFrequencyARINC4242 {
  Callsign: string;
  Frequency: number;
  IsSecondary: boolean;
  RemoteFacility: string;
  ServiceIndicator: string;
  Type: string;
  Unit: string;
}

export interface AirportFrequencies2 {
  AirportFrequencyARINC424: AirportFrequencyARINC4242[];
}

export interface Elevation3 {
  Unit: string;
  Value: number;
}

export interface FIR3 {
  LowerFLLimit: number;
  Name?: any;
  Shape?: any;
  UpperFLLimit: number;
  Address?: any;
  CPDLCCode?: any;
  FIRType: string;
  FullName?: any;
  ICAOCode: string;
}

export interface ReferencePosition5 {
  Elevation?: any;
  Latitude: number;
  Longitude: number;
}

export interface DayASDA3 {
  Unit: string;
  Value: number;
}

export interface DayLDA3 {
  Unit: string;
  Value: number;
}

export interface DayTODA3 {
  Unit: string;
  Value: number;
}

export interface DayTORA3 {
  Unit: string;
  Value: number;
}

export interface Length3 {
  Unit: string;
  Value: number;
}

export interface NightASDA3 {
  Unit: string;
  Value: number;
}

export interface NightLDA3 {
  Unit: string;
  Value: number;
}

export interface NightTODA3 {
  Unit: string;
  Value: number;
}

export interface NightTORA3 {
  Unit: string;
  Value: number;
}

export interface ReferencePosition6 {
  Elevation?: any;
  Latitude: number;
  Longitude: number;
}

export interface Width3 {
  Unit: string;
  Value: number;
}

export interface Runway3 {
  Bearing: number;
  DayASDA: DayASDA3;
  DayLDA: DayLDA3;
  DayTODA: DayTODA3;
  DayTORA: DayTORA3;
  Description?: any;
  Gradient: number;
  Identifier: string;
  Length: Length3;
  NightASDA: NightASDA3;
  NightLDA: NightLDA3;
  NightTODA: NightTODA3;
  NightTORA: NightTORA3;
  ReferencePosition: ReferencePosition6;
  Surface: string;
  SurfaceCondition: string;
  Width: Width3;
}

export interface Runways3 {
  Runway: Runway3[];
}

export interface TransitionAltitude3 {
  Unit: string;
  Value: number;
}

export interface DestinationAirport {
  AirportFrequencies: AirportFrequencies2;
  CustomCode?: any;
  Elevation: Elevation3;
  FIR: FIR3;
  IATACode: string;
  ICAOCode: string;
  IsAirportOfEntry: boolean;
  IsArtificialAirport: boolean;
  IsArtificialPoint: boolean;
  LengthOfLongestRunway?: any;
  MagneticVariation: number;
  Name: string;
  ReferencePosition: ReferencePosition5;
  RegionalCode?: any;
  Runways: Runways3;
  ServedCity: string;
  SupportsIFR: boolean;
  TransitionAltitude: TransitionAltitude3;
  Type: string;
}

export interface AirportInformation {
  DepartureAirport: DepartureAirport;
  DestinationAirport: DestinationAirport;
}

export interface MaximumAltitude {
  Unit: string;
  Value: string;
}

export interface MinimumAltitude {
  Unit: string;
  Value: string;
}

export interface Altitude {
  AltitudeUnit: string;
  MaximumAltitude: MaximumAltitude;
  MinimumAltitude: MinimumAltitude;
}

export interface RequestData {
  depart: Date;
  rule: string;
  types: string;
  operation: string;
}

export interface ClimbProfile {
  Id: number;
  Name: string;
  UUID: string;
}

export interface CruiseProfile {
  Id: number;
  Name: string;
  UUID: string;
  Index: number;
}

export interface DescentProfiles {
  Id: number;
  Name: string;
  UUID: string;
}

export interface CountryPrefix {
  ACRID: string;
  Country: string;
  ICAOflag: string;
  RegCode: string;
  TextField: string;
}

export interface AircraftDetail {
  AircraftID: string;
  AircraftManfacturer: string;
  AircraftType: string;
  AircaftCategory: string;
  OperationType: string;
  MTOWeight: string;
  UnitType: string;
  FuelConsumption: string;
  CruiseSpeed: string;
  flag: string;
  EngineType: string;
  SATPhone: string;
  NoiseCat: string;
  FuelType: string;
  TypeofFueling: string;
  BasicOperationWeight: string;
  MFCapacity: string;
  MOFWeight: string;
  MLWeight: string;
  MRWeight: string;
  TaxiAmount: string;
  DryMass: string;
  ReservePolicy: string;
  HoldAmount: string;
  MFLevel: string;
  MaxPaxSeats: string;
  SingleCruiseSpeed: string;
  ETOPSMinutes: string;
  ACFTColor: string;
  SpecialLetter: string;
  LifeVest: string;
  EmergencyRadio: string;
  SurvivalGear: string;
  NoofRats: string;
  RaftCap: string;
  RaftColor: string;
  RaftCovered: string;
  RACertifications: string;
  HFUnits: string;
  SecondayRadar: string;
  AutoDependingSur: string;
  TCASVersion: string;
  PerformanceNav: string;
  UplinkCap: string;
  GPSRAIM: string;
  GPSRecType: string;
  ReportType: string;
  MaskAngle: string;
  DEPTREQNAV: string;
  ARRVREQNAV: string;
  ENTREREQNAV: string;
  ApiAircraftType: string;
  Registration: string;
  RegisteredUserID: string;
  EngineName: string;
  EtopsEnable: string;
  Lengthspaninsqm: string;
  ETSECSpeed: string;
  ETMinutes: string;
  ACICAOcode: string;
  OPSRegulation: string;
  FlightTime: string;
  FuelBurn: string;
  MinAlternateFuel: string;
  BaseLocation: string;
  NoOfCrewCapacity: string;
  NoOfPaxCapacity: string;
  SEDistance: string;
  FuelUnit: string;
  ProfileID: string;
  OperationName: string;
  FlightRule: string;
  UUID: string;
  NCM: string;
  MaxflightlevelUnit: string;
  lengthSpanWeightRange: string;
  MinContingency: string;
  Carbonoffset: string;
  fuelbias: string;
  CharterStatus: string;
  DeleteFlag: string;
  minimumRunwayLength: string;
  climbProfile: ClimbProfile;
  CruiseProfiles: CruiseProfile[];
  DescentProfiles: DescentProfiles;
  CountryPrefix: CountryPrefix;
}

export interface Timezonedetails {
  dist: number;
  Timezone: string;
  UTC1: string;
  UTC2: string;
  fromAirportTimezone: string;
  toAirportTimezone: string;
}

export interface ReceivedData {
  data: string;
  aircraftID: string;
  legIndex: string;
  fromStation: string;
  toStation: string;
  timezone: string;
  flightType: string;
  operationType: string;
  ruleType: string;
  pax: number;
  crew: number;
  cruieseSpeed: string;
  Flightlevel: string;
  flightLevelType: string;
  FuelPolicy: string;
  extrafuel: string;
  adequate: string;
  depalt: string;
  alt1: string;
  alt2: string;
  cargo: string;
  stopType: string;
  avoidFirs: string;
  applyRules: string;
  revTripId: string;
  revLegId: string;
  modifiedData?: any;
}

export interface RequestInputParam {
  ReceivedData: ReceivedData;
  StdUtc: Date;
  AdultPaxCount: string;
  OrigApt: string;
  DestApt: string;
  AircraftId: string;
  TestFlight: string;
  FltNum: number;
  FirstLegOriginDate: string;
  IsDomestic: string;
  GetMapInfo: string;
  aircraftID: string;
  ScheduleType: string;
  FileBy: string;
  AtcFltNum: string;
  AtcCallSign: string;
  AtcItem19Pob: string;
  ProfileId: string;
  FixedReservePercent: string;
  ReserveConsumedEnroute: string;
  BasicOperationWeight: string;
  MaxTakeOffWgt: number;
  MaxLandingWgt: string;
  MaxPayload: string;
  MaxZeroFuelWgt: string;
  OrigTaxiFuel: string;
  Flightlevel: number;
  RoutePath: boolean;
  applyRules: boolean;
  DestAltApts: boolean;
  alt1Route: boolean;
  DestAltApts2: boolean;
  alt2Route: boolean;
  PayloadWgt: number;
  AvoidListing: boolean;
  DepartureFuel: number;
}

export interface Attributes {
  waypointName: string;
  countryICAOCode: string;
  waypointId: string;
  sequenceId: string;
}

export interface Attributes2 {
  latitude: string;
  longitude: string;
}

export interface EstimatedAltitude {
  Value: string;
}

export interface Altitude2 {
  EstimatedAltitude: EstimatedAltitude;
}

export interface MinimumSafeAltitude {
  Value: string;
}

export interface Direction {
  Value: string;
}

export interface Speed {
  Value: string;
}

export interface SegmentWind {
  Direction: Direction;
  Speed: Speed;
}

export interface SegmentWindComponent {
  Value: string;
}

export interface SegmentVerticalWindChange {
  Value: string;
}

export interface SegmentShearRate {
  SegmentVerticalWindChange: SegmentVerticalWindChange;
}

export interface SegmentTemperature {
  Value: string;
}

export interface SegmentISADeviation {
  Value: string;
}

export interface Tropopause {
  Value: string;
}

export interface EstimatedSpeed {
  Value: string;
}

export interface TrueAirSpeed {
  EstimatedSpeed: EstimatedSpeed;
}

export interface EstimatedMachNumber {
  Value: string;
}

export interface MachNumber {
  EstimatedMachNumber: EstimatedMachNumber;
}

export interface EstimatedSpeed2 {
  Value: string;
}

export interface IndicatedAirSpeed {
  EstimatedSpeed: EstimatedSpeed2;
}

export interface EstimatedSpeed3 {
  Value: string;
}

export interface GroundSpeed {
  EstimatedSpeed: EstimatedSpeed3;
}

export interface OutboundTrack {
  Value: string;
}

export interface SegmentTrack {
  Value: string;
}

export interface InboundTrack {
  Value: string;
}

export interface Tracks {
  OutboundTrack: OutboundTrack[];
  SegmentTrack: SegmentTrack[];
  InboundTrack: InboundTrack[];
}

export interface GroundDistance {
  Value: string;
}

export interface RemainingGroundDistance {
  Value: string;
}

export interface AirDistance {
  Value: string;
}

export interface RemainingAirDistance {
  Value: string;
}

export interface EstimatedTime {
  Value: string;
}

export interface TimeFromPreviousWaypoint {
  EstimatedTime: EstimatedTime;
}

export interface EstimatedTime2 {
  Value: Date;
}

export interface TimeOverWaypoint {
  EstimatedTime: EstimatedTime2;
}

export interface EstimatedTime3 {
  Value: string;
}

export interface CumulatedFlightTime {
  EstimatedTime: EstimatedTime3;
}

export interface EstimatedTime4 {
  Value: string;
}

export interface RemainingFlightTime {
  EstimatedTime: EstimatedTime4;
}

export interface EstimatedWeight {
  Value: string;
}

export interface BurnOff {
  EstimatedWeight: EstimatedWeight;
}

export interface EstimatedWeight2 {
  Value: string;
}

export interface CumulatedBurnOff {
  EstimatedWeight: EstimatedWeight2;
}

export interface EstimatedWeight3 {
  Value: string;
}

export interface FuelOnBoard {
  EstimatedWeight: EstimatedWeight3;
}

export interface MinimumFuelOnBoard {
  Value: string;
}

export interface Waypoint {
  Coordinates: any;
  Airway: any;
  Altitude: Altitude2;
  MinimumSafeAltitude: MinimumSafeAltitude;
  SegmentWind: SegmentWind;
  SegmentWindComponent: SegmentWindComponent;
  SegmentShearRate: SegmentShearRate;
  SegmentTemperature: SegmentTemperature;
  SegmentISADeviation: SegmentISADeviation;
  Tropopause: Tropopause;
  TrueAirSpeed: TrueAirSpeed;
  MachNumber: MachNumber;
  IndicatedAirSpeed: IndicatedAirSpeed;
  GroundSpeed: GroundSpeed;
  Tracks: Tracks;
  GroundDistance: GroundDistance;
  RemainingGroundDistance: RemainingGroundDistance;
  AirDistance: AirDistance;
  RemainingAirDistance: RemainingAirDistance;
  TimeFromPreviousWaypoint: TimeFromPreviousWaypoint;
  TimeOverWaypoint: TimeOverWaypoint;
  CumulatedFlightTime: CumulatedFlightTime;
  RemainingFlightTime: RemainingFlightTime;
  BurnOff: BurnOff;
  CumulatedBurnOff: CumulatedBurnOff;
  FuelOnBoard: FuelOnBoard;
  MinimumFuelOnBoard: MinimumFuelOnBoard;
  Function: string;
}

export interface Waypoints {
  Waypoint: Waypoint[];
}

export interface Attributes3 {
  airportName: string;
  airportFunction: string;
}

export interface Airport {
  AirportICAOCode: string;
  AirportIATACode: string;
}

export interface Attributes4 {
  fMSRouteName: string;
}

export interface AverageWindComponent {
  Value: string;
}

export interface AverageTemperature {
  Value: string;
}

export interface AverageISADeviation {
  Value: string;
}

export interface InitialAltitude {
  Value: string;
}

export interface GroundDistance2 {
  Value: string;
}

export interface AirDistance2 {
  Value: string;
}

export interface RouteInformation {
  AverageWindComponent: AverageWindComponent;
  AverageTemperature: AverageTemperature;
  AverageISADeviation: AverageISADeviation;
  InitialAltitude: InitialAltitude;
  RouteDescription: string;
  GroundDistance: GroundDistance2;
  AirDistance: AirDistance2;
}

export interface LatlngArr2 {
  Designator: any;
  Latitude: number;
  Longitude: number;
}

export interface Alternate {
  Waypoints: Waypoints;
  Airport: Airport;
  RouteInformation: RouteInformation;
  latlngArr: LatlngArr2[];
}

export interface GetATCStripByRouteID {
  ETOPSMinutes: string;
  AdequateAirport: AdequateAirport[];
  from: string;
  to: string;
  EstFlightTimetmp: string;
  EstFlightTime: string;
  OperatorScheTimeInHour: Date;
  ATCroute: string[];
  FromStation: string;
  ToStation: string;
  dateOfJourney: Date;
  ETD: string;
  ETA: string;
  latlngArr: LatlngArr[];
  FlightLevel: string;
  FlightRule: string;
  Speed?: any;
  AirportInformation: AirportInformation;
  Altitude: Altitude;
  validatedRoute: any[];
  requestData: RequestData;
  AircraftDetail: AircraftDetail;
  timezonedetails: Timezonedetails;
  timezone?: any;
  ETDGMT?: any;
  ETAGMT?: any;
  routeId: string;
  flightPlanResponse: boolean;
  alt1?: any;
  alt2?: any;
  firList: string[];
  firNames: string[];
  countryList: string[];
  error?: any;
  requestInputParam: RequestInputParam;
  alternate: Alternate;
  inputRoutePath?: any;
  warnings: any[];
  shortnotice?: any;
  alternate2?: Alternate;
}

export interface RootObject {
  ResponseCode: string;
  APIName: string;
  GetATCStripByRouteID: GetATCStripByRouteID;
  ResponseStatus: string;
}

export interface AIRPORTINFORMATION {
  DEPARTURE: any;
  DESTINATION: any;
}

export interface GetWx {
  AIRPORTINFORMATION: AIRPORTINFORMATION;
}

export interface GetWxResponse {
  ResponseCode: string;
  APIName: string;
  GetWx: GetWx;
  ResponseStatus: string;
}
export interface AIRPORTINFORMATION {
  DEPARTURE: any;
  DESTINATION: any;
}
export interface GetAirportInformation {
  AIRPORTINFORMATION: AIRPORTINFORMATION;
}
export interface GetAirportInformationResponse {
  ResponseCode: string;
  APIName: string;
  GetAirportInformation: GetAirportInformation;
  ResponseStatus: string;
}
export const LatLng = {
  lat: 0.0,
  lng: 0.0,
};

export const CircleOptions = {
  strokeColor: '',
  strokeOpacity: 0.8,
  strokeWeight: 1,
  fillColor: '#ffffff00',
  fillOpacity: 0.1,
  map: null,
  center: LatLng,
  radius: null,
};
export interface ProcessUploadCBPResponse {
  ProcessUploadCBP: ProcessUploadCBP;
}
export interface ProcessUploadCBP {
  ETOPSMinutes: number;
  AdequateAirport: AdequateAirport[];
  from: string;
  to: string;
  EstFlightTimetmp: string;
  EstFlightTime: string;
  OperatorScheTimeInHour: Date;
  ATCroute: string[];
  FromStation: string;
  ToStation: string;
  dateOfJourney: Date;
  ETD: string;
  ETA: string;
  latlngArr: LatlngArr[];
  FlightLevel: string;
  FlightRule: string;
  Speed?: any;
  AirportInformation: AirportInformation;
  Altitude: Altitude;
  validatedRoute: any[];
  requestData: RequestData;
  AircraftDetail: AircraftDetail;
  timezonedetails: Timezonedetails;
  timezone?: any;
  ETDGMT?: any;
  ETAGMT?: any;
  routeId: number;
  flightPlanResponse: boolean;
  alt1?: any;
  alt2?: any;
  firList: string[];
  countryList: string[];
  error?: any;
  requestInputParam: RequestInputParam;
  alternate: Alternate;
  inputRoutePath: string;
  warnings: string[];
  shortnotice?: any;
}
