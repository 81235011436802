export interface NotificationObj {
    Sendertype: string;
    content: string;
    logo: string;
    notificationstage: string;
    notificationtype: string;
    senddate: number;
    sender: string;
    status: string;
    view: string;
}
export class ChatDetails {
    public message: string;
    public time: string;
    public sender: string;
    public receiver: string;
    public read: boolean;
    public file?: any;
    public fileType?: string;

    constructor(
        message: string,
        time: string,
        sender: string,
        receiver: string,
        read: boolean,
        fileType?: string,
        file?: any,
    ) {
        this.message = message;
        this.time = time;
        this.sender = sender;
        this.receiver = receiver;
        this.read = read;
        this.fileType = fileType;
        if (file) {
            this.file = file;
        }
    }
}