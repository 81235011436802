import { RatingService } from '@app/shared/services/rating.service';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { SigninService } from '@app/authentication/services/signin.service';
import { ApiTypes, MessageConfig } from '@app/shared/constants/constant';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, Subject } from 'rxjs';
import { AppUpdateService } from './app-update.service';
import { ServerConnectService } from './server-connect.service';
import { UtilService } from './util.service';
import { ActivatedRoute, NavigationExtras, Router, RouterStateSnapshot } from '@angular/router';
import { Login } from '@app/authentication/authentication.model';
@Injectable({
  providedIn: 'root',
})
export class AuthSessionService {
  public logIn = new Login();
  public token: string;
  public activeRoute = new Subject<any>();
  public type = ApiTypes;
  public userSessionSubject = new BehaviorSubject<any>(null);
  public $userSession = this.userSessionSubject.asObservable();
  public userLoggedin = new BehaviorSubject<boolean>(null);
  public readOnly = new BehaviorSubject<boolean>(false);
  public roleID: any;
  public userId: any;
  public newUserRegister = false;
  public activeUrl: any;
  constructor(
    private afAuth: AngularFireAuth,
    private storage: Storage,
    private serverConnectService: ServerConnectService,
    private appUpdateService: AppUpdateService,
    private router: Router,
    private signInService: SigninService,
    private utilService: UtilService,
    private activatedRoute: ActivatedRoute,
    private ratingService: RatingService,
  ) { }
  public loadSession() {
    const onceSubscribe = this.appUpdateService.urlSetCompleted.subscribe(
      (res) => {
        if (res) {
          onceSubscribe.unsubscribe();
          if (!this.signInService.emailVerify) {
            this.startAuthSession();
          }
        }
      }
    );
    const onceSubscribe1 =
      this.appUpdateService.urlSetCompletedLoginError.subscribe((res) => {
        if (this.signInService.emailVerify) {
          this.startAuthSession();
        }
        this.signInService.emailVerify = false;
      });
  }

  public startAuthSession() {
    this.afAuth.authState.subscribe((user) => {

      if (user != null && user.emailVerified) {
        this.userId = user.uid;
        this.storage.get('userdetails').then((value) => {
          if (value == null) {
            // signin call for backend api call
            setTimeout(() => {
              this.signInService.sendlogin().then((data: any) => {
                this.userSessionSubject.next(data.APILogin);
                this.roleID = data.APILogin.RoleID;
                this.userLoggedin.next(true);
                this.storage.clear();
                this.router.navigate([], { replaceUrl: true });
                this.storage.set('userdetails', data.APILogin);
                this.redirectAsPerRoalId();
              });
            }, 3000);
          } else {
            this.userSessionSubject.next(value);
            this.userLoggedin.next(true);
            this.redirectAsPerRoalId();
          }
        });
      } else {
        if (!this.newUserRegister) {
          if (user && !user.emailVerified) {
            this.signInService.sendEmailVerification(user.email);
            this.utilService.showToastCtrl(MessageConfig.emailverify);
          } else {
            // Allow access to the public page without authentication
            if (!this.router.url.startsWith('/fuel-delivery-note')) {
              this.afAuth.signOut();
              this.router.navigateByUrl('signin');
            }
          }
        } else {
          this.afAuth.signOut();
          this.router.navigateByUrl('signin');
          this.utilService.dismissLoader();
        }
      }
    });
  }
  public redirectAsPerRoalId() {
    this.$userSession.subscribe((res) => {
      try {
        this.signInService
          .checkFlag(res.firebase_uid)
          .then(async (userProfileStatus) => {
            this.utilService.dismissLoader();
            if (userProfileStatus == '0') {
              const navigationExtras: NavigationExtras = {
                state: {
                  fromPage: 'registration',
                },
              };
              this.router.navigate(['profile-setup'], navigationExtras);
            } else {
              await this.highlightSideMenu(this.activeUrl);
              const currentUrl = this.router.url;
              switch (res.RoleID) {
                // client , vendor
                case '2':
                case '3':
                  if (!currentUrl.startsWith('/fuel-delivery-note')) {
                    await this.router.navigateByUrl('trips');
                    await this.highlightSideMenu('/trips');
                  }
                  break;
                case '7':
                  await this.router.navigateByUrl('charter');
                  break;
                default:
                  this.utilService.showAlertConfirm();
                  break;
              }
            }
          });
      } catch (error) {
        this.utilService.dismissLoader();
        // this.logOut();
      }
    });
  }
  public async permission(moduleName: string, access: string) {
    return await new Promise(async (resolve, reject) => {
      this.$userSession.subscribe((res: any) => {
        if (res && res.permissions) {
          const permissions = Object.values(res.permissions);
          permissions.filter((re: any) => {
            if (re.Access) {
              if (re.Access.includes(access) && re.Module == moduleName) {
                return resolve(true);
              }
            }
          });
          resolve(false);
        } else if (res && res.RoleID != null && res.RoleID == '7') {
          return resolve(true);
        } else {
          setTimeout(() => {
            this.permission(moduleName, access);
          }, 200);
        }
      });
    });
  }
  public logOut() {
    this.storage.clear().then(() => {
      this.storage.remove('userdetails');
      this.afAuth.signOut();
      // this.userSessionSubject.next(null);
      this.userLoggedin.next(false);
      this.router.navigate(['signin'], {
        replaceUrl: true,
        skipLocationChange: true,
      });
      this.utilService.dismissLoader();
    });
  }
  public async highlightSideMenu(url: string) {
    if (url) {
      const urlRoute = url.split('/');
      this.activeRoute.next(urlRoute[1]);
    }
  }
  public setRoleID() {
    this.$userSession.subscribe((res) => {
      if (res) {
        this.roleID = res.RoleID;
      }
    });
  }
}
