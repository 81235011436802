import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ServerConnectService } from '@app/core/services/server-connect.service';
import { ApiTypes, MessageConfig } from '@app/shared/constants/constant';
import { ApiConfig } from '@app/shared/constants/url-constant';
import { ForgotPassword, Login, Signup } from '../authentication.model';
import firebase from 'firebase';
import { FormGroup } from '@angular/forms';
import { UtilService } from '@app/core/services/util.service';
import { Router } from '@angular/router';
import { queryParams } from '@app/shared/utils/query-params';
import { AppUpdateService } from '@app/core/services/app-update.service';

@Injectable({
  providedIn: 'root',
})
export class SigninService {
  public token: any;
  public userVerified: any;
  public type = ApiTypes;
  public userUid: any;
  public emailVerify: boolean;
  public otpId: number;
  constructor(
    private afAuth: AngularFireAuth,
    private serverConnectService: ServerConnectService,
    private utilService: UtilService,
    public router: Router,
    private appUpdateService: AppUpdateService
  ) { }
  public siginInEmail(loginData: Login) {
    return this.afAuth.signInWithEmailAndPassword(
      loginData.email,
      loginData.password
    );
  }
  public authetication(login) {
    this.utilService.showSpinnerLoader();
    this.siginInEmail(login)
      .then((auth: any) => {
        this.afAuth.currentUser
          .then((res) => {
            try {
              if (!res.emailVerified) {
                this.emailVerify = true;
                this.utilService.dismissLoader();
                this.utilService.showToastCtrl(MessageConfig.emailverify);
              }
              else {
                this.appUpdateService.urlSetCompletedLoginError.next(true);
              }
            } catch (error) {
            }
          })
          .catch((afError) => {
            this.utilService.dismissLoader();
          });
      })
      .catch((error) => {
        this.utilService.dismissLoader();
                // Handle errors
                switch (error.code) {
                  case 'auth/user-not-found':
                  case 'auth/wrong-password':
                    this.utilService.errorAlert(MessageConfig.LoginErrorTitle,MessageConfig.WrongLoginAlert);
                    break;
                    case 'auth/network-request-failed':
                    this.utilService.showToastCtrlMessage(MessageConfig.wifiIcon + MessageConfig.offlineAlert);
                    break;
                  default:
                    this.utilService.showToastCtrlMessage(MessageConfig.wrongAlert);
                    break;
                }
      });
  }
  public checkFlag(uid: string) {
    return new Promise((resolve) => {
      const userStatusRef = firebase.database().ref('/Userprofilesetup/' + uid);
      userStatusRef.once('value', (snapshot) => {
        setTimeout(() => {
          if (snapshot.val() != null) {
            resolve(
              snapshot.val().Flag ? snapshot.val().Flag : snapshot.val().flag
            );
          } else {
            resolve(false);
          }
        }, 100);
      });
    });
  }

  // Send email verfificaiton when new user sign up
  public sendEmailVerification(username: string) {
    const databody = 'Email=' + username;
    this.serverConnectService.connectToServer(
      this.type.POST,
      ApiConfig.EmailVerification,
      databody,
      false,
      false,
      false
    );
  }

  public signupEmail(signupData: Signup) {
    return this.afAuth.createUserWithEmailAndPassword(
      signupData.Email,
      signupData.Password
    );
  }
 public checkEmailExists(email: string): Promise<boolean> {
    return this.afAuth
      .fetchSignInMethodsForEmail(email)
      .then((signInMethods) => {
        return signInMethods && signInMethods.length > 0;
      })
      .catch((error) => {
        return false;
      });
      // APID-5590 | Purpose of this code: To check Wheather User Email already exists or not in our firebase
      // Before Signing Up.
  }

  public async setProfileFlag() {
    await this.afAuth.currentUser.then((data: any) => {
      this.userUid = data.uid;
      const reference = firebase
        .database()
        .ref('/Userprofilesetup/' + this.userUid);
      reference.set({
        flag: '0',
      });
    });
  }
  public saveSingupDetails(signupData) {
    return new Promise((resolve) => {
      const useruid = firebase.auth().currentUser.uid;
      const databody =
        'UID=' +
        useruid +
        '&Role=' +
        signupData.Role +
        '&data=' +
        JSON.stringify(signupData);
      this.serverConnectService
        .connectToServer(
          this.type.POST,
          ApiConfig.signupUrl,
          databody,
          false,
          false,
          false
        )
        .then((res) => {
          resolve('successful');
        });
    });
  }

  public MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }
      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
  public sendOnForgetMail(forgetpassword: ForgotPassword) {
    return new Promise((resolve, reject) => {
      const databody = 'Email=' + forgetpassword.UserName;
      this.serverConnectService
        .connectToServer(
          this.type.POST,
          ApiConfig.ForgetPasswordMail,
          databody,
          false,
          false,
          false
        )
        .then((data) => {
          if (data) {
            resolve(true);
            this.router.navigate(['/signin']);
            this.utilService.showToastCtrl(MessageConfig.PasswordReset);
          } else {
            resolve(false);
            this.utilService.showToastCtrl(MessageConfig.PasswordDetected);
          }
        });
    });
  }
  public sendlogin() {
    const databody = queryParams({
      deviceToken: this.utilService.deviceToken,
      registrationType: this.utilService.registrationType,
    });
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.type.POST,
          ApiConfig.apiLogin,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          return resolve(res);
        });
    });
  }
  public sendContactNumberForOTP(contactNumber: any) {

    const databody = queryParams({
      user_contact_number: contactNumber
    });
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.type.POST,
          ApiConfig.optVerification,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          this.otpId = res.otpid;
          return resolve(res);
        });
    });
  }

  public verifyCode(code: number) {
    const databody = queryParams({
      otpid: this.otpId,
      code,
      action: 'verify'
    });
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.type.POST,
          ApiConfig.optVerification,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          return res == 'verified' ? resolve(true) : resolve(false);
        });
    });
  }

  public sendLogout() {
    const databody = queryParams({
      deviceToken: this.utilService.deviceToken,
      registrationType: this.utilService.registrationType,
    });
    return new Promise((resolve,reject) => {
      this.serverConnectService
        .connectToServer(
          this.type.POST,
          ApiConfig.deleteDeviceToken,
          databody,
          false,
          false,
          false
        )
        .then((res: any) => {
          resolve(res);
        }).catch((err)=>{
          reject(err)
          this.utilService.dismissLoader();
        });
    });
  }
}
