import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuController, Platform } from '@ionic/angular';
import { SigninService } from '../services/signin.service';
import { Login } from '../authentication.model';
import { Storage } from '@ionic/storage';
import { AuthSessionService } from '@app/core/services/auth-session.service';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MessageConfig } from '@app/shared/constants/constant';
import { UtilService } from '@app/core/services/util.service';
import { SideMenuService } from '@app/right-menu/services/side-menu.service';
import { CommonService } from '@app/shared/services/common.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.page.html',
  styleUrls: ['./signin.page.scss'],
})
export class SigninPage implements OnInit {
  public logIn: Login = new Login();
  public passwordType = 'password';
  public signinForm: FormGroup;
  public messageDisplay = MessageConfig;
  constructor(
    private menuCtrl: MenuController,
    private router: Router,
    private signInService: SigninService,
    private authSessionService: AuthSessionService,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private storage: Storage,
    private platform: Platform,
    private sideMenu: SideMenuService,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService
  ) {
  }

  public ngOnInit() {
    this.validForm();

  }
  public ionViewWillEnter() {
    this.willenterLogout();
    this.menuCtrl.enable(false);
    this.sideMenu.closedChat.next(false);
    this.menuCtrl.enable(false, 'end');
  }
  public willenterLogout() {
    // this.utilService.showSpinnerLoader();
    this.authSessionService.logOut();
  }
  public ionViewWillUnload() {
    this.menuCtrl.enable(true);
  }

  public goToDashboard() {
    this.menuCtrl.enable(true, 'left');
    this.menuCtrl.open('right');
    this.router.navigateByUrl('invoices');
  }
  public signup() {
    this.router.navigateByUrl('register');
  }
  public forgotPassword() {
    this.router.navigateByUrl('forgot');
  }
  public login(form) {
    this.signInService.authetication(form.value);
  }

 public keyDownFunction(event,signinForm) {
  if(signinForm.valid)
  {
    if (event.keyCode === 13) {
      this.login(signinForm)
    }
  }
  else{
    this.commonService.dismissLoader();
  }
}
  public showPassword() {
    if (this.passwordType == 'password') {
      this.passwordType = 'text';
    } else {
      this.passwordType = 'password';
    }
  }
  public validForm() {
    this.signinForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$')]],
      password: [
        this.logIn.password,
        Validators.compose([Validators.required]),
      ],
    });
  }
}
