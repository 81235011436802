import { Component, OnInit } from '@angular/core';
import { Platform, PopoverController } from '@ionic/angular';
import { CommonService } from '../services/common.service';
@Component({
  selector: 'app-linkshare',
  templateUrl: './linkshare.component.html',
  styleUrls: ['./linkshare.component.scss'],
})
export class LinkshareComponent implements OnInit {

  constructor(private popoverController: PopoverController,
    private commonService:CommonService,
    private platform:Platform
  ) { }
  public isPopoverOpen = false;
  public popoverEvent: any;
  public publicPageUrl: string;

  public ngOnInit(){
    this.popoverController.getTop().then(popover => {
      const url = popover.componentProps.url;
      this.publicPageUrl = this.getFullUrl(url);
    });
  }
  public presentPopover(ev: any) {
    this.popoverEvent = ev;
    this.isPopoverOpen = true;
  }
  public copyLink() {
    // const completeUrl = window.location.origin + this.publicPageUrl;
    navigator.clipboard.writeText(this.publicPageUrl)
      .then(() => {
        this.commonService.showToastMessage('Link copied to clipboard!');
      })
      .catch(err => {
        // Handle error
      });
    this.isPopoverOpen = false;
  }

  public shareEmail() {
    const emailSubject = 'Check out FDN link!';
    const emailBody = 'Here is the FDN link: ' + ' ' + this.publicPageUrl;
    const mailToLink = 'mailto:?subject=' + encodeURIComponent(emailSubject) + '&body=' + encodeURIComponent(emailBody);
    window.location.href = mailToLink;
    this.isPopoverOpen = false;
  }
  public openLink() {
    window.open(this.publicPageUrl, '_blank');
    this.popoverController.dismiss();
  }
  private getFullUrl(path: string): string {
    if (this.platform.is('android') || this.platform.is('ios')) {
      return path; // Mobile platforms should already have the full URL
    } else {
      return window.location.origin + path; // Web should append the origin
    }
  }
}
