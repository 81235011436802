export class Login {
  public email: string;
  public password: string;
}
export class Signup {
  public CompanyName: string;
  public ContactNumber: any;
  public Email: string;
  public Password: string;
  public ConfirmPassword: string;
  public Role: number = 3;
  public Agreement?: boolean = false;
  public uid?: string;
  public callingCodes?: string;
  // public isProd?:boolean = false;
}

export class ForgotPassword {
  public UserName: string;
  public Password: string;
}
