import { ScheduleService } from '@app/trips/services/schedule.service';
import { Injectable } from '@angular/core';
import { AuthSessionService } from '@app/core/services/auth-session.service';
import { ServerConnectService } from '@app/core/services/server-connect.service';
import { ACTION_ITEMS } from '@app/shared/enums/common-enums';
import { CommonService } from '@app/shared/services/common.service';
import { FirebaseDataService } from '@app/shared/services/firebase-data.service';
import { activityUriConfig } from '@app/trips/constants/trip-config-url';
import { ActivityService } from '@app/trips/services/activity.service';
import { Subject } from 'rxjs';
import { GetVendorTripsUpcomingActivity, GetVendorTripsUpcomingActivityResponse, LegDetails, LegListing, OpenClientSideMenu, OpenClientSideMenuResponse, ServiceListing, StationDetails, TripDetails, TripInfo, VendorServiceListing } from '../models/right-menu.model';
import firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class SideMenuService {
  public tripData = new Subject<any>();
  public tripDetails = new TripDetails();
  public openChat = new Subject<{}>();
  public closedChat = new Subject<boolean>();
  public totalTripCount: number = 0;
  public cbppath: any;
  public flightPlan: any;
  constructor(private serverConnect: ServerConnectService, private authSessionService: AuthSessionService,
    private firebaseService: FirebaseDataService,
    private commonService: CommonService, private activityService: ActivityService, private scheduleService: ScheduleService) {
    this.activityService.tripActivityCountData.subscribe((data: number) => {
      this.totalTripCount = data;
    });
  }
  public getClientSideMenuTrips(id: number) {
    this.activityService.tripActivityCount(id);
    this.commonService.showSpinnerLoader();
    const databody = "id=" + id;
    this.firebaseService.sendSelectedTripNotificationRef(id);
    return new Promise((resolve, reject) => {
      this.serverConnect.connectToServer(0, activityUriConfig.ClientSideMenu, databody, false, false, false).then((res: OpenClientSideMenuResponse) => {
        if(res){
          this.commonService.dismissLoader();
          this.createClientDetailsArr(res.OpenClientSideMenu);
          resolve(res);
        }
      }, (err) => {
        this.commonService.dismissLoader();
        reject(err);
      });
    }).catch((error)=>{
      this.commonService.dismissLoader();
    });
  }
  public createClientDetailsArr(tripData: OpenClientSideMenu) {
    const tripInfoDetails = new TripDetails();
    tripInfoDetails.dateCreated = tripData?.srdata?.DateCreated;
    tripInfoDetails.status = tripData?.status;
    tripInfoDetails.tripId = tripData?.srid;
    tripInfoDetails.showStatus = tripData?.viewQuote;
    tripInfoDetails.aircraftId = tripData?.srdata?.AirID;
    tripInfoDetails.info.push(new TripInfo("TCE", "", "", true));
    tripInfoDetails.info.push(new TripInfo("TCS", "", "", true));
    // tripInfoDetails.info.push(
    //   new TripInfo("Operational Notes", "", "", true),
    // );;
    if (tripData?.srdata?.Legs) {
      tripData.srdata?.Legs.forEach((legsData?, id?) => {
        tripInfoDetails?.legInfo.push(
          new LegDetails(
            new StationDetails(legsData.from, legsData.sectorfromtimezone),
            new StationDetails(legsData.to, legsData.sectortotimezone),
            tripData.srdata.Regd,
            legsData.departDate,
            legsData.BLKTIME,
            legsData.DIST,
            +legsData.legid,
            legsData.CreatedDate,
            legsData.Legstage,
            this.makeDownloads(
              tripData,
              +legsData.legid,
              legsData.sector,
              "client",
              id,
              legsData.from.substring(0, 4),
              legsData.LegType,
              legsData
            ),
            true,
            this.scheduleService.getServiceDeliveryDateTime(
              tripInfoDetails.dateCreated,
              legsData.departDate,
              legsData.BLKTIME,
              legsData.Legstage,
              "progressbar",
            ),
            this.scheduleService.getServiceDeliveryDateTime(
              tripInfoDetails.dateCreated,
              legsData.departDate,
              legsData.BLKTIME,
              legsData.Legstage,
              "timer",
            ),
            legsData.sector,
            +legsData.routeID,
            legsData.Arrivaltime,
            legsData.ActualLanding,
            legsData.ActualTakeoff,
            tripInfoDetails.status
          ),
        );
      });
    }

    if (tripData.vendorArr) {
      for (const legIndexVal in tripData.vendorArr) {
        if (tripData.vendorArr[legIndexVal]) {
          for (const legId in tripData.vendorArr[legIndexVal]) {
            if (tripData.vendorArr[legIndexVal][legId]) {
              const servicesList = new Array<ServiceListing>();
              for (const services in tripData.vendorArr[legIndexVal][legId]) {
                if (tripData.vendorArr[legIndexVal][legId][services]) {
                  for (const station in tripData.vendorArr[legIndexVal][
                    legId
                  ][services]) {
                    if (station) {
                      servicesList.push(
                        new ServiceListing(
                          tripData.vendorArr[legIndexVal][legId][services][
                          station
                          ],
                        ),
                      );
                    }
                  }
                }
              }
              tripInfoDetails.chatInfo.push(
                new LegListing(
                  servicesList,
                  tripData.srdata.Legs.filter((legs) => {
                    return legs.legid == legId;
                  })[0]?.Sector,
                  tripData.srdata.Legs.filter((legs) => {
                    return legs.legid == legId;
                  })[0]?.Legstage,
                  Number(legId),
                  false,
                ),
              );
            }
          }
        }
      }
    }
    this.tripData.next(tripInfoDetails);
    this.commonService.dissmissSpinnerLoader();
  }
  public addFuelReleaseNote(tripDownloads) {
    this.tripInfoData(tripDownloads, "", "", true, ACTION_ITEMS.FUEL);
  }
  public makeDownloads(
    tripData: any,
    legId: number,
    sector: string,
    type: string,
    uniqueId: number,
    station: string,
    LegType,
    legsData
  ) {
    let servicesByLegID;
    const tripDownloads = new Array<TripInfo>();
    switch (type) {
      case "client":
        this.getGdPaxDownloads(
          tripDownloads,
          tripData,
          legId,
          LegType,
          uniqueId,
          legsData
        );
        if (
          tripData.vendorArr &&
          tripData.vendorArr[uniqueId] &&
          tripData.vendorArr[uniqueId][legId] != undefined
        ) {
          servicesByLegID = tripData.vendorArr[uniqueId][legId];
          if (
            tripData.status != "Unapproved" &&
            tripData.status != "Declined" &&
            servicesByLegID.fuel != undefined
          ) {
            this.addFuelReleaseNote(tripDownloads);
          }
          this.flightPlan = servicesByLegID["crew briefing"] ? "flightPlan/" + servicesByLegID["crew briefing"][sector].firebase_uid +
            "/" +
            sector
            : "";
          this.cbppath = servicesByLegID["crew briefing"]
            ? "crewdatacbp/" +
            servicesByLegID["crew briefing"][sector].firebase_uid +
            "/" +
            sector
            : "";

          if (
            servicesByLegID["crew briefing"] != undefined &&
            servicesByLegID["crew briefing"][sector].Service.length != 0
          ) {
            const downloadOpt = [
              {
                serviceName: "Trip Kit",
                serviceType: "TripkitFilePath",
                servicePath: "cbppath",
                serviceVisibilityCheckBy: "Cbpname",
                serviceVisibilityCheckByValue: "",
              },
              {
                serviceName: "Nav Log",
                serviceType: "FilePath",
                servicePath: "flightPlan",
                serviceVisibilityCheckBy: "Selected",
                serviceVisibilityCheckByValue: "0",
              },
            ];
            downloadOpt?.forEach((service) => {
              this.tripInfoData(
                tripDownloads,
                servicesByLegID &&
                  servicesByLegID["crew briefing"][sector]?.Service?.[0]?.[
                  service?.serviceType
                  ] != ""
                  ? servicesByLegID["crew briefing"][sector]?.Service?.[0]?.[
                  service?.serviceType
                  ]
                  : false,
                this[service.servicePath],
                servicesByLegID
                  ? servicesByLegID["crew briefing"][sector]?.Service?.length >
                    0 &&
                    servicesByLegID["crew briefing"][sector]?.Service?.[0]?.[
                    service?.serviceVisibilityCheckBy
                    ] != service.serviceVisibilityCheckByValue
                    ? true
                    : false
                  : false,
                service.serviceName,
              );
            });
          } else if (
            servicesByLegID["crew briefing"] != undefined &&
            servicesByLegID["crew briefing"][sector].Service.length == 0
          ) {
            tripData.srdata.Legs.forEach((legData, id) => {
              if (tripData.srdata.Legs[id].sector == sector) {
                const finlizeTripKit =
                  tripData.srdata.Legs[id].generatedcbparray[sector]
                    .Fianlizecbp;
                this.tripInfoData(
                  tripDownloads,
                  finlizeTripKit[0] ? finlizeTripKit[0].FilePath
                  : finlizeTripKit.AttachmentFileName
                    ? finlizeTripKit.AttachmentFileName
                    : finlizeTripKit[0]
                      ? finlizeTripKit[0].AttachmentFileName
                      : false,
                  this.flightPlan,
                  finlizeTripKit.Selected == "1"
                    ? true
                    : finlizeTripKit[0]
                      ? true
                      : false,
                  "Nav Log",
                );
                this.tripInfoData(
                  tripDownloads,
                  finlizeTripKit
                  ? tripData.srdata.Legs[id].generatedcbparray[sector]
                  .Fianlizecbp.TripkitFilePath
                  ? tripData.srdata.Legs[id].generatedcbparray[sector]
                  .Fianlizecbp.TripkitFilePath
                  : tripData.srdata.Legs[id].generatedcbparray[sector]
                      .Fianlizecbp.Cbpname
                    : false,
                  this.cbppath,
                  finlizeTripKit.Finalize == "1" ? true : false,
                  "Trip Kit",
                );
              }
            });
          } else {
            this.getFlytPlanCbpDownloads(
              tripData,
              sector,
              uniqueId,
              tripDownloads,
            );
          }
        } else {
          this.getFlytPlanCbpDownloads(
            tripData,
            sector,
            uniqueId,
            tripDownloads,
          );
        }
        break;

      case "vendor":
        this?.getGdPaxDownloads(
          tripDownloads,
          tripData,
          legId,
          LegType,
          uniqueId,
          legsData
        );
        if (
          tripData.srcbparray.length > 0 &&
          tripData.srcbparray[0][legId] &&
          tripData.srcbparray[0][legId][sector]
        ) {
          const flightPlan =
            "flightPlan/" +
            tripData.srcbparray[0][legId][sector][0].firebase_uid +
            "/" +
            sector;
          const cbppath =
            "crewdatacbp/" +
            tripData.srcbparray[0][legId][sector][0].firebase_uid +
            "/" +
            sector;
          this.tripInfoData(
            tripDownloads,
            tripData.srcbparray.length && tripData.srcbparray[0][legId]
              ? tripData.srcbparray[0][legId][sector][0].FilePath ?
              tripData.srcbparray[0][legId][sector][0].FilePath:
               tripData.srcbparray[0][legId][sector][0].AttachmentFileName
              : false,
            flightPlan,
            tripData.srcbparray.length && tripData.srcbparray[0][legId]
              ? tripData.srcbparray[0][legId][sector].length > 0 &&
                tripData.srcbparray[0][legId][sector][0].Selected != "0"
                ? true
                : false
              : false,
            "Nav Log",
          );
          this.tripInfoData(
            tripDownloads,
            tripData.srcbparray.length &&
            tripData.srcbparray[0][legId] &&
            tripData.srcbparray[0][legId][sector][0]?.TripkitFilePath !="" ?
            tripData.srcbparray[0][legId][sector][0]?.TripkitFilePath :
            tripData.srcbparray.length &&
              tripData.srcbparray[0][legId] &&
              tripData.srcbparray[0][legId][sector][0].Cbpname != ""
              ? tripData.srcbparray[0][legId][sector][0].Cbpname
              : false,
            cbppath,
            tripData.srcbparray.length && tripData.srcbparray[0][legId]
              ? tripData.srcbparray[0][legId][sector].length > 0 &&
                tripData.srcbparray[0][legId][sector][0].Cbpname != ""
                ? true
                : false
              : false,
            "Trip Kit",
          );
        } else {
          this.getFlytPlanCbpDownloads(
            tripData,
            sector,
            uniqueId,
            tripDownloads,
          );
        }
        break;
    }
    return tripDownloads;
  }
  public getGdPaxDownloads(tripDownloads, tripData, legId, LegType, legIndex,legsData) {
    [
      {
        serviceName: ACTION_ITEMS.GD,
        searchServiceBy: "assgnedcrewdetails",
      },
      {
        serviceName: ACTION_ITEMS.PAX,
        searchServiceBy: "assignedpaxdetails",
      },
    ]?.forEach((service?: any) => {
      this?.tripInfoData(
        tripDownloads,
        "",
        "",
        legsData.noOfPax == "0" ?  LegType == "Domestic" || LegType == "International" &&
        service.serviceName == ACTION_ITEMS.PAX
        ? true: false:
        LegType == "Domestic" &&
          tripData[service.searchServiceBy][legIndex][legId]?.list?.length != 0 &&
          service.serviceName == ACTION_ITEMS.PAX
          ? true
          : (service.serviceName == ACTION_ITEMS.PAX ||
            service.serviceName == ACTION_ITEMS.GD) &&
            tripData[service.searchServiceBy][legIndex][legId]?.list?.length !=
            0 &&
            LegType == "International"
            ? true
            : false,
        service.serviceName,
      );
    });
  }
  public getVendorSideMenuTrips(id) {
    this.activityService.tripActivityCount(id);
    const databody = "id=" + id;
    this.firebaseService.sendSelectedTripNotificationRef(id);
    this.commonService.showSpinnerLoader();
    return new Promise((resolve, reject) => {
      this.serverConnect.connectToServer(0, activityUriConfig.VendorTripsUpcoming, databody, false, false, false).then((res: GetVendorTripsUpcomingActivityResponse) => {
        if(res){
          this.commonService.dismissLoader();
          this.createVendorServiceArr(res.GetVendorTripsUpcomingActivity);
          resolve(res);
        }
      }, (err) => {
        reject(err);
        this.commonService.dismissLoader();
      });
    });
  }
  public async createVendorServiceArr(tripData: GetVendorTripsUpcomingActivity) {
    const tripInfoDetails = new TripDetails();
    tripInfoDetails.tripId = tripData.SRID;
    tripInfoDetails.dateCreated = tripData?.srdata?.DateCreated;
    tripInfoDetails.showStatus = tripData?.QuotesStatus;
    const aircraftDocs = new Array<{
      DownloadURl: string;
      Name: string;
      UploadName: string;
    }>();
    await firebase
      .database()
      .ref(
        `AircraftAttachment/${tripData.clientDetails.firebase_uid}/${tripData?.srdata?.AirID}`,
      )
      .once("value", (snapshot) => {
        if (snapshot.val() != null) {
          snapshot.forEach((childSnapshot) => {
            const tDocs = childSnapshot.val();
            aircraftDocs.push(tDocs);
          });
        }
      });
    if (tripData?.srdata?.Legs) {
      tripData.srdata?.Legs?.forEach((legsData, id) => {
        tripInfoDetails.legInfo.push(
          new LegDetails(
            new StationDetails(legsData.from, legsData.sectorfromtimezone),
            new StationDetails(legsData.to, legsData.sectortotimezone),
            tripData?.srdata.Regd,
            legsData?.departDate,
            legsData?.BLKTIME,
            legsData?.DIST,
            +legsData?.legid,
            legsData?.CreatedDate,
            legsData?.Legstage,
            this.makeDownloads(
              tripData,
              +legsData.legid,
              legsData.sector,
              "vendor",
              id,
              legsData.from.substring(0, 4),
              legsData.LegType,
              legsData
            ),
            true,
            this.scheduleService.getServiceDeliveryDateTime(
              tripInfoDetails.dateCreated,
              legsData.departDate,
              legsData.BLKTIME,
              legsData.Legstage,
              "progressbar",
              "vendor"
            ),
            this.scheduleService.getServiceDeliveryDateTime(
              tripInfoDetails.dateCreated,
              legsData.departDate,
              legsData.BLKTIME,
              legsData.Legstage,
              "timer",
              "vendor"
            ),
            legsData.sector,
            +legsData.routeID,
            legsData.Arrivaltime,
            legsData.ActualLanding,
            legsData.ActualTakeoff,
            tripData.status
          ),
        );
        if (
          tripData.Sector != undefined && tripData.Sector[legsData.legid]
            ? tripData.Sector[legsData.legid].Overflight
            : false
        ) {
          this.vendorTripData(tripInfoDetails, "COA", aircraftDocs);
          this.vendorTripData(tripInfoDetails, "COR", aircraftDocs);
          this.vendorTripData(tripInfoDetails, "NOISE", aircraftDocs);
        }
        if (
          tripData.Station != undefined && tripData.Station[legsData.legid]
            ? tripData.Station[legsData.legid].groundhandling
            : false ||
              (tripData.Station != undefined &&
                tripData.Station[legsData.legid])
              ? tripData.Station[legsData.legid].airportcharges
              : false
        ) {
          aircraftDocs.forEach((element) => {
            this.vendorTripData(tripInfoDetails, "COA", aircraftDocs);
            this.vendorTripData(tripInfoDetails, "COR", aircraftDocs);
            this.vendorTripData(tripInfoDetails, "NOISE", aircraftDocs);
            this.vendorTripData(tripInfoDetails, "AOC", aircraftDocs);
            this.vendorTripData(tripInfoDetails, "Insurance", aircraftDocs);
            this.vendorTripData(tripInfoDetails, element.Name, aircraftDocs, element.UploadName);
          });
        }
        if (
          tripData.Station != undefined && tripData.Station[legsData.legid]
            ? tripData.Station[legsData.legid].fuel
            : false
        ) {
          this.vendorTripData(tripInfoDetails, "AOC", aircraftDocs);
          this.vendorTripData(tripInfoDetails, "Insurance", aircraftDocs);
        }
      });
    }
    // for vendor chatlisting data for sector service
    if (tripData.sectorservicesarray) {
      for (const legid of Object.keys(tripData.sectorservicesarray)) {
        const servicesList = new Array<ServiceListing>();
        for (const sector of Object.keys(
          tripData.sectorservicesarray[legid],
        )) {
          tripData.sectorservicesarray[legid][sector].forEach((services) => {
            const vendorListing = new VendorServiceListing(services, sector, Number(legid), tripData.clientDetails);
            const serviceListing = new ServiceListing(vendorListing);
            servicesList.push(serviceListing);
          });
        }
        const legListing = new LegListing(servicesList,
          tripData.srdata.Legs.filter((legs) => {
            return legs.legid == legid;
          })[0]?.Sector,
          tripData.srdata.Legs.filter((legs) => {
            return legs.legid == legid;
          })[0]?.Legstage,
          Number(legid),
          false,
        );
        tripInfoDetails.chatInfo.push(legListing);
      }
    }
    if (tripData.servicesarray) {
      for (const legid of Object.keys(tripData.servicesarray)) {
        const servicesList = new Array<ServiceListing>();
        for (const sector of Object.keys(tripData.servicesarray[legid])) {
          for (const station of Object.keys(
            tripData.servicesarray[legid][sector],
          )) {
            tripData.servicesarray[legid][sector][station].forEach(
              (services) => {
                servicesList.push(
                  new ServiceListing(
                    new VendorServiceListing(
                      services,
                      station,
                      Number(legid),
                      tripData.clientDetails,
                    ),
                  ),
                );
              },
            );
          }
        }
        servicesList.forEach((element: ServiceListing) => {
          if (
            tripInfoDetails.chatInfo.filter((item: LegListing) => {
              return item.legId == element.legId;
            }).length
          ) {
            tripInfoDetails.chatInfo
              .filter((item: LegListing) => {
                return item.legId == element.legId;
              })[0]
              .service.push(element);
          } else {
            tripInfoDetails.chatInfo.push(
              new LegListing(
                servicesList,
                tripData.srdata.Legs.filter((legs) => {
                  return +legs.legid == element.legId;
                })[0].Sector,
                tripData.srdata.Legs.filter((legs) => {
                  return +legs.legid == element.legId;
                })[0].Legstage,
                Number(element.legId),
                false,
              ),
            );
          }
        });
      }
    }
    this.tripData.next(tripInfoDetails);
  }

  public tripInfoData(
    tripInfoDetails: TripInfo[],
    tripData: any,
    path: any,
    visible,
    name: string,
  ) {
    let fileName = "";
    let filePath = "";
    if (
      name == ACTION_ITEMS.TRIP_KIT ||
      name == ACTION_ITEMS.FLIGHT_PLAN ||
      name == ACTION_ITEMS.FDN ||
      name == ACTION_ITEMS.FUEL
    ) {
      fileName = tripData;
      filePath = path;
    }
    switch (name) {
      case ACTION_ITEMS.GD:
      case ACTION_ITEMS.PAX:
      case ACTION_ITEMS.TRIP_KIT:
      case ACTION_ITEMS.FLIGHT_PLAN:
      case ACTION_ITEMS.FDN:
      case ACTION_ITEMS.FUEL:
        if (
          tripInfoDetails.filter((item: TripInfo) => {
            return item.name == name;
          }).length == 0
        ) {
          tripInfoDetails.push(new TripInfo(name, fileName, filePath, visible));
        }
        break;
      default:
        break;
    }
  }
  public getFlytPlanCbpDownloads(tripData, sector, id, tripDownloads) {
    this.authSessionService.userSessionSubject.subscribe((res) => {
      if (res) {
        const flightPlan = "flightPlan/" + res.firebase_uid + "/" + sector;
        const cbppath = "crewdatacbp/" + res.firebase_uid + "/" + sector;
        const flightPlanAttachmentName = this.fetchPropsFromFinalizeCbp(
          tripData,
          id,
          sector,
          "AttachmentFileName",
        );
        const cbpName = this.fetchPropsFromFinalizeCbp(
          tripData,
          id,
          sector,
          "Cbpname",
        );
        const finalizeCbp =
          tripData.srdata.Legs[id].generatedcbparray[sector].Fianlizecbp.length != 0
            ? tripData.srdata.Legs[id].generatedcbparray[sector].Fianlizecbp[0]
            : [];

        if (tripData.srdata.Legs[id].sector == sector) {
          [
            {
              service: ACTION_ITEMS.FLIGHT_PLAN,
              serviceProp: flightPlanAttachmentName,
              serviceSelectOpt: "Selected",
            },
            {
              service: ACTION_ITEMS.TRIP_KIT,
              serviceProp: cbpName,
              serviceSelectOpt: "Finalize",
            },
          ].forEach((data) => {
            data.service == ACTION_ITEMS.FLIGHT_PLAN
              ? this.tripInfoData(
                tripDownloads,
                data.serviceProp ? data.serviceProp[0] : false,
                flightPlan,
                finalizeCbp && finalizeCbp[data.serviceSelectOpt] == "1"
                  ? true
                  : false,
                data.service,
              )
              : this.tripInfoData(
                tripDownloads,
                data.serviceProp ? data.serviceProp[0] : false,
                cbppath,
                finalizeCbp && finalizeCbp[data.serviceSelectOpt] == "1"
                  ? true
                  : false,
                data.service,
              );
          });
        }
      }
    });
  }
  public fetchPropsFromFinalizeCbp(tripData, id, sector, propName) {
    const finalizecbp =
      tripData.srdata.Legs[id].generatedcbparray[sector].Fianlizecbp;
    return (
      finalizecbp &&
      finalizecbp.length != 0 &&
      finalizecbp.map((item) => {
        return item[propName];
      })
    );
  }
  public vendorTripData(
    tripInfoDetails: TripDetails,
    name: string,
    aircraftDoc: any,
    uploadedFile?: string,
  ) {
    switch (name) {
      case "COA":
        if (
          tripInfoDetails.info.filter((item) => {
            return item.name == "COA";
          }).length == 0 &&
          aircraftDoc != null
        ) {
          tripInfoDetails.info.push(
            new TripInfo(
              "COA",
              aircraftDoc.filter(
                (aircraftItem: {
                  DownloadURl: string;
                  Name: string;
                  UploadName: string;
                }) => {
                  return aircraftItem.Name == "Certificate Of Airworthiness";
                },
              )[0]
                ? aircraftDoc.filter(
                  (aircraftItem: {
                    DownloadURl: string;
                    Name: string;
                    UploadName: string;
                  }) => {
                    return (
                      aircraftItem.Name == "Certificate Of Airworthiness"
                    );
                  },
                )[0].DownloadURl
                : "",
              "",
              true,
              aircraftDoc.filter(
                (aircraftItem: {
                  DownloadURl: string;
                  Name: string;
                  UploadName: string;
                }) => {
                  return aircraftItem.Name == "Certificate Of Airworthiness";
                },
              )[0]
                ? aircraftDoc.filter(
                  (aircraftItem: {
                    DownloadURl: string;
                    Name: string;
                    UploadName: string;
                  }) => {
                    return (
                      aircraftItem.Name == "Certificate Of Airworthiness"
                    );
                  },
                )[0].UploadName
                : "",
            ),
          );
        }
        break;
      case "COR":
        if (
          tripInfoDetails.info.filter((item) => {
            return item.name == "COR";
          }).length == 0 &&
          aircraftDoc != null
        ) {
          tripInfoDetails.info.push(
            new TripInfo(
              "COR",
              aircraftDoc.filter(
                (aircraftItem: {
                  DownloadURl: string;
                  Name: string;
                  UploadName: string;
                }) => {
                  return aircraftItem.Name == "Certificate of Registration";
                },
              )[0]
                ? aircraftDoc.filter(
                  (aircraftItem: {
                    DownloadURl: string;
                    Name: string;
                    UploadName: string;
                  }) => {
                    return aircraftItem.Name == "Certificate of Registration";
                  },
                )[0].DownloadURl
                : "",
              "",
              true,
              aircraftDoc.filter(
                (aircraftItem: {
                  DownloadURl: string;
                  Name: string;
                  UploadName: string;
                }) => {
                  return aircraftItem.Name == "Certificate of Registration";
                },
              )[0]
                ? aircraftDoc.filter(
                  (aircraftItem: {
                    DownloadURl: string;
                    Name: string;
                    UploadName: string;
                  }) => {
                    return aircraftItem.Name == "Certificate of Registration";
                  },
                )[0].UploadName : ''
            ),
          );
        }

        break;
      case "NOISE":
        if (
          tripInfoDetails.info.filter((item) => {
            return item.name == "NOISE";
          }).length == 0 &&
          aircraftDoc != null
        ) {
          tripInfoDetails.info.push(
            new TripInfo(
              "NOISE",
              aircraftDoc.filter(
                (aircraftItem: {
                  DownloadURl: string;
                  Name: string;
                  UploadName: string;
                }) => {
                  return aircraftItem.Name == "Noise Certificate";
                },
              )[0]
                ? aircraftDoc.filter(
                  (aircraftItem: {
                    DownloadURl: string;
                    Name: string;
                    UploadName: string;
                  }) => {
                    return aircraftItem.Name == "Noise Certificate";
                  },
                )[0].DownloadURl
                : "",
              "",
              true,
              aircraftDoc.filter(
                (aircraftItem: {
                  DownloadURl: string;
                  Name: string;
                  UploadName: string;
                }) => {
                  return aircraftItem.Name == "Noise Certificate";
                },
              )[0]
                ? aircraftDoc.filter(
                  (aircraftItem: {
                    DownloadURl: string;
                    Name: string;
                    UploadName: string;
                  }) => {
                    return aircraftItem.Name == "Noise Certificate";
                  },
                )[0].UploadName
                : "",
            ),
          );
        }

        break;
      case "AOC":
        if (
          tripInfoDetails.info.filter((item) => {
            return item.name == "AOC";
          }).length == 0 &&
          aircraftDoc != null
        ) {
          tripInfoDetails.info.push(
            new TripInfo(
              "AOC",
              aircraftDoc.filter(
                (aircraftItem: {
                  DownloadURl: string;
                  Name: string;
                  UploadName: string;
                }) => {
                  return aircraftItem.Name == "Air Operator Certificate(AOC)";
                },
              )[0]
                ? aircraftDoc.filter(
                  (aircraftItem: {
                    DownloadURl: string;
                    Name: string;
                    UploadName: string;
                  }) => {
                    return (
                      aircraftItem.Name == "Air Operator Certificate(AOC)"
                    );
                  },
                )[0].DownloadURl
                : "",
              "",
              true,
              aircraftDoc.filter(
                (aircraftItem: {
                  DownloadURl: string;
                  Name: string;
                  UploadName: string;
                }) => {
                  return aircraftItem.Name == "Air Operator Certificate(AOC)";
                },
              )[0]
                ? aircraftDoc.filter(
                  (aircraftItem: {
                    DownloadURl: string;
                    Name: string;
                    UploadName: string;
                  }) => {
                    return (
                      aircraftItem.Name == "Air Operator Certificate(AOC)"
                    );
                  },
                )[0].UploadName : ''
            ),
          );
        }

        break;
      case "Insurance":
        if (
          tripInfoDetails.info.filter((item) => {
            return item.name == "Insurance" || item.name == "INSURANCE";
          }).length == 0 &&
          aircraftDoc != null
        ) {
          tripInfoDetails.info.push(
            new TripInfo(
              "INSURANCE",
              aircraftDoc.filter(
                (aircraftItem: {
                  DownloadURl: string;
                  Name: string;
                  UploadName: string;
                }) => {
                  return aircraftItem.Name == "Certificate of Insurance";
                },
              )[0]
                ? aircraftDoc.filter(
                  (aircraftItem: {
                    DownloadURl: string;
                    Name: string;
                    UploadName: string;
                  }) => {
                    return aircraftItem.Name == "Certificate of Insurance";
                  },
                )[0].DownloadURl
                : "",
              "",
              true,
              aircraftDoc.filter(
                (aircraftItem: {
                  DownloadURl: string;
                  Name: string;
                  UploadName: string;
                }) => {
                  return aircraftItem.Name == "Certificate of Insurance";
                },
              )[0]
                ? aircraftDoc.filter(
                  (aircraftItem: {
                    DownloadURl: string;
                    Name: string;
                    UploadName: string;
                  }) => {
                    return aircraftItem.Name == "Certificate of Insurance";
                  },
                )[0].UploadName
                : "",
            ),
          );
        }
        break;
      default:
        aircraftDoc.forEach((element) => {
          if (
            tripInfoDetails.info.filter((item) => {
              return item.name == element.Name;
            }).length == 0 &&
            aircraftDoc != null
          ) {
            if (
              element.Name != "Noise Certificate" &&
              element.Name != "Air Operator Certificate(AOC)" &&
              element.Name != "Certificate of Insurance" &&
              element.Name != "Certificate of Registration" &&
              element.Name != "Certificate Of Airworthiness"
            ) {
              tripInfoDetails.info.push(
                new TripInfo(
                  element.Name,
                  aircraftDoc.filter(
                    (aircraftItem: {
                      DownloadURl: string;
                      Name: string;
                      UploadName: string;
                    }) => {
                      return aircraftItem.Name == element.Name;
                    },
                  )[0]
                    ? aircraftDoc.filter(
                      (aircraftItem: {
                        DownloadURl: string;
                        Name: string;
                        UploadName: string;
                      }) => {
                        return aircraftItem.Name == element.Name;
                      },
                    )[0].DownloadURl
                    : "",
                  "",
                  true,
                  uploadedFile
                ),
              );
            }
          }
        });
        break;
    }
  }
}
