import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ServerConnectService } from '@app/core/services/server-connect.service';
import { MessageConfig } from '@app/shared/constants/constant';
import { CommonService } from '@app/shared/services/common.service';
import { CurrencyService } from '@app/shared/services/currency.service';
import { FirebaseDataService } from '@app/shared/services/firebase-data.service';
import { UnitCurrecyService } from '@app/shared/services/unit-currecy.service';
import { activityUriConfig } from '@app/trips/constants/trip-config-url';
import { Subject } from 'rxjs';
import { FuelBasicPrice, FuelBasicPriceResponse, FuelData, GetAllClients } from './fuel.model';
import { queryParams } from '@app/shared/utils/query-params';

@Injectable({
  providedIn: 'root',
})
export class FuelSetupService {
  public title = new Subject<string>();
  public feesPriceSetup = new Subject();
  public fuelData = new FuelData();
  public surchargeFBArr: [];
  public isUpdate: boolean = false;
  public intoPlaneCalingCode: any;
  public fulerCalingCode: any;
  public rateCardOption: string[];
  public getClientData: GetAllClients[];
  constructor(
    public firebaseService: FirebaseDataService,
    public serverConnectService: ServerConnectService,
    private commonService: CommonService,
    private unitService: UnitCurrecyService,
    public currencyService: CurrencyService
  ) {
    this.firebaseService.surchargeFBArr = this.firebaseService.surchargeFBArr;
  }
  public getIconNameByCategory(page) {
    return this.checkDoneNotDone(page.title);
  }
  public checkDoneNotDone(title: string) {
    const fuelData = this.fuelData;
    switch (title) {
      case 'Basic Setup':
        if (
          fuelData &&
          fuelData.stationArr?.length > 0 &&
          fuelData.fuelapplied.length > 0 &&
          fuelData.flyingTo.length > 0 &&
          fuelData.registered.length > 0 &&
          fuelData.operation.length > 0 &&
          fuelData.flighttype.length > 0 &&
          fuelData.stopType.length > 0 &&
          fuelData.fueling.length > 0 &&
          fuelData.vendorType !== null &&
          fuelData.fuelType.length > 0 &&
          (fuelData.intoPlaneName ?? fuelData.intoPlaneName) &&
          (fuelData.intoPlaneEmail ?? fuelData.intoPlaneEmail) &&
          fuelData.intoPlanecontact !== null &&
          (fuelData.validFromTo ?? fuelData.validFromTo) &&
          ((this.specificGravityValidationForCheckMark()) ? undefined : fuelData.specificGravity)) {
          return 'checkmark-outline';
        } else {
          return 'chevron-forward-outline';
        }
        break;
      case 'Price Setup':
        switch (fuelData.priceType) {
          case 'PAP':
            if (fuelData && fuelData.papprice && fuelData.papprice !== null) {
              return 'checkmark-outline';
            } else {
              return 'chevron-forward-outline';
            }
            break;
          case 'Differential':
            if (
              fuelData &&
              fuelData.throughputfee &&
              fuelData.throughputfee !== null &&
              fuelData.unitDiffprice &&
              fuelData.unitDiffprice !== null &&
              fuelData.diffprice &&
              fuelData.diffprice !== null
            ) {
              return 'checkmark-outline';
            } else {
              return 'chevron-forward-outline';
            }
            break;
        }
        break;
      case 'Fuel Fees':
        let servicesDone: boolean = false;
        for (const orderItem in this.firebaseService.fessFBArr) {
          if (this.firebaseService.fessFBArr[orderItem].value) {
            servicesDone = true;
          }
        }
        if (servicesDone) {
          return 'checkmark-outline';
        } else {
          return 'chevron-forward-outline';
        }
        break;
      case 'Fuel Surcharges':
        let surchargesDone: boolean = false;
        for (const orderItem in this.firebaseService.surchargeFBArr) {
          if (this.firebaseService.surchargeFBArr[orderItem].value) {
            surchargesDone = true;
          }
        }
        if (surchargesDone) {
          return 'checkmark-outline';
        } else {
          return 'chevron-forward-outline';
        }
        break;
      default:
        return 'chevron-forward-outline';
    }
  }
  public processSaveFuel() {
    return new Promise<void>((resolve, reject) => {
      const sendArr = Array();
      this.fuelData?.selectedtaxList?.forEach((element) => {
        sendArr.push(element.TaxID);
      });
      const jsonArray = JSON.stringify(sendArr);
      this.updateFeesForLatestCurrency();
      this.updateSurchargesForLatestCurrency();
      const payload =
        'BasicSetup=' +
        JSON.stringify(this.createBasicSetup()) +
        '&FeesData=' +
        JSON.stringify(this.firebaseService.fessFBArr) +
        '&SurchargeData=' +
        JSON.stringify(this.firebaseService.surchargeFBArr) +
        '&selectedtaxList=' +
        jsonArray +
        '&SelectedCurrency=' +
        this.fuelData.currency.currencyId +
        '&TypeofVendor=' +
        this.fuelData.vendorType +
        '&Currency=' +
        JSON.stringify(this.fuelData.currency) +
        '&UnitType=' +
        this.fuelData.fuelUnit +
        '&Oprationalnotes=' +
        encodeURIComponent(this.fuelData.Oprationalnotes) +
        '&vendorDescription=' +
        encodeURIComponent(this.fuelData.vendorDescription) +
        '&fuelchargeID=' +
        this.fuelData.fuelChargeID
      this.serverConnectService
        .connectToServer(
          0,
          activityUriConfig.ProcessSaveFuel,
          payload,
          false,
          false,
          false
        )
        .then((res: any) => {
          this.fuelData.fuelChargeID = res.ProcessSaveFuel;
          // this.commonService.dismissLoader();
          resolve(res);
          this.commonService.showToastMessage(
            this.isUpdate
              ? 'Rate Card updated successfully '
              : 'Rate Card added successfully'
          );
        }).catch((error)=>{
          this.commonService.dismissLoader();
          reject()
        });
    });
  }

  public UpdateLogoFuel(photoUrls){
    const setupid = this.fuelData.fuelChargeID;
    const logos =  photoUrls;
    const databody = queryParams({
      SetupID: setupid,
      logos,
    });
    return new Promise<void>((resolve, reject) => {
      this.serverConnectService.connectToServer(0, activityUriConfig.UpdateLogoFuel, databody, false, false, false, "api").then((response: any) => {
        resolve(response.ResponseStatus);
      });
    });
  }
  public createBasicSetup() {
    if (this.fuelData.intoPlanecontact != undefined && typeof this.fuelData.intoPlanecontact == 'object') {
      const contactNumFormate = this.commonService.contactNumberFormate(this.fuelData?.intoPlanecontact);
      this.fuelData.intoPlanecontact = contactNumFormate.contact;
      this.fuelData.intoplancallingcode = contactNumFormate.dialCode;
    }else{
    }
    if (this.fuelData.fuelerContact != undefined && typeof this.fuelData.fuelerContact == 'object') {
      const contactNumFormate = this.commonService.contactNumberFormate(this.fuelData?.fuelerContact);
      this.fuelData.fuelerContact = contactNumFormate.contact;
      this.fuelData.fuelercallingcode = contactNumFormate.dialCode;
    }
    this.fuelData.intoplancallingcode = this.fuelData.intoplancallingcode ?? this.intoPlaneCalingCode;
    this.fuelData.fuelercallingcode = this.fuelData.fuelercallingcode ?? this.fulerCalingCode;
    const fuelerEmail = this.fuelData.fuelerEmail != '' ? this.commonService.tranformMailsToLowerCase(this.fuelData.fuelerEmail) : '';
    const alternateFuelerEmail = this.fuelData?.alterfuelerEmail ? this.commonService.tranformMailsToLowerCase(this.fuelData.alterfuelerEmail): [];
    const basicSetup = [
      {
        AirportArr: this.fuelData.stationArr,
        AircraftRegisterType: !this.isUpdate
          ? this.fuelData.fuelapplied
          : this.fuelData.fuelapplied.toString(),
        ICAO: this.fuelData.registered,
        OperationType: this.fuelData.operation,
        flighttype: this.fuelData.flighttype,
        TypeofFueling: this.fuelData.fueling,
        FuelType: this.fuelData.fuelType,
        GoingOn: this.fuelData.flyingTo,
        StopType: this.fuelData.stopType,
        TypeofVendor: this.fuelData.vendorType,
        DifferentialType: this.fuelData.priceType,
        Currency: this.fuelData.currency,
        SelectedCurrency: this.fuelData.currency.currencyId,
        PAPFuelCost: this.fuelData.papprice,
        currPAPFuelCost: '',
        PAPsalespriacycurrency: '',
        PAPsalespriceunittype: '',
        FixedFuelCost: '',
        Fixedsalespriacycurrency: '',
        Fixedsalespriceunittype: '',
        Priceindex: this.fuelData.priceindex,
        mcdCode: this.fuelData.mcdCode,
        Priceindextype: this.fuelData.priceindextype,
        Tradedtype: this.fuelData.priceindextypevalid,
        PLATTSFuelCost: this.fuelData.unitDiffprice,
        currPLATTSFuelCost: this.unitService.vendorconvertperUnit(
          this.fuelData.specificGravity,
          1,
          this.fuelData.unitDiffprice,
          this.fuelData.fuelUnit,
          this.fuelData.currency.currencyId,
          'vendor'
        ),
        PLATTSsalespriceunittype: this.fuelData.fuelUnit,
        Differentialprice: this.fuelData.diffprice,
        currDifferentialprice: this.unitService.vendorconvertperUnit(
          this.fuelData.specificGravity,
          1,
          this.fuelData.diffprice,
          this.fuelData.fuelUnit,
          this.fuelData.currency.currencyId,
          'vendor'
        ),
        Differentialpricecurrency: this.fuelData.currency,
        Differentialunittype: this.fuelData.fuelUnit,
        currThroughputFee: this.fuelData.throughputfee
          ? this.unitService.vendorconvertperUnit(
              this.fuelData.specificGravity,
              1,
              this.fuelData.throughputfee,
              this.fuelData.fuelUnit,
              this.fuelData.currency.currencyId,
              'vendor'
            )
          : '',
        ThroughputFee: this.fuelData.throughputfee
          ? this.fuelData.throughputfee
          : '',
        ThroughputfeeCurrency: this.fuelData.currency.currencyId,
        ThroughputfeeUnittype: this.fuelData.fuelUnit,
        ValidFrom: this.fuelData.validfrom,
        ValidTo: this.fuelData.validto,
        SpecificGravity: this.fuelData.specificGravity,
        Taxes: this.fuelData.selectedtaxList,
        FuelerName: this.fuelData?.fuelerName?.toUpperCase(),
        FuelerContactNo: this.fuelData?.fuelerContact,
        Fuelercallingcode: encodeURIComponent(this.fuelData?.fuelercallingcode),
        FuelerEmail: fuelerEmail,
        AlterfuelerEmail: alternateFuelerEmail,
        IntoplanName: this.fuelData?.intoPlaneName?.toUpperCase(),
        IntoplanContactNo: this.fuelData?.intoPlanecontact,
        IntoplanEmail: this.fuelData?.intoPlaneEmail ? this.commonService.tranformMailsToLowerCase(this.fuelData.intoPlaneEmail):this.fuelData?.intoPlaneEmail,
        AlterIntoPlaneEmail: this.fuelData?.alterIntoPlaneEmail ? this.commonService.tranformMailsToLowerCase(this.fuelData.alterIntoPlaneEmail) : this.fuelData?.alterIntoPlaneEmail,
        Intoplancallingcode:encodeURIComponent(this.fuelData?.intoplancallingcode),
        logo :this.fuelData?.photoUrl ? encodeURIComponent(this.fuelData.photoUrl):'',
        rateCardType: this.fuelData.rateCardType,
        sendClientsData: this.fuelData.sendListOfSelectedClients ? this.fuelData.sendListOfSelectedClients.map(client => ({
          ...client,
          logo: encodeURIComponent(client.logo)
        })) : [],
      },
    ];
    return basicSetup;
  }
  public updateFeesForLatestCurrency() {
    for (const data of Object.keys(this.firebaseService.fessFBArr)) {
      this.firebaseService.fessFBArr[data].currpriceval =
        this.firebaseService.fessFBArr[data].Pricetype != 'Percentage' &&
        this.firebaseService.fessFBArr[data].priceval
          ? this.currencyService.vendorConvertCurrency(
              this.firebaseService.fessFBArr[data].priceval,
              this.fuelData.currency.currencyId,
              'platform'
            )
          : this.firebaseService.fessFBArr[data].priceval;
          this.firebaseService.fessFBArr[data].appcon = encodeURIComponent(
            this.firebaseService.fessFBArr[data].appcon
          );
    }
  }
  public updateSurchargesForLatestCurrency() {
    if (!this.fuelData.surcharges) {
      this.fuelData.surcharges = this.firebaseService.surchargeFBArr;
    }
    for (const data in this.firebaseService.surchargeFBArr) {
      if (
        data != 'Holiday Surcharge' &&
        data != 'Peak Period Surcharge' &&
        data != 'Peak Hour Surcharge'
      ) {
        if (data == 'Weekend Surcharge') {
          if (
            this.firebaseService.surchargeFBArr[data].weekendsurchargetype ==
            'Fixed'
          ) {
            this.firebaseService.surchargeFBArr[
              data
            ].currWeekendSurchargeprice = this.currencyService.vendorConvertCurrency(
              this.firebaseService.surchargeFBArr[data].WeekendSurchargeprice,
              this.fuelData.currency.currencyId,
              'platform'
            );
          }
        } else if (data == 'Freight Surcharge') {
          if (
            this.firebaseService.surchargeFBArr[data].FrieghtSurchargeType ==
            'Fixed'
          ) {
            this.firebaseService.surchargeFBArr[data][
              'currFrieght Surchargeprice'
            ] = this.currencyService.vendorConvertCurrency(
              this.firebaseService.surchargeFBArr[data][
                'Frieght Surchargeprice'
              ],
              this.fuelData.currency.currencyId,
              'platform'
            );
          }
        } else if (data == 'Left on Board Surcharge') {
          if (
            this.firebaseService.surchargeFBArr[data]
              .LeftonBoardSurchargeType == 'Fixed'
          ) {
            this.firebaseService.surchargeFBArr[
              data
            ].currLeftonBoardSurchargeprice = this.currencyService.vendorConvertCurrency(
              this.firebaseService.surchargeFBArr[data]
                .LeftonBoardSurchargeprice,
              this.fuelData.currency.currencyId,
              'platform'
            );
          }
        } else if (data == 'Night Surcharge') {
          if (
            this.firebaseService.surchargeFBArr[data].nightsurchargetype ==
            'Fixed'
          ) {
            this.firebaseService.surchargeFBArr[data].currnightsurchargeprice =
              this.currencyService.vendorConvertCurrency(
                this.firebaseService.surchargeFBArr[data].nightsurchargeprice,
                this.fuelData.currency.currencyId,
                'platform'
              );
          }
        } else if (data == 'Outside Airport Operational Hour Surcharge') {
          if (
            this.firebaseService.surchargeFBArr[data]
              .OutsideAirportOperationalHourSurchargetype == 'Fixed'
          ) {
            this.firebaseService.surchargeFBArr[
              data
            ].currOutsideAirportOperationalHourSurchargeprice = this.currencyService.vendorConvertCurrency(
              this.firebaseService.surchargeFBArr[data]
                .OutsideAirportOperationalHourSurchargeprice,
              this.fuelData.currency.currencyId,
              'platform'
            );
          }
        } else if (data == 'Outside Office Operational Hours Surcharge') {
          if (
            this.firebaseService.surchargeFBArr[data]
              .OutsideOfficeOperationalHoursSurchargetype == 'Fixed'
          ) {
            this.firebaseService.surchargeFBArr[
              data
            ].currOutsideOfficeOperationalHoursSurchargeprice = this.currencyService.vendorConvertCurrency(
              this.firebaseService.surchargeFBArr[data]
                .OutsideOfficeOperationalHoursSurchargeprice,
              this.fuelData.currency.currencyId,
              'platform'
            );
          }
        } else if (data == 'SmallLoad Surcharge') {
          if (
            this.firebaseService.surchargeFBArr[data].smallloadsurchargetype ==
            'Fixed'
          ) {
            this.firebaseService.surchargeFBArr[
              data
            ].currsmallloadsurchargeprice = this.currencyService.vendorConvertCurrency(
              this.firebaseService.surchargeFBArr[data].smallloadsurchargeprice,
              this.fuelData.currency.currencyId,
              'platform'
            );
          }
        }
      } else {
        if (data == 'Peak Hour Surcharge') {
          if (this.firebaseService.surchargeFBArr[data].price.length > 0) {
            this.firebaseService.surchargeFBArr[data].price.forEach(
              (element, index) => {
                if (
                  this.firebaseService.surchargeFBArr[data].type[index] !==
                  'Percentage'
                ) {
                  this.firebaseService.surchargeFBArr[data].currprice[index] =
                    this.currencyService.vendorConvertCurrency(
                      element,
                      this.fuelData.currency.currencyId,
                      'platform'
                    );
                } else {
                  this.firebaseService.surchargeFBArr[data].currprice[index] =
                    element;
                }
              }
            );
          }
        } else if (data == 'Peak Period Surcharge') {
          if (this.firebaseService.surchargeFBArr[data].price.length > 0) {
            this.firebaseService.surchargeFBArr[data].price.forEach(
              (element, index) => {
                if (
                  this.firebaseService.surchargeFBArr[data].type[index] !==
                  'Percentage'
                ) {
                  this.firebaseService.surchargeFBArr[data].currprice[index] =
                    this.currencyService.vendorConvertCurrency(
                      element,
                      this.fuelData.currency.currencyId,
                      'platform'
                    );
                } else {
                  this.firebaseService.surchargeFBArr[data].currprice[index] =
                    element;
                }
              }
            );
          }
        } else if (data == 'Holiday Surcharge') {
          if (this.firebaseService.surchargeFBArr[data].price.length > 0) {
            this.firebaseService.surchargeFBArr[data].price.forEach(
              (element, index) => {
                if (
                  this.firebaseService.surchargeFBArr[data].type[index] !==
                  'Percentage'
                ) {
                  this.firebaseService.surchargeFBArr[data].currprice[index] =
                    this.currencyService.vendorConvertCurrency(
                      element,
                      this.fuelData.currency.currencyId,
                      'platform'
                    );
                } else {
                  this.firebaseService.surchargeFBArr[data].currprice[index] =
                    element;
                }
              }
            );
          }
        }
      }
    }
  }
  public getFuelDataByAirportID(fuelChargeID: number) {
    this.commonService.showSpinnerLoader(MessageConfig.fetchFuelDetails);
    return new Promise((resolve, reject) => {
      const payload = 'FuelChargeID=' + fuelChargeID;
      this.serverConnectService
        .connectToServer(
          0,
          activityUriConfig.FuelBasicPrice,
          payload,
          false,
          false,
          false
        )
        .then(async (res: FuelBasicPriceResponse) => {
          if(res.FuelBasicPrice.operator !== '') {
            const trimSpacesForEmails = Array.isArray(res.FuelBasicPrice.operator) ? res.FuelBasicPrice.operator.map(email => email.trim()) : res.FuelBasicPrice.operator.trim();
            const operatorsRecieved = this.getClientData.filter(client =>trimSpacesForEmails.includes(client.EmailID));
            res.FuelBasicPrice.RecievedOperators = operatorsRecieved;
          }
          this.fuelData = new FuelData(
            res.FuelBasicPrice,
            this.currencyService,
            this.commonService
          );
          this.intoPlaneCalingCode = this.fuelData.intoplancallingcode;
          this.fulerCalingCode = this.fuelData.fuelercallingcode;
          if (res.FuelBasicPrice.DifferntialType == 'PAP') {
            await this.createPapPriceArr(res.FuelBasicPrice);
          } else {
            await this.createDiffencialPriceArr(res.FuelBasicPrice);
          }
          await this.createFeesArr(res.FuelBasicPrice.FuelFeePrices);
          await this.createSurchargeArr(
            res.FuelBasicPrice.FuelSurchargesPrices.fuelsurchargesprices
          );
          this.commonService.dissmissSpinnerLoader();
        });
    });
  }
  public async createFeesArr(fuelFeePrices) {
    const feesData = fuelFeePrices;
    feesData.forEach((element) => {
      this.firebaseService.fessFBArr[element.FeeName].value = true;
      this.firebaseService.fessFBArr[element.FeeName].Pricetype =
        element.PriceType;
      if (
        this.firebaseService.fessFBArr[element.FeeName].Pricetype !=
        'Percentage'
      ) {
        this.firebaseService.fessFBArr[element.FeeName].priceval =
          this.currencyService.vendorConvertCurrency(
            element.Priceval,
            this.fuelData.currency.currencyId,
            'user'
          );
        this.firebaseService.fessFBArr[element.FeeName].currpriceval =
          element.Priceval;
      } else {
        this.firebaseService.fessFBArr[element.FeeName].priceval =
          element.Priceval;
        this.firebaseService.fessFBArr[element.FeeName].currpriceval =
          element.Priceval;
      }
      this.firebaseService.fessFBArr[element.FeeName].appcon =
        element.ApplicableCondition;
      if (this.fuelData.fuelapplied.indexOf('International') != -1) {
        this.firebaseService.fessFBArr[element.FeeName].appliedInternational =
          element.TripType;
      } else {
        this.firebaseService.fessFBArr[element.FeeName].appliedDomestic =
          element.TripType;
      }
      if (element.FeeName == 'Multi Vehicle Fee') {
        this.firebaseService.fessFBArr[element.FeeName].multifuelcapacity =
          element.FuelCapacity;
      }
    });
  }
  public async createPapPriceArr(fuelBasicPrice: FuelBasicPrice) {
    this.fuelData.papprice = this.unitService.vendorconvertperUnit(
      fuelBasicPrice.SpecificGravity,
      1,
      fuelBasicPrice.FuelCost,
      fuelBasicPrice.UnitType,
      fuelBasicPrice.SelectedCurrency,
      'user'
    );
  }
  public async createDiffencialPriceArr(fuelBasicPrice: FuelBasicPrice) {
    this.fuelData.priceindextypevalid = fuelBasicPrice.TradedDays;
    this.fuelData.unitDiffprice = this.getVendorConvertPerUnit(
      fuelBasicPrice.SpecificGravity,
      fuelBasicPrice.FuelCost,
      fuelBasicPrice.UnitType,
      fuelBasicPrice.SelectedCurrency
    );
    this.fuelData.diffprice = this.getVendorConvertPerUnit(
      fuelBasicPrice.SpecificGravity,
      fuelBasicPrice.DifferentialPrice,
      fuelBasicPrice.UnitType,
      fuelBasicPrice.SelectedCurrency
    );
    this.fuelData.throughputfee = this.getVendorConvertPerUnit(
      fuelBasicPrice.SpecificGravity,
      fuelBasicPrice.ThroughputFee,
      fuelBasicPrice.UnitType,
      fuelBasicPrice.SelectedCurrency
    );
  }
  public async createSurchargeArr(surchargeData) {
    let convertprice: any;
    for (const element of Object.keys(surchargeData)) {
      if (
        element != 'Holiday Surcharge' &&
        element != 'Peak Period Surcharge' &&
        element != 'Peak Hour Surcharge'
      ) {
        this.firebaseService.surchargeFBArr[element].value = true;
        if (element == 'SmallLoad Surcharge') {
          if (surchargeData[element].Type == 'Fixed') {
            convertprice = this.currencyService.vendorConvertCurrency(
              surchargeData[element].Price,
              this.fuelData.currency.currencyId,
              'user'
            );
          } else {
            convertprice = surchargeData[element].Price;
          }
          this.firebaseService.surchargeFBArr[element].smallloadsurchargeprice =
            convertprice;
          this.firebaseService.surchargeFBArr[element].smallloadsurchargetype =
            surchargeData[element].Type;
          this.firebaseService.surchargeFBArr[
            element
          ].smallloadsurchargecondition = surchargeData[element].ConditionType;
          this.firebaseService.surchargeFBArr[
            element
          ].currsmallloadsurchargeprice = surchargeData[element].Price;
        } else if (element == 'Freight Surcharge') {
          if (surchargeData[element].Type == 'Fixed') {
            convertprice = this.currencyService.vendorConvertCurrency(
              surchargeData[element].Price,
              this.fuelData.currency.currencyId,
              'user'
            );
          } else {
            convertprice = surchargeData[element].Price;
          }
          this.firebaseService.surchargeFBArr[
            element
          ].FrieghtSurchargeFlightType = surchargeData[element].ConditionType;
          this.firebaseService.surchargeFBArr[element][
            'Frieght Surchargeprice'
          ] = convertprice;
          this.firebaseService.surchargeFBArr[element].FrieghtSurchargeType =
            surchargeData[element].Type;
          this.firebaseService.surchargeFBArr[element][
            'currFrieght Surchargeprice'
          ] = surchargeData[element].Price;
        } else if (element == 'Weekend Surcharge') {
          if (surchargeData[element].Type == 'Fixed') {
            convertprice = this.currencyService.vendorConvertCurrency(
              surchargeData[element].Price,
              this.fuelData.currency.currencyId,
              'user'
            );
          } else {
            convertprice = surchargeData[element].Price;
          }
          this.firebaseService.surchargeFBArr[element].WeekendSurchargeprice =
            convertprice;
          this.firebaseService.surchargeFBArr[element].weekendsurchargetype =
            surchargeData[element].Type;
          this.firebaseService.surchargeFBArr[element].weekday1 =
            surchargeData[element].WeekendDay1;
          this.firebaseService.surchargeFBArr[element].weekday2 =
            surchargeData[element].WeekendDay2;
          this.firebaseService.surchargeFBArr[
            element
          ].currWeekendSurchargeprice = surchargeData[element].Price;
        } else if (element == 'Peak Period Surcharge') {
          const startDate = [];
          const enddate = [];
          const price = [];
          const curprice = [];
          const name = [];
          const type = [];
          surchargeData[element].peakperiodenddate.forEach((value, i) => {
            if (surchargeData[element].type[i] == 'Fixed') {
              convertprice = this.currencyService.vendorConvertCurrency(
                surchargeData[element].price[i],
                this.fuelData.currency.currencyId,
                'user'
              );
            } else {
              convertprice = surchargeData[element].price[i];
            }
            const peakdata = {
              start: surchargeData[element].peakperiodstartdate[i],
              end: value,
              name: surchargeData[element].peakperiodname[i],
              price: convertprice,
              type: surchargeData[element].type[i],
              currprice: surchargeData[element].price[i],
            };
            startDate.push(surchargeData[element].peakperiodstartdate[i]);
            enddate.push(value);
            price.push(convertprice);
            curprice.push(surchargeData[element].price[i]);
            name.push(surchargeData[element].peakperiodname[i]);
            type.push(surchargeData[element].type[i]);
            // this.peakperiodArr.push(peakdata);
            this.firebaseService.surchargeFBArr[element].peakperiodstartdate =
              startDate;
            this.firebaseService.surchargeFBArr[element].peakperiodenddate =
              enddate;
            this.firebaseService.surchargeFBArr[element].price = price;
            this.firebaseService.surchargeFBArr[element].peakperiodname = name;
            this.firebaseService.surchargeFBArr[element].type = type;
            this.firebaseService.surchargeFBArr[element].currprice = curprice;
          });
        } else if (element == 'Peak Hour Surcharge') {
          const startDate = [];
          const enddate = [];
          const price = [];
          const curprice = [];
          const name = [];
          const type = [];
          surchargeData[element].peakhourenddate.forEach((value, i) => {
            if (surchargeData[element].type[i] == 'Fixed') {
              convertprice = this.currencyService.vendorConvertCurrency(
                surchargeData[element].price[i],
                this.fuelData.currency.currencyId,
                'user'
              );
            } else {
              convertprice = surchargeData[element].price[i];
            }
            const peakhourdata = {
              end: value,
              start: surchargeData[element].peakhourstartdate[i],
              name: surchargeData[element].peakhourname[i],
              price: convertprice,
              type: surchargeData[element].type[i],
              currprice: surchargeData[element].price[i],
            };
            startDate.push(surchargeData[element].peakhourstartdate[i]);
            enddate.push(value);
            price.push(convertprice);
            curprice.push(surchargeData[element].price[i]);
            name.push(surchargeData[element].peakhourname[i]);
            type.push(surchargeData[element].type[i]);
            // this.peakhourArr.push(peakhourdata);
            this.firebaseService.surchargeFBArr[element].peakhourenddate =
              enddate;
            this.firebaseService.surchargeFBArr[element].peakhourstartdate =
              startDate;
            this.firebaseService.surchargeFBArr[element].price = price;
            this.firebaseService.surchargeFBArr[element].peakhourname = name;
            this.firebaseService.surchargeFBArr[element].type = type;
            this.firebaseService.surchargeFBArr[element].currprice = curprice;
          });
        } else if (element == 'Outside Airport Operational Hour Surcharge') {
          if (surchargeData[element].Type == 'Fixed') {
            convertprice = this.currencyService.vendorConvertCurrency(
              surchargeData[element].Price,
              this.fuelData.currency.currencyId,
              'user'
            );
          } else {
            convertprice = surchargeData[element].Price;
          }
          this.firebaseService.surchargeFBArr[element][
            'Airport OperationalHoursStartTime'
          ] = surchargeData[element].AirportOperationalHoursStartTime;
          this.firebaseService.surchargeFBArr[
            element
          ].AirportOperationalHoursendtime =
            surchargeData[element].AirportOperationalHoursEndTime;
          this.firebaseService.surchargeFBArr[
            element
          ].OutsideAirportOperationalHourSurchargeprice = convertprice;
          this.firebaseService.surchargeFBArr[
            element
          ].OutsideAirportOperationalHourSurchargetype =
            surchargeData[element].Type;
          this.firebaseService.surchargeFBArr[
            element
          ].currOutsideAirportOperationalHourSurchargeprice =
            surchargeData[element].Price;
        } else if (element == 'Outside Office Operational Hours Surcharge') {
          if (surchargeData[element].Type == 'Fixed') {
            convertprice = this.currencyService.vendorConvertCurrency(
              surchargeData[element].Price,
              this.fuelData.currency.currencyId,
              'user'
            );
          } else {
            convertprice = surchargeData[element].Price;
          }
          this.firebaseService.surchargeFBArr[element].OfficeHoursStartTime =
            surchargeData[element].OfficeHoursStartTime;
          this.firebaseService.surchargeFBArr[element].OfficeHoursendTime =
            surchargeData[element].OfficeHoursEndTime;
          this.firebaseService.surchargeFBArr[
            element
          ].OutsideOfficeOperationalHoursSurchargeprice = convertprice;
          this.firebaseService.surchargeFBArr[
            element
          ].OutsideOfficeOperationalHoursSurchargetype =
            surchargeData[element].Type;
          this.firebaseService.surchargeFBArr[
            element
          ].currOutsideOfficeOperationalHoursSurchargeprice =
            surchargeData[element].Price;
        } else if (element == 'Night Surcharge') {
          if (surchargeData[element].Type == 'Fixed') {
            convertprice = this.currencyService.vendorConvertCurrency(
              surchargeData[element].Price,
              this.fuelData.currency.currencyId,
              'user'
            );
          } else {
            convertprice = surchargeData[element].Price;
          }
          this.firebaseService.surchargeFBArr[element].nightsurchargeprice =
            convertprice;
          this.firebaseService.surchargeFBArr[element].nightsurchargetype =
            surchargeData[element].Type;
          this.firebaseService.surchargeFBArr[element].nightsurchargestarttime =
            surchargeData[element].NightSurchargeStartTime;
          this.firebaseService.surchargeFBArr[element].nightsurchargeendtime =
            surchargeData[element].NightSurchargeEndTime;
          this.firebaseService.surchargeFBArr[element].currnightsurchargeprice =
            surchargeData[element].Price;
        } else if (element == 'Left on Board Surcharge') {
          if (surchargeData[element].Type == 'Fixed') {
            convertprice = this.currencyService.vendorConvertCurrency(
              surchargeData[element].Price,
              this.fuelData.currency.currencyId,
              'user'
            );
          } else {
            convertprice = surchargeData[element].Price;
          }
          this.firebaseService.surchargeFBArr[
            element
          ].LeftonBoardSurchargeprice = convertprice;
          this.firebaseService.surchargeFBArr[
            element
          ].currLeftonBoardSurchargeprice = surchargeData[element].Price;
          this.firebaseService.surchargeFBArr[
            element
          ].LeftonBoardSurchargeType = surchargeData[element].Type;
        } else if (element == 'Holiday Surcharge') {
          const startDate = [];
          const enddate = [];
          const price = [];
          const curprice = [];
          const name = [];
          const type = [];
          surchargeData[element].enddate.forEach((value, i) => {
            if (surchargeData[element].type[i] == 'Fixed') {
              convertprice = this.currencyService.vendorConvertCurrency(
                surchargeData[element].price[i],
                this.fuelData.currency.currencyId,
                'user'
              );
            } else {
              convertprice = surchargeData[element].price[i];
            }
            const peakdata = {
              start: surchargeData[element].startDate[i],
              end: value,
              name: surchargeData[element].holidayname[i],
              price: convertprice,
              type: surchargeData[element].type[i],
              currprice: surchargeData[element].price[i],
            };
            startDate.push(surchargeData[element].startDate[i]);
            enddate.push(value);
            price.push(convertprice);
            curprice.push(surchargeData[element].price[i]);
            name.push(surchargeData[element].holidayname[i]);
            type.push(surchargeData[element].type[i]);
            // this.holidayArr.push(peakdata);
            this.firebaseService.surchargeFBArr[element].startdate = startDate;
            this.firebaseService.surchargeFBArr[element].enddate = enddate;
            this.firebaseService.surchargeFBArr[element].price = price;
            this.firebaseService.surchargeFBArr[element].holidayname = name;
            this.firebaseService.surchargeFBArr[element].type = type;
            this.firebaseService.surchargeFBArr[element].currprice = curprice;
          });
        }
      }
    }
    if (Object.keys(surchargeData).reduce((acc, surcharge) => acc + 1, 0) > 0) {
      const surchargeFlag = Object.keys(surchargeData).reduce(
        (acc, surchargeName) => {
          if (
            acc == null &&
            surchargeName != 'Holiday Surcharge' &&
            surchargeName != 'Peak Period Surcharge' &&
            surchargeName != 'Peak Hour Surcharge'
          ) {
            return surchargeName;
          }
          return acc;
        },
        null
      );
    }
  }
  public getVendorConvertPerUnit(
    specificGravity,
    fuelcost,
    unitType,
    selectedCurrency
  ) {
    return this.unitService.vendorconvertperUnit(
      specificGravity,
      1,
      fuelcost,
      unitType,
      selectedCurrency,
      'user'
    );
  }
  public specificGravityValidationForCheckMark()
  {
     if(((this.fuelData.specificGravity - Math.floor(this.fuelData.specificGravity)) == 0))
    {
      return true;
    }
    else if(this.fuelData?.specificGravity?.toString().split('.')[0]!=0){
      return true;
    }
    else if(this.fuelData?.specificGravity?.toString().split('.')[0].length>1)
    {
      return true;
    }
    else if(this.fuelData?.specificGravity?.toString().length>=6)
    {
      return true;
    }
    else{}
  }
}
