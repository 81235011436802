import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthSessionService } from './auth-session.service';
import firebase from "firebase/app";
import { ActivityCountService } from '@app/shared/services/activity-count.service';
@Injectable()
export class AppService {
  public $leftMenuItemList = new BehaviorSubject<any>([]);
  public tripsActivityCount: number = 0;
  public requestActivityCount: number = 0;
  constructor(private authSessionService: AuthSessionService,
    private activityCountService: ActivityCountService) {

  }
  public setLeftMenuTabs() {
    this.authSessionService.$userSession.subscribe((res) => {
      if (res) {
        switch (res.RoleID) {
          case '2':
            this.activityCountService.$tripsActivityCount.subscribe((data) => {
              this.tripsActivityCount = data;
            });
            this.$leftMenuItemList.next([
              { title: 'Quick Quotes', badgeValue: 0, url: '/quick-qoutes', icon: 'clipboard' },
              { title: 'Flight Plans', badgeValue: 0, url: '/flight-plan-listing', icon: 'list-circle' },
              { title: 'Estimates', badgeValue: 0, url: '/estimates', icon: 'list' },
              { title: 'Trips', badgeValue: this.tripsActivityCount, url: '/trips', icon: 'paper-plane' },
              { title: 'Invoices', badgeValue: 0, url: '/invoices', icon: 'newspaper' },
              { title: 'Setup', badgeValue: 0, url: '/setup', icon: 'build' },
            ]);
            break;
          case '3':
            this.activityCountService.$vendorUnreadActivityCount.subscribe((data) => {
              this.tripsActivityCount = data;
              this.activityCountService.$tripsQuotesCount.subscribe((QuotesCount) => {
                this.requestActivityCount = QuotesCount;
              });
            });
            this.$leftMenuItemList.next([
              { title: 'Quick Quotes', badgeValue: 0, url: '/quick-qoutes', icon: 'clipboard' },
              { title: 'Requests', badgeValue: this.requestActivityCount, url: '/requests', icon: 'list' },
              { title: 'Trips', badgeValue: this.tripsActivityCount, url: '/trips', icon: 'paper-plane' },
              { title: 'Invoices', badgeValue: 0, url: '/invoices', icon: 'newspaper' },
              { title: 'Setup', badgeValue: 0, url: '/setup', icon: 'build' },
            ]);
            break;
          case '7':
            this.$leftMenuItemList.next([
              { title: 'Estimates', badgeValue: 0, url: '/charter/save-estimate', icon: 'list' },
              { title: 'Trips', badgeValue: this.tripsActivityCount, url: '/charter', icon: 'paper-plane' },
              { title: 'Invoices', badgeValue: 0, url: '/charter/charter-invoice', icon: 'newspaper' },
              { title: 'Setup', badgeValue: 0, url: '/setup', icon: 'build' },
            ]);
            break;
          default:
            return false;
          }
      } else {
        this.$leftMenuItemList.next([]);
      }
    });
  }
  public setSetupMenuTabs() {
    return new Promise((resolve) => {
      this.authSessionService.$userSession.subscribe((res) => {
        if (res) {
          const menuTabList = [
            {
              id: 'profile-setup',
              title: 'Profile',
              icon: 'person-outline',
              link: 'profile-setup',
              roleID: ['2', '3', '7'],
            },
            {
              id: 'aircraft',
              title: 'Aircraft',
              icon: 'airplane-outline',
              link: 'aircraft',
              roleID: ['2'],
            },
            {
              id: 'passengers',
              title: 'Passengers',
              icon: 'people-outline',
              link: 'passengers',
              roleID: ['2'],
            },
            {
              id: 'crew',
              title: 'Crew',
              icon: 'crew-icon',
              link: 'crew',
              roleID: ['2'],
            },
            {
              id: 'service-providers',
              title: 'Service Providers',
              icon: 'people-outline',
              link: 'service-providers',
              roleID: ['2'],
            },
            {
              id: 'users',
              title: 'Users',
              icon: 'people-outline',
              link: 'users',
              roleID: ['2'],
            },
            {
              id: 'accounting',
              title: 'Accounting',
              icon: 'account-icon',
              link: 'accounting',
              roleID: ['2', '3'],
            },
            {
              id: 'taxes',
              title: 'Taxes',
              icon: 'cash-outline',
              link: 'taxes',
              roleID: ['3'],
            },
            {
              id: 'handling',
              title: 'Handling',
              icon: 'handling-icon',
              link: 'handling/station',
              roleID: ['3'],
            },
            {
              id: 'airport-charges',
              title: 'Airport Charges',
              icon: 'airport-icon',
              link: 'airport charges/station',
              roleID: ['3'],
            },
            {
              id: 'landing',
              title: 'Landing Permission',
              icon: 'landing-icon',
              link: 'landing/station',
              roleID: ['3'],
            },
            {
              id: 'slot',
              title: 'Slot Permission',
              icon: 'slot-icon',
              link: 'slot/station',
              roleID: ['3'],
            },
            {
              id: 'fuel',
              title: 'Fuel',
              icon: 'fuel-icon',
              link: 'fuel/station',
              roleID: ['3'],
            },
            {
              id: 'flight-planning',
              title: 'Flight Planning',
              icon: 'flight-icon',
              link: 'flight-planning/station',
              roleID: ['3'],
            },
            {
              id: 'ovf-permits',
              title: 'Overflight Permits',
              icon: 'ovf-permits-icon',
              link: 'ovf-permits/station',
              roleID: ['3'],
            },
            {
              id: 'navigation',
              title: 'Navigation',
              icon: 'navigation-icon',
              link: 'navigation/station',
              roleID: ['3'],
            },
            {
              id: 'guests',
              title: 'Passenger',
              icon: 'people-outline',
              link: 'charter-passenger',
              roleID: ['7'],
            },
            {
              id: 'stations',
              title: 'Stations',
              icon: 'add-circle-outline',
              link: 'stations',
              roleID: ['3'],  // temp
            }
          ];
          const returnTabList = [];
          menuTabList.map((item) => {
            if (item.roleID.indexOf(res.RoleID) != -1) {
              returnTabList.push(item);
            }
          });
          resolve(returnTabList);
        }
      });
    });
  }
}
