import { TripTabService } from './../../services/trip-tab.service';
import { ActivatedRoute } from '@angular/router';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { TripServices } from '../../model/trip-service.model';
import { AlertController } from '@ionic/angular';
import { EnterDetailsApiService } from '@app/trips/services/enter-details-api.service';
import { MessageConfig } from '@app/shared/constants/constant';
@Injectable({
  providedIn: 'root',
})
export class PermitsService {
  public tripServices = new TripServices();
  @Output() public save: EventEmitter<any> = new EventEmitter<any>();
  public edit: boolean = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private alertCtrl: AlertController,
    private enterDetailsapiService: EnterDetailsApiService,
    private tripTabService: TripTabService,
  ) {}
  public async enterPermitno(permitDetails, index) {
    if (this.edit) {
      if (
        this.tripServices.legStatus == 'Processing' ||
        this.tripServices.legStatus == 'Confirmed'
      ) {
        const alert = await this.alertCtrl.create({
          cssClass: 'alertCustomCssPermit',
          backdropDismiss:false,
          header: 'Enter permit number',
          inputs: [
            {
              value: permitDetails.PermitNumber,
              type: 'text',
              name: 'overflightName',
            },
          ],
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: (blah) => { },
            },
            {
              text: 'Ok',
              handler: (data) => {
                const validateObj = this.tripTabService.validate(data.overflightName, MessageConfig.PermitNumber);
                if (!validateObj.isValid) {
                  alert.message = validateObj.message;
                  return false;
                } else {
                  if (permitDetails.PermitNumber != data.overflightName) {
                    this.enterDetailsapiService
                      .submitPermitNumber(
                        permitDetails.SRPermitID,
                        encodeURIComponent(data.overflightName)
                      )
                      .then((res) => {
                        if (res == 'Success') {
                          this.tripServices.subservicedetails.sort((a,b)=> a.SRPermitID - b.SRPermitID);
                          this.tripServices.subservicedetails[
                            index
                          ].PermitNumber = data.overflightName;
                        }
                      });
                  }
                }
              },
            },
          ],
        });
        await alert.present();
      }
    }
  }
}
