import { CsvFileService } from './../../../services/csv-file.service';
import { Component, Input, OnInit } from '@angular/core';
import { MessageConfig } from '@app/shared/constants/constant';
import { CsvExcelData } from '@app/shared/models/tax.model';
import { CommonService } from '@app/shared/services/common.service';
import { ModalController } from '@ionic/angular';
import { TaxCsvServiceService } from '../../../services/tax-csv-service.service';
import { HandlingCsvServiceService } from '../../../services/handling-csv-service.service';
import { CsvFileDetailsComponent } from '../csv-file-details/csv-file-details.component';
@Component({
  selector: 'app-csv-file-list',
  templateUrl: './csv-file-list.component.html',
  styleUrls: ['./csv-file-list.component.scss'],
})
export class CsvFileListComponent implements OnInit {
  public showSkelton: boolean = true;
  public items = [];
  @Input() public typeOfService: any;
  constructor(
    private taxCsvSer: TaxCsvServiceService,
    private handlingCsvSer: HandlingCsvServiceService,
    public modalController: ModalController,
    private commonService: CommonService,
    private csvFileService: CsvFileService
  ) { }

  public ngOnInit() {
    this.getCsvData();
  }

  public getCsvData() {
    this.showSkelton = true;
    switch (this.typeOfService) {
      case 'Taxes':
        this.taxCsvSer
          .fetchCsvList()
          .then((res: any) => {
            this.showSkelton = false;
            this.items = this.filterOnDeleteFlag(this.typeOfService, res);
          })
          .then(() => {
            this.showSkelton = false;
          });
        break;
      case 'handling':
        this.handlingCsvSer.fetchCsvList().then((res: any) => {
          this.showSkelton = false;
          this.items = this.filterOnDeleteFlag(this.typeOfService, res);
        }).then(() => {
          this.showSkelton = false;
        });
        break;
      case 'fuel':
        this.csvFileService.fetchFuelCsvList().then((res: any) => {
          this.showSkelton = false;
          this.items = this.filterOnDeleteFlag(this.typeOfService, res);
        }).then(() => {
          this.showSkelton = false;
        });
        break;
      case 'ovf-permits':
        this.csvFileService.fetchCsvListByServiceName(this.typeOfService).then((res:any) => {
          this.showSkelton = false;
          this.items = this.filterOnDeleteFlag(this.typeOfService, res);
        }).then(() =>{
          this.showSkelton = false;
        })
        break;
        case 'slot':
          this.csvFileService.fetchCsvListByServiceName(this.typeOfService).then((res:any) => {
            this.showSkelton = false;
            this.items = this.filterOnDeleteFlag(this.typeOfService, res);
          }).then(() =>{
            this.showSkelton = false;
          })
          break;
        case 'landing':
            this.csvFileService.fetchCsvListByServiceName(this.typeOfService).then((res:any) => {
              this.showSkelton = false;
              this.items = this.filterOnDeleteFlag(this.typeOfService, res);
            }).then(() =>{
              this.showSkelton = false;
            })
            break;
      default:
        break;
    }
  }
  public async csvDetails(item: CsvExcelData) {
    const csvFileDetails = await this.modalController.create({
      component: CsvFileDetailsComponent,
      componentProps: {
        backPage: this,
        csvDetails: item,
        serviceType: this.typeOfService,
      },
    });
    await csvFileDetails.present();
  }
  public async removeItem(item, i) {
    this.commonService.showSpinnerLoader();
    switch (this.typeOfService) {
      case 'Taxes':
        this.taxCsvSer.deleteCsvFile(item.FUDName, item.TEID).then((res) => {
          if (res == 'Success') {
            this.getCsvData();
            this.commonService.dismissLoader();
          } else {
            this.commonService.dismissLoader();
          }
        });
        break;
      case 'handling':
        this.handlingCsvSer.deleteCsvFile(item.FUDName, item.GHEDID).then((res) => {
          if (res == "Success") {
            this.getCsvData();
            this.commonService.dismissLoader();
          } else {
            this.commonService.dismissLoader();
          }
        });
        break;
      case 'fuel':
        this.csvFileService.deleteFuelCsvFile(item.FUDName, item.FPEDID).then((res) => {
          if (res == "Success") {
            this.getCsvData();
            this.commonService.dismissLoader();
          } else {
            this.commonService.dismissLoader();
          }
        });
        break;
      case 'landing':
        this.taxCsvSer.deleteLandingCsv(item.FUDName,item.LEDID, this.typeOfService).then((res) =>{
          if(res == "Success") {
            this.getCsvData();
            this.commonService.dismissLoader();
          } else {
            this.commonService.dismissLoader();
          }
        });
      break;
      case 'slot':
        this.taxCsvSer.deleteSlotCsv(item.FUDName,item.SEDID, this.typeOfService).then((res) =>{
          if(res == "Success") {
            this.getCsvData();
            this.commonService.dismissLoader();
          } else {
            this.commonService.dismissLoader();
          }
        });
      break;
      case 'ovf-permits':
        this.taxCsvSer.deleteOvfCsv(item.FUDName,item.OFEDID, this.typeOfService).then((res) =>{
          if(res == "Success") {
            this.getCsvData();
            this.commonService.dismissLoader();
          } else {
            this.commonService.dismissLoader();
          }
        });
      break;
      default:
        break;
    }
  }
  public filterOnDeleteFlag(type, data) {
    let flitereOnDeleteFlag;
    switch(type) {
      case 'Taxes':
       flitereOnDeleteFlag = data.filter((item:any) => {
          return  item.DeleteFlag === "";
         });
      break;
      case 'fuel':
        flitereOnDeleteFlag = data.filter((item:any) => {
          return  item.DeleteFlag === "0";
         });
      break;
      case 'handling':
        flitereOnDeleteFlag = data.filter((item:any) => {
          return  item.DeleteFlag === "1";
         });
      case 'landing':
        flitereOnDeleteFlag = data.filter((item:any) => {
          return  item.DeleteFlag === "";
         });
      break;
      case 'slot':
        flitereOnDeleteFlag = data.filter((item:any) => {
          return  item.DeleteFlag === "";
         });
      break;
      case 'ovf-permits':
        flitereOnDeleteFlag = data.filter((item:any) => {
          return  item.DeleteFlag === "";
         });
      break;
    }
    return flitereOnDeleteFlag;
  }
  public iconShowStatusWise(status) {
    if (status == 'Pending') {
      return 'ellipsis-horizontal-circle-outline';
    } else if (status == 'Failed') {
      return 'close-circle-outline';
    } else {
      return 'checkmark-circle-outline';
    }
  }
}
