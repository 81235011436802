import { environment } from './../../../environments/environment';
import { CommonService } from '@app/shared/services/common.service';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthSessionService } from '@app/core/services/auth-session.service';
import { UtilService } from '@app/core/services/util.service';
import { MessageConfig } from '@app/shared/constants/constant';
import { AlertController, MenuController, ModalController, ToastController } from '@ionic/angular';
import { Signup } from '../authentication.model';
import { SigninService } from '../services/signin.service';
import { IonIntlTelInputValidators } from 'ion-intl-tel-input';
import { Capacitor } from '@capacitor/core';
import { VerifyOTPPage } from '../verify-OTP/verify-otp/verify-otp.page';
declare var cordova: any;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.page.html',
  styleUrls: ['./signup.page.scss'],
})
export class SignupPage implements OnInit {
  public signUp: Signup = new Signup();
  public signupForm: FormGroup;
  public messageDisplay = MessageConfig;
  public checkVerified=false;
  public isEmailExists:boolean=false;
  constructor(
    private menuCtrl: MenuController,
    private router: Router,
    private signInService: SigninService,
    private utliService: UtilService,
    private formBuilder: FormBuilder,
    private authSession: AuthSessionService,
    public commonService: CommonService,
    private alertController: AlertController,
    private modalController: ModalController
  ) {  }

  public ngOnInit() {
    this.validForm();
  }
  public ionViewWillEnter() {
    // this.signUp.isProd = environment.firebase_config.projectId == "avplat-local" ? false:true;
    this.menuCtrl.enable(false);
  }
  public ionViewWillUnload() {
    this.menuCtrl.enable(true);
  }
  public signin() {
    this.router.navigateByUrl('signin');
  }
  public signup() {
    this.commonService.showSpinnerLoader();
    const contactNumberFormate = this.commonService.contactNumberFormate(this.signUp.ContactNumber);
    this.signUp.ContactNumber = contactNumberFormate.contact
    this.signUp.callingCodes = contactNumberFormate.dialCode
    const signUpData = {
      CompanyName: this.signUp.CompanyName,
      ContactNumber: this.signUp.ContactNumber,
      Email: this.signUp.Email,
      Password: this.signUp.ConfirmPassword,
      ConfirmPassword: this.signUp.Password,
      Role: this.signUp.Role,
      Agreement: this.signUp.Agreement,
      callingCodes: encodeURIComponent(this.signUp.callingCodes)
    }
    this.authSession.newUserRegister = true;
    this.signInService
      .signupEmail(this.signUp)
      .then((singup: any) => {
        this.signInService.setProfileFlag();
        this.signInService
          .saveSingupDetails(signUpData)
          .then((res) => {
            this.commonService.dismissLoader();
            this.authSession.logOut();
            this.authSession.newUserRegister = false;
            this.utliService.showToastCtrl(
              this.messageDisplay.emailVerification
            );
          })
          .catch((error) => {
            this.commonService.dismissLoader();
            this.commonService.showErrorAlert();
          });
      })
      .catch((error) => {
        this.commonService.dismissLoader();
        this.utliService.errorAlert(
          MessageConfig.LoginErrorTitle,
          MessageConfig.WrongSignupAlert
        );
      });
  }
  public setRole(role) {
    switch (role) {
      case 'client':
        this.signUp.Role = 2;
        break;
      case 'vendor':
        this.signUp.Role = 3;
        break;
      case 'charter':
        this.signUp.Role = 7;
        break;
      default:
        break;
    }
  }
  public agree() {
    if (this.signUp.Agreement == true) {
      this.signUp.Agreement = false;
    } else {
      this.signUp.Agreement = true;
    }
  }

  public validForm() {
    this.signupForm = this.formBuilder.group(
      {
        CompanyName: [
          { value: this.signUp.CompanyName },
          Validators.compose([
            Validators.required,
            Validators.pattern('^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$'),
          ]),
        ],
        ContactNumber: [
          { value: this.signUp.ContactNumber },
          Validators.compose([
            Validators.required,
          ]),
        ],
        Email: [
          { value: this.signUp.Email },
          Validators.compose([
            Validators.required,
            Validators.pattern(
              '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$'
            ),
          ]),
        ],
        Password: [
          { value: this.signUp.Password },
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            Validators.pattern('^([a-zA-Z0-9_!@#$%&*()+-=`~]){8,}$'),
          ]),
        ],
        ConfirmPassword: ['', Validators.required],
      },
      { validator: this.signInService.MustMatch('Password', 'ConfirmPassword') }
    );
  }
  public keyDownFunction(event) {
    if( (this.signupForm.valid && this.signUp.Agreement))
    {
      if (event.keyCode === 13) {
        this.signup();
      }
    }
    else{
      this.commonService.dismissLoader();
    }
  }
  // InAppBrowser URL
  public ViewInBrowser() {
    const ref =
    Capacitor.isNativePlatform()
        ? cordova.InAppBrowser.open(MessageConfig.InAppBrowserURL, '_blank', 'location=yes')
        : window.open(MessageConfig.InAppBrowserURL, '_blank');
  }
  public async generateVerificationCode() {
    // if(this.signUp.isProd) // For Production
    // {
    const userContactNumber= this.signupForm.value.ContactNumber.internationalNumber.replace(/\s/g, "");
    this.signInService.sendContactNumberForOTP(userContactNumber)
    const modal = await this.modalController.create({
      component: VerifyOTPPage,
      componentProps: {
        formData: this.signupForm.value.ContactNumber, // Pass your form data here
      },
      backdropDismiss: false,
    });
    modal.onDidDismiss().then((data) => {
      if (data.data && data.data.otpVerified) {
        // OTP has been verified, now execute the signup function
        this.signup();
        this.signupForm.reset();
      }
    });
    return await modal.present();
  // }
  // else
  // {
  //   this.signup(); // For Staging
  // }
  }
  public checkEmailExistence() {
    if(this.signUp.Email)
    {
      this.commonService.showSpinnerLoader();
      this.signInService.checkEmailExists(this.signUp.Email).then((emailExists) => {
        if (emailExists) {
          this.isEmailExists=true;
          // Email already exists
              this.commonService.dismissLoader();
        } else {
          // Email doesn't exist
          this.commonService.dismissLoader();
          this.isEmailExists=false;
        }
      });
    }
  }
}
