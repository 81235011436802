import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enterDecimal',
})
export class EnterDecimalPipe implements PipeTransform {
  public transform(ev): unknown {
    return [ev].filter(Boolean).length && String(ev).startsWith('.')
      ? '0' + ev
      : ev;
  }
}
