import { Vendor } from './../../trips/schedule-creation/model/schedule-model';
import { Deserializable } from "@app/estimates/model/estimates.model";
import * as moment from 'moment';
import { EstimateStatusList } from '../config/charter-constant';
export class CharterLegInformationDeatils implements Deserializable{
    public fromStation: StationDetails;
    public toStation: StationDetails;
    public departDate: string;
    public timeType: string;
    public noOfCrew: number;
    public pax: number;
    public flightType: string;
    public operationType: string;
    public stopType: string; // check
    public flightRule: string;
    public fuelPolicy: string = 'MaximumFuelPolicy';
    public adequate: string = 'Auto';
    public alt1: string;
    public alt2: string;
    public depAlt: string;
    public fuelonBoard: number;
    public etops: string;
    public flightLvelalt1: number;
    public flightLvelalt2: number;
    public cruiseSpeed: string;
    public aircraftId: string;
    public maxflightlevelUnit: string;
    public route: any;
    public arrivalTime: any;
    public serviceProvider: any[];
    public BLKTIME: string;
    public legRouteid: string;
    public flightPlanresponseID: string;
    public overflyingCountry = [];
    public fuelOnLast: FuelLastStationDetails;
    public DIST: number;
    public ETDGMT: string;
    public ETAGMT: string;
    public ETALT: string;
    public ETDLT: string;
    public vendorSelectionCompleted: boolean;
    public legId: number;
    // it will done by model
    public sector: string;
    public flightLevelType: string = 'OptimumFlightLevelDefinition';
    public regd?: string;
    public flightLvel: number;
    public extrafuel: number;
    public fuelUnit: string = 'Pound';
    public baseStationEditable: boolean;
    // this below two will come from route
    public GMTPLUSORIGIN: string;
    public GMTPLUSDEST: string;
    // it will assign later
    public DraftEstimateId: number;
    // leg changes
    // if leg affecting after any changes
    public legAffected: boolean;
    // is the leg is able to edit or not
    public legEditable: boolean;
    // status of the leg
    public legStatus: string;
    // is the route able to edit
    public routeEditable: boolean;
    // if the leg modified by time or station then it will be true its for backend
    public modifiedLeg: boolean;
    // if the edit button clicked of the leg
    public legOnHighlight: boolean;
    // to add a leg befor the selecetd leg
    public addLeg: boolean;
    // srid for the legchange time
    public srid?: number;
    // if the leg delete at the time of edit then it will be true
    public removed?: boolean = false;
    // the id coming from backend after trip posting
    public backendLegId?: number;
    // cann't do any oparation with this leg
    public noOperation: boolean;
    // vendor editable i.e if any modification happen then it will be true
    public vendorEditable: boolean;
    // either leg got some change opr not
    public gotModified: boolean;
    public noOfPax:number;
    public timezonedetails: Timezonedetails;
    constructor(
      fromStationData?: StationDetails,
      toStation?: StationDetails,
      departDate?: string,
      timeType?: string,
      noOfCrew?: number,
      noOfPax?: number,
      flightType?: string,
      operationType?: string,
      stopType?: string,
      flightRule?: string,
      fuelPolicy?: string,
      adequate?: string,
      alt1?: string,
      alt2?: string,
      depAlt?: string,
      fuelonBoard?: number,
      etops?: string,
      flightLvelalt1?: number,
      flightLvelalt2?: number,
      cruiseSpeed?: string,
      aircraftId?: string,
      maxflightlevelUnit?: string,
      route?: any,
      arrivalTime?: any,
      serviceProvider?: any,
      BLKTIME?: string,
      legRouteid?: string,
      flightPlanresponseID?: string,
      overflyingCountry?: any,
      travelDistrance?: number,
      ETDGMT?: string,
      ETAGMT?: string,
      ETALT?: string,
      ETDLT?: string,
      vendorSelectionCompleted?: boolean,
      legId?: number,
      flightLevelType?: string,
      regd?: string,
      flightLvel?: number,
      extrafuel?: number,
      fuelUnit?: string,
      baseStationEditable?: boolean,
      legAffected?: boolean,
      legStatus?: string,
      modifiedLeg?: boolean,
      legOnHighlight?: boolean,
      addLeg?: boolean,
      backendLegId?: number,
      gotModified?: boolean,
      srid?: number,
      removed?: boolean,
      fuelOnLastStn?: FuelLastStationDetails
    ) {
      this.fromStation = fromStationData;
      this.toStation = toStation;
      this.fuelOnLast = fuelOnLastStn;
      this.departDate = departDate;
      this.timeType = timeType;
      this.noOfCrew = noOfCrew;
      this.pax  = noOfPax;
      this.flightType = flightType;
      this.operationType = operationType;
      this.stopType = stopType;
      this.flightRule = flightRule;
      this.fuelPolicy = fuelPolicy;
      this.adequate = adequate;
      this.alt1 = alt1;
      this.alt2 = alt2;
      this.depAlt = depAlt;
      this.fuelonBoard = fuelonBoard;
      this.etops = etops;
      this.flightLvelalt1 = flightLvelalt1;
      this.flightLvelalt2 = flightLvelalt2;
      this.cruiseSpeed = cruiseSpeed;
      this.aircraftId = aircraftId;
      this.maxflightlevelUnit = maxflightlevelUnit;
      this.route = route;
      this.arrivalTime = arrivalTime;
      this.GMTPLUSORIGIN = route.timezonedetails.UTC1;
      this.GMTPLUSDEST = route.timezonedetails.UTC2;
      this.serviceProvider = serviceProvider;
      this.BLKTIME = BLKTIME;
      this.legRouteid = legRouteid;
      this.flightPlanresponseID = flightPlanresponseID;
      this.overflyingCountry = overflyingCountry;
      this.DIST = travelDistrance;
      this.ETDGMT = ETDGMT;
      this.ETAGMT = ETAGMT;
      this.ETALT = ETALT;
      this.ETDLT = ETDLT;
      this.vendorSelectionCompleted = vendorSelectionCompleted;
      this.legId = legId + 1;
      this.sector =
        this.fromStation.name.substr(0, 4) +
        '-' +
        this.toStation.name.substr(0, 4);
      if (flightLevelType && flightLevelType != '') {
        this.flightLevelType = flightLevelType;
      }
      this.regd = regd;
      this.flightLvel = flightLvel;
      this.extrafuel = extrafuel;
      this.fuelUnit = fuelUnit;
      this.srid = srid ? srid : this.srid;
      this.removed = removed ? true : this.removed;
      // if it coming from activated leg
      if (backendLegId) {
        this.backendLegId = backendLegId;
      } else {
        this.backendLegId = 0;
      }
      this.gotModified = gotModified;
      // logic only for leg change code by saroj
      this.legAffected = legAffected;
      this.legEditable = this.legEditableStatus(legStatus);
      if (legStatus == 'newleg') {
        this.routeEditable = true;
      } else {
        this.routeEditable = false;
      }
      if (legStatus == 'newleg' || gotModified) {
        this.vendorEditable = true;
      } else {
        this.vendorEditable = false;
      }
      this.noOperation = this.legnooperation(legStatus, legAffected);
      this.legStatus = legStatus;
      this.modifiedLeg = modifiedLeg;
      this.legOnHighlight = legOnHighlight;
      this.baseStationEditable = baseStationEditable;
      this.addLeg = addLeg;
    }
    public deserialize(input: any): this {
      Object.assign(this, input);
      this.fromStation = new StationDetails(
        input.fromStation.name,
        input.fromStation.timeZone
      ).deserialize(input.fromStation);
      this.toStation = new StationDetails(
        input.toStation.name,
        input.toStation.timeZone
      ).deserialize(input.toStation);
      return this;
    }
    public legEditableStatus(status: string): boolean {
      let returnStatus: boolean = false;
      switch (status) {
        case 'newleg':
          returnStatus = true;
          break;
        case 'Processing':
          returnStatus = true;
          break;
        case 'Confirmed':
          returnStatus = true;
          break;
        case 'Inprogressstarting':
          returnStatus = false;
          break;
        case 'Inprogressfinishing':
          returnStatus = false;
          break;
        case 'Inprogressfinished':
          returnStatus = false;
          break;
        case 'Completed':
          returnStatus = false;
          break;
        case 'Closed':
          returnStatus = false;
          break;
        case 'Cancelled':
          returnStatus = false;
          break;
      }
      return returnStatus;
    }

    public legAffectedStatus(status: string): boolean {
      let returnStatus: boolean = false;
      switch (status) {
        case 'newleg':
          returnStatus = false;
          break;
        case 'editleg':
          returnStatus = true;
          break;
        default:
          returnStatus = false;
          break;
      }
      return returnStatus;
    }
    public legrouteEditable(status: string): boolean {
      let returnStatus: boolean = false;
      switch (status) {
        case 'newleg':
          returnStatus = true;
          break;
        case 'editleg':
          returnStatus = true;
          break;
        case 'Inprogress':
          returnStatus = false;
          break;
        case 'Upcoming':
          returnStatus = true;
          break;
        case 'Completed':
          returnStatus = false;
          break;

        default:
          returnStatus = false;
          break;
      }
      return returnStatus;
    }

    public legvendorEditable(status: string): boolean {
      let returnStatus: boolean = false;
      switch (status) {
        case 'newleg':
          returnStatus = true;
          break;
        case 'editleg':
          returnStatus = true;
          break;
        case 'Inprogress':
          returnStatus = false;
          break;
        case 'Upcoming':
          returnStatus = false;
          break;
        case 'Completed':
          returnStatus = false;
          break;

        default:
          returnStatus = false;
          break;
      }
      return returnStatus;
    }

    public legnooperation(status: string, affected: boolean): boolean {
      let returnStatus: boolean = false;
      if (affected) {
        returnStatus = true;
      } else {
        switch (status) {
          case 'newleg':
            returnStatus = false;
            break;
          case 'Processing':
            returnStatus = false;
            break;
          case 'Confirmed':
            returnStatus = false;
            break;
          case 'Inprogressstarting':
            returnStatus = false;
            break;
          case 'Inprogressfinishing':
            returnStatus = true;
            break;
          case 'Inprogressfinished':
            returnStatus = true;
            break;
          case 'Completed':
            returnStatus = true;
            break;
          case 'Closed':
            returnStatus = true;
            break;
          case 'Cancelled':
            returnStatus = true;
            break;
        }
      }

      return returnStatus;
    }
  }
  export class StationDetails implements Deserializable {
    public name: string;
    public timeZone: string;
    constructor(name: string, timeZone: string) {
      this.name = name;
      this.timeZone = timeZone;
    }
    public deserialize(input: any): this {
      return Object.assign(this, input);
    }
  }
  export class FuelLastStationDetails implements Deserializable {
    public fuelLastStnReq: string;
    public fuelQuantity: any;
    public fuelUnit: string;
    public lastStation: string;
    constructor(
      fuelLastStnReq: string,
      fuelQuantity: any,
      fuelUnit: string,
      lastStation: string
    ) {
      this.fuelLastStnReq = fuelLastStnReq;
      this.fuelQuantity = fuelQuantity;
      this.fuelUnit = fuelUnit;
      this.lastStation = lastStation;
    }
    public deserialize(input: any): this {
      return Object.assign(this, input);
    }
  }
  export class Charter implements Deserializable {
    public name: string;
    public logo: string;
    public price: number;
    public vendorId: any;
    public UID: string;
    public sector: string;
    public station: string;
    public chargeId: number;
    public serviceName: string;
    public legId: number;
    public backendFormat: any;
    public sharray: any;
    public clickable?: boolean;
    public responseMessage?: any;
    public carbonoffsetstatus?: number;
    constructor(
      name: string,
      logo: string,
      price: number,
      vendorId: any,
      UID: string,
      sector: string,
      station: string,
      chargeId: number,
      serviceName: string,
      backendFormat: any,
      legId: number,
      sharray?: any,
      ResellerName?: string,
      Handlername?: string,
      TypeofVendor?: string,
      clickable?: boolean,
      responseMessage?: boolean,
      carbonoffsetstatus?: number
    ) {
      this.name = name;
      this.logo = logo;
      this.price = Number(price);
      this.vendorId = vendorId;
      this.UID = UID;
      this.sector = sector;
      this.station = station;
      this.chargeId = chargeId;
      this.serviceName = serviceName;
      this.backendFormat = backendFormat;
      this.legId = legId;
      this.carbonoffsetstatus = carbonoffsetstatus;
      if (sharray) {
        this.sharray = sharray;
      }
      if (!clickable) {
        this.clickable = true;
      } else {
        this.clickable = false;
      }
      this.responseMessage = responseMessage;
    }
    public deserialize(input: any): this {
      return Object.assign(this, input);
    }
  }
  export class CharterBackendActivateTripLeglistFormat implements Deserializable {
    public fromStation: StationDetails;
    public toStation: StationDetails;
    public departDate: string;
    public timeType: string;
    public noOfCrew: number;
    public noOfPax: number;
    public flightType: string;
    public operationType: string;
    public stopType: string; // check
    public flightRule: string;
    public fuelPolicy: string;
    public adequate: string;
    public alt1: string;
    public alt2: string;
    public depAlt: string;
    public fuelonBoard: number;
    public etops: string;
    public flightLvelalt1: number;
    public flightLvelalt2: number;
    public cruiseSpeed: string;
    public aircraftId: string;
    public maxflightlevelUnit: string;
    public serviceProvider = [];
    public BLKTIME: string;
    public legRouteid: string;
    public flightPlanresponseID: string;
    public overflyingCountry = [];
    public DIST: number;
    public ETDGMT: string;
    public ETAGMT: string;
    public ETALT: string;
    public ETDLT: string;
    public legId: number;
    public sector: string;
    public flightLevelType: string;
    public flightLvel: number;
    public extrafuel: number;
    public fuelUnit: string;
    public GMTPLUSORIGIN: string;
    public GMTPLUSDEST: string;

    constructor(LegInfo: CharterLegInformationDeatils,type) {
        this.fromStation = LegInfo.fromStation;
        this.toStation = LegInfo.toStation;
        if (LegInfo.timeType == "true") {
            this.departDate = this.covertUTCtime(
                LegInfo.fromStation.timeZone,
                LegInfo.departDate,
            );
        } else {
            let departDate;
            if (LegInfo.departDate.includes('+')) {
                departDate = LegInfo.departDate.slice(0, -6) + 'Z'
            }
            else {
                departDate = LegInfo.departDate;
            }
            this.departDate = departDate;
        }
        this.timeType = LegInfo.timeType;
        this.noOfCrew = LegInfo.noOfCrew;
        this.noOfPax = type == "saveEstimate" ?LegInfo.noOfPax  :LegInfo.pax ;
        this.flightType = LegInfo.flightType;
        this.operationType = LegInfo.operationType;
        this.stopType = LegInfo.stopType;
        this.flightRule = LegInfo.flightRule;
        this.fuelPolicy = LegInfo.fuelPolicy;
        this.adequate = LegInfo.adequate;
        this.alt1 = LegInfo.alt1;
        this.alt2 = LegInfo.alt2;
        this.depAlt = LegInfo.depAlt;
        this.fuelonBoard = LegInfo.fuelonBoard;
        this.etops = LegInfo.etops;
        this.flightLvelalt1 = LegInfo.flightLvelalt1;
        this.flightLvelalt2 = LegInfo.flightLvelalt2;
        this.cruiseSpeed = LegInfo.cruiseSpeed;
        this.aircraftId = LegInfo.aircraftId;
        this.maxflightlevelUnit = LegInfo.maxflightlevelUnit;
        LegInfo.serviceProvider.forEach((element: Charter) => {
            this.serviceProvider.push(Object.assign({}, element));
        });

        this.BLKTIME = LegInfo.BLKTIME;
        this.legRouteid = LegInfo.legRouteid;
        this.flightPlanresponseID = LegInfo.flightPlanresponseID;
        this.overflyingCountry = LegInfo.overflyingCountry;
        this.DIST = LegInfo.DIST;
        this.ETDGMT = LegInfo.ETDGMT;
        this.ETAGMT = LegInfo.ETAGMT;
        this.ETALT = LegInfo.ETALT;
        this.ETDLT = LegInfo.ETDLT;
        this.legId = LegInfo.legId;
        this.sector = LegInfo.sector;
        this.flightLevelType = LegInfo.flightLevelType;
        this.flightLvel = LegInfo.flightLvel;
        this.extrafuel = LegInfo.extrafuel;
        this.fuelUnit = LegInfo.fuelUnit;
        this.GMTPLUSORIGIN = LegInfo.GMTPLUSORIGIN;
        this.GMTPLUSDEST = LegInfo.GMTPLUSDEST;
        this.serviceProvider.forEach((element: any) => {
            element.logo = "";
        });
    }
    public deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
    public covertUTCtime(timeZone, time) {
        return moment.tz(time, timeZone).utc().format();
    }
}
export class CharterBackendActivateTripTripFormat {
    public legList = new Array<CharterBackendActivateTripLeglistFormat>();
    public SectorServiceArray = [];
    public StationServiceArray = [];
    public TotalCost: number;
}
export class EstimateBackendFormatSavedraft implements Deserializable {
  public fromStation: StationDetails;
  public toStation: StationDetails;
  public departDate: string;
  public timeType: string;
  public noOfCrew: number;
  public noOfPax: number;
  public flightType: string;
  public operationType: string;
  public stopType: string; // check
  public flightRule: string;
  public fuelPolicy: string = "MaximumFuelPolicy";
  public adequate: string = "Auto";
  public alt1: string;
  public alt2: string;
  public depAlt: string;
  public fuelonBoard: number;
  public etops: string;
  public flightLvelalt1: number;
  public flightLvelalt2: number;
  public cruiseSpeed: string;
  public aircraftId: string;
  public maxflightlevelUnit: string;
  public serviceProvider = [];
  public BLKTIME: string;
  public legRouteid: string;
  public flightPlanresponseID: string;
  public overflyingCountry = [];
  public DIST: number;
  public ETDGMT: string;
  public ETAGMT: string;
  public ETALT: string;
  public ETDLT: string;
  public vendorSelectionCompleted: boolean;
  public legId: number;
  public sector: string;
  public flightLevelType: string = "OptimumFlightLevelDefinition";
  public flightLvel: number;
  public extrafuel: number;
  public fuelUnit: string = "Pound";
  public legAffected: boolean;
  public legEditable: boolean;
  public newLeg: boolean;
  public legOnHighlight: boolean;
  public legTotalCost: number;
  public baseStationEditable: boolean;
  public GMTPLUSORIGIN: string;
  public GMTPLUSDEST: string;
  public sharray = [];
  public removed: boolean = false;

  constructor(LegInfo: CharterLegInformationDeatils) {
      this.fromStation = LegInfo.fromStation;
      this.toStation = LegInfo.toStation;
      this.departDate = this.covertUTCtime(
          LegInfo.fromStation.timeZone,
          LegInfo.departDate,
      );
      this.timeType = LegInfo.timeType;
      this.noOfCrew = LegInfo.noOfCrew;
      this.noOfPax = LegInfo.pax ;
      this.flightType = LegInfo.flightType;
      this.operationType = LegInfo.operationType;
      this.stopType = LegInfo.stopType;
      this.flightRule = LegInfo.flightRule;
      this.fuelPolicy = LegInfo.fuelPolicy;
      this.adequate = LegInfo.adequate;
      this.alt1 = LegInfo.alt1;
      this.alt2 = LegInfo.alt2;
      this.depAlt = LegInfo.depAlt;
      this.fuelonBoard = LegInfo.fuelonBoard;
      this.etops = LegInfo.etops;
      this.flightLvelalt1 = LegInfo.flightLvelalt1;
      this.flightLvelalt2 = LegInfo.flightLvelalt2;
      this.cruiseSpeed = LegInfo.cruiseSpeed;
      this.GMTPLUSORIGIN = LegInfo.GMTPLUSORIGIN;
      this.GMTPLUSDEST = LegInfo.GMTPLUSDEST;
      this.aircraftId = LegInfo.aircraftId;
      this.maxflightlevelUnit = LegInfo.maxflightlevelUnit;
      LegInfo.serviceProvider.forEach((element: Vendor) => {
          if (element.backendFormat) {
              element.backendFormat.logo = "";
          }
          this.serviceProvider.push({
              name: element.name,
              logo: "",
              price: element.price,
              vendorId: element.vendorId,
              UID: element.UID,
              sector: element.sector,
              station: element.station,
              chargeId: element.chargeId,
              serviceName: element.serviceName,
              legId: element.legId,
              backendFormat: element.backendFormat,
              sharray: element.sharray,
              carbonoffsetstatus: element.carbonoffsetstatus,
          });
      });
      this.BLKTIME = LegInfo.BLKTIME;
      this.legRouteid = LegInfo.legRouteid;
      this.flightPlanresponseID = LegInfo.flightPlanresponseID;
      this.overflyingCountry = LegInfo.overflyingCountry;
      this.DIST = LegInfo.DIST;
      this.ETDGMT = LegInfo.ETDGMT;
      this.ETAGMT = LegInfo.ETAGMT;
      this.ETALT = LegInfo.ETALT;
      this.ETDLT = LegInfo.ETDLT;
      this.vendorSelectionCompleted = LegInfo.vendorSelectionCompleted;
      this.legId =
          LegInfo.backendLegId != 0
              ? LegInfo.backendLegId
              : LegInfo.srid
                  ? LegInfo.backendLegId
                  : LegInfo.legId
                      ? LegInfo.legId
                      : LegInfo.serviceProvider[0].legId;
      this.sector =
          this.fromStation.name.substr(0, 4) +
          "-" +
          this.toStation.name.substr(0, 4);
      if (LegInfo.flightLevelType && LegInfo.flightLevelType != "") {
          this.flightLevelType = LegInfo.flightLevelType;
      }
      this.flightLvel = LegInfo.flightLvel;
      this.extrafuel = LegInfo.extrafuel;
      this.fuelUnit = LegInfo.fuelUnit;

      this.legAffected = LegInfo.legAffected;
      this.legEditable = LegInfo.legEditable;
      this.newLeg = LegInfo.modifiedLeg;
      this.legOnHighlight = LegInfo.legOnHighlight;
      this.baseStationEditable = LegInfo.baseStationEditable;
  }
  public covertUTCtime(timeZone, time) {
      return moment.tz(time, timeZone).utc().format();
  }

  public deserialize(input: any): this {
      Object.assign(this, input);
      this.fromStation = new StationDetails(
          input.fromStation.name,
          input.fromStation.timeZone,
      ).deserialize(input.fromStation);
      this.toStation = new StationDetails(
          input.toStation.name,
          input.toStation.timeZone,
      ).deserialize(input.toStation);
      return this;
  }

}
export interface Timezonedetails {
  dist:string;
  TimeZone:string;
  UTC1:string;
  UTC2:string;
  fromAirportTimezone:string;
  toAirportTimezone:string;
}
export interface ServiceProvider {
    sector: string;
    firebaseuid: string;
    vendorid: string;
    Totalprice: number;
    logo: string;
    VendorName: string;
    type: string;
    station: string;
    legid: number;
    name: string;
    price: number;
    vendorId: string;
    serviceName: string;
    legId: number;
    UID: string;
    registertype: string;
    chargeId: string;
    sharray: string;
    countryList: string;
    autoselect?: string;
    ServiceName:string
}
export class CharterEstimateQoutes{
  public Chrateperhour:any;
  public TotalBlktime:any;
  public CharterPrice:number
  public ELDiscount:any;
  public BrokerageCost:any;
  public BrokerageCalculated:any;
  public BrokerageCostCalculated:any;
  public BrokerageCostUnit:any;
  public TotalELdiscount:number;
  public ServicescostIncluded:any;
  public ServicescostExcluded:any;
  public ELDPrice:number;
  public legList: LegList[];
  public brokrageCost:number;
  public charterdata: any;
}

export interface LegList {
  from: string;
  to: string;
  depart: Date;
  timeZone: string;
  clientseltimeZone: string;
  flightRule: string;
  flightType: string;
  operationType: string;
  BLKTIME: string;
  ETDGMT: string;
  ETDLT: string;
  ETAGMT: string;
  ETALT: string;
  routeID: number;
  TOTALBLKTIME: number;
  crew: number;
  pax: number;
  ETOPS: string;
  fromStation: FromStation;
  toStation: ToStation;
  departDate: Date;
  timeType: string;
  etops: string;
  aircraftId: string;
  legRouteid: number;
  flightPlanresponseID: number;
  sector: string;
  serviceProvider: ServiceProvider[];
  ServicesCostForServices:ServicesCostForServices[];
}
export interface ServicesCostForServices {
  Fuel:string;
  GroundHandling:string;
}
export interface FromStation {
  name: string;
  timeZone: string;
}

export interface ToStation {
  name: string;
  timeZone: string;
}
export class Estimates {
  public badgeShow: boolean = false;
  public vendorLogin: boolean = false;
  public operatorLogin: boolean = false;
  public show: boolean = false;
  public allowToScrollData: boolean = true;
  public statusList = EstimateStatusList;
  public typeOfList: string = "All";
  public loadStatus: string = "";
  public filterStatus: string = "";
  public searchItemEv: any;
  public roleID: any;
}