import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, throwError } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { UtilService } from './util.service';
import { MessageConfig } from '@app/shared/constants/constant';

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  public token: any;
  constructor(
    private utilService: UtilService,
  ) { }

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        setHeaders: {
          'content-type': 'application/x-www-form-urlencoded',
        },
      });
    }
    request = request.clone({
      headers: request.headers.set(
        'Accept',
        'application/x-www-form-urlencoded'
      ),
    });
    request = request.clone({
      setHeaders: {
        'x-api-token': this.utilService.token ? this.utilService.token : '',
      },
    });
    if (this.requestUrl(request)) {
      request = request.clone({
        url: `${this.utilService.actualURL}${request.url}`,
      });
    }
    if (request.url.includes('api/v1')) {
      request = request.clone({
        url: `${request.url}`,
      });
    }
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        let errorMsg: string = '';
        if (error?.error?.status === 'unauthorized' || error.status === 401) { }
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        }
        if (!navigator.onLine) {
          this.utilService.showToastCtrlMessage(MessageConfig.wifiIcon + MessageConfig.offlineAlert);
         } else {
          errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
        }
        this.utilService.dismissLoader();
        return throwError(error);
      }),
      // finalize(() => {
      //   // this.utilService.dismissLoader();
      // })
    );
  }
  public includesArray = [
    'assets',
    'velocityweather',
    'v1/ComputeFlight',
    'v1/WeatherNotam',
    'v1/FileFlightPlan/manualFile',
    'v1/FileFlightPlan/cancel',
    'v1/FileFlightPlan/delay',
    'v1/FileFlightPlan/change',
    'v1/ValidateRoute',
    'v1/ProductionAndRemarks',
  ];
  public requestUrl(request) {
    for (const index of this.includesArray) {
      if (!index) {
        return (request = request.clone({
          url: `${this.utilService.actualURL}${request.url}`,
        }));
      }
    }
  }
}
