import { LatlngArr } from "../model/route.model";

export const tripsStatusList = [
  { icon: 'infinite-outline', status: 'All', loadStatus: 'tripall' },
  { icon: 'contrast-outline', status: 'Pending', loadStatus: 'Processing' },
  {
    icon: 'thumbs-down-outline',
    status: 'Declined',
    loadStatus: 'declined',
  },
  { icon: 'timer-outline', status: 'Upcoming', loadStatus: 'Confirmed' },
  {
    icon: 'play-forward-outline',
    status: 'In Progress',
    loadStatus: 'Active',
  },
  {
    icon: 'checkmark-circle-outline',
    status: 'Completed',
    loadStatus: 'Closed',
  },
  {
    icon: 'close-circle-outline',
    status: 'Cancelled',
    loadStatus: 'Tripcancelled',
  },
];
export const ServiceName = {
  overflight: 'overflight',
  flightplanning: 'flightplanning',
  fuel: 'fuel',
  handling: 'handling',
  airportcharges: 'airportcharges',
};
export const inProgressLegStages = [
  'Inprogressstarting',
  'Inprogressfinishing',
  'Inprogressfinished',
];
export const ACTION_ITEM_NAME = {
  aircrafthaslanded: 'Landed',
  chockson: 'Chocks on',
  passengershavecompletedciq: 'Pax Departed',
  passengershavecompletedciqDep: 'Pax Arrived',
  arrivalghservicesformsignoff: 'Arr GH Services Form Signoff',
  handlingdelivered: 'Arr Handling Delivered',
  arrivalHandling: 'Arr Handling',
  arrivalAirportcharges: 'Arr Apt Fees & Permits',
  arrivalairportchargesdelivered: 'Arr Airport Charges Delivered',

};
export const ACTIVITY_SERVICES = {
  AIRCRAFT_HAS_LANDED: 'aircrafthaslanded',
  CHOCKS_ON: 'chockson',
  PASSENGERS_HAVE_COMPLETED_CIQ: 'passengershavecompletedciq',
  ARRIVAL_GHSERVICES_FORM_SIGNOFF: 'arrivalghservicesformsignoff',
  HANDLING_DELIVERED: 'handlingdelivered',
  ARRIVAL_HANDLING: 'arrivalHandling',
  ARRIVAL_AIRPORT_CHARGES: 'arrivalAirportcharges',
  ARRIVAL_AIRPORT_CHARGES_DELIVERED: 'arrivalairportchargesdelivered',
};
export const ACTION_ITEM_ORDER = {
  aircrafthaslanded: 15,
  chockson: 16,
  passengershavecompletedciq: 17,
  arrivalghservicesformsignoff: 19,
  handlingdelivered: 20,
  arrivalHandling: 18,
  arrivalAirportcharges: 21,
  arrivalairportchargesdelivered: 22,
};
export const HANDLING_ACTION_ITEM_LIST = [
  'aircrafthaslanded',
  'chockson',
  'passengershavecompletedciq',
  'arrivalghservicesformsignoff',
  'handlingdelivered',
  'arrivalHandling',
];
export const CONSTANT_MESSAGES = {
  AIR_PERMISSIONS: "airpermissions",
  OPERATOR: "Operator",
  STATION: "station",
  TIMER: "timer",
  PROGRESS_BAR: "progressbar",
  serviceProviderAccept: "You want to provide this Service?",
  tripRejectMessage: "You want to decline this service ?"
};
export const CancelTripMessage = {
  cancelTripContent: "Please wait while we cancelling the trip...",
  tripCancelConfirmation: "You want to cancel the Trip?"

}
export const ROUTE_SERVICE_CONSTANTS = {
  gettingRouteDetails: "Please wait we are getting the route details ...",
  validateRoute: "Please wait while we validate the route...",
  selectFlightPlan: "Please wait while we select flight plan",
  sortICAOPlan: "Please wait while we fetch route details",
  FilingOfFlightPlan: "Please wait while we file the flight plan",
  GenerateTripKit: "Please wait while we Generate Trip Kit",
  AttachTripKit: "Please wait while we Attach Trip Kit",
  UpdateOfFlightPlan: "Please wait while we update the flight plan...",
  UploadFlightPlan: "Please wait while we submit the plan",
}
export const TRIP_SERVICE_CONSTANT = {
  registerTripLoader: "Activating Trip...",
  estimateText: "Saving trip cost estimate.",
}

export const PageHeader = {
  NavFees: "Nav Fees",
  OvfPermits: "Ovf Permits",
  OverflightPermits: "Nav Fees & Permits",
  Overflight: "Overflight Permits",
  Permit: "Nav Fees & Permits",
  FlightPlanning: "Flight Planning",
  FileFlightPlan: "File Flight Plan",
  FileFlightPlanAndGenerateTripKit: "File And Generate Trip Kit",
  DelayFlightPlan: "Delay Flight Plan",
  ChangeFlightPlan: "Change Flight Plan",
  Fuel: "Fuel",
  TripKit: "Trip Kit",
  TripKitAttach: "Trip Kit Attachment",
  AttachCBP: "Attach CBP",
  Handling: "Handling",
  ArrHandling: "Arr Handling",
  DepHandling: "Dep Handling",
  ArrDepHandling: "Arr & Dep Handling",
  AirportCharges: "Apt Fees & Permits",
  ArrDepAirportCharges: "Arr & Dep Airport Charges",
  AirportChargesPermission: "Apt Fees & Permits",
  ArrAirportCharges: "Arr Apt Fees & Permits",
  ArrAirportChargesPermission: "Apt Fees & Permits",
  DepAirportCharges: "Dep Apt Fees & Permits",
  DepAirportChargesPermission: "Apt Fees & Permits",
  CBPFinaalize: "CBP Finalize",
  TripKitFinaalize: "Trip Kit Finalize",
  SelectFlightPlan: "Finalize Flight Plan",
  SelectFuelOn: "Select Fuel On",
  SelectHandlingServices: "Select Handling Services",
  groundhandling: "Handling",
  airportcharges: "Apt Fees & Permits",
  crewbriefing: "Flight Planning",
  Navigation: "Nav Fees & Permits",
};
export enum VendorServiceRequest {
  Accept = 'You want to provide this Service?',
  Declined = "You don't want to provide this Service?",
}
export enum ServiceRequestStatus {
  ServiceThere = 'serviceremains',
  NoServiceThere = 'nomoreserviceremains',
}
export enum tripUrlConstName {
  trips = 'trips',
  estimate = 'estimates',
  flightPlan = 'flight-plan-listing',
  riviseSchedule = 'schedule'
}
export interface FlightPlanObj {
  CrewBriefingAttachmentID: string;
  SRID: string;
  SRSecID: string;
  respID: string;
  Sector: string;
  AttachmentName: string;
  AttachmentFileName: string;
  Finalize: string;
  Selected: string;
  Cbpname: string;
  Filed: string;
  ShortFiledFlightPlan: string;
  FiledId: string;
  ATCFilingType?: any;
  legID: string;
  AircraftID: string;
  RouteID: string;
  RouteType: string;
  Valid: string;
  VendorID: string;
  FlightType: string;
  Current_ATC_Status?: any;
  FuelBurn: string;
  Time: string;
  GroundDistance: string;
  FlightPlanResId: string;
  FlightLevel: string;
  Profile: string;
  latlngArr: LatlngArr[];
}