import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appUppercase]'
})
export class UppercaseDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event.target']) public onInput(input: HTMLTextAreaElement) {
    const start = input.selectionStart;
    const end = input.selectionEnd;

    input.value = input.value.toUpperCase();

    // Set the cursor position to the end of the inserted text
    input.setSelectionRange(start, end);
  }
}
