import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AuthSessionService } from './services/auth-session.service';
import { ServerConnectService } from './services/server-connect.service';


@NgModule({
  providers: [
    AuthSessionService,
    {
      provide: APP_INITIALIZER,
      useFactory: (ds: AuthSessionService) => () => {
        return ds.loadSession();
      },
      deps: [AuthSessionService],
      multi: true,
    },
    ServerConnectService,
  ],
})
export class CoreModule { }
