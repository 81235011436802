import { Injectable } from '@angular/core';
import { ServerConnectService } from '@app/core/services/server-connect.service';
import { ApiTypes } from '@app/shared/constants/constant';
import { queryParams } from '@app/shared/utils/query-params';
import { ProfileUriConfig } from './profile-config-url';
import {
  CharterApiDetails,
  ClientProfile,
  UserLocalData,
  Userprivacy,
  VendorLocalData,
} from './profile.model';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  public type = ApiTypes;
  private userLocalData = new UserLocalData();
  private userDetails = new ClientProfile();
  private vendorLocalData = new VendorLocalData();
  public  dataSubject = new Subject<UserLocalData>();
  public data$ = this.dataSubject.asObservable();
  public variableSubject = new BehaviorSubject<boolean>(false);
  public variable$ = this.variableSubject.asObservable();
  constructor(private serverConnectService: ServerConnectService) { }

  public saveClientprofile(
    userLocalData: UserLocalData,
    userPrivacy: Userprivacy
  ) {
    return new Promise((resolve) => {
      const databody =
        'ClientID=' +
        userLocalData.ClientID +
        '&enct=1&ClientName=' +
        userLocalData.ClientName +
        '&ClientInvoiceAddressline1=' +
        userLocalData.ClientInvoiceAddressline1 +
        '&ClientInvoiceAddressline2=' +
        userLocalData.ClientInvoiceAddressline2 +
        '&ClientInvoiceAddressline3=' +
        userLocalData.ClientInvoiceAddressline3 +
        '&ClientContactNo=' +
        userLocalData.ClientContactNo +
        '&clientcallingCodes=' +
        encodeURIComponent(userLocalData.clientcallingCodes) +
        '&ClientTimezone=' +
        userLocalData.timezoneselect +
        '&Accounts=' + JSON.stringify(userLocalData.Accounts) +
        '&ClientOperationContactName=' +
        userLocalData.ClientOperationContactName +
        '&ClientOperationContactNo=' +
        userLocalData.ClientOperationContactNo +
        '&callingCodeOpscontact=' +
        encodeURIComponent(userLocalData.callingCodeOpscontact) +
        '&ClientOperationContactEmailID=' +
        userLocalData.ClientOperationContactEmailID +
        '&Ops=' + JSON.stringify(userLocalData.Ops) +
        '&ClientAccDeptContactName=' +
        userLocalData.ClientAccDeptContactName +
        '&ClientAccDeptContactNo=' +
        userLocalData.ClientAccDeptContactNo +
        '&AccountcallingCodes=' +
        encodeURIComponent(userLocalData.AccountcallingCodes) +
        '&ClientAccDeptContactEmailID=' +
        userLocalData.ClientAccDeptContactEmailID +
        '&currency=' +
        JSON.stringify(userLocalData.currencyCode) +
        '&CompanyName=' +
        userLocalData.CompanyName +
        '&CompanyEmail=' +
        userLocalData.CompanyEmail +
        '&CompanyMultipleEmail=' +
        JSON.stringify(userLocalData.CompanyMultipleEmail) +
        '&Description=' +
        encodeURIComponent(userLocalData.Description) +
        '&fuelUnit=' +
        userLocalData.fuelUnit +
        '&Userprivacy=' +
        JSON.stringify(userPrivacy) +
        '&datatype=' +
        userLocalData.datatype +
        '&logo=' +
        encodeURIComponent(userLocalData.logo) +
        '&Pincode=' +
        userLocalData.Pincode +
        '&Country=' +
        JSON.stringify(userLocalData.Country) +
        '&State=' +
        userLocalData.State +
        '&Documentpath=' +
        encodeURIComponent(userLocalData.Documentpath) +
        '&filenametitle=' +
        encodeURIComponent(userLocalData.filenametitle) +
        '&PANNo=' +
        userLocalData.PAN_No +
        '&ServiceTaxNo=' +
        userLocalData.Service_TaxNo;
      this.serverConnectService
        .connectToServer(
          this.type.POST,
          ProfileUriConfig.SaveClientDetails,
          databody,
          false,
          false,
          false
        )
        .then((res) => {
          resolve(res);
        });
    });
  }
  public setVariableTrue() {
    this.variableSubject.next(true);
  }

  public saveVendorprofile(
    vendorLocalData: VendorLocalData,
    userPrivacy: Userprivacy
  ) {
    return new Promise((resolve) => {
      const databody =
        'VendorID=' +
        vendorLocalData.VendorID +
        '&enct=1&VendorName=' +
        vendorLocalData.VendorName +
        '&VendorInvoiceAddressline1=' +
        vendorLocalData.VendorInvoiceAddressline1 +
        '&VendorInvoiceAddressline2=' +
        vendorLocalData.VendorInvoiceAddressline2 +
        '&VendorInvoiceAddressline3=' +
        vendorLocalData.VendorInvoiceAddressline3 +
        '&Pincode=' +
        vendorLocalData.Pincode +
        '&Country=' +
        JSON.stringify(vendorLocalData.Country) +
        '&State=' +
        vendorLocalData.State +
        '&VendorContactNo=' +
        vendorLocalData.VendorContactNo +
        '&vendorcallingCodes=' +
        encodeURIComponent(vendorLocalData.vendorcallingCodes) +
        '&VendorTimezone=' +
        vendorLocalData.Timezone +
        '&VendorOperationContactName=' +
        vendorLocalData.VendorOperationContactName +
        '&VendorOperationContactNo=' +
        vendorLocalData.VendorOperationContactNo +
        '&vendorOpscallingCodes=' +
        encodeURIComponent(vendorLocalData.vendorOpscallingCodes) +
        '&VendorOperationContactEmailID=' +
        vendorLocalData.VendorOperationContactEmailID +
        '&Ops=' +
        JSON.stringify(vendorLocalData.Ops) +
        '&VendorAccDeptContactName=' +
        vendorLocalData.VendorAccDeptContactName +
        '&VendorAccDeptContactNo=' +
        vendorLocalData.VendorAccDeptContactNo +
        '&vendoraccountcallingCodes=' +
        encodeURIComponent(vendorLocalData.vendoraccountcallingCodes) +
        '&VendorAccDeptContactEmailID=' +
        vendorLocalData.VendorAccDeptContactEmailID +
        '&Accounts=' +
        JSON.stringify(vendorLocalData.Accounts) +
        '&currency=' +
        JSON.stringify(vendorLocalData.currencyCode) +
        '&CompanyName=' +
        vendorLocalData.CompanyName +
        '&CompanyEmail=' +
        vendorLocalData.CompanyEmail +
        '&CompanyMultipleEmail=' +
        JSON.stringify(vendorLocalData.CompanyMultipleEmail) +
        '&Description=' +
        encodeURIComponent(vendorLocalData.Description) +
        '&PANNo=' +
        vendorLocalData.PAN_No +
        '&ServiceTaxNo=' +
        vendorLocalData.Service_TaxNo +
        '&Branch=' +
        vendorLocalData.Branch +
        '&CurrentACNo=' +
        vendorLocalData.CurrentACNo +
        '&IFSCCode=' +
        vendorLocalData.IFSCCode +
        '&BankName=' +
        vendorLocalData.BankName +
        '&unitType=' +
        vendorLocalData.unitType +
        '&frequencyString=' +
        vendorLocalData.FrequencyString +
        '&Fax=' +
        vendorLocalData.Fax +
        '&serviceprovidedarr=' +
        vendorLocalData.VendorServicesCat +
        '&TollFreeNumber=' +
        vendorLocalData.TollFreeNumber +
        '&OperationalStartFrom=' +
        vendorLocalData.OperationalStartFrom +
        '&OperationalEndTo=' +
        vendorLocalData.OperationalEndTo +
        '&FrequencyUnit=' +
        vendorLocalData.FrequencyUnit +
        '&Userprivacy=' +
        JSON.stringify(userPrivacy) +
        '&datatype=' +
        vendorLocalData.datatype +
        '&logo=' +
        encodeURIComponent(vendorLocalData.logo) +
        '&Documentpath=' +
        encodeURIComponent(vendorLocalData.Documentpath) +
        '&filenametitle=' +
        encodeURIComponent(vendorLocalData.filenametitle);
      this.serverConnectService
        .connectToServer(
          this.type.POST,
          ProfileUriConfig.SaveVendorDetails,
          databody,
          false,
          false,
          false
        )
        .then((res) => {
          resolve(res);
        });
    });
  }
  public saveCharterProfile(charterApiDetails:CharterApiDetails , userPrivacy: Userprivacy){
    const databody = queryParams({
      AccountcallingCodes: charterApiDetails.AccountcallingCodes,
      CharterUserAccDeptContactEmailID:charterApiDetails.CharterUserAccDeptContactEmailID,
      CharterUserAccDeptContactName:charterApiDetails.CharterUserAccDeptContactName,
      CharterUserAccDeptContactNo: charterApiDetails.CharterUserAccDeptContactNo,
      CharterUserContactNo:charterApiDetails.CharterUserContactNo,
      CharterUserID:charterApiDetails.CharterUserID,
      CharterUserInvoiceAddressline1: charterApiDetails.CharterUserInvoiceAddressline1,
      CharterUserInvoiceAddressline2:charterApiDetails.CharterUserInvoiceAddressline2,
      CharterUserInvoiceAddressline3: charterApiDetails.CharterUserInvoiceAddressline3,
      CharterUserName: charterApiDetails.CharterUserName,
      CharterUserOperationContactEmailID:charterApiDetails.CharterUserOperationContactEmailID,
      CharterUserOperationContactName:charterApiDetails.CharterUserOperationContactName,
      CharterUserOperationContactNo: charterApiDetails.CharterUserOperationContactNo,
      CharterUsercallingCodes: charterApiDetails.CharterUsercallingCodes,
      CompanyEmail: charterApiDetails.CompanyEmail,
      CompanyName: charterApiDetails.CompanyName,
      Country: JSON.stringify(charterApiDetails.Country),
      CreatedDate:charterApiDetails.CreatedDate,
      NumberOfAircraft: charterApiDetails.NumberOfAircraft,
      PAN_No:charterApiDetails.PAN_No,
      PaymentType:charterApiDetails.PaymentType,
      Pincode: charterApiDetails.Pincode,
      PrivacyForEmail: charterApiDetails.PrivacyForEmail,
      PrivacyForNotification:charterApiDetails.PrivacyForNotification,
      Timezone: charterApiDetails.Timezone,
      UserID: charterApiDetails.UserID,
      Verified: charterApiDetails.Verified,
      callingCodeOpscontact: charterApiDetails.callingCodeOpscontact,
      currencyCode:JSON.stringify(charterApiDetails.currencyCode),
      flag: charterApiDetails.flag,
      logo: charterApiDetails.logo,
      name: charterApiDetails.name
    });
    return new Promise((resolve , reject) => {
      this.serverConnectService
      .connectToServer(
        this.type.POST,
        ProfileUriConfig.SaveCharterDetails,
        databody,
        false,
        false,
        false
      )
      .then((res) => {
        resolve(res);
      }).catch((err)=>{
        reject(false);
      })
    })

  }
  public sendOnBoardingMail() {
    const databody: any = {};
    this.serverConnectService.connectToServer(
      this.type.POST,
      ProfileUriConfig.SendOnBoardingMail,
      databody,
      false,
      false,
      false
    );
  }

  public tokenlogout(flag) {
    const databody = 'all=' + flag;
    this.serverConnectService.connectToServer(
      this.type.POST,
      ProfileUriConfig.UserLogOut,
      databody,
      false,
      false,
      false
    );
  }
  public deleteUser() {
    return new Promise((resolve,reject) => {
      const databody = '';
      this.serverConnectService.connectToServer(
        this.type.POST,
        ProfileUriConfig.deleteUser,
        databody,
        false,
        false,
        false
      ).then((res) => {
        resolve(true);
      }).catch((err)=>{
        reject(false)
      })
    });
  }
}
