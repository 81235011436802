import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { ForgotPassword } from '../authentication.model';
import { MessageConfig } from '@app/shared/constants/constant';
import { SigninService } from '../services/signin.service';
import { CommonService } from '@app/shared/services/common.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.page.html',
  styleUrls: ['./forgot-password.page.scss'],
})
export class ForgotPasswordPage implements OnInit {
  public Form: FormGroup;
  public forgotPasswrod: ForgotPassword = new ForgotPassword();
  public messageDisplay = MessageConfig;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private signInService: SigninService,
    private commonService: CommonService
  ) { }

  public ngOnInit() {
    this.validForm();
  }
  public ionViewWillEnter() { }
  public back() {
    this.router.navigateByUrl('signin');
  }
  public validForm() {
    this.Form = this.formBuilder.group({
      UserName: [
        { value: this.forgotPasswrod.UserName },
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+.[a-zA-Z]+.[a-zA-Z]{2,4}$'
          ),
        ]),
      ],
    });
  }
  public resetPassword() {
    this.commonService.showSpinnerLoader();
    this.signInService.sendOnForgetMail(this.forgotPasswrod).then((res) => {
      this.commonService.dissmissSpinnerLoader();
    });
  }
 public keyDownFunction(event) {
  if(this.Form.valid)
  {
    if (event.keyCode === 13) {
      this.resetPassword();
    }
  }
  else{
    this.commonService.dismissLoader();
  }
  }
}
