import { Directive,ElementRef,HostListener} from '@angular/core';

@Directive({
  selector: '[appAutocopy]'
})
export class AutocopyDirective {
  constructor(private el : ElementRef) {}
  @HostListener('mouseup', ['$event'])
  public onMouseUp(event: MouseEvent) {
    const selectedText = window.getSelection().toString().trim();
    if (selectedText) {
      navigator.clipboard.writeText(selectedText).then(() => {
        const parentElement = this.el.nativeElement.parentElement;
        // Use nextElementSibling to find the next sibling element directly
        const copyMessageElement = this.el.nativeElement.nextElementSibling;
        copyMessageElement.style.display = 'inline';
        setTimeout(() => {
          copyMessageElement.style.display = 'none';
        }, 5000);
      }).catch((error) => {
      });
    }
  }
  }


