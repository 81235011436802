export class Station {
  public AirportID: string;
  public AirportName: string;
  public Airportname?: string;
  public CityName: string;
  public Country: string;
  public IATACode: string;
  public FAAIdent: string;
  public ICAOCode: string;
  public Latitude: string;
  public Longitude: string;
  public Elevation: string;
  public UTC: string;
  public DST: string;
  public flag: string;
  public timezone: string;
  public verified: string;
  public searchIndexString: string;
  public Runways: string;
  public RunwaysUnit: string;
  public maxRunwayLength: any;
  public Distance?:any;

}
export class InsertStation extends Station {
  constructor(data){
     // Call the constructor of the base class (Station) using super()
    super()
    this.AirportID = data.AirportID;
    this.AirportName = this.capitalizeEachWord(data.AirportName);
    this.CityName =  this.capitalizeEachWord(data.CityName);
    this.Country =  this.capitalizeEachWord(data.Country);
    this.DST = data?.DST?.toUpperCase()
    this.Elevation = data.Elevation
    this.FAAIdent = data.FAAIdent
    this.IATACode = data?.IATACode ? data?.IATACode?.toUpperCase():''
    this.ICAOCode = data?.ICAOCode?.toUpperCase()
    this.Latitude = data.Latitude
    this.Longitude = data.Longitude
    this.UTC = data.UTC
    this.flag = '1'
    this.maxRunwayLength = data.maxRunwayLength
    this.searchIndexString = this.generateSearchIndexString()
    this.timezone = data.timezone
    this.verified = '1'
  }

  public generateSearchIndexString() {
    const searchIndexString = `${this.ICAOCode}_${this.AirportName}_${this.CityName}_${this.Country}`;
    return searchIndexString.replace(/\s+/g, '_');
  }
  public capitalizeEachWord(str) {
    const words = str.split(' ');
    const capitalizedWords = words.map(word => {
      if (word.length > 0) {
        return word[0].toUpperCase() + word.slice(1).toLowerCase();
      }
      return word;
    });
    return capitalizedWords.join(' ');
  }
}

export class RecivedParams {
  public stationType: string; // which type of selection
  public departure?: string; // its a optinal i.e if comparing two thing
  public fromstation?: string;
  public timeZoneVal?: string;
  constructor(
    stationType: string,
    departure?: string,
    fromstation?: string,
    timeZoneVal?: string
  ) {
    this.stationType = stationType;
    this.departure = departure;
    this.fromstation = fromstation;
    this.timeZoneVal = timeZoneVal;
  }
}
export class AllStationJson {
  public data: Station[];
}
export class TripBasicDeatils {
  public from: string;
  public to: string;
  public departDate: string;
  public timeType: string = 'true';
  public crew: string;
  public pax: string;
  public flightType: string;
  public operationType: string;
  public flightRule: string;
  public fuelPolicy: string = 'MaximumFuelPolicy';
  public adequate: string = 'Auto';
  public alt1: string;
  public alt2: string;
  public depAlt: string;
  public fuelonBoard: number;
  public etops: string;
  public flightLvel: number;
  public flightLvelalt2: number;
  public cruiseSpeed: string;
  public aircraft: string;
  public MaxflightlevelUnit: string;
  public route: any;
  public fromStationTimeZone: string;
  public toStationTimeZone: string;
  public serviceProvider: any;
  public BLKTIME: string;
  public legRouteid: string;
  public flightPlanresponseID: string;
  public country = [];
  public DIST: number;
  public ETDGMT: string;
  public ETAGMT: string;
  public ETALT: string;
  public ETDLT: string;
  public allVendorServicesSelected?: boolean = false;
  public id?: number;
  public sector: string;
  public VendorArr: any;
  public ServiceCostArr: any;
  public flightLevelType: string = 'OptimumFlightLevelDefinition';
  public regd?: string;
  public extrafuel: string;
  public FuelUnit: string = 'Pound';
  public fromStationDetails?: any;
  public legrouteInformationid?: string;
  public selectedVendorList?: any;
  public tostationDetails?: any;
}
export class FinalTrip {
  public RequestArray: RequestDataStructure[];
  public SectorServiceArray: SectorServiceObject[];
  public StationServiceArray: StationServiceObject[];
  public legInfoDetails: LegObject[];
  public savedTCEArr: SavedTCEObject;
  public AircraftID: string;
  public WayType: string;
  public FlightRule: string;
  public FlightType: string;
  public Operation: string;
  public CreatedDate: string;
  public DraftTripID: string;
}
export class LegObject {
  public from: string = '';
  public to: string = '';
  public toStationTimeZone: string = '';
  public fromStationTimeZone: string = '';
  public fromStationDetails?: {
    from: string;
    timezone: string;
  };
  public tostationDetails?: {
    to: string;
    timezone: string;
  };
  public crew: string = '';
  public pax: string = '';
  public flightRule: string = '';
  public flightType: string = '';
  public stopType?: string = '';
  public time?: string = '';
  public operationType: string = '';
  public timeType: string = '';
  public id?: number = null;
  public BLKTIME: string = '';
  public legRouteid: string = '';
  public flightPlanresponseID: string = '';
  public country = [];
  public DIST: number = null;
  public ETDGMT: string = '';
  public ETAGMT: string = '';
  public ETALT: string = '';
  public ETDLT: string = '';
  public aircraftID?: number = null;
}
export class RequestDataStructure {
  public from: string = '';
  public to: string = '';
  public depart?: string = '';
  public timeZone?: string = '';
  public stoptype?: string = '';
  public BLKTIME: string = '';
  public DIST: number = null;
  public ETDGMT: string = '';
  public ETDLT: string = '';
  public ETAGMT: string = '';
  public ETALT: string = '';
  public GMTPLUSORIGIN?: string = '';
  public GMTPLUSDEST?: string = '';
  public TIMEZONE?: string = '';
  public routeID?: string = '';
  public createdDate?: Date;
}
export class SectorServiceObject {
  public sector: string;
  public service: string = 'navigation,permits,crew briefing';
}

export class StationServiceObject {
  public station: string;
  public sector: string;
  public service: string =
    'fuel,airportcharges,groundhandling,crewtransport,handlingsetups,handlingadmincredit';
}
export class SavedTCEObject {
  public Sector = [];
  public fuel = [];
  public handling = [];
  public airpermissions = [];
  public handlingcoordination = [];
}
export class TCE {
  public service: string;
  public sector: string;
  public station: string;
  public vendorid: string;
  public UID: string;
  public name: string;
  public price: number;
  public logo: string;
  public legId: number;
  public rating: number = 0;
  public sectorWise: string;
}
