import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { TripInformationComponent } from './trip-information/trip-information.component';
import { TripInformationModule } from './trip-information/trip-information.module';
import { LiveChatComponent } from './live-chat/live-chat.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { NotificationChatComponent } from './notification-chat/notification-chat.component';
import { UnreadChatComponent } from './notification-chat/unread-chat/unread-chat.component';
import { RecentChatComponent } from './notification-chat/recent-chat/recent-chat.component';
import { SharedDirectiveModule } from '@app/shared/directives/shared-directive.module';

@NgModule({
  imports: [IonicModule, CommonModule,
    TripInformationModule, PipesModule, FormsModule,
     ReactiveFormsModule,PipesModule,SharedDirectiveModule],
  declarations: [TripInformationComponent,
    LiveChatComponent,
    RecentChatComponent,
    UnreadChatComponent,
    NotificationChatComponent],
  exports: [TripInformationComponent,
    LiveChatComponent,
    RecentChatComponent,
    UnreadChatComponent,
    NotificationChatComponent
],
})
export class RightSideMenuModule { }
