export const SetupUriConfig = {
  SignInURL: '/index.php?page=API&action=APILogin',
  VerifyPin: '/index.php?page=API&action=VerifyUserPin',
  SetUserPin: '/index.php?page=API&action=SetUserPin',
  AddAirport: '/index.php?page=API&action=AddAirport',
};

export const ServiceProviderUriConfig = {
  GetServiceVendors: '/index.php?page=API&action=GetServiceVendors',
  saveServiceProviderVendorsBy:
    '/index.php?page=API&action=SaveServiceProvider',
  getServiceProviderSelected:
    '/index.php?page=API&action=GetSelectServiceProvider',
  getHandlingStationList: '/index.php?page=API&action=GetGroundHandlingCharges',
  getFuelStationList: '/index.php?page=API&action=ViewFuelData',
  getAirportChargesStationList: '/index.php?page=API&action=AirportCharges',
  getSlotStationList: '/index.php?page=API&action=GetSlotCharges',
  getOverFlightStationList:
    '/index.php?page=API&action=GetOverflightPermitCharges',
  getFlightPlanningStationList:
    '/index.php?page=API&action=GetCrewBriefingCharges',
  getLandingStationList: '/index.php?page=API&action=GetLandingCharges',
  getNavigationStationList:
    '/index.php?page=API&action=GetNewNavigationCharges',
  getHandlingPriceList:
    '/index.php?page=API&action=GetGroundHandlingByAirportID',
  getAirportPriceList: '/index.php?page=API&action=AirportChargesByAirportId',
  getLandingPriceList: '/index.php?page=API&action=GetLandingChargesAirportID',
  getSlotPriceList: '/index.php?page=API&action=GetSlotChargesByAirportID',
  getFuelPriceList: '/index.php?page=API&action=ViewFuelDataByAirportID',
  getFlightPlanningPriceList:
    '/index.php?page=API&action=GetOverflightPermitChargesByAirportID',
  getOverfligtPermitPriceList:
    '/index.php?page=API&action=GetOverflightPermitChargesByAirportID',
  getNavigationPriceList:
    '/index.php?page=API&action=GetNavigationChargesByCountryID',
  UpdateFlagOfOverFlight: '/index.php?page=API&action=UpdateFlagOfOverFlight',
  UpdateFlagOfHandling: '/index.php?page=API&action=UpdateFlagOfHandling',
  UpdateFlagOfSlot: '/index.php?page=API&action=UpdateFlagOfSlot',
  UpdateFlagOfLanding: '/index.php?page=API&action=UpdateFlagOfLanding',
  UpdateFlagOfAirport: '/index.php?page=API&action=UpdateFlagOfAirport',
  UpdateFlagOfFuel: '/index.php?page=API&action=UpdateFlagOfFuel',
  UpdateFlagOfFlightPlanning:
    '/index.php?page=API&action=UpdateFlagOfFlightPlanning',
  DeleteNewNavigationChargesByCountryID:
    '/index.php?page=API&action=DeleteNewNavigationChargesByCountryID',
  GetTaxList: '/index.php?page=API&action=GetTaxList',
  DeleteTaxData: '/index.php?page=API&action=DeleteTaxData',
  ProcessSaveTax: '/index.php?page=API&action=ProcessSaveTax',
  AddSlotPrice: '/index.php?page=API&action=ProcessSaveSlotCharges',
  AddLandingPrice: '/index.php?page=API&action=ProcessSaveLandingCharges',
  AddPermitPrice: '/index.php?page=API&action=ProcessSaveOverflightCharges',
  AddCrewBriefingPrice: '/index.php?page=API&action=ProcessSaveCrewBriefing',
  GetNewNavigationChargesByCountryID:
    '/index.php?page=API&action=GetNewNavigationChargesByCountryID',
  SaveNewNavigationCharges:
    '/index.php?page=API&action=SaveNewNavigationCharges',
  GetNewNavigationCharges: '/index.php?page=API&action=GetNewNavigationCharges',
  UpdateNavigationCharges: '/index.php?page=API&action=UpdateNavigationCharges',
  DeleteNavigationCountry: '/index.php?page=API&action=DeleteNavigationCountry',
  DeleteSetup: '/index.php?page=API&action=DeleteSetup',
  GetRateCardOptions:"/index.php?page=API&action=GetAllClients"
};

export const AircraftUriConfig = {
  getAircraftDetailsUID: '/index.php?page=API&action=GetAircraftDetailsByUUID',
  uploadAircraftDetails: '/index.php?page=API&action=AircraftSetup',
  getAircraftNewData: '/index.php?page=API&action=GetNewAircraftData',
  deleteAircraft: '/index.php?page=API&action=DeleteAircraft',
  getAircraftSettings: '/index.php?page=API&action=GetAircraftSettings',
  checkAircraftTailNumber: '/index.php?page=API&action=CheckAircraftTailNumber',
};

export const CrewPaxUriConfig = {
  getCrewSettings: '/index.php?page=API&action=GetCrewSettings',
  deletePassenger: '/index.php?page=API&action=DeletePassenger',
  getPaxSettings: '/index.php?page=API&action=GetPaxSettings',
  savePassengerDetails: '/index.php?page=API&action=SavePassengerDetails',
  saveCrewDetails: '/index.php?page=API&action=SaveCrewDetails',
};

export const RolesConfig = {
  getAllRolesBasedonUserID: '/index.php?page=API&action=GetAllRoles',
  getAllRolesandUsersBasedonMainID:
    '/index.php?page=API&action=GetAllRolesandUsersBasedonMainID',
  getEachRoleModulesBasedonRoleID:
    '/index.php?page=API&action=GetEachRoleModulesBasedonRoleID',
  addClientSubRole: '/index.php?page=API&action=AddClientSubRole',
  deleteUserFromRole: '/index.php?page=API&action=DeleteUserFromRole',
  updatRoleModules: '/index.php?page=API&action=UpdatRoleModules',
};
export const ThirdPartyAccountingUriConfig = {
  AccountingTools: '/index.php?page=API&action=AccountingTools',
  AccountingOAuth: '/index.php?page=API&action=AccountingOAuth',
  AccountingOAuthDelete: '/index.php?page=API&action=AccountingOAuthDelete',
}