export enum INVOICE_NAMES {
  ADDITIONAL_INVOICES = 'Additional Invoice',
  SUPPORTING_DOCUMENTS = 'Supporting Documents',
  INVOICE_DETAILS = 'Invoice Details',
}
export enum LEG_STATUSES {
  PROCESSING = 'Processing',
  CLOSED = 'Closed',
  INPROGRESSSTARTING = 'Inprogressstarting',
  INPROGRESSFINISHING = 'Inprogressfinishing',
  INPROGRESSFINISHED = 'Inprogressfinished',
  CONFIRMED = 'Confirmed',
  COMPLETED = 'Completed',
  UPCOMING = 'Upcoming',
  PENDING = 'Pending',
  INPROGRESS = 'In Progress',
}
export enum TRIP_STATUSES {
  PROCESSING = 'Processing',
  DECLINED = 'Declined',
  PENDING = 'Pending',
}

export enum TRIP_ACTIVITY_CONSTANTS {
  ACTIVITY = 'activity',
  INCOMPLETE = "incomplte",
  SUCCESS = 'Success',
  REJECT = 'reject',
  OK = 'Ok',
  RELOAD = 'reload',
  TIMELINE_EXPIRED = 'timeline expired',
  NOTIFICATION = 'notification',
  SERVICE_STATUS = 'nomoreserviceremains',
}

export enum ACTION_ITEMS {
  TRIP_KIT = 'Trip Kit',
  FLIGHT_PLAN = 'Nav Log',
  FDN = 'FDN',
  GD = 'GD',
  PAX = 'Pax Manifest',
  FUEL = "Fuel Release",
  ARR_AIRPORT_CHARGES_DEL = 'Arr Airport Charges Delivered',
  Dep_GH_Services_Form_Signoff = 'Dep GH Services Form Signoff',
  Handling_Signoff = 'Handling Signoff',
  Arr_GH_Services_Form_Signoff = 'Arr GH Services Form Signoff',
  Dep_Apt_Fees_Permits = 'Dep Apt Fees & Permits',
  Arr_Apt_Fees_Permits = 'Arr Apt Fees & Permits',
  Nav_Fees_Permits = 'Nav Fees & Permits',
  Apt_Fees_Permits = 'Apt Fees & Permits',
  Manifest = 'Manifest',
  Overflight_Permits = 'Overflight Permits',
  Airport_Charges = 'Airport Charges',
  Payload = 'Payload',
  Select_Handling_Services = 'Select Handling Services',
}

export enum AIRPORT_CHARGES_SURCHARGES {
  COMMUNICATION = 'Communication',
  SUPERVISION_CHARGES = 'SupervisionCharges',
  THIRD_PARTY_DISBURSEMENT = 'ThirdPartyDisbursement',
  NIGHT_SURCHARGE = 'NightSurcharge',
  OUTSIDE_OFFICE_SURCHARGE = 'OutsideOfficeOperationalHoursSurcharge',
  OUTSIDE_AIRPORT_SURCHARGE = 'OutsideAirportOperationalHourSurcharge',
  PEAK_PERIOD_DATE_SURCHARGE = 'peakperioddateslist',
  PEAK_PERIOD_TIMES_SURCHARGE = 'peakperiodtimeslist',
  WEEKEND_SURCHARGE = 'WeekendSurcharge',
  HOLIDAY_SURCHARGE = 'holidayslistdetails',
}

export enum ServiceStatus {
  DONE = 'Done',
  ARRANGED = 'Arranged',
  CONFIRMED = 'Confirmed',
}
export enum ACTION_ITEMS_CATEGORY {
  AIRPORT_CHARGES = 'Airport Charges',
  PAYLOAD = 'Payload',
  FLIGHT_PLANNING = 'Flight Planning',
  OVERFLIGHT_PERMITS = 'Overflight Permits',
  FUEL = 'Fuel',
  HANDLING = 'Handling',
  PAXDEPARTED = 'Pax Departed',
}

export enum SERVICE_TYPE {
  CREW = 'crew',
  OVERFLY = 'overfly',
  HANDLING = 'handling',
  AIRPORT = 'airport',
  SLOT = 'slot',
  LANDING = 'landing',
  FUEL = 'fuel',
  NAVIGATION = 'navigation',
}
export const SERVICES_TYPE: string[] = ['crew', 'overfly', 'handling', 'airport', 'slot', 'landing', 'fuel', 'navigation'];
export enum Handling_Order_Services_Names {
  ACU = 'ACU',
  AdditionalRampLabour = 'Ramp Labour (Per Hour)',
  AirsideTransport = 'Airside Transport(Per Trip)',
  ConveyorBelt = 'ConveyorBelt(Per Hour)',
  Forklift = 'Forklift 5T % 9T (Per Hour)',
  GPU = 'GPU',
  HangarSupervision = 'Hangar Supervision',
  HighLoader = 'High Loader(Capacity7T)(Per Hour)',
  MaintenanceStairs = 'Maintenance Stairs(Per Use)(Upto 6 hours)',
  MaintenanceStand = 'Maintenance Stand(Per Hour)',
  NitrogenCart = 'Nitrogen Cart(Per Use)',
  OxygenCart = 'Oxygen Cart(Per Use)',
  PassengerStairs = 'PassengerStairs(Per Hour)',
  ScissorLift = 'Scissor Lift(Per Hour)',
}

export enum Handling_Services_Names {
  ACU = 'ACU',
  GPU = 'GPU',
  HangarSupervision = 'Hangar Supervision',
  ConveyorBelt = 'Conveyor Belt',
  PassengerStairs = 'Passenger Stairs',
  AdditionalRampLabour = 'Ramp Labour',
  AirsideTransport = 'Airside Transport',
  Forklift = 'Forklift 5T % 9T ',
  HighLoader = 'High Loader (Capacity7T)',
  MaintenanceStairs = 'Maintenance Stairs (Upto 6 hours)',
  MaintenanceStand = 'Maintenance Stand',
  NitrogenCart = 'Nitrogen Cart',
  OxygenCart = 'Oxygen Cart',
  ScissorLift = 'Scissor Lift',
}

export enum Handling_Order_Services_Order {
  ACU = 1,
  GPU = 2,
  AirsideTransport = 3,
  OxygenCart = 4,
  NitrogenCart = 5,
  ConveyorBelt = 6,
  Forklift = 7,
  ScissorLift = 8,
  HangarSupervision = 9,
  HighLoader = 10,
  MaintenanceStand = 11,
  MaintenanceStairs = 12,
  AdditionalRampLabour = 13,
  PassengerStairs = 14,
}

export enum Passenger_Services_Names {
  PaxTransport = 'Pax Transport',
}
export enum InvoiceStatus {
  Pending = 'pending',
  Invoiced = 'invoiced',
  Resolved = 'resolved',
  Dispute = 'dispute',
}

export enum setupVendorTabNames {
  PROFILE = 'Profile',
  TAXES = 'Taxes',
  HANDLING = 'Handling',
  AIRPORT_CHARGES = 'Airport Charges',
  LANDING_PERMISSION = 'Landing Permission',
  SLOT_PERMISSION = 'Slot Permission',
  FUEL = 'Fuel',
  FLIGHT_PLANNING = 'Flight Planning',
  OVERFLIGHT_PERMITS = 'Overflight Permits',
  NAVIGATION_FEES = 'Navigation Fees',
  NAVIGATION = 'Navigation',
  ACCOUNTING = 'Accounting',
}
export enum SignatureDeclaration {
  CovidSelfDeclaration = 'Covid-19 Self Declaration',
  CovidDeclarartion = 'Covid declarartion',
}
export enum shortNoticeLiterals {
  RevisionApplicable = 'RevisionApplicable',
  Revision_Applicable = 'Revision Applicable',
  ShortNotice ='ShortNotice',
  Short_Notice = 'Short Notice'
}