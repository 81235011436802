import {
  AvionicsAircraftDetails,
  BasicAircraftDetails,
  CharterRules,
  EquipmentAircraftDetails,
  FuelAircraftDetails,
  UploadAircraft,
  WeightsAircraftDetails,
} from './aircraft-model';

export const ICON_CONFIG = {
  CheckMark: 'checkmark',
  ForwardIcon: 'chevron-forward-outline',
};
// message for aircraft setup
export const AircraftMessageConfig = {
  ACFT: 'ACFT Color required.',
  ACType: 'Aircraft type required',
  ACcategorty: 'Aircraft category required.',
  ADS: ' Automatic dependent surveillance required.',
  AcCode: 'Aircraft code required.',
  ActiveAircraft: 'Activate this Aircraft?',
  AircraftDelete: 'Deactivate this Aircraft?',
  DeleteAircraft: 'You want to delete this Aircraft?',
  Aircraftgeneric: 'Aircraft generic configuration not found.',
  Alert: 'Alert!',
  Attachfilename: 'Document Name',
  BasicLocatin: 'Base location required',
  BasicOperatin: 'Basic operation weight required.',
  CancelBtn: 'Cancel',
  Close: 'Close',
  Confirmation: 'Confirm',
  Contingency: 'Contingency required.',
  FuelBias: 'Fuel Bias required.',
  ContinueBtn: 'Continue',
  CorrectEnterdvalue: 'Please enter required values.',
  CountryPrefix: 'Country Prefix required',
  CrewCapacity: 'Crew capacity required.',
  DrpartureTaxi: 'Departure taxi fuel required.',
  DryMass: 'Dry Mass required',
  ENE: 'Etops/Non-Etops required.',
  EngineName: 'Engine name required.',
  EngineType: 'Engine type required.',
  FileSize: 'File size exceeded! Please upload an file smaller than 7MB',
  FlightRule: 'Flight rule required.',
  FuelType: 'Fuel type required.',
  carbonOffset: 'Carbon Offset type required.',
  FuelingType: 'Fueling type required.',
  HoldTime: 'Hold time required.',
  LiveVest: 'Live vest required.',
  MinAlternate: 'Min ALT fuel required.',
  MinContingency: 'Min contingency fuel required.',
  MaxFuel: 'Max fuel capacity required.',
  MaxFlight: 'Max flight level required.',
  MaxFlightLevel: 'Max flight level is out of range.',
  MaxLanding: 'Max Landing weight required.',
  MaxZero: 'Max zero fuel weight required.',
  MaxRamp: 'Max ramp weight required.',
  MaxTakeOff: 'Max takeoff weight required.',
  Manufacture: 'Manufacturer required',
  MinimumReserve: 'Minimum reserve fuel required.',
  lengthSpanWeightRange: 'Length and span weight range required',
  Error: 'Oops! Some thing went wrong, Try after some time.',
  FuelinTank: 'Fuel required.',
  NoiseCategory: 'Noise category required.',
  NoOfCapacity: 'Number of capacity required.',
  NoOfRafts: 'Number of rafts required.',
  NormalMode: 'Normal cruise mode required.',
  OkBtn: 'Ok',
  Oops: 'Oops!',
  operationtypes: 'Operation type required',
  OpsError: 'Error! Please try again',
  OpsRegulation: 'Ops regulation required.',
  PaxCapacity: 'Pax capacity required.',
  PBN: 'Performance based navigation required',
  RAC: 'Radios avionics certifications required.',
  RaftsColor: 'Raftscolor required',
  RaftsCovered: 'Raftscovered required',
  Ragistration: 'TailNo required',
  RagistrationPrefix: 'Registration Prefix required',
  SaveBtn: 'Save',
  SEdistance: 'SE distance required',
  SELCAL: 'SELCAL required.',
  selectAllFile: 'All mandatory Attachment files are required.',
  selectFile: 'Attachment file Name required.',
  SEspeed: 'SE SPD required.',
  SEtime: 'SE time required.',
  SSR: 'Secondary surveillance radar required.',
  SurvivelGear: 'Survival gear required.',
  TCAS: '  Traffic Collision Avoidance required',
  TryAgain: 'Try Again',
  TypeOfFlight: 'Type of flight required.',
  UnitType: 'Unit type required.',
  UplinkCapabilities: 'Uplink Capabilities required',
  validationmessage: 'fill valid characters',
  ValidCharcters: 'Please fill valid characters.',
  Emergency: 'Emergency radios required.',
  delete: 'Delete attachment?',
  AircraftgenericNotFoundAlertMessage:
    'We do not seem to have Generic Data for this Model. Please mail us at team@avplat.com',
  minimumRunwayLength: '',
  AircraftSetupSaving: 'Please wait. We are saving your aircraft details.',
  AircraftGetDetails: 'Please wait. We are retrieving your aircraft details.',
  NetCharterRate: 'Net Charter Price is required.',
  EmptyLegValidation: 'The percentage must be between 0 and 100.'
};

// no of tab showing in the front end
export const AircraftTab = [
  {
    active: true,
    checkmarkicon: ICON_CONFIG.CheckMark,
    flag: false,
    forwardicon: ICON_CONFIG.ForwardIcon,
    iconname: 'cog-outline',
    id: '0',
    name: 'Basic Setup',
  },
  {
    active: true,
    checkmarkicon: ICON_CONFIG.CheckMark,
    flag: false,
    forwardicon: ICON_CONFIG.ForwardIcon,
    iconname: 'timer-outline',
    id: '1',
    name: 'Weight',
  },
  {
    active: true,
    checkmarkicon: ICON_CONFIG.CheckMark,
    flag: false,
    forwardicon: ICON_CONFIG.ForwardIcon,
    iconname: 'fuel-icon',
    id: '2',
    name: 'Fuel',
  },
  {
    active: true,
    checkmarkicon: ICON_CONFIG.CheckMark,
    flag: false,
    forwardicon: ICON_CONFIG.ForwardIcon,
    iconname: 'settings-outline',
    id: '3',
    name: 'Equipment',
  },
  {
    active: true,
    checkmarkicon: ICON_CONFIG.CheckMark,
    flag: false,
    forwardicon: ICON_CONFIG.ForwardIcon,
    iconname: 'avionics-icon',
    id: '4',
    name: 'Avionics',
  },
  {
    active: true,
    checkmarkicon: ICON_CONFIG.CheckMark,
    flag: false,
    forwardicon: ICON_CONFIG.ForwardIcon,
    iconname: 'list-outline',
    id: '5',
    name: 'Charter Rules',
  },
  {
    active: true,
    checkmarkicon: ICON_CONFIG.CheckMark,
    flag: false,
    forwardicon: ICON_CONFIG.ForwardIcon,
    iconname: 'attach-outline',
    id: '6',
    name: 'Aircraft Attachment',
  },
];
export class AircraftTabList {
  public name: string = '';
  public active: boolean;
  public flag: boolean;
  public id: string = '';
}
export class AircraftStructure {
  public basicAircraft: BasicAircraftDetails;
  public weightAircrft: WeightsAircraftDetails;
  public fuelAircraft: FuelAircraftDetails;
  public equipmentAircraft: EquipmentAircraftDetails;
  public avionicsAircraft: AvionicsAircraftDetails;
  public charterRule: CharterRules;
  public id?: string;
  public cruiseProfileData: any = {};
  public ClimbProfiles: {};
  public DescentProfiles: {};
  public AircraftId?: any;
  public isaircarftCopied?:boolean;
}
export class AircraftFirebase {
  public UploadAircraftArr: UploadAircraft;
}
export class AircraftTotalStructure {
  public FirebaseData: any;
  public AircraftData: AircraftStructure;
  public firebaseAircaftData: AircraftFirebase;
}

export enum AircraftValue {
  AircraftAttach = 'Aircraft Attachment',
  CruisePerformancePorifleSummary = 'CruisePerformancePorifleSummary',
}
export const ServiceType = [

  {
    name: 'Overflight',
    value: '1',
  },
  {
    name: 'Flight Planning',
    value: '2',
  },
  {
    name: 'Fuel',
    value: '3',
  },
  {
    name: 'Handling',
    value: '4',
  },
  {
    name: 'Airport Charges',
    value: '5',
  },
  {
    name: 'Landing',
    value: '6',
  }, {
    name: 'Slot',
    value: '7',
  }, {
    name: 'Navigation',
    value: '8',
  },
];