import { Pipe, PipeTransform } from '@angular/core';
import * as timezones from 'moment-timezone';
import { DateFormate } from '@app/shared//constants/constant';
import { CommonService } from '@app/shared/services/common.service';
@Pipe({
  name: 'flyingDatetime',
})
export class FlyingDatetimePipe implements PipeTransform {
  constructor(private commonService: CommonService) {}
  public transform(
    date: string,
    flyinghour?: string,
    datetype?: string,
    stationtimezone?: string,
    station?: string,
    timeZone?: string
  ) {
    if (datetype != 'UTC') {
      if (this.commonService.userFormat.TimeFormat != '') {
        if (station == 'from') {
          return timezones
            .tz(date, stationtimezone)
            .format(this.commonService.userFormat.TimeFormat);
        } else {
          return timezones
            .tz(date, stationtimezone)
            .add(this.convertominute(flyinghour), 'minutes')
            .format(this.commonService.userFormat.TimeFormat);
        }
      } else {
        if (station == 'from') {
          return timezones
            .tz(date, stationtimezone)
            .format(DateFormate.dispalyMinDate);
        } else {
          return timezones
            .tz(date, stationtimezone)
            .add(this.convertominute(flyinghour), 'minutes')
            .format(DateFormate.dispalyMinDate);
        }
      }
    } else {
      if (this.commonService.userFormat.TimeFormat != '') {
        if (station == 'from') {
          return (
            timezones
              .tz(date, stationtimezone)
              .utc()
              .format(DateFormate.dispalyMinDate) + 'Z'
          );
        } else {
          return (
            timezones
              .tz(date, stationtimezone)
              .utc()
              .add(this.convertominute(flyinghour), 'minutes')
              .format(this.commonService.userFormat.TimeFormat) + 'Z'
          );
        }
      } else {
        if (station == 'from' && timeZone == 'zulu') {
          return timezones
            .tz(date, stationtimezone)
            .utc()
            .format(DateFormate.dispalyMinDate);
        } else if (station == 'from' && timeZone == undefined) {
          return (
            timezones
              .tz(date, stationtimezone)
              .utc()
              .format(DateFormate.dispalyMinDate) + 'Z'
          );
        } else if (station == 'to' && timeZone == 'zulu') {
          return timezones
            .tz(date, stationtimezone)
            .utc()
            .add(this.convertominute(flyinghour), 'minutes')
            .format(DateFormate.dispalyMinDate);
        } else {
          return (
            timezones
              .tz(date, stationtimezone)
              .utc()
              .add(this.convertominute(flyinghour), 'minutes')
              .format(DateFormate.dispalyMinDate) + 'Z'
          );
        }
      }
    }
  }
  public convertominute(time) {
    if (time) {
      const startTime = time.split(':');
      const startMinutes = Number(startTime[0]) * 60 + Number(startTime[1]);
      return Number(startMinutes);
    }
  }

}
