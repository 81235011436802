import { PageNames } from '@app/trips/trips-tab/constants/constant-message';
import { MessageConfig, DateFormate } from '@app//shared/constants/constant';
import { ActivityOrderPipe } from '@app/trips/trips-tab/pipe/activity-order.pipe';
import { TripServices } from './trip-service.model';
import {
  LEG_STATUSES,
  TRIP_ACTIVITY_CONSTANTS,
  TRIP_STATUSES,
} from '@app/shared/enums/common-enums';
export class Activity {
  public legsDetails = new Array<Leg>();
  public services = new Array<TripServices>();
  public ratingArr = new Array<Rating>();
  public tripStatus: string;
  public clientId: string;
  public firebaseID: string;
  public quotes:any;
  public disabledeclineall:any;
  public disableacceptall:any;
  public chartertrip :any;
}
export class Leg {
  public createdDate: string;
  public from: string;
  public to: string;
  public sector: string;
  public BLKTIME: string;
  public legid: string;
  public Legstage: string;
  public departDate: string;
  public countdownTime: number;
  public ActualLanding:any;
  public ActualTakeoff:any;
}
export class Notification {
  public Sendertype: string;
  public content: string;
  public logo: string;
  public notificationstage: string;
  public notificationtype: string;
  public senddate: number;
  public sender: string;
  public status: string;
  public view: string;
}
export class Rating {
  public rating: number;
  public platformRating: number;
  public comment: string;
  public image: string;
  public name: string;
  public ratedtoUid: string;
  public ratingStatus: boolean = false;
  public services = [];
  public noVendor: boolean;
  public type: string;
  constructor(
    rating: number,
    platformRating: number,
    comment: string,
    image: string,
    name: string,
    ratedtoUid: string,
    ratingStatus: boolean,
    services: any,
    type: string,
    userType?: number
  ) {
    this.rating = rating;
    this.platformRating = platformRating;
    this.comment = comment;
    this.image = image;
    this.name = name;
    this.ratedtoUid = ratedtoUid;
    this.ratingStatus = ratingStatus;
    this.services = services;
    this.type = type;
    if (this.services.length == 0 && !userType) {
      this.noVendor = true;
    } else {
      this.noVendor = false;
    }
  }
}
export class SendData {
  public id: number;
  public status: string;
  public tripStatus: string;
  public TripProcessing: string;
  public quoteStatus: string;
  public clientFirebaseUid: string;
}
export class ActivityCount {
  public Count: number = null;
  public Done: number = null;
}
export class TripStatusModel {
  public Id: any;
  public status: any;
}
export class ActivityCountModel {
  public Id:any;
  public Count: number = null;
}

export class ServicesListing {
  public sector: string;
  public category: string;
  public legStatus: string;
  public loadStatus: string;
  public legId: number;
  public checked: boolean;
  public statusToggle: boolean;
  public sectorToggle: boolean;
  public serviceToggle: boolean;
  constructor(
    sector: string,
    category: string,
    legStatus: string,
    loadStatus: string,
    legId: number,
    checked: boolean,
    statusToggle: boolean,
    sectorToggle: boolean,
    serviceToggle: boolean
  ) {
    this.sector = sector;
    this.category = category;
    this.legStatus = legStatus;
    this.loadStatus = loadStatus;
    this.legId = legId;
    this.checked = checked;
    this.statusToggle = statusToggle;
    this.sectorToggle = sectorToggle;
    this.serviceToggle = serviceToggle;
  }
}

export class TripActivityModel {
  public activities = "activity";
  public activitiesDetails = "incomplte";
  public completeFlag: boolean;
  public inCompleteFlag: boolean;
  public storeTripStatus: boolean = false;
  public hideFilter: boolean = true;
  public platformRating: number;
  public enableNoActionItemsMsg: boolean = false;
  public storeFetchedActivityData = [];
  public activityData = new Activity();
  public filterServiceData: any;
  public activityOrderPipe = new ActivityOrderPipe();
  public allNotification = new Array<Notification>();
  public notifications = new Array<Notification>();
  public ActivityCount = new ActivityCount();
  public completedActionItems = [];
  public messageConfig = MessageConfig;
  public configSegmentName = PageNames;
  public dateFormate = DateFormate;
  public receivedData = new SendData();
  public spinner: boolean;
  public filterActionItemBy: any;
  public passServiceLength: any;
  public storeINprogressServices = [];
  public storeUpcomingServices = [];
  public processingServices = [];
  public pendingServices = [];
  public completedServices = [];
  public storeNOPendingServices = [];
  public filterStationData: any;
  public passLegStatus: any;
  public ratingarray: any;
  public tripActivityConstants = TRIP_ACTIVITY_CONSTANTS;
  public tripStatuses = TRIP_STATUSES;
  public legStatuses = LEG_STATUSES;
  public hideAcceptance: boolean = true;
  public userType: any;
  public newNoificationCount: boolean = false;
}
