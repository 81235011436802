export const CharterUriConfig = {
  getAvailableAircraftsOfAllOperators: '/index.php?page=API&action=GetAvailableAircraftsOfAllOperators',
  charterRegisterTrip: '/index.php?page=API&action=CharterRegisterTrip',
  getCharterTripEstimateDetails: '/index.php?page=API&action=GetCharterTripEstimateDetails',
  GetQouteInfo: '/index.php?page=API&action=GetTripInfo',
  GetCharterInvoicesAll: '/index.php?page=API&action=GetCharterInvoicesAll',
  GetCharterInvoiceDetails: '/index.php?page=API&action=GetCharterInvoiceDetails',
  charterPassenger: '/index.php?page=API&action=CharterPax',
  getBlktime:'/index.php?page=API&action=GetBlktime',
  getEstimateInfo:'/index.php?page=API&action=GetEstimateInfo',
  choosePassengerCharter: '/index.php?page=API&action=ChoosePassengerCharter',
  addPassengerCharter:'/index.php?page=API&action=AddPassengerCharter',
  getTceDownload: '/index.php?page=API&action=DownloadSchedule',
  downloadCharterQuote:'/index.php?page=API&action=DownloadCharterQuote'
}


