import { Component, Input, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { AuthSessionService } from '@app/core/services/auth-session.service';
import { CommonService } from '@app/shared/services/common.service';
import { FirebaseDataService } from '@app/shared/services/firebase-data.service';
import { ChatInfo, ProfileData, UnreadNotification } from '../models/right-menu.model';
import firebase from "firebase/app";
import { ChatDetails } from '@app/shared/models/shared.model';
import { FileUploadServiceService } from '@app/shared/services/file-upload-service.service';
import { IonContent, Platform } from '@ionic/angular';
import { AircraftMessageConfig } from '@app/setup/aircraft/aircraft-constant-config';
// import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';
import { UtilService } from '@app/core/services/util.service';
import { Capacitor } from '@capacitor/core';
import { SideMenuService } from '../services/side-menu.service';
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-live-chat',
  templateUrl: './live-chat.component.html',
  styleUrls: ['./live-chat.component.scss'],
})
export class LiveChatComponent implements OnInit {
  public message: string;
  @Input() public chatInfo: any;
  @ViewChild(IonContent) public content: IonContent;
  @Output() public openChats: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild("fileInput") public fileInput: ElementRef;
  private userRef: any;
  private chatingRef: string;
  private tripChatingRef: any = [];
  public chatDetailsData = new Array<ChatDetails>();
  private $chatRef: any;
  private recentchatref: any;
  public toChatProfile: ProfileData;
  public captureName = "image";
  private file: any;
  public fileName: any;


  constructor(public commonService: CommonService,
    public auth: AuthSessionService,
    public firebaseDataService: FirebaseDataService,
    private fileUploadService: FileUploadServiceService,
    private sideMenu : SideMenuService ,private authSessionService : AuthSessionService
    ) {
  }

  public ngOnInit() {
    this.chatRef();
    this.userProfileRef();
    this.setUserChatStatus(true);
    this.recentChatNotification();
    this.removeNotification();
  }
  public open(url) {
    const urlBreak: any = url.split('/');
    const filename: any = urlBreak.pop();
    this.firebaseDataService.openDoc(url, filename);
  }

  public ngOnDestroy() {
    this.setUserChatStatus(false);
  }
  //  for chatRefrence
  public chatRef() {
    this.$chatRef = firebase
      .database()
      .ref(
        "/Chats/" +
        this.chatInfo.toChatId +
        "," +
        this.commonService.uid,
      )
      .once("value", (snapshot3) => {
        if (snapshot3.exists()) {
          this.chatingRef =
            this.chatInfo.toChatId +
            "," +
            this.commonService.uid;
          this.finalChatRef();
        } else {
          firebase
            .database()
            .ref(
              "/Chats/" +
              this.commonService.uid +
              "," +
              this.chatInfo.toChatId,
            )
            .once("value", (snapshot2) => {
              if (snapshot2.exists()) {
                this.chatingRef =
                  this.commonService.uid +
                  "," +
                  this.chatInfo.toChatId;
                this.finalChatRef();
              } else {
                this.chatingRef =
                  this.chatInfo.toChatId +
                  "," +
                  this.commonService.uid;
                this.finalChatRef();
              }
            });
        }
      });
  }
  // for chatref with path
  public finalChatRef() {
    this.tripChatingRef = firebase
      .database()
      .ref(
        `/Chats/${this.chatingRef}/${this.chatInfo.tripId}/${this.chatInfo.legId}/${this.chatInfo.location}/${this.chatInfo.service}`,
      );
    this.fetchChatData();
  }
  // for fetch data in firebase
  public fetchChatData() {
    this.tripChatingRef.on("value", (snapshot4) => {
      if (snapshot4.val()) {
        this.chatDetailsData = Object.keys(snapshot4.val()).map(
          (key) => snapshot4.val()[key],
        );
        this.bottomScroll();
      } else {
        this.chatDetailsData = new Array<ChatDetails>();
      }
    });
  }
  // for scroll chat
  public bottomScroll() {
    setTimeout(() => {
      this.content.scrollToBottom(0);
    }, 10);
  }
  public rightclose() {
    this.openChats.next(true);
    this.setUserChatStatus(false);
    this.getTripData()
  }

  public getTripData() {
    this.authSessionService.$userSession.pipe(take(1)).subscribe((res: any) => {
      if (res)
        switch (res.RoleID) {
          case '2':
            this.sideMenu.getClientSideMenuTrips(this.chatInfo.tripId).then((response) => { });
            break;
          case '3':
            this.sideMenu.getVendorSideMenuTrips(this.chatInfo.tripId).then((result) => { });
            break;
          default:
            return false;
        }
    });
  }

  public filechoose(event) {
    this.fileUploadService
      .checkFileType(event.target.files[0], "file")
      .then((filevalid) => {
        if (filevalid) {
          if (event.target.files && event.target.files[0]) {
            const size = event.target.files[0].size;
            if (size < 7000000) {
              const file = event.target.files[0];
              const reader = new FileReader();
              reader.onload = (event1: any) => {
                this.uploadBucket(file, event1.target.result);
              };
              reader.readAsDataURL(event.target.files[0]);
            } else {
              this.commonService.showFileAlert(AircraftMessageConfig.FileSize);
            }
          }
        } else {
          this.commonService.showFileAlert("file");
        }
        event.target.value = null;
      });
  }
  // for upload in bucket
  public uploadBucket(file: any, fileUri: string) {
    this.commonService.showSpinnerLoader();
    const fileType = file.type;
    let filename = file.name;
    filename = filename.split(".");
    const time = new Date(this.commonService.NowTime).getTime();
    const filenameex = filename[0] + time + "." + filename[1];
    this.fileUploadService
      .FirebaseBucketUpload("Chats/" + filenameex, fileUri)
      .then((downloadURl: string) => {
        setTimeout(() => {
          this.sendNewFile(fileType, downloadURl);
          this.commonService.dismissLoader();
        }, 10);
      });
  }
  public attchamentFile() {
    if (Capacitor.isNativePlatform()) {
      this.selectFileForCordova();
    } else {
      this.fileInput.nativeElement.click();
    }
  }
  public async presentImage(file?) {
    if (Capacitor.isNativePlatform()) {
      // this.photoViewer.show('file')
    }
    else {
      this.open(file);
    }
  }
  // for send message
  public sendNewMessage(fileType?: string, fileUrl?: string) {
    this.message = this.message ? this.message.trim() : this.message;
    if (this.message && this.message != "") {
      this.commonService.getUTCTime();
      this.tripChatingRef.push(
        Object.assign(
          {},
          new ChatDetails(
            this.message,
            this.commonService.NowTime,
            String(this.commonService.uid),
            this.chatInfo.toChatId,
            false,
            "text",
          ),
        ),
      );
      this.publishChatNotification(this.toChatProfile.status);

      setTimeout(() => {
        this.bottomScroll();
        this.message = null;
      }, 100);
    } else {
      setTimeout(() => {
        this.bottomScroll();
        this.message = null;
      }, 100);
    }
  }
  // for send attachment
  public sendNewFile(fileType: string, fileUrl: string) {
    this.commonService.getUTCTime();
    this.tripChatingRef.push(
      Object.assign(
        {},
        new ChatDetails(
          "",
          this.commonService.NowTime,
          String(this.commonService.uid),
          this.chatInfo.toChatId,
          false,
          fileType,
          fileUrl,
        ),
      ),
    );
    this.publishChatNotification(this.toChatProfile.status);
    setTimeout(() => {
      this.bottomScroll();
      this.message = null;
    }, 100);
  }
  // for profile refrence
  public userProfileRef() {
    this.userRef = firebase
      .database()
      .ref("/UserProfile/" + this.chatInfo.toChatId);
    this.fetchUserRef();
  }

  public fetchUserRef() {
    let fetchStatuscalled = false;
    this.userRef.on("value", (snapshot5) => {
      this.toChatProfile = new ProfileData(snapshot5.val());
      if (!fetchStatuscalled) {
        this.userActiveStatus();
      }
      fetchStatuscalled = true;
    });
  }
  public userActiveStatus() {
    firebase
      .database()
      .ref(this.userActiveStatusRef(this.chatInfo.toChatId))
      .on("value", (snapshot2: any) => {
        if (snapshot2.val() != null) {
          this.toChatProfile.status = snapshot2.val().status;
        } else {
          this.toChatProfile.status = "Offline";
        }
      });
  }

  public userActiveStatusRef(userId): string {
    return `UserChatStatus/${userId}/${this.chatInfo.tripId}/${this.chatInfo.legId}/${this.chatInfo.location}/${this.chatInfo.service}`;
  }

  public resetValue() {
    this.tripChatingRef.off("value");
  }
  // push chatnotification
  public publishChatNotification(status: string) {
    if (status != "Online") {
      // this.auth.$userSession.subscribe((res) => {
        firebase.database().ref(this.chatNotificationRef()).push({
          toChatId: this.commonService.uid,
          logo: this.commonService.userProfileData.Image,
          name: this.commonService.userProfileData.Name,
        });
      // });
    }
  }
  // recent chatref
  public recentChatRef() {
    return `/RecentChat/${this.commonService.uid}`;
  }

  public recentChatNotification() {
    const recentchat: any = null;
    const chatInfo = new UnreadNotification();
    chatInfo.chatDetails = new ChatInfo(
      this.chatInfo.toChatId,
      Number(this.chatInfo.tripId),
      this.chatInfo.legId,
      this.chatInfo.location,
      this.chatInfo.service,
      "LiveChat",
      "",
    );
    chatInfo.name = String(this.commonService.userProfileData.Name);
    chatInfo.photoUrl = String(
      this.commonService.userProfileData.Image,
    );
    chatInfo.toId = String(
      this.commonService.uid,
    );

    this.recentchatref = firebase
      .database()
      .ref(this.recentChatRef())
      .once("value", (snapshot6: any) => {
        if (snapshot6.val() != null) {
          const chatKey = Object.keys(snapshot6.val());
          const chatArr = [];
          chatArr.push(...snapshot6.val()[chatKey[0]]);
          let duplicateIndex: number;
          const exitChat = chatArr.filter((chats, index) => {
            if (
              chats.name == chatInfo.name &&
              chats.chatDetails.legId == chatInfo.chatDetails.legId &&
              chats.chatDetails.location == chatInfo.chatDetails.location &&
              chats.chatDetails.service == chatInfo.chatDetails.service &&
              chats.chatDetails.toChatId == chatInfo.chatDetails.toChatId &&
              chats.chatDetails.tripId == chatInfo.chatDetails.tripId
            ) {
              duplicateIndex = index;
            }
            return (
              chats.name == chatInfo.name &&
              chats.chatDetails.legId == chatInfo.chatDetails.legId &&
              chats.chatDetails.location == chatInfo.chatDetails.location &&
              chats.chatDetails.service == chatInfo.chatDetails.service &&
              chats.chatDetails.toChatId == chatInfo.chatDetails.toChatId &&
              chats.chatDetails.tripId == chatInfo.chatDetails.tripId
            );
          });
          if (exitChat.length > 0) {
            chatArr.splice(duplicateIndex, 1);
            chatArr.unshift(chatInfo);
          } else {
            chatArr.push(chatInfo);
          }
          if (chatArr.length > 10) {
            chatArr.shift();
          }
          firebase
            .database()
            .ref(`${this.recentChatRef()}/${chatKey}`)
            .set(chatArr);
        } else {
          const chatArr = [];
          chatArr.push(chatInfo);
          firebase.database().ref(this.recentChatRef()).push(chatArr);
        }
      });
  }
  // chat ref ChatNotification
  public chatNotificationRef() {
    return `/ChatNotification/${this.chatInfo.toChatId}/${this.chatInfo.tripId}/${this.chatInfo.legId}/${this.chatInfo.location}/${this.chatInfo.service}`;
  }
  public setUserChatStatus(status: boolean) {
    setTimeout(() => {
      const stausRef = firebase
        .database()
        .ref(
          this.userActiveStatusRef(this.commonService.uid),
        );
      if (status) {
        stausRef.set({ status: "Online" });
      } else {
        this.commonService.getUTCTime();
        stausRef.set({ status: this.commonService.NowTime });
      }
    }, 1000);
  }
  public selectFileForCordova() {
      this.fileUploadService.uploadFile(this.fileInput)
        .then((data: any) => {
          this.attachFileCordova(data);
        });
  }

  public attachFileCordova(data) {
    if (data != "Error") {
      if (data != null || undefined) {
        this.fileUploadService.chekfileType = data;
        this.fileUploadService.imageSelect = this.fileUploadService.base64MimeType(
          this.fileUploadService.chekfileType,
        );
        this.commonService.showSpinnerLoader();
        if (this.fileUploadService.imageSelect == "image/jpeg") {
          const time = new Date(this.commonService.NowTime).getTime();
          const fileName = this.captureName + time + "." + "jpg";
          const file64 = data;
          this.cordovaUpload(
            fileName,
            file64,
            this.fileUploadService.imageSelect,
          );
        } else {
          const filename = data.filename;
          this.file = data.base64;
          const filedot = filename.substr(filename.lastIndexOf(".") + 1);
          const time = new Date(this.commonService.NowTime).getTime();
          this.fileName = time + "." + filedot;
          this.cordovaUpload(
            this.fileName,
            this.file,
            this.fileUploadService.chekfileType.mediaType,
          );
        }
      }
    }
  }
  public removeNotification() {
    firebase
      .database()
      .ref(
        `/ChatNotification/${this.commonService.uid}/${this.chatInfo.tripId}/${this.chatInfo.legId}/${this.chatInfo.location}/${this.chatInfo.service}`,
      )
      .remove();
  }
  public cordovaUpload(fileName, file64, type) {
    this.fileUploadService
      .FirebaseBucketUpload("Chats/" + fileName, file64)
      .then((downloadURl: string) => {
        setTimeout(() => {
          this.sendNewFile(type, downloadURl);
          this.commonService.dismissLoader();
        }, 10);
      });
  }
}
