import { Directive,ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[hideTitle]'
})
export class HidetitleDirective  {
  @Input('iconTitle') public iconTitle: string;
  constructor(private el: ElementRef<HTMLElement>) { }
  public ngAfterViewInit() {
    const removeTitle = () => {
      if (
          this.el.nativeElement &&
          this.el.nativeElement.shadowRoot &&
          this.el.nativeElement.shadowRoot.querySelector('.icon-inner svg title')
      ) {
          this.el.nativeElement.shadowRoot.querySelector('.icon-inner svg title').innerHTML =
              this.iconTitle || '';
      } else {
          setTimeout(() => {
              removeTitle();
          }, 500);
      }
  };
  removeTitle();
  }
}
