import { Injectable } from '@angular/core';
import { ServerConnectService } from '@app/core/services/server-connect.service';
import { UtilService } from '@app/core/services/util.service';
import { ClientAircraft } from '@app/trips/schedule-creation/model/client-aircraft';
import {
  FinalTrip,
  Station,
  TripBasicDeatils,
} from '@app/trips/schedule-creation/model/create-schedule';
import { LegInformationDeatils } from '@app/trips/schedule-creation/model/schedule-model';
import {
  AviationWeather,
  EstimateURI,
  NFPURI,
  ScheduleURI,
} from '@app/trips/schedule-creation/schedule-config-url';
import { NewTripError } from '@app/trips/schedule-creation/schedule-constant-config';
import * as moment from 'moment';
import { ApiTypes } from '../constants/constant';
import { FlyingDatetimePipe } from '../pipes/flying-datetime.pipe';
import { CommonService } from './common.service';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class CreateScheduleService {
  public type = ApiTypes;

  constructor(
    private serverConnectService: ServerConnectService,
    private commonService: CommonService,
    private dataservice: DataService,
    private flyingDatetime: FlyingDatetimePipe,
    private utilservice: UtilService
  ) { }

  public getDraftTripdetails(DraftTripid, resolve) {
    const databody = 'trip_id=' + DraftTripid;
    this.serverConnectService
      .connectToServer(
        this.type.POST,
        ScheduleURI.DraftTripUrl,
        databody,
        false,
        false,
        false
      )
      .then((response: any) => {
        this.commonService.dissmissSpinnerLoader();
        resolve(response.ResponseStatus);
      });
  }
  // getting alternate staions
  public alternateStionlist(destination: Station) {
    return new Promise((resolve) => {
      const databody = 'data=' + JSON.stringify(destination);
      this.commonService.showSpinnerLoader();
      this.serverConnectService
        .connectToServer(
          this.type.POST,
          ScheduleURI.DraftTripUrl,
          databody,
          false,
          false,
          false
        )
        .then((stations: any) => {
          this.commonService.dismissLoader();
          resolve(stations.GetAlternateAirport);
        });
    });
  }

  public newAlternateStationList(destination: Station) {
    return new Promise((resolve, reject) => {
      this.dataservice.getStationList$().then(
        (stationData: Station[]) => {
          resolve(
            this.dataservice.filterArr(stationData, destination).slice(0, 100)
          );
        },
        (err) => {
          reject('sorry we are unable to fetch records.');
        }
      );
    });
  }

  // generate new route for the leg
  public getRoute(legId: number, leg: TripBasicDeatils, crewProfile: any) {
    return new Promise((resolve, reject) => {
      const route = {
        depart: this.covertUTCtime(leg.departDate, leg.fromStationTimeZone),
        rule: leg.flightRule,
        types: leg.flightType,
        operation: leg.operationType,
      };
      this.commonService.showSpinnerLoader(NewTripError.GeneRoutemessage);
      const body =
        'data=' +
        JSON.stringify(route) +
        '&aircraftID=' +
        leg.aircraft +
        '&legIndex=' +
        (legId + 1) +
        '&fromStation=' +
        leg.from +
        '&toStation=' +
        leg.to +
        '&timezone=' +
        (leg.timeType == 'true' ? 'Local' : 'UTC') +
        '&flightType=' +
        leg.flightType +
        '&operationType=' +
        leg.operationType +
        '&ruleType=' +
        leg.flightRule +
        '&pax=' +
        leg.pax +
        '&crew=' +
        leg.crew +
        '&cruieseSpeed=' +
        JSON.stringify(crewProfile) +
        '&Flightlevel=' +
        leg.flightLvel +
        '&flightLevelType=' +
        leg.flightLevelType +
        '&ETOPS=' +
        leg.etops +
        '&FuelPolicy=' +
        (leg.fuelPolicy != undefined ? leg.fuelPolicy : 'Maximum Fuel Policy') +
        '&extrafuel=' +
        leg.extrafuel +
        '&adequate=' +
        (leg.adequate != undefined ? leg.adequate : '1') +
        '&depalt=' +
        (leg.depAlt ? leg.depAlt.substring(0, 4) : null) +
        '&alt1=' +
        (leg.alt1 ? leg.alt1.substring(0, 4) : null) +
        '&alt2=' +
        (leg.alt2 ? leg.alt2.substring(0, 4) : null) +
        '&stopType=' +
        '' +
        '&avoidFirs=' +
        '';
      this.serverConnectService
        .connectToServer(
          this.type.POST,
          ScheduleURI.DraftTripUrl,
          body,
          false,
          false,
          false
        )
        .then((routes: any) => {
          this.commonService.dismissLoader();
          if (routes.GetRouteDetails.error) {
            this.commonService.showMeassgeAlert(routes.GetRouteDetails.error);
          } else {
            resolve(routes.GetRouteDetails);
          }
          this.commonService.dismissLoader();

          this.commonService.showErrorAlert().then((option: any) => {
            if (option) {
              this.getRoute(legId, leg, crewProfile);
            }
          });
        });
    });
  }

  public covertUTCtime(time, timeZone) {
    return timeZone.tz(time, timeZone).utc().format();
  }
  public registerTrip(arr: FinalTrip) {
    const senddata = JSON.parse(JSON.stringify(arr));
    if (senddata.savedTCEArr.airpermissions) {
      senddata.savedTCEArr.airpermissions.forEach((element) => {
        if (element.quoteDetails) {
          element.quoteDetails.logo = '';
        }
      });
    }

    return new Promise((resolve) => {
      this.commonService.showSpinnerLoader(NewTripError.RegisterTripLoader);
      const body = 'data=' + JSON.stringify(senddata);
      this.serverConnectService
        .connectToServer(
          this.type.POST,
          EstimateURI.RegisterTrpUrl,
          body,
          false,
          false,
          false
        )
        .then(
          (data: any) => {
            resolve(data.RegisterTrip);
          },
          (err) => {
            this.commonService.dismissLoader();
            resolve(err);
          }
        );
    });
  }
  public saveDraftTrip(rawdata, draftTripID: string) {
    // this.commonService.showSpinnerLoader(NewTripError.EstimateText);
    this.commonService.isSpinnerLoading = true;
    this.commonService.dynamicMessage = NewTripError.EstimateText;
    const senddata = JSON.parse(JSON.stringify(rawdata));
    return new Promise((resolve, reject) => {
      for (const leg of Object.keys(senddata)) {
        for (const service of Object.keys(senddata[leg].VendorArr)) {
          for (const station of Object.keys(senddata[leg].VendorArr[service])) {
            senddata[leg].VendorArr[service][station].forEach((element) => {
              element.logo = '';
            });
          }
        }
        senddata[leg].selectedVendorList.forEach((data) => {
          data.logo = '';
        });
      }

      const databody =
        'trip_id=' + draftTripID + '&trip_data=' + JSON.stringify(senddata);
      this.serverConnectService
        .connectToServer(
          this.type.POST,
          EstimateURI.SaveDraftTrpUrl,
          databody,
          false,
          false,
          false
        )
        .then(
          (data: any) => {
            if (data.ResponseStatus == 'Success') {
              // this.commonService.dismissLoader();
              this.commonService.isSpinnerLoading = false;
             document.body.classList.remove('spinner-active');
              resolve(data.saveRawTripData);
            }
          },
          (err) => {
            // this.commonService.dismissLoader();
            this.commonService.isSpinnerLoading = false;
            document.body.classList.remove('spinner-active');
            this.utilservice.errorAlert('', err.message);
            resolve([]);
          }
        );
    });
  }
  public deleteDraftTrip(draftTripID, SRID) {
    return new Promise((resolve) => {
      const databody = 'trip_id=' + draftTripID + '&SRID=' + SRID;
      this.serverConnectService
        .connectToServer(
          this.type.POST,
          EstimateURI.UpdateDraftTrip,
          databody,
          false,
          false,
          false
        )
        .then(
          (data: any) => {
            this.commonService.dismissLoader();
            resolve(data.UpdateDraftTrip);
          },
          (err) => {
            this.commonService.dismissLoader();
            resolve('Error');
          }
        );
    });
  }
  public downloadIternary(data: LegInformationDeatils[], index?: string) {
    const temp = this.createArrayForIternaryVendor(data, index);
    this.commonService.showSpinnerLoader();
    return new Promise((resolve, reject) => {
      const databody = 'data=' + JSON.stringify(temp);
      this.serverConnectService
        .connectToServer(
          this.type.POST,
          ScheduleURI.DownloadPDF,
          databody,
          false,
          false,
          false
        )
        .then(
          (value: any) => {
            this.commonService.dismissLoader();
            resolve(value.DownloadIternaryPDF);
          },
          (err) => {
            this.commonService.dismissLoader();
            resolve('Error');
          }
        );
    });
  }

  public createArrayForIternaryVendor(data: LegInformationDeatils[], index: string) {
    return data.map((items: LegInformationDeatils) => {
      return {
        from: items.fromStation.name,
        to: items.toStation.name,
        departDate: items.departDate,
        timeType: items.timeType = "true",
        crew: items.noOfCrew,
        pax: items.noOfPax,
        flightType: items.flightType,
        operationType: items.operationType,
        flightRule: items.flightRule,
        aircraft: items.aircraftId,
        fromStationTimeZone: items.fromStation.timeZone,
        toStationTimeZone: items.toStation.timeZone,
        BLKTIME: items.BLKTIME,
        DIST: items.travelDistrance,
        ETDGMT: items.ETDGMT,
        ETAGMT: items.ETAGMT,
        ETALT: items.ETALT,
        ETDLT: items.ETDLT,
        id: items.legId,
        sector: items.sector,
        UTC1: items.route.timezonedetails.UTC1,
        UTC2: items.route.timezonedetails.UTC2,
        DEPTTIMELOCAL: moment(
          this.flyingDatetime.transform(
            items.departDate,
            items.BLKTIME,
            "LT",
            items.fromStation.timeZone,
            "from",
          ),
        ).format("D-MMM HH:mm"),
        DEPTTIMEUTC: moment(
          this.flyingDatetime.transform(
            items.departDate,
            items.BLKTIME,
            "UTC",
            items.fromStation.timeZone,
            "from",
            "zulu",
          ),
        ).format("D-MMM HH:mm"),
        ARRTIMELOCAL: moment(
          this.flyingDatetime.transform(
            items.departDate,
            items.BLKTIME,
            "LT",
            items.toStation.timeZone,
            "to",
          ),
        ).format("D-MMM HH:mm"),
        ARRTIMEUTC: moment(
          this.flyingDatetime.transform(
            items.departDate,
            items.BLKTIME,
            "UTC",
            items.toStation.timeZone,
            "to",
            "zulu",
          ),
        ).format("D-MMM HH:mm"),
        SRID: index == "0" ? items.srid : "",
      };
    });
  }
  public getWX(type: string, station: string) {
    const databody = "hoursBeforeNow=1&stationString=" + station;
    const url = type == 'metar' ? AviationWeather.weatherMetar : AviationWeather.weatherTafs;
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.type.POST,
          url,
          databody,
          false,
          false,
          false,
        )
        .then((data: any) => {
          resolve(data);
        });
    });
  }
  public getNotams(station: string) {
    const databody = "station=" + station;;
    return new Promise((resolve) => {
      this.serverConnectService
        .connectToServer(
          this.type.POST,
          NFPURI.WeatherNotams,
          databody,
          false,
          false,
          false,
          "api/v1"
        )
        .then((data: any) => {
          resolve(data);
        });
    });
  }
  public getWsNotams(station: string) {
    this.commonService.showSpinnerLoader();
    const responseStatus = {
      metar: false,
      metarText: 'NA',
      tafs: false,
      tafsText: 'NA',
      notams: false,
      notamsText: 'NA'
    }
    return new Promise((resolve) => {
      this.getWX("metar", station).then((weatherXml: any) => {
        if(weatherXml.GetWeatherMetar!=false && weatherXml?.GetWeatherMetar?.data?.METAR)
        {
          responseStatus.metar = true;
          const data = weatherXml?.GetWeatherMetar?.data;
          if (data['@attributes']?.num_results > 0) {
            responseStatus.metarText = data?.METAR.raw_text ? data?.METAR.raw_text : data?.METAR[0]?.raw_text;
          }
          this.resolveAllCalls(responseStatus, resolve);
          this.getWX("tafs", station).then((weatherXML: any) => {
            responseStatus.tafs = true;
            const weatherData = weatherXML?.GetWeatherTafs?.data;
            if (weatherData['@attributes']?.num_results > 0 && weatherData?.TAF) {
              responseStatus.tafsText = weatherData?.TAF?.raw_text ? weatherData?.TAF?.raw_text : (weatherData?.TAF[0]?.raw_text ? weatherData?.TAF[0]?.raw_text : "NA");
            }
            else
            {
               this.commonService.dissmissSpinnerLoader();
            }
            this.resolveAllCalls(responseStatus, resolve);
          });
        }
        else
        {
          this.commonService.dissmissSpinnerLoader();
        }
      });
    });
  }
  public resolveAllCalls(responseStatus, resolve) {
    if (responseStatus.tafs && responseStatus.metar) {
      this.commonService.dissmissSpinnerLoader();
      resolve(responseStatus);
    }
  }
  public getSingleStationNotams(station: string) {
    return new Promise((resolve) => {
      this.getNotams(station)
        .then((weatherXml: any) => {
          const notamsText = !weatherXml ? [] : weatherXml?.WeatherNotam?.Text ? [weatherXml?.WeatherNotam] : weatherXml?.WeatherNotam;
          resolve(notamsText);
        });
    });
  }
}