import { NgModule } from '@angular/core';
import { FocuserDirective } from './focuser.directive';
import { NumbersOnlyDirective } from './numbers-only.directive';
import { DigitOnlyDirective } from './digit-only.directive';
import { PercentageValidationDirective } from './percentage-validation.directive';
import { UppercaseDirective } from './uppercase-directive.directive';
import { HidetitleDirective } from './hidetitle.directive';
import { AutocopyDirective } from './autocopy.directive';

@NgModule({
  imports: [],
  declarations: [NumbersOnlyDirective, FocuserDirective, DigitOnlyDirective, PercentageValidationDirective, UppercaseDirective, HidetitleDirective, AutocopyDirective],
  exports: [NumbersOnlyDirective, FocuserDirective, DigitOnlyDirective, PercentageValidationDirective,UppercaseDirective,HidetitleDirective,AutocopyDirective],
})
export class SharedDirectiveModule { }
