import { Injectable } from '@angular/core';
import { AuthSessionService } from '@app/core/services/auth-session.service';
import { ServerConnectService } from '@app/core/services/server-connect.service';
import { ApiTypes } from '@app/shared/constants/constant';
import { TimerService } from '@app/shared/services/timer.service';
import { Schedule } from '@app/trips/trips-tab/model/trip-schedule';
import { activityUriConfig } from '../constants/trip-config-url';
import { CommonService } from '@app/shared/services/common.service';

@Injectable({
  providedIn: 'root',
})
export class ScheduleService {
  public apiType = ApiTypes;
  constructor(
    private authSessionService: AuthSessionService,
    private serverConnectService: ServerConnectService,
    private timerService: TimerService,
    private commonService: CommonService
  ) { }
  public fetchTripSchedule(tripId: number) {
    return new Promise((resolve, reject) => {
      let TripScheduleDatabody = 'id=' + tripId;
      if (this.commonService.roleId == 2) {
        this.serverConnectService
          .connectToServer(
            this.apiType.POST,
            activityUriConfig.GetTripScheduleDetails,
            TripScheduleDatabody,
            false,
            false,
            false
          )
          .then((tripData: any) => {
            const schedule = new Schedule();
            schedule.Regd = tripData.GetTripScheduleDetails.srdata.Regd;
            schedule.quotesstaus = tripData.GetTripScheduleDetails.quotesstaus;
            schedule.clientUID = tripData.GetTripScheduleDetails.ClientUID;
            schedule.legs = tripData.GetTripScheduleDetails.srdata.Legs;
            schedule.tripStatus = tripData.GetTripScheduleDetails.status;
            schedule.createDate =
              tripData.GetTripScheduleDetails.srdata.DateCreated;
            schedule.legs.forEach((legDetails) => {
              legDetails.countdownTime = this.getServiceDeliveryDateTime(
                schedule.createDate,
                legDetails.departDate,
                legDetails.BLKTIME,
                legDetails.Legstage,
                'timer'
              );
              legDetails.progressDate = this.getServiceDeliveryDateTime(
                schedule.createDate,
                legDetails.departDate,
                legDetails.BLKTIME,
                legDetails.Legstage,
                'progressbar'
              );
            });
            resolve(schedule);
          })
          .catch((error) => {
            // this.commonService.showErrorAlert().then(res => {
            //   if (res) {
            //     this.fetchTripSchedule(tripId);
            //   }
            //   else {
            //     reject();
            //   }
            // })
          });
      } else if (this.commonService.roleId == 3) {
        TripScheduleDatabody = 'tripID=' + tripId;
        this.serverConnectService
          .connectToServer(
            this.apiType.POST,
            activityUriConfig.getVendorTripSchedule,
            TripScheduleDatabody,
            false,
            false,
            false
          )
          .then((tripData: any) => {
            const schedule = new Schedule();
            schedule.Regd = tripData.GetVendorTripsScheduleDetails.srdata.Regd;
            schedule.legs = tripData.GetVendorTripsScheduleDetails.srdata.Legs;
            schedule.tripStatus = tripData.GetVendorTripsScheduleDetails.Status;
            schedule.createDate =
              tripData.GetVendorTripsScheduleDetails.srdata.DateCreated;
            schedule.legs.forEach((legDetails) => {
              legDetails.countdownTime = this.getServiceDeliveryDateTime(
                schedule.createDate,
                legDetails.departDate,
                legDetails.BLKTIME,
                legDetails.Legstage,
                'timer',
                'vendor'
              );
              legDetails.progressDate = this.getServiceDeliveryDateTime(
                schedule.createDate,
                legDetails.departDate,
                legDetails.BLKTIME,
                legDetails.Legstage,
                'progressbar',
                'vendor'
              );
            });
            resolve(schedule);
          })
          .catch((error) => {
            // this.commonService.showErrorAlert().then(res => {
            //   if (res) {
            //     this.fetchTripSchedule(tripId);
            //   }
            //   else {
            //     reject();
            //   }
            // });
          });
      } else if (this.commonService.roleId == 7) {
        const tripScheduleDatabody = 'id=' + tripId;
        this.serverConnectService
          .connectToServer(
            this.apiType.POST,
            activityUriConfig.GetTripSchedule,
            tripScheduleDatabody,
            false,
            false,
            false
          )
          .then((tripData: any) => {
            const schedule = new Schedule();
            schedule.Regd = tripData.GetTripSchedule.srdata.Regd;
            schedule.quotesstaus = tripData.GetTripSchedule.quotesstaus;
            schedule.clientUID = tripData.GetTripSchedule.ClientUID;
            schedule.legs = tripData.GetTripSchedule.srdata.Legs;
            schedule.tripStatus = tripData.GetTripSchedule.status;
            schedule.createDate = tripData.GetTripSchedule.srdata.DateCreated;
            schedule.legs.forEach((legDetails) => {
              legDetails.countdownTime = this.getServiceDeliveryDateTime(
                schedule.createDate,
                legDetails.departDate,
                legDetails.BLKTIME,
                legDetails.Legstage,
                'timer'
              );
              legDetails.progressDate = this.getServiceDeliveryDateTime(
                schedule.createDate,
                legDetails.departDate,
                legDetails.BLKTIME,
                legDetails.Legstage,
                'progressbar'
              );
            });
            resolve(schedule);
          })
          .catch((error) => {
            // this.commonService.showErrorAlert().then(res => {
            //   if (res) {
            //     this.fetchTripSchedule(tripId);
            //   }
            //   else {
            //     reject();
            //   }
            // });
          });
      }
    });
  }
  /**
   *
   * @param createDate  date when the trip is created
   * @param DepartDate  date when the aircraft is flying
   * @param flyingTime how much time it will take to reach the destination
   * @param legStatus status of the leg
   * @param returnType is it request for to return the time or progress time
   *
   */
  public getServiceDeliveryDateTime(
    createDate: any,
    DepartDate: string,
    flyingTime: string,
    legStatus: string,
    returnType: string,
    user?:string
  ): any {
    if (legStatus == 'Processing') {
      if (returnType == 'progressbar') {
        return this.timerService.confirmationRequest(createDate, DepartDate);
      } else {
        return this.timerService.timeDiffInSecond(
          user == 'vendor' ?
         this.timerService.confirmationRequest(createDate, DepartDate): DepartDate,
          this.timerService.getTimeNow()
        );
      }
    } else {
      if (returnType == 'progressbar') {
        return this.checkArrivalDepartureStation(
          DepartDate,
          flyingTime,
          legStatus
        );
      } else {
        return this.timerService.timeDiffInSecond(
          this.checkArrivalDepartureStation(DepartDate, flyingTime, legStatus),
          this.timerService.getTimeNow()
        );
      }
    }
  }
  /**
   * @param legStatus status of the leg
   * @param departDate takeoff time
   * @param flyingTime hour of flyingtime
   */
  public checkArrivalDepartureStation(
    departDate: string,
    flyingTime: string,
    legStatus: string
  ) {
    if (legStatus == 'Inprogressfinishing') {
      return this.timerService.addMinutes(
        this.timerService.getTimeInMin(flyingTime),
        departDate
      );
    } else if (legStatus == 'Inprogressfinished') {
      return this.timerService.addMinutes(
        20,
        this.timerService.addMinutes(
          this.timerService.getTimeInMin(flyingTime),
          departDate
        )
      );
    } else {
      return departDate;
    }
  }
}
