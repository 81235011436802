import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import { ApiConfig } from '@app/shared/constants/url-constant';
import { ApiTypes, Status } from '@app/shared/constants/constant';
import { ACTION_ITEMS_CATEGORY } from '@app/shared/enums/common-enums';
import { TimerService } from '@app/shared/services/timer.service';
import { ServerConnectService } from '@app/core/services/server-connect.service';
import { CommonService } from '@app/shared/services/common.service';
import { ACTION_ITEMS } from '@app/shared/enums/common-enums';
import {
  inProgressLegStages,
  ACTIVITY_SERVICES,
  HANDLING_ACTION_ITEM_LIST,
  ACTION_ITEM_NAME,
  ACTION_ITEM_ORDER,
  CONSTANT_MESSAGES,
} from '@app/trips/constants/trips-constant-config';
import { TripServices } from '@app/trips/trips-tab/model/trip-service.model';
import { ApiResponseMessages } from '@app/trips/trips-tab/constants/constant-message';
import { ServiceName } from '@app/trips/constants/trips-constant-config';
import {
  Activity,
  Rating,
} from '@app/trips/trips-tab/model/trip-activity.model';
import { activityUriConfig } from '../constants/trip-config-url';
import { AuthSessionService } from '@app/core/services/auth-session.service';
import { TripRouteService } from './trip-route.service';
import { User } from '@app/core/models/core.model';

@Injectable({
  providedIn: 'root',
})
export class ActivityService {
  public apiType = ApiTypes;
  private notificationRef: any;
  private servicesCategory = ACTION_ITEMS_CATEGORY;
  public tripServices: any;
  public selectFlightPlan: any;
  public $tripNotification: BehaviorSubject<Notification[]> =
    new BehaviorSubject([]);
  public $tripUnreadNotification: BehaviorSubject<number> = new BehaviorSubject(
    null
  );
  public UnreadNotificationRef: any;
  public BayNumber = new BehaviorSubject<any>({});
  public reloadActivity = new Subject<boolean>();
  public tripActivityCountData = new Subject<number>();
  public declineReq = new Subject<boolean>();
  public acceptReq = new Subject<boolean>();
  constructor(
    private commonService: CommonService,
    private ServerConnect: ServerConnectService,
    private timerService: TimerService,
    private authSessionService: AuthSessionService,
    private tripRouteService: TripRouteService,
  ) { }
  public getFlightPlanSelectedData() {
    for (const service in this.tripServices) {
      if (service) {
        this.tripServices[service].forEach((leg) => {
          if (
            service == 'crew briefing' &&
            leg.UploadedArray &&
            leg.UploadedArray.length > 0
          ) {
            for (const element of leg.UploadedArray) {
              if (element?.Selected == "1") {
                this.selectFlightPlan = true;
                break;
              }
            }
          }
        });
      }
    }
  }
  public async fetchTripActivity(tripId: number) {
    return new Promise((resolve, reject) => {
      this.tripNotification(tripId);
      this.tripActivityCount(tripId);
      let databody = 'id=' + tripId;
      if (this.commonService.roleId == 2) {
        // client
        this.ServerConnect.connectToServer(
          this.apiType.POST,
          ApiConfig.getClientTripActivity,
          databody,
          false,
          false,
          false
        ).then(
          async (tripData: any) => {
            const Activities = new Activity();
            Activities.clientId = tripData?.GetTripActivity?.ClientUID;
            Activities.tripStatus = tripData?.GetTripActivity?.status;
            Activities.clientId = tripData?.GetTripActivity?.srdata?.Idclient;
            Activities.disableacceptall = tripData?.GetTripActivity?.disableacceptall;
            Activities.disabledeclineall = tripData?.GetTripActivity?.disabledeclineall;
            Activities.chartertrip = tripData?.GetTripActivity?.chartertrip;
            Activities.legsDetails = tripData?.GetTripActivity?.srdata?.Legs.map(
              (legdata: any) => {
                return {
                  ActualLanding: legdata.ActualLanding,
                  ActualTakeoff: legdata.ActualTakeoff,
                  createdDate: legdata.CreatedDate,
                  from: legdata.from,
                  to: legdata.to,
                  sector: legdata.sector,
                  BLKTIME: legdata.BLKTIME,
                  legid: legdata.legid,
                  Legstage: legdata.Legstage,
                  departDate: legdata.depart,
                  quotes: tripData?.GetTripActivity?.quotes,
                  countdownTime:
                    Activities.tripStatus == 'Unapproved'
                      ? this.timerService.timeDiffInSecond(
                        this.timerService.confirmationRequest(
                          tripData.GetTripActivity.srdata.Legs[0].CreatedDate,
                          tripData.GetTripActivity.srdata.Legs[0].depart
                        ),
                        this.timerService.getTimeNow()
                      )
                      : this.timerService.timeDiffInSecond(
                        legdata.depart,
                        this.timerService.getTimeNow()
                      ),
                };
              }
            );
            if (Activities.tripStatus == 'Completed') {
              await this.createRatingArr(
                tripData.GetTripActivity.quotes,
                Activities,
                tripData.GetTripActivity.srid
              ).then((data: any) => {
                setTimeout(() => {
                  resolve(this.createClientActivityArr(Activities, tripData));
                }, 500);
              });
            } else {
              resolve(this.createClientActivityArr(Activities, tripData));
            }
          },
          (error) => {
            this.commonService.showErrorAlert().then((res) => {
              if (res) {
                this.fetchTripActivity(tripId);
              } else {
                reject();
              }
            });
          }
        );
      } else if (this.commonService.roleId == 3) {
        // vendor
        databody = 'tripID=' + tripId;
        this.ServerConnect.connectToServer(
          this.apiType.POST,
          ApiConfig.getVendorTripActivity,
          databody,
          false,
          false,
          false
        ).then(
          (tripData: any) => {
            const Activities = new Activity();
            Activities.clientId = tripData?.GetVendorTripsActivity?.ClientUID;
            Activities.tripStatus = tripData?.GetVendorTripsActivity?.Status;
            Activities.clientId = tripData?.GetVendorTripsActivity?.srdata?.Idclient;
            Activities.disableacceptall = tripData?.GetVendorTripsActivity?.disableacceptall;
            Activities.disabledeclineall = tripData?.GetVendorTripsActivity?.disabledeclineall;
            Activities.chartertrip = tripData?.GetVendorTripsActivity?.chartertrip;
            Activities.legsDetails =
              tripData?.GetVendorTripsActivity?.srdata?.Legs?.map(
                (legdata: any) => {
                  return {
                    ActualLanding: legdata.ActualLanding,
                    ActualTakeoff: legdata.ActualTakeoff,
                    createdDate: legdata.CreatedDate,
                    from: legdata.from,
                    to: legdata.to,
                    sector: legdata.sector,
                    BLKTIME: legdata.BLKTIME,
                    legid: legdata.legid,
                    Legstage: legdata.Legstage,
                    departDate: legdata.depart,
                    countdownTime:
                      Activities.tripStatus == 'Unapproved'
                        ? this.timerService.timeDiffInSecond(
                          this.timerService.confirmationRequest(
                            tripData.GetTripActivity.srdata.Legs[0]
                              .CreatedDate,
                            tripData.GetTripActivity.srdata.Legs[0].depart
                          ),
                          this.timerService.getTimeNow()
                        )
                        : this.timerService.timeDiffInSecond(
                          legdata.depart,
                          this.timerService.getTimeNow()
                        ),
                  };
                }
              );
            if (Activities.tripStatus == 'Closed') {
              this.createRatingArr(
                tripData.GetVendorTripsActivity.quotes,
                Activities,
                tripData.GetVendorTripsActivity.SRID,
                tripData.GetVendorTripsActivity.clientDetails
              ).then((data) => {
                setTimeout(() => {
                  resolve(this.createVendorServiceArr(Activities, tripData));
                }, 500);
              });
            } else {
              resolve(this.createVendorServiceArr(Activities, tripData));
            }
          },
          (error) => {
            this.commonService.showErrorAlert().then((res) => {
              if (res) {
                this.fetchTripActivity(tripId);
              } else {
                reject();
              }
            });
          }
        );
      }
    });
  }
  public async clientRating(quotesArr, Activities: Activity, tripId) {
    for (const legId in quotesArr) {
      if (legId) {
        for (const service in quotesArr[legId]) {
          if (service) {
            quotesArr[legId][service].forEach((serviceDetails: any) => {
              if (serviceDetails.status != 'inhouse') {
                if (
                  Activities.ratingArr.filter((item) => {
                    return (
                      item.ratedtoUid.indexOf(
                        String(serviceDetails.firebaseID)
                      ) > -1
                    );
                  }).length > 0
                ) {
                  if (
                    Activities.ratingArr
                      .filter((item) => {
                        return (
                          item.ratedtoUid.indexOf(
                            String(serviceDetails.firebaseID)
                          ) > -1
                        );
                      })[0]
                      .services.indexOf(service) == -1
                  ) {
                    Activities.ratingArr
                      .filter((item) => {
                        return (
                          item.ratedtoUid.indexOf(
                            String(serviceDetails.firebaseID)
                          ) > -1
                        );
                      })[0]
                      .services.push(service);
                  }
                } else {
                  Activities.ratingArr.push(
                    new Rating(
                      0,
                      0,
                      '',
                      serviceDetails.logo,
                      serviceDetails.Vendorname,
                      serviceDetails.firebaseID,
                      false,
                      [service],
                      'client'
                    )
                  );
                }
              }
            });
          }
        }
      }
    }
  }
  public vendorRatingArr(
    quotesArr,
    Activities: Activity,
    tripId,
    clientDetails: any
  ) {
    Activities.ratingArr.push(
      new Rating(
        0,
        0,
        '',
        clientDetails.logo,
        clientDetails.ClientName,
        clientDetails.firebase_uid,
        false,
        [],
        'vendor',
        3
      )
    );
  }
  public CreateActionForFuelOn(
    Activities: Activity,
    serviceDetails: any,
    tripData: any,
    legIndex
  ) {
    Activities.services.push({
      SRID: tripData.srid,
      legIndex,
      legId: serviceDetails.legid,
      serviceDetails: serviceDetails.fuelDetailsInprogress,
      subservicedetails: [],
      taxes: serviceDetails.taxes,
      price: serviceDetails.price,
      status:
        serviceDetails.fuelDetails.length > 0
          ? serviceDetails.fuelDetails.filter((item) => {
            return item.FuelOn == '';
          }).length > 0
            ? 'pending'
            : 'confirmed'
          : '',
      flightPlanningUploaded: false,
      sector: serviceDetails.sector,
      legStatus: Activities.legsDetails.filter((item) => {
        return item.legid.indexOf(String(serviceDetails.legid)) > -1;
      })[0].Legstage,
      tripStatus: Activities.tripStatus,
      order: 2.8,
      location: serviceDetails.location
        ? serviceDetails.location
        : serviceDetails.sector,
      vendorid: serviceDetails.vendorid,
      servicename: 'Select Fuel On',
      serviceType: 'Fuel',
      category: this.servicesCategory.FUEL,
      requestType: 'Vendor',
      logo: '',
      fuelDetailsInprogress: serviceDetails.fuelDetailsInprogress,
      fuelDetails: serviceDetails.fuelDetails,
      departSecond: this.timerService.confirmationRequest(
        tripData.srdata.Legs[0].CreatedDate,
        tripData.srdata.Legs[0].depart
      ),
      createSecond: tripData.srdata.DateCreated,
      timerCount: this.getServiceDeliveryDateTime(
        tripData.srdata.DateCreated,
        Activities.legsDetails.filter((item) => {
          return item.legid.indexOf(String(serviceDetails.legid)) > -1;
        })[0].departDate,
        serviceDetails.sector,
        serviceDetails.location
          ? serviceDetails.location
          : serviceDetails.sector,
        Activities.legsDetails.filter((item) => {
          return item.legid.indexOf(String(serviceDetails.legid)) > -1;
        })[0].BLKTIME,
        Activities.legsDetails.filter((item) => {
          return item.legid.indexOf(String(serviceDetails.legid)) > -1;
        })[0].Legstage,
        Activities.tripStatus,
        'timer',
        'Fuel'
      ),
      progressDate: this.getServiceDeliveryDateTime(
        tripData.srdata.DateCreated,
        Activities.legsDetails.filter((item) => {
          return item.legid.indexOf(String(serviceDetails.legid)) > -1;
        })[0].departDate,
        serviceDetails.sector,
        serviceDetails.location
          ? serviceDetails.location
          : serviceDetails.sector,
        Activities.legsDetails.filter((item) => {
          return item.legid.indexOf(String(serviceDetails.legid)) > -1;
        })[0].BLKTIME,
        Activities.legsDetails.filter((item) => {
          return item.legid.indexOf(String(serviceDetails.legid)) > -1;
        })[0].Legstage,
        Activities.tripStatus,
        'progressbar',
        'Fuel'
      ),
      ActualLanding: Activities?.legsDetails[0]?.ActualLanding,
      ActualTakeoff: Activities?.legsDetails[0]?.ActualTakeoff,
    });
  }
  public submitRating(tripId: string | number, activitydata: Rating[]) {
    return new Promise((resolve, reject) => {
      this.commonService.showSpinnerLoader();
      this.authSessionService.$userSession.subscribe((res1) => {
        firebase
          .database()
          .ref(
            "/ReviewAndRating/" +
            tripId +
            "/" +
            res1.firebase_uid,
          )
          .set(activitydata)
          .then(
            (res: any) => {
              this.commonService.dismissLoader();
              resolve(true);
            },
            (error) => {
              this.commonService.showErrorAlert().then((res) => {
                if (res) {
                  this.submitRating(tripId, activitydata);
                } else {
                  reject();
                }
              });
            },
          );
      })
    });
  }
  public tripNotification(tripId: number) {
    // if (this.commonService.sessionData.loginsession) {
    // this.notificationRef = firebase
    //   .database()
    //   .ref(
    //     "/Notification/" +
    //       this.commonService.sessionData.loginsession.firebase_uid +
    //       "/" +
    //       tripId,
    //   );
    // this.notificationRef.on(
    //   "value",
    //   (snapshot) => {
    //     if (snapshot.val() != null) {
    //       this.$tripNotification.next(
    //         Array.from(
    //           Object.keys(snapshot.val()),
    //           (keys) => snapshot.val()[keys],
    //         ),
    //       );
    //     }
    //   },
    //   (error) => {
    //     this.tripNotification(tripId);
    //   },
    // );
    // this.getTripNotification(tripId);
    // }
  }
  public getTripNotification(tripId: number) {
    // this.UnreadNotificationRef = firebase
    //   .database()
    //   .ref(
    //     "/unread_notification/" +
    //       this.commonService.sessionData.loginsession.firebase_uid +
    //       "/" +
    //       tripId,
    //   );
    // this.UnreadNotificationRef.on(
    //   "value",
    //   (snapshot) => {
    //     if (snapshot.val() != null) {
    //       this.$tripUnreadNotification.next(snapshot.val().count);
    //     } else {
    //       this.$tripUnreadNotification.next(null);
    //     }
    //   },
    //   (error) => {
    //     this.tripNotification(tripId);
    //   },
    // );
  }
  public async tripActivityCount(tripid: number) {
    this.authSessionService.$userSession.subscribe(async (res: User) => {
      if (res) {
        const refference = "/Activity/" + res.firebase_uid + "/" + tripid;
        const notificationRef = firebase.database().ref(refference);
        notificationRef.on("value", (snapshot) => {
          if (snapshot.val() != null) {
            this.tripActivityCountData.next(snapshot.val().Count);
          } else {
            this.tripActivityCountData.next(null);
          }
        }
        );
      }
    })
  }
  public async createRatingArr(
    quotesArr,
    Activities: Activity,
    tripId,
    clientDetails?: any,
  ) {
    if (this.authSessionService.userId) {
      return new Promise((resolve, reject) => {
        firebase
          .database()
          .ref(
            "/ReviewAndRating/" +
            tripId +
            "/" +
            this.authSessionService.userId,
          )
          .once("value", (snapshot) => {
            if (snapshot.val() != null) {
              Activities.ratingArr.push(...snapshot.val());
              resolve(true);
            } else {
              Activities.services.push({
                legId: tripId,
                serviceDetails: [],
                taxes: [],
                price: 0,
                status: "pending",
                sector: "How would you rate your trip?",
                legStatus: "Closed",
                tripStatus: "Completed",
                order: 1,
                location: "",
                vendorid: "",
                servicename: "Trip Completed",
                serviceType: "Sector",
                requestType: "",
                logo: "",
                departSecond: "0",
                createSecond: "0",
                timerCount: 0,
              });
              if (clientDetails) {
                this.vendorRatingArr(
                  quotesArr,
                  Activities,
                  tripId,
                  clientDetails,
                );
                resolve(true);
              } else if (quotesArr != undefined) {
                for (const typeService in quotesArr) {
                  if (typeService) {
                    this.clientRating(
                      quotesArr[typeService],
                      Activities,
                      tripId,
                    );
                    resolve(true);
                  }
                }
              } else {
                resolve(false);
              }
            }
          });
      });
    }
  }
  public createClientActivityArr(Activities: Activity, tripData: any) {
    this.tripServices = [];
    if (tripData.GetTripActivity?.status == "In progress") {
      if (tripData.GetTripActivity.srdata.Legs.length > 1) {
        this.checkPreviousStationrquest(tripData.GetTripActivity, Activities);
      }
    }
    this.tripRouteService.LegInformation = tripData.GetTripActivity?.srdata?.Legs;
    this.tripRouteService.RouteDetails = tripData.GetTripActivity;
    for (const legData in tripData?.GetTripActivity?.assignedpaxdetails) {
      if (tripData.GetTripActivity.assignedpaxdetails[legData]) {
        for (const legID in tripData.GetTripActivity.assignedpaxdetails[
          legData
        ]) {
          if (legID) {
            const leg = legID;
            Activities.services.push({
              AircraftType: tripData.GetTripActivity.srdata.Regd,
              legIndex: legData,
              legId: Number(leg),
              serviceDetails: [],
              taxes: [],
              price: 0,
              qoutes: tripData.GetTripActivity.quotes,
              status:
              Activities.legsDetails.filter((item) => {
                return item.legid.indexOf(String(leg)) > -1;
              })[0].Legstage == "Closed"
                ? "confirmed"
                : tripData.GetTripActivity.assignedpaxdetails[legData][leg]
                  .list.length == 0
                  ? "pending"
                  : "confirmed",
              sector:
                tripData.GetTripActivity.assignedpaxdetails[legData][leg]
                  .sector,
              legStatus: Activities.legsDetails.filter((item) => {
                return item.legid.indexOf(String(leg)) > -1;
              })[0].Legstage,
              tripStatus: Activities.tripStatus,
              order: 1,
              location:
                tripData.GetTripActivity.assignedpaxdetails[legData][leg]
                  .sector,
              vendorid: "",
              servicename: "Add Pax",
              category: this.servicesCategory.PAYLOAD,
              serviceType: "Sector",
              requestType: "",
              logo: "",
              departSecond: Activities.legsDetails.filter((item) => {
                return item.legid.indexOf(String(leg)) > -1;
              })[0].departDate,
              createSecond: tripData.GetTripActivity.srdata.DateCreated,
              timerCount: this.getServiceDeliveryDateTime(
                tripData.GetTripActivity.srdata.DateCreated,
                Activities.legsDetails.filter((item) => {
                  return item.legid.indexOf(String(leg)) > -1;
                })[0].departDate,
                tripData.GetTripActivity.assignedpaxdetails[legData][leg]
                  .sector,
                tripData.GetTripActivity.assignedpaxdetails[legData][leg]
                  .sector,
                Activities.legsDetails.filter((item) => {
                  return item.legid.indexOf(String(leg)) > -1;
                })[0].BLKTIME,
                Activities.legsDetails.filter((item) => {
                  return item.legid.indexOf(String(leg)) > -1;
                })[0].Legstage,
                Activities.tripStatus,
                "timer",
                "sector",
              ),
              progressDate: this.getServiceDeliveryDateTime(
                tripData.GetTripActivity.srdata.DateCreated,
                Activities.legsDetails.filter((item) => {
                  return item.legid.indexOf(String(leg)) > -1;
                })[0].departDate,
                tripData.GetTripActivity.assignedpaxdetails[legData][leg]
                  .sector,
                tripData.GetTripActivity.assignedpaxdetails[legData][leg]
                  .sector,
                Activities.legsDetails.filter((item) => {
                  return item.legid.indexOf(String(leg)) > -1;
                })[0].BLKTIME,
                Activities.legsDetails.filter((item) => {
                  return item.legid.indexOf(String(leg)) > -1;
                })[0].Legstage,
                Activities.tripStatus,
                "progressbar",
                "sector",
              ),
              ActualLanding: Activities.legsDetails[0].ActualLanding,
              ActualTakeoff: Activities.legsDetails[0].ActualTakeoff,
            });
          }
        }
      }
    }
    for (const legData in tripData?.GetTripActivity?.assgnedcrewdetails) {
      if (tripData.GetTripActivity.assgnedcrewdetails[legData]) {
        for (const legID in tripData.GetTripActivity.assgnedcrewdetails[
          legData
        ]) {
          if (legID) {
            const leg = legID;
            Activities.services.push({
              AircraftType: tripData.GetTripActivity.srdata.Regd,
              legIndex: legData,
              legId: Number(leg),
              serviceDetails: [],
              taxes: [],
              price: 0,
              qoutes: tripData.GetTripActivity.quotes,
              status:
                tripData.GetTripActivity.assgnedcrewdetails[legData][leg].list
                  .length == 0
                  ? "pending"
                  : "confirmed",
              sector:
                tripData.GetTripActivity.assgnedcrewdetails[legData][leg]
                  .sector,
              legStatus: Activities.legsDetails.filter((item) => {
                return item.legid.indexOf(String(leg)) > -1;
              })[0].Legstage,
              tripStatus: Activities.tripStatus,
              order: 2,
              location:
                tripData.GetTripActivity.assgnedcrewdetails[legData][leg]
                  .sector,
              vendorid: "",
              servicename: "Add Crew",
              category: this.servicesCategory.PAYLOAD,
              serviceType: "Sector",
              requestType: "",
              logo: "",
              createSecond: tripData.GetTripActivity.srdata.DateCreated,
              departSecond: Activities.legsDetails.filter((item) => {
                return item.legid.indexOf(String(leg)) > -1;
              })[0].departDate,
              timerCount: this.getServiceDeliveryDateTime(
                tripData.GetTripActivity.srdata.DateCreated,
                Activities.legsDetails.filter((item) => {
                  return item.legid.indexOf(String(leg)) > -1;
                })[0].departDate,
                tripData.GetTripActivity.assignedpaxdetails[legData][leg]
                  .sector,
                tripData.GetTripActivity.assignedpaxdetails[legData][leg]
                  .sector,
                Activities.legsDetails.filter((item) => {
                  return item.legid.indexOf(String(leg)) > -1;
                })[0].BLKTIME,
                Activities.legsDetails.filter((item) => {
                  return item.legid.indexOf(String(leg)) > -1;
                })[0].Legstage,
                Activities.tripStatus,
                "timer",
                "sector",
              ),
              progressDate: this.getServiceDeliveryDateTime(
                tripData.GetTripActivity.srdata.DateCreated,
                Activities.legsDetails.filter((item) => {
                  return item.legid.indexOf(String(leg)) > -1;
                })[0].departDate,
                tripData.GetTripActivity.assignedpaxdetails[legData][leg]
                  .sector,
                tripData.GetTripActivity.assignedpaxdetails[legData][leg]
                  .sector,
                Activities.legsDetails.filter((item) => {
                  return item.legid.indexOf(String(leg)) > -1;
                })[0].BLKTIME,
                Activities.legsDetails.filter((item) => {
                  return item.legid.indexOf(String(leg)) > -1;
                })[0].Legstage,
                Activities.tripStatus,
                "progressbar",
                "sector",
              ),
              ActualLanding: Activities.legsDetails[0].ActualLanding,
              ActualTakeoff: Activities.legsDetails[0].ActualTakeoff,
            });
          }
        }
      }
    }
  // Update Flight plan In operator side
    for (const legData of Object.keys(tripData?.GetTripActivity?.srdata?.Legs)) {
      if(tripData.GetTripActivity.srdata.Legs[legData].Legstage =='Inprogressstarting' || tripData.GetTripActivity.srdata.Legs[legData].Legstage == 'Inprogressfinishing' || tripData.GetTripActivity.srdata.Legs[legData].Legstage =='Inprogressfinished'){
      const leg = tripData.GetTripActivity.srdata.Legs[legData].legid;
      if (leg) {
      Activities.services.push({
        AircraftType: tripData.GetTripActivity.srdata.Regd,
        legIndex: legData,
        legId: Number(leg),
        serviceDetails: [],
        taxes: [],
        price: 0,
        qoutes: tripData.GetTripActivity.quotes,
        status: tripData.GetTripActivity.srdata.Legs[legData].Legstage == 'Closed' ? 'confirmed': 'pending',
        sector:
        tripData.GetTripActivity.srdata.Legs[legData].sector,
        legStatus: Activities.legsDetails.filter((item) => {
          return item.legid.indexOf(String(leg)) > -1;
        })[0].Legstage,
        tripStatus: Activities.tripStatus,
        order: 2.6,
        location:
        tripData.GetTripActivity.srdata.Legs[legData].sector,
        vendorid: "",
        servicename: "Update Flight Times",
        category: this.servicesCategory.PAYLOAD,
        serviceType: "Sector",
        requestType: "",
        logo: "",
        createSecond: tripData.GetTripActivity.srdata.DateCreated,
        departSecond: Activities.legsDetails.filter((item) => {
          return item.legid.indexOf(String(leg)) > -1;
        })[0].departDate,
        timerCount: this.getServiceDeliveryDateTime(
          tripData.GetTripActivity.srdata.DateCreated,
          Activities.legsDetails.filter((item) => {
            return item.legid.indexOf(String(leg)) > -1;
          })[0].departDate,
         tripData.GetTripActivity.srdata.Legs[legData]
            .sector,
         tripData.GetTripActivity.srdata.Legs[legData]
            .sector,
          Activities.legsDetails.filter((item) => {
            return item.legid.indexOf(String(leg)) > -1;
          })[0].BLKTIME,
          Activities.legsDetails.filter((item) => {
            return item.legid.indexOf(String(leg)) > -1;
          })[0].Legstage,
          Activities.tripStatus,
          "timer",
          "sector",
        ),
        progressDate: this.getServiceDeliveryDateTime(
          tripData.GetTripActivity.srdata.DateCreated,
          Activities.legsDetails.filter((item) => {
            return item.legid.indexOf(String(leg)) > -1;
          })[0].departDate,
          tripData.GetTripActivity.srdata.Legs[legData]
            .sector,
          tripData.GetTripActivity.srdata.Legs[legData]
            .sector,
          Activities.legsDetails.filter((item) => {
            return item.legid.indexOf(String(leg)) > -1;
          })[0].BLKTIME,
          Activities.legsDetails.filter((item) => {
            return item.legid.indexOf(String(leg)) > -1;
          })[0].Legstage,
          Activities.tripStatus,
          "progressbar",
          "sector",
        ),
        ActualLanding: Activities.legsDetails[0].ActualLanding,
        ActualTakeoff: Activities.legsDetails[0].ActualTakeoff,
      });
      }
  }
    }

    for (const typeOfservice in tripData.GetTripActivity.quotes) {
      if (typeOfservice) {
        for (const legs in tripData.GetTripActivity.quotes[typeOfservice]) {
          if (typeOfservice) {
            for (const service in tripData.GetTripActivity.quotes[
              typeOfservice
            ][legs]) {
              if (service) {
                this.tripServices =
                  tripData.GetTripActivity.quotes[typeOfservice][legs];
                tripData.GetTripActivity.quotes[typeOfservice][legs][
                  service
                ].forEach((leg, index) => {
                  const legByLegId = Activities.legsDetails.filter((item) => {
                    return item.legid.indexOf(String(leg.legid)) > -1;
                  })[0];
                  const getServiceDeliveryDateTime = this.getServiceDeliveryDateTime(
                    tripData.GetTripActivity.srdata.DateCreated,
                    legByLegId.departDate,
                    leg.sector,
                    leg.location ? leg.location : leg.sector,
                    legByLegId.BLKTIME,
                    legByLegId.Legstage,
                    Activities.tripStatus,
                    "progressbar",
                    typeOfservice,
                  );
                  if (service != "handling" && service != "airpermissions") {
                    const tempLegDetails = Activities.legsDetails.filter(
                      (item) => {
                        return item.legid == leg.legid;
                      },
                    );
                    const legStatus: string = tempLegDetails[0].Legstage;
                    if (
                      service == "crew briefing" &&
                      leg.UploadedArray &&
                      leg.UploadedArray.length > 0
                    ) {
                      if (
                        leg.UploadedArray.filter((element) => {
                          return element.Selected == "1";
                        }).length <= 0
                      ) {
                        this.createActivityForFlightPlanSelect(
                          Activities,
                          leg,
                          tripData.GetTripActivity,
                          legs,
                        );
                      }
                    }
                    if (
                      service == "fuel" &&
                      leg.fuelDetails &&
                      leg.fuelDetails.length > 0 &&
                      legStatus != "Inprogressfinishing" &&
                      legStatus != "Inprogressfinished" &&
                      legStatus != "Closed"
                    ) {
                      this.CreateActionForFuelOn(
                        Activities,
                        leg,
                        tripData.GetTripActivity,
                        legs,
                      );
                    }
                    if (
                      legStatus == "Inprogressstarting" ||
                      legStatus == "Inprogressfinishing" ||
                      legStatus == "Inprogressfinished" ||
                      legStatus == "Closed"
                    ) {
                      if (leg.status == "inhouse") {
                        if (service == "fuel") {
                          this.getFlightPlanSelectedData();
                          this.checkInprogressActivity(
                            Activities,
                            tripData.GetTripActivity,
                            service,
                            leg,
                            leg.legid,
                            legs,
                          );
                          Activities.services.push({
                            SRID: tripData.GetTripActivity.srid,
                            AircraftType: tripData.GetTripActivity.srdata.Regd,
                            legIndex: legs,
                            legId: Number(leg.legid),
                            serviceDetails: leg.fuelDetailsInprogress,
                            subservicedetails: [],
                            taxes: leg.taxes,
                            price: leg.price,
                            qoutes: tripData.GetTripActivity.quotes,
                            status:
                              leg.fuelDetailsInprogress.length > 0
                                ? "confirmed"
                                : "pending",
                            flightPlanningUploaded: false,
                            sector: leg.sector,
                            legStatus: Activities.legsDetails.filter((item) => {
                              return item.legid.indexOf(String(leg.legid)) > -1;
                            })[0].Legstage,
                            tripStatus: Activities.tripStatus,
                            order: 2.8,
                            location: leg.location ? leg.location : leg.sector,
                            vendorid: leg.vendorid,
                            servicename: service == "fuel" ? "Fuel" : "Invalid",
                            serviceType: typeOfservice,
                            category:
                              service == "fuel"
                                ? this.servicesCategory.FUEL
                                : service == "permits"
                                  ? this.servicesCategory.OVERFLIGHT_PERMITS
                                  : this.servicesCategory.FLIGHT_PLANNING,
                            selectFlightPlan:
                              leg.FlightPlanSelected != undefined
                                ? leg.FlightPlanSelected
                                : this.selectFlightPlan,
                            requestType: "self",
                            logo: "",
                            fuelDetailsInprogress: leg.fuelDetailsInprogress,
                            fuelDetails: leg.fuelDetails,
                            fuelIntoplanAgentDetails:
                              leg.fuelIntoplanAgentDetails,
                            departSecond: getServiceDeliveryDateTime,
                            createSecond:
                              tripData.GetTripActivity.srdata.DateCreated,
                            timerCount: this.getServiceDeliveryDateTime(
                              tripData.GetTripActivity.srdata.DateCreated,
                              legByLegId.departDate,
                              leg.sector,
                              leg.location ? leg.location : leg.sector,
                              legByLegId.BLKTIME,
                              legByLegId.Legstage,
                              Activities.tripStatus,
                              "timer",
                              typeOfservice,
                            ),
                            progressDate: getServiceDeliveryDateTime,
                            ActualLanding: Activities.legsDetails[0].ActualLanding,
                          ActualTakeoff: Activities.legsDetails[0].ActualTakeoff,
                          });
                        } else {
                          if (
                            service == "crew briefing" &&
                            leg.UploadedArray.length > 0
                          ) {
                            leg.UploadedArray.forEach((item) => {
                              if (item.Selected == "1") {
                                this.cbpFinalizeAction(
                                  Activities,
                                  leg,
                                  tripData.GetTripActivity,
                                  "self",
                                  legs,
                                );
                              }
                            });
                          }
                        }
                      }
                    } else {
                      if (leg.status == "inhouse" || leg.SelfService == true) {
                        this.getFlightPlanSelectedData();
                        if (
                          service == "crew briefing" &&
                          leg.UploadedArray.length > 0
                        ) {
                          const value =
                            leg.UploadedArray.filter((item) => {
                              return item.Filed != "true" && item.Selected == 1;
                            }).length > 0
                              ? this.fileFlightPlan(
                                Activities,
                                leg,
                                tripData.GetTripActivity,
                                "self",
                                legs,
                              )
                              : leg.UploadedArray.filter((item) => {
                                return (
                                  item.Filed == "true" &&
                                  item.Finalize != 1 &&
                                  item.Selected == "1"
                                );
                              }).length > 0
                                ? this.cbpFinalizeAction(
                                  Activities,
                                  leg,
                                  tripData.GetTripActivity,
                                  "self",
                                  legs,
                                )
                                : " ";
                        }
                        Activities.services.push({
                          SRID: tripData.GetTripActivity.srid,
                          AircraftType: tripData.GetTripActivity.srdata.Regd,
                          legIndex: legs,
                          legId: Number(leg.legid),
                          serviceDetails: leg.fuelDetails,
                          subservicedetails:
                            service == "crew briefing"
                              ? leg.UploadedArray
                              : service == "permits"
                                ? leg.subcharges
                                : [],
                          taxes: leg.taxes,
                          price: leg.price,
                          qoutes: tripData.GetTripActivity.quotes,
                          status:
                            service == "permits"
                              ? leg.subcharges.filter((item) => {
                                return item.PermitNumber == "";
                              }).length == 0
                                ? "confirmed"
                                : "pending"
                              : service == "crew briefing"
                                ? leg.UploadedArray.length > 0
                                  ? "confirmed"
                                  : "pending"
                                : leg.fuelDetails.length > 0
                                  ? "confirmed"
                                  : "pending",
                          flightPlanningUploaded: tripData.GetTripActivity
                            .srcbparray[leg.legid]
                            ? tripData.GetTripActivity.srcbparray[leg.legid]
                              .length > 0
                              ? true
                              : false
                            : false,
                          sector: leg.sector,
                          legStatus: legByLegId.Legstage,
                          tripStatus: Activities.tripStatus,
                          order:
                            service == "permits"
                              ? 4
                              : service == "crew briefing"
                                ? 3
                                : 5,
                          location: leg.location ? leg.location : leg.sector,
                          vendorid: leg.vendorid,
                          servicename:
                            service == "fuel"
                              ? "Fuel"
                              : service == "permits"
                                ? "Overflight Permits"
                                : "Flight Planning",
                          category:
                            service == "fuel"
                              ? this.servicesCategory.FUEL
                              : service == "permits"
                                ? this.servicesCategory.OVERFLIGHT_PERMITS
                                : this.servicesCategory.FLIGHT_PLANNING,
                          serviceType: typeOfservice,
                          requestType: "self",
                          logo: "",
                          selectFlightPlan:
                            leg.FlightPlanSelected != undefined
                              ? leg.FlightPlanSelected
                              : this.selectFlightPlan,
                          fuelDetailsInprogress:
                            service == "fuel" ? leg.fuelDetailsInprogress : [],
                          fuelDetails: service == "fuel" ? leg.fuelDetails : [],
                          departSecond: getServiceDeliveryDateTime,
                          createSecond:
                            tripData.GetTripActivity.srdata.DateCreated,
                          timerCount: this.getServiceDeliveryDateTime(
                            tripData.GetTripActivity.srdata.DateCreated,
                            legByLegId.departDate,
                            leg.sector,
                            leg.location ? leg.location : leg.sector,
                            legByLegId.BLKTIME,
                            legByLegId.Legstage,
                            Activities.tripStatus,
                            "timer",
                            typeOfservice,
                          ),
                          progressDate: getServiceDeliveryDateTime,
                          fuelIntoplanAgentDetails:
                            leg.fuelIntoplanAgentDetails,
                          ActualLanding: Activities.legsDetails[0].ActualLanding,
                          ActualTakeoff: Activities.legsDetails[0].ActualTakeoff,
                          crewDetails: tripData.GetTripActivity?.assgnedcrewdetails[index]
                        });
                      }
                    }
                  } else {
                    const tempLegDetails = Activities.legsDetails.filter(
                      (item) => {
                        return item.legid == leg.legid;
                      },
                    );
                    const legStatus: string = tempLegDetails[0].Legstage;
                    let onTimeOfService: string;
                    if (leg.sector.substring(0, 4) == leg.location) {
                      onTimeOfService = "Dep ";
                    } else {
                      onTimeOfService = "Arr ";
                    }
                    if (
                      legStatus == "Inprogressstarting" ||
                      legStatus == "Inprogressfinishing" ||
                      legStatus == "Inprogressfinished" ||
                      legStatus == "Closed"
                    ) {
                      if (leg.status == "inhouse") {
                        this.checkInprogressActivity(
                          Activities,
                          tripData.GetTripActivity,
                          service,
                          leg,
                          leg.legid,
                          legs,
                        );
                        Activities.services.push({
                          SRID: tripData.GetTripActivity.srid,
                          AircraftType: tripData.GetTripActivity.srdata.Regd,
                          legIndex: legs,
                          legId: Number(leg.legid),
                          serviceDetails: leg.subcharges,
                          taxes: leg.taxes,
                          price: leg.price,
                          qoutes: tripData.GetTripActivity.quotes,
                          status:
                            service == "handling"
                              ? leg.InprogressActivity == "false"
                                ? "pending"
                                : "confirmed"
                              : leg.InprogressActivity == "false"
                                ? "pending"
                                : "confirmed",
                          sector: leg.sector,
                          legStatus: legByLegId.Legstage,
                          tripStatus: Activities.tripStatus,
                          order:
                            service == "handling"
                              ? onTimeOfService == "Dep "
                                ? 6.1
                                : 6.2
                              : onTimeOfService == "Arr "
                                ? 7.2
                                : 7.1,
                          location: leg.location ? leg.location : leg.sector,
                          vendorid: leg.vendorid,
                          servicename:
                            service == "handling"
                              ? onTimeOfService + "Handling"
                              : onTimeOfService + "Apt Fees & Permits",
                          category:
                            service == "handling"
                              ? this.servicesCategory.HANDLING
                              : this.servicesCategory.AIRPORT_CHARGES,
                          serviceType: typeOfservice,
                          requestType: "self",
                          logo: "",
                          subservicedetails: leg.subservicedetails,
                          ArrDeptStatus:
                            leg.ArrDeptStatus == "inprogress" ? true : false,
                          fuelDetails:
                            service == "handling" ? leg.fuelDetails : [],
                          airlandingandslotpermits:
                            service != "handling"
                              ? leg.airlandingandslotpermits
                              : [],
                          departSecond: getServiceDeliveryDateTime,
                          createSecond:
                            tripData.GetTripActivity.srdata.DateCreated,
                          crewDetails:
                            tripData.GetTripActivity.assgnedcrewdetails !=
                              undefined &&
                              tripData.GetTripActivity.assgnedcrewdetails[legs][
                                leg.legid
                              ].list.length > 0
                              ? tripData.GetTripActivity.assgnedcrewdetails[
                                legs
                              ][leg.legid].list.map((crewdata: any) => {
                                return {
                                  crewname: crewdata.ClientCrewName,
                                  crewid: crewdata.ClientCrewID,
                                };
                              })
                              : [],
                          timerCount: this.getServiceDeliveryDateTime(
                            tripData.GetTripActivity.srdata.DateCreated,
                            legByLegId.departDate,
                            leg.sector,
                            leg.location ? leg.location : leg.sector,
                            legByLegId.BLKTIME,
                            legByLegId.Legstage,
                            Activities.tripStatus,
                            "timer",
                            typeOfservice,
                          ),
                          progressDate: getServiceDeliveryDateTime,
                          ActualLanding: Activities.legsDetails[0].ActualLanding,
                          ActualTakeoff: Activities.legsDetails[0].ActualTakeoff
                        });
                      }
                    } else {
                      if (
                        leg.status == "inhouse" ||
                        (leg.status != "inhouse" &&
                          service == "handling" &&
                          leg.status != "pending")
                      ) {
                        Activities.services.push({
                          SRID: tripData.GetTripActivity.srid,
                          AircraftType: tripData.GetTripActivity.srdata.Regd,
                          legIndex: legs,
                          legId: Number(leg.legid),
                          serviceDetails: leg.subcharges,
                          taxes: leg.taxes,
                          price: leg.price,
                          qoutes: tripData.GetTripActivity.quotes,
                          status:
                            leg.status == 'declined' ? service == "handling" &&
                              leg.status != "inhouse" && leg.clientSelectedService == "false"
                              ? "declined" : service == "handling"
                                ? leg.UpcomingActivity == "false"
                                  ? "pending"
                                  : "confirmed"
                                : leg.UpcomingActivity == "false"
                                  ? "pending"
                                  : "confirmed" :

                              service == "handling" &&
                                leg.status != "inhouse" &&
                                leg.clientSelectedService == "false"
                                ? "pending"
                                : service == "handling"
                                  ? leg.UpcomingActivity == "false"
                                    ? "pending"
                                    : "confirmed"
                                  : leg.UpcomingActivity == "false"
                                    ? "pending"
                                    : "confirmed",
                          sector: leg.sector,
                          legStatus: legByLegId.Legstage,
                          tripStatus: Activities.tripStatus,
                          order:
                            service == "handling"
                              ? onTimeOfService == "Dep "
                                ? 6.1
                                : 6.2
                              : onTimeOfService == "Arr "
                                ? 7.2
                                : 7.1,
                          location: leg.location ? leg.location : leg.sector,
                          vendorid: leg.vendorid,
                          servicename:
                            service == "handling" && leg.status != "inhouse"
                              ? ACTION_ITEMS.Select_Handling_Services
                              : service == "handling"
                                ? onTimeOfService + "Handling"
                                : onTimeOfService + "Apt Fees & Permits",
                          category:
                            service == "handling"
                              ? this.servicesCategory.HANDLING
                              : this.servicesCategory.AIRPORT_CHARGES,
                          serviceType: typeOfservice,
                          requestType: "self",
                          logo: "",
                          subservicedetails: leg.subservicedetails,
                          airlandingandslotpermits:
                            service != "handling"
                              ? leg.airlandingandslotpermits
                              : [],
                          departSecond: getServiceDeliveryDateTime,
                          createSecond:
                            tripData.GetTripActivity.srdata.DateCreated,
                          crewDetails:
                            tripData.GetTripActivity.assgnedcrewdetails !=
                              undefined &&
                              tripData.GetTripActivity.assgnedcrewdetails[legs][
                                leg.legid
                              ].list.length > 0
                              ? tripData.GetTripActivity.assgnedcrewdetails[
                                legs
                              ][leg.legid].list.map((crewdata: any) => {
                                return {
                                  crewname: crewdata.ClientCrewName,
                                  crewid: crewdata.ClientCrewID,
                                };
                              })
                              : [],
                          ArrDeptStatus:
                            leg.ArrDeptStatus == "inprogress" ? true : false,
                          commonLeg:
                            service == "handling" &&
                              tripData.GetTripActivity.quotes[typeOfservice] &&
                              tripData.GetTripActivity.quotes[typeOfservice][
                              index
                              ]
                              ? this.both_arrival_departure(
                                tripData.GetTripActivity.srdata.Legs,
                                tripData.GetTripActivity.quotes[
                                typeOfservice
                                ][index][service],
                                leg.SRSecID,
                                leg,
                                index,
                              )
                              : service == "airpermissions" &&
                                tripData.GetTripActivity.quotes[
                                typeOfservice
                                ] &&
                                tripData.GetTripActivity.quotes[typeOfservice][
                                index
                                ]
                                ? this.both_arrival_departure(
                                  tripData.GetTripActivity.srdata.Legs,
                                  tripData.GetTripActivity.quotes[
                                  typeOfservice
                                  ][index][service],
                                  leg.SRSecID,
                                  leg,
                                  index,
                                )
                                : false,
                          timerCount: this.getServiceDeliveryDateTime(
                            tripData.GetTripActivity.srdata.DateCreated,
                            legByLegId.departDate,
                            leg.sector,
                            leg.location ? leg.location : leg.sector,
                            legByLegId.BLKTIME,
                            legByLegId.Legstage,
                            Activities.tripStatus,
                            "timer",
                            typeOfservice,
                          ),
                          progressDate: getServiceDeliveryDateTime,
                          previousLegId: this.prevLegandSector(
                            Number(leg.legid),
                            typeOfservice,
                            service,
                            tripData.GetTripActivity,
                            leg.location,
                            leg.sector,
                            "led_id",
                          ),
                          previousSector: this.prevLegandSector(
                            Number(leg.legid),
                            typeOfservice,
                            service,
                            tripData.GetTripActivity,
                            leg.location,
                            leg.sector,
                            "sector",
                          ),
                          previousLegStatus: this.prevLegandSector(
                            Number(leg.legid),
                            typeOfservice,
                            service,
                            tripData.GetTripActivity,
                            leg.location,
                            leg.sector,
                            "status",
                          ),
                          ActualLanding: Activities.legsDetails[0].ActualLanding,
                          ActualTakeoff: Activities.legsDetails[0].ActualTakeoff,
                        });
                      }
                    }
                  }
                });
              }
            }
          }
        }
      }
    }
    return Activities;
  }
  public prevLegandSector(
    legid: number,
    typeOfService: string,
    serviceName: string,
    tripDetails: any,
    station: string,
    sector: string,
    type: string
  ): any {
    const index = tripDetails.srdata.Legs.map((e) => {
      return e.legid;
    }).indexOf(String(legid));
    if (
      index > 0 &&
      station == sector.substring(0, 4) &&
      tripDetails.srdata.Legs.length > 1
    ) {
      if (type == 'led_id') {
        return legid - 1;
      } else if (type == 'sector') {
        return tripDetails.quotes[typeOfService][index - 1] &&
          tripDetails.quotes[typeOfService][index - 1][serviceName]
          ? tripDetails.quotes[typeOfService][index - 1][serviceName][0].Sector
          : false;
      } else if (type == 'status') {
        const legStage = tripDetails.srdata.Legs.filter((item) => {
          return item.legid == legid - 1;
        });
        if (
          legStage.length > 0 &&
          (legStage[0].Legstage == 'Inprogressstarting' ||
            legStage[0].Legstage == 'Inprogressfinishing' ||
            legStage[0].Legstage == 'Inprogressfinished')
        ) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return null;
    }
  }

  public checkInprogressActivity(
    Activities: Activity,
    tripData: any,
    serviceName: string,
    legsDetails: any,
    legId: any,
    legIndex?: any
  ) {
    if (tripData.inprogressfullfillmentarray[legIndex][legId]) {
      if (
        tripData.inprogressfullfillmentarray[legIndex][legId][
        legsDetails.Sector
        ]
      ) {
        if (
          tripData.inprogressfullfillmentarray[legIndex][legId][
          legsDetails.Sector
          ][legsDetails.LocationName]
        ) {
          switch (serviceName) {
            case 'handling':
              this.makeHandlingInprogressActivity(
                legsDetails,
                Activities,
                tripData,
                tripData.inprogressfullfillmentarray[legIndex][legId][
                legsDetails.Sector
                ][legsDetails.LocationName],
                legIndex
              );
              break;
            case 'airpermissions':
              this.makeAirportChragesInprogressActivity(
                legsDetails,
                Activities,
                tripData,
                tripData.inprogressfullfillmentarray[legIndex][legId][
                legsDetails.Sector
                ][legsDetails.LocationName],
                legIndex
              );
              break;
            case 'fuel':
              this.makeFuelInprogressActivity(
                legsDetails,
                Activities,
                tripData,
                tripData.inprogressfullfillmentarray[legIndex][legId][
                legsDetails.Sector
                ][legsDetails.LocationName],
                legIndex
              );
              break;
          }
        }
      }
    }
  }
  public makeHandlingInprogressActivity(
    serviceDetails: any,
    Activities: Activity,
    tripData: any,
    activityToshow: any,
    legIndex?: any
  ) {
    for (const key in activityToshow) {
      if (key) {
        const flagArr = [
          'handlingdelivered',
          'paxclearedciq',
          'departureghservicesformsignoff',
          'arrivalghservicesformsignoff',
          'chocksoff',
          'takeoff',
          'reviewpaxmanifest',
          'reviewgeneraldeclaration',
          'EnterBaynumber',
          'aircrafthaslanded',
          'chockson',
          'passengershavecompletedciq',
        ];
        if (flagArr.indexOf(key) != -1) {
          let message = '';
          let category = '';
          let order = null;
          switch (key) {
            case 'handlingdelivered':
              category = this.servicesCategory.HANDLING;
              let onTimeOfService: string;
              if (
                serviceDetails.Sector.substring(0, 4) ==
                serviceDetails.LocationName
              ) {
                onTimeOfService = 'Dep ';
              } else {
                onTimeOfService = 'Arr ';
              }
              message = onTimeOfService + 'Handling Delivered';
              order = 6.8;
              break;
            case 'paxclearedciq':
              category = this.servicesCategory.HANDLING;
              message = 'Pax Arrived';
              order = 10;
              break;
            case 'departureghservicesformsignoff':
              category = this.servicesCategory.HANDLING;
              message = 'Dep GH Services Form Signoff';
              order = 6.5;
              break;
            case 'chocksoff':
              category = this.servicesCategory.HANDLING;
              message = 'Chocks Off';
              order = 11;
              break;
            case 'takeoff':
              category = this.servicesCategory.HANDLING;
              message = 'Take Off';
              order = 12;
              break;
            case 'reviewpaxmanifest':
              category = this.servicesCategory.HANDLING;
              message = 'Review Pax Manifest';
              order = 13;
              break;
            case 'reviewgeneraldeclaration':
              category = this.servicesCategory.HANDLING;
              message = 'Review General Declaration';
              order = 14;
              break;
            case 'EnterBaynumber':
              category = this.servicesCategory.HANDLING;
              message = 'Bay Number';
              order = 2.5;
              break;
            case 'aircrafthaslanded':
              category = this.servicesCategory.HANDLING;
              message = 'Landed';
              order = 15;
              break;
            case 'chockson':
              category = this.servicesCategory.HANDLING;
              message = 'Chocks on';
              order = 16;
              break;
            case 'passengershavecompletedciq':
              category = this.servicesCategory.HANDLING;
              message = this.servicesCategory.PAXDEPARTED;
              order = 17;
              break;
            case 'arrivalghservicesformsignoff':
              category = this.servicesCategory.HANDLING;
              message = 'Arr GH Services Form Signoff';
              order =
                Activities.legsDetails[Activities.legsDetails.length - 1]
                  .Legstage == Status.Inprogressstarting ||
                  Activities.legsDetails[Activities.legsDetails.length - 1]
                    .Legstage == Status.Inprogressfinishing ||
                  Activities.legsDetails[Activities.legsDetails.length - 1]
                    .Legstage == Status.Inprogressfinished
                  ? 6.5
                  : 18;
              break;
          }
          Activities.services.push({
            SRID: serviceDetails.SRID,
            legIndex,
            SRStnID: serviceDetails.SRStnID,
            AircraftType: tripData.srdata.Regd,
            FlowID: serviceDetails.FlowID,
            legId: Number(serviceDetails.SRSecID),
            serviceDetails: serviceDetails.ratebreakarray,
            sector: serviceDetails.Sector,
            price: serviceDetails.Price,
            tripStatus: Activities.tripStatus,
            airlandingandslotpermits:
              serviceDetails == 'airpermissions'
                ? serviceDetails.airlandingandslotpermits
                : [],
            status: activityToshow[key] ? 'confirmed' : 'pending',
            vendorid: serviceDetails.VendorID,
            location: serviceDetails.LocationName
              ? serviceDetails.LocationName
              : serviceDetails.Sector,
            servicename: message,
            category,
            fuelDetails: serviceDetails.fuelDetails
              ? serviceDetails.fuelDetails
              : [],
            serviceType: 'station',
            requestType: 'Operator',
            order,
            logo: serviceDetails.logo,
            subservicedetails: serviceDetails.subservicedetails,
            fuelIntoplanAgentDetails: serviceDetails.fuelIntoplanAgentDetails,
            departSecond: Activities.legsDetails.filter((item) => {
              return item.legid.indexOf(String(serviceDetails.SRSecID)) > -1;
            })[0].departDate,
            createSecond: tripData.srdata.DateCreated,
            timerCount: this.getServiceDeliveryDateTime(
              tripData.srdata.DateCreated,
              Activities.legsDetails.filter((item) => {
                return item.legid.indexOf(String(serviceDetails.SRSecID)) > -1;
              })[0].departDate,
              serviceDetails.Sector,
              serviceDetails.LocationName
                ? serviceDetails.LocationName
                : serviceDetails.Sector,
              Activities.legsDetails.filter((item) => {
                return item.legid.indexOf(String(serviceDetails.SRSecID)) > -1;
              })[0].BLKTIME,
              Activities.legsDetails.filter((item) => {
                return item.legid.indexOf(String(serviceDetails.SRSecID)) > -1;
              })[0].Legstage,
              Activities.tripStatus,
              'timer',
              'station'
            ),
            progressDate: this.getServiceDeliveryDateTime(
              tripData.srdata.DateCreated,
              Activities.legsDetails.filter((item) => {
                return item.legid.indexOf(String(serviceDetails.SRSecID)) > -1;
              })[0].departDate,
              serviceDetails.Sector,
              serviceDetails.LocationName
                ? serviceDetails.LocationName
                : serviceDetails.Sector,
              Activities.legsDetails.filter((item) => {
                return item.legid.indexOf(String(serviceDetails.SRSecID)) > -1;
              })[0].BLKTIME,
              Activities.legsDetails.filter((item) => {
                return item.legid.indexOf(String(serviceDetails.SRSecID)) > -1;
              })[0].Legstage,
              Activities.tripStatus,
              'progressbar',
              'station'
            ),
            ActualLanding: Activities.legsDetails[0].ActualLanding,
            ActualTakeoff: Activities.legsDetails[0].ActualTakeoff,
            legStatus: Activities.legsDetails.filter((item) => {
              return item.legid.indexOf(String(serviceDetails.SRSecID)) > -1;
            })[0].Legstage,
            instantActivity: true,
            crewDetails:
              tripData.assgnedcrewdetails[legIndex][serviceDetails.SRSecID].list
                .length > 0
                ? tripData.assgnedcrewdetails[legIndex][
                  serviceDetails.SRSecID
                ].list.map((crewdata: any) => {
                  return {
                    crewname: crewdata.ClientCrewName,
                    crewid: crewdata.ClientCrewID,
                  };
                })
                : [],
          });
        }
      }
    }
  }
  public makeAirportChragesInprogressActivity(
    serviceDetails: any,
    Activities: Activity,
    tripData: any,
    activityToshow: any,
    legIndex?: any
  ) {
    for (const key in activityToshow) {
      if (key) {
        let message = '';
        let category = '';
        let order = null;
        const statusArray = [
          'departureairportchargesdelivered',
          'arrivalairportchargesdelivered',
        ];
        if (statusArray.includes(key)) {
          let onTimeOfService: string;
          if (
            serviceDetails.Sector.substring(0, 4) == serviceDetails.LocationName
          ) {
            onTimeOfService = 'Dep ';
          } else {
            onTimeOfService = 'Arr ';
          }
          switch (key) {
            case 'departureairportchargesdelivered':
              category = this.servicesCategory.AIRPORT_CHARGES;
              message = onTimeOfService + 'Airport Charges Delivered';
              order = 10.5;
              break;
            case 'arrivalairportchargesdelivered':
              category = this.servicesCategory.AIRPORT_CHARGES;
              message = onTimeOfService + 'Airport Charges Delivered';
              order = 7.5;
              break;
          }
          Activities.services.push({
            SRID: serviceDetails.SRID,
            legIndex,
            SRStnID: serviceDetails.SRStnID,
            FlowID: serviceDetails.FlowID,
            legId: Number(serviceDetails.SRSecID),
            serviceDetails: serviceDetails.ratebreakarray,
            sector: serviceDetails.Sector,
            price: serviceDetails.Price,
            tripStatus: Activities.tripStatus,
            airlandingandslotpermits:
              serviceDetails == 'airpermissions'
                ? serviceDetails.airlandingandslotpermits
                : [],
            status: activityToshow[key] ? 'confirmed' : 'pending',
            vendorid: serviceDetails.VendorID,
            location: serviceDetails.LocationName
              ? serviceDetails.LocationName
              : serviceDetails.Sector,
            servicename: message,
            serviceType: 'station',
            requestType: 'Operator',
            order,
            category,
            logo: serviceDetails.logo,
            subservicedetails: serviceDetails.subservicedetails,
            departSecond: Activities.legsDetails.filter((item) => {
              return item.legid.indexOf(String(serviceDetails.SRSecID)) > -1;
            })[0].departDate,
            createSecond: tripData.srdata.DateCreated,
            timerCount: this.getServiceDeliveryDateTime(
              tripData.srdata.DateCreated,
              Activities.legsDetails.filter((item) => {
                return item.legid.indexOf(String(serviceDetails.SRSecID)) > -1;
              })[0].departDate,
              serviceDetails.Sector,
              serviceDetails.LocationName
                ? serviceDetails.LocationName
                : serviceDetails.Sector,
              Activities.legsDetails.filter((item) => {
                return item.legid.indexOf(String(serviceDetails.SRSecID)) > -1;
              })[0].BLKTIME,
              Activities.legsDetails.filter((item) => {
                return item.legid.indexOf(String(serviceDetails.SRSecID)) > -1;
              })[0].Legstage,
              Activities.tripStatus,
              'timer',
              'station'
            ),
            progressDate: this.getServiceDeliveryDateTime(
              tripData.srdata.DateCreated,
              Activities.legsDetails.filter((item) => {
                return item.legid.indexOf(String(serviceDetails.SRSecID)) > -1;
              })[0].departDate,
              serviceDetails.Sector,
              serviceDetails.LocationName
                ? serviceDetails.LocationName
                : serviceDetails.Sector,
              Activities.legsDetails.filter((item) => {
                return item.legid.indexOf(String(serviceDetails.SRSecID)) > -1;
              })[0].BLKTIME,
              Activities.legsDetails.filter((item) => {
                return item.legid.indexOf(String(serviceDetails.SRSecID)) > -1;
              })[0].Legstage,
              Activities.tripStatus,
              'progressbar',
              'station'
            ),
            ActualLanding: Activities?.legsDetails[0]?.ActualLanding,
            ActualTakeoff: Activities?.legsDetails[0]?.ActualTakeoff,
            legStatus: Activities.legsDetails.filter((item) => {
              return item.legid.indexOf(String(serviceDetails.SRSecID)) > -1;
            })[0].Legstage,
            instantActivity: true,
            crewDetails:
              tripData.assgnedcrewdetails[legIndex][serviceDetails.SRSecID].list
                .length > 0
                ? tripData.assgnedcrewdetails[legIndex][
                  serviceDetails.SRSecID
                ].list.map((crewdata: any) => {
                  return {
                    crewname: crewdata.ClientCrewName,
                    crewid: crewdata.ClientCrewID,
                  };
                })
                : [],
          });
        }
      }
    }
  }
  public makeFuelInprogressActivity(
    serviceDetails: any,
    Activities: Activity,
    tripData: any,
    activityToshow: any,
    legIndex
  ) {
    for (const key in activityToshow) {
      if (key) {
        let message = '';
        let category = '';
        let order = null;
        if (key == 'fueldelivered' || key == 'fdnsignoff') {
          switch (key) {
            case 'fueldelivered':
              category = this.servicesCategory.FUEL;
              message = 'Fuel Delivered';
              order = 4;
              break;
            case 'fdnsignoff':
              category = this.servicesCategory.FUEL;
              message = 'FDN Signoff';
              order = 3;
              break;
          }
          Activities.services.push({
            SRID: serviceDetails.SRID,
            SRStnID: serviceDetails.SRStnID,
            legIndex,
            FlowID: serviceDetails.FlowID,
            legId: Number(serviceDetails.SRSecID),
            serviceDetails: serviceDetails.ratebreakarray,
            sector: serviceDetails.Sector,
            price: serviceDetails.Price,
            tripStatus: Activities.tripStatus,
            airlandingandslotpermits:
              serviceDetails == 'airpermissions'
                ? serviceDetails.airlandingandslotpermits
                : [],
            status: activityToshow[key] ? 'confirmed' : 'pending',
            vendorid: serviceDetails.VendorID,
            location: serviceDetails.LocationName
              ? serviceDetails.LocationName
              : serviceDetails.Sector,
            servicename: message,
            serviceType: 'station',
            requestType: 'Operator',
            order,
            category,
            logo: serviceDetails.logo,
            subservicedetails: serviceDetails.fuelsubcharges
              ? serviceDetails.fuelsubcharges
              : [],
            departSecond: Activities.legsDetails.filter((item) => {
              return item.legid.indexOf(String(serviceDetails.SRSecID)) > -1;
            })[0].departDate,
            createSecond: tripData.srdata.DateCreated,
            timerCount: this.getServiceDeliveryDateTime(
              tripData.srdata.DateCreated,
              Activities.legsDetails.filter((item) => {
                return item.legid.indexOf(String(serviceDetails.SRSecID)) > -1;
              })[0].departDate,
              serviceDetails.Sector,
              serviceDetails.LocationName
                ? serviceDetails.LocationName
                : serviceDetails.Sector,
              Activities.legsDetails.filter((item) => {
                return item.legid.indexOf(String(serviceDetails.SRSecID)) > -1;
              })[0].BLKTIME,
              Activities.legsDetails.filter((item) => {
                return item.legid.indexOf(String(serviceDetails.SRSecID)) > -1;
              })[0].Legstage,
              Activities.tripStatus,
              'timer',
              'station'
            ),
            progressDate: this.getServiceDeliveryDateTime(
              tripData.srdata.DateCreated,
              Activities.legsDetails.filter((item) => {
                return item.legid.indexOf(String(serviceDetails.SRSecID)) > -1;
              })[0].departDate,
              serviceDetails.Sector,
              serviceDetails.LocationName
                ? serviceDetails.LocationName
                : serviceDetails.Sector,
              Activities.legsDetails.filter((item) => {
                return item.legid.indexOf(String(serviceDetails.SRSecID)) > -1;
              })[0].BLKTIME,
              Activities.legsDetails.filter((item) => {
                return item.legid.indexOf(String(serviceDetails.SRSecID)) > -1;
              })[0].Legstage,
              Activities.tripStatus,
              'progressbar',
              'station'
            ),
            ActualLanding: Activities?.legsDetails[0]?.ActualLanding,
            ActualTakeoff: Activities?.legsDetails[0]?.ActualTakeoff,
            legStatus: Activities.legsDetails.filter((item) => {
              return item.legid.indexOf(String(serviceDetails.SRSecID)) > -1;
            })[0].Legstage,
            instantActivity: true,
            crewDetails:
              tripData.assgnedcrewdetails[legIndex][serviceDetails.SRSecID].list
                .length > 0
                ? tripData.assgnedcrewdetails[legIndex][
                  serviceDetails.SRSecID
                ].list.map((crewdata: any) => {
                  return {
                    crewname: crewdata.ClientCrewName,
                    crewid: crewdata.ClientCrewID,
                  };
                })
                : [],
          });
        }
      }
    }
  }
  public ConfirmServiceDelivered(
    serviceData: TripServices,
    url,
    crewid,
    paxflag?: boolean
  ) {
    return new Promise((resolve, reject) => {
      let content = '';
      let service = '';
      let category = '';
      switch (serviceData?.servicename) {
        case 'Airport Charges':
          category = this.servicesCategory.AIRPORT_CHARGES;
          content = 'airportchargessignoff';
          service = '';
          break;
        case 'Arr Handling Delivered':
          category = this.servicesCategory.HANDLING;
          content = 'serviceshavebeendelivered';
          service = 'handling';
          break;
        case 'Dep Handling Delivered':
          category = this.servicesCategory.HANDLING;
          content = 'serviceshavebeendelivered';
          service = 'handling';
          break;
        case 'Pax Arrived':
          category = this.servicesCategory.HANDLING;
          content = 'paxclearedciq';
          service = '';
          break;
        case 'Dep GH Services Form Signoff':
          category = this.servicesCategory.HANDLING;
          content = 'departureghservicesformsignoff';
          service = '';
          break;
        case 'Arr GH Services Form Signoff':
          category = this.servicesCategory.HANDLING;
          content = 'arrivalghservicesformsignoff';
          service = '';
          break;
        case 'Chocks Off':
          category = this.servicesCategory.HANDLING;
          content = 'chocksoff';
          service = '';
          break;
        case 'Take Off':
          category = this.servicesCategory.HANDLING;
          content = 'takeoff';
          service = '';
          break;
        case 'Review Pax Manifest':
          category = this.servicesCategory.HANDLING;
          content = 'reviewpaxmanifest';
          service = '';
          break;
        case 'Review General Declaration':
          category = this.servicesCategory.HANDLING;
          content = 'reviewgeneraldeclaration';
          service = '';
          break;
        case 'Landed':
          category = this.servicesCategory.HANDLING;
          content = 'aircrafthaslanded';
          service = '';
          break;
        case 'Chocks on':
          category = this.servicesCategory.HANDLING;
          content = 'chockson';
          service = '';
          break;
        case this.servicesCategory.PAXDEPARTED:
          category = this.servicesCategory.HANDLING;
          content = 'passengershavecompletedciq';
          service = '';
          break;
        case 'Arr Airport Charges Delivered':
          category = this.servicesCategory.AIRPORT_CHARGES;
          content = 'serviceshavebeendelivered';
          service = 'airportcharges';
          break;
        case 'Dep Airport Charges Delivered':
          category = this.servicesCategory.AIRPORT_CHARGES;
          content = 'serviceshavebeendelivered';
          service = 'airportcharges';
          break;
        case 'Fuel Delivered':
          category = this.servicesCategory.FUEL;
          content = 'serviceshavebeendelivered';
          service = 'fuel';
          break;
        case 'FDN Signoff':
          category = this.servicesCategory.FUEL;
          content = 'fdnsignoff';
          service = '';
          break;
      }
      let sector: string;
      let legId: any;
      if (
        serviceData?.previousSector &&
        serviceData?.previousTimeOfService == 'arrival' &&
        content == ''
      ) {
        sector = serviceData?.previousSector;
      } else {
        sector = serviceData?.sector;
      }
      if (
        serviceData?.previousLegId &&
        serviceData?.previousTimeOfService == 'arrival' &&
        content == ''
      ) {
        legId = serviceData?.previousLegId;
      } else {
        legId = serviceData?.legId;
      }
      let databody =
        'content=' +
        content +
        '&srid=' +
        serviceData.SRID +
        '&sector=' +
        sector +
        '&station=' +
        serviceData.location +
        '&SRSecID=' +
        legId +
        '&service=' +
        service +
        '&url=' +
        encodeURIComponent(url) +
        '&crewid=' +
        crewid;
      if (paxflag == true) {
        databody = databody + '&ignore=' + false;
      } else if (paxflag == false) {
        databody = databody + '&ignore=' + true;
      }
      if (serviceData.servicename.startsWith('Arr ')) {
        databody = databody + '&ArrOrDept=Arrival';
      } else if (serviceData.servicename.startsWith('Dep ')) {
        databody = databody + '&ArrOrDept=Departure';
      }
      this.ServerConnect.connectToServer(
        this.apiType.POST,
        ApiConfig.ConfirmServiceDelivered,
        databody,
        false,
        false,
        false
      )
        .then((res: any) => {
          this.commonService.dismissLoader();
          const displayAlertFor = [
            ApiResponseMessages.GH_SIGNOFF,
            ApiResponseMessages.FDN_SIGNOFF,
            ApiResponseMessages.NOTICE,
            ApiResponseMessages.ERROR,
          ].find((val) => (res.SaveVendorFDNSignoff as string).includes(val));
          if (displayAlertFor) {
            this.commonService
              .showConfirmAlert(res.SaveVendorFDNSignoff)
              .then(() => {
                resolve(res.SaveVendorFDNSignoff);
              });
          } else {
            resolve(res.ResponseStatus);
          }
        })
        .catch((error) => {
          this.commonService.dismissLoader();
          this.commonService.showErrorAlert().then((res) => {
            if (res) {
              this.ConfirmServiceDelivered(serviceData, url, crewid);
            } else {
              reject();
            }
          });
        });
    });
  }
  public enterBayNo(serviceData: TripServices, bayno) {
    return new Promise((resolve, reject) => {
      const databody =
        'BayNumber=' +
        bayno +
        '&FuelDetailID=' +
        serviceData.fuelDetails[0].FuelDetailID +
        '&SRID=' +
        serviceData.SRID +
        '&Sector=' +
        serviceData.sector +
        '&SRSecID=' +
        serviceData.fuelDetails[0].SRSecID;
      this.ServerConnect.connectToServer(
        this.apiType.POST,
        ApiConfig.EnterBayNo,
        databody,
        false,
        false,
        false
      )
        .then((res: any) => {
          resolve(res.ResponseStatus);
        })
        .catch((error) => {
          this.commonService.showErrorAlert().then((res) => {
            if (res) {
              this.enterBayNo(serviceData, bayno);
            } else {
              reject();
            }
          });
        });
    });
  }
  public createVendorServiceArr(Activities: Activity, tripData: any) {
    this.tripServices = [];
    if (tripData.GetVendorTripsActivity.Status == "Active") {
      if (tripData.GetVendorTripsActivity.srdata.Legs.length > 1) {
        this.checkPreviousStationrquest(
          tripData.GetVendorTripsActivity,
          Activities,
        );
      }
    }
    for (const typeOfservice in tripData.GetVendorTripsActivity.quotes) {
      if (typeOfservice) {
        for (const legid in tripData.GetVendorTripsActivity.quotes[
          typeOfservice
        ]) {
          if (legid) {
            for (const serviceName in tripData.GetVendorTripsActivity.quotes[
              typeOfservice
            ][legid]) {
              if (serviceName) {
                this.tripServices =
                  tripData.GetVendorTripsActivity.quotes[typeOfservice][legid];
                tripData.GetVendorTripsActivity.quotes[typeOfservice][legid][
                  serviceName
                ].forEach((details, index) => {
                  const tempLegDetails = Activities.legsDetails.filter(
                    (item) => {
                      return item.legid.indexOf(String(details.SRSecID)) > -1;
                    },
                  );
                  const legStatus: string = tempLegDetails[0].Legstage;
                  if (details.UploadedArray) {
                    if (
                      serviceName == "crew briefing" &&
                      details.UploadedArray.length > 0
                    ) {
                      const uploaValue =
                        details.UploadedArray.filter((item) => {
                          return item.Filed != "true" && item.Selected == 1;
                        }).length > 0
                          ? this.fileFlightPlan(
                            Activities,
                            details,
                            tripData.GetVendorTripsActivity,
                            "Vendor",
                            legid,
                          )
                          : details.UploadedArray.filter((item) => {
                            return (
                              item.Filed == "true" &&
                              item.Finalize != 1 &&
                              item.Selected == "1"
                            );
                          }).length > 0
                            ? this.cbpFinalizeAction(
                              Activities,
                              details,
                              tripData.GetVendorTripsActivity,
                              "Operator",
                              legid,
                            )
                            : "";
                    }
                  }
                  let onTimeOfService: string;
                  if (details.Sector.substring(0, 4) == details.LocationName) {
                    onTimeOfService = "Dep ";
                  } else {
                    onTimeOfService = "Arr ";
                  }
                  if (
                    legStatus == "Inprogressstarting" ||
                    legStatus == "Inprogressfinishing" ||
                    legStatus == "Inprogressfinished" ||
                    legStatus == "Closed"
                  ) {
                    if (
                      serviceName != "permits" &&
                      serviceName != "crew briefing"
                    ) {
                      this.checkInprogressActivity(
                        Activities,
                        tripData.GetVendorTripsActivity,
                        serviceName,
                        details,
                        details.SRSecID,
                        legid,
                      );
                      Activities.services.push({
                        SRID: details.SRID,
                        SRStnID: details.SRStnID,
                        AircraftType:
                          tripData.GetVendorTripsActivity.srdata.Regd,
                        legIndex: legid,
                        FlowID: details.FlowID,
                        legId: Number(details.SRSecID),
                        serviceDetails:
                          serviceName == "fuel"
                            ? details.fuelsubcharges
                            : details.ratebreakarray,
                        sector: details.Sector,
                        price: details.Price,
                        order:
                          serviceName == "permits"
                            ? 3
                            : serviceName == "crew briefing"
                              ? 4
                              : serviceName == "fuel"
                                ? 2.8
                                : serviceName == "handling"
                                  ? onTimeOfService == "Dep "
                                    ? 6.1
                                    : 6.2
                                  : onTimeOfService == "Arr "
                                    ? 7.2
                                    : 7.1,
                        tripStatus: Activities.tripStatus,
                        airlandingandslotpermits:
                          serviceName == "airpermissions"
                            ? details.airlandingandslotpermits
                            : [],
                        status:
                          serviceName == "handling"
                            ? details.InprogressActivityStatus == "false"
                              ? "pending"
                              : "confirmed"
                            : serviceName == "fuel"
                              ? details.fuelsubcharges.length > 0
                                ? "Confirmed"
                                : "pending"
                              : details.InprogressActivityStatus == "false"
                                ? "pending"
                                : "confirmed",
                        vendorid: details.VendorID,
                        firebaseUID: details.firebaseID,
                        location: details.LocationName
                          ? details.LocationName
                          : details.Sector,
                        servicename:
                          serviceName == "fuel"
                            ? "Fuel"
                            : "" || serviceName == "handling"
                              ? onTimeOfService + "Handling"
                              : "" || serviceName == "airpermissions"
                                ? onTimeOfService + "Apt Fees & Permits"
                                : "",
                        serviceType: typeOfservice,
                        category:
                          serviceName == "fuel"
                            ? this.servicesCategory.FUEL
                            : "" || serviceName == "handling"
                              ? this.servicesCategory.HANDLING
                              : "" || serviceName == "airpermissions"
                                ? this.servicesCategory.AIRPORT_CHARGES
                                : "",
                        requestType: "Operator",
                        fuelDetails:
                          serviceName == "fuel" || serviceName == "handling"
                            ? details.fuelDetails
                            : [],
                        logo: details.logo,
                        subservicedetails:
                          serviceName == "fuel" && details.Status == "confirmed"
                            ? details.fuelDetails
                            : serviceName == "airpermissions" &&
                              details.Status == "confirmed"
                              ? details.subservicedetails
                              : serviceName == "handling"
                                ? details.subservicedetails
                                : details.subcharges,
                        fuelIntoplanAgentDetails:
                          details.fuelIntoplanAgentDetails,
                        departSecond: Activities.legsDetails.filter((item) => {
                          return (
                            item.legid.indexOf(String(details.SRSecID)) > -1
                          );
                        })[0].departDate,
                        createSecond:
                          tripData.GetVendorTripsActivity.srdata.DateCreated,
                        timerCount: this.getServiceDeliveryDateTime(
                          tripData.GetVendorTripsActivity.srdata.DateCreated,
                          Activities.legsDetails.filter((item) => {
                            return (
                              item.legid.indexOf(String(details.SRSecID)) > -1
                            );
                          })[0].departDate,
                          details.Sector,
                          details.LocationName
                            ? details.LocationName
                            : details.Sector,
                          Activities.legsDetails.filter((item) => {
                            return (
                              item.legid.indexOf(String(details.SRSecID)) > -1
                            );
                          })[0].BLKTIME,
                          Activities.legsDetails.filter((item) => {
                            return (
                              item.legid.indexOf(String(details.SRSecID)) > -1
                            );
                          })[0].Legstage,
                          Activities.tripStatus,
                          "timer",
                          typeOfservice,
                        ),
                        progressDate: this.getServiceDeliveryDateTime(
                          tripData.GetVendorTripsActivity.srdata.DateCreated,
                          Activities.legsDetails.filter((item) => {
                            return (
                              item.legid.indexOf(String(details.SRSecID)) > -1
                            );
                          })[0].departDate,
                          details.Sector,
                          details.LocationName
                            ? details.LocationName
                            : details.Sector,
                          Activities.legsDetails.filter((item) => {
                            return (
                              item.legid.indexOf(String(details.SRSecID)) > -1
                            );
                          })[0].BLKTIME,
                          Activities.legsDetails.filter((item) => {
                            return (
                              item.legid.indexOf(String(details.SRSecID)) > -1
                            );
                          })[0].Legstage,
                          Activities.tripStatus,
                          "progressbar",
                          typeOfservice,
                        ),
                        ActualLanding: Activities?.legsDetails[0]?.ActualLanding,
                        ActualTakeoff: Activities?.legsDetails[0]?.ActualTakeoff,
                        legStatus: Activities.legsDetails.filter((item) => {
                          return (
                            item.legid.indexOf(String(details.SRSecID)) > -1
                          );
                        })[0].Legstage,
                        handlerdetails: details.handlerDetails,
                        ArrDeptStatus:
                          details.ArrDeptStatus == "inprogress" ? true : false,
                        crewDetails: tripData.GetVendorTripsActivity
                          .assgnedcrewdetails[legid][details.SRSecID].list
                          ? tripData.GetVendorTripsActivity.assgnedcrewdetails[
                            legid
                          ][details.SRSecID].list.map((crewdata: any) => {
                            return {
                              crewname: crewdata.ClientCrewName,
                              crewid: crewdata.ClientCrewID,
                            };
                          })
                          : [],
                        commonLeg:
                          serviceName == "handling"
                            ? this.both_arrival_departure(
                              tripData.GetVendorTripsActivity.srdata.Legs,
                              tripData.GetVendorTripsActivity.quotes[
                              typeOfservice
                              ][legid][serviceName],
                              details.SRSecID,
                              details,
                              index,
                            )
                            : serviceName == "airpermissions"
                              ? this.both_arrival_departure(
                                tripData.GetVendorTripsActivity.srdata.Legs,
                                tripData.GetVendorTripsActivity.quotes[
                                typeOfservice
                                ][legid][serviceName],
                                details.SRSecID,
                                details,
                                index,
                              )
                              : false,
                        previousLegId: this.prevLegandSector(
                          Number(details.SRSecID),
                          typeOfservice,
                          serviceName,
                          tripData.GetVendorTripsActivity,
                          details.LocationName,
                          details.Sector,
                          "led_id",
                        ),
                        previousSector: this.prevLegandSector(
                          Number(details.SRSecID),
                          typeOfservice,
                          serviceName,
                          tripData.GetVendorTripsActivity,
                          details.LocationName,
                          details.Sector,
                          "sector",
                        ),
                        previousLegStatus: this.prevLegandSector(
                          Number(details.SRSecID),
                          typeOfservice,
                          serviceName,
                          tripData.GetVendorTripsActivity,
                          details.LocationName,
                          details.Sector,
                          "status",
                        ),
                      });
                    }
                  } else {
                    if (details.Status != "declined") {
                      this.getFlightPlanSelectedData();
                      Activities.services.push({
                        SRID: details.SRID,
                        SRStnID: details.SRStnID,
                        AircraftType:
                          tripData.GetVendorTripsActivity.srdata.Regd,
                        legIndex: legid,
                        FlowID: details.FlowID,
                        legId: Number(details.SRSecID),
                        serviceDetails: details.ratebreakarray,
                        sector: details.Sector,
                        price: details.Price,
                        order:
                          serviceName == "permits"
                            ? 4
                            : serviceName == "crew briefing"
                              ? 3
                              : serviceName == "fuel"
                                ? 5
                                : serviceName == "handling"
                                  ? onTimeOfService == "Dep "
                                    ? 6.1
                                    : 6.2
                                  : onTimeOfService == "Arr "
                                    ? 7.2
                                    : 7.1,
                        tripStatus: Activities.tripStatus,
                        airlandingandslotpermits:
                          serviceName == "airpermissions"
                            ? details.airlandingandslotpermits
                            : [],
                        Status: details.Status ? details.Status : "",
                        status: this.setVendorActionItemStatus(
                          Activities,
                          details,
                        ),
                        vendorid: details.VendorID,
                        location: details.LocationName
                          ? details.LocationName
                          : details.Sector,
                        servicename:
                          serviceName == "permits"
                            ? "Overflight Permits"
                            : "" || serviceName == "crew briefing"
                              ? "Flight Planning"
                              : "" || serviceName == "fuel"
                                ? "Fuel"
                                : "" || serviceName == "handling"
                                  ? onTimeOfService + "Handling"
                                  : "" || serviceName == "airpermissions"
                                    ? onTimeOfService + "Apt Fees & Permits"
                                    : "",
                        serviceType: typeOfservice,
                        category:
                          serviceName == "permits"
                            ? this.servicesCategory.OVERFLIGHT_PERMITS
                            : "" || serviceName == "crew briefing"
                              ? this.servicesCategory.FLIGHT_PLANNING
                              : "" || serviceName == "fuel"
                                ? this.servicesCategory.FUEL
                                : "" || serviceName == "handling"
                                  ? this.servicesCategory.HANDLING
                                  : "" || serviceName == "airpermissions"
                                    ? this.servicesCategory.AIRPORT_CHARGES
                                    : "",
                        requestType: "Operator",
                        logo: details.logo,
                        selectFlightPlan:
                          details.FlightPlanSelected != undefined
                            ? details.FlightPlanSelected
                            : this.selectFlightPlan,
                        NavigationCost:
                          serviceName == "permits" ? details.NavigationCost : 0,
                        fuelDetails:
                          serviceName == "fuel" ? details.fuelDetails : [],
                        handlerdetails: details.handlerDetails,
                        fuelIntoplanAgentDetails:
                          details.fuelIntoplanAgentDetails,
                        subservicedetails:
                          serviceName == "crew briefing" &&
                            details.Status == "confirmed"
                            ? details.UploadedArray
                            : serviceName == "fuel" &&
                              details.Status == "confirmed"
                              ? details.fuelDetails
                              : serviceName == "airpermissions" &&
                                details.Status == "confirmed"
                                ? details.subservicedetails
                                : serviceName == "handling"
                                  ? details.subservicedetails
                                  : details.subcharges,
                        flightPlanningUploaded:
                          (serviceName == "crew briefing" ||
                            serviceName == "permits") &&
                            details.Status == "confirmed" &&
                            Activities.tripStatus != "Processing"
                            ? details.FlightPlanUploaded
                            : false,
                        departSecond: Activities.legsDetails.filter((item) => {
                          return (
                            item.legid.indexOf(String(details.SRSecID)) > -1
                          );
                        })[0].departDate,
                        createSecond:
                          tripData.GetVendorTripsActivity.srdata.DateCreated,
                        timerCount: this.getServiceDeliveryDateTime(
                          tripData.GetVendorTripsActivity.srdata.DateCreated,
                          Activities.tripStatus == "Processing"
                            ? tripData.GetVendorTripsActivity.srdata.Legs[0]
                              .departDate
                            : Activities.legsDetails.filter((item) => {
                              return (
                                item.legid.indexOf(String(details.SRSecID)) >
                                -1
                              );
                            })[0].departDate,
                          details.Sector,
                          details.LocationName
                            ? details.LocationName
                            : details.Sector,
                          Activities.legsDetails.filter((item) => {
                            return (
                              item.legid.indexOf(String(details.SRSecID)) > -1
                            );
                          })[0].BLKTIME,
                          Activities.legsDetails.filter((item) => {
                            return (
                              item.legid.indexOf(String(details.SRSecID)) > -1
                            );
                          })[0].Legstage,
                          Activities.tripStatus,
                          "timer",
                          typeOfservice,
                        ),
                        progressDate: this.getServiceDeliveryDateTime(
                          tripData.GetVendorTripsActivity.srdata.DateCreated,
                          Activities.tripStatus == "Processing"
                            ? tripData.GetVendorTripsActivity.srdata.Legs[0]
                              .departDate
                            : Activities.legsDetails.filter((item) => {
                              return (
                                item.legid.indexOf(String(details.SRSecID)) >
                                -1
                              );
                            })[0].departDate,
                          details.Sector,
                          details.LocationName
                            ? details.LocationName
                            : details.Sector,
                          Activities.legsDetails.filter((item) => {
                            return (
                              item.legid.indexOf(String(details.SRSecID)) > -1
                            );
                          })[0].BLKTIME,
                          Activities.legsDetails.filter((item) => {
                            return (
                              item.legid.indexOf(String(details.SRSecID)) > -1
                            );
                          })[0].Legstage,
                          Activities.tripStatus,
                          "progressbar",
                          typeOfservice,
                        ),
                        ActualLanding: Activities?.legsDetails[0]?.ActualLanding,
                        ActualTakeoff: Activities?.legsDetails[0]?.ActualTakeoff,
                        legStatus: Activities.legsDetails.filter((item) => {
                          return (
                            item.legid.indexOf(String(details.SRSecID)) > -1
                          );
                        })[0].Legstage,
                        ArrDeptStatus:
                          details.ArrDeptStatus == "inprogress" ? true : false,
                        crewDetails:
                          tripData.GetVendorTripsActivity.assgnedcrewdetails !=
                            undefined &&
                            tripData.GetVendorTripsActivity.assgnedcrewdetails[
                              legid
                            ][details.SRSecID].list
                            ? tripData.GetVendorTripsActivity.assgnedcrewdetails[
                              legid
                            ][details.SRSecID].list.map((crewdata: any) => {
                              return {
                                crewname: crewdata.ClientCrewName,
                                crewid: crewdata.ClientCrewID,
                              };
                            })
                            : [],
                        flightPlanDetails:
                          serviceName == "permits"
                            ? details.flightPlanResponse
                              ? details.flightPlanResponse.CountryInformation
                              : []
                            : [],
                        commonLeg:
                          serviceName == "handling"
                            ? this.both_arrival_departure(
                              tripData.GetVendorTripsActivity.srdata.Legs,
                              tripData.GetVendorTripsActivity.quotes[
                              typeOfservice
                              ][legid][serviceName],
                              details.SRSecID,
                              details,
                              index,
                            )
                            : serviceName == "airpermissions"
                              ? this.both_arrival_departure(
                                tripData.GetVendorTripsActivity.srdata.Legs,
                                tripData.GetVendorTripsActivity.quotes[
                                typeOfservice
                                ][legid][serviceName],
                                details.SRSecID,
                                details,
                                index,
                              )
                              : false,
                        previousLegId: this.prevLegandSector(
                          Number(details.SRSecID),
                          typeOfservice,
                          serviceName,
                          tripData.GetVendorTripsActivity,
                          details.LocationName,
                          details.Sector,
                          "led_id",
                        ),
                        previousSector: this.prevLegandSector(
                          Number(details.SRSecID),
                          typeOfservice,
                          serviceName,
                          tripData.GetVendorTripsActivity,
                          details.LocationName,
                          details.Sector,
                          "sector",
                        ),
                        previousLegStatus: this.prevLegandSector(
                          Number(details.SRSecID),
                          typeOfservice,
                          serviceName,
                          tripData.GetVendorTripsActivity,
                          details.LocationName,
                          details.Sector,
                          "status",
                        ),
                      });
                    }
                  }
                });
              }
            }
          }
        }
      }
    }
    return Activities;
  }
  public setVendorActionItemStatus(Activities, details) {
    let serviceStatus;
    if (Activities.tripStatus == 'Processing') {
      serviceStatus = details.Status;
    } else if (Activities.tripStatus == 'Confirmed') {
      serviceStatus =
        details.UpcomingActivityStatus == 'false' ? 'pending' : 'confirmed';
    } else if (Activities.tripStatus == 'Active') {
      if (details.vendorUpcomingConfirmation != undefined) {
        if (
          (details.vendorUpcomingConfirmation == 'no' &&
            details.UpcomingActivityStatus == undefined) ||
          (details.vendorUpcomingConfirmation == 'no' &&
            details.UpcomingActivityStatus == 'false')
        ) {
          serviceStatus = 'pending';
        } else {
          serviceStatus = 'confirmed';
        }
      } else if (details.UpcomingActivityStatus != undefined) {
        serviceStatus =
          details.UpcomingActivityStatus == 'false' ? 'pending' : 'confirmed';
      } else {
        serviceStatus = 'confirmed';
      }
    } else {
      serviceStatus = 'pending';
    }
    return serviceStatus;
  }
  public both_arrival_departure(
    legs: any,
    services: any,
    legid: any,
    legdetails: any,
    servicindex: number
  ) {
    return (
      legs.length > 1 &&
      this.legindex_of_legid(legs, legid, servicindex) &&
      (legdetails.Sector
        ? legdetails.Sector.substring(0, 4)
        : legdetails.sector.substring(0, 4)) ==
      (legdetails.LocationName || legdetails.location)
    );
  }
  public createActivityForFlightPlanSelect(
    Activities: Activity,
    serviceDetails: any,
    tripData: any,
    legIndex
  ) {
    if (Activities.tripStatus != 'Completed') {
      Activities.services.push({
        SRID: tripData.srid != undefined ? tripData.srid : serviceDetails.SRID,
        legIndex,
        legId:
          serviceDetails.legid != undefined
            ? serviceDetails.legid
            : serviceDetails.legid,
        serviceDetails: serviceDetails.fuelDetails,
        subservicedetails: serviceDetails.UploadedArray,
        taxes: serviceDetails.taxes,
        price: serviceDetails.price,
        status:
          serviceDetails.UploadedArray.filter((item) => {
            return item.Selected == 1;
          }).length != 0
            ? 'confirmed'
            : 'pending',
        flightPlanningUploaded:
          tripData.srcbparray[serviceDetails.legid].length > 0 ? true : false,
        sector:
          serviceDetails.Sector == undefined
            ? serviceDetails.sector
            : serviceDetails.Sector,
        legStatus: Activities.legsDetails.filter((item) => {
          return item.legid.indexOf(String(serviceDetails.legid)) > -1;
        })[0].Legstage,
        tripStatus: Activities.tripStatus,
        order: 2.1,
        location:
          serviceDetails.Sector == undefined
            ? serviceDetails.sector
            : serviceDetails.Sector,
        vendorid: serviceDetails.vendorid,
        firebaseUID: serviceDetails.firebaseID,
        servicename: 'Finalize Flight Plan',
        category: this.servicesCategory.FLIGHT_PLANNING,
        serviceType: 'sector',
        requestType: serviceDetails.status == 'inhouse' ? 'Self' : 'Vendor',
        logo: '',
        departSecond: Activities.legsDetails.filter((item) => {
          if (serviceDetails.legid == undefined) {
            return item.legid.indexOf(String(serviceDetails.legid)) > -1;
          } else if (serviceDetails.legid != undefined) {
            return item.legid.indexOf(String(serviceDetails.legid)) > -1;
          }
        })[0].departDate,
        createSecond: tripData.srdata.DateCreated,
        timerCount: this.getServiceDeliveryDateTime(
          tripData.srdata.DateCreated,
          Activities.legsDetails.filter((item) => {
            return item.legid.indexOf(String(serviceDetails.legid)) > -1;
          })[0].departDate,
          serviceDetails.Sector,
          serviceDetails.Sector,
          Activities.legsDetails.filter((item) => {
            return item.legid.indexOf(String(serviceDetails.legid)) > -1;
          })[0].BLKTIME,
          Activities.legsDetails.filter((item) => {
            return item.legid.indexOf(String(serviceDetails.legid)) > -1;
          })[0].Legstage,
          Activities.tripStatus,
          'timer',
          'sector'
        ),
        progressDate: this.getServiceDeliveryDateTime(
          tripData.srdata.DateCreated,
          Activities.legsDetails.filter((item) => {
            return item.legid.indexOf(String(serviceDetails.legid)) > -1;
          })[0].departDate,
          serviceDetails.Sector,
          serviceDetails.Sector,
          Activities.legsDetails.filter((item) => {
            return item.legid.indexOf(String(serviceDetails.legid)) > -1;
          })[0].BLKTIME,
          Activities.legsDetails.filter((item) => {
            return item.legid.indexOf(String(serviceDetails.legid)) > -1;
          })[0].Legstage,
          Activities.tripStatus,
          'progressbar',
          'sector'
        ),
        // ActualLanding:tempLegDetails[0].ActualLanding, // temp
        // ActualTakeoff:tempLegDetails[0].ActualTakeoff, //
        ActualLanding: Activities?.legsDetails[0]?.ActualLanding,
        ActualTakeoff: Activities?.legsDetails[0]?.ActualTakeoff,
        crewDetails:tripData.assgnedcrewdetails[legIndex]
      });
    }
  }
  public checkPreviousStationrquest(tripData: any, Activities: Activity) {
    tripData.srdata.Legs.forEach((element, legIndex) => {
      if (inProgressLegStages.includes(element.Legstage)) {
        const servicesList =
          tripData.inprogressfullfillmentarray[legIndex][element.legid][
          element.sector
          ][String(element.sector).substring(5, 9)];
        if (this.commonService.roleId == 2) {
          if (tripData?.quotes?.station && tripData.quotes.station[Number(legIndex) + 1]) {
            if (
              tripData.quotes.station[Number(legIndex) + 1].handling?.filter(
                (item) => {
                  return (
                    item.legid == String(Number(element.legid) + 1) &&
                    item.location.indexOf(
                      String(element.sector).substring(5, 9)
                    ) > -1 &&
                    item.status.indexOf('inhouse') > -1
                  );
                }
              ).length > 0
            ) {
              for (const serviceActivity in servicesList) {
                if (serviceActivity) {
                  const flagArr = HANDLING_ACTION_ITEM_LIST;
                  const isAirportCharges = [
                    ACTIVITY_SERVICES.ARRIVAL_AIRPORT_CHARGES,
                    ACTIVITY_SERVICES.ARRIVAL_AIRPORT_CHARGES_DELIVERED,
                  ].includes(serviceActivity);

                  if (flagArr.indexOf(serviceActivity) != -1) {
                    const message = ACTION_ITEM_NAME[serviceActivity];
                    const category =
                      serviceActivity ==
                        ACTIVITY_SERVICES.ARRIVAL_AIRPORT_CHARGES_DELIVERED
                        ? this.servicesCategory.AIRPORT_CHARGES
                        : this.servicesCategory.HANDLING;
                    const order = ACTION_ITEM_ORDER[serviceActivity];
                    const quoteStationType =
                      serviceActivity ==
                        ACTIVITY_SERVICES.ARRIVAL_AIRPORT_CHARGES
                        ? CONSTANT_MESSAGES.AIR_PERMISSIONS
                        : ServiceName.handling;
                    const setSubcharges =
                      serviceActivity ==
                        ACTIVITY_SERVICES.ARRIVAL_AIRPORT_CHARGES
                        ? true
                        : false;
                    const instantActvityVal =
                      serviceActivity ==
                        ACTIVITY_SERVICES.ARRIVAL_AIRPORT_CHARGES
                        ? false
                        : true;
                    const type =
                      serviceActivity ==
                        ACTIVITY_SERVICES.ARRIVAL_AIRPORT_CHARGES
                        ? CONSTANT_MESSAGES.AIR_PERMISSIONS
                        : ACTIVITY_SERVICES.ARRIVAL_HANDLING;

                    this.getInprogressActionItems(
                      legIndex,
                      Activities,
                      tripData,
                      element,
                      serviceActivity,
                      message,
                      order,
                      category,
                      CONSTANT_MESSAGES.STATION,
                      CONSTANT_MESSAGES.OPERATOR,
                      type,
                      quoteStationType,
                      instantActvityVal,
                      setSubcharges
                    );
                  }
                }
              }
            }
          }
        } else if (this.commonService.roleId == 3) {
          if (
            tripData?.quotes?.station &&
            tripData.quotes.station[Number(legIndex) + 1]
          ) {
            if (
              tripData.quotes.station[Number(legIndex) + 1].handling &&
              this.checkServiceAvailabilityInQuotes(
                tripData,
                element,
                ServiceName.handling,
                legIndex
              ).length > 0
            ) {
              for (const serviceActivity in servicesList) {
                if (serviceActivity) {
                  const vendorFlagarr = HANDLING_ACTION_ITEM_LIST;
                  if (vendorFlagarr.indexOf(serviceActivity) != -1) {
                    const sendType =
                      serviceActivity == ACTIVITY_SERVICES.ARRIVAL_HANDLING
                        ? ACTIVITY_SERVICES.ARRIVAL_HANDLING
                        : ServiceName.handling;
                    const category = this.servicesCategory.HANDLING;
                    const message = ACTION_ITEM_NAME[serviceActivity];
                    const order = ACTION_ITEM_ORDER[serviceActivity];
                    this.getInprogressActionItems(
                      legIndex,
                      Activities,
                      tripData,
                      element,
                      serviceActivity,
                      message,
                      order,
                      category,
                      CONSTANT_MESSAGES.STATION,
                      CONSTANT_MESSAGES.OPERATOR,
                      sendType,
                      ServiceName.handling
                    );
                  }
                }
              }
            }
            if (
              tripData.quotes.station[Number(legIndex) + 1].airpermissions &&
              this.checkServiceAvailabilityInQuotes(
                tripData,
                element,
                CONSTANT_MESSAGES.AIR_PERMISSIONS,
                legIndex
              ).length > 0
            ) {
              for (const serviceActivity in servicesList) {
                if (serviceActivity) {
                  const isAirportCharges = [
                    ACTIVITY_SERVICES.ARRIVAL_AIRPORT_CHARGES,
                    ACTIVITY_SERVICES.ARRIVAL_AIRPORT_CHARGES_DELIVERED,
                  ].includes(serviceActivity);
                  if (isAirportCharges) {
                    const message = ACTION_ITEM_NAME[serviceActivity];
                    const order = ACTION_ITEM_ORDER[serviceActivity];
                    const category = this.servicesCategory.AIRPORT_CHARGES;
                    this.getInprogressActionItems(
                      legIndex,
                      Activities,
                      tripData,
                      element,
                      serviceActivity,
                      message,
                      order,
                      category,
                      CONSTANT_MESSAGES.STATION,
                      CONSTANT_MESSAGES.OPERATOR,
                      CONSTANT_MESSAGES.AIR_PERMISSIONS,
                      CONSTANT_MESSAGES.AIR_PERMISSIONS
                    );
                  }
                }
              }
            }
          }
        }
      }
    });
  }
  public getInprogressActionItems(
    legIndex,
    Activities,
    tripData,
    element,
    serviceActivity,
    servicename,
    order,
    category,
    serviceType,
    requestType,
    type,
    quoteStationType,
    instantActvityVal?: boolean,
    setSubcharges?: boolean
  ) {
    const legId = element.legid;
    const sectorValue = element.sector;
    const legDetails = Activities.legsDetails.filter(
      (item) => item.legid.indexOf(String(legId)) > -1
    )[0];
    const sectorVal =
      tripData.inprogressfullfillmentarray[legIndex][legId][sectorValue][
      String(sectorValue).substring(0, 4)
      ];
    const isValid: boolean =
      type == CONSTANT_MESSAGES.AIR_PERMISSIONS ||
      type == ACTIVITY_SERVICES.ARRIVAL_HANDLING;
    const getAvailableService = this.checkServiceAvailabilityInQuotes(
      tripData,
      element,
      quoteStationType,
      legIndex
    )[0];
    const incrementLegId =
      getAvailableService && getAvailableService.SRSecID != undefined
        ? getAvailableService.SRSecID
        : Number(legId) + 1;
    Activities.services.push({
      SRID: tripData.SRID || tripData.srid,
      legIndex,
      legId,
      serviceDetails: [],
      sector: sectorValue,
      price: 0,
      tripStatus: Activities.tripStatus,
      airlandingandslotpermits: this.setAirLandingAndSlotPermits(
        type,
        tripData,
        element,
        quoteStationType,
        setSubcharges,
        legIndex
      ),
      status: tripData.inprogressfullfillmentarray[legIndex][legId][
        sectorValue
      ][String(sectorValue).substring(5, 9)][serviceActivity]
        ? Status.Confirmed.toLowerCase()
        : Status.Pending.toLowerCase(),
      vendorid: isValid
        ? this.commonService.UserID
        : getAvailableService.VendorID
          ? getAvailableService.VendorID
          : '',
      location: String(sectorValue).substring(5, 9),
      servicename,
      order,
      category,
      fuelDetails: [],
      serviceType,
      requestType,
      logo: isValid
        ? ''
        : getAvailableService.logo
          ? getAvailableService.logo
          : '',
      subservicedetails: isValid
        ? {
          Arrival:
            tripData.quotes.station[legIndex + 1][quoteStationType][0]
              .subservicedetails.Arrival,
          Departure: [],
        }
        : [],
      departSecond: legDetails.departDate,
      createSecond: tripData.srdata.DateCreated,
      timerCount: this.getServiceDeliveryDateTime(
        tripData.srdata.DateCreated,
        legDetails.departDate,
        sectorValue,
        (sectorValue as string).substring(5, 9),
        legDetails.BLKTIME,
        legDetails.Legstage,
        Activities.tripStatus,
        CONSTANT_MESSAGES.TIMER,
        CONSTANT_MESSAGES.STATION
      ),
      progressDate: this.getServiceDeliveryDateTime(
        tripData.srdata.DateCreated,
        legDetails.departDate,
        sectorValue,
        (sectorValue as string).substring(5, 9),
        legDetails.BLKTIME,
        legDetails.Legstage,
        Activities.tripStatus,
        CONSTANT_MESSAGES.PROGRESS_BAR,
        CONSTANT_MESSAGES.STATION
      ),
      legStatus:
        isValid || type == 'handling'
          ? element.Legstage
          : Activities.legsDetails.filter(
            (item) =>
              item.legid.indexOf(getAvailableService.SRSecID as string) > -1
          )[0].Legstage,
      instantActivity:
        isValid && !instantActvityVal && instantActvityVal != undefined
          ? false
          : this.setInstantActivity(
            serviceActivity,
            sectorVal,
            servicename,
            instantActvityVal
          ),
      crewDetails:
        tripData.assgnedcrewdetails[legIndex + 1][incrementLegId].list.length >
          0
          ? tripData.assgnedcrewdetails[legIndex + 1][incrementLegId].list.map(
            ({ ClientCrewName, ClientCrewID }) => {
              return {
                crewname: ClientCrewName,
                crewid: ClientCrewID,
              };
            }
          )
          : [],
      previousLegId: isValid ? legId : '',
      previousSector: instantActvityVal ? sectorValue : '',
      previousLegStatus: isValid,
      ActualLanding: Activities.legsDetails[0].ActualLanding,
      ActualTakeoff: Activities.legsDetails[0].ActualTakeoff,
    });
  }
  public cbpFinalizeAction(
    Activities: Activity,
    serviceDetails: any,
    tripData: any,
    requestType,
    legIndex
  ) {
    Activities.services.push({
      SRID: tripData.srid != undefined ? tripData.srid : serviceDetails.SRID,
      AircraftType: tripData.srdata.Regd,
      legIndex,
      legId:
        serviceDetails.SRSecID != undefined
          ? serviceDetails.SRSecID
          : serviceDetails.legid,
      serviceDetails: serviceDetails.fuelDetails,
      subservicedetails: serviceDetails.UploadedArray,
      taxes: serviceDetails.taxes,
      price: serviceDetails.price,
      status:
        serviceDetails.UploadedArray.filter((item) => {
          return item.Finalize == 1;
        }).length != 0
          ? 'confirmed'
          : 'pending',
      flightPlanningUploaded:
        requestType == 'self'
          ? tripData.srcbparray[serviceDetails.SRSecID]
            ? tripData.srcbparray[serviceDetails.SRSecID].length > 0
              ? true
              : false
            : false
          : serviceDetails.UploadedArray,
      sector:
        requestType == 'self' ? serviceDetails.sector : serviceDetails.Sector,
      legStatus: Activities.legsDetails.filter((item) => {
        return (
          item.legid.indexOf(
            requestType == 'self'
              ? String(serviceDetails.legid)
              : String(serviceDetails.SRSecID)
          ) > -1
        );
      })[0].Legstage,
      tripStatus: Activities.tripStatus,
      order: 2.2,
      location:
        requestType == 'self' ? serviceDetails.sector : serviceDetails.Sector,
      vendorid: serviceDetails.vendorid,
      servicename: 'Trip Kit',
      category: this.servicesCategory.FLIGHT_PLANNING,
      serviceType: 'sector',
      requestType,
      logo: '',
      departSecond: Activities.legsDetails.filter((item) => {
        if (serviceDetails.SRSecID == undefined) {
          return (
            item.legid.indexOf(
              requestType == 'self'
                ? String(serviceDetails.legid)
                : String(serviceDetails.SRSecID)
            ) > -1
          );
        } else if (serviceDetails.SRSecID != undefined) {
          return (
            item.legid.indexOf(
              requestType == 'self'
                ? String(serviceDetails.legid)
                : String(serviceDetails.SRSecID)
            ) > -1
          );
        }
      })[0].departDate,
      createSecond: tripData.srdata.DateCreated,
      timerCount: this.getServiceDeliveryDateTime(
        tripData.srdata.DateCreated,
        Activities.legsDetails.filter((item) => {
          return (
            item.legid.indexOf(
              requestType == 'self'
                ? String(serviceDetails.legid)
                : String(serviceDetails.SRSecID)
            ) > -1
          );
        })[0].departDate,
        requestType == 'self' ? serviceDetails.sector : serviceDetails.Sector,
        requestType == 'self' ? serviceDetails.sector : serviceDetails.Sector,
        Activities.legsDetails.filter((item) => {
          return (
            item.legid.indexOf(
              requestType == 'self'
                ? String(serviceDetails.legid)
                : String(serviceDetails.SRSecID)
            ) > -1
          );
        })[0].BLKTIME,
        Activities.legsDetails.filter((item) => {
          return (
            item.legid.indexOf(
              requestType == 'self'
                ? String(serviceDetails.legid)
                : String(serviceDetails.SRSecID)
            ) > -1
          );
        })[0].Legstage,
        Activities.tripStatus,
        'timer',
        'sector'
      ),
      progressDate: this.getServiceDeliveryDateTime(
        tripData.srdata.DateCreated,
        Activities.legsDetails.filter((item) => {
          return (
            item.legid.indexOf(
              requestType == 'self'
                ? String(serviceDetails.legid)
                : String(serviceDetails.SRSecID)
            ) > -1
          );
        })[0].departDate,
        requestType == 'self' ? serviceDetails.sector : serviceDetails.Sector,
        requestType == 'self' ? serviceDetails.sector : serviceDetails.Sector,
        Activities.legsDetails.filter((item) => {
          return (
            item.legid.indexOf(
              requestType == 'self'
                ? String(serviceDetails.legid)
                : String(serviceDetails.SRSecID)
            ) > -1
          );
        })[0].BLKTIME,
        Activities.legsDetails.filter((item) => {
          return (
            item.legid.indexOf(
              requestType == 'self'
                ? String(serviceDetails.legid)
                : String(serviceDetails.SRSecID)
            ) > -1
          );
        })[0].Legstage,
        Activities.tripStatus,
        'progressbar',
        'sector'
      ),
      ActualLanding: Activities?.legsDetails[0]?.ActualLanding,
      ActualTakeoff: Activities?.legsDetails[0]?.ActualTakeoff,
    });
  }
  public fileFlightPlan(
    Activities: Activity,
    serviceDetails: any,
    tripData: any,
    requestType,
    legIndex
  ) {
    if (Activities.tripStatus != 'Closed') {
      Activities.services.push({
        SRID: tripData.srid != undefined ? tripData.srid : serviceDetails.SRID,
        legIndex,
        legId:
          serviceDetails.SRSecID != undefined
            ? serviceDetails.SRSecID
            : serviceDetails.legid,
        serviceDetails: serviceDetails.fuelDetails,
        subservicedetails: serviceDetails.UploadedArray,
        taxes: serviceDetails.taxes,
        price: serviceDetails.price,
        status:
          serviceDetails.UploadedArray.filter((item) => {
            return item.Filed == 'true';
          }).length != 0
            ? 'confirmed'
            : 'pending',
        flightPlanningUploaded:
          requestType == 'self'
            ? tripData.srcbparray[serviceDetails.SRSecID]
              ? tripData.srcbparray[serviceDetails.SRSecID].length > 0
                ? true
                : false
              : false
            : serviceDetails.UploadedArray,
        sector:
          requestType == 'self' ? serviceDetails.sector : serviceDetails.Sector,
        legStatus: Activities.legsDetails.filter((item) => {
          return (
            item.legid.indexOf(
              requestType == 'self'
                ? String(serviceDetails.legid)
                : String(serviceDetails.SRSecID)
            ) > -1
          );
        })[0].Legstage,
        tripStatus: Activities.tripStatus,
        order: 10,
        location:
          requestType == 'self' ? serviceDetails.sector : serviceDetails.Sector,
        vendorid: serviceDetails.vendorid,
        servicename: 'File Flight Plan',
        category: this.servicesCategory.FLIGHT_PLANNING,
        serviceType: 'sector',
        requestType,
        logo: '',
        departSecond: Activities.legsDetails.filter((item) => {
          if (serviceDetails.SRSecID == undefined) {
            return (
              item.legid.indexOf(
                requestType == 'self'
                  ? String(serviceDetails.legid)
                  : String(serviceDetails.SRSecID)
              ) > -1
            );
          } else if (serviceDetails.SRSecID != undefined) {
            return (
              item.legid.indexOf(
                requestType == 'self'
                  ? String(serviceDetails.legid)
                  : String(serviceDetails.SRSecID)
              ) > -1
            );
          }
        })[0].departDate,
        createSecond: tripData.srdata.DateCreated,
        timerCount: this.getServiceDeliveryDateTime(
          tripData.srdata.DateCreated,
          Activities.legsDetails.filter((item) => {
            return (
              item.legid.indexOf(
                requestType == 'self'
                  ? String(serviceDetails.legid)
                  : String(serviceDetails.SRSecID)
              ) > -1
            );
          })[0].departDate,
          requestType == 'self' ? serviceDetails.sector : serviceDetails.Sector,
          requestType == 'self' ? serviceDetails.sector : serviceDetails.Sector,
          Activities.legsDetails.filter((item) => {
            return (
              item.legid.indexOf(
                requestType == 'self'
                  ? String(serviceDetails.legid)
                  : String(serviceDetails.SRSecID)
              ) > -1
            );
          })[0].BLKTIME,
          Activities.legsDetails.filter((item) => {
            return (
              item.legid.indexOf(
                requestType == 'self'
                  ? String(serviceDetails.legid)
                  : String(serviceDetails.SRSecID)
              ) > -1
            );
          })[0].Legstage,
          Activities.tripStatus,
          'timer',
          'sector'
        ),
        progressDate: this.getServiceDeliveryDateTime(
          tripData.srdata.DateCreated,
          Activities.legsDetails.filter((item) => {
            return (
              item.legid.indexOf(
                requestType == 'self'
                  ? String(serviceDetails.legid)
                  : String(serviceDetails.SRSecID)
              ) > -1
            );
          })[0].departDate,
          requestType == 'self' ? serviceDetails.sector : serviceDetails.Sector,
          requestType == 'self' ? serviceDetails.sector : serviceDetails.Sector,
          Activities.legsDetails.filter((item) => {
            return (
              item.legid.indexOf(
                requestType == 'self'
                  ? String(serviceDetails.legid)
                  : String(serviceDetails.SRSecID)
              ) > -1
            );
          })[0].BLKTIME,
          Activities.legsDetails.filter((item) => {
            return (
              item.legid.indexOf(
                requestType == 'self'
                  ? String(serviceDetails.legid)
                  : String(serviceDetails.SRSecID)
              ) > -1
            );
          })[0].Legstage,
          Activities.tripStatus,
          'progressbar',
          'sector'
        ),
        ActualLanding: Activities?.legsDetails[0]?.ActualLanding,
        ActualTakeoff: Activities?.legsDetails[0]?.ActualTakeoff,
      });
    }
  }
  public checkServiceAvailabilityInQuotes(tripData, element, type, legIndex) {
    const elementBySector = this.getSectorByLegId(tripData, element.legid);
    let legId;
    let sector;
    if (
      (type == 'handling' && elementBySector.length != 0) ||
      (type == 'airpermissions' && elementBySector.length != 0)
    ) {
      legId = elementBySector[0].legid;
      sector = elementBySector[0].Sector;
    } else {
      legId = Number(element.legid) + 1;
      sector = element.Sector;
    }
    const stationData = tripData.quotes.station[legIndex + 1][type];
    return stationData.filter((item) => {
      return (
        item.SRSecID != undefined &&
        item.LocationName != undefined &&
        String(item.SRSecID).indexOf(String(legId)) > -1 &&
        item.LocationName.indexOf(String(element.Sector).substring(5, 9)) > -1
      );
    });
  }
  public setAirLandingAndSlotPermits(
    type,
    tripData,
    element,
    quoteStationType,
    setSubcharges?: boolean,
    legIndex?: any
  ) {
    let airLandingAndSlotPermits: any = [];
    if (type == CONSTANT_MESSAGES.AIR_PERMISSIONS) {
      airLandingAndSlotPermits =
        tripData.quotes.station[Number(legIndex) + 1][quoteStationType][0]
          .airlandingandslotpermits;
    } else if (setSubcharges) {
      airLandingAndSlotPermits = {
        subcharges: {
          Arrival:
            tripData.quotes.station[Number(legIndex) + 1][quoteStationType][0]
              .airlandingandslotpermits.subcharges.Arrival,
          Departure: [],
        },
      };
    }
    return airLandingAndSlotPermits;
  }
  public getSectorByLegId(tripData, legId) {
    const nextLegSector = [];
    const selectedLegIndex = tripData.srdata.Legs.map((leg) =>
      Number(leg.legid)
    ).indexOf(Number(legId));
    if (
      selectedLegIndex != -1 &&
      tripData.srdata.Legs[selectedLegIndex + 1] != undefined
    ) {
      nextLegSector.push(tripData.srdata.Legs[selectedLegIndex + 1]);
    }
    return nextLegSector;
  }
  public setInstantActivity(
    serviceActivity,
    sectorVal,
    servicename,
    instantActvityVal?: boolean
  ) {
    let instantActivity: boolean = false;
    if (
      serviceActivity == ACTIVITY_SERVICES.AIRCRAFT_HAS_LANDED ||
      instantActvityVal
    ) {
      instantActivity = sectorVal.takeoff || instantActvityVal ? true : false;
    } else {
      instantActivity =
        sectorVal.aircrafthaslanded ||
          (!sectorVal.takeoff &&
            (servicename == ACTION_ITEM_NAME.chockson ||
              servicename == ACTION_ITEM_NAME.passengershavecompletedciq))
          ? false
          : true;
    }
    return instantActivity;
  }
  public getServiceDeliveryDateTime(
    createDate: string,
    DepartDate: string,
    sector: string,
    station: string,
    flyingtime: string,
    legStatus: string,
    tripStatus: string,
    returnType: string,
    typeOFservice: string
  ): any {
    if (tripStatus == 'Unapproved' || tripStatus == 'Processing') {
      if (tripStatus == 'Unapproved') {
        if (returnType == 'progressbar') {
          return this.checkAriivalDepartureStation(
            sector,
            station,
            DepartDate,
            flyingtime,
            typeOFservice
          );
        } else {
          return this.timerService.timeDiffInSecond(
            this.checkAriivalDepartureStation(
              sector,
              station,
              DepartDate,
              flyingtime,
              typeOFservice
            ),
            this.timerService.getTimeNow()
          );
        }
      } else {
        if (returnType == 'progressbar') {
          return this.timerService.confirmationRequest(createDate, DepartDate);
        } else {
          return this.timerService.timeDiffInSecond(
            this.timerService.confirmationRequest(createDate, DepartDate),
            this.timerService.getTimeNow()
          );
        }
      }
    } else {
      if (returnType == 'progressbar') {
        return this.checkAriivalDepartureStation(
          sector,
          station,
          DepartDate,
          flyingtime,
          typeOFservice
        );
      } else {
        return this.timerService.timeDiffInSecond(
          this.checkAriivalDepartureStation(
            sector,
            station,
            DepartDate,
            flyingtime,
            typeOFservice
          ),
          this.timerService.getTimeNow()
        );
      }
    }
  }
  public checkAriivalDepartureStation(
    sector: string,
    station: string,
    departDate: string,
    flyingTime: string,
    typeOFservice: string
  ) {
    if (typeOFservice != 'sector') {
      if (sector.substring(0, 4) == station) {
        return departDate;
      } else {
        return this.timerService.addMinutes(
          this.timerService.getTimeInMin(flyingTime),
          departDate
        );
      }
    } else {
      return departDate;
    }
  }
  public getQuarantineMappingInfo(sector: any, menfestData: any) {
    return new Promise((resolve, reject) => {
      const databody =
        'Sector=' + sector + '&manifestData=' + JSON.stringify(menfestData);
      this.ServerConnect.connectToServer(
        this.apiType.POST,
        ApiConfig.getQuarantineMappingInfo,
        databody,
        false,
        false,
        false
      )
        .then((res: any) => {
          this.commonService.dissmissSpinnerLoader();
          resolve(res.GetQuarantineMappingInfo);
        })
        .catch((error) => {
          this.commonService.dissmissSpinnerLoader();
          this.commonService.showErrorAlert().then((res) => {
            if (res) {
              this.getQuarantineMappingInfo(sector, menfestData);
            } else {
              reject();
            }
          });
        });
    });
  }
  private legindex_of_legid(legs: any, legid: any, servicindex: number) {
    let index: number;
    legs.forEach((element: any, Index: number) => {
      if (Number(element.legid) == legid) {
        index = Index;
      }
    });
    if (index > 0 && legs[index + 1]) {
      return true;
    } else {
      if (servicindex == 0 && index != 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  public updateQuarantineMappingInfo(
    srId,
    srsecId,
    clientcrewId,
    signatureURL,
    clientpaxId,
    stationID
  ) {
    return new Promise((resolve, reject) => {
      const databody =
        'SRID=' +
        srId +
        '&SRSecID=' +
        srsecId +
        '&ClientCrewID=' +
        clientcrewId +
        '&SignatureURL=' +
        encodeURIComponent(signatureURL) +
        '&ClientPassengerID=' +
        clientpaxId +
        '&StationID=' +
        stationID;
      this.ServerConnect.connectToServer(
        this.apiType.POST,
        ApiConfig.updateQuarantineMappingInfo,
        databody,
        false,
        false,
        false
      )
        .then((res: any) => {
          this.commonService.dismissLoader();
          resolve(res.ResponseStatus);
        })
        .catch((error) => {
          this.commonService.dismissLoader();
          this.commonService.showErrorAlert().then((res) => {
            if (res) {
              this.updateQuarantineMappingInfo(
                srId,
                srsecId,
                clientcrewId,
                signatureURL,
                clientpaxId,
                stationID
              );
            } else {
              reject();
            }
          });
        });
    });
  }
  public AcceptAllActivity(SRID, activityStatus) {
    return new Promise((resolve, reject) => {
      this.commonService.showSpinnerLoader();
      const databody = "tripID=" + SRID + "&status=" + activityStatus;
      this.ServerConnect.connectToServer(
        this.apiType.POST,
        activityUriConfig.RequestToActivity,
        databody,
        false,
        false,
        false
      )
        .then(
          (res: any) => {
            this.commonService.dismissLoader();
            resolve(res);
          },
          (error) => {
            this.commonService.dismissLoader();
            this.commonService.showErrorAlert().then((res) => {
              if (res) {
                this.AcceptAllActivity(SRID, activityStatus);
              } else {
                reject();
              }
            });
          },
        );
    });
  }
}
