export const ProfileUriConfig = {
  SaveClientDetails: '/index.php?page=API&action=SaveClientsProfileDetails',
  SaveVendorDetails: '/index.php?page=API&action=SaveVendorProfileDetails',
  SendOnBoardingMail: '/index.php?page=API&action=SendOnBoardingMail',
  ForgetPasswordMail: '/index.php?page=API&action=SendForgotPasswordMail',
  EmailVerification: '/index.php?page=API&action=SendEmailVerificationMail',
  UserLogOut: '/index.php?page=API&action=UserLogOut',
  deleteUser: '/index.php?page=API&action=DeleteUserAccount',
  SaveCharterDetails:'/index.php?page=API&action=SaveCharterUserProfileDetails'
};
